import { actionTypes, ActionKeys, AControlModalDlg, ASetCSAFile, AApplySettings, ASetCSAJobList,
        ASetIntervalId }  from '../actions/actionCreatorTypes';
import { CSAHistoryItem } from '../data/queryResultDefinitions';
import { EProjectState, SysFeatureEnums } from './reducerEnums';
import logger from '../shared/logUtilities';

import moment from 'moment';
export interface State {
    intervalId: number;                         // id used for handling management of setIntervals
    updateTimestamp: moment.Moment;
    showModal: boolean;
    haveUploadFile: boolean;
    selectedFile: FormData;
    csaJobList: CSAHistoryItem[];
    jobListStatus: EProjectState;
}

const csaInitData: State = {
    intervalId: -1,                         // id used for handling management of setIntervals
    updateTimestamp: moment(),
    selectedFile: {} as FormData,
    showModal: false,
    haveUploadFile: false,
    csaJobList: [],
    jobListStatus: EProjectState.reloadData,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initCSAData = (initialState: State, actions: actionTypes): State => {
    const state: State = {...initialState};

    state.intervalId = 0;                         // id used for handling management of setIntervals
    state.updateTimestamp =  moment();
    state.showModal = false;
    state.haveUploadFile = false;
    state.selectedFile = {} as FormData;
    state.csaJobList.length = 0;
    state.jobListStatus = EProjectState.initComplete;
    
    return state;
}

const csaControlModal = (initialState: State, actions: actionTypes): State => {
    const action = actions as AControlModalDlg;
    let state: State = {...initialState};
    
    if (action.payload.feature === SysFeatureEnums.csaLiteConfig) {
        state = {...initialState};
        if (state.showModal !== action.payload.setOpen) {
            state.showModal = action.payload.setOpen;
            if (state.showModal) {
                state.haveUploadFile = false;
            }
        }
    }

    return state;
}

const setCSAFile = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetCSAFile;
    const state: State = {...initialState};
    
    state.haveUploadFile = true;
    state.selectedFile = action.payload.selectedFile;

    return state;
}

const setCSAJobList = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetCSAJobList;
    const state: State = {...initialState};

    const list = action.payload.csaJobList;
    const jobList = state.csaJobList;
    jobList.length = 0;

    list.sort((a: CSAHistoryItem, b: CSAHistoryItem): number => {
        let rc = 0;
        if ( a.created_at > b.created_at) { rc = -1; }
        if ( a.created_at < b.created_at) { rc = 1; }
        return rc;
    })

    list.forEach((item: CSAHistoryItem) => {
        jobList.push(item)
    });
    state.updateTimestamp = moment();
    state.jobListStatus = EProjectState.dataLoaded;

    return state;
}

const applySettings = (initialState: State, actions: actionTypes): State => {
    const action = actions as AApplySettings;
    const state: State = {...initialState};

    if (action.payload.error !== undefined) {
        state.selectedFile = {} as FormData;
        state.haveUploadFile = false;
        state.jobListStatus = EProjectState.dataLoaded;
    } else {
        state.jobListStatus = EProjectState.reloadData;
    }

    return state;
}

const setIntervalId = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetIntervalId;
    const state: State = {...initialState};

    state.intervalId = action.payload.intervalId;

    return state;
}


type ActionFunction = ((state: State, action: actionTypes) => State);
type CacheActions = ActionKeys.SET_PROJECTS | ActionKeys.CHANGE_PROJECTS | ActionKeys.CONTROL_MODAL | 
                    ActionKeys.INIT_CSA_LITE | ActionKeys.SET_CSA_FILE | ActionKeys.SET_INTERVAL_ID |
                    ActionKeys.APPLY_SETTINGS | ActionKeys.SET_CSA_JOB_LIST | ActionKeys.CHANGE_ENVIRONMENT |
                    ActionKeys.SET_CURRENT_SERVICE_EVT_IDX;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in CacheActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.SET_PROJECTS]: initCSAData,
    [ActionKeys.CHANGE_PROJECTS]: initCSAData,
    [ActionKeys.CHANGE_ENVIRONMENT]: initCSAData,
    [ActionKeys.SET_CURRENT_SERVICE_EVT_IDX]: initCSAData,
    [ActionKeys.CONTROL_MODAL]: csaControlModal,
    [ActionKeys.SET_CSA_JOB_LIST]: setCSAJobList,
    [ActionKeys.INIT_CSA_LITE]: initCSAData,
    [ActionKeys.SET_CSA_FILE]: setCSAFile,
    [ActionKeys.APPLY_SETTINGS]: applySettings,
    [ActionKeys.SET_INTERVAL_ID]: setIntervalId,
}

function csaLiteUIState(initialState: State, action: actionTypes ): State {
    let state: State = initialState;

    if ( initialState === undefined ) {
        state = Object.assign({}, csaInitData);
    }

    const fct: ActionFunction | undefined = actionMap[action.type];
    if (fct) {
        logger.log(`csaLiteState: ${action.type}`)
        state = (fct)(state, action);
    } 

    return  state;
}

export default csaLiteUIState