import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { State } from '../../App';
import { ZRestResponse, ChangePwdResponse, FetchError, changePasswordBody, ZUserMin } from '../../data/queryResultDefinitions';

import { State as AuthNServerData } from '../../reducers/authentication';
import { buildUrl } from '../../reducers/serverEnvironAccessor';

import ZButton from '../../shared/ZButton';
import ChangePwdCtrl from '../../shared/ChangePwdCtrl';
import { LOGIN_STRINGS as Strs, MISC_STRINGS, ACCOUNT_STRINGS as ACCT_STRS } from '../../shared/strings';
import ZURLS from '../../shared/urls';
import { ZPost } from '../../shared/backend';
import { NotificationStyles, DEFAULT_NUM_PREV_PREV_PWD_NOT_ALLOWED } from '../../shared/constants'
import { pwdExpiryUtils } from '../../shared/utilities';

interface ChangePwdProps extends State {
    authNServerData: AuthNServerData; 
    validatePassword: (password: string, newPassword: string, confirmPassword: string) => actionTypes;
    pwdChangeInProgress: (inprogress: boolean) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    redirectTo: (url: string) => actionTypes;
    valRulesBackground?: string;
}

class ChangePwd extends React.Component<ChangePwdProps> {
    private handleUpdatePasswordClick = (): void => {
        const {password, newPassword, expiredPwdAuthToken } = this.props.authNServerData;

        const qParams: changePasswordBody = {
            'old_password': password,
            'new_password': newPassword
        }

        if (expiredPwdAuthToken) {
            qParams.auth_data = expiredPwdAuthToken;
        }

        const params = JSON.stringify(qParams);

        const changePwdUrl = buildUrl(this.props.authNServerData, ZURLS.serverChangePassword)
        this.props.pwdChangeInProgress( true );
        const promise = ZPost(changePwdUrl, params);

        promise.then( ( pwdData: ZRestResponse ) => {
            const pwdResults: ChangePwdResponse = pwdData as ChangePwdResponse;
            if (pwdResults.message === 'Success') {
                this.props.pwdChangeInProgress( false );
                this.props.validatePassword('', '', '');
                this.props.showNotification(NotificationStyles.success, Strs.pwdChangeSuccessful);
                this.closeNotification();
                setTimeout( () => { this.props.redirectTo(ZURLS.logout) }, 5000);
            }
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.props.pwdChangeInProgress( false );
                this.closeNotification();
            })
        });
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }

    render(): JSX.Element {
        const { newPassword, confirmPassword, password, pwdValidation, passwordChangeInProgress, user, currentOrg } =  
                    this.props.authNServerData ;
        const expiryInfo = new pwdExpiryUtils((user as ZUserMin).meta.password_expires_at_epoch_seconds, currentOrg.password_expiry_warning_days);

        let expiringWarning = <div></div>;
        if (expiryInfo.isExpiring) {
            expiringWarning = <div className="warning-text-large">{expiryInfo.timeString}<br/><br/></div>
        } 

        let passwordReuse = '';
        
        if (currentOrg && currentOrg.num_prev_passwords_not_allowed) {
            passwordReuse =  (currentOrg.num_prev_passwords_not_allowed > 1) ? ACCT_STRS.passwordReuse(currentOrg.num_prev_passwords_not_allowed) : '';
        } else {
            ACCT_STRS.passwordReuse(DEFAULT_NUM_PREV_PREV_PWD_NOT_ALLOWED);
        }
        return (
            <div className="feature-panel">
                <div id="change-pwd">
                    <div className="title">{Strs.changePasswordTitle}</div>
                    {expiringWarning}
                    <div id="change-pwd-ctrl" className="row">
                        <div className="col-md-10">
                        <div className="validate pad-left-30">
                            <ChangePwdCtrl 
                                newPassword={newPassword} 
                                confirmPassword={confirmPassword} 
                                validationState={pwdValidation}
                                handleValidation={(oldPwd: string, newPwd: string, confirmPwd: string): void => {
                                    this.props.validatePassword(oldPwd,  newPwd, confirmPwd); }} 
                                promptForOldPassword={true}
                                currentPassword={password}
                                validatationBkgCss={this.props.valRulesBackground}
                            />
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <strong>{passwordReuse}</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ZButton disabled={(! pwdValidation.validPwd) || passwordChangeInProgress } 
                                    btnType="primary" btnCls="btn-padding" 
                                    onClick={(): void => {this.handleUpdatePasswordClick(); }}>
                                <span>
                                    <span className="pad-btm-3px"><FontAwesomeIcon size="xs" icon={['fas', 'lock']} />
                                    </span>&nbsp;{Strs.changePwd}
                                </span>
                            </ZButton>
                        </div>
                    </div>
                </div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
        )
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        logsUIState: state.logsUIState,
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(ChangePwd);