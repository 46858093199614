
import { MISC_STRINGS as STRs } from '../../shared/strings';

export enum formatterEnum {
    UNDEFINED = -1,
    KBYTE = 'kbyte',
    BYTE = 'byte',
    TIME = 'time',
    NUM = 'num',
    PERCENT = '%',
}

const pwrOfTenMB = {
    // ebyte: Math.pow(1024, 6),
    pbyte: Math.pow(1024, 5),
    tbyte: Math.pow(1024, 4),
    gbyte: Math.pow(1024, 3),
    mbyte: Math.pow(1024, 2),    
    kbyte: Math.pow(1024, 1),  // for byte units
}

export class DataFormatter {
    public  formatToolTipData(value: number | null, formatter: formatterEnum): string {
        let dataStr = '';
    
        if (value === null) {
            dataStr = '';
        } else {
            switch (formatter) {
                case formatterEnum.KBYTE:
                    dataStr = this.buildKbLabel(value);
                    break;
    
                case formatterEnum.BYTE:
                    dataStr = this.buildByteLabel(value);
                    break;
    
                case formatterEnum.TIME:
                    dataStr = this.buildTimeLabel(value);
                    break;
    
                case formatterEnum.NUM:
                    dataStr = this.buildGenericLabel(value);
                    break;
    
                case formatterEnum.PERCENT:
                    dataStr = this.buildPercentLabel(value);
                    break;
    
                default:
            }
        }
    
        return dataStr;
    }

    public buildSimpleTime(label: number): string {
        return this.formatTime(new Date(label), false, true);
    }

    public  buildSimpleDateTime(dateNum: number): string {
        const date: Date = new Date(dateNum);
        return this.formatDate(date) + ' ' + this.formatTime(date, false, true)
    }

    public  formatTime( date: Date, noAMPM = false, noSeconds = false, twoDigitHour = false ): string {
        let hours = date.getHours();
        let ampm = '';
        if (! noAMPM) {
            if (hours >= 12) {
                hours -= 12;
                ampm = STRs.pm;
            } else {
                ampm = STRs.am;
            }

            if (hours === 0) {
                hours = 12;
            }
        }

        const hoursStr = (twoDigitHour) ? this.twoDigits(hours) : hours.toString();
        const seconds = noSeconds ? '' : (':' + this.twoDigits(date.getSeconds()));

        const time = hoursStr + ':' + this.twoDigits(date.getMinutes()) + 
                    seconds  + ampm;
        return time;
    }

    public  formatDate( date: Date, iso = false): string {
        let dateStr: string;
        const separator = (iso) ? '-' : '/';
        if (iso) {
            dateStr =  date.getFullYear() + separator + this.twoDigits(date.getMonth() + 1) + separator + 
            this.twoDigits(date.getDate());
        } else {
            dateStr = this.twoDigits(date.getMonth() + 1) + separator + 
                    this.twoDigits(date.getDate()) + separator + date.getFullYear();
        }

        // console.log('formatDate: date - ', date, '; dateStr - ', dateStr );
        return dateStr;
    }

    public  twoDigits(num: number): string {
        let twoDig: string;
        if (num < 10) {
            twoDig = '0' + num;
        } else {
            twoDig = num.toString(10);
        }

        return twoDig;
    }

    public  buildKbLabel(label: number): string {
        let labelStr = '';
        // if (label >= pwrOfTenMB.ebyte) {
        //     labelStr = (label / pwrOfTenMB.ebyte).toFixed(2) + STRs.ebyte;

        // } else 
        if (label >= pwrOfTenMB.pbyte) {
            labelStr = (label / pwrOfTenMB.pbyte).toFixed(2) + STRs.pbyte;

        } else if (label >= pwrOfTenMB.tbyte) {
            labelStr = (label / pwrOfTenMB.tbyte).toFixed(2) + STRs.tbyte;

        } else if (label >= pwrOfTenMB.gbyte) {
            labelStr = (label / pwrOfTenMB.gbyte).toFixed(1) + STRs.gbyte;

        } else if (label >= pwrOfTenMB.mbyte) {
            labelStr =  (label / pwrOfTenMB.mbyte).toFixed(1) + STRs.mbyte;
            
        } else {
            labelStr = (label / pwrOfTenMB.kbyte).toFixed(1) + STRs.kbyte;
        }

        // console.log('buildKbYAxis: value ', label, '; labelStr = ' + labelStr);
        return labelStr;
    }

    public  buildByteLabel(label: number): string {
        let labelStr = '';
        if (label < pwrOfTenMB.kbyte) {
            labelStr =  (label).toFixed(1) + STRs.byte;
        } else {
            labelStr = this.buildKbLabel(label);
        } 

        // console.log('buildByteLabel: value ', label, '; labelStr = ' + labelStr);
        return labelStr;
    }

    public  buildTimeLabel( label: number): string {
        let labelStr = '';
        if (label >= Math.pow(10, 3)) {
            labelStr =  (label / Math.pow(10, 3)).toFixed(2) + STRs.sec;
        } else {
            labelStr =  (label).toFixed(1) + STRs.msec;
        }

        // console.log('buildTimeLabel: value ', label, '; labelStr = ' + labelStr);
        return labelStr;
    }

    public  buildPercentLabel( label: number): string {
        return label.toString() + '%';
    }

    public  buildGenericLabel(label: number): string {
        const labelStr = label.toFixed(2);

        // console.log('buildGenericLabel: value ', label, '; labelStr = ' + labelStr);
        return labelStr;
    }
}
