import * as React from 'react';
// import { Redirect } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { State } from '../App';
import { actionTypes } from '../actions/actionCreatorTypes';

import { ZRestResponse, ServerConfig, FetchError, ResetTokenInfo, changePasswordBody } from 
            '../data/queryResultDefinitions';

import { State as AuthNServerData, AuthenticationType } from '../reducers/authentication';
import { buildLoginServerURL, } from '../reducers/serverEnvironAccessor'
// import { PwdResetState } from '../reducers/reducerEnums';

import { ZGet, ZPost } from '../shared/backend';
import { LOGIN_STRINGS as Strs } from '../shared/strings';
import { PwdValidationState } from '../shared/authUtils';
import { NotificationStyles } from '../shared/constants';
import LoginPanel from '../shared/LoginPanel';
import ZURLs from '../shared/urls';
import ZButton from '../shared/ZButton';
import NotificationControl from '../shared/Notification';
import ChangePwdCtrl from '../shared/ChangePwdCtrl';

import ZURLS from '../shared/urls';

interface ResetProps extends State {
    authNServerData: AuthNServerData;
    saveServerConfig: (serverConfig: ServerConfig) => actionTypes;
    validatePassword: (password: string, newPassword: string, confirmPassword: string) => actionTypes;
    validateProfile: (fname: string, lname: string, email: string, phone: string) => actionTypes;
    resetPassword: ( success: boolean) => actionTypes;
    pwdChangeInProgress: (inprogress: boolean) => actionTypes;

    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    setPwdResetTokenInfo: (authenticationType: AuthenticationType, token: string, tokenStatus: ResetTokenInfo) => actionTypes;
}

class PwdExpired extends React.Component<ResetProps> {
    public componentDidMount(): void {
        if (! this.props.authNServerData.serverConfigLoaded) {
            const configURL = (ZURLs.getSysConfigURL( ) as string) + 'conf.json';
            const promise = ZGet(configURL, '');
            promise.then(( lData: ZRestResponse ) => {
                const serverConfig = lData as ServerConfig;
                this.props.saveServerConfig(serverConfig);
            })        
            .catch((errStr) => {
                const p = errStr as Promise<FetchError>;
                p.then((err) => {
                    console.log(`Reset.componentDidMount: fetch failed: ${err}`);

                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.handleCloseNotification();
                })
            });
        }
    }


    public handleResetButtonClick(): void {
        const authData = this.props.authNServerData;
 
        const json: changePasswordBody = {
            'old_password': authData.password,
            'new_password': authData.newPassword,
            'auth_data': authData.expiredPwdAuthToken
        }

        const changePwdUrl = buildLoginServerURL(this.props.authNServerData, ZURLS.serverChangePassword)
        // this.props.pwdChangeInProgress( true );
        this.executeServerCmd(changePwdUrl, JSON.stringify(json), Strs.pwdChangeSuccessful, true);
    }

    public render(): JSX.Element { 
        const systemState = this.props.systemNavProjectState;
        const authData = this.props.authNServerData;
        const expiredPwdAuthToken = authData.expiredPwdAuthToken;

        const title = Strs.expiredTitle;
        const msg: string = systemState.notificationData.message ? systemState.notificationData.message : '';

        let markup = <div></div>;
        let instructMarkup = <div></div>;
        const copyrightPanelBottom = false;
        const resetCSS = 'display-box1';

        if (this.props.authNServerData && this.props.authNServerData.serverConfigLoaded) {
            if (!( expiredPwdAuthToken && expiredPwdAuthToken.length > 0)) {
                instructMarkup  = <div>{Strs.refreshedExpireData}</div>
                markup = <div>
                 <ZButton btnCls="login-btn-style"
                                onClick={(): void => { window.location.href='./login'}}  btnType="primary">
                    <span>{Strs.gotoLogin}</span>
                    </ZButton>
                </div>
            } else {
                markup = this.buildExpirePanel();
                instructMarkup = (
                    <div className="row popup">
                        <div className="col-md-1"></div>
                        <div className="col-md-11 instructions">{Strs.expiredInstruct}</div>
                    </div>
                );
            }
        } else {
            if (msg.length === 0) {
                return markup;
            }
            markup = ( <div>{msg}</div>);
        }

        return (
            <div id="password-reset">                       
                <NotificationControl show={this.props.systemNavProjectState.notificationData.show} message={msg}
                                style={this.props.systemNavProjectState.notificationData.style}
                                cssStyles="errorPosition"
                                closeHandler={(): void => { this.handleCloseNotification() }} />
                <LoginPanel title={title} msg={''} lpCSS={resetCSS} copyrightPanelBottom={copyrightPanelBottom}>
                    {instructMarkup}
                    {markup}
                </LoginPanel>
            </div>
        );
    }

    private buildExpirePanel = (): JSX.Element => {
        const pwdValidation: PwdValidationState = this.props.authNServerData.pwdValidation;
        const { password, newPassword, confirmPassword,  } =  this.props.authNServerData;

        const markup = (
            <div id="pwd-reset" className="row">
                <div className="col-md-1"></div>
                <div className="col-md-11 popup">
                    <ChangePwdCtrl
                        newPassword={ newPassword } 
                        confirmPassword={ confirmPassword } 
                        validationState={pwdValidation}
                        promptForOldPassword={true}
                        currentPassword={password}
                        handleValidation={(oldPwd: string, newPwd: string, confirmPwd: string): void => {
                            this.props.validatePassword(oldPwd,  newPwd, confirmPwd); }}
                        validatationBkgCss="background-transparent"
                    />
                </div>
            </div>
        )

        return (
            <>
            {markup}
            <div className="row">
                <div className="col-md-12">
                    <ZButton disabled={! pwdValidation.validPwd } btnCls="login-btn-style"
                                onClick={(): void => {this.handleResetButtonClick()}}  btnType="primary">
                    <span><FontAwesomeIcon icon={['fas', 'lock']} /> {Strs.resetPwd}</span>
                    </ZButton>
                </div>
            </div>
            </>
        )
    }

    public executeServerCmd(resetUrl: string, params: string, successMsg: string, changeToLoginPage: boolean): void {
        const promise = ZPost(resetUrl, params);

        promise.then( ( ) => {
            this.props.showNotification(NotificationStyles.success, successMsg);
            this.handleCloseNotification();
            this.props.resetPassword( true );
            if (changeToLoginPage) {
                setTimeout( () => { 
                    window.location.href = "./login";
                }, 5000);
            }
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.handleCloseNotification();
            })
        })
    }

    private handleCloseNotification(): void {
        setTimeout(() => {this.props.closeNotification()}, 5000);
    }
}

export default PwdExpired;
