import * as React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ACCOUNT_STRINGS as Strs, MISC_STRINGS } from './strings';
import { ProfileValidationState } from './authUtils';

export interface ChangeProfileCtrlProps {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    profileValidation: ProfileValidationState;
    validateProfile: (firstName: string, lastName: string, email: string, phone: string) => void;
    validationBkgCss?: string;
    readOnlyEmail?: boolean;
}

class ChangeProfileCtrl extends React.Component<ChangeProfileCtrlProps> {
    
    render(): JSX.Element {
        let emailCSS = 'form-control';
        let phoneCSS = 'form-control';
        const emailDisabled = !!this.props.readOnlyEmail;
        if (!emailDisabled) {
            emailCSS += (this.props.profileValidation.emailValid ? '' : ' input-error');
        }
        const fnameCSS = 'form-control' + (this.props.profileValidation.firstNameValid ? '' : ' input-error');
        const lnameCSS = 'form-control' + (this.props.profileValidation.lastNameValid ? '' : ' input-error');

        if (this.props.phoneNumber.length > 0) {
            phoneCSS += (this.props.profileValidation.phoneNumberValid ? '' : ' input-error');
        }

        return (
            <div id="change-profile-ctrl">
            <div className="validate">
            <div className="row">
                <div className="col-md-6">
                    <div className={'pad-bottom-3'}>{Strs.firstName}{this.showValidationRules('firstName')}</div>
                    <div className="input-group input-group-lg">
                        <input id="fname" type="text" 
                            className={fnameCSS}
                            placeholder={Strs.firstName} value={this.props.firstName}  maxLength={45} required
                            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                                this.props.validateProfile(
                                    e.currentTarget.value, this.props.lastName, this.props.email, 
                                    this.props.phoneNumber);
                            }} 
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={'pad-bottom-3'}>{Strs.lastName} {this.showValidationRules('lastName')}</div>
                    <div className="input-group input-group-lg">
                        <input id="lname" type="text" className={lnameCSS} 
                            placeholder={Strs.lastName} value={this.props.lastName}  maxLength={45} required
                            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                                this.props.validateProfile(
                                    this.props.firstName, e.currentTarget.value, 
                                    this.props.email, this.props.phoneNumber);
                            }} 
                            />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-offset-1 col-md-6">
                    <div className={'pad-bottom-3'}>{MISC_STRINGS.emailAddress} {emailDisabled ? '' : this.showValidationRules('email')}</div>
                    <div className="input-group input-group-lg">
                        <input id="email" type="text" className={emailCSS} disabled={emailDisabled}
                            placeholder={MISC_STRINGS.emailAddress} value={this.props.email}  maxLength={255} 
                            required onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                                this.props.validateProfile(
                                    this.props.firstName, this.props.lastName, 
                                    e.currentTarget.value, this.props.phoneNumber);
                            }} />
                    </div>
                </div>
                <div className="col-md-offset-1 col-md-6">
                    <div className={'pad-bottom-3'}>{Strs.phoneNumber}{ this.props.phoneNumber.length > 0 ? this.showValidationRules('phoneNumber') : ''}</div>
                    <div className="input-group input-group-lg">
                        <input id="phonenumber" type="text" className={phoneCSS} required
                            placeholder={Strs.phoneNumberSample} value={this.props.phoneNumber}  maxLength={45} 
                            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                                this.props.validateProfile(
                                    this.props.firstName, this.props.lastName, 
                                    this.props.email, e.currentTarget.value);
                            }} 
                            />
                    </div>
                </div>
            </div>
        </div>
        </div>
        );
    }

    private errWithOverLay = ( key: string, txt: string ): JSX.Element => {
        const ttip = <Tooltip id={`tooltip-${key}`}><span>{txt}</span></Tooltip>;
        return (
            <OverlayTrigger  key={key}  placement="right" overlay={ ttip }>
                <Button variant="link">
                     <FontAwesomeIcon className="profile-rule" size="xs" icon={['fas', 'exclamation-triangle']} />
                </Button>
            </OverlayTrigger>
        )
    }
    
    private showValidationRules = (fieldName: string): JSX.Element => {
        const validationErrorMap = {
            firstName: Strs.nameRule,
            lastName: Strs.nameRule,
            email: Strs.emailRule,
            phoneNumber: Strs.phoneRule
        }

        const valid = this.props.profileValidation[fieldName + 'Valid'];
        let rules = <span></span>;

        if (!valid) {
            const overlay = this.errWithOverLay(fieldName, validationErrorMap[fieldName])
            rules = (<span className="profile-rule">{overlay}</span>)
        }
        return rules;
    }
}

export default ChangeProfileCtrl;