import * as React from 'react';

import { State } from '../App';

import { AvailableBillingReports, BillingOrgUsageData } from '../data/queryResultDefinitions';
import { SysFeatureEnums, UsageUnits, ZSortDirection} from '../reducers/reducerEnums';

import { actionTypes } from '../actions/actionCreatorTypes';

import BillingDownloads from './billing/BillingDownloads';
import TrafficUsage from './billing/TrafficUsage';

import { MISC_STRINGS } from '../shared/strings';
import { NotificationStyles } from '../shared/constants';

export interface BillingStateProps extends State {
    setReportPeriodIndex: (periodIndex: number) => actionTypes,
    sortTable: (feature: SysFeatureEnums, tableName: string, fieldName: string, direction: ZSortDirection) => actionTypes,
    setBillingReportData:(availableBillingReports: AvailableBillingReports) => actionTypes,
    setProjectTrafficUsage: (billingUsageData: BillingOrgUsageData, includesServices: boolean) => actionTypes,
    toggleBillingShowServices: (showingServices: boolean) => actionTypes,
    toggleBillingShowProjectServices: (projectIndex: number) => actionTypes,
    setBillingUnits: (units: UsageUnits) => actionTypes,
    setBillingMonthIndex: (index: number) => actionTypes,
    
    applySettings: (error?: string) => actionTypes;
    initFeature: (featurName: SysFeatureEnums) => actionTypes,
    featureReady: () => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
}

class BillingReports extends React.Component<BillingStateProps> {
    render(): JSX.Element {
        const uiState = this.props.systemNavProjectState;
        let page: JSX.Element = <span></span>;
        if (uiState.currentFeature === SysFeatureEnums.billingReportsTrafficUsage) { 
            page = <TrafficUsage  {...this.props} /> 
        } else if (uiState.currentFeature === SysFeatureEnums.billingReportsDownloads) { 
            page = <BillingDownloads  {...this.props} /> 
        } else {
            page = <div>{MISC_STRINGS.loading}</div>
        }

        return (
            <div className="feature-panel">
                <div className="report-features">
                    {page}
                </div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
        )
    }
}

export default BillingReports;