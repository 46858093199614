import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../actions/actionCreators';

import { State } from '../App';
import { SysFeatureEnums } from '../reducers/reducerEnums';

import ChangePwd from './accountComps/ChangePwd';
import UpdateAccountProfile from './accountComps/UpdateAccountProfile';
import Documentation from './accountComps/Documentation';
import ViewMyApiKeys from './adminComps/ViewMyKeys';
import TFAMgmt from './accountComps/TFAMgmt';

class Account extends React.Component<State> {
    render(): JSX.Element {
        const uiState = this.props.systemNavProjectState;
        // console.log(uiState.currentFeature) 
        let page: JSX.Element = <span></span>;
        
        if (uiState.currentFeature === SysFeatureEnums.accountChgPwd) {
            page = <ChangePwd  {...this.props} />;
        } else if (uiState.currentFeature === SysFeatureEnums.apiKeys) {
            page = <ViewMyApiKeys {...this.props} /> 
        } else if (uiState.currentFeature === SysFeatureEnums.tfaMgmt) {
            page = <TFAMgmt {...this.props} />
        } else if (uiState.currentFeature === SysFeatureEnums.documentation) {
            page = <Documentation {...this.props} />
        } else {
            page = <UpdateAccountProfile  {...this.props} />;
        }

        return page;
    }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        authNServerData: state.authNServerData,
        systemNavProjectState: state.systemNavProjectState,
    }
}

// eslint-disable-next-line
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(Account);