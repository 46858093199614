import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actionTypes } from '../actions/actionCreatorTypes';
import * as actionCreators from '../actions/actionCreators';
import { redirectTo } from '../actions/actionCreators';

import { State } from '../App';
import { State as AuthNServerData } from '../reducers/authentication'
import { buildUrl } from '../reducers/serverEnvironAccessor';

import { ZPost } from '../shared/backend';
import ZURLs from '../shared/urls';
import logger from '../shared/logUtilities';
import { removeSessionStorage } from '../shared/utilities';
import { store } from '../store';

interface LogoutProps extends State {
    authNServerData: AuthNServerData, 
    redirectTo: (url: string) => actionTypes;
    logout: () => actionTypes;
}

class Logout extends React.Component<LogoutProps> {
    public componentDidMount() {
        logger.log('logging out');
        this.logout();
    }

    public render() {
        return (
            <div className="feature-panel">
                <div>Logging out...</div>
            </div>
        )
    }

    private logout() {
        removeSessionStorage();
        const url = buildUrl(this.props.authNServerData, ZURLs.serverLogout)

        const promise = ZPost(url, '{}');
        this.props.logout();

        promise.then( ( ) => {
            logger.log('Logout:  success:  navigating to login...');
            store.dispatch( redirectTo(ZURLs.login));
        })
        .catch((err) => {
            logger.log('Logout: logout failed.  Err: ' + JSON.stringify(err));
            store.dispatch( redirectTo(ZURLs.login));
        });
    }
}

const stateToProps = (state: State) => {
    return {
        authNServerData: state.authNServerData,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(Logout);