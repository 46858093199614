import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import { getNumCheckedAdminItems, getCheckedApiKeyList } from '../../reducers/adminAccessors';
import { EProjectState, ZAdminMapType, ZFeaturePopupTypes } from '../../reducers/reducerEnums';
import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { AdminManagementTypes } from '../../reducers/adminState';

import { ZApiKey, ZUserMin, ZRestResponse, ApiKeyPermission, FetchError, ZUserTypes } from '../../data/queryResultDefinitions';

import { ZGet, ZPost, ZPut } from '../../shared/backend';
import { APIKEY_NAME_LENGTH, DATETIME_FORMAT_NOSECS, NotificationStyles, CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT } from '../../shared/constants';
import { ADMIN_STRINGS as Strs, MISC_STRINGS } from '../../shared/strings';
import { getTimeZone, copyTextToClipboard } from '../../shared/utilities';

import ZDatePicker from '../../shared/DateControl';
import ListPanel from '../../shared/ListPanel';
import logger from '../../shared/logUtilities';
import ZURLS from '../../shared/urls';
import ZButton from '../../shared/ZButton';  
import GenDialog from '../../shared/GenDialog';

import { APIKeyAdminProps } from './ApiKeyAdmin';
import GenerateEditApiKey from './GenerateEditApiKey';

export interface ApiKeyControlProps {
    state: APIKeyAdminProps, 
    canCreateKeys: boolean,
    showCreateBtn: boolean,
    msg?: string,
    additionalCSS?: string,
}

export const ApiKeyControl: React.FunctionComponent<ApiKeyControlProps> = props => {
    const keysList = buildKeyList(props.state);
    const numCheckedItems = getNumCheckedAdminItems(props.state.adminUIState, AdminManagementTypes.apiKeys);
    const canNotRemoveKeys = (numCheckedItems === 0);
    const canEditKey = !(numCheckedItems === 1);
    const panelCSS = 'admin-panel ' + (props.additionalCSS !== undefined ? props.additionalCSS : '')
    const keyMsg = props.msg ? props.msg : '';

    const keysHeader: JSX.Element = (
        <div className="admin-ka-key-list-header">
            <div></div>
            <div>{Strs.keyName}</div>
            <div>{Strs.type}</div>
            <div>{Strs.creationDate}</div>
            <div>{Strs.validTilLabel}</div>
            <div>{Strs.lastUsed}</div>
            <div></div>
        </div>);

    let tzone = getTimeZone();
    tzone = Strs.timeZone(tzone);

    const createBtn: JSX.Element = props.showCreateBtn ? (<>
                        <ZButton disabled={! props.canCreateKeys} onClick={(): void => { handleGenerateKeyBtnClick(props.state); }}>
                        {Strs.createKeys}
                        </ZButton>&nbsp;&nbsp;
                    </>) : <span></span>

    const markup = (<div className={panelCSS}>
            <ListPanel
                renderHeader={(): JSX.Element => { return keysHeader }}
                renderBody={(): JSX.Element => { return keysList}}
                renderFooter={(): JSX.Element => {return (
                    <div className="list-panel-footer-3col">
                        <div>
                        <input onChange={(): void => {handleToggleExpiredKeysCheckbox(props.state)}} type="checkbox" >
                        </input>&nbsp;{Strs.showExpiredKeys}</div>
                        <div className="small-text"><span className="optional">{tzone}</span></div>
                        <div>
                            {createBtn}
                            <ZButton disabled={canEditKey} onClick={(): void => { 
                                handleEditKeyBtnClick(props.state); }}>
                                {Strs.editKey}
                            </ZButton>&nbsp;&nbsp;
                            <ZButton onClick={(): void => { handleExpireSelectedKeysBtnClick(props.state); }} 
                                disabled={canNotRemoveKeys} btnCls="list-panel-footer-item">
                                {Strs.expireKeys}
                            </ZButton> 
                        </div>
                    </div>
            )}}/>
            <div>{keyMsg}</div>
        </div>)

    return markup;

}

const buildKeyList = (state: APIKeyAdminProps): JSX.Element => {
    const { apiKeyList, adminState, showExpiredKeys, selectedUser } = state.adminUIState;
    if (adminState !== EProjectState.ready) {
        return <span></span>;
    }
    
    const keyList: JSX.Element[] = [];
    if (apiKeyList.length > 0) {
        for (let i = 0, len = apiKeyList.length; i < len; i++) {
            const key = apiKeyList[i];
            const keyExpiresTime = moment(key.expires_at);
            const keyNotExpired = keyExpiresTime.isAfter(Date.now());
            if (showExpiredKeys || (!showExpiredKeys && keyNotExpired)) {
                keyList.push(buildKeyItem(state, key, keyExpiresTime, i));
            }
        }
    } else {
        let keyItem = <span></span>;
        if (selectedUser && selectedUser.role === ZUserTypes.user) {
            keyItem = <div className="not-allowed">{Strs.apiKeysNotSupportedForUser}</div>
        } else {
            keyItem = <div className="not-allowed">{Strs.noActiveApiKeysForUser}</div>
        }
        keyList.push((
            <div key="line1" className='admin-ka-key-list-item' >
                {keyItem}
            </div>
        ));
    }

    return <>{keyList}</>;
}

const buildKeyItem = (state: APIKeyAdminProps, key: ZApiKey, keyExpiresTime: moment.Moment, index: number): JSX.Element => {
    const selected = (index === state.adminUIState.apiKeyListIdx) ? 'selected' : '';
    const isExpired = keyExpiresTime.isBefore(Date.now());
    const className = `admin-ka-key-list-item ${selected} ${isExpired ? ' expired-key' : ''}`;

    let checked = state.adminUIState.checkedApiKeys[key.api_key_id];
    checked = checked !== undefined ? checked : false;

    const edit = <span>{key.name}</span>;

    const checkbox = (isExpired) ? <span></span> : (
        <input type="checkbox"  checked={checked} onChange={(): void => { logger.log('ApiKeyAdmin: checked key')}}>
        </input>
    );

    const createTime = moment(key.created_at);
    const expireTime = isExpired ? Strs.expired : keyExpiresTime.format(DATETIME_FORMAT_NOSECS);
    const lastUseTime = (key.last_used_at === '1970-01-01T00:00:00Z') ? Strs.never : 
                    moment(key.last_used_at).format(DATETIME_FORMAT_NOSECS);

    const permission = key.permission.toUpperCase();
    const permIcon = (key.permission === ApiKeyPermission.ro) ? 'shield-alt' : 'pencil-alt';
    return (
        <div key={'line' + index} className={className} 
            onClick={(): void => { 
                state.setApiKeyListIndex(index);
                if (! isExpired) {
                    state.toggleAdminSelection(ZAdminMapType.SelectedUserApiKey, key.api_key_id);
                }
            }}>
            <div>{checkbox}</div>
            <div>{edit}</div>
            <div><FontAwesomeIcon icon={permIcon as IconProp} /> {permission}</div>
            <div>{createTime.format(DATETIME_FORMAT_NOSECS)}</div>
            <div>{expireTime}</div>
            <div>{lastUseTime}</div>
            <div></div>

        </div>
    )
}

// const activeKeysSelected = (state: APIKeyAdminProps, numCheckedItems: number): boolean => {
//     let foundActive = false;
//     const adminState = state.adminUIState;

//     if (numCheckedItems > 0) {
//         if (adminState.showExpiredKeys) {
//             const now = moment();

//             adminState.apiKeyList.forEach(key => {
//                 if (moment(key.expires_at) > now) {
//                     foundActive = foundActive || adminState.checkedApiKeys[key.api_key_id]
//                 }
//             })
//         } else {
//             foundActive = true;
//         }
//     }

//     return foundActive;
// }

// *****
// Generate Key
// *****
export const buildGenerateApiKeyPopup = (state: APIKeyAdminProps, user: ZUserMin, onDone?: () => void): JSX.Element => {
    const {keyExpire, keyName} = state.adminUIState;
    const markup = (
        <GenerateEditApiKey
            show={state.adminUIState.popupOpen}
            edit={false}
            title={Strs.generateApiKey}
            applyBtnTxt={Strs.generateApiKey}
            disableApply={(): boolean => { return canEnableApply(state); }}
            onHide={(): void => { closePopup(state)} }
            handleApply={(): void => { generateKey(state, user, onDone); }}
        >
        { buildCommonEditGeneratePopupParts(state, keyExpire) }
        <div className="key-perm-spacer">{Strs.keyType}</div>
        <div className="key-perm-radio">
            <div>
                <input type="radio" 
                onChange={(): void => { state.setApiKeyState(keyName, keyExpire, ApiKeyPermission.ro ); }} 
                value={state.adminUIState.keyPermission}
                checked={state.adminUIState.keyPermission === ApiKeyPermission.ro}></input>&nbsp;&nbsp;
                <FontAwesomeIcon icon={'shield-alt' as IconProp} />&nbsp;{Strs.readOnly}
            </div>
            <div>
                <input type="radio" 
                    onChange={(): void => { state.setApiKeyState(keyName, keyExpire, ApiKeyPermission.rw ); }} 
                    value={state.adminUIState.keyPermission}
                    checked={state.adminUIState.keyPermission === ApiKeyPermission.rw}></input>&nbsp;&nbsp;
                    <FontAwesomeIcon icon={'pencil-alt' as IconProp} />&nbsp;{Strs.readWrite}
            </div>

        </div>
        </GenerateEditApiKey>
    );

    return markup;  
}

const canEnableApply = (state: APIKeyAdminProps): boolean => {
    const fName = state.adminUIState.keyName;
    const expDate = state.adminUIState.keyExpire;
    const cDateCopy = moment(state.adminUIState.keyCreation);
    const lastDate = cDateCopy.add('1', 'year');

    let okToApply = false;
    okToApply = (fName.length > 0 && fName.indexOf(' ') === -1);
    okToApply = okToApply && (typeof(expDate) !== 'string') && expDate.isValid() && expDate.isBefore(lastDate);

    return !okToApply; 
}

const canEnableUpdate = (state: APIKeyAdminProps): boolean => {
    const fName = state.adminUIState.keyName;
    const expDate = state.adminUIState.keyExpire;
    const oldExpDate = moment(state.adminUIState.selectedApiKey.expires_at);
    const cDateCopy = moment(state.adminUIState.keyCreation);
    const lastDate = cDateCopy.add('1', 'year');

    let okToApply = false;
    okToApply = (fName.length > 0 && fName.indexOf(' ') === -1);
    okToApply = okToApply && (typeof(expDate) !== 'string') && expDate.isValid() && expDate.isBefore(lastDate);
    okToApply = okToApply && !(expDate.isSame(oldExpDate) && 
                (fName === state.adminUIState.selectedApiKey.name));

    return !okToApply; 
}

const buildCommonEditGeneratePopupParts = (state: APIKeyAdminProps, keyExpire: moment.Moment): JSX.Element => {
    return (
        <>
        <div>
        <label>
            {Strs.friendlyKeyName}&nbsp; 
            <input className="key-friendly-name" type="text" value={state.adminUIState.keyName} 
                   placeholder={ Strs.friendlyPlaceholder }
                   onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                        state.setApiKeyState(e.currentTarget.value, 
                                                  state.adminUIState.keyExpire, 
                                                  state.adminUIState.keyPermission ); 
            }} maxLength={ APIKEY_NAME_LENGTH } />
        </label>
        </div>
        <div>{Strs.keyExpirationDate} &nbsp;
            <ZDatePicker
                date={keyExpire}
                isValidDate={(current: moment.Moment): boolean => {
                    const cDateCopy = moment(state.adminUIState.keyCreation);
                    let lastDate = cDateCopy.add('1', 'year');
                    lastDate = lastDate.subtract(1, 'day');

                    return current.isAfter(moment.now()) && !current.isAfter(lastDate);
                }}
                handleDateChange={ (newDate: moment.Moment | string): void => { 
                     state.setApiKeyState(state.adminUIState.keyName, 
                                               newDate as moment.Moment, state.adminUIState.keyPermission ); }
                 }
                css="expiration-date"
            />
        </div>
        </>
    )
}

const updateKey = (state: APIKeyAdminProps, onDone?: () => void): void => {
    closePopup(state);

    const { keyName, keyExpire, selectedApiKey} = state.adminUIState;
    const updatedKey: ZApiKey = {} as ZApiKey;

    if (keyName !== selectedApiKey.name) {
        updatedKey.name = keyName;
    }
    const currentExpire = moment(selectedApiKey.expires_at); 
    if (! keyExpire.isSame(currentExpire)) {
        updatedKey["expires_at"] = keyExpire.toISOString();
    }

    const url = buildUrl(state.authNServerData, ZURLS.serverApiKeyUpdate(selectedApiKey.api_key_id));
    const promise = ZPut(url, JSON.stringify(updatedKey));
    
    promise.then( ( ) => {
        loadUserApiKeys(state, onDone );
        state.showNotification(NotificationStyles.success, Strs.apiKeyUpdateSuccess);
        closeNotification(state);

    })
    .catch((errStr) => {
        const p = errStr as Promise<FetchError>;
        p.then((err) => {
            state.showNotification(NotificationStyles.danger, err.message);
            closeNotification(state);
        })
    });
}

const closePopup  = (state: APIKeyAdminProps): void => {
    state.toggleAdminPopup(ZFeaturePopupTypes.NoPopup, false);
}

const generateKey = (state: APIKeyAdminProps, user: ZUserMin, onDone?: () => void): void => {
    closePopup(state);

    const { keyName, keyExpire, keyPermission} = state.adminUIState;
    const expiration = moment(keyExpire)
    expiration.hour(23);
    expiration.minute(59);
    expiration.seconds(59);

    if (!state.authNServerData.user) {
        return 
    }
    
    const userId = state.authNServerData.user.user_id;
    const newKey = {
        name: keyName,
        "expires_at": expiration,
        permission: keyPermission
    }
    
    const url = buildUrl(state.authNServerData, ZURLS.serverUserApiKeyManagement(userId));
    const promise = ZPost(url, JSON.stringify(newKey));
    
    promise.then( ( updatedKey: ZRestResponse ) => {
        const key = updatedKey as ZApiKey;
        loadUserApiKeys(state, onDone );
        state.toggleAdminPopup(ZFeaturePopupTypes.NewAPIKeyCreated, true, key);
    })
    .catch((errStr) => {
        const p = errStr as Promise<FetchError>;
        p.then((err) => {
            state.showNotification(NotificationStyles.danger, err.message);
            closeNotification(state);
        })
    });
}

// *********
// Button and checkbox handlers
// *********
const handleToggleExpiredKeysCheckbox = (state: APIKeyAdminProps): void => {
    state.toggleShowExpiredApiKeys();
}

const handleEditKeyBtnClick = (state: APIKeyAdminProps): void => {
    const checkedKeyList = state.adminUIState.checkedApiKeys;
    const keyIdList = Object.keys(checkedKeyList);
    let index = -1;
    for (let i = 0, len = keyIdList.length; i < len; i++) {
        const id = keyIdList[i];
        if (checkedKeyList[id]) {
            index = i;
            break;
        }
    }
    
    if (index >= 0) {
        state.toggleAdminPopup(ZFeaturePopupTypes.EditKey, true, index)
    }
}

const handleGenerateKeyBtnClick = (state: APIKeyAdminProps): void => {
    state.toggleAdminPopup(ZFeaturePopupTypes.GenerateKey, true);
}

const handleExpireSelectedKeysBtnClick = (state: APIKeyAdminProps): void => {
    state.toggleAdminPopup(ZFeaturePopupTypes.ExpireKeys, true);
}

export const loadUserApiKeys = async (state: APIKeyAdminProps, onDone?: () => void /*, showExpired?: boolean*/): Promise<void> => {
    const adminState = state.adminUIState;
    if (!adminState || adminState.selectedUser === undefined) {
        return;
    }

    const userId = adminState.selectedUser.user_id;
    if (userId && userId.length > 0) {
        try {
            let url = ZURLS.serverUserApiKeys(userId);
            url = buildUrl(state.authNServerData, url);

            const params = adminState.showExpiredKeys ? 'include_expired=true' : '';
            const keyList: ZRestResponse = await ZGet(url, params);
            state.setUserApiKeyList( keyList as ZApiKey[]);

            if (onDone !== undefined) {
                (onDone)();
            }
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`ApiKeyAdmin.loadUserApiKeys: fetch failed: ${err}`);

                state.showNotification(NotificationStyles.danger, err.message);
                closeNotification(state);
            })
        }
    }
}

export const buildEditApiKeyPopup = (state: APIKeyAdminProps, user: ZUserMin, onDone: () => void): JSX.Element => {
    const {keyExpire, keyPermission} = state.adminUIState;
    const perms = keyPermission === ApiKeyPermission.rw ? Strs.readWrite : Strs.readOnly;
    const permIcon = (keyPermission === ApiKeyPermission.ro) ? 'shield-alt' : 'pencil-alt';
    const now = moment();

    let markup = <span></span>;
    if (keyExpire < now) {
        markup =  (
            <GenDialog 
                show={state.adminUIState.popupOpen}
                title={Strs.editApiKey} 
                msg={Strs.cannotExpireKey}
                css="zmodel-33"
                cancelBtnTxt={MISC_STRINGS.close}
                hideApplyButton={true}
                disableApply={(): boolean => { return false; }}
                onHide={(): void => {closePopup(state)}}
                handleApply={(): void => { closePopup(state); }}
            >
            </GenDialog>)
    } else {
        markup = (
            <GenerateEditApiKey
                show={state.adminUIState.popupOpen}
                edit={false}
                title={Strs.editApiKey}
                applyBtnTxt={Strs.updateKey}
                disableApply={(): boolean => { return canEnableUpdate(state); }}
                onHide={(): void => {closePopup(state)}}
                handleApply={(): void => { updateKey(state, onDone); }}
            >
                { buildCommonEditGeneratePopupParts(state, keyExpire) }
                <div className="key-perm-spacer">{Strs.keyType} 
                    <span><FontAwesomeIcon icon={permIcon as IconProp} />&nbsp;{perms}</span>
                </div>
            </GenerateEditApiKey>
            );
    }

    return markup; 
}

export const buildExpireKeysPopup = (state: APIKeyAdminProps, user: ZUserMin, onDone?: () => void): JSX.Element => {
    if (getNumCheckedAdminItems(state.adminUIState, AdminManagementTypes.apiKeys) === 0) {
        return <span></span>;
    }
    
    const expiredKeyIds = getCheckedApiKeyList(state.adminUIState);
    const allUserApiKeys = state.adminUIState.apiKeyList;
    const expiredKeyNames: string[] = [];

    for (let i = 0, len = expiredKeyIds.length; i < len; i++) {
        const keyId = expiredKeyIds[i];
        const key = allUserApiKeys.find((possibleKey: ZApiKey) => { return possibleKey.api_key_id === keyId });
        if (key !== undefined) {
            expiredKeyNames.push(key.name);
        }
    }

    const expiredKeyList = expiredKeyNames.map((keyName: string) => {
        return (
            <li key={keyName}>{keyName}</li>
        )
    })

    const message = Strs.confirmApiKeyExpiration(state.adminUIState.selectedUser.email, 
                                               (expiredKeyList.length > 1))
        const add = (
        <GenDialog 
            show={state.adminUIState.popupOpen}
            title={Strs.expireKeys} 
            msg={message}
            css="zmodel-33"
            applyBtnTxt={Strs.expireKeys}
            disableApply={(): boolean => { return false; }}
            onHide={(): void => {closePopup(state)}}
            handleApply={(): void => { applyExpireKeys(state, user, onDone); }}
        >
            <div className="list-boundary modal-maxsize">
                <ul>
                {expiredKeyList}
                </ul>
            </div>
        </GenDialog>
    );

    return add;
}

const applyExpireKeys = (state: APIKeyAdminProps, user: ZUserMin, onDone?: () => void): void => {
    if (getNumCheckedAdminItems(state.adminUIState, AdminManagementTypes.apiKeys) === 0) {
        return;
    }
    
    const expiredKeyIds = getCheckedApiKeyList(state.adminUIState);
    const expiredKey = JSON.stringify({ "expires_at": 'now' });

    const expirePromises = expiredKeyIds.map((keyId: string) => {

        const url = buildUrl(state.authNServerData, ZURLS.serverApiKeyUpdate(keyId));
        const promise = ZPut(url, expiredKey);

        promise.then( ( updatedKey: ZRestResponse ) => {
            const key = updatedKey as ZApiKey;
            logger.log(`applyExpireKeys: successfully expired ${key.name}`);
            })
        .catch((errStr) => {
            throw errStr;
        });

        return promise;
    })

    const expirePromise = Promise.all(expirePromises);
    expirePromise.then(() => {
        loadUserApiKeys(state, onDone);
        logger.log('applyExpireKeys: all keys expired');
    })           
    .catch((errStr) => {
        const p = errStr as Promise<FetchError>;
        p.then((err) => {
            state.showNotification(NotificationStyles.danger, err.message);
            closeNotification(state);
        })
    })
}

export const buildNewKeySuccess = (state: APIKeyAdminProps): JSX.Element => {
    const key = state.adminUIState.newKey.api_key;

    const newKey = (
        <GenDialog 
            show={state.adminUIState.popupOpen}
            title={Strs.newApiKey} 
            msg=""
            css="zmodel-33"
            applyBtnTxt={Strs.copyKey}
            cancelBtnTxt={MISC_STRINGS.close}
            disableApply={(): boolean => { return false; }}
            onHide={(): void => {closeNewKeyDialog(state)}}
            handleApply={(): void => { copyToClipboard(state, key) }}
            onCancel={(): void => { closeNewKeyDialog(state) }}
        >

        {Strs.newKeyCreated}
        <div className="new-key-block"><strong>{Strs.keyLabel}</strong> &nbsp;&nbsp;{key}</div>
        <div className="warning-text">{Strs.newKeyCreated1}</div>

        </GenDialog>
    );

    return newKey;
}

const closeNewKeyDialog = (state: APIKeyAdminProps): void => {
    closePopup(state);
    // this.loadUserList();
}

const copyToClipboard = ( state: APIKeyAdminProps, key: string ): void => {
    const success = copyTextToClipboard(key);
    const msg = (success) ? Strs.apiKeyCopied : Strs.apiKeyNotCopied;
    const msgStyle = (success) ? NotificationStyles.success : NotificationStyles.danger ;

    state.showNotification(msgStyle, msg);
    closeNotification(state);
}

const closeNotification = (state: APIKeyAdminProps): void => {
    setTimeout(() => { state.closeNotification(); }, CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT);
}