/* eslint-disable react/prop-types */
import * as React from 'react';

export interface ZStatBoxProps {
    stat: string,
    title: string
}

const ZStatBox: React.FunctionComponent<ZStatBoxProps> = props => {
    return (
        <div className="stat-box">
            <div>{props.stat}</div>
            <div>{props.title}</div>
        </div>
    )
}

export default ZStatBox;
