/* eslint-disable react/prop-types */
import * as React from 'react';
import DateTime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import { DATE_FORMAT } from './constants';

export interface ZDatePickerProps {
    date: moment.Moment;
    handleDateChange: (newDate: moment.Moment ) => void;
    isValidDate: (current: moment.Moment) => boolean;
    css?: string;
}

const ZDatePicker: React.FunctionComponent<ZDatePickerProps> = props => {
    function changeHandler(newFrom: moment.Moment | string ): void {
        // logger.log(newFrom);
        (props.handleDateChange)(newFrom as moment.Moment)
    }

    const css = 'zinput ' + (props.css ? props.css : '');
    return (
        <>
            <DateTime input={true} value={props.date} dateFormat={DATE_FORMAT} timeFormat={false}
                      onChange={changeHandler} initialViewMode="days"
                      isValidDate={(current: moment.Moment): boolean => { 
                                return (props.isValidDate)(current);
                            }
                      }
                      className={css} 
            />&nbsp;
            <FontAwesomeIcon className="cal-padding" size="lg" icon="calendar-alt" />
        </>
    )
}


export default ZDatePicker;