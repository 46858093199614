import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { FetchError, ZServiceExt, ZUserTypes, ServerPlatforms } from '../../data/queryResultDefinitions';
import { ServiceFieldValidate } from '../../data/metricsAndOptionsDefs';

import { /* EProjectState, */ SysFeatureEnums, ZFeaturePopupTypes } from '../../reducers/reducerEnums'
import { buildUrl } from '../../reducers/serverEnvironAccessor'

import { NotificationStyles, MAX_SERVICE_NAME_LENGTH, MAX_DOMAIN_NAME_LENGTH, ServiceTemplateName } from '../../shared/constants';
import { SERVICES_STRINGS as STRs, NAVSTRINGS as NStrs, MISC_STRINGS } from '../../shared/strings'; 
import { ZModalSize } from '../../shared/MiscEnums';
import { ZGet, ZPost } from '../../shared/backend'
import logger from '../../shared/logUtilities';
import GenDialog from '../../shared/GenDialog';
import ZDropDown from '../../shared/ZDropDown';
import ZURLS from '../../shared/urls';

interface CreateServiceFieldValidate extends ServiceFieldValidate {
    isSaveable: boolean
}

export interface CreateServiceProps extends State {
    setNavItem: (analyticsTab: string, feature: SysFeatureEnums) => actionTypes,
    toggleServicePopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean) => actionTypes,
    setServiceOverviewDetails:  (serviceName: string, domainName: string, certListIdx?: number,
                            enableHTTP2?: boolean, enableHTTPSRedirect?: boolean) => actionTypes,

    setProjectPlatforms:  (platforms: ServerPlatforms) => actionTypes,
    createNewService: (service: ZServiceExt) => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes,
    closeNotification: () => actionTypes,
    initSessionData: () => actionTypes,
    redirectTo: (url: string) => actionTypes,
    setServiceCreationTemplate: (templatete: ServiceTemplateName) => actionTypes,
    setPlatformIdx: (platformIdx: number) => actionTypes,
}

class CreateService extends React.Component<CreateServiceProps> {
    componentDidMount() {
        logger.log('mounting ViewServices');
        this.initCreateService();
    }

    render() {
        const popup = this.buildCreatePopup();
        const markup =  (
                <div className="feature-panel">
                    <div className="servicesFeature">
                        <div className="title">{STRs.createNewService}</div>
                    </div>
                {popup}
                </div>
        )

        return markup;
    }

    private initCreateService = async (): Promise<void> => {

        const projectId =this.props.systemNavProjectState._currentProject.projectId;
        const url = buildUrl(this.props.authNServerData, ZURLS.serviceProjectPlatforms(projectId));
        
        try {
            let serverPlatforms: ServerPlatforms = {} as ServerPlatforms;
            const platforms = await ZGet(url, '');
            if (Array.isArray(platforms)) {
                const workingPlatforms = platforms as string[];
                (workingPlatforms as string[]).forEach((pForm: string) => {
                    serverPlatforms[pForm] = { display_name: pForm};
                });
            } else {
                serverPlatforms = platforms as ServerPlatforms
            }
            this.props.setProjectPlatforms(serverPlatforms);
            this.props.toggleServicePopup(ZFeaturePopupTypes.CreateService, true);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`CreateService.initCreateService: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }


    private buildCreatePopup = (): JSX.Element => {
        let message = STRs.createServiceInstruct;
        let title = STRs.createNewService;
        let hideApplyButton = false;
        let dontCloseOnApply = true;
        let closeBtnTxt = MISC_STRINGS.cancelBtn;
        let cssWidth: ZModalSize = 'lg';
        const enableApply = this.isApplyEnabled();
        const servicelimits = this.props.authNServerData.currentOrg.max_services_per_project;
        const numStagingSvcs = this.props.systemNavProjectState._currentProject.stagingEnv.allServiceNames.length;

        let overview = <span></span>;
        if (numStagingSvcs < servicelimits) {
            overview = this.buildOverview(enableApply);
            
        } else {
            message = STRs.cantCreateService;
            title = STRs.tooManyServicesTitle;
            hideApplyButton = true;
            dontCloseOnApply = true;
            closeBtnTxt = MISC_STRINGS.close;
            cssWidth='sm';
            overview = this.buildError(numStagingSvcs, servicelimits);
        }
   
        const createService = (
            <GenDialog 
                show={this.props.serviceUIState.popupOpen}
                title={title} 
                msg={message}
                size={cssWidth}
                css="svc-create-svc-min-width"
                applyBtnTxt={STRs.createService}
                cancelBtnTxt={closeBtnTxt}
                dontCloseOnApply={dontCloseOnApply}
                hideCloseButtonIcon={true}
                hideApplyButton={hideApplyButton}
                disableApply={() => { return ! enableApply.isSaveable}}
                onHide={() => {this.closePopup()}}
                onCancel={() => { this.gotoViewServices()} }
                handleApply={() => { this.applyCreateService(); }}
            >
                <div className="create-service">
                    <div>{overview}</div>
                </div>
            </GenDialog>
        );

        return createService;
    }

    private isApplyEnabled = (): CreateServiceFieldValidate => {
        const { domainName } = this.props.serviceUIState;
        let { serviceName } =  this.props.serviceUIState;
        
        const matchSvc = /(\w|\d|\s|\.|-)*/g;
        const matchHost = /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}$/i;

        const fieldValid: CreateServiceFieldValidate = {} as CreateServiceFieldValidate;
        fieldValid.ddDomainName = false;
        fieldValid.zyTrafficPercentage = false;

        serviceName = serviceName.trim(); 

        let isEnabled: boolean = (serviceName.length > 0 && domainName.length > 0);

        const regMatch = (s: string, rex: RegExp): boolean => {
            const match = s.match(rex);
            const error = ((match !== null) && (match.length === 2) && (match[0].length === s.length));
            isEnabled = isEnabled && error;
            return error;
        }

        if (serviceName.length > 0) {
            fieldValid.serviceName = regMatch(serviceName, matchSvc);
        }

        if (domainName.length > 0) {
            fieldValid.domainName = regMatch(domainName, matchHost);
        }

        fieldValid.isSaveable = isEnabled;
        return  fieldValid;
    }

    private buildError = (numStagingSvc: number, serviceLimits: number): JSX.Element => {
        return (
            <div>
                <div className="pad-top-20">{STRs.maxServicePerProject}&nbsp;&nbsp;{serviceLimits}</div>
                <div className="pad-top-20">{STRs.contactZycada}</div>
            </div>
        )
    }

    private buildOverview = (fieldsValid: ServiceFieldValidate): JSX.Element => {
        const { authNServerData, serviceUIState } = this.props;
        const snameCSS = 'service-friendly-name' + (fieldsValid.serviceName ? '' : ' field-error');
        const dnameCSS = 'service-friendly-name' + (fieldsValid.domainName ? 
                        '' : ' field-error');
        
        const userType: ZUserTypes = ((authNServerData.user !== undefined) && (authNServerData.user.role === ZUserTypes.zadmin)) ? ZUserTypes.zadmin : ZUserTypes.admin;
        let platformInfo = <div></div>;
        // serviceTemplateNone: 'None',

        if (userType === ZUserTypes.zadmin) {
            if (serviceUIState.platforms.length > 0) {
                const platforms = [STRs.noPlatforms];
                const serverPlatforms = serviceUIState.serverPlatforms;
                serviceUIState.platforms.forEach((pform: string) => {
                    const pformType = serverPlatforms[pform];
                    if (pformType !== undefined) {
                        platforms.push(pformType.display_name);
                    }
                });

                const dropdown = ZDropDown(
                    platforms, // dropdown items
                    serviceUIState.platformIdx, // selected item
                    'platformId', // required HTML id
                    (index: number) => { this.props.setPlatformIdx(index) }// select calback function
                )

                platformInfo = (
                    <div className="service-creation-chkboxs">
                            <div>{STRs.selectServicePlatform}</div><div>{dropdown}</div>
                    </div>);
            }
        }   

        logger.log(snameCSS, dnameCSS);
        const overview = (
            <section>
            <div>{STRs.overview}</div>
            <div id="service-creation-def">
                <div><label>{STRs.name}</label></div>
                <div>               
                    <input className={snameCSS} type="text" 
                        value={this.props.serviceUIState.serviceName} placeholder={ STRs.serviceName }
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            this.handleServiceOverviewDetailUpdate('serviceName', e.currentTarget.value)
                        }} maxLength={ MAX_SERVICE_NAME_LENGTH } />
                </div>

                <div><label>{STRs.domainName}</label></div>
                <div>               
                    <input className={dnameCSS} type="text" 
                        value={this.props.serviceUIState.domainName} placeholder={ STRs.domainName }
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            this.handleServiceOverviewDetailUpdate('domainName', e.currentTarget.value)
                        }} maxLength={ MAX_DOMAIN_NAME_LENGTH } />
                </div>
            </div>
            {platformInfo}
            </section>
        );

        return overview;
    }

    private handleServiceOverviewDetailUpdate = ( fieldName: string, value: string | boolean | number) => {
        const { serviceName, domainName} = this.props.serviceUIState

        const oData = {
            serviceName,
            domainName,
        }

        if (oData[fieldName] !== undefined) {
            oData[fieldName] = value
        } else {
            logger.log(`handleServiceOverviewDetails: passed field name.  Was passed ${fieldName}`)
            return;
        }

        this.props.setServiceOverviewDetails(oData.serviceName, oData.domainName);
    }

    private applyCreateService = async (): Promise<void> => {
        const svcState = this.props.serviceUIState;
        const service: ZServiceExt = this.buildService();

        try {
        
        const platform = (svcState.platforms.length > 0 && svcState.platformIdx > 0) ? svcState.platforms[svcState.platformIdx-1] : '';
        let url = buildUrl(this.props.authNServerData, ZURLS.serverServiceMgmtProjectLvl);
        url = platform.length > 0 ? url + `?platform=${platform}` : url;
        const newService = await ZPost(url, JSON.stringify(service));
            const newSvc = newService as ZServiceExt;
            this.props.showNotification(NotificationStyles.success, STRs.serviceCreated(service.service_name));
            this.props.createNewService(newSvc);
            this.props.setNavItem(NStrs.svcMgmt, SysFeatureEnums.editDraftService);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`CreateService.applyCreateServiceFailed: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    private buildService = (): ZServiceExt => {
        const service: ZServiceExt = {} as ZServiceExt;
        const state = this.props.serviceUIState;

        service.service_name = state.serviceName;
        service.zycadized_domain_name = state.domainName;
        service.project_id = this.props.systemNavProjectState._currentProject.projectId;

        return service;
    }

    private gotoViewServices = () => {
        setTimeout(() => { 
                    this.props.toggleServicePopup(ZFeaturePopupTypes.CreateService, false);
                    this.props.closeNotification(); 
                    this.props.setNavItem(NStrs.svcMgmt, SysFeatureEnums.viewServices);
                }, 1);
    }

    private closeNotification = () => {
        setTimeout(() => { this.props.closeNotification(); }, 3000);
    }

    private closePopup  = () => {
        this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false);
    }
}

const stateToProps = (state: State) => {
    return {
        serviceUIState: state.serviceUIState,
        authNServerData: state.authNServerData,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(CreateService);