import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import { MISC_STRINGS as MSTRs } from './strings';
import logger from './logUtilities';

export interface DropdownOptions {
  bIgnoreLabelIfNotAll?: boolean;
  disabled?: boolean;
  label?: string;
  optionName?: string; // Value passed along with index to help id dropdown.
  pullRight?: boolean;
  maxButtonTextLength?: number;
}

const emptyOptions: DropdownOptions = {
    bIgnoreLabelIfNotAll: false,
    disabled: false,
    optionName: '',
    label: '',
    pullRight: false,
    maxButtonTextLength: 32768,
};

export interface DropDwnJSXProps {
    optionList: string[] | JSX.Element[], // dropdown items
    values: string[],
    selectedIdx: number, // selected item
    ctrlId: string, // required HTML id
    handleOptionsSelect: (index: number, optionName?: string, value?: string) => void, // select calback function
    options?: DropdownOptions, // modifications for the dropdown.
}

// tslint:disable-next-line: variable-name
// export const ApiKeyControl: React.FunctionComponent<ApiKeyControlProps> = props => {

const ZDropDownJSX: React.FunctionComponent<DropDwnJSXProps> = props => {
    const getRandomLabel = (): string => {
      return (Math.random() * 1000).toString(10);
    };

    let ddOptions: DropdownOptions;

    if (props.selectedIdx === undefined || props.optionList.length === 0) {
        return <span key={'x' + getRandomLabel()} />;
    }

    const selectedItem = props.optionList[props.selectedIdx];
    let maxTitleLength = 32768;

    if (props.options) {
        ddOptions = props.options;
        ddOptions.optionName = ddOptions.optionName ? ddOptions.optionName : '';
        ddOptions.pullRight = !!ddOptions.pullRight;
        ddOptions.disabled = !!ddOptions.disabled;
        ddOptions.bIgnoreLabelIfNotAll = !!ddOptions.bIgnoreLabelIfNotAll;

        const maxButtonTextLen = props.options.maxButtonTextLength;
        maxTitleLength = maxButtonTextLen && !Number.isNaN(maxButtonTextLen) ? maxButtonTextLen : maxTitleLength;
    } else {
        ddOptions = emptyOptions;
    }

    const dropdownItems: JSX.Element[] = []
    props.optionList.forEach(
        (dropdownItem: string | JSX.Element, index: number) => {
            dropdownItems.push(
              <Dropdown.Item
                key={props.values[index] + index} eventKey={props.values[index]}
                onSelect={(): void => { 
                    const value = (props.values !== undefined && props.values[index] !== undefined) ? props.values[index] : undefined;
                    props.handleOptionsSelect(index, ddOptions.optionName, value); }}
              > {dropdownItem}</Dropdown.Item>
            );
        }
    );

    
    let title: string | JSX.Element= <span></span>;
    if (typeof selectedItem === 'string') {
        if (ddOptions.label) {
        
            title = (ddOptions.bIgnoreLabelIfNotAll && selectedItem !== MSTRs.allOption) ?
                    selectedItem : ddOptions.label + ': ' + selectedItem;
        } else {
            title = selectedItem;
        }
        title = title.length > maxTitleLength ? (title.substring(0, maxTitleLength) + '...') : title;

    } else if (typeof selectedItem === 'object') {
        const myValue = props.values[props.selectedIdx];
        if (ddOptions.label) {
            title = (ddOptions.bIgnoreLabelIfNotAll &&  myValue !== MSTRs.allOption) ?
                    selectedItem : ddOptions.label + ': ' + selectedItem;
        } else {
            title = selectedItem;
        }

    }

    if (!title) {
        logger.alert('ZDropDown: no option title');
    }
    if (!props.ctrlId) {
        logger.alert('ZDropDown: no CtrlId');
    }
    const dropdownDisabled = (props.optionList.length === 1) || ddOptions.disabled;
    const dropdown: JSX.Element = (
        <div className="zdropdown inline" key={props.ctrlId + 'Main'}>
        <DropdownButton className="zdropdown-item" alignRight={ddOptions.pullRight} id={props.ctrlId} 
                        title={title} disabled={dropdownDisabled} key={props.ctrlId}>
            {dropdownItems}
        </DropdownButton>
        </div>
    );

    return dropdown;
};

export default ZDropDownJSX;
