/* eslint-disable react/prop-types */
import * as React from 'react';

export interface RPProps {
    renderHeader: () => JSX.Element,
    renderBody: () => JSX.Element,
    renderFooter?: () => JSX.Element,
    cssClass?: string,              // must include height of panel if you want it to fill the page.
}

// tslint:disable-next-line: variable-name
const ReportPanel: React.FunctionComponent<RPProps> = (props) => {
    const cssClass = props.cssClass ? props.cssClass : 'default-reportpanel-css';
    const panelCSS = 'report-panel ' + cssClass;
    const footer = props.renderFooter ? (<div>{props.renderFooter()}</div>) : <div></div>;

    return (
        <div className={panelCSS}>
            <div>{props.renderHeader()}</div><div></div>
            <div>{props.renderBody()}</div>
            {footer}<div></div>
        </div>
    )
}

export default ReportPanel;