import React, { useState, useRef } from 'react';

import { Certificate } from '../data/queryResultDefinitions'

import { CERT_CHAIN_START, CERT_CHAIN_END, CERT_MAX_LINE_LENGTH, CERT_MAX_PER_CHAIN, CERT_PRIVATE_START, 
         CERT_PRIVATE_END, CERT_RSA_PRIVATE_START, CERT_RSA_PRIVATE_END } from './constants';
import { convertStringToStringArray, isBase64 } from './utilities';
import { ADMIN_STRINGS as Strs } from './strings';
import logger from './logUtilities';
import GenDialog from './GenDialog';

export interface CertEditorProps {
    cert?: Certificate,
    updatingCert: boolean,
    handleApply: (certPrivate: string, certChain: string) => void,
    handleCancel: () => void,
}

const validateCert = (cert: string, isCertChain: boolean): string => {
    if (cert.length === 0) {
        return '';
    } 

    let certValid = '';
    let certStarted = false;
    let shortLine = false;
    let hasCertData = false;
    let numCerts = 0;
    const certKeyStart = isCertChain ? CERT_CHAIN_START : CERT_PRIVATE_START;
    const keyRSAStart = isCertChain ? '**notRSA**' : CERT_RSA_PRIVATE_START;
    let certKeyEnd   = isCertChain ? CERT_CHAIN_END : CERT_PRIVATE_END;

    const certBody = convertStringToStringArray(cert);
    const numCertLines = certBody.length;

    for (let i = 0; i < numCertLines; i++) {
        const line = certBody[i];
        if (line === certKeyStart || line === keyRSAStart) {  // cert or keystart marker
            if (line === CERT_RSA_PRIVATE_START) {
                certKeyEnd = CERT_RSA_PRIVATE_END;
            }
            if (certStarted) {                  // must have an end before another start
                certValid = 'startEndMismatch';
                break;

            } else {
                certStarted = true;
                continue;
            }

        } else if (line === certKeyEnd) {          // cert end marker
            if (!certStarted) {                 // must have a start before an end
                certValid = 'startEndMismatch';
                break;

            } else if (!hasCertData) {
                certValid = 'certNoData';
                break;
            } else {
                numCerts++;
                certStarted = false;
                shortLine = false;
                hasCertData = false;
                continue;
            }

        } else if (line.length > CERT_MAX_LINE_LENGTH) {    // line can't be longer than 64 chars
            certValid = 'lineTooLong';
            break;

        } else {
            hasCertData = true;
            if (line.length < CERT_MAX_LINE_LENGTH) {    // short lines are allowed
                if (shortLine) {                                // but can't have multiple short lines in block
                    certValid = 'misformatted';
                    break;
                } else {
                    shortLine = true;
                }
            }
                                        // regular text lines
            if (!certStarted) {
                certValid = 'misformatted';  
                break;
            }

            if (! isBase64(line)) {                         // must be valid base 64.
                certValid = 'notBase64';
                break;
            }
        }
    }
    if (certValid.length === 0 && certStarted) {                                      // no ending for cert
        certValid = 'startEndMismatch';      
    }
    
    if (certValid.length === 0 && isCertChain && numCerts > CERT_MAX_PER_CHAIN) {
        certValid = 'tooManyInChain';
    }

    if (certValid.length === 0 && !isCertChain && numCerts > 1) {
        certValid = 'tooManyInKey';
    }
    
    return certValid;
}

const CertEditor: React.FunctionComponent<CertEditorProps> = props => {
    logger.log('CertEditor Start');
    const [ certPrivate, setCertPrivate ] = useState('');
    const [ certChain, setCertChain ] = useState('');
    const [ editorVisible,  setEditorVisible ] = useState(true);
    const certChainTextAreaRef = useRef(null)

    let message = Strs.certInstructionMsg;
    let applyBtnTxt = Strs.uploadCert;
    let titleTxt = Strs.uploadNewCert;
    if (props.updatingCert) {
        if (props.cert !== undefined) {
            const cert = props.cert;
            message =  Strs.updateCertInstructionMsg(cert.common_name);
            applyBtnTxt = props.updatingCert ?  Strs.editCert : Strs.uploadCert;
            titleTxt = props.updatingCert ? Strs.updateExistingCert: Strs.uploadNewCert;
        } else {
            logger.alert('CertEditor: Trying to update a cert but the cert was not provided.');
        }
    }
    
    const validChain = validateCert(certChain, true);
    const validKey = validateCert(certPrivate, false);
            
    const buildCertMarkup = (certData: string, validCert: string, instructMsg: string, placeholderTxt: string,
                                handler: (contents: string) => void): JSX.Element => {
        const certErrors = {
            startEndMismatch: Strs.startEndMismatch,
            lineTooLong: Strs.lineTooLong,
            misformatted: Strs.misformatted,
            notBase64: Strs.notBase64,
            certNoData: Strs.certNoData,
            tooManyInChain: Strs.tooManyInChain,
            tooManyInKey:  Strs.tooManyInKey,
        }
    
        let certMsg = ' ';
        if (validCert.length > 0 ) {
            certMsg = certErrors[validCert];
            if (!certMsg) {
                certMsg = validCert;
            }
        }
    
        return (
        <>
            <div className="cert-instruct">{instructMsg}</div>
            <div className="ztext-area">
                <div className="textarea-indent">
                    <textarea value={certData} 
                        className={'fixed-font ' + (validCert.length !== 0 ? 'text-area-error' : '')}
                        onChange={(e: React.FormEvent<HTMLTextAreaElement>): void => {
                                    e.stopPropagation();
                                    (handler)(e.currentTarget.value) }} 
                        rows={8} cols={85} tabIndex={0}
                        placeholder={placeholderTxt} ref={certChainTextAreaRef}>
                    </textarea>
    
                    <div className={'textarea-message ' + (validCert.length !== 0 ? 
                                    'too-many-char-error' : '') } >
                        {certMsg}
                    </div>
                </div>
            </div>
        </>
        );
    }
    
    const chainMarkup = buildCertMarkup(certChain, validChain, Strs.certChainInstructions, 
                                        Strs.keyChainPlaceHolder, setCertChain);
    const privateKeyMarkup = buildCertMarkup(certPrivate, validKey, Strs.certKeyInstructions, 
                                        Strs.privateKeyPlaceHolder, setCertPrivate);
    const applyDisabled = (certChain.length > 0 && validChain.length === 0) && 
                          (certPrivate.length > 0 && validKey.length === 0);
                          
    const certEditor = (
        <GenDialog 
            show={editorVisible}
            title={titleTxt} 
            msg={message}
            size='lg'
            applyBtnTxt={applyBtnTxt}
            disableApply={(): boolean => { return !applyDisabled}}
            onHide={(): void => {setEditorVisible(false); props.handleCancel();}}
            handleApply={(): void => {setEditorVisible(false); props.handleApply(certPrivate, certChain); }}
        >
            <div className="list-boundary">
                {chainMarkup}
                {privateKeyMarkup}
            </div>
        </GenDialog>
    );
    
    return certEditor
}

export default CertEditor;