// import { findIconDefinition, IconDefinition, IconLookup, IconName } from '@fortawesome/fontawesome-svg-core';
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface TabItem {
    selected: boolean,
    text: string,
    icon: IconName,
    iconCSS: string,
}

export interface TabCtrlProps {
    tabData: TabItem[],
    tabSelectedHandler(index: number): void
}

class TabControl extends React.Component<TabCtrlProps> {
    constructor(props: TabCtrlProps) {
        super(props);
        this.handleTabSelect = this.handleTabSelect.bind(this);
    }

    handleTabSelect( index: number ): void {
        if (! this.props.tabData[index].selected) {         // only call handler if selection changed
            (this.props.tabSelectedHandler)(index);
        }
    }

    render(): JSX.Element {
        const tabs: JSX.Element[] = [];

        for (let i = 0, len = this.props.tabData.length; i < len; i++) {
            const tabItem: TabItem = this.props.tabData[i];
            let tabText;
            const tabCSS = (tabItem.selected) ? 'selected-tab' : 'unselected-tab';
            
            if (tabItem.icon && tabItem.icon.length > 0) {
                // const icnLookup: IconLookup = { prefix: 'fas', iconName: tabItem.icon };
                // const icnDefinition: IconDefinition = findIconDefinition(icnLookup)
                tabText = (<div onClick={() => { this.handleTabSelect(i); }}>
                            <span className={tabItem.iconCSS}>
                            <FontAwesomeIcon icon={tabItem.icon} /></span> {tabItem.text}
                        </div>);
            } else {
                tabText = (<div onClick={() => { this.handleTabSelect(i); }}>
                <span className={tabItem.iconCSS}></span> {tabItem.text}</div>)
            }
            const tab = (
                <div className={tabCSS} key={'tab' + i}>
                    {tabText}
                </div>
            );
            tabs.push(tab);
        }

        return (
            <div className="tab-control" key="tabControl">
                {tabs}
            </div>
        )
    }
}

export default TabControl;