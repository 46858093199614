import { StringMap } from './metricsAndOptionsDefs';
import { SERVICES_STRINGS as SVC_STRINGS, MISC_STRINGS } from '../shared/strings';

// tslint:disable-next-line: variable-name
export const SvcStatusMap: StringMap = {
    'draft': SVC_STRINGS.draft,
    'publishing': SVC_STRINGS.publishing,
    'published': SVC_STRINGS.published,
    'publish_failed': SVC_STRINGS.publish_failed,
    'deleting': SVC_STRINGS.deleting,
    'delete_failed': SVC_STRINGS.delete_failed,
    'active': SVC_STRINGS.active,
}

// The method names are standard in any language so we don't have to put them in a string table.
export const reqMethods: string[] = [ MISC_STRINGS.allOption, "GET", "POST", "PUT", "DELETE", "OPTIONS", "HEAD",
                     "PATCH", "CONNECT", "TRACE" ];