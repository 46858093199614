import * as React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { State } from '../../App';
import { LogData, LogEntry, LogFilterExt, ZRestResponse, LogHeaderEntries, FetchError } from 
        '../../data/queryResultDefinitions';
import { StringMap } from '../../data/metricsAndOptionsDefs';
import { reqMethods } from '../../data/staticData';

import { HeaderFilterItem, LogHeaderDisplay, LogRowData,  ResponseCodeData, } from '../../reducers/logState'
import { EProjectState, HeaderFilterOpsIndexEnum, loginState, SysFeatureEnums, ZSortDirection, ZSortFields } from 
        '../../reducers/reducerEnums';
import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { getProjectDspFeatures, getQueryServiceIds } from '../../reducers/uiAccessors';

import PaginationCtrl from '../../shared/PaginationCtrl';
import { LogDummyData, PAGE_SIZE, ProjDTypeFeatures  } from '../../shared/constants'
import logger from '../../shared/logUtilities';
import { LOG_STRINGS as STRs, MISC_STRINGS } from '../../shared/strings';
import { ShortenedUrl, truncateUrl, renderRangeInfo } from '../../shared/utilities';

import { ZPost } from '../../shared/backend';
import ZURLS from '../../shared/urls';

import LogOptions from './LogOptions';
import logRow from './logRow';

interface LogsProps extends State {
    initLogOptions: () => actionTypes,
    changeProjects: (projectId: string) => actionTypes,
    logDataObtained: (status: boolean, logRowData: LogRowData[], totals: number) => actionTypes,
    setLogRowState: (rowIdx: number, rowOpen: boolean, showReqHeader: boolean, 
                     showRespHeader: boolean) => actionTypes,
    setCurrentPage: ( pageNum: number, component: string ) => actionTypes,
    applySettings: () => actionTypes,
    sortTable: (feature: SysFeatureEnums, tableName: string, fieldName: string | ZSortFields, direction: ZSortDirection) => actionTypes,
    // resetProject: (project: ZProject) => actionTypes,
}

interface StdParams {
    serviceId: string[],
    start: number,
    end: number
}

interface LogQueryData {
    client_ip: string,
    origin_ip: string,
    response_code: string,
    asset_url: string,
    request_method: string,
    header_filters: LogFilterExt[],
    start_record: number,
    num_records: number,
    sort: ZSortDirection,
    field: ZSortFields,
}

class Logs extends React.Component<LogsProps> {
    constructor(props: LogsProps) {
        super(props);
        this.handleApply = this.handleApply.bind(this);
    }

    public componentDidMount() {
        if (this.props.authNServerData.isLoggedIn === loginState.loggedIn) {
            const systemState = this.props.systemNavProjectState;

            if (this.props.logsUIState.logState === EProjectState.projectsUpdated) {
                const currentProj = systemState._currentProject;
                this.props.changeProjects(currentProj.projectId);
            } else {
                this.props.initLogOptions();
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public componentDidUpdate(prevProps: LogsProps) {
        const systemState = this.props.systemNavProjectState;
        const currentProject = systemState._currentProject;

        if (! (systemState.projectsLoaded && systemState.servicesLoaded)) {
            return;
        }
        
        let projectState = this.props.logsUIState.logState;

        if (currentProject.servicesList.length <= 0) {
            projectState = EProjectState.ready;
        }

        if (systemState.systemUIState === EProjectState.serviceChanged) {
            projectState = EProjectState.serviceChanged;
        }

        if (systemState.systemUIState === EProjectState.serviceEnvironmentChanged) {
            projectState = EProjectState.serviceEnvironmentChanged;
        }

        switch (projectState) {
            case EProjectState.projectsUpdated: 
                this.props.changeProjects(currentProject.projectId);
                break;

            case EProjectState.serviceEnvironmentChanged:
                this.props.applySettings();
                // const resetProj = projects.currentProject.project;
                // this.props.resetProject(resetProj);
                break;

            case EProjectState.newProjectLoaded:
            case EProjectState.tabChanged:
                this.props.initLogOptions();
                break;
                
            case EProjectState.initComplete:
            case EProjectState.serviceChanged:
                this.props.applySettings();
                break;

            case EProjectState.userClickedApply:
            case EProjectState.userChangedPages:
                // time to get metric data from the server
                this.updatePage()
                break;

            case EProjectState.ready:
            default:
                break;
        }
    }

    render() {
        let logs;
        const systemState = this.props.systemNavProjectState;
        const currentProject = systemState._currentProject;

        const logDataPanel: JSX.Element = this.renderLogDataPanel();
        if (currentProject.servicesList.length > 0) {
            logs = (
                <>
                    <LogOptions applyHandler={this.handleApply}  {...this.props} />
                    {logDataPanel}
                </>
                );
        } else {
            logs = (
                <>
                <div className="no-services"> 
                    { MISC_STRINGS.descNoDeployedServices(currentProject.currentServiceEnvironment) }
                </div>;
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                </>
                )
        }

        return (
            <div className="feature-panel">
                <div className="logs">
                    {logs}
                </div>
            </div>
        )
    }

    private renderLogDataPanel = () => {
        const logsUIState = this.props.logsUIState;
        let copyrightCSS = 'copyright';
        const header = this.buildLogsTableHeader();

        const displayFeatures: ProjDTypeFeatures = getProjectDspFeatures(this.props.systemNavProjectState);
        const visibilityCSS = (displayFeatures.loggingObscurred) ?  'poc-blur' : ''; // isPOCBlur()
        const noDataForPOC = (displayFeatures.loggingObscurred) ? 
                            <div className="poc-msg2"><div>{MISC_STRINGS.dataNotAvailable}</div></div> : '';
                            const rowData: JSX.Element[] = (logsUIState.loading) ? [<span key="nodata"></span>] :
            logsUIState.logRowData.map((row, i) => {
                return logRow(row, i, this.rowToggle, this.reqToggle, this.respToggle);
        });
        if (rowData.length > 0 && !logsUIState.loading) {
            rowData.push(<div key="hrule" className="lrow"><div className="hrule"/></div>);
        }

        if (rowData.length <= 0) {
            copyrightCSS = 'copyright-bottom';
        }

        const logMgmtLine = this.renderManagementLine();
        return (
        <div id="log-content-data" className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className={visibilityCSS}>
                        {logMgmtLine}
                        <div>
                            {header}
                            {rowData}
                        </div>
                    </div>
                    {noDataForPOC}
                </div>
            </div>
            <div className={copyrightCSS} dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
        </div>);
    };

    private buildLogsTableHeader = (): JSX.Element => {
        const logsUIState = this.props.logsUIState;
        const { sortField, sort} = logsUIState;
        const sortIcon = (sort === ZSortDirection.asc) ? "sort-up" : "sort-down";
        let timeSort = "sort-down";
        let resptimeSort = "sort";
        if (sortField === ZSortFields.timestamp) {
            timeSort = sortIcon;
            resptimeSort = "sort"
        } else {
            timeSort = "sort";
            resptimeSort = sortIcon;
        }

        const header: JSX.Element = (logsUIState.loading  || logsUIState.logRowData.length === 0) ? <span></span> : (
            <div className="log-row tbl-header-bkg">
                <div> </div>
                <div onClick={() => { this.handleChangeSort(ZSortFields.timestamp); }} className="nowrap"> <FontAwesomeIcon icon={timeSort as IconProp} /> {STRs.timestamp}</div>
                <div>{STRs.requestMethod}</div>
                <div>{STRs.requestUri}</div>
                <div>{STRs.clientIP}</div>
                <div>{STRs.responseCode}</div>
                <div>{STRs.cacheStatus}</div>
                <div  onClick={() => { this.handleChangeSort(ZSortFields.responseTime); }} className="rtime-log-column"> 
                    <div><FontAwesomeIcon icon={['fas', resptimeSort as IconName]} /></div><div className="nowrap">{STRs.responseTime}</div>
                </div>
            </div>
        );

        return header;
    }

    private renderManagementLine = (): JSX.Element => {
        const logsUIState = this.props.logsUIState;
        const pagingData = logsUIState.logPagingData;

        let leftPanel: JSX.Element;
        let centerPanel: JSX.Element = <div/>;
        let rightPanel: JSX.Element = <div/>;
        if (logsUIState.loading) {
            leftPanel =  (<div>
                            <FontAwesomeIcon spin={true} icon={['fas', 'sync']} />&nbsp;&nbsp;{MISC_STRINGS.loading}
                         </div>);
        } else {
            leftPanel = (<div className="compensate-4-pctrl">{MISC_STRINGS.lastUpdateLabel} 
                        { logsUIState.updateTimestamp.format('MM/DD/YYYY hh:mm:ss A') }&nbsp;&nbsp;</div>)
        }

        let errors = <span></span>;        
        if (pagingData.totalItems > 0) {
            const rangeInfo = renderRangeInfo(pagingData);
            if (pagingData.pageSize < pagingData.totalItems) {
                 centerPanel = <div className="compensate-4-pctrl">{rangeInfo}</div>
                 if (pagingData.pageSize < pagingData.totalItems) {
                    rightPanel = <PaginationCtrl pData={pagingData} handlePageChange={(pageNum: number): void => { this.changePage(pageNum)}} />
                 }
            } else {
                rightPanel = <div className="compensate-4-pctrl">{rangeInfo}</div>
            }
        } else if (!logsUIState.loading) {
            errors = <div className="col-sm-12" id="serverError"><div>{MISC_STRINGS.noData}</div></div>;
        }

        if (!pagingData.isGoodData) {
            errors = <div className="col-sm-12" id="serverError"><div>{STRs.serverError}</div></div>;
        }

        return (
            <div className="row date-apply">
                <div className="col-sm-12" id="loadedData">
                    {leftPanel}
                    {centerPanel}
                    {rightPanel}
                </div>
                {errors}
            </div>
        );
    }



    // event handlers
    protected handleApply(): boolean {
        this.props.applySettings();
        return true;
    }

    private handleChangeSort = (field: ZSortFields): void => {
        const { sortField, sort} = this.props.logsUIState;

        let newSort = sort;
        if (field === sortField) {
            newSort = sort === ZSortDirection.asc ? ZSortDirection.desc : ZSortDirection.asc;
        } else {
            newSort = ZSortDirection.desc;
        }

        this.props.sortTable(SysFeatureEnums.logsAnl, 'logs', field, newSort);

    }
    private changePage = (pageNum: number) => {
        // console.log('change page to ' + pageNum);
        this.props.setCurrentPage(pageNum, 'logs');
    }

    private rowToggle = (rowNum: number) => {
        const rowData: LogRowData = this.props.logsUIState.logRowData[rowNum];
        if (rowData) {
            this.props.setLogRowState(rowNum, !rowData.open, rowData.showReqHeaders, rowData.showRespHeaders);
        }
    }

    private reqToggle = (rowNum: number) => {
        const rowData: LogRowData = this.props.logsUIState.logRowData[rowNum];
        if (rowData) {
            this.props.setLogRowState(rowNum, rowData.open, !rowData.showReqHeaders, rowData.showRespHeaders);
        }
    }

    private respToggle = (rowNum: number) => {
        const rowData: LogRowData = this.props.logsUIState.logRowData[rowNum];
        if (rowData) {
            this.props.setLogRowState(rowNum, rowData.open, rowData.showReqHeaders, !rowData.showRespHeaders);
        }
    }

    // get data from server and process it.
    private updatePage = () => {
        const displayFeatures: ProjDTypeFeatures = getProjectDspFeatures(this.props.systemNavProjectState);
        const { logsUIState, systemNavProjectState } = this.props;
        if (! displayFeatures.loggingObscurred) {
            const stdParams: StdParams = {
                serviceId: [] as string[],
                start: logsUIState.currentOpts.from.valueOf(),
                end: logsUIState.currentOpts.to.valueOf(),
            };
            let serviceIds = getQueryServiceIds(systemNavProjectState);
            serviceIds = serviceIds !== undefined ? serviceIds : ''
            stdParams.serviceId = serviceIds.split(',');
            const query: LogQueryData = this.buildLogsQuery(stdParams);
            this.getLogs(stdParams, query);

        } else {
            const logData: LogEntry[] = [] as LogEntry[];
            for (let i = 0; i < 5; i++) {
                const o = {...LogDummyData};
                logData.push(o);
            }
            const rowData: LogRowData[] = this.buildLogData(logData);
            this.props.logDataObtained(true, rowData, 5);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private buildLogsQuery = (stdParams: StdParams): LogQueryData => {
        const logsUIState = this.props.logsUIState;
        let statusCode = '';

        const statusData: ResponseCodeData = logsUIState.responseCodeData.type ? 
                                             logsUIState.currentOpts.responseCodeData : 
                                             {type: 'blank'} as ResponseCodeData;
        switch (statusData.type) {
            case 'single':
                statusCode = statusData.codes[0];
                break;
            case 'range':
                statusCode = statusData.codes.join('-');
                break;
            case 'array': {
                    const codes = statusData.codes.map(code => code.toString().trim());
                    statusCode = codes.join(',');
                }
                break;
            case 'blank':
                statusCode = '';
                break;
            default:
                logger.alert('bad status code type');
                break;
        }

        const headerFilters: LogFilterExt[] = [] as LogFilterExt[];
        const opMapping = ['none', 'is', 'is_not', 'exists', 'does_not_exist'];
  
        const makeFilterEntry = (entry: HeaderFilterItem) => {
            const name = (entry.isRequest ? STRs.request : STRs.response) + entry.filterName
            if (entry.isEnabled) {
                const value: LogFilterExt = {name, operation: opMapping[entry.filterOp + 1] };
                if (entry.filterOp >= HeaderFilterOpsIndexEnum.opIs || 
                    entry.filterOp >= HeaderFilterOpsIndexEnum.opIsNot) {
                    value.value = [entry.filterValue as string]; // for 'none' (which is -1) to work in the array
                }
                headerFilters.push(value);
            }
        };

        const logFilters = logsUIState.currentOpts.logFilters;
        for (let i = 0, len = logFilters.length; i < len; i++) {
            makeFilterEntry(logFilters[i]);
        }

        let requestMethod = '';
        if (logsUIState.requestMethodIdx !== 0) {
            requestMethod = reqMethods[logsUIState.currentOpts.requestMethodIdx]
        }

        return {
            client_ip: logsUIState.currentOpts.clientIP,
            origin_ip: logsUIState.currentOpts.originIP,
            response_code: statusCode,
            asset_url: logsUIState.currentOpts.assetURL,
            header_filters: headerFilters,
            start_record: (logsUIState.logPagingData.currentPage - 1) * PAGE_SIZE,
            num_records: PAGE_SIZE,
            request_method: requestMethod,
            sort: logsUIState.sort,
            field: logsUIState.sortField
        }
    }

    private getLogs = (stdParams: StdParams, query: LogQueryData) => {
        // console.log('getLogs', stdParams, query);
        const {start, end} = stdParams;
        const serviceIds = stdParams.serviceId.join(',');

        let url = `${ZURLS.serverLogs}?service_id=${serviceIds}&start_epochmillis=${start}&end_epochmillis=${end}`;
        const queryParams: string  = JSON.stringify(query);
        url = buildUrl(this.props.authNServerData, url);
        const promise = ZPost(url, queryParams);

        promise.then( ( response: ZRestResponse ) => {
                const logdata: LogData = response as LogData;
                if (logdata && Array.isArray(logdata.logs)) {
                    const rowData: LogRowData[] = this.buildLogData(logdata.logs);
                    this.props.logDataObtained(true, rowData, logdata.total);
                } else {
                    this.props.logDataObtained(false, [], -1);
                }
            });
        promise.catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log('logsCtrl.logs: Fetch failed - ' + err.message);
            })
            this.props.logDataObtained(false, [], -1);
        });
    }

    private buildLogData = (logData: LogEntry[]): LogRowData[] => {
        const logsUIState = this.props.logsUIState;
        const logRowData: LogRowData[] = [] as LogRowData[];

        const exclude: StringMap = {'user-agent': '', 'x-xy-true-origin': '', 'cache_control': '', 
                                    'x-zy-cache-hit': '', 'cookie': '', 'set-cookie': ''};

        const reqFilterMap: LogHeaderEntries = {};
        const respFilterMap: LogHeaderEntries = {};

        const filters = logsUIState.logFilters;
        for (let i = 0, len = filters.length; i < len; i++) {
            const item = filters[i];
            if (item.isEnabled) {
                const name = item.filterName;
                const rmap = (item.isRequest) ? reqFilterMap : respFilterMap;
                rmap[name] = '';            // mark filter name in appropriate map
            }
        }

        for (let i = 0, len = logData.length; i < len; i++) {
            const row: LogEntry = logData[i];

            let maxAge = '--';
            let trueOrigin = '';
            let cacheStatus: string = row.cache_status as string;
            const respHeaders: LogHeaderEntries = row.response_headers as LogHeaderEntries;
            if (row.response_headers) {
                if (respHeaders['Cache-Control']) {
                    maxAge = respHeaders['Cache-Control'];
                    const eqIdx = maxAge.indexOf('=');
                    if (eqIdx > 0) {
                        maxAge = maxAge.substr(eqIdx + 1);
                    }
                }

                trueOrigin = row.response_headers['X-Zy-True-Origin'] ? row.response_headers['X-Zy-True-Origin'] : '';
                if (trueOrigin.length > 0) {
                    const idx = trueOrigin.indexOf(':');
                    if (idx > 0) {
                        trueOrigin = trueOrigin.substring(0, idx);
                    }
                }

                if (!cacheStatus || cacheStatus.length === 0) {
                    cacheStatus = row.response_headers['X-Zy-Cache-Hit'];
                    cacheStatus = cacheStatus ? cacheStatus : '';
                }
            }

            const zyError: string = row.zy_error ? row.zy_error as string : '';
            let userAgent = (row.request_headers && row.request_headers['User-Agent']) ? 
                            row.request_headers['User-Agent'] : '';
            if (userAgent.length === 0) {
                userAgent = (row.request_headers && row.request_headers['user-agent']) ? 
                            row.request_headers['user-agent'] : '';
            }
            const reqURL: ShortenedUrl = truncateUrl(row.request_uri as string);

            const reqHeaderData = this.buildHeaderData(row.request_headers as LogHeaderEntries, exclude, reqFilterMap);
            const respHeaderData = this.buildHeaderData(row.response_headers as LogHeaderEntries, exclude, respFilterMap);

            logRowData.push({
                timestamp: moment(row['@timestamp'] as string),
                requestMethod: row.request_method as string,
                requestURI: reqURL.url,
                responseCode: row.response_status as number,
                cacheStatus,
                responseTime: row.response_time_ms as number,
                clientIP: row.client_ip as string,
                originIP: trueOrigin as string,
                zyError: zyError,
                userAgent,
                maxAge,
                fullRequestURI: reqURL.fullUrl,
                zyPragma: '',
                pushIndicator: (row.is_pushed !== undefined) ? 'true' : 'false',
                responseSize: this.buildSizeLabel(row.response_size as number),
                reqHeaderData,
                respHeaderData,
                open: false,
                showReqHeaders: false,
                showRespHeaders: false
            })
        }

        return logRowData;
    }

    private buildHeaderData(recData: LogHeaderEntries, exclude: StringMap, 
                            filters: StringMap): LogHeaderDisplay[] {

        const headerData: LogHeaderDisplay[] = [] as LogHeaderDisplay[];
        if (recData !== undefined) {
            const keys = Object.keys(recData);
            for (let i = 0, len = keys.length; i < len; i++) {
                const name = keys[i];
                const lcKey = name.toLowerCase();
                if (!exclude[lcKey]) {
                    if (recData[name] !== undefined) {
                        // let value = recData[name] ? recData[name] : ''
                        if (recData[name]) {
                            const value = recData[name];
                            const css = filters[name] !== undefined ? 'filtered' : '';
                            if (exclude[lcKey] === undefined) {
                                headerData.push({
                                    name,
                                    value,
                                    css
                                });
                            }
                        }
                    }
                }
            }
        }
        return headerData;
    }

    private buildSizeLabel = (num: number) => {
        let label = '0';
        try {
            if (num >= Math.pow(10, 6)) {
                label = (num / Math.pow(10, 6)).toFixed(3) + MISC_STRINGS.mbyte;
            } else if (num >= Math.pow(10, 3)) {
                label = (num / Math.pow(10, 3)) + MISC_STRINGS.kbyte;
            } else {
                label = num + MISC_STRINGS.byte;
            }
        } catch (e) {
            label = num + MISC_STRINGS.byte;
        }

        return label
    };
}

const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        logsUIState: state.logsUIState,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(Logs);
