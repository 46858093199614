import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actionTypes } from '../actions/actionCreatorTypes';
import * as actionCreators from '../actions/actionCreators';

import { State } from '../App';
import { CachePurgeRequest, CachePurgeReqStatusExt, CacheUriCodes, ZRestResponse, FetchError, 
         ZUserMin, ZUserTypes } from  '../data/queryResultDefinitions';

import NewCacheRequest from './cacheComps/NewCacheRequest';

import { buildUrl } from '../reducers/serverEnvironAccessor';
import { loginState, EProjectState, ZEnvironments } from '../reducers/reducerEnums';
import { CachePurgeReqStatus, PurgeType, TableOpenState, State as CacheUIState } from '../reducers/cacheState'
import { State as systemNavProjectState } from '../reducers/systemState';

import { CACHE_STRINGS as STRs, MISC_STRINGS, SHARED_JOB_STRINGS as JSTRs } from '../shared/strings';
import ZUrls from '../shared/urls';
import { ZGet, ZPost } from '../shared/backend';
import { CACHE_REFRESH_INTERVAL, DATETIME_FORMAT, NotificationStyles, CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT } from '../shared/constants';
import { ZButton } from '../shared/ZButton';
// import logger from '../shared/logUtilities';

import { convertStringToStringArray, copyTextToClipboard } from '../shared/utilities';
import { ProjUriCode } from '../data/queryResultDefinitions';

/* eslint-disable @typescript-eslint/member-delimiter-style */
export interface CacheProps extends State {
    cacheCmdStarted: () => actionTypes,
    cacheCmdCompleted: (data: CachePurgeReqStatusExt[] | undefined, success: boolean, msg: string) => actionTypes,
    cacheInitOptions: (environment: ZEnvironments, service: string) => actionTypes,
    changeProjects: (projectId: string) => actionTypes,
    setCacheRowState: (rowNum: number) => actionTypes,
    
    initCacheModal: (uriCodes: ProjUriCode) => actionTypes,
    closeCacheModal: () => actionTypes,
    // setCacheModalData: (environment: string, purgeType: PurgeType, serviceIdx: number, uriCodeIdx: number, 
    //                     urls: string) => actionTypes,
    submitCachePurgeRequest: () => actionTypes,
    setCacheSetIntervalId: (intervalId: number) => actionTypes,
    refreshCacheStatus: () => actionTypes,
    featureReady: () => actionTypes,

    showNotification: (style: NotificationStyles, message: string) => actionTypes,
    closeNotification: () => actionTypes,
}

interface CachePurgeUriCode {
    uri_code: string;
}

interface CreatePurgeRequestData {
    service_id: string,
    uris?:  string[],
    uri_codes?: CachePurgeUriCode[],
    cache_tags?: string[],
}
/* eslint-enable @typescript-eslint/member-delimiter-style */

const statusMapping = {
    "aborted": JSTRs.aborted,
    accepted: JSTRs.accepted,
    failed: JSTRs.failed,
    "in_progress": JSTRs.in_progress,
    success: JSTRs.success,
};

// eslint-disable-next-line 
class Cache extends React.Component<any, CacheProps> {
    public componentDidMount(): void {
        if (this.props.authNServerData.isLoggedIn === loginState.loggedIn) {
            const systemNavProjectState = this.props.systemNavProjectState;

            if (this.props.logsUIState.logState === EProjectState.projectsUpdated) {
                const currentProj = systemNavProjectState._currentProject;
                this.props.changeProjects(currentProj.projectId);
            } else {
                this.initCacheData();
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public componentDidUpdate(prevProps: CacheProps): void {
        const systemState = this.props.systemNavProjectState;
        const { systemUIState } = this.props.systemNavProjectState;
        let projectState = this.props.cacheUIState.cacheState;

        if (! (systemState.projectsLoaded && systemState.servicesLoaded)) {
            return;
        }

        if (systemState._currentProject.servicesList.length <= 0) {
            projectState = EProjectState.ready;
        }

        const impSysStates = {
            [EProjectState.serviceEnvironmentChanged]: EProjectState.serviceEnvironmentChanged,
            [EProjectState.servicesUpdated]: EProjectState.servicesUpdated,
            [EProjectState.serviceChanged]: EProjectState.serviceChanged,
        }
        projectState = (impSysStates[systemUIState] !== undefined) ? systemUIState : projectState;

        switch (projectState) {
            case EProjectState.projectsUpdated: {        // new user logged in project list set
                    const currentProj = systemState._currentProject;
                    this.props.changeProjects(currentProj.projectId);
                }
                break;

            case EProjectState.servicesUpdated:
            case EProjectState.serviceChanged:
                this.initCacheData();
                this.props.featureReady();
                break;

            case EProjectState.newProjectLoaded:
            case EProjectState.tabChanged:
                this.initCacheData();
                break;

            case EProjectState.initComplete:
            case EProjectState.reloadData:
                this.updateCacheRequestStatus()
                break;

            case EProjectState.ready:
            default:
                break;
        }
    }

    public componentWillUnmount(): void {
        this.clearInterval()
    }

    private clearInterval = (): void => {
        const interval: number =  this.props.cacheUIState.intervalId;
        if (interval >= 0) {
            // console.log(`clearInterval: Clearing interval ${interval}`);
            clearInterval(interval);
            this.props.setCacheSetIntervalId(-1);
        }
    }

    private setRefresh = (): void => {
        let interval: number =  this.props.cacheUIState.intervalId;

        // console.log(`setRefesh: intervalId is ${interval}`)
        if (interval <= 0) { 
            // console.log(`setRefresh: Setting interval`)
            interval = window.setInterval(() => {
                if (! this.props.cacheUIState.showModal) {
                    this.props.refreshCacheStatus();
                }
            }, CACHE_REFRESH_INTERVAL);
            
            if (interval > 0) {
                // console.log(`setRefresh: new interval is ${interval}`)
                this.props.setCacheSetIntervalId(interval);
            }
        }
    } 

    public render(): JSX.Element {
        const cacheUIState = this.props.cacheUIState;
        let purgeData: JSX.Element = <span></span>;
        let copyrightCSS = 'copyright-bottom';
        const user = this.props.authNServerData.user as ZUserMin;
        const userType: ZUserTypes = user.role;

        if (cacheUIState.cacheCmdSuccess) {
            if (cacheUIState.reqStatusData.length === 0) {
                purgeData = (<div className="no-requests">{STRs.noRequests}</div>);
            } else {
                const header = this.buildPurgeStatusTableHeader();
                const purgeDetail = this.buildPurgeDetail();
                purgeData = (
                    <>
                    {header}
                    <div className="purgeRequestTbl">
                    {purgeDetail}
                    </div>
                    </>);
                copyrightCSS = 'copyright';
            }
        } else {
            purgeData = <div>{MISC_STRINGS.serverError}</div>
        }

        const button = (userType !== ZUserTypes.user) ? (                 
            <ZButton btnCls="cachePurge" onClick={this.handleNewRequestBtn} btnType="primary">
                {STRs.newPurgeRequest}
            </ZButton> ) : <div></div>;
        const dialog = (cacheUIState.showModal) ? this.buildPurgeReqDialog() : <div></div>;

        return (
            <div className="feature-panel">
                <div className="servicesFeature">
                    <div className="cache">
                        <div className="title">{STRs.statusRecentPurgeReq}</div>
                        <div className="refresh">
                            <ZButton onClick={() => { this.props.refreshCacheStatus() }}>{MISC_STRINGS.refresh}</ZButton> 
                            <span className="padding">{MISC_STRINGS.lastUpdateLabel}&nbsp;
                            {cacheUIState.updateTimestamp.format(DATETIME_FORMAT)}</span>
                        </div>
                        {purgeData}
                        {button}
                    </div>
                    <div className={copyrightCSS} dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                </div>
                {dialog}
            </div>
        )
    }

    private buildPurgeReqDialog = (): JSX.Element => {
        const cacheUIState: CacheUIState = this.props.cacheUIState;
        const {modalEnv,  services, modalServiceIds, svcIdsToCacheTagHeader} = cacheUIState;
        const serviceId = this.getSelectedServiceId();

        let svcListIdx = modalServiceIds.findIndex((svcId: string) => { return svcId === serviceId });
        svcListIdx = (svcListIdx === -1) ? 0 : svcListIdx + 1;

        return (
            <NewCacheRequest onHide={this.hideModal} 
                environment={modalEnv} 
                serviceDDownList={services}
                servicesList={modalServiceIds}
                serviceIdx={svcListIdx} 
                svcIdsToCacheTagHeader={svcIdsToCacheTagHeader}
                onCreatePurgeRequest={(purgeType: PurgeType, serviceId: string, purgeInfo: string): void => {
                    this.onCreatePurgeRequest(purgeType, serviceId, purgeInfo)}}>
            </NewCacheRequest> );
    }

    private hideModal = (): void => {
        this.props.closeCacheModal();
    }

    private handleNewRequestBtn = (): void => {
        // this.clearInterval()
        this.props.initCacheModal(this.props.systemNavProjectState._currentProject.uriCodes);
    }

    
    private updateCacheRequestStatus = (): void => {
        const projectId = this.props.systemNavProjectState._currentProject.projectId;
        const url = buildUrl(this.props.authNServerData, `${ZUrls.serverCache}/${projectId}`);
        const promise = ZGet(url, '');

        promise.then( ( response: ZRestResponse ) => {
            const cacheReqStatus: CachePurgeReqStatusExt[] = response as CachePurgeReqStatusExt[];
            this.props.cacheCmdCompleted(cacheReqStatus, true, '');
            if (this.props.cacheUIState.cacheState !== EProjectState.initComplete) {
                this.setRefresh();
            }
        })
        promise.catch((restError) => {
            const fError = restError as Promise<FetchError>;
            this.serverError(fError);
            this.props.cacheCmdCompleted(undefined, false, '');
            this.setRefresh();
        });
    }

    private initCacheData = (): void => {
        const systemState = this.props.systemNavProjectState;

        if (systemState.servicesLoaded) {
            const serviceId = this.getSelectedServiceId();
            this.props.cacheInitOptions(systemState._currentProject.currentServiceEnvironment, serviceId);
        }
    }

    private getSelectedServiceId = (): string => {
        const systemState = this.props.systemNavProjectState;
        let serviceId = '';

        if (systemState.servicesLoaded) {
            const project = systemState._currentProject;
            const currentEnv = project.currentEnv;
            
            if (systemState.setCurrentServiceEvtIdx !== 0) {
                const serviceName = systemState.serviceEvents[systemState.serviceEvtIdx];
                serviceId = currentEnv.serviceNameToId[serviceName];
            }
        }

        return serviceId;
    }

    private buildPurgeStatusTableHeader = (): JSX.Element => {
        const cacheUIState = this.props.cacheUIState;
        const tableOpenState = cacheUIState.reqTblOpenState;
        const openIconState = {
            [TableOpenState.open]: {icon: "caret-square-down", css: '' },
            [TableOpenState.mixed]: {icon: "caret-square-down", css: ' partial-open-tbl' },
            [TableOpenState.closed]: {icon: "caret-square-right", css: '' },
        }
        const rowOpenIcon = ["fa", openIconState[tableOpenState].icon];
        const header: JSX.Element = (cacheUIState.isLoading || cacheUIState.reqStatusData.length === 0) ? 
                <span></span> : 
                (
                    <div key="header" className="cache-row cache-tbl-header-bkg">
                        <div onClick={(): void => this.handleRowClick(-1)}>
                            <span className={'open-icon-header-spacing' + openIconState[tableOpenState].css}><FontAwesomeIcon icon={rowOpenIcon as IconProp} /></span>
                        </div>
                        <div className="header">{JSTRs.jobID}</div>
                        <div className="header">{JSTRs.serviceName}</div>
                        <div className="header">{JSTRs.submitTime}</div>
                        <div className="header">{JSTRs.lastStatusChange}</div>
                        <div className="header">{STRs.completionTime}</div>
                        <div className="header">{MISC_STRINGS.status}</div>
                    </div>
                );
        
        return header;
    }

    private buildPurgeDetail = (): JSX.Element => {
        const cacheUIState = this.props.cacheUIState;
        const systemState: systemNavProjectState = this.props.systemNavProjectState;

        const data: CachePurgeReqStatus[] = cacheUIState.reqStatusData;
        // 'iconColor' - the attribute names are the names of font-awesome characters we show for the icon
        const iconColor = {
            check: 'green-check',
            'times-circle': 'red-x',
            spinner: 'white-icon', //'blue-circle'
        }

        const rowData: JSX.Element[] = data.map((row: CachePurgeReqStatus, i: number): JSX.Element => {
            let completionTime: string;
            let updateTime: string;
            const updateDate = this.formatDate( moment(row.updated_at));
            const statusStr = statusMapping[row.status];
            const serviceName = systemState._currentProject.serviceIdMap[row.service_id].serviceName;
            const status: string = row.status;
            const icon = this.formatStatusCell(status);
            const rowOpenIcon: string = row.open ? 'caret-down' : 'caret-right';
            const spin = (icon === 'spinner');
            const rowCSS = i % 2 ? ' odd-row' : ' even-row';

            const statusIcon = (icon.length > 0) ? 
                        ( <FontAwesomeIcon className={iconColor[icon]} spin={spin} icon={icon as IconProp} /> ) : '';
            if (status === 'accepted' || status === 'in_progress') {
                updateTime = updateDate;
                completionTime = '---'
            } else {
                updateTime = '---';
                completionTime = updateDate;
            }

            let moreInfo: JSX.Element = <div></div>;
            if (row.open) {
                moreInfo = this.buildMoreInfo(row);
            }
            return (
                <div key={ 'cacheRow' + i} className={rowCSS}  >
                <div key={'cacheReq' + i} className={ 'cache-row'}>
                    <div onClick={(): void => this.handleRowClick(i)}><span className="open-icon-spacing"><FontAwesomeIcon icon={rowOpenIcon as IconProp} /></span></div>
                    <div>{row.job_id}</div>
                    <div>{serviceName}</div>
                    <div>{this.formatDate(moment(row.created_at))}</div>
                    <div>{updateTime}</div>
                    <div>{completionTime}</div>
                    <div>{statusIcon}&nbsp;{statusStr} </div>
                </div>
                {moreInfo}
                </div>)
            }
        );

        return (<>{rowData}</>);
    }
    private handleRowClick = (rowNum: number): void => {
        this.props.setCacheRowState(rowNum)
    }

    private buildMoreInfo = (row: CachePurgeReqStatus): JSX.Element => {
        const rowMsg = (row.message.length > 0) ? (
            <div className="mi-multi-per-row">
                <span className="mi-label">{STRs.purgeMessages}</span> {row.message}
            </div>
        ) : '';

        let urls: JSX.Element[] = [] as JSX.Element[];
        urls = row.uris.map( (uri, i) => (<li key={'uris' + i}>{uri}</li>) )
        const urlList =  urls.length > 0 ? (
            <div><span className="mi-label">{STRs.urlList}&nbsp;&nbsp;<span onClick={(): void => { this.copyToClipboard( row.uris.join("\n"), PurgeType.urlList); }}>
            <FontAwesomeIcon icon={'copy' as IconProp} /></span></span>
            <ul className="cache-data">
                {urls}
            </ul>
        </div>
        ) : '';

        let tags: JSX.Element[] = [] as JSX.Element[];
        let tagList = <span></span>
        if (row.cache_tags !== undefined && row.cache_tags !== null) {
            tags = row.cache_tags.map( (ctag, i) => (<li key={'tags' + i}>{ctag}</li>) )
            tagList =  tags.length > 0 ? (
                <div><span className="mi-label">{STRs.ctagList}&nbsp;&nbsp;<span onClick={(): void => { this.copyToClipboard( row.cache_tags.join("\n"), PurgeType.cacheTags); }}>
                <FontAwesomeIcon icon={'copy' as IconProp} /></span></span>
                <ul className="cache-data">
                    {tags}
                </ul>
            </div>
            ) : <div></div>;
        }

        const uriCodeList = this.buildUriCodePurgeList(row.uri_codes);
        // console.log(uriCodeList);

        return (
            <div className={' more-info'}>
                <div className="mi-divider-margin"></div>
                <div className="mi-divider"></div>
                {urlList}
                {uriCodeList}
                {tagList}
                {rowMsg}
            </div>
        );
    }

    private formatDate = (opDate: moment.Moment | string): string => {
        let dateStr = '';
        if (moment.isMoment(opDate)) {
            dateStr = opDate.format(DATETIME_FORMAT);
        } else {
            dateStr = opDate as string;
        }
        return dateStr;
    };

    private formatStatusCell = (status: string): string  => {
        let cellCss = '';

        if (status !== undefined) {
            status = status.toLowerCase();
            if (status.indexOf('succ') !== -1) {
                cellCss = 'check';
            } else if (status.indexOf('fail') !== -1 || status.indexOf('abort') !== -1) {
                cellCss = 'times-circle';
            } else if (status.indexOf('prog') !== -1) {
                cellCss = 'spinner';
            }
        }
        return cellCss;
    };

    private buildUriCodePurgeList = (uriCodesList: CacheUriCodes[]): JSX.Element => {
        const uriCodes = [];
        let markup: JSX.Element = <div></div>;
        
        if (uriCodesList.length > 0) {
            for (let i = 0, len = uriCodesList.length; i < len; i++) {
                const uriCode = uriCodesList[i];
                const str = 
                `Domain: ${uriCode.domain_name}; URI Code: ${ uriCode.uri_code}; URI Pattern: ${uriCode.uri_pattern}`;
                uriCodes.push( <li key={'uricode' + i} >{str}</li>)
            }

            markup = (
                <div><span className="mi-label">{STRs.uriCode}</span>
                    <ul className="cache-data">
                    {uriCodes}
                    </ul>
                </div>
            );
        }

        return markup;
    }

    private onCreatePurgeRequest = (purgeType: PurgeType, serviceId: string, purgeInfo: string): void => {
        this.props.submitCachePurgeRequest();

        const purgeItems: CreatePurgeRequestData = {
            "service_id": serviceId,
        }

        const pinfo = convertStringToStringArray(purgeInfo);
        if (purgeType === PurgeType.urlList) {
            purgeItems.uris = pinfo;
        } else if (purgeType === PurgeType.cacheTags) {
            purgeItems.cache_tags = pinfo;
        }

        this.submitPurgeRequest(purgeItems);
    }

    private async submitPurgeRequest(purgeItems: CreatePurgeRequestData): Promise<void> {
        const projectId = this.props.systemNavProjectState._currentProject.projectId;

        const url = buildUrl(this.props.authNServerData, `${ZUrls.serverCache}/${projectId}`);

        try {
            const cprData: ZRestResponse = await ZPost(url, JSON.stringify(purgeItems));

            setTimeout((): void => {this.props.submitCachePurgeRequest()}, 1000);
            const reqResult: CachePurgeRequest = cprData as CachePurgeRequest;
            if (reqResult.status === 'accepted') {
                this.showNotification( STRs.jobSubmitOK(reqResult.job_id), NotificationStyles.success);
            } else {
                this.props.showNotification(STRs.errorCreatingReq(reqResult.status), NotificationStyles.danger, );
            }
        }
        catch(restError) {
            const fError = restError as Promise<FetchError>;
            this.serverError(fError);
        }
    }

    private serverError = (restResp: Promise<FetchError>): void => {
        const errList = {
            '400': MISC_STRINGS.illFormedUrl,
            '403': MISC_STRINGS.serviceNotInProj,
            '500': STRs.errorProcessingReq
        };

        restResp.then((err) => {
            const scode = err.statusCode;
            const status: string = (scode === undefined) ? '' : scode.toString();
            let msg = err.message;
            msg = (errList[status] !== undefined) ? errList[status] : msg

            this.showNotification(msg, NotificationStyles.danger);
        })
    }

    private copyToClipboard = ( key: string, purgeType: PurgeType ): void => {
        const success = copyTextToClipboard(key);
        let msg = '';
        if (purgeType === PurgeType.urlList) {
            msg = (success) ? STRs.URLsCopied : STRs.URLsNotCopied;
        } else {
            msg = (success) ? STRs.cTagsCopied : STRs.cTagsNotCopied;

        }

        const msgStyle = (success) ? NotificationStyles.success : NotificationStyles.danger ;

        this.showNotification(msg, msgStyle);
    }

    private showNotification = ( message: string, style: NotificationStyles ): void => {
        this.props.showNotification(style, message);
        setTimeout(() => { this.props.closeNotification(); }, CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT);
    }  

    // private closeNotification = (): void => {
    //     setTimeout(() => {this.props.closeNotification()}, 5000);
    // }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        cacheUIState: state.cacheUIState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(Cache);