import { actionTypes, ActionKeys, AUpdateEmailPassword, AValidatePassword, ALoginUserFail, ASetResetPasswordState, 
         AResetPassword, ASetUserInfo, AChangeServerFeatureEnv, ASaveServerConfigData, AEnableTFASupport, ASetOrgLimits, 
         AValidateProfile, ASetSelectedDocumentationItem, AUpdateProfileInProgress, ASetTFAGenInfo, ASetTFAToken, 
         ASetOrgList, ASetCurrentOrg, ASetPwdResetTokenInfo, ASetOrgListIdx, AControlModalDlg, ASetOrgName, ASetOrgData,
         ASetPwdExpiredAuthToken } 
         from '../actions/actionCreatorTypes';
import {  OrgInfo, OrgLimit, ResetTokenInfo, ServerConfig, TFA_FORMAT, ZUserTypes, ZTfaSecret, ZUserMin } 
         from '../data/queryResultDefinitions';

import { authProgressStates, loginState, PwdResetState, SysFeatureEnums, TFAState, ZFeaturePopupTypes } from './reducerEnums';
import { PwdValidationState, ProfilePwdValidationState } from '../shared/authUtils';
import { initProfileData, validatePwdData, validateProfileData, resetPwdProfileData } from '../shared/authUtils';
import { setPublishPromoteValue } from './serverEnvironAccessor';
import { LOGIN_STRINGS as Strs, MISC_STRINGS } from '../shared/strings';
import { NotificationStyles, DEFAULT_PASSWORD_VALID_DAYS, DEFAULT_NUM_PREV_PREV_PWD_NOT_ALLOWED,
         DEFAULT_PWD_EXPIRY_WARNING_DAYS } from '../shared/constants';
import logger from '../shared/logUtilities';
import { pwdExpiryUtils } from '../shared/utilities';
import { cloneDeep } from 'lodash';

export interface OrgByIdMap {
    [orgId: string]: OrgInfo;
}

export enum AuthenticationType  {
    'login' = 'login',
    'resetpwd' = 'resetpwd',
    'activate' = 'activate',
    'invite' = 'invite',
    'expiredPwd' = 'expiredPwd'
}

export interface State extends ProfilePwdValidationState {
    orgList: OrgInfo[];
    currentOrg: OrgInfo;
    orgById: OrgByIdMap;
    orgListIdx: number; 
    orgLimits: OrgLimit[];

    editableOrg: OrgInfo;
    newOrgName: string,

    authProgress: authProgressStates;
    error?: string;
    notificationStyle: NotificationStyles;
    isLoggedIn: loginState;
    user: ZUserMin | undefined;

    pwdValidation: PwdValidationState;
    pwdResetState: PwdResetState;
    passwordChangeInProgress: boolean;
    resetTokenInfo: ResetTokenInfo;
    resetToken: string;
    authenticationType: AuthenticationType;

    tfaEnabled: boolean;
    tfaToken: string;
    tfaGenInfo: ZTfaSecret;
    tfaState: TFAState;

    navItemIdx: number;
    navSubItemIdx: number;

    currentApiURL: string;
    serverConfig: ServerConfig;
    serverConfigLoaded: boolean;           // environment loaded from server
    popupType: ZFeaturePopupTypes;
    popupOpen: boolean;
    expiredPwdAuthToken: string;
    pwdExpiryInfo: pwdExpiryUtils;
}

const resetServerEnv: ServerConfig = {
    "api_url_mappings": {},
      automatEnabled: false,
      loggingEnabled: false,
      legacyConsole: '',
      features: '',
      "CSALite": "",
      "CSA_verification": "",  
      "login_url": '',
      "publish_promote_old_versions_upto": {}
};

const authInitData: State = {
    authProgress: authProgressStates.fail,
    currentApiURL: '',
    error: '',

    orgList: [],
    currentOrg: {} as OrgInfo,
    orgById: {} as OrgByIdMap,
    orgListIdx: 0,
    orgLimits: [],

    editableOrg: {} as OrgInfo,
    newOrgName: '',
    
    isLoggedIn: loginState.unknown,
    notificationStyle: NotificationStyles.success,
    navItemIdx: 0,
    navSubItemIdx: -1,
    
    pwdResetState: PwdResetState.unknown,
    resetTokenInfo: {} as ResetTokenInfo,
    resetToken: '',
    authenticationType: AuthenticationType.login,
    passwordChangeInProgress: false,
    serverConfig: {
        "api_url_mappings": {},
        automatEnabled: false,
        CSALite: '',
        CSA_verification: '',
        legacyConsole: '',
        loggingEnabled: false,
        features: '',
        "login_url": '',
        "publish_promote_old_versions_upto": { 'days': '30' }
    },
    serverConfigLoaded: false,           // environment loaded from server
    user: undefined,

    // following is initialization of the ProfilePwdValidationState interface
    newPassword: '',
    password: '',
    confirmPassword: '',
    pwdValidation: {
        badCharMsg: '',
        badChars: '',
        letters: 'red',
        minChars: 'red',
        numbers: 'red',
        pwdMatch: 'red',
        validPwd: false
    },
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    profileValidation: {
        validProfile: false,
        firstNameValid: false,
        lastNameValid: false,
        phoneNumberValid: false,
        emailValid: false
    },
    role: '' as ZUserTypes,

    // TFA variables
    tfaEnabled: false,
    tfaToken: '',
    tfaState: TFAState.unknown,
    tfaGenInfo: {} as ZTfaSecret,

    popupType: ZFeaturePopupTypes.NoPopup,
    popupOpen: false,
    expiredPwdAuthToken: '',
    pwdExpiryInfo: new pwdExpiryUtils(-1, 0),            // 2 years (365*2)
}

const resetUserData = (state: State, initProfile?: boolean): void => {
    resetPwdProfileData(state, initProfile);

    state.tfaEnabled = false;
    state.tfaState = TFAState.unknown;
    state.tfaToken = '';
    state.tfaGenInfo = {} as ZTfaSecret;
    state.pwdResetState = PwdResetState.unknown;

    state.resetTokenInfo = {
        'token_valid': false,
        'token_expired': true,
        'max_rate_reached': true, 
        'retry_after_hours': 0,
        'max_attempts_reached': true,
        url: '',
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetState = ( initialState: State, action: actionTypes ): State => {
    const state = {...initialState};
    resetUserData(state);
    return state;
}

const updateEmailPwd = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AUpdateEmailPassword;
    const state = {...initialState};

    state.email = action.payload.email;
    state.password = action.payload.password;
    
    return state;
}

const validatePwdAction = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AValidatePassword;
    const state = {...initialState};
    const { oldPwd, newPwd, confirmPwd} =  action.payload;

    validatePwdData( state, oldPwd, newPwd, confirmPwd)

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginUserStart = ( initialState: State, action: actionTypes ): State => {
    const state = {...initialState};
    state.authProgress = state.authProgress === authProgressStates.tfa ? state.authProgress : 
                                                authProgressStates.inProgress;
    state.error = '';
    state.notificationStyle = NotificationStyles.success;
    state.isLoggedIn = loginState.unknown;
    state.user = undefined;
    return state;
}

const loginUserFail = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ALoginUserFail;
    const state = {...initialState};

    state.authProgress = authProgressStates.fail;
    state.error = action.payload.errorMsg;
    state.notificationStyle = NotificationStyles.danger;
    state.isLoggedIn = loginState.loggedOut;
    state.tfaToken = '';
    state.user = undefined;
    return state;
}

const setPwdResetState = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetResetPasswordState;
    const state = {...initialState};

    state.error = '';
    state.notificationStyle = NotificationStyles.warning;

    if (action.payload.pwdResetState === PwdResetState.emailSent ) {
        state.error = Strs.emailSentMsg;
        state.notificationStyle = NotificationStyles.warning;
        state.pwdResetState = action.payload.pwdResetState;
    } else if (action.payload.pwdResetState === PwdResetState.invalidEmail ) {
        state.notificationStyle = NotificationStyles.danger;
        state.error = Strs.invalidEmail;
    } else if (action.payload.pwdResetState === PwdResetState.miscError) {
        state.notificationStyle = NotificationStyles.danger;
        state.error = MISC_STRINGS.serverError
    } else {
        state.pwdResetState = action.payload.pwdResetState;
    }
    return state;
}

const resetPwd = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AResetPassword;
    const state = {...initialState};

    const resetPwdState = state.pwdResetState;
    resetUserData(state);
    state.error = action.payload.success ? Strs.pwdResetSuccess : Strs.pwdResetFail;
    state.pwdResetState = resetPwdState;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginUserSuccess = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};

    state.authProgress = authProgressStates.success; 
    state.isLoggedIn = loginState.loggedIn; 
    state.tfaToken = '';

    resetUserData(state, true);

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginUserTFA = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};

    state.authProgress = authProgressStates.tfa; 

    return state;
}

const setUserInfo = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetUserInfo;
    const state = {...initialState};

    const user = action.payload.user;
    state.user = user;
    
    const { first_name, last_name, email, phone } = user;
    const firstName: string = first_name !== undefined ? first_name : '';
    const lastName = last_name !== undefined ? last_name : '';
    const phoneNumber = phone !== undefined ? phone : '';

    const userMeta = action.payload.user.meta;
    state.pwdExpiryInfo = (userMeta !== undefined && userMeta.password_expires_at_epoch_seconds !== undefined) ? 
                                        new pwdExpiryUtils(userMeta.password_expires_at_epoch_seconds, state.currentOrg.password_expiry_warning_days) :
                                        new pwdExpiryUtils(-1, 0);

    state.tfaEnabled = (action.payload.user.two_factor_auth) ? 
                        action.payload.user.two_factor_auth.enabled : false;
    resetUserData(state, true);
    validateProfileData( state, firstName, lastName, email, phoneNumber);


    return state;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logoutUser = ( initialState: State, action: actionTypes ): State => {
    const state = {...initialState};

    state.authProgress = authProgressStates.success;
    state.isLoggedIn = loginState.loggedOut;
    state.user = undefined;
    state.error = '';
    state.notificationStyle = NotificationStyles.success;
    state.serverConfig = Object.assign(resetServerEnv);
    state.currentApiURL = '';
    state.serverConfigLoaded = false;

    state.email =  '';
    state.firstName =  '';
    state.lastName =  '';
    state.phoneNumber =  '';

    resetUserData(state, true);

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initServerEnv = ( initialState: State, action: actionTypes ): State => {
    const state = {...initialState};
    state.serverConfig = Object.assign(resetServerEnv);
    state.currentApiURL = '';
    state.serverConfigLoaded = false;
    return state;
}

function getCurrentApiUrl(state: State, projectType: string): string {
    let currentApiURL: string;
    if (state.serverConfig.api_url_mappings[projectType] !== undefined) {
        currentApiURL = state.serverConfig.api_url_mappings[projectType];
    } else {
        currentApiURL = '';                
    }

    return currentApiURL;
}

const changeServerFeatureEnv = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AChangeServerFeatureEnv;
    const state = { ...initialState};
    state.currentApiURL = getCurrentApiUrl(state, action.payload.projectType);
    return state;
}

const saveServerConfigData = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASaveServerConfigData;
    const state = { ...initialState};

    const config: ServerConfig = Object.assign(action.payload.config);
    state.serverConfigLoaded = true;
    state.serverConfig = config;
    logger.initLogger(state.serverConfig.loggingEnabled);
    setPublishPromoteValue(state.serverConfig.publish_promote_old_versions_upto)

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pwdChangeInProgress = ( initialState: State, action: actionTypes ): State => {
    const state = {...initialState};
    return state;
}

const updateProfile = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};
    const action = actions as AUpdateProfileInProgress;

    if  (! action.payload.inProgress && action.payload.updateSucceeded && state.user !== undefined) {
        state.user.email = state.email;
        state.user['first_name'] = state.firstName;
        state.user['last_name'] = state.lastName;
        state.user.phone = state.phoneNumber;
    }

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initUpdateProfile = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};
    const user = state.user as ZUserMin;
    
    initProfileData(state, user.first_name, user.last_name, user.email, user.phone)

    return state;
}

const validateUserProfile = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AValidateProfile;
    const state = {...initialState};
    const { firstName, lastName, email, phoneNumber } = action.payload;
    validateProfileData( state, firstName, lastName, email, phoneNumber);

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const clearNotification = (initialState: State, action: actionTypes): State => {
    const state = {...initialState};
    state.error = '';
    state.notificationStyle = NotificationStyles.success;
    return state;
}

const setSelectedDocumentationItem = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetSelectedDocumentationItem;
    const state = {...initialState};

    state.navItemIdx = action.payload.navItem;
    state.navSubItemIdx = action.payload.subNavItem;
    return state;
}

const setTFAGenInfo = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetTFAGenInfo;
    const state = {...initialState};

    state.tfaGenInfo = action.payload.tfaGenInfo;
    state.tfaState = TFAState.gotSecret;
    return state;
}

const updateTFAToken = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetTFAToken;
    const state = {...initialState};

    state.tfaToken = action.payload.token;
    return state;
}

const enableTFASupport = (initialState: State, actions: actionTypes): State => {
    const action = actions as AEnableTFASupport;
    const state = {...initialState};

    const enabled = action.payload.enabled;
    state.tfaEnabled = enabled;
    let tfa = {
        enabled: false,
        type: TFA_FORMAT.QR as string
    }
    if (state.user) {
        tfa = state.user.two_factor_auth ? state.user.two_factor_auth : tfa;
        tfa.enabled = enabled;
        state.user['two_factor_auth'] = tfa;
    }

    if (enabled) {
        state.tfaState = TFAState.confirming2FA;
        state.tfaToken = '';
        state.authProgress = authProgressStates.fail;
    } else {
        state.tfaState = TFAState.unknown;
        state.tfaToken = '';
        state.tfaGenInfo = {} as ZTfaSecret;
        state.password = '';
        state.email = '';
    }
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initTFAMgmt = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    state.tfaEnabled = (state.user && state.user.two_factor_auth) ? state.user.two_factor_auth.enabled : false;
    state.tfaState = TFAState.unknown;
    state.tfaToken = '';
    state.tfaGenInfo = {} as ZTfaSecret;
    
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const beginTFADeactivate = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    state.tfaState = TFAState.disabling2FA;
    
    return state;
}

const setPwdTokenInfo = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetPwdResetTokenInfo;
    const tokenStatus = action.payload.tokenStatus;
    state.authenticationType = action.payload.authenticationType;

    state.resetTokenInfo = tokenStatus;
    state.resetToken = action.payload.token;

    state.pwdResetState = PwdResetState.invToken;
    if (tokenStatus.token_valid) {
        if (!tokenStatus.token_expired) {
            state.pwdResetState = PwdResetState.validTokenGetPwd;
        } else {
            if (tokenStatus.max_attempts_reached) {
                state.pwdResetState =PwdResetState.invTokenMaxAttempts;
            } else if (tokenStatus.max_rate_reached) {
                state.pwdResetState = PwdResetState.invTokenMaxRateReached;
            } else {
                state.pwdResetState = PwdResetState.validTokenExpired;
            }
        }
    }
    
    return state;
}

const setOrgList = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetOrgList;
    const state = {...initialState};

    state.orgList = [...action.payload.orgList];
    state.orgList.sort((a: OrgInfo, b: OrgInfo): number => {
        return a.org_name.localeCompare(b.org_name);
    });

    state.orgById = {} as OrgByIdMap;
    state.orgList.forEach((org: OrgInfo) => {
        state.orgById[org.org_id] = org; 
        if (!org.password_expiry_warning_days) {
            org.password_expiry_warning_days = DEFAULT_PWD_EXPIRY_WARNING_DAYS;
            org.num_password_valid_days = DEFAULT_PASSWORD_VALID_DAYS;
            org.num_prev_passwords_not_allowed = DEFAULT_NUM_PREV_PREV_PWD_NOT_ALLOWED
        }
    });
    let idx = -1;
    const selectedOrgName = action.payload.selectedOrgName;
    if (selectedOrgName !== undefined) {
        idx = state.orgList.findIndex((org: OrgInfo) => { return selectedOrgName === org.org_name; });
    } else {
        idx = state.orgList.findIndex((org: OrgInfo) => { return state.currentOrg.org_id === org.org_id; });
    }
    state.orgListIdx = idx !== -1 ? idx : 0;

    state.editableOrg = cloneDeep(state.orgList[state.orgListIdx])
    state.currentApiURL = getCurrentApiUrl(state, 'prod');
    return state;
}

const setCurrentOrg = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetCurrentOrg;
    const state = {...initialState};

    const orgId = action.payload.orgId;
    const org = state.orgById[orgId];
    
    if (org !== undefined) {
        state.currentOrg = org;
        const orgIdx = state.orgList.findIndex((org: OrgInfo) => {return (org.org_id === orgId)});
        state.orgListIdx = orgIdx !== -1 ? orgIdx : 0;
        state.editableOrg = cloneDeep(state.orgList[state.orgListIdx])

    } else {
        state.currentOrg = {} as OrgInfo;
        logger.alert(`systemState.setCurrentOrg: Cannot find org id ${orgId}`)
    }

    return state;
}

const setOrgListIdx = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetOrgListIdx;

    state.orgListIdx = action.payload.orgIndex;
    state.editableOrg = cloneDeep(state.orgList[state.orgListIdx]);

    return state; 
}

const controlModalDlg = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as AControlModalDlg; 
    const { dialogName, setOpen } = action.payload;


    switch(action.payload.feature) {
        case SysFeatureEnums.pwdExpiring:
            state.popupOpen = setOpen;
            state.popupType = dialogName as ZFeaturePopupTypes;

            // two cases - If the popup type is ExpiringPassword and we are closing the dialog this means
            //             the user doesn't want to update the password now.  If the popup is different then
            //             the user wants to update the password;
            if (! setOpen && state.popupType === ZFeaturePopupTypes.ExpiringPassword) {
                state.pwdExpiryInfo = new pwdExpiryUtils(-1, 0);
            }
            break;

        case SysFeatureEnums.organizations: {
            state.popupType = dialogName as ZFeaturePopupTypes;
            state.popupOpen = setOpen;
    
            if (setOpen) {
                if (dialogName === ZFeaturePopupTypes.CreateOrg) {
                    state.newOrgName = '';
                } else if (dialogName === ZFeaturePopupTypes.EditOrg) {
                    state.editableOrg = {...state.orgList[state.orgListIdx]};
                }
            }}
            break;

        default:
            break;
    }

    return state;
}

const setOrgName = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetOrgName; 

    state.newOrgName = action.payload.orgName;

    return state;
}

const setOrgData = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetOrgData; 

    state.editableOrg = action.payload.orgData;
    return state;
}

const setOrgLimits = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetOrgLimits; 

    state.orgLimits = action.payload.limits;
    return state;
}

const setPwdExpiredAuthToken = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetPwdExpiredAuthToken; 

    state.expiredPwdAuthToken = action.payload.authToken;
    return state;
}

type ActionFunction = ((state: State, action: actionTypes ) => State);
type AuthActions =  ActionKeys.SET_ACCOUNT_TAB |  ActionKeys.SET_NAV_ITEM | 
                    ActionKeys.UPDATE_EMAIL_PWD | ActionKeys.VALIDATE_PWD | ActionKeys.LOGIN_USER_START | 
                    ActionKeys.LOGIN_USER_FAIL | ActionKeys.SET_PWD_RESET_STATE | ActionKeys.RESET_PASSWORD | 
                    ActionKeys.LOGIN_USER_SUCCESS | ActionKeys.LOGOUT_USER |  ActionKeys.INIT_SERVER_ENV | 
                    ActionKeys.CHANGE_SERVER_FEATURE_ENV | ActionKeys.ENABLE_TFA_SUPPORT | ActionKeys.SET_NEW_ORG_NAME |
                    ActionKeys.SAVE_SERVER_CONFIG_DATA | ActionKeys.PWD_CHANGE_IN_PROGRESS | ActionKeys.LOGIN_USER_TFA |
                    ActionKeys.UPDATE_PROFILE_IN_PROGRESS | ActionKeys.VALIDATE_PROFILE | ActionKeys.UPDATE_TFA_TOKEN |
                    ActionKeys.INIT_UPDATE_PROFILE | ActionKeys.SET_SELECTED_DOCUMENTATION_ITEM | ActionKeys.SET_ORG_LIST |
                    ActionKeys.SET_CURRENT_ORG | ActionKeys.CLOSE_NOTIFICATION | ActionKeys.SET_USER_INFO | 
                    ActionKeys.SET_TFA_GEN_INFO | ActionKeys.INIT_TFA_MGMT | ActionKeys.BEGIN_TFA_DEACTIVATE | 
                    ActionKeys.SET_PWD_RESET_TOKEN_INFO | ActionKeys.SET_ORG_LIST_IDX | ActionKeys.CONTROL_MODAL |
                    ActionKeys.SET_ORG_DATA | ActionKeys.SET_ORG_LIMITS | ActionKeys.SET_PWD_EXPIRED_AUTH_TOKEN;
                    
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in AuthActions]: ActionFunction | undefined };

const actionMap: ActionCmds = {
    [ActionKeys.SET_ACCOUNT_TAB]: resetState,
    [ActionKeys.SET_NAV_ITEM]: resetState,

    [ActionKeys.SET_ORG_LIST]: setOrgList,
    [ActionKeys.SET_CURRENT_ORG]: setCurrentOrg,

    [ActionKeys.UPDATE_EMAIL_PWD]: updateEmailPwd,
    [ActionKeys.VALIDATE_PWD]: validatePwdAction,
    [ActionKeys.LOGIN_USER_START]: loginUserStart,
    [ActionKeys.LOGIN_USER_FAIL]: loginUserFail,
    [ActionKeys.SET_PWD_RESET_STATE]: setPwdResetState,
    [ActionKeys.RESET_PASSWORD]: resetPwd,
    [ActionKeys.LOGIN_USER_SUCCESS]: loginUserSuccess,
    [ActionKeys.SET_USER_INFO]: setUserInfo,
    [ActionKeys.LOGOUT_USER]: logoutUser,
    [ActionKeys.INIT_SERVER_ENV]: initServerEnv,
    [ActionKeys.CHANGE_SERVER_FEATURE_ENV]: changeServerFeatureEnv,
    [ActionKeys.SAVE_SERVER_CONFIG_DATA]: saveServerConfigData,
    [ActionKeys.PWD_CHANGE_IN_PROGRESS]: pwdChangeInProgress,
    [ActionKeys.SET_PWD_EXPIRED_AUTH_TOKEN]: setPwdExpiredAuthToken,
    [ActionKeys.UPDATE_PROFILE_IN_PROGRESS]: updateProfile,
    [ActionKeys.VALIDATE_PROFILE]:  validateUserProfile,
    [ActionKeys.INIT_UPDATE_PROFILE]: initUpdateProfile,
    [ActionKeys.SET_SELECTED_DOCUMENTATION_ITEM]: setSelectedDocumentationItem,
    [ActionKeys.CLOSE_NOTIFICATION]: clearNotification,
    [ActionKeys.SET_TFA_GEN_INFO]: setTFAGenInfo,
    [ActionKeys.UPDATE_TFA_TOKEN]: updateTFAToken,
    [ActionKeys.ENABLE_TFA_SUPPORT]: enableTFASupport,
    [ActionKeys.LOGIN_USER_TFA]: loginUserTFA,
    [ActionKeys.INIT_TFA_MGMT]: initTFAMgmt,
    [ActionKeys.BEGIN_TFA_DEACTIVATE]: beginTFADeactivate,
    [ActionKeys.SET_PWD_RESET_TOKEN_INFO]: setPwdTokenInfo,
    [ActionKeys.SET_ORG_LIST_IDX]: setOrgListIdx,
    [ActionKeys.CONTROL_MODAL]: controlModalDlg,
    [ActionKeys.SET_NEW_ORG_NAME]: setOrgName,
    [ActionKeys.SET_ORG_DATA]: setOrgData,
    [ActionKeys.SET_ORG_LIMITS]: setOrgLimits,
}

function authentication(initialState: State, action: actionTypes): State {
    let state = initialState; 
    // logger.log('authentication: action.type = ' + action.type + ';\n    initialState = ', state);
    if (! state) {
        state = {...authInitData};
        state.serverConfig = Object.assign(resetServerEnv);
    }

    const fct: ActionFunction | undefined = actionMap[action.type];

    if (fct) {
        state = (fct)(state, action);
    }

    return state;
}

export default authentication;