import { ZServiceFeatures, ZUserMin, ZProjectMin, OrgInfo } from '../data/queryResultDefinitions';
import { State, _CurrentProject, ZService } from './systemState';
import logger from '../shared/logUtilities';
// import GenericLogo from '../img/generic-logo.svg';
import * as React from 'react';

export function getCurrentProjectEnvironment(state: State): string {
    return state._currentProject.currentServiceEnvironment
}

export function getCurrentProjectName(state: State): string {
    return state._currentProject.name;
}

export function getProjectList(state: State): string[] {
    let projectList: string[] = []

    projectList = state._projectList.map((project: ZProjectMin) => {
        return project.name;
    });

    return projectList
}

export const getSelectedServiceId = (state: State, index?: number): string => {
    const project: _CurrentProject = state._currentProject;
    const idx = state.serviceEvtIdx;
    if (idx !== index) {
        logger.alert(`projectAccessors.getSelectedServiceId: passed idx = ${index} doesn't match serviceIdx ${idx}`)
        return '';
    }
    const svcName = state.serviceEvents[index];
    return project.currentEnv.serviceNameToId[svcName];
}

export function getProjectFeatures(state: State): ZServiceFeatures {
    return state._currentProject.projectFeatures;
}

export function getCurrentProjectIndex(state: State): number {
    const currentProjName: string = state._currentProject.name;
    return state._projectList.findIndex(project => project.name === currentProjName);
}

export function getProjectByIndex(state: State, index: number): ZProjectMin {
    let project: ZProjectMin = {} as ZProjectMin;
    const projectList = state._projectList;
    if (index < projectList.length) {
        project = projectList[index];
    } else if (state._projectList.length > 0) {
        project = projectList[0];
    } else {
        logger.log('projectAccessors: attempt to get a project when there are no projects in the project list.');
    }

    return project;
}

export function getProjectLogo(state: State, user: ZUserMin, org: OrgInfo): JSX.Element {
    let orgLogo = <span></span>;

    const makeLogo = (svgLogo: string): JSX.Element => {
        return (
            <div className="inline">
                <img alt="" className="company-logo" src={`data:image/svg+xml;base64,${btoa(svgLogo)}`} />
            </div>)
    }

    if (user) {
        if (org.org_logo_url && org.org_logo_url.length > 0) {
            orgLogo = makeLogo(org.org_logo_url);
        } else {
            orgLogo = (
                <div className="inline company-logo-text">{org.org_name}</div>
            )
        }
    } else {
        orgLogo = makeLogo('https://console.zycada.com/static/media/generic-logo.svg');
    }

    return orgLogo;
}

export const findProjectById = (state: State, projectId: string): ZProjectMin => {
    let project = state._projectObj[projectId];
    if (! project) {
        project = state._currentProject;
    }

    return project;
}

export const findServiceInProjectByServiceId = (project: _CurrentProject, serviceId: string): 
                                                ZService | undefined => {
    const service: ZService | undefined = project.serviceIdMap[serviceId]; 

    return service;
}