/* eslint-disable react/prop-types */
import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import ZButton from './ZButton';

import { MISC_STRINGS } from './strings';
import { ZModalSize } from './MiscEnums';

export interface GenDlgProps {
    show: boolean;
    title: string;
    msg: string | JSX.Element;
    applyBtnTxt?: string;
    cancelBtnTxt?: string;
    css?: string;
    dontCloseOnApply?: boolean;
    hideCloseButtonIcon?: boolean;
    hideApplyButton?: boolean;
    size?: ZModalSize,
    disableApply: () => boolean;
    onHide: () => void;
    handleApply: () => void;
    onCancel?: () => void;
}

// tslint:disable-next-line: variable-name
const GenDialog: React.FunctionComponent<GenDlgProps> = props => {

    const handleApplyBtn = (): void => {
        (props.handleApply)();
        if (props.dontCloseOnApply === undefined || props.dontCloseOnApply === false) {
            (props.onHide)();
        }
    }

    const handleCancel = (isCancel: boolean): void => {
        if (isCancel) {
            props.onCancel ? props.onCancel() : props.onHide();
        } else {
            props.onHide();
        }
    }
    const sizes = { sm: 'sm', lg: 'lg', xl: 'xl' };

    // const disabled = props.disableApply();
    const css = `zmodal ${props.css ? props.css : ''}` 
    const applyBtnTxt = props.applyBtnTxt ? props.applyBtnTxt : MISC_STRINGS.applyBtn;
    const cancelBtnTxt = props.cancelBtnTxt ? props.cancelBtnTxt : MISC_STRINGS.cancelBtn;
    const modalSize: ZModalSize = props.size !== undefined ? sizes[props.size] as ZModalSize: undefined;
    let applyBtn = <span></span>;
    if (! props.hideApplyButton) {
        applyBtn = <ZButton disabled={props.disableApply()} onClick={(): void => { handleApplyBtn()}} >{applyBtnTxt}</ZButton>
    }

    return (
        <Modal onHide={(): void => { handleCancel(false) }} show={props.show} 
        dialogClassName={css} backdrop="static" size={modalSize as  ZModalSize}>
            <Modal.Header closeButton={( props.hideCloseButtonIcon !== true)}>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.title}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {props.msg}
                </div>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                {applyBtn}
                <ZButton onClick={(): void => { handleCancel(true) } } >
                    {cancelBtnTxt}
                </ZButton>
            </Modal.Footer>
        </Modal>
    )
}

export default GenDialog;