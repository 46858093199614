/* eslint-disable react/prop-types */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTime from 'react-datetime';
import moment from 'moment';
import logger from './logUtilities'
import { ANALYTICS_STRINGS as STRs } from './strings';
import { DATE_FORMAT, TIME_FORMAT, TzAbbrev } from './constants';

export interface AbsTimeCtrlProps {
    from: moment.Moment, 
    to: moment.Moment,
    handleFromChange: (from: moment.Moment | string) => void,
    handleToChange: (to: moment.Moment | string) => void,
    validDateTime?: (current:  moment.Moment, selected: moment.Moment) => boolean
}

const  AbsTimeControl: React.FunctionComponent<AbsTimeCtrlProps> = props => {
    const fromDateTime = (current: moment.Moment, selected: moment.Moment): boolean => {
        let isValid = true;
        if (props.validDateTime) {
            isValid = (props.validDateTime)(current, selected);
        }
        return isValid;
    } 

    const fromChangeHandler = (newFrom: moment.Moment | string): void => {
        logger.log(newFrom);
        (props.handleFromChange)(newFrom as moment.Moment)
    }

    const toChangeHandler = (newTo: moment.Moment | string): void  => {
        logger.log(newTo);
        (props.handleToChange)(newTo)
    }
    
    const tz = TzAbbrev.length > 0 ? ` (${TzAbbrev.trim()})` : TzAbbrev;
    return (
        <div className="abs-time-control" key="abs-time">
            <div id="timeFrom" key="timeFrom">
                {STRs.calendarFrom}&nbsp;
                <DateTime input={true} value={props.from} dateFormat={DATE_FORMAT} timeFormat={TIME_FORMAT}
                onChange={fromChangeHandler}  initialViewMode="days"
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                isValidDate={(current: moment.Moment, selected: moment.Moment): boolean => { 
                    const now = moment();
                    now.hours(23);
                    now.minutes(59);
                    now.seconds(59);
                    return now.isAfter(current); }}
                 />
                <FontAwesomeIcon className="cal-padding" size="lg" icon="calendar-alt" />
            </div>
            <div id="timeTo" key="timeTo">
                {STRs.calendarTo}&nbsp;
                <DateTime input={true} value={props.to} dateFormat={DATE_FORMAT} timeFormat={TIME_FORMAT}
                        onChange={toChangeHandler} initialViewMode="days" 
                        isValidDate={(current: moment.Moment, selected: moment.Moment): boolean => { 
                            return fromDateTime(current, selected)} } />
                <FontAwesomeIcon size="lg" icon="calendar-alt" />&nbsp;{tz}
            </div>
        </div>);
}

export default AbsTimeControl;