import logger from '../shared/logUtilities';
const defaultServerURL = '/'; 

// TO RUN AGAINST A LOCAL NODE SERVER ADD THE FOLLOWING TO YOUR .env FILE:
// REACT_APP_SERVER_CONFIG=http://localhost:8080/
function getSysConfigURL(): string {
    let devUrl: string = process.env.REACT_APP_SERVER_CONFIG as string;
    devUrl = devUrl ? devUrl : '/';
    
    const myenv = (process.env.NODE_ENV === 'production') ? defaultServerURL :  devUrl;
    logger.log('environment: ' + process.env.NODE_ENV + '; config=' + myenv);
    return myenv;
}

export const makeQueryParams = (url: string, params: Record<string, unknown> | string): string => {

    let paramStr = '';
    if (typeof params === 'object') {
        const paramKeys = Object.keys(params);
        for (let i = 0, len = paramKeys.length; i < len; i++) {
            if (paramStr.length > 0) {
            paramStr += '&';
            }
            const key = paramKeys[i];
            paramStr += key + '=' + params[key];
        }
    } else if (typeof params === 'string') {
        paramStr = params;
    }

    return url + '?' + paramStr;
}


const ZURLS = {
    getSysConfigURL,

    // react router urls
    // top level features
    account: '/account',
    admin: '/admin',
    analytics: '/analytics',
    cache: '/cache',
    dashboard: '/dashboard',
    documentation: '/account',
    home: '/',
    login:  '/login',
    reports: '/reports',
    service: '/services',
    svcMgmt: '/svcMgmt',

    organizations: '/organizations',
    billingReports: '/billing',
        
    // analytics urls
    dnsDirector: '/analytics',
    cloudSvcMonitor: '/analytics',
    cloudSvcAccelerator: '/analytics',
    traffic: '/analytics',
    cacheAnalytics: '/analytics',
    efr: '/analytics',
    nrum: '/analytics',
    logs: '/analytics',
    cssWaf: '/analytics',
    cssBot: '/analytics',

    // account urls
    logout: '/logout',
    changePwd: '/account',
    updateProfile: '/account',
    viewUsersApiKeys: '/account',
    TFAMgmt: '/account',
    CSALite: '/csalite',
    
    // the next 4 are analytic features that currently don't exist anymore
    push: '/analytics',
    wirelessStreamingAccel: '/analytics',
    streamingOptimization: '/analytics',
    cdnLoadBalancer: '/analytics',

    // login urls
    angularResetLink: '/#!/reset-password',
    angularActivate:  '/#!/v1/user/activate',
    reset: '/reset',
    activate: '/activate',
    invite: '/invite',
    expired: '/expired',

    // server urls
    serverGetMetric:        '/get-metrics',
    serverGetMetricKeys:    '/get-metrics-key',
    serverLogin:            '/login',
    serverCheckLoggedIn:    '/v1/login_info',
    serverTfaLogin:         '/v1/login_with_otp',
    serverLogout:           '/logout',
    serverReqResetPwd:      '/user/forgot_password',
    serverTokenValidation:  `/v1/tokens/status`,
    serverGetDDDnsWeights:  (projectId: string): string => { return `/v1/projects/${projectId}/analytics/dns_director/dns_weight`},
    serverCSMUpTime:        (projectId: string): string => { return `/v1/projects/${projectId}/analytics/cloud_service_monitor/uptime`},
    serverCSMLogs:          (projectId: string): string => { return `/v1/projects/${projectId}/analytics/cloud_service_monitor/logs`},

    serverLogs:             '/elasticsearch/query_log',
    serverESAnalytics:      '/elasticsearch/query_analytics',
    serverChangePassword:   '/user/change_password',
    serverChangeProfile:    (userId: string): string => `/v1/users/${userId}`,
    
    serverCache:            '/v1/cache/purge',
    serverReports:          '/v1/reports',

    serverOrgs:             '/v1/orgs',
    serverChangeOrgs:       (orgId: string): string => {return `/v1/orgs/${orgId}/switch`},
    serverUpdateOrgInfo:    (orgId: string): string => {return `/v1/orgs/${orgId}`},
    serverOrgLimits:         (orgId: string): string => {return `/v1/orgs/${orgId}/settings`},
    
    serverAdminUserList:    '/v1/users',
    serverUserProjects:     (userId: string): string => {return `/v1/users/${userId}/projects`},
    serverManageUser:       (userId: string): string => {return `/v1/users/${userId}`},
    serverResendUserActivateEmail: (userId: string): string => {return `/v1/users/${userId}/resend_invite`},
    
    serverUserApiKeys:      (userId: string): string => {return `/v1/users/${userId}/api_keys`},

    serverAdminProjectList: '/v1/projects',
    serverAllProjectSolutions: '/v1/solutions',
    serverProjectUsers:     (projectId: string): string => { return `/v1/projects/${projectId}/users`},
    serverManageProject:    (projectId: string): string => { return `/v1/projects/${projectId}`},
    serverProjectSolutions: (projectId: string): string => { return `/v1/projects/${projectId}/solutions`},

    serverUriCodeManagement:      (projectId: string): string => { return `/v1/projects/${projectId}/uri_codes` },
    serverUserApiKeyManagement:   (userId: string): string => { return `/v1/users/${userId}/api_keys`},
    serverApiKeyUpdate:           (apiKeyId: string): string => { return `/v1/api_keys/${apiKeyId}`},

    serverCertManagement:         `/v1/certs`,
    serverCertUpdate:             (certId: string): string => { return `/v1/certs/${certId}` },

    serverServiceVersions:        (svcid: string): string => { return `/v1/services/${svcid}/versions` },
    serverServiceMgmtProjectLvl:  `/v1/services`,
    serviceProjectPlatforms:      (projectId: string): string => { return `/v1/projects/${projectId}/platforms` },
    serverServiceManagement:      (svcid: string): string => { return `/v1/services/${svcid}` },
    serverServiceCopyAsDraft:     (svcid: string, version: number): string => {
                                        return `/v1/services/${svcid}?base_version=${version}` + 
                                        `&overwrite_draft=true` },

    serverDeployServiceToStaging: (svcid: string): string => {
                                        return `/v1/services/${svcid}/publish` },
    serverDeployServiceToProd:    (svcid: string): string => {
                                        return `/v1/services/${svcid}/promote_to_prod` },
    serverDeploymentHistory:      (svcid: string): string => { return `/v1/services/${svcid}/deployment_history`},

    
    serverTfaGeneration:          (userId: string): string => { 
                                        return `/v1/users/${userId}/two_factor_auth/otp_token/generate?format=qr` },
    serverTfaActivate:            (userId: string): string => { 
                                        return `/v1/users/${userId}/two_factor_auth/otp_token/activate` },
    serverTfaDeactivate:          (userId: string): string => {
                                        return `/v1/users/${userId}/two_factor_auth/otp_token/deactivate` },
    
    serverCSAVerify:              '/internal/zy/validate_presales_csv',
    serverCSAUpload:              (serviceId: string): string => { return `/v1/services/${serviceId}/data_files?file_type=csalite`},
    serverCSAJobList:             (serviceId: string): string => { return `/v1/services/${serviceId}/data_files` },

    serverProductsAndAttributes:  (projectId: string): string => {return `/v1/projects/${projectId}/products`},
    // serverProductsAndAttributes:     (projectId: string): string => { console.log(projectId); return `##https://console-local.zycada.com/v1/products` },

    serverBillingReportsAvailable:          `##https://console-local.zycada.com/v1/reports/overview`,
    // serverBillingReportsAvailable:          `/v1/reports/overview`,
    serverBillingReportsMonthly:            (reportType: string): string => {
                                                    return  `##https://console-local.zycada.com/v1/reports/${reportType}/projects` },
                                                    // return  `/v1/reports/${reportType}/projects` },
    serverBillingServicesReportsMonthly:    (reportType: string): string => {
                                                    return  `##https://console-local.zycada.com/v1/reports/${reportType}/services` },
                                                    // return  `/v1/reports/${reportType}/services` },
}

export default ZURLS;
