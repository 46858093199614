import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import { LOG_STRINGS as Strs } from '../../shared/strings';
import ZDropdown from '../../shared/ZDropDown';
import { HeaderFilterOpsIndexEnum } from '../../reducers/reducerEnums';
import ZButton from '../../shared/ZButton';

export interface LogHeaderProps {
  onHide: () => void,
  show: boolean,
  headerTypeIdx: number,
  filterOp: HeaderFilterOpsIndexEnum,
  filterName: string,
  filterValue: string,
  filterList: JSX.Element,
  onHeaderTypeIndexChange: (index: number) => void,
  onFilterDataChange: (filterName: string, filterValue: string, op: HeaderFilterOpsIndexEnum) => void,
  onAddFilter: (isRequest: boolean,  filterOp: HeaderFilterOpsIndexEnum, filterName: string, 
                filterValue: string) => void,
  onDeleteFilter: (index: number) => void,
  onToggleFilter: (index: number) => void
}

class LogHeaderFilters extends React.Component<LogHeaderProps> {

  render(): JSX.Element {
    const headerTypes: string[] = [Strs.requestHeader, Strs.responseHeader];
    const filterOps: string[] = [Strs.is, Strs.isNot, Strs.exists, Strs.doesNotExist];
    const headerTypeDropdown = 
              ZDropdown( headerTypes, this.props.headerTypeIdx, 'headerType',
                         (index) => { (this.props.onHeaderTypeIndexChange)(index); });
    const filterOpsDropdown = 
              ZDropdown(filterOps, this.props.filterOp, 'filterTypes', 
                        (index) => {
                          this.props.onFilterDataChange(this.props.filterName, this.props.filterValue, index)});
    const filterValueCtrl = this.buildFilterValueField();
    const isAddDisabled = this.isAddFilterDisabled(); 

    return (
      <Modal onHide={() => { this.props.onHide() }} show={this.props.show} size='lg' dialogClassName="zmodal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {Strs.addReqOrRespFilterTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row one-line">
            <div className="col-md-12">
              <div className="one-line">
                {Strs.filterType} {headerTypeDropdown}
              </div>
            </div>
        </div>

        <div className="row pad-top">
          <div className="col-md-12">
            Header Field:
            <div className="filter-indent zinput one-line">
            <input id="filterName" type="text" autoComplete="off" autoFocus
                  value={this.props.filterName} size={25} maxLength={256} placeholder={ Strs.exampleHeader }
                  onKeyUp={ (e: React.KeyboardEvent<HTMLInputElement>) => { this.inputCheck(e) } }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => 
                             this.props.onFilterDataChange(e.currentTarget.value, 
                                                           this.props.filterValue, this.props.filterOp)} /> &nbsp;&nbsp;
              {filterOpsDropdown}&nbsp;&nbsp;
              {filterValueCtrl}
            </div>
        </div>

        <div className="row pad-top">
          <div className="col-md-12">
          {this.props.filterList}
          </div>
        </div>
    </div>
        </Modal.Body>
        <Modal.Footer>
          <ZButton disabled={isAddDisabled} onClick={() => {this.handleAddFilter()}}>{Strs.addFilter}</ZButton>
          <ZButton onClick={this.props.onHide} btnType="primary">{Strs.done}</ZButton>
        </Modal.Footer>
      </Modal>
    );
  }

  handleAddFilter(): void {
    if (this.isAddFilterDisabled()) {
      return;
    }
    
    const {headerTypeIdx, filterName, filterOp} = this.props;
    let filterValue = '';

    if (filterOp === HeaderFilterOpsIndexEnum.opIs || filterOp === HeaderFilterOpsIndexEnum.opIsNot) {
      filterValue = this.props.filterValue;
    } 
    
    this.props.onAddFilter((headerTypeIdx === 0), filterOp, filterName, filterValue);
  }

  buildFilterValueField(): JSX.Element {
    return (this.props.filterOp === HeaderFilterOpsIndexEnum.opIs || 
            this.props.filterOp === HeaderFilterOpsIndexEnum.opIsNot) ?
              (<input className="zinput" id="filterName" type="text" autoComplete="off" value={this.props.filterValue} 
                      size={25} maxLength={256} 
                      placeholder={ Strs.value } 
                      onKeyUp={ (e: React.KeyboardEvent<HTMLInputElement>) => { this.inputCheck(e) }}
                      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => 
                        this.props.onFilterDataChange(this.props.filterName, e.currentTarget.value, 
                                                      this.props.filterOp) } />) : <span></span>;
  }

  inputCheck(e: React.KeyboardEvent<HTMLInputElement>): void {
    let addFilter = false;
    if (e.keyCode === 13) {
      if (!this.isAddFilterDisabled()) {
        addFilter = true;
      }
    }
    if (addFilter) {
      this.handleAddFilter();
    }
  }

  isAddFilterDisabled(): boolean {
    let disabled = false;
    const filterOp = this.props.filterOp;
    // filter name must be non-blank
    if (this.props.filterName.length <= 0) {
      disabled = true;
    }

    // if op is 'is' or 'is not' then filterValue must be non-blank
    if (!disabled && (filterOp === HeaderFilterOpsIndexEnum.opIs || filterOp === HeaderFilterOpsIndexEnum.opIsNot)) {
      if (this.props.filterValue.length <= 0) {
        disabled = true;
      }
    }
    return disabled;
  }
}

export default LogHeaderFilters;
