import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';
import * as actionCreators from '../../actions/actionCreators';

import { EProjectState, ZFeaturePopupTypes } from '../../reducers/reducerEnums';
import { ADMIN_STRINGS as Strs, MISC_STRINGS } from '../../shared/strings';

import logger from '../../shared/logUtilities';

import { APIKeyAdminProps } from './ApiKeyAdmin';
import { ApiKeyControl, loadUserApiKeys, buildGenerateApiKeyPopup, buildEditApiKeyPopup, buildNewKeySuccess, 
    buildExpireKeysPopup } from './apiKeyUtils';

class ViewMyApiKeys extends React.Component<APIKeyAdminProps> {
    componentDidMount(): void {
        logger.log('mounting Viewing My Api Keys');
        this.initViewMyAPIKeys();
    }

    componentDidUpdate(prevProps: APIKeyAdminProps): void {
        const adminUIState = this.props.adminUIState;
        const oldAdminState = prevProps.adminUIState;
        logger.log(`component did update (ViewMyApiKeys: ${adminUIState}`)
        if (adminUIState.adminState === EProjectState.dataLoadInProgress || 
            adminUIState.showExpiredKeys !== oldAdminState.showExpiredKeys) {
            loadUserApiKeys(this.props)
        }

        logger.log(`component did update (ViewMyApiKeys): ${adminUIState}`)
    }

    render(): JSX.Element {
        const org = this.props.authNServerData.currentOrg;
        const adminState = this.props.adminUIState;
        const maxKeysPerUserInOrg = org && org.max_api_keys_per_user ? org.max_api_keys_per_user : -1;
        const canCreateKeys = maxKeysPerUserInOrg > adminState.numActiveKeys;

        const usedKeys = (adminState.numActiveKeys === 1) ? Strs.numUsedKey(maxKeysPerUserInOrg) :
                                                            Strs.numUsedKeys(maxKeysPerUserInOrg, adminState.numActiveKeys)
        const markup = <ApiKeyControl state={this.props} showCreateBtn={ true } canCreateKeys={canCreateKeys} 
                            additionalCSS="admin-panel-extra-btm-space"  msg={usedKeys}
                       />;

        const popupBuilders = {
            [ZFeaturePopupTypes.GenerateKey]: buildGenerateApiKeyPopup,
            [ZFeaturePopupTypes.EditKey]: buildEditApiKeyPopup,
            [ZFeaturePopupTypes.ExpireKeys]: buildExpireKeysPopup,
            [ZFeaturePopupTypes.NewAPIKeyCreated]: buildNewKeySuccess,
        };
        let adminPopup = <span></span>;
        if (popupBuilders[this.props.adminUIState.popupType]) {
            adminPopup = (popupBuilders[this.props.adminUIState.popupType])(this.props, this.props.authNServerData.user);
        }
        return (
            <>
                <div className="feature-panel">
                <div className="administration-features">
                    <div className="title">{Strs.viewMyKeys}</div>
                    <div id="admin-tables" className="view-my-keys">
                        {markup}
                    </div>
                </div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
            {adminPopup}
            </>
        )
    }

    private initViewMyAPIKeys = (): void => {
        const user = this.props.authNServerData.user;

        if (user === undefined) {
            return;
        }
        this.props.setAdminUserList([user]);
        this.props.setUserListIndex(0);
        this.props.applySettings();
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        adminUIState: state.adminUIState,
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(ViewMyApiKeys);
