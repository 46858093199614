import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';
import * as actionCreators from '../../actions/actionCreators';
import { actionTypes } from '../../actions/actionCreatorTypes';

import { ZUserMin, ZRestResponse, ZProjectMin, ZUserTypes, FetchError, CreateUserBody, EditUserBody } 
        from '../../data/queryResultDefinitions';

import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { ZFeaturePopupTypes,  ZAdminMapType, EProjectState } from '../../reducers/reducerEnums';
import { anyPopupCheckBoxesChecked, canAddProjects, getNumCheckedAdminItems, getRemoveProjectList, 
         userIsAdmin } from '../../reducers/adminAccessors';
import { State as AdminStateData, AdminManagementTypes } from '../../reducers/adminState'

import { ADMIN_STRINGS as Strs, MISC_STRINGS } from '../../shared/strings';
import { ZGet, ZPost, ZDelete, ZPut } from '../../shared/backend';
import { NotificationStyles } from '../../shared/constants';
import { ProfilePwdValidationState } from '../../shared/authUtils';
import ListPanel from '../../shared/ListPanel';
import ZButton from '../../shared/ZButton';
import ZURLS from '../../shared/urls';
import logger from '../../shared/logUtilities';
import { buildUserName } from '../../shared/utilities';

import CreateEditUser from './CreateEditUser';
import GenDialog from '../../shared/GenDialog';

interface UserListInfo {
    userList: JSX.Element;
    someUsersNotActivate: boolean;
}

/* eslint-disable @typescript-eslint/member-delimiter-style */
export interface UserAdminProps extends State {
    // history: History,
    applySettings: () => actionTypes,
    setUserListIndex: (index: number) => actionTypes,
    setAdminUserList: (userList: ZUserMin[], showZAdmin?: boolean) => actionTypes,
    setProjectListIndex: (index: number) => actionTypes,
    setAdminProjectList: (projectList: ZProjectMin[]) => actionTypes,
    setUserProjectList: (projectList: ZProjectMin[]) => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes,
    closeNotification: () => actionTypes,
    toggleAdminPopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean) => actionTypes,
    toggleAdminSelection: (collectionName: ZAdminMapType, id: string) => actionTypes,
    toggleAdminPopupCheckmark: (index: number) => actionTypes,
    deleteUser: (userId: string) => actionTypes,
    validateProfile: (firstName: string, lastName: string,  email: string, phoneNumber: string) => actionTypes,
    validatePassword: (password: string, newPassword: string, confirmPwd: string) => void,
    setUserRole: (role: ZUserTypes) => actionTypes,
    addUserToAllProjects: (addToAllProjects: boolean) => actionTypes,
}
/* eslint-enable @typescript-eslint/member-delimiter-style */

// eslint-disable-next-line 
class UserAdmin extends React.Component<any, UserAdminProps> {
    componentDidMount(): void {
        logger.log('mounting user admin');
        this.initUserAdminPage();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: UserAdminProps): void {
        const adminUIState = this.props.adminUIState.adminState;
        logger.log(`component did update (user admin): ${adminUIState}`)
        if (adminUIState === EProjectState.tabChanged) {
            this.initUserAdminPage();
        }
        if (adminUIState === EProjectState.dataLoadInProgress) {
            this.loadUserList();
        }
    }

    render(): JSX.Element {
        const systemState = this.props.systemNavProjectState;
        const hasProjects = systemState._projectList.length > 0;
        const hasUsers = this.props.adminUIState.userList.length > 0;

        const userListInfo: UserListInfo = this.buildUserList(hasUsers);
        const userList = userListInfo.userList;
        const projectList = this.buildProjectList(hasProjects);
        // const users = this.props.adminUIState.userList;
        const user: ZUserMin = this.props.adminUIState.selectedUser;

        const popupBuilders = {
            [ZFeaturePopupTypes.AddProject]: this.buildAddProjectsPopup,
            [ZFeaturePopupTypes.RemoveProject]: this.buildRemoveProjectsPopup,
            [ZFeaturePopupTypes.CreateUser]: this.buildCreateUserPopup,
            [ZFeaturePopupTypes.EditUser]: this.buildEditUserPopup,
            [ZFeaturePopupTypes.DeleteUser]: this.buildDeleteUserPopup,
        }

        let userName = '';
        if (user && user.email && (user.email.length > 0)) {
            userName = (user.first_name && user.first_name.length > 0) ? 
                            `${user.first_name} ${user.last_name}` : user.email;
        }

        let canNotAddProjects = true;
        let canNotDeleteProjects = true;
        let userActivated = true; 
        
        if (user !== undefined && user.user_id !== undefined) {
            canNotAddProjects = !(hasProjects && canAddProjects(this.props.adminUIState));
            canNotDeleteProjects = !hasProjects || userIsAdmin(user.role) || 
                                      (getNumCheckedAdminItems(this.props.adminUIState, AdminManagementTypes.users) === 0);
            userActivated = user.email_verified;
        } 
        let adminPopup = <span></span>;
        if (popupBuilders[this.props.adminUIState.popupType]) {
            adminPopup = (popupBuilders[this.props.adminUIState.popupType])();
        }
        const userHeader: JSX.Element = (<div className="admin-ua-usr-list-header">
                <div></div>
                <div>{Strs.user}</div>
                <div>{Strs.numProjects}</div>
                <div>{Strs.role}</div>
            </div>);

        let activateBtn: JSX.Element = <span></span>;
        if (!userActivated) {
            activateBtn = (
                <>
                <ZButton onClick={(): void => { this.handleResend(); }}>{Strs.resendEmail}</ZButton>&nbsp;&nbsp;
                </>
            );
        }

        const userFooter: JSX.Element = (
            <div className="list-panel-footer-3col">
                <div>{activateBtn}</div>
                <div></div>
                <div>
                    <ZButton onClick={(): void => { this.handleCreateUser(); }}>{Strs.createUser}</ZButton>
                    &nbsp;&nbsp;
                    <ZButton onClick={(): void => { this.handleEditUser(); }} disabled={! hasUsers}
                        btnCls="list-panel-footer-item">{Strs.editUser}</ZButton> 
                    &nbsp;&nbsp;
                    <ZButton disabled={!(hasUsers && this.isLoggedInUserSelected())} 
                            onClick={(): void => { this.handleDeleteUser() }}>{Strs.deleteUser}</ZButton>
                </div>
            </div>
        );

        const projHeader: JSX.Element = (<div className="admin-ua-proj-list-header">
                <div></div><div>{hasUsers ? Strs.projectFor(userName) : Strs.noProjects}</div>
            </div>);

        const projFooter = (
            <div className="list-panel-footer">
                <ZButton onClick={(): void => { this.handleAddProjects(); }} disabled={canNotAddProjects}>
                    {Strs.addProjects}
                </ZButton>
                &nbsp;&nbsp;
                <ZButton onClick={(): void => { this.handleRemoveProjects(); }} 
                    disabled={canNotDeleteProjects} btnCls="list-panel-footer-item">
                    {Strs.removeProjects}
                </ZButton> 
            </div>
        );
        return (
            <>
                <div className="title">{Strs.userManagement}</div>
                <div id="admin-tables" className="user-admin">
                    <div className="admin-panel">
                        <ListPanel 
                            renderHeader={(): JSX.Element => { return userHeader }}
                            renderBody={(): JSX.Element => { return userList}}
                            renderFooter={(): JSX.Element => {return userFooter}}/>
                        <div>{userListInfo.someUsersNotActivate ? Strs.usersNotActivated : ''}</div>
                    </div>
                    <div className="admin-panel">
                        <ListPanel 
                            renderHeader={(): JSX.Element => { return projHeader }}
                            renderBody={(): JSX.Element=> { return projectList}}
                            renderFooter={(): JSX.Element => {return projFooter}}/>
                    </div>
                </div>
                {adminPopup}
            </>
        )
    }

    //
    // Rendering routines
    private buildUserList = (hasUsers: boolean): UserListInfo => {
        const selectedUserIdx = this.props.adminUIState.userListIdx
        const userList = this.props.adminUIState.userList;
        const usrListStuff = [];
        let usersNeedActivation = false;

        if (hasUsers) {
            for (let i = 0, len = userList.length; i < len; ++i) {
                const selected = (i === selectedUserIdx) ? 'selected' : '';
                const user = userList[i];
                const numProjects = (user.meta && user.meta.num_projects !== undefined) ? user.meta.num_projects : '-';
                const actIndicator = !user.email_verified ? '* ' : '';
                const username = actIndicator + buildUserName( user );
                usersNeedActivation = usersNeedActivation || !user.email_verified;
                
                usrListStuff.push((
                    <div key={'line' + i} className={'admin-ua-usr-list-item ' + selected}  
                        onClick={(): void => {this.handleSelectedUserChanged(i)}}>
                        <div></div>
                        <div>{username}</div>
                        <div>{numProjects}</div>
                        <div className="capitalize-text">{user.role}</div>
                    </div>));
            }
        } else {
            usrListStuff.push((
                <div key={'line-nousers'} className={'admin-ua-usr-list-item '} >
                    <div></div>
                    <div>{Strs.noUsersInOrg}</div>
                    <div></div>
                    <div className="capitalize-text"></div>
                </div>));
        }
        const usrBody: JSX.Element = <>{usrListStuff}</>
        logger.log(`some userNotActivated is ${usersNeedActivation}`)
        return { userList: usrBody, someUsersNotActivate: usersNeedActivation};
    }

    private buildProjectList = (hasProjects: boolean): JSX.Element => {
        const { projectList, adminState } = this.props.adminUIState;
        if (adminState !== EProjectState.ready) {
            return <span></span>;
        }
        const projectListStuff = [];
        if (hasProjects) {
            if (projectList.length > 0) {
                const user = this.props.adminUIState.selectedUser;
                const isAdmin = userIsAdmin(user.role) || user.auto_add_to_all_projects;
                for (let i = 0, len = projectList.length; i < len; ++i) {
                    const entry = this.buildProjectListEntry(user, isAdmin, projectList[i], i)
                    projectListStuff.push(entry);
                }
            }
        } else {
            const msg = (adminState === EProjectState.dataLoadInProgress) ? '' : Strs.noProjectsInOrg;
            projectListStuff.push(
                (
                    <div key={'line-noproj'} className='admin-ua-proj-list-item'>
                        <div></div>
                        <div>{msg}</div>
                    </div>
                )
            )
        }

        const projBody: JSX.Element = <>{projectListStuff}</>
        return projBody;
    }

    private buildProjectListEntry = (user: ZUserMin, isAdmin: boolean, proj: ZProjectMin, index: number): JSX.Element => {
        const selected = (index === this.props.adminUIState.projectListIdx) ? 'selected' : '';
        const className = 'admin-ua-proj-list-item ' + selected;
        const checked = this.props.adminUIState.checkedProjects[proj.project_id];
        const myIndex = index;
        return (
            <div key={'line' + index} className={className} onClick={(): void => { 
                this.props.setProjectListIndex(myIndex);
                if (!isAdmin) {
                    this.props.toggleAdminSelection(ZAdminMapType.SelectedUserProject, proj.project_id);
                }
            }}>
                <div>
                    <input type="checkbox" disabled={isAdmin} checked={checked} 
                           onChange={(): void => { logger.log('UserAdmin: checked Project')}}></input>
                </div>
                <div>{proj.name}</div>
            </div>
        )
    }

    //
    // data loading methiods
    //
    private initUserAdminPage = async (): Promise<void> => {
        let url = buildUrl(this.props.authNServerData, ZURLS.serverAdminProjectList);

        try {
            const projList: ZRestResponse = await ZGet(url, {});
            this.props.setAdminProjectList(projList as ZProjectMin[]);
            
            url = buildUrl(this.props.authNServerData, ZURLS.serverAdminUserList);
            const currentUser = this.props.authNServerData.user;
            const userList: ZRestResponse = await ZGet(url, {});
            
            const usrList = userList as ZUserMin[];
            const currentUserIdx = usrList.findIndex((usr: ZUserMin) => {
                return (usr.user_id === currentUser.user_id)})
            const showZAdmin = currentUserIdx !== -1;
            
            this.props.setAdminUserList(usrList, showZAdmin);
            this.handleSelectedUserChanged(this.props.adminUIState.userListIdx, true);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`UserAdmin.initUserAdminPage: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    private loadUserList = async (): Promise<void> => {
        try {
            const url = buildUrl(this.props.authNServerData, ZURLS.serverAdminUserList);
            const userList: ZRestResponse = await ZGet(url, {});
            this.props.setAdminUserList(userList as ZUserMin[], this.props.adminUIState.showZAdminRole);
            this.handleSelectedUserChanged(this.props.adminUIState.userListIdx, true);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`UserAdmin.loadUserData: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    private loadSelectedUsersProjects = (index: number): void => {
        const users = this.props.adminUIState.userList;
        if (users.length > 0) {
            const user = this.props.adminUIState.userList[index];
            this.loadUserProjects(user);
        }
    }
    
    private loadUserProjects = (user: ZUserMin): void => {
        const userId = (user) ? user.user_id : '';
        if (userId && userId.length > 0) {
            let url = ZURLS.serverUserProjects(userId);
            url = buildUrl(this.props.authNServerData, url);
            this.loadUserProjectData(url, this.props.setUserProjectList);
        }
    }

    private loadUserProjectData = async (url: string, fct: (projectList: ZProjectMin[]) => actionTypes): Promise<void> => {
        try {
            const projList: ZRestResponse = await ZGet(url, {});
            (fct)(projList as ZProjectMin[]);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`UserAdmin.loadUserProjectData: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    //
    // utilties methods
    //
    private isLoggedInUserSelected = (): boolean => {
        const selectedUser  = this.props.adminUIState.selectedUser;
        const user = this.props.authNServerData.user;
        return (user !== undefined && selectedUser && (selectedUser.user_id !== user.user_id));
    }

    private closePopup  = (): void => {
        this.props.toggleAdminPopup(ZFeaturePopupTypes.NoPopup, false);
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }

    //
    // methods related to user actions (button clicks, etc)
    //
    private handleSelectedUserChanged = (index: number, force = false): void => {
        if ( (index !== this.props.adminUIState.userListIdx) || force) {
            this.props.setUserListIndex(index);
            this.loadSelectedUsersProjects(index);
        }
    }

    // **
    // Resend email
    //
    private handleResend = (): void => {
        console.log('user clicked resend');
        const user: ZUserMin = this.props.adminUIState.selectedUser;
        if (user.email_verified) {
            console.log(`UserAdmin.handleResend.  User (${user.email}) has a verified email.`)
            return;
        }

        let url = ZURLS.serverResendUserActivateEmail(user.user_id);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZPost(url, '');
        promise.then( ( ) => {
            this.props.showNotification(NotificationStyles.success, Strs.resentInviteMsg(user.email));
            this.closeNotification();
        });
        promise.catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        })

    }
    
    // *******
    // Add Project Methods
    //
    private handleAddProjects = (): void => {
        if (canAddProjects(this.props.adminUIState)) {
            this.props.toggleAdminPopup(ZFeaturePopupTypes.AddProject, true);
        }
    }

    private buildAddProjectsPopup = (): JSX.Element => {
        if (!canAddProjects( this.props.adminUIState )) {
            return <span></span>;
        }

        const projectList = this.props.adminUIState.potentialUserProjectList;
        const checkedItems = this.props.adminUIState.popupCheckState;

        const projects = projectList.map((project: ZProjectMin, idx: number) => {
            return (
                <div key={'projects' + idx} onClick={(): void => { this.props.toggleAdminPopupCheckmark(idx); }}>
                <input checked={checkedItems[idx]} type="checkbox" onChange={(): void => { 
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    }}></input>
                    &nbsp;&nbsp;{project.name}
                </div>
            )
        })

        const addDisabled = !anyPopupCheckBoxesChecked(this.props.adminUIState)
        const add = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.addProjects} 
                msg={''}
                css="zmodel-33"
                applyBtnTxt={Strs.addProjects}
                disableApply={(): boolean => {
                    return addDisabled}
                }
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { this.applyAddProjects(); }}
            >
                <div className="list-boundary modal-maxsize">{projects}</div>
            </GenDialog>
        );

        return add;
    }

    private applyAddProjects = (): void => {
        const { popupCheckState, potentialUserProjectList } = this.props.adminUIState;

        const projectsToAdd: Record<string, string>[] = [];
        potentialUserProjectList.forEach((project: ZProjectMin, idx: number) => { 
            if (popupCheckState[idx]) { projectsToAdd.push({ 'project_id': project.project_id }); }
        });
        let url = ZURLS.serverUserProjects(this.props.adminUIState.selectedUser.user_id);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZPost(url, JSON.stringify(projectsToAdd));
        promise.then( ( ) => {
            this.props.applySettings();
        });
        promise.catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        })
    }

    // *******
    // Remove Project Methods
    //
    private handleRemoveProjects = (): void => {
        if (!userIsAdmin(this.props.adminUIState.selectedUser.role) && 
            (getNumCheckedAdminItems(this.props.adminUIState, AdminManagementTypes.users) > 0)) {
                this.props.toggleAdminPopup(ZFeaturePopupTypes.RemoveProject, true);
        }
    }

    private buildRemoveProjectsPopup = (): JSX.Element => {
        if (userIsAdmin(this.props.adminUIState.selectedUser.role) ||
            (getNumCheckedAdminItems(this.props.adminUIState, AdminManagementTypes.users) === 0)) {
            return <span></span>;
        }
            
        const user = this.props.adminUIState.selectedUser;

        const removeList = getRemoveProjectList(this.props.adminUIState);

        const projList: JSX.Element[] = [];
        for (let i = 0, len = removeList.length; i < len; i++) {
            projList.push(<li key={'projlist' + i}>{removeList[i].name}</li>);
        }

        const msg = Strs.confirmProjectRemoval(user.email);
        const confirmRemove = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.removeProjects} 
                msg={msg}
                applyBtnTxt={MISC_STRINGS.yes}
                cancelBtnTxt={MISC_STRINGS.no}
                disableApply={(): boolean => { return false }}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { this.applyRemoveProjects(); }}
            >
                <ul className="list-boundary">
                    {projList}
                </ul>
            </GenDialog>
        );

        return confirmRemove;
    }

    private applyRemoveProjects = (): void => {
        const projectToRemoveJSON: Record<string, string>[] = [];
        const projectsToRemove = getRemoveProjectList(this.props.adminUIState);
        projectsToRemove.forEach((project: ZProjectMin) => { 
            projectToRemoveJSON.push({ 'project_id': project.project_id }); 
        });

        let url = ZURLS.serverUserProjects(this.props.adminUIState.selectedUser.user_id);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZDelete(url, JSON.stringify(projectToRemoveJSON));
        promise.then( ( ) => {
            this.props.applySettings();
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });
    }

    // *******
    // Create User Methods
    //
    private handleCreateUser = (): void => {
            this.props.toggleAdminPopup(ZFeaturePopupTypes.CreateUser, true);
    }

    private showZAdmin = (): boolean => {
        const loggedinUser: ZUserMin = this.props.authNServerData.user;
        const showZAdminRole = this.props.adminState.showZAdminRole;

        // if the user is a zadmin but is not i
        let bShowZAdmin = false;
        if (loggedinUser) {
            bShowZAdmin = (showZAdminRole && loggedinUser.role === ZUserTypes.zadmin);
        }

        return bShowZAdmin;
    }

    private buildCreateUserPopup = (): JSX.Element => {
        const adminState: AdminStateData = this.props.adminUIState;
        const { profileValidation } = adminState;
        const showZAdmin = this.showZAdmin();

        const createUser = <CreateEditUser
                            show={adminState.popupOpen}  editingUser={false} isCurrentUser={false} addToAllProjects={adminState.addUserToAllProjects}
                            createUserState={adminState as ProfilePwdValidationState} currentRole={ZUserTypes.user}
                            setRole={(role: ZUserTypes): void => { this.props.setUserRole(role) }}  
                            addUserToAllProjectsHandler={(addUser: boolean): void => { this.props.addUserToAllProjects(addUser)}}
                            validateEmail={(email: string): void => { this.props.validateProfile('firstName', 'lastName', email, '')}}
                            onHide={() => {this.closePopup()}} disableApply={() => {return !profileValidation.validProfile}}
                            handleApply={() => { this.applyCreateUser(); }} showZAdmin={showZAdmin}
        />

        return createUser;
    }

    private applyCreateUser = (): void => {
        logger.log('applied create user');
        const {email, role, profileValidation, addUserToAllProjects } = this.props.adminUIState;
        if ( !profileValidation.validProfile ) {
                logger.alert('Create user fields are bad.');
                return;
        }

        const newUser: CreateUserBody = {
            'email': email as string,
            'role': role as string,
        }

        const isSelectedRoleAdmin = (role === ZUserTypes.admin) || (role === ZUserTypes.zadmin)
        if (!isSelectedRoleAdmin) {
            newUser.auto_add_to_all_projects = addUserToAllProjects;
        }
        const url = buildUrl(this.props.authNServerData, ZURLS.serverAdminUserList);

        const promise = ZPost(url, JSON.stringify(newUser));
        promise.then( ( ) => {
            this.props.applySettings();
        });
        promise.catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                if (err.message === MISC_STRINGS.illFormedUrl) {
                    err.message = Strs.userExists;
                }
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        })
    }

    // *******
    // Edit User Methods
    //
    private handleEditUser = (): void => {
        logger.log('Edit User'); 
        this.props.toggleAdminPopup(ZFeaturePopupTypes.EditUser, true);
    }

    private buildEditUserPopup = (): JSX.Element => {
        const adminState: AdminStateData = this.props.adminUIState;
        const loggedInUser = this.props.authNServerData.user;
        const isCurrentUser = (loggedInUser !== undefined && 
                            (this.props.adminUIState.selectedUser.user_id === loggedInUser.user_id));
        const selectedUser: ZUserMin = this.props.adminUIState.selectedUser;

        const showZAdmin = this.showZAdmin();

        const editUser = <CreateEditUser
                            show={adminState.popupOpen} editingUser={true} isCurrentUser={isCurrentUser}
                            createUserState={adminState}  addToAllProjects={adminState.addUserToAllProjects}
                            currentRole={selectedUser.role}  setRole={(role: ZUserTypes) => { this.props.setUserRole(role) }}
                            validateEmail={(emailAddr: string) => { this.props.validateProfile('firstName', 'lastName', emailAddr, '')}}
                            onHide={() => {this.closePopup()}} disableApply={() => { return this.editUserApplyBtnDisabled() }}
                            handleApply={() => { this.applyEditUser(); }} showZAdmin={showZAdmin}
                            addUserToAllProjectsHandler={(addUser: boolean): void => { this.props.addUserToAllProjects(addUser)}}

        />

        return editUser;
    }

    private editUserApplyBtnDisabled = (): boolean => {
        const {selectedUser, role, addUserToAllProjects} = this.props.adminUIState;
        let disabled = true;

        if (selectedUser.role !== role) {
            disabled = false;
        }

        if (selectedUser.auto_add_to_all_projects !== addUserToAllProjects) {
            disabled = false;
        }

        return disabled 
    }

    // private processPhoneNum = ( ph: string):  string => {
    //     const phNum: string[] = [];
    //     if (phNum !== undefined) {
    //         for (let i = 0, len = ph.length; i < len; i++) {
    //             const c = ph[i];
    //             if (c >= '0' && c <= '9') {
    //                 phNum.push(c);
    //             }
    //         }
    //     }
    //     return phNum.join('');
    // }

    private applyEditUser = (): void => {
        logger.log('applied Edit user');
        const {email, role, addUserToAllProjects } = this.props.adminUIState;

        const editUser: EditUserBody = {
            'email': email,
        }

        const oldRole = (this.props.adminUIState.selectedUser && this.props.adminUIState.selectedUser.role) ? 
                        this.props.adminUIState.selectedUser.role : '';
        if (oldRole !== role) {
            editUser.role = role as string;
        }

        const isSelectedRoleAdmin = (role === ZUserTypes.admin) || (role === ZUserTypes.zadmin)
        if (!isSelectedRoleAdmin) {
            editUser.auto_add_to_all_projects = addUserToAllProjects;
        }

        let url = ZURLS.serverManageUser(this.props.adminUIState.selectedUser.user_id);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZPut(url, JSON.stringify(editUser));
        promise.then( ( ) => {
            this.props.applySettings();
            this.props.showNotification(NotificationStyles.success, Strs.userUpdated);
            this.closeNotification();
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });
    }

    // *******
    // Delete User Methods
    //
    private handleDeleteUser = (): void=> {
        logger.log('Delete User'); 
        const user = this.props.authNServerData.user;
        const isCurrentUser = (user !== undefined && (this.props.adminUIState.selectedUser.user_id === user.user_id));
        if (isCurrentUser) {
            return;
        }
        this.props.toggleAdminPopup(ZFeaturePopupTypes.DeleteUser, true);
    }

    private buildDeleteUserPopup = (): JSX.Element=> {
        const user = this.props.authNServerData.user;
        const isCurrentUser = (user !== undefined && (this.props.adminUIState.selectedUser.user_id === user.user_id));
        if (isCurrentUser) {
            return <span></span>;
        }
            
        const suser = this.props.adminUIState.selectedUser;
        const userName = `${suser.first_name} ${suser.last_name} (${suser.email})`;

        const dialog = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.deleteUser} 
                msg={''}
                applyBtnTxt={MISC_STRINGS.yes}
                cancelBtnTxt={MISC_STRINGS.no}
                disableApply={(): boolean => { return false }}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { this.applyDeleteUser(); }}
            >
                <div>{Strs.confirmDeleteUser}<br /><br />{userName}</div>
            </GenDialog>
        );

        return dialog;
    }

    private applyDeleteUser = (): void => {
        const userId = this.props.adminUIState.selectedUser.user_id;
        let url = ZURLS.serverManageUser(userId);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZDelete(url, '');
        promise.then( ( ) => {
            this.props.deleteUser(userId);
            // this.props.setUserListIndex(0);
            this.props.applySettings();
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });
    }
}

// export default UserAdmin;
// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        adminState: state.adminUIState,
        authNServerData: state.authNServerData
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(UserAdmin);
