import { CacheAgeItem, RoutingFeatures, FeatureDef, CacheFeature, XffFeature, ZServiceExt, ZCSMEntity } from '../data/queryResultDefinitions';
import { DisplayRoutingRules, ServiceFieldValidate, servicesTypeAssetRule } from '../data/metricsAndOptionsDefs';
import { checkIPAddr, integerValidation, headerValueValidation, matchSvc, matchHost, matchOrigin, regMatch } from '../shared/utilities';

import { State } from './serviceState';
import { HealthCheckIndex } from './reducerEnums';
import { buildCSMEntry, splitAndCleanDomainsString} from '../shared/utilities';
import { MAX_DOMAIN_REDIRECTS } from '../shared/constants';
export interface SvcValidate {
    state: State, 
    fieldValid: ServiceFieldValidate
}
export interface validateSvcFctMap {
    [name:string]: (vData: SvcValidate) => ServiceFieldValidate
}

export const editServiceVerifications = (): validateSvcFctMap => {

    const validatetrafficPercentageValue = (weight: string): boolean => {
        let valid = true;
        const chars = weight.split('');
        for (let i=0, len=chars.length; i<len; i++) {
            valid = valid && (chars[i] >= '0' && chars[i] <= '9');
        }
        const weightNum = parseInt(weight);
        if (!valid || weightNum < 0 || weightNum > 100) {
            valid = false;
        }

        return valid;
    }
    
    const validateAllFields = (vData: SvcValidate): ServiceFieldValidate => {
        const state = vData.state
        const { isDataLoading } = state;

        let fieldValid: ServiceFieldValidate = {
            serviceName: true,
            domainName: true,
            originName: true,
            originDomainName: true,
            ddDomainName: true,
            zyTrafficPercentage: true,
            sni: true,
            rules: [],
            healthChecks: [],
            cdnTag: true,
            domainsRedirect: true,
            domainRedirectErrors: {
                hasSvcDomainName: true,
                inValidDomainName: true,
                tooManyDomains: true,
            }
        }
    
        if (isDataLoading) {
            return fieldValid;
        }
    
        fieldValid = validateOverviewData({state, fieldValid})
        fieldValid = validateOriginData({state, fieldValid});
        fieldValid = validateDDData({state, fieldValid});
        fieldValid = validateCSM({state, fieldValid});
    
        // fieldValid.isSaveable = isSaveable;   // following methods need current state of isSaveable
        fieldValid = validateRoutingRules({state, fieldValid});
    
        return  fieldValid;
    }

    const validateOverviewData = (vData: SvcValidate): ServiceFieldValidate => {
        const { state, fieldValid } = vData;
        let { serviceName, } = state;
        const { domainRedirects, domainName } = state;

        serviceName = serviceName.trim(); 
        // isSaveable = (serviceName.length > 0 && domainName.length > 0);
    
        if (serviceName.length > 0) {
            const fieldError = regMatch(serviceName, matchSvc);
            // isSaveable = isSaveable && fieldError;
            fieldValid.serviceName = fieldError;
        } else if (serviceName.length === 0) {
            fieldValid.serviceName = false;
        }

        if (domainRedirects.length > 0) {
            const redirects = splitAndCleanDomainsString(domainRedirects);

            const redirErrors = fieldValid.domainRedirectErrors;
            let redirValid = true;
            let numDomains = 0;

            if (redirects.length > 0) {
                let fieldError = true;
                redirects.forEach((domain: string) => {
                    domain = domain.trim();
                    if (domain.length !== 0) {
                        numDomains++;
                        fieldError = fieldError && regMatch(domain, matchHost);
                    }
                })
                redirErrors.inValidDomainName = fieldError;
                redirValid = fieldError;

                const zDomainIdx = redirects.findIndex((domain: string): boolean => {
                    return domain === domainName
                })
                fieldValid.domainRedirectErrors.hasSvcDomainName = zDomainIdx === -1;
                redirValid = redirValid && fieldValid.domainRedirectErrors.hasSvcDomainName;

                fieldValid.domainRedirectErrors.tooManyDomains = numDomains <= MAX_DOMAIN_REDIRECTS;
                redirValid = redirValid && fieldValid.domainRedirectErrors.tooManyDomains;
                fieldValid.domainsRedirect = redirValid
            }
        }

        return fieldValid;
    }
        
    const validateOriginData = (vData: SvcValidate): ServiceFieldValidate => {
        const { state, fieldValid } = vData;
        const { tlsEnabled } = state;
        let { originName, originDomainName, sni } = state;
        let isIPAddr = false;
        let fieldError = false;

        originName = originName.trim(); 
        originDomainName  = originDomainName.trim();

        if (originName.length === 0 && originDomainName.length === 0) {
            fieldValid.originName = true;
            fieldValid.originDomainName = true;
        }

        if (originName.length > 0) {
            const fieldError = regMatch(originName, matchOrigin);
            fieldValid.originName = fieldError;
        } else if (originName.length === 0 && originDomainName.length !== 0) {
            fieldValid.originName = false;
        }
    
        if (originDomainName.length > 0) {
            isIPAddr = checkIPAddr(originDomainName);
            if (! isIPAddr) {
                fieldError = regMatch(originDomainName, matchHost);
                fieldValid.originDomainName = fieldError;
            }
        } else if (originDomainName.length === 0 && originName.length !== 0) {
            fieldValid.originDomainName = false;
        }

        if (tlsEnabled) {
            sni = sni !== undefined ? sni.trim() : '';
            if (sni.length > 0) {
                fieldValid.sni = regMatch(sni, matchHost);
            } else if (sni.length === 0) {
                fieldValid.sni = true;
            }
        }

        return fieldValid
    }

    const validateDDData = (vData: SvcValidate): ServiceFieldValidate => {
        const { state, fieldValid } = vData;
        let { ddDomainName, zyTrafficPercentage, cdnTag } = state;

        ddDomainName = ddDomainName.trim();
        zyTrafficPercentage = zyTrafficPercentage.trim();
        cdnTag = cdnTag.trim();
        let fieldError = false;
        const isIPAddr = false;

        if (ddDomainName.length === 0 && cdnTag.length === 0 ) {
            fieldValid.ddDomainName = true;
            fieldValid.cdnTag = true;
        } else {
            // DNS Director handling.  Only check weight if the domain name is longer than 0 chars.
            if (ddDomainName.length > 0) {
                // uncomment next line when we add support for ip address in CDN Domains
                // isIPAddr = checkIPAddr(ddDomainName);
                if (! isIPAddr) {
                    fieldError = regMatch(ddDomainName, matchHost);
                    fieldValid.ddDomainName = fieldError;
                } else {
                    fieldValid.ddDomainName = true;
                }
            } else if (cdnTag.length !== 0) {
                fieldValid.ddDomainName = false;
            }

            if (cdnTag.length > 0) {
                const fieldError = regMatch(cdnTag, matchOrigin);
                fieldValid.cdnTag = fieldError;
            } else if (ddDomainName.length > 0) {
                fieldValid.cdnTag = false;
            }

            // if there is a DNS Director domain then we check the traffic weight 
            if (zyTrafficPercentage.length > 0) {
                fieldValid.zyTrafficPercentage = validatetrafficPercentageValue(zyTrafficPercentage);
            } else {
                fieldValid.zyTrafficPercentage = false;
            }
        }
    
        if (zyTrafficPercentage.length > 0) {
            fieldValid.zyTrafficPercentage = validatetrafficPercentageValue(zyTrafficPercentage);
        }

        return fieldValid;
    }

    const validateRoutingRules = (vData: SvcValidate): ServiceFieldValidate => {
        const { state, fieldValid } = vData;
        let masterValid = true;
        const validRules: boolean[] = [];
        const editableService = state.editableService
        const routingRules = editableService.rules ? editableService.rules : [];
        
        for (let i = 0, len = routingRules.length; i < len; i++) {
            const rule = routingRules[i];
            if (rule.asset_type === undefined || fieldValid === undefined) {
                continue;
            }
            const checkFields = servicesTypeAssetRule[rule.asset_type];
            if (checkFields === undefined) {
                continue;
            }
            const valid = validateFeatures(rule.features, checkFields)
            masterValid = masterValid && valid;
            validRules.push(valid);
        }
    
        const features = editableService.features;
        if (features) {
            const dynFeatures: RoutingFeatures = {
                cache: features.cache as CacheFeature,
                waf: features.waf as FeatureDef,
                xff: features.xff as XffFeature,
                image_mux: features.image_mux as FeatureDef,
            }
            const validDyn = validateFeatures(dynFeatures, servicesTypeAssetRule.dynamic)
            masterValid = masterValid && validDyn;
    
            validRules.push(validDyn)
        }
    
        fieldValid.rules = validRules;
    
        return fieldValid;
    }

    const validateCSM = (vData: SvcValidate): ServiceFieldValidate => {
        const { state, fieldValid } = vData;
        const { csmHealthChecks } = state;

        const validatePath = (path: string): boolean => {
            let valid = true;
            if (path) {
                if (path.length === 0) {
                    valid = true;
                } else if (path.charAt(0) !== '/') {
                    valid = false;
                } else {
                    const starPos = path.indexOf('*')
                    if (starPos !== -1) {
                        valid = false;
                    }
                }
            }

            return valid;
        }

        fieldValid.healthChecks.length = 0;
        if (csmHealthChecks !== undefined && csmHealthChecks.length > 0) {
            fieldValid.healthChecks.push(validatePath(csmHealthChecks[HealthCheckIndex.cdnCheck].request_path));
            fieldValid.healthChecks.push(validatePath(csmHealthChecks[HealthCheckIndex.originCheck].request_path));
        } else {
            fieldValid.healthChecks.push(true);
            fieldValid.healthChecks.push(true);
        }

        return fieldValid;
    }
    
    const validateFeatures = (features: RoutingFeatures, displayRules: DisplayRoutingRules): boolean => {
        let valid = true;
    
        if (displayRules.features.cache && features.cache) {
            const cacheRules: CacheAgeItem[] = (features.cache.default_age) ? features.cache.default_age : [];
            cacheRules.forEach((cRule: CacheAgeItem) => {
                const age = cRule.age;
                const value = age.substr(0, age.length - 1);
                valid = valid && integerValidation(value);
            });
        }
    
        if (displayRules.features.xff && features.xff && features.xff.enabled) {
            if (features.xff.enabled) {
                const value = features.xff.x_forwarded_for_header_name;
                valid = valid && headerValueValidation(value);
            }
        }
    
        return valid;
    }

    return {
        validateAllFields,
        validateOverviewData,
        validateOriginData,
        validateDDData,
        validateRoutingRules,
        validateCSM,
    }
}

export const isServiceSaveable = (state: State): boolean => {
    const { editSvcFieldValidate, cdnTag, ddDomainName, originName, originDomainName, projHasCSM, 
            isDirty, isLegacyService } = state;

    let saveable = true;
    saveable = saveable && isDirty;

    if (saveable) {
        // validate that we have valid syntax for each field
        saveable = saveable && editSvcFieldValidate.serviceName && editSvcFieldValidate.domainsRedirect;
        saveable = saveable && editSvcFieldValidate.originDomainName && editSvcFieldValidate.originName && editSvcFieldValidate.sni;
        if (! isLegacyService) {
            saveable = saveable && editSvcFieldValidate.ddDomainName && editSvcFieldValidate.cdnTag;
        }
        if (projHasCSM) {
            editSvcFieldValidate.healthChecks.forEach((hc: boolean): void => {
                saveable = saveable && hc;
            });
        }
        editSvcFieldValidate.rules?.forEach((rule: boolean): void => {
            saveable = saveable && rule;
        });

        let originSaveable = true;
        let ddSaveable = true;

        // now validate panel by panel
        originSaveable = (originName.length === 0 && originDomainName.length === 0) || 
                         (originName.length > 0 && originDomainName.length > 0);
        ddSaveable = (ddDomainName.length === 0 && cdnTag.length === 0) ||
                      (ddDomainName.length > 0 && cdnTag.length > 0);
        saveable = saveable && originSaveable && ddSaveable;
    }

    return saveable;
}

export const isServiceDeployableUI = (state: State): boolean => {
    const { ddDomainName, originName, originDomainName, isCDNReserve,  csmHealthChecks, cdnTag } = state;

    return internalIsSvcDeployable(ddDomainName, originName, originDomainName, cdnTag, csmHealthChecks, isCDNReserve );
}

export const isServiceDeployable = (state: State, svc: ZServiceExt): boolean => {
    const { origins, dns_director, cloud_service_monitor } = svc;
    const { ddAutoSwitchEnabled } = state;
    let isCdnReserve = false;

    let ddDomainName = '';
    let originName = '';
    let originDomainName = '';
    let cdnTag = '';

    if (origins) {
        const keys = Object.keys(origins);
        if (keys.length > 0) {
            originName = keys[0];
            originDomainName = origins[originName].domain_name;
        }
    }

    if (dns_director) {
        const cdns = dns_director.cdns;
        isCdnReserve = dns_director.auto_switch && ddAutoSwitchEnabled
        if (cdns !== undefined) {
            const cdnKeys = Object.keys(cdns);
            if (cdnKeys.length > 0) {
                cdnTag = cdnKeys[0];
                ddDomainName = cdns[cdnTag].cname;
            }
        }
    }

    const healthCheck: ZCSMEntity[] = [];
    if (cloud_service_monitor) {
        let hchk: ZCSMEntity = cloud_service_monitor['cdn_check'];
        healthCheck.push(buildCSMEntry(hchk));

        hchk = cloud_service_monitor['origin_check'];
        healthCheck.push(buildCSMEntry(hchk));
    }

    return internalIsSvcDeployable(ddDomainName, originName, originDomainName, cdnTag, healthCheck, isCdnReserve);
}

const internalIsSvcDeployable = (ddDomainName: string, originName: string, originDomainName: string, cdnTag: string,
                                 healthChecks: ZCSMEntity[], isCdnReserve: boolean,): boolean => {
    let isDeployable = false;

    const originSectionExists = (originName.length > 0 && originDomainName.length > 0);
    const ddSectionExists = ( ddDomainName.length > 0 && cdnTag.length > 0);

    if (isCdnReserve) {
        const csmValid = healthChecks[HealthCheckIndex.cdnCheck].request_path.length > 0 && 
                         healthChecks[HealthCheckIndex.originCheck].request_path.length > 0;
        isDeployable = originSectionExists && ddSectionExists && csmValid;
    } else {
        isDeployable = (originSectionExists || ddSectionExists);
    }

    return isDeployable;
}

