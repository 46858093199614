export enum authProgressStates {
    init = 'init',
    inProgress = 'inProgress',
    tfa = 'tfa',
    success = 'success',
    fail = 'fail',
}

export enum TFAState {
    unknown = 'unknown',
    gotSecret = 'gotSecret',
    confirming2FA = 'confirming2FA',
    disabling2FA = 'disabling2FA',
}

export enum PwdResetState {
    validTokenGetPwd = 'token-get-pwd',
    validTokenExpired = 'token-expired',
    invTokenMaxAttempts = 'invalid-token-max-requests',
    invTokenMaxRateReached = 'invalid-token-too-many-4-period',
    invToken = 'invalid-token',

    wantEmail = 'want-email',
    emailSent = 'email-sent',
    normalLogin = 'login,',
    invalidEmail = 'invalid-email',
    miscError = 'misc-error',
    unknown = 'unknown'
}

export enum MetricDataTypes {
    legacy = 'legacy',
    dnsdir = 'dnsdir'
}

export enum loginState {
    loggedIn = 'logged-in',
    loggedOut = 'logged-out',
    unknown = 'unknown'
}

export enum sessionStorageStates {
    init = 'init',
    clean = 'clean',
    dirty = 'dirty',
}

export enum serviceDisplayEnum {
    'undefined' = 'undefined',
    'none' = 'none',
    'environmentOnly' = 'environmentOnly',
    'deployed' = 'deployed',
    'deployedSingleService' = 'deployedSingleService',
    'stagingAll' = 'stagingAll',
    'prodAll' = 'prodAll',
    'all' = 'all',
}

export enum ZEnvironments {
    'unknown' = '',
    'staging' = 'staging',
    'prod' = 'prod',
}
export enum projectDisplayEnum {
    'none' = 'none',
    'justProjects' = 'justProjects',            // just list of projects
    'all' = 'all',                              // 'all' + list of projects
}

export enum UsageUnits  {
    bytes = 'bytes',
    gigaBytes = 'gigabytes',
    teraBytes = 'terabytes',
    petaBytes = 'petaBytes',
}

// BillingTrafficUnitIndex - used to get the correct field name from the BillingUnitsMap.  The correct array is selected based on UsageUnits.
export enum BTUnitsIdx {
    bytesIn = 0,
    bytesOut = 1,
    logBytesOut = 2
}

export const BillingUnitsMap = {
    [UsageUnits.bytes]: ['total_bytes_in', 'total_bytes_out', 'total_log_bytes_out' ],
    [UsageUnits.gigaBytes]: ['total_bytes_in_gb', 'total_bytes_out_gb', 'total_log_bytes_out_gb'],
    [UsageUnits.teraBytes]: ['total_bytes_in_tb', 'total_bytes_out_tb', 'total_log_bytes_out_tb'],
    [UsageUnits.petaBytes]: ['total_bytes_in_pb', 'total_bytes_out_pb', 'total_log_bytes_out_pb']
}

// The enum values much match the field names that are being passed from the server.
export enum TrafficBillingFieldsEnum  {
    'unknown' = '', 
    'projectName' = 'name',
    'domainName' = 'zycadized_domain_name',
    'totalRequests' = 'total_requests',
    'totalBytesOut' = 'total_bytes_out',
    'totalBytesIn' = 'total_bytes_in',
    'totalLogBytesOut' = 'total_log_bytes_out',
}

export enum SysFeatureEnums {
    unknown = 'unknown',
    login = 'not-logged-in',

    // categories
    account = 'account',
    admin = 'admin',
    analytics = 'analytics',
    cache = 'cache',
    dashboard = 'dashboard',
    services = 'services',
    reports = 'reports',
    svcMgmt = 'svcMgmt',
    logout = 'logout',
    organizations = 'organizations',
    
    // account tabs
    accountUpdateProfile = 'accountUpdateProfile',
    accountChgPwd = 'accountChangePwd',
    documentation = 'accountDocumentation',
    tfaMgmt = 'accountTfaMgmt',
    apiKeys = 'accountApiKey',
    pwdExpiring = 'pwdExpiring',

    // individual analytics tabs
    csaAnl = 'analyticsCSA',
    dnsDirAnl = 'analyticsDnsDir',
    csmAnl = 'analyticsCSM',
    apiAnl = 'analyticsAPI',
    cacheAnl = 'analyticsCache',
    efrAnl = 'analyticsEFR',
    wafAnl = 'analyticsWAF',
    botAnl = 'analyticsBOT',
    logsAnl = 'analyticsLogs',
    nrumAnl = 'analyticsNRUM',
    pushAnl = 'analyticsPush',
    streamLBAnl = 'analyticsStreamingLB',
    streamSOAnl = 'analyticsStreamingOptimization',
    streamWMAAnl = 'analyticsStreamingMWA',
    trafficAnl = 'analyticsTraffic',

    usersAdmin = 'adminUsers',
    projectsAdmin = 'adminProjects',
    apiKeyAdmin = 'adminKeys',
    certAdmin = 'adminCerts',
    
    viewServices = 'svcMgmtViewServices',
    editDraftService = 'svcMgmtEditDraftService',
    deployService = 'svcMgmtDeployService',
    deleteService = 'svcMgmtDeleteService',
    createService = 'svcMgmtCreateService',

    orgYourOrgs = 'orgYourOrgs',
    orgManageOrgs = 'orgManageOrgs',
    csaLiteConfig = 'csaLiteConfig',

    billingReports = 'billingReports',
    billingReportsTrafficUsage = 'billingReportsTrafficUsage',
    billingReportsDownloads = 'billingReportsDownloads',
}

export enum ZFeaturePopupTypes {
    NoPopup = 'noPopup',
    AddProject = 'AddProject',
    RemoveProject = 'RemoveProject',
    CreateProject = 'CreateProject',
    EditProject = 'EditProject',
    DeleteProject = 'DeleteProject',
    CreateUser = 'CreateUser',
    DeleteUser = 'DeleteUser',
    EditUser = 'EditUser',
    AddUser = 'AddUser',
    RemoveUser = 'RemoveUser',
    GenerateKey = 'GenerateKey',
    ExpireKeys = 'ExpireKeys',
    EditKey = 'EditKey',
    EditCert = 'EditCert',
    UploadCert = 'UploadCert',
    DeleteCert = 'DeleteCert',
    CopyServiceToDraft = 'CopyServiceToDraft',
    CreateService = 'CreateService',
    NewAPIKeyCreated = 'NewAPIKeyCreated',
    CSALite = 'CSALite',
    CreateOrg = 'CreateOrg',
    EditOrg = 'EditOrg',
    ShowServiceJson = 'ShowServiceJson',
    CantEditUser = 'CantEditUser',
    DiffServiceVersions = 'DiffServiceVersions',
    DeleteService = 'DeleteService',
    EditServiceJSON = 'EditServiceJSON',
    ExpiringPassword = 'ExpiringPassword',
    ExpiringPasswordUpdate = 'ExpiringPasswordUpdate'
}

export enum ZAdminMapType {             // these match the name of the maps in AdminState
    SelectedProjectUsers = 'checkedUsers',
    SelectedUserProject = 'checkedProjects',
    SelectedUserApiKey = 'checkedApiKeys',
}

export enum ZSortDirection {
    h2l = 'h2l',        // high to low
    l2h = 'l2h',        // low to high  - default
    asc = 'asc',
    desc = 'desc',
}

// TODO:  currently only for logs.  Needs to be updated for billing
export enum ZSortFields {
    timestamp = 'timestamp',
    responseTime = 'response_time',
}

export enum ZReportLoadState {
    unknown,
    overviewLoaded,
    reportDataLoaded,
    overviewLoadFailed,
    reportDataLoadFailed
}

export enum ZOpenRowsState {
    all = 1,
    none = 0,
    mixed = -1,
}

export enum EProjectState {
    unknown             = 'unknown',
    dataLoadInProgress  = 'data_load_in_progress',      // only relevant for EFR who makes multiple requests
    userClickedApply    = 'user_clicked_apply',         // user clicked 'Apply'. Time to load data
    userChangedPages    = 'user_changed_pages',         // user changed pages in a feature like 'logs'.
    initStarted         = 'init_started',                // 
    initComplete        = 'init_complete',              // options and metric initialization has been completed
    dataLoaded          = 'dataLoaded',                 // received graph data from server.  This may cause the 
                                                        // metric-keys to be loaded.

    newProjectLoaded    = 'project_loaded',             // all project related ui info dumped.  Need data and possibly
                                                        // metrics keys
    projectKeysLoaded   = 'project_keys_loaded',        // keys loaded 
    ready               = 'ready',                      // nothing to do
    reloadData          = 'reload_data',                // Refresh the data used by graphs and data tables
                                                        // (by loading data with last applied settings).
    projectsUpdated     = 'projects_updated',           // project overviews have come from the server.
    servicesUpdated     = 'services_updated',           // services updated to be for current project
    projectChanged      = 'project_changed',            // user selected a new project, we need to load new project data
    serviceChanged      = 'service_select_changed',     // service changed, need to update key info. (which doesn't
                                                        // require a graph update until user clicks apply)
    tabChanged          = 'tab_changed',                // user chose new analytics tab
    // pageChanged          = 'page_changed',              // user changed pages (currently logs only) but options 
    //                                                     // are the same.
    serviceEnvironmentChanged = 'service_environment_changed',
                                                        // User changed environment.  Staging->Prod or Prod-Staging.
                                                        // force a project re-initialization
    listSelect          = 'listSelect',                 // List entry selected
    serverError         = 'serverError',                // error from the server.
}

export enum HeaderFilterOpsIndexEnum {
    none = -1,
    opIs = 0,
    opIsNot = 1,
    opExists = 2,
    opDoesNotExist = 3,
}

export enum CertUpdateStatus {
    unknown = 'unknown',
    success = 'success',
    failed = 'failed',
    inProgress = 'in_progress',
}

export enum HealthCheckIndex {
    cdnCheck = 0,           // formerly static
    originCheck = 1         // formerly dynamic;
}

// TODO: These are temporary until the api server gives me default hc values.
export const BackupHCValues = {
    fast: 15,
    default: 30,
    slow: 60,
}