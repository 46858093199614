import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { actionTypes } from '../actions/actionCreatorTypes';
import * as actionCreators from '../actions/actionCreators';
import { State } from '../App';

import { getCurrentProjectEnvironment, getCurrentProjectName } from '../reducers/projectAccessors';
import { AggrDataMap, RestAPI, ZRestResponse, FetchError } from '../data/queryResultDefinitions';
import { EProjectState, SysFeatureEnums } from '../reducers/reducerEnums';
import { getQueryServiceIds } from '../reducers/uiAccessors';

import { REPORT_STRINGS as STRs, MISC_STRINGS, ERROR_STRINGS as ERRs } from '../shared/strings';
import TimeControlTab, { TimeControlOptions } from '../shared/TimeControl';
import ZButton from '../shared/ZButton';
import { NotificationStyles } from '../shared/constants';
import { toTitleCase } from '../shared/utilities';
import { ZGet } from '../shared/backend';
import ZURLS from '../shared/urls';
import { buildUrl } from '../reducers/serverEnvironAccessor';
import { DataFormatter } from './analyticsComps/DataFormatter';

const fmtr = new DataFormatter();

interface ReportProps extends State {
    setReportData: (reportType: string, data: AggrDataMap[], serviceIds: string, 
                    serviceNames: string, env: string) => actionTypes,
    applySettings: (error?: string) => actionTypes,
    setReportFromTo: (from: moment.Moment, to: moment.Moment) => actionTypes,
    setReportPeriodIndex: (periodIndex: number) => actionTypes,
    setReportQueryTimeFormat: (showingRelativeTime: boolean) => actionTypes,
    changeProjects: (projectId: string) => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes,
    initFeature: (featurName: SysFeatureEnums) => actionTypes,
    featureReady: () => actionTypes,
}

const reportPeriods: string[] = [
    STRs.today,
    STRs.yesterday,
    STRs.thisWeek,
    STRs.thisMonth,
    STRs.lastWeek,
    STRs.lastMonth,
    STRs.last7Days,
    STRs.last30Days,
    STRs.yearToDate,
]
class Reports extends React.Component<ReportProps> {
    public componentDidMount() {
        this.props.initFeature(SysFeatureEnums.reports)
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public componentDidUpdate(prevProps: ReportProps) {
        const systemState = this.props.systemNavProjectState;
        const systemUIState = systemState.systemUIState;

        if (! (systemState.projectsLoaded && systemState.servicesLoaded)) {
            return;
        }

        switch (systemUIState) {
            case EProjectState.projectsUpdated: {
                    const currentProj = systemState._currentProject;
                    this.props.changeProjects(currentProj.projectId);
                }
                break;

            case EProjectState.servicesUpdated:
                this.props.featureReady();
                this.handleApplyBtn();
                break;

            default:
                this.processReportState();
                break;
        }
    }

    private processReportState = () => {
        switch (this.props.reportUIState.reportState) {
            case EProjectState.userClickedApply:
                if (this.props.systemNavProjectState.servicesLoaded) {
                    this.loadData();
                }
                break;

            case EProjectState.serviceChanged:
            case EProjectState.initComplete:
                if (this.props.systemNavProjectState.servicesLoaded) {
                    this.handleApplyBtn();
                }
                break;

            case EProjectState.serverError:
                break;

            case EProjectState.ready:
            default:
                break;
        }
    }

    render(): JSX.Element {
        let page: JSX.Element = <span></span>;
        let options: JSX.Element = <span></span>;
        const systemState = this.props.systemNavProjectState;
        const env = getCurrentProjectEnvironment(systemState);
        const copyright = <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />;
        if (this.props.reportUIState.reportState === EProjectState.serverError) {
            page = <div  className="report-display">{MISC_STRINGS.serviceNotInProj}</div>;
        } else if (systemState._currentProject.servicesList.length === 0) {
            options = <div className="no-services">{MISC_STRINGS.descNoDeployedServices(env)}</div>
            page = <span></span>;
        } else {
            options = this.buildOptionsPanel();
            page = this.buildReport();
        }

        return (
            <div className="feature-panel">
                <div id="reports">
                    {options}
                    {page}
                    {copyright}
                </div>
            </div>
        );
    }

    private buildOptionsPanel = () => {
        const to = this.props.reportUIState.to;
        const from = this.props.reportUIState.from;
        const showingRelativeTime = this.props.reportUIState.showingRelativeTime;
        const relTimeIdx = this.props.reportUIState.periodIdx;
        const isDirty = this.props.reportUIState.isDirty;

        const tcOptions: TimeControlOptions = {
            tab1Label: STRs.reportingPeriods,
            tab2Label: STRs.customPeriod,
            noTab1Icon: true,
            noTab2Icon: true,
            intervalList: reportPeriods,
        }

        const timeControl = <TimeControlTab
            showingRelativeTime={showingRelativeTime}
            relativeTimeIdx={relTimeIdx}
            from={from}
            to={to}
            handleTabChange={this.handleTabChange}
            handleRelativeSelectChange={this.handleRelativeSelectChange}
            handleFromChange={this.handleFromChange}
            handleToChange={this.handleToChange}
            tcOptions={tcOptions}
        />

        const options = (
            <div className="report-options">
                {timeControl}
                <div className="apply-items"> 
                    <ZButton onClick={() => {this.handleApplyBtn()}} disabled={!isDirty}  btnCls="apply"  
                             btnType="primary">
                    {MISC_STRINGS.applyBtn}</ZButton>
                </div>
            </div>
        );

        return options;

    }

    private buildReport = () => {
        const systemState = this.props.systemNavProjectState;
        const reportData = this.props.reportUIState.currentReportData;
        const fromStr = moment.isMoment(reportData.from) ? reportData.from.format('MM/DD/YYYY hh:mma') : 'no string';
        const toStr = moment.isMoment(reportData.to) ? reportData.to.format('MM/DD/YYYY hh:mma') : 'no string';

        const user = this.props.authNServerData.user;
        const companyName =  (user) ? toTitleCase(user.company as string) : 'No Name';
        const env = toTitleCase(getCurrentProjectEnvironment(systemState));

        let serviceEnv = reportData.env;
        if (reportData.serviceIds === MISC_STRINGS.allOption) {
            serviceEnv = STRs.envAllServices(env);
        } else {
            serviceEnv = STRs.envService(env, reportData.serviceNames);
        }

        // let period = reportData.showingRelativeTime ? reportPeriods[reportData.periodIdx] : '';

        let markup: JSX.Element = <span></span>;
        if (this.props.reportUIState.isLoading || !systemState.projectsLoaded) {
            markup = (<div className="report-display">{STRs.loading}</div>);
        } else {
            const data: JSX.Element = this.buildTable();
            markup =  (
                <div className="report-display">
                    <h1>{STRs.trafficReportTitle(companyName)}</h1>
                    <h2>{MISC_STRINGS.project}: {getCurrentProjectName(systemState)}</h2>
                    <h3>{serviceEnv}</h3>
                    <h4>{STRs.dateRange(fromStr, toStr)}</h4>
                    <div>
                        {data}
                    </div>
        
                </div>
            );
        }

        return markup;
    }

    private buildTable = () => {
        const tableData: AggrDataMap[] = this.props.reportUIState.reportData;
        let goodData = true;
        if (tableData.length !== 4) {
            goodData = false;
        }

        const displayData: RestAPI = {};
        for (let i = 0, len = tableData.length; i < len; i++) {
            const t = tableData[i];
            displayData[ t.name ] = t.value;
        }

        const buildHRule = () => { return (<><div></div><div className="report-hrule"></div> <div></div></>)};
        const buildPercRow = ( label: string, key: string, denom: number, fmt?: string) => {
            let value: number = displayData[key] as number;
            let percentage = '';
            value = (value !== undefined) ? value : 0;
            if (fmt !== undefined) {
                value = (fmtr[fmt])(value);
            } else {
                percentage = goodData && (denom && (denom !== 0)) ? ((value / denom) * 100).toFixed(2) : '0';
                percentage = (denom !== undefined) ? percentage + '%' : '';
            }

            return (
                <>
                <div></div>
                <div className="metric-label">{label}</div>
                <div>{value.toLocaleString()}</div>
                <div>{percentage}</div>
                <div></div>
                </>
            )
        }

        const denominator = displayData.total_requests;
        if (denominator === 0) {
            goodData = false;
        }

        return (
            <div className="report-body">
                <div className="report-header"></div>
                <div className="report-header-metric">{STRs.metrics}</div>
                <div className="report-header">{STRs.totals}</div>
                <div className="report-header">{STRs.percentage}</div>
                <div className="report-header"></div>

                {buildPercRow(STRs.requests, 'total_requests', denominator as number)}
                {buildHRule()}
                {buildPercRow(STRs.success, 'successful_requests', denominator as number)}
                {buildHRule()}
                {buildPercRow(STRs.others, 'other_requests', denominator as number)}
                {buildHRule()}
                {buildPercRow(STRs.totalDataXfer, 'data_transferred', 0, 'buildByteLabel')}
            </div>
        )
    }

    private handleApplyBtn = () => {
        if (! this.props.reportUIState.isDirty && 
            ( this.props.reportUIState.reportState !== EProjectState.initComplete)) {
            return;
        }
        
        let errorIdx = 0;
        const errorMsg = [
            ERRs.absTimeInvalidFrom,
            ERRs.absTimeInvalidTo,
            ERRs.absTimeFutureToDate,
            ERRs.absTimeFromAfterTo,
            ERRs.absTimeFromDateToOld,
        ];
        if (!this.props.reportUIState.showingRelativeTime) {
            const {to, from} = this.props.reportUIState;

            if (typeof from === 'string') {
                errorIdx = 1;
            } else if (typeof to === 'string') {
                errorIdx = 2;
            }

            if (errorIdx === 0) {
                const now = moment();
                const oneYearBack = moment().subtract(1, 'y');

                if (to.isAfter(now)) {
                    errorIdx = 3;  // to past now
                } else if (from.isAfter(to)) {
                    errorIdx = 4;  // from is after to
                } else if (from.isBefore(oneYearBack)) {
                    errorIdx = 5;  // from is older than a year back
                } 
            }
            if (errorIdx > 0) {
                this.props.showNotification(NotificationStyles.danger, errorMsg[errorIdx - 1]);
                setTimeout(() => this.props.closeNotification(), 5000);
            } else {
                console.log( 'options.tx.  Bad errorIdx in handleApplyBtn');

            }
        }
        if (errorIdx === 0) {
            this.props.applySettings();
        }
    }

    private handleTabChange = (relativeSelected: number) => {
        this.props.setReportQueryTimeFormat(relativeSelected === 0);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private handleRelativeSelectChange = (index: number, optionName: string) => {
        this.props.setReportPeriodIndex(index);
    }

    private handleFromChange = (from: moment.Moment | string) => {
        this.props.setReportFromTo(from as moment.Moment, this.props.reportUIState.to)
    }

    private handleToChange = (to: moment.Moment | string) => {
        this.props.setReportFromTo(this.props.reportUIState.from, to as moment.Moment);
    }

    private loadData = () => {
        // console.log('loading data....')
        const reportData = this.props.reportUIState.currentReportData;
        const serviceIds = getQueryServiceIds(this.props.systemNavProjectState)
        if (serviceIds === undefined || serviceIds.length === 0) {
            return;
        }
        const params: RestAPI = {
            from: reportData.from.unix(),
            to: reportData.to.unix(),
            service_ids: serviceIds,
            report_name: 'aggregated_traffic'
        }

        const url = buildUrl(this.props.authNServerData, ZURLS.serverReports);

        const promise = ZGet(url, params);
        promise.then((data: ZRestResponse) => {
            // let rdata = data as ZRestResponse;
            // console.log(('report data: ' + JSON.stringify(rdata as ZRestResponse)));
            // console.log('params:' + params)

            const systemNavState = this.props.systemNavProjectState;
            const env = toTitleCase(getCurrentProjectEnvironment(systemNavState));
            const serviceIds = params.service_ids as string;
            let serviceNames = MISC_STRINGS.allOption;
            if (serviceIds.indexOf(',') === -1) {
                serviceNames = systemNavState._currentProject.serviceIdMap[serviceIds].serviceName;
            }

            this.props.setReportData(params.report_name as string, data as AggrDataMap[], 
                                     serviceIds, serviceNames, env);
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.applySettings('error');
                console.log(`Reports.loadData: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });

        // promise.catch((err) => {
        //     const msg = 'loadOptions: request failed.  Err: ' + err;
        //     console.log(msg);
        //     throw(msg);
        // });   

    }

    private closeNotification = () => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        reportUIState: state.reportUIState,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(Reports);
