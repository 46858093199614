import { ZRestResponse, RestAPI, FetchError, RestModifiers } from '../data/queryResultDefinitions';
import { MISC_STRINGS as STRs } from './strings';

import { removeSessionStorage } from '../shared/utilities';

import  {redirectTo, logout } from '../actions/actionCreators';
import { store, zhistory } from '../store';

import ZURLs, { makeQueryParams } from './urls';

const currentlyLoggingIn = (): boolean => {
  const href = zhistory.location.pathname;
  return ((href.indexOf('login') !== -1) || (href.indexOf('reset') !== -1))
}

function stringifyResponse(response: Response): string {
  return JSON.stringify({
    url: response.url,
    statusText: response.statusText,
    bodyUsed: response.bodyUsed,
    type: response.type,
    redirected: response.redirected,
    headers: { ...response.headers },
    status: response.status,
  })
}

const serverError = (response: Response): void => {
  console.log(stringifyResponse(response));
  const errList = {

    '403': STRs.serviceNotInProj,
    '404': STRs.serverDataChanged,
    '500': STRs.serverError
  };

  if ((response.status === 401  || response.status === 419) && !currentlyLoggingIn()) {
    const myLocation = zhistory.location.pathname;
    if (myLocation.indexOf('login') === -1) {
      store.dispatch( logout() );
      store.dispatch( redirectTo(ZURLs.login));
    }
  }

  const throwError = (stcode: number, msg?: string): void => {
    const status = stcode.toString();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const errPromise = new Promise((resolve, reject) => {
      const error: FetchError = {
        message: (msg !== undefined) ? msg : errList[status],
        statusCode: stcode
      }
      resolve(error);
    });
    throw errPromise;
  }

  const scode = response.status;

  switch (scode) {
    case 302: 
      throw response.json();

    case 401:
    case 419:
      removeSessionStorage();
      throw response.json();

    case 400:
    case 403:
      throw response.json();

    case 404:
    case 500:
      throwError(scode);
      break;

    default:
      if (response.bodyUsed) {
        throw response.json();
      } else if (scode !== 401 && scode !== 419) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const miscPromise = new Promise((resolve, reject) => {
          const error: FetchError = {
            message: STRs.serverError,
            statusCode: scode
          }
          resolve(error);
        });
        throw miscPromise;
      }
  }
}

export async function ZGet( url: string, params: RestAPI | string ): Promise<ZRestResponse> {
  const serverParams: RequestInit = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const paramStr = makeQueryParams( '', params );

  const promise = fetch(url + paramStr, serverParams)
    .then(response => {
      if (!response.ok) {
        serverError(response);
      }

      const keyData = response.json();
      return keyData;
    })

  return promise;
}


export async function ZPost(url: string, restParams: string | FormData, modifiers?: RestModifiers): Promise<ZRestResponse> {
  const params: RequestInit = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST'
  };

  if (modifiers) {
    if (params.headers) {
      if (modifiers.mimeType !== undefined && modifiers.mimeType.length > 0) {
        params.headers['Content-Type'] = modifiers.mimeType;
      } else {
        delete params.headers['Content-Type'];
      }

      if (modifiers.accept !== undefined && modifiers.accept.length > 0) {
        params.headers['Accept'] = modifiers.accept;
      } else {
        delete params.headers['Accept'];
      }
    }
    if (modifiers.noAuthKey) {
      delete params.credentials;
    }
  }

  params.body =
    restParams !== undefined ? restParams : '';

  // console.log(`${Date().toString()}: POST url: ${url}; parms: ${restParams} `);
  const promise = fetch(url, params)
    .then(response => {
      if (!response.ok) {
        serverError(response);
      }
      try {
        const lData = response.json();
        return lData;
      }
      catch (e) {
        return undefined;
      }
    })
    
  return promise;
}

export async function ZDelete(url: string, restParams: string): Promise<ZRestResponse> {
  const params: RequestInit = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  };

  params.body =
    restParams !== undefined && restParams.length > 0 ? restParams : '';

  // console.log(`${Date().toString()}: POST url: ${url}; parms: ${restParams} `);
  const promise = fetch(url, params)
    .then(response => {
      if (!response.ok) {
        serverError(response);
      } 

      const lData = response.json();
      return lData;
    })

  return promise;
}

export async function ZPut(url: string, restParams: string): Promise<ZRestResponse> {
  const params: RequestInit = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  };

  params.body =
    restParams !== undefined && restParams.length > 0 ? restParams : '';

  // console.log(`${Date().toString()}: Put url: ${url}; parms: ${restParams} `);
  const promise = fetch(url, params)
    .then(response => {
      if (!response.ok) {
        serverError(response);
      }

      const lData = response.json();
      return lData;
    })

  return promise;
}