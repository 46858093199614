import * as React from 'react';
import { LogRowData, LogHeaderDisplay } from '../../reducers/logState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LOG_STRINGS as STRs } from '../../shared/strings';

const buildOpenRowData = ( rowNum: number, row: LogRowData, css: string, headerDetails: JSX.Element,
                           onRowToggle: (rowNum: number) => void
): JSX.Element => {
    let openRow: JSX.Element = <span></span>;
    const openRowData: JSX.Element[] = [] as JSX.Element[];

    const buildHeaderItem  = ( key: string , label: string, data: string | number | boolean ): JSX.Element => {
        return (
            <div key={key} className="break-long-lines">
                <span className="mi-label">{label}</span>&nbsp;{data}
            </div>
        )
    }

    if (row.fullRequestURI.length > 0) {
        openRowData.push(buildHeaderItem('fulluri', STRs.fullUri, row.fullRequestURI ));
    }

    if (row.userAgent.length > 0) {
        openRowData.push(buildHeaderItem('userAgent', STRs.userAgent, row.userAgent ));
    }

    openRowData.push(buildHeaderItem('respSize', STRs.responseSize, row.responseSize ));
    // openRowData.push(buildHeaderItem('maxAge', STRs.maxAge, row.maxAge ));
    // openRowData.push(buildHeaderItem('pushIndicator', STRs.pushIndicator, row.pushIndicator ));

    if (row.zyError.length > 0) {
        openRowData.push(buildHeaderItem('zyError', STRs.zyError, row.zyError ));
    }

    if (row.zyPragma.length > 0) {
        openRowData.push(buildHeaderItem('zyPragma', STRs.zyPragma, row.zyPragma ));        
      }

    openRow = (
        <div className={css} >
            <div className="mi-divider-margin" />
            <div className="mi-divider" />
            <div className="log-detail" key="openData"  onClick={() => { onRowToggle(rowNum); }}>
                {openRowData}
            </div>
            {headerDetails}
        </div>
    );

    return openRow;
};

const buildHeaderData = (row: LogRowData, rowNum: number,
                         onToggleReq: (rowNum: number) => void,
                         onToggleResp: (rowNum: number) => void ): JSX.Element => {

    const headerList = (items: LogHeaderDisplay[], keyPrefix: string): JSX.Element[] => {
        return items.map((item, i) => {
            return (<div key={keyPrefix + i} className="break-long-lines">
                        <span className={item.css}><span className="mi-label">{item.name}: </span>{item.value}</span>
                    </div>);
        });
    };

    let showReqHeaderDiv: JSX.Element = <span />;
    let reqHeaders: JSX.Element = <span />;
    if (row.reqHeaderData) {
        const reqLabel = row.showReqHeaders ? STRs.hideRequestHeaders : STRs.showRequestHeaders;
        const icon = !row.showReqHeaders ? ['fas', 'caret-right'] : ['fas', 'caret-down'];
        showReqHeaderDiv = (
            <div className="mi-multi-per-row" onClick={() => { onToggleReq(rowNum); }} >
                <div className="mi-label">
                    <FontAwesomeIcon icon={icon as IconProp} />
                    <span className="filters-underline">{reqLabel}</span>
                </div>
            </div>
        );

        if (row.showReqHeaders) { 
            reqHeaders = (
                <div className="rheaders">
                    <span className="mi-label">{STRs.requestHeader}:</span>
                    {headerList(row.reqHeaderData, 'reqh')}
                </div>);
        }
    }

    let showRespHeaderDiv: JSX.Element = <span />;
    let respHeaders: JSX.Element = <span />;
    if (row.respHeaderData) {
        const respLabel = row.showRespHeaders ? STRs.hideResponseHeaders : STRs.showResponseHeaders;
        const icon = row.showRespHeaders ? ['fas', 'caret-down'] : ['fas', 'caret-right'];
        showRespHeaderDiv = (
            <div className="mi-multi-per-row" onClick={() => { onToggleResp(rowNum); }} >
                <div className="mi-label">
                    <FontAwesomeIcon icon={icon as IconProp} />
                    <span className="filters-underline">{respLabel}</span>
                </div>
            </div>
        );

        if (row.showRespHeaders) {
            respHeaders = (
                <div className="rheaders">
                    <span className="mi-label">{STRs.responseHeader}:</span>
                    {headerList(row.respHeaderData, 'resph')}
                </div>);
        }
    }

    return (
        <>
        {showReqHeaderDiv}
        {showRespHeaderDiv}
        {reqHeaders}
        {respHeaders}
        </>
    );
};

const logRow = (row: LogRowData, rowNum: number,  onRowToggle: (rowNum: number) => void,
                onReqToggle: (rowNum: number) => void, onRespToggle: (rowNum: number) => void ): JSX.Element => {
                    
    const rowCSS = rowNum % 2 ? ' odd-row' : '';
    const css = 'log-row toggle-cursor' + rowCSS;
    const openCSS = 'more-info' + rowCSS;
    const key = 'logRow' + rowNum;
    const rowOpenIcon: string[] = row.open ? ['fas', 'caret-down'] : ['fas', 'caret-right'];

    let openRow: JSX.Element = <span />;
    let headerDetails: JSX.Element = <span />;
    if (row.open) {
        headerDetails = buildHeaderData(row, rowNum, onReqToggle, onRespToggle);
        openRow = buildOpenRowData(rowNum, row, openCSS, headerDetails, onRowToggle);
    }

    return (
        <div className="lrow" key={key}>
        <div className={css} id={key} onClick={() => { onRowToggle(rowNum);  }}  >
            <div><FontAwesomeIcon icon={rowOpenIcon as IconProp} /></div>
            <div>{row.timestamp.format('MM/DD/YY hh:mm:ss a')}</div>
            <div>{row.requestMethod}</div>
            <div title={row.fullRequestURI}>{row.requestURI}</div>
            <div>{row.clientIP}</div>
            <div>{row.responseCode}</div>
            <div>{row.cacheStatus}</div>
            <div>{row.responseTime}</div>
        </div>
        {openRow}
        </div>
    );
};

export default logRow;
