/* eslint-disable react/prop-types */
import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import ZButton from '../../shared/ZButton';
// import moment from 'moment';
// import { ApiKeyPermission } from '../../data/queryResultDefinitions';

import { MISC_STRINGS } from '../../shared/strings';

export interface GenerateEditKeyProps {
    show: boolean;
    edit: boolean;
    title: string;
    applyBtnTxt?: string;
    // css?: string;
    disableApply: () => boolean;
    onHide: () => void;
    handleApply: () => void;
}

const GenerateEditApiKey: React.FunctionComponent<GenerateEditKeyProps> = props => {

    const css = `zmodal`;

    return (
        <Modal onHide={(): void => { props.onHide() }} show={props.show}  dialogClassName={css}>
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="key-modal-padding">
        {props.children}
        </div>
        </Modal.Body>
        <Modal.Footer>
                <ZButton disabled={(props.disableApply)()} 
                         onClick={(): void => { if (!(props.disableApply)()) { (props.handleApply)(); }}} >
                    {props.applyBtnTxt}
                </ZButton>
                <ZButton onClick={(): void => { props.onHide()} } >{MISC_STRINGS.cancelBtn}</ZButton>
            </Modal.Footer>
        </Modal>
        );
}

export default GenerateEditApiKey;