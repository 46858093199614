import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PwdValidationCtrl from './PwdValidation';
import { LOGIN_STRINGS as Strs } from './strings';
import { PwdValidationState } from './authUtils';
import logger from './logUtilities';
import { MAX_PASSWORD_LENGTH } from './constants';

export interface ChangePwdCtrlProps {
    newPassword: string;
    confirmPassword: string;
    validationState: PwdValidationState;
    handleValidation: (newPassword: string, currentPassword: string, confirmPassword: string) => void;
    promptForOldPassword?: boolean;
    currentPassword?: string;
    validatationBkgCss?: string;
}

class ChangePwdCtrl extends React.Component<ChangePwdCtrlProps> {
    constructor(props: ChangePwdCtrlProps) {
        super(props);
        this.handleConfirmPwdUpdate = this.handleConfirmPwdUpdate.bind(this);
        this.handlePwdUpdate = this.handlePwdUpdate.bind(this);
        this.handleOldPwdUpdate = this.handleOldPwdUpdate.bind(this);
    }

    public handlePwdUpdate(e: React.FormEvent<HTMLInputElement>): void {
        let oldPwd = 'not-needed';

        if (this.props.promptForOldPassword) {
            oldPwd = this.props.currentPassword as string;
        }
        (this.props.handleValidation)(oldPwd, e.currentTarget.value, this.props.confirmPassword);
    }

    public handleConfirmPwdUpdate(e: React.FormEvent<HTMLInputElement>): void {
        let oldPwd = 'not-needed';

        if (this.props.promptForOldPassword) {
            oldPwd = this.props.currentPassword as string;
        }

        this.props.handleValidation(oldPwd, this.props.newPassword, e.currentTarget.value);
    }

    public handleOldPwdUpdate(e: React.FormEvent<HTMLInputElement>): void {
        if (!this.props.promptForOldPassword) {
            logger.alert('handleOldPwdUpdate: Got called even though we were told we do not care about old password');
        } else {
            this.props.handleValidation(e.currentTarget.value, this.props.newPassword, this.props.confirmPassword);
        }
    }

    render(): JSX.Element {
        const {currentPassword, promptForOldPassword, newPassword, confirmPassword, validatationBkgCss} = this.props;
        const valBkg = validatationBkgCss && validatationBkgCss.length > 0 ? validatationBkgCss : undefined;
        const oldPwd: JSX.Element = (promptForOldPassword === true) ? (
            <>
            {Strs.oldPasswordLabel}
            <div className="input-group input-group-lg">
                <input id="oldPassword" type="password" className="form-control" 
                    placeholder={Strs.oldPassword} onChange={this.handleOldPwdUpdate} 
                    value={currentPassword} maxLength={MAX_PASSWORD_LENGTH} required />
                <span className="input-group-addon">
                    <FontAwesomeIcon icon={['fas', 'lock']} />
                </span>
            </div>
            </>
        ) : (<div></div>)
        
        return (
            <div className="row validate">
                <div className="col-md-5">
                    <div className="row">
                        {oldPwd}
                        {Strs.newPasswordLabel}
                        <div className="input-group input-group-lg">
                            <input id="newPassword" type="password" className="form-control" 
                                placeholder={Strs.newPassword} onChange={this.handlePwdUpdate} 
                                value={newPassword} maxLength={MAX_PASSWORD_LENGTH} required />
                            <span className="input-group-addon">
                                <FontAwesomeIcon icon={['fas', 'lock']} />
                            </span>
                        </div>

                        {Strs.confirmPasswordLabel}
                        <div className="input-group input-group-lg">
                            <input id="confirmPassword" type="password" className="form-control" 
                                    placeholder={Strs.confirmPassword} value={confirmPassword} 
                                    onChange={this.handleConfirmPwdUpdate} maxLength={MAX_PASSWORD_LENGTH} required/>
                            <span className="input-group-addon">
                                <FontAwesomeIcon icon={['fas', 'lock']} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-offset-2 col-md-6">
                    <PwdValidationCtrl valState={this.props.validationState} 
                                       background={valBkg}/>
                </div>
            </div>
        )
    }
}

export default ChangePwdCtrl;