import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';
import { actionTypes, OptionalApplyArgs } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { ZUserMin, ZRestResponse, ZProjectMin, FetchError, ZUserTypes, ProjectSolutions } from '../../data/queryResultDefinitions';
import { ZFeaturePopupTypes,  ZAdminMapType, EProjectState } from '../../reducers/reducerEnums';
import { anyPopupCheckBoxesChecked, getNumCheckedAdminItems, canAddUsers, getRemoveUsersList,
         userIsAdmin } from '../../reducers/adminAccessors';
import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { AdminManagementTypes } from '../../reducers/adminState';
import { State as AdminStateData } from '../../reducers/adminState';

import { ADMIN_STRINGS as Strs, MISC_STRINGS } from '../../shared/strings';
import ListPanel from '../../shared/ListPanel';
import ZButton from '../../shared/ZButton';
import ZInputTextBox from '../../shared/ZInputText'

import { NotificationStyles, MAX_PROJECT_NAME_LEN } from '../../shared/constants';
import { ZGet, ZPost, ZDelete, ZPut } from '../../shared/backend';
import ZURLS from '../../shared/urls';
import logger from '../../shared/logUtilities';
import GenDialog from '../../shared/GenDialog';
import { buildUserName, isValidProjectOrgName } from '../../shared/utilities';
import { BooleanMap } from '../../data/metricsAndOptionsDefs';


export interface ProjectAdminProps extends State {
    applySettings: (error?: string, extraData?: string,optionalArgs?: OptionalApplyArgs) => actionTypes;
    setProjectUserList: (userList: ZUserMin[]) => actionTypes;
    setAdminUserList: (userList: ZUserMin[]) => actionTypes;
    setUserListIndex: (index: number) => actionTypes;
    setAdminProjectList: (projectList: ZProjectMin[]) => actionTypes;
    setProjectListIndex: (index: number) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    toggleAdminSelection: (collectionName: ZAdminMapType, id: string) => actionTypes;
    toggleAdminPopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean) => actionTypes;
    toggleAdminPopupCheckmark: (index: number) => actionTypes;
    setProjectName: (projectName: string) => actionTypes;
    dataGroupLoad: (inProgress: boolean, feature: string) => actionTypes;
    setProjectSolutions: (solutions: string[]) => actionTypes;
}

// eslint-disable-next-line 
class ProjectAdmin extends React.Component<any, ProjectAdminProps> {
    componentDidMount(): void {
        logger.log('mounting project admin');
        this.initProjectAdminPage();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: ProjectAdminProps): void {
        const adminState: AdminStateData = this.props.adminUIState
        const adminUIState = adminState.adminState;
        logger.log(`component did update (project admin): ${adminUIState}`)
        if (adminUIState === EProjectState.tabChanged) {
            this.initProjectAdminPage();
        }
        if (adminUIState === EProjectState.dataLoadInProgress) {
            this.loadProjectList();
        // } else if (adminUIState === EProjectState.tabChanged) {
        //     this.initProjectAdminPage();
        }
        if (adminUIState === EProjectState.ready && adminState.projectSolutions === undefined) {
            this.loadProjectSolutions();
        }
    }

    render(): JSX.Element {
        const adminState = this.props.adminUIState;
        if (adminState.dataLoading) {
            return <></>;
        }
        const numProjects = adminState.projectList.length;
        const hasProjects = numProjects > 0;
        const hasUsers = adminState.userList.length > 0;

        const authState =  this.props.authNServerData;
        const user = authState.user as ZUserMin;
        const maxNumProj = authState.currentOrg.max_projects;
        const numProjStr = (user.role === ZUserTypes.zadmin) ? Strs.usedProjInOrg(numProjects, maxNumProj) : '';

        const projectList = this.buildProjectList(hasProjects);
        const userList = this.buildUserList(hasUsers);
        const canNotAddUsers = !(hasUsers && canAddUsers(adminState));
        const canNotRemoveUsers = !(hasUsers && (getNumCheckedAdminItems(adminState, AdminManagementTypes.projects) > 0));

        const cannotCreateProj = false;
        const cannotEditProject = false;

        const popupBuilders = {
            [ZFeaturePopupTypes.AddUser]: this.buildAddUsersPopup,
            [ZFeaturePopupTypes.RemoveProject]: this.buildRemoveUsersPopup,
            [ZFeaturePopupTypes.CreateProject]: this.buildCreateProjectPopup,
            [ZFeaturePopupTypes.EditProject]: this.buildEditProjectPopup,
            [ZFeaturePopupTypes.DeleteProject]: this.buildDeleteProjectPopup,
        };

        const deleteBtn = <span></span>;
        // const deleteBtn = (
        //     <>
        //     &nbsp;&nbsp;
        //     <ZButton onClick={(): void => { this.handleDeleteProjectBtnClick(); }} 
        //         disabled={cannotDeleteProject} btnCls="list-panel-footer-item">
        //         {Strs.deleteProject}
        //     </ZButton> 
        //     </>
        // )

        let adminPopup = <span></span>;
        if (popupBuilders[adminState.popupType]) {
            adminPopup = (popupBuilders[adminState.popupType])();
        }

        const projectHeader: JSX.Element = (<div className="admin-pa-proj-list-header">
                <div></div>
                    <div>{Strs.project}</div>
                    <div>{Strs.numUsers}</div>
                </div>);

        const canCreate = (user.role === ZUserTypes.zadmin);
        const createBtn = canCreate ? (
            <>
            <ZButton onClick={(): void => { this.handleCreateProjectBtnClick(); }} disabled={cannotCreateProj}>
                {Strs.createProject}
            </ZButton>&nbsp;&nbsp;
            </>
        ) : <div></div>;
        const projectFooter: JSX.Element = (
            <div className="list-panel-footer-sm-margin">
                {createBtn}
                <ZButton onClick={(): void => { this.handleEditProjectBtnClick(); }} 
                    disabled={cannotEditProject} btnCls="list-panel-footer-item">
                    {Strs.editProject}
                </ZButton> 
                {deleteBtn}
            </div>
        );

        const userHeader: JSX.Element = (<div className="admin-pa-proj-list-header">
            <div></div>
                <div>{Strs.user}</div>
                <div>{Strs.role}</div>
            </div>);

        const userFooter: JSX.Element = (
            <div className="list-panel-footer">
                <ZButton onClick={(): void => { this.handleAddUsersBtnClick(); }} disabled={canNotAddUsers}>
                    {Strs.addUsers}
                </ZButton>
                    &nbsp;&nbsp;
                <ZButton onClick={(): void => { this.handleRemoveUserBtnClick(); }} 
                    disabled={canNotRemoveUsers} btnCls="list-panel-footer-item">
                    {Strs.removeUsers}
                </ZButton> 
            </div>
        );

        return (
        <>
            <div className="title">{Strs.projectManagement}</div>
            <div id="admin-tables" className="project-admin">
                <div className="admin-panel">
                    <ListPanel 
                        renderHeader={(): JSX.Element => { return projectHeader }}
                        renderBody={(): JSX.Element => { return projectList}}
                        renderFooter={(): JSX.Element => {
                            return projectFooter;
                        }}
                    />
                    {numProjStr}
                </div>
                <div className="admin-panel">
                    <ListPanel 
                        renderHeader={(): JSX.Element => { return userHeader }}
                        renderBody={(): JSX.Element => { return userList}}
                        renderFooter={(): JSX.Element => {return userFooter}}/>
                </div>
            </div>
            {adminPopup}
        </>
        );
    }

    private buildProjectList = (hasProjects: boolean): JSX.Element => {
        const { projectListIdx, projectList } = this.props.adminUIState;
        const projList: JSX.Element[] = [];

        if (hasProjects) {
            for (let i = 0, len = projectList.length; i < len; i++) {
                const selected = (i === projectListIdx) ? 'selected' : '';
                const project = projectList[i];

                const numProjects = (project && project.meta.num_users !== undefined) ? project.meta.num_users : '';
                projList.push((
                    <div key={'line' + i} className={'admin-pa-proj-list-item ' + selected}
                    onClick={(): void => {this.handleSelectedProjectChanged(i)}}>
                        <div></div>
                        <div>{project.name}</div>
                        <div>{numProjects}</div>
                    </div>
                ))
            }
        } else {
            projList.push((
                <div key="line" className="admin-pa-proj-list-item">
                    <div></div>
                    <div>{Strs.noProjectsInOrg}</div>
                    <div></div>
                </div>
            ));
        }

        return <>{projList}</>;
    }

    private buildUserList = (hasUsers: boolean): JSX.Element => {
        const { userList, adminState } = this.props.adminUIState;
        if (adminState !== EProjectState.ready) {
            return <span></span>;
        }
        
        const usrList: JSX.Element[] = [];
        if (hasUsers) {
            // let project = this.props.adminUIState.selectedProject;
            for (let i = 0, len = userList.length; i < len; i++) {
                const user = userList[i];
                const entry = this.buildUserListEntry(user, i)
                usrList.push(entry);
            }
        }

        return <>{usrList}</>;
    }

    private buildUserListEntry = (user: ZUserMin, index: number): JSX.Element => {
        const selected = (index === this.props.adminUIState.userListIdx) ? 'selected' : '';
        const className = 'admin-pa-usr-list-item ' + selected;
        const isAdmin = userIsAdmin(user.role) || user.auto_add_to_all_projects;
        let checked = this.props.adminUIState.checkedUsers[user.user_id];
        checked = checked !== undefined ? checked : false;
        const myIndex = index;

        const username = buildUserName(user);

        return (
            <div key={'line' + index} className={className} 
                 onClick={(): void => { 
                     this.props.setUserListIndex(myIndex);
                     if (!isAdmin) {
                        this.props.toggleAdminSelection(ZAdminMapType.SelectedProjectUsers, user.user_id);
                     }
                    }}>
                <div>
                    <input type="checkbox"  disabled={isAdmin} checked={checked} 
                           onChange={(): void => { logger.log('checked user') } }>
                    </input>
                </div>
                <div>{username}</div>
                <div>{user.role}</div>
            </div>
        )
    }

    private initProjectAdminPage = async (): Promise<void> => {
        const adminUIState: AdminStateData = this.props.adminUIState;
        if (adminUIState.dataLoading) {
            return;
        }
        const serverData = this.props.authNServerData
        let url = buildUrl(serverData, ZURLS.serverAdminUserList);
        const solutionsUrl = buildUrl(serverData, ZURLS.serverAllProjectSolutions);

        try {
            this.props.dataGroupLoad(true, 'admin')
            if (adminUIState.systemProjectSolutions.length === 0) {
                const solutionsList: ZRestResponse = await ZGet(solutionsUrl, {})
                this.props.setProjectSolutions(solutionsList as string[])
            }

            const userList: ZRestResponse = await ZGet(url, {});
            this.props.setAdminUserList(userList as ZUserMin[]);

            url = buildUrl(serverData, ZURLS.serverAdminProjectList);
            const projectList: ZRestResponse = await ZGet(url, {});
            this.props.setAdminProjectList(projectList as ZProjectMin[]);
            this.handleSelectedProjectChanged(this.props.adminUIState.projectListIdx, true);

            this.props.dataGroupLoad(false, 'admin');
        }
        catch(errStr) {
            this.props.dataGroupLoad(false, 'admin');

            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`ProjectAdmin.initProjectAdminPage: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })    
        }
    }

    private loadProjectList = async (): Promise<void> => {
        const url = buildUrl(this.props.authNServerData, ZURLS.serverAdminProjectList);

        try {
            const projectList: ZRestResponse = await ZGet(url, {});
            this.props.setAdminProjectList(projectList as ZProjectMin[]);
            this.handleSelectedProjectChanged(this.props.adminUIState.projectListIdx, true);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`ProjectAdmin.loadProjectList: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    private handleSelectedProjectChanged = (index: number, force = false): void=> {
        if ( (index !== this.props.adminUIState.projectListIdx) || force) {
            this.props.setProjectListIndex(index);
            this.loadSelectedProjectsUsers(index);
        }
    }

    private  loadProjectSolutions = async (): Promise<void> => {
        const adminUIState = this.props.adminUIState;
        const selectedProject: ZProjectMin = adminUIState.selectedProject;
        if (adminUIState.dataLoading) {
            return;
        } 
        if (selectedProject === undefined || selectedProject.project_id === undefined) {
            return;
        }

        this.props.dataGroupLoad(true, 'admin')

        const url = buildUrl(this.props.authNServerData, ZURLS.serverProjectSolutions(selectedProject.project_id));
        const sList: ZRestResponse = await ZGet(url, {});
        const solutionList = sList as ProjectSolutions[]
        const solutions: string[] = []
        for (let i=0, len=(solutionList as ProjectSolutions[]).length; i < len; i++) {
            solutions.push(solutionList[i].solution_name)
        }
        this.props.setProjectSolutions(solutions);
        this.props.dataGroupLoad(false, 'admin')
    }

    private loadSelectedProjectsUsers = (index: number): void => {
        const projects = this.props.adminUIState.projectList;
        if (projects.length > 0) {
            const project = projects[index];
            this.loadProjectsUsers(project);
        }
    }

    private loadProjectsUsers = async (project: ZProjectMin): Promise<void> => {
        const projectId = (project) ? project.project_id : '';
        if (projectId && projectId.length > 0) {
            try {
                let url = ZURLS.serverProjectUsers(projectId);
                url = buildUrl(this.props.authNServerData, url);

                const userList: ZRestResponse = await ZGet(url, {});
                this.props.setProjectUserList(userList as ZUserMin[]);
            }
            catch(errStr) {
                const p = errStr as Promise<FetchError>;
                p.then((err) => {
                    console.log(`ProjectAdmin.loadProjectsUserData: fetch failed: ${err}`);

                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification();
                })
            }
        }
    }

    private closePopup  = (): void => {
        this.props.toggleAdminPopup(ZFeaturePopupTypes.NoPopup, false);
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }

    // ******
    // Add user handlers
    //
    private handleAddUsersBtnClick = (): void => {
        if (canAddUsers(this.props.adminUIState)) {
            this.props.toggleAdminPopup(ZFeaturePopupTypes.AddUser, true);
        }
    }

    private buildAddUsersPopup = (): JSX.Element => {
        if (!canAddUsers( this.props.adminUIState )) {
            return <span></span>;
        }
        const userList = this.props.adminUIState.potentialProjectUserList;
        const checkedItems = this.props.adminUIState.popupCheckState;

        const usrList: JSX.Element[] = [];
        for (let i = 0, len = userList.length; i < len; i++) {
            const usr = userList[i];
            usrList.push(
                <div className="uname-chkbox-lst" key={'users' + i} 
                    onClick={(): void => { this.props.toggleAdminPopupCheckmark(i) }}>
                    <div>&nbsp;&nbsp;
                    <input checked={checkedItems[i]} type="checkbox" onChange={(): void => { logger.log(' '); }}></input>
                        &nbsp;&nbsp;{buildUserName(usr)}
                    </div>
                    <div>{usr.role}&nbsp;&nbsp;</div>
                </div>
            )
        }

        const add = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.addUserToProject(this.props.adminUIState.selectedProject.name)} 
                msg={Strs.chooseUsers}
                css="zmodel-50"
                applyBtnTxt={Strs.addUsers}
                disableApply={(): boolean => { return !anyPopupCheckBoxesChecked(this.props.adminUIState) }}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { this.applyAddUsers(); }}
            >
                <div className="list-boundary">{usrList}</div>
            </GenDialog>
        );

        return add;
    }

    private applyAddUsers = (): void  => {
        const { popupCheckState, potentialProjectUserList } = this.props.adminUIState;

        const usersToAdd: Record<string, string>[] = [];
        potentialProjectUserList.forEach((user: ZUserMin, idx: number) => { 
            if (popupCheckState[idx]) { usersToAdd.push({ 'user_id': user.user_id }); }
        });
        let url = ZURLS.serverProjectUsers(this.props.adminUIState.selectedProject.project_id);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZPost(url, JSON.stringify(usersToAdd));
        promise.then( ( ) => {
            this.props.applySettings();
        });
        promise.catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        })
    }

    // ******
    // remove user handlers
    //
    private handleRemoveUserBtnClick = (): void => {
        if ((getNumCheckedAdminItems(this.props.adminUIState, AdminManagementTypes.projects) > 0)) {
            this.props.toggleAdminPopup(ZFeaturePopupTypes.RemoveProject, true);
        }
    }

    private buildRemoveUsersPopup = (): JSX.Element => {
        const userList = getRemoveUsersList(this.props.adminUIState);
        if (userList.length === 0) {
            return <span></span>;
        }

        const usrList: JSX.Element[] = [];
        for (let i = 0, len = userList.length; i < len; i++) {
            usrList.push(<li key={'userlist' + i}>{userList[i].email}</li>);
        }

        const msg = Strs.confirmUserRemoval(this.props.adminUIState.selectedProject.name, (userList.length > 1));
        const remove = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.removeUsers} 
                msg={msg}
                applyBtnTxt={MISC_STRINGS.yes}
                cancelBtnTxt={MISC_STRINGS.no}
                disableApply={(): boolean => { return false }}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { this.applyRemoveUsers(); }}
            >
                <ul className="list-boundary">
                    {usrList}
                </ul>
            </GenDialog>
        );

        return remove;
    }

    private applyRemoveUsers = (): void => {
        const usersToRemoveJSON: Record<string, string>[] = [];
        const usersToRemove = getRemoveUsersList(this.props.adminUIState);
        usersToRemove.forEach((user: ZUserMin) => { 
            usersToRemoveJSON.push({ 'user_id': user.user_id }); 
        });

        let url = ZURLS.serverProjectUsers(this.props.adminUIState.selectedProject.project_id);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZDelete(url, JSON.stringify(usersToRemove));
        promise.then( ( ) => {
            this.props.applySettings();
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });
    }

    private buildAvailableSolutionList = (selectedSolutions: BooleanMap): JSX.Element => {
        let markup = <div></div>;
        if (selectedSolutions) {
            const selKeys = Object.keys(selectedSolutions);
            const solutionList: JSX.Element[] = [];

            selKeys.forEach( (key: string, idx: number) => {
                let solName = Strs['solution_' + key];
                solName = (solName === undefined) ? key : solName;
                solutionList.push((
                    <div key={'chkbox' + idx}>
                        <input  type="checkbox" checked={selectedSolutions[key]}
                               onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    this.props.setSelectedProjectSolutions(key, e.currentTarget.checked) }} >
                        </input>&nbsp;&nbsp;{solName}
                    </div>)
                )}
            );
            if (solutionList.length > 0) {
                markup = (
                    <div>
                        {Strs.addSolutions}
                        <div className="avl-solutions-list ">
                        {solutionList}
                        </div>
                    </div>
                )
            }
        }

        return markup;
    }

    private buildCurrentSolutionsList = (projectSolutions: string[] | undefined): JSX.Element => {
        const solutionList: JSX.Element[] = [];
        let markup = <div></div>;
        if (projectSolutions && projectSolutions.length > 0) {
            projectSolutions.forEach((solution: string, index: number) => {
                let solName = Strs['solution_' + solution];
                solName = (solName === undefined) ? solution : solName;
                solutionList.push(<li key={'solution'+index}>{solName}</li>)
            });
            markup = (
                <div className="crnt-solutions-list">
                    {Strs.currentSolutions}
                    <ul>
                        { solutionList }
                    </ul>
                </div>
            )
        }
        return markup;
    }

    private createEditProjectPopup = (editing: boolean ): JSX.Element => {
        const adminState: AdminStateData = this.props.adminUIState;
        const { projectName, selectedSolutions, projectSolutions, systemProjectSolutions, solutionsChanged } = adminState;
        let disableApply = !(projectName.length > 0 && isValidProjectOrgName(projectName));

        let title = Strs.createProject;
        let saveBtnText = title;
        let msg = Strs.enterNewProjectName;
        let applyFct = this.applyCreateProject;
        let validationMsg = disableApply ? MISC_STRINGS.projectOrgValidationRules : '';

        const planMarkup = <div></div>;
        let currentSolutionsMarkup = <div></div>
        let selectableSolutions = <div></div>

        if (editing) {
            title = Strs.editProject;
            saveBtnText = MISC_STRINGS.save
            msg = Strs.editProjectName;
            applyFct = this.applyEditProject
            validationMsg = (projectName === adminState.selectedProject.name) ? '' : validationMsg;
            if (!disableApply) {
                disableApply = !((projectName !== adminState.selectedProject.name) || solutionsChanged);
            }

            if (systemProjectSolutions.length > 0) {
                currentSolutionsMarkup = this.buildCurrentSolutionsList( projectSolutions );
                selectableSolutions = this.buildAvailableSolutionList(selectedSolutions)
            }
        } else {
            if (systemProjectSolutions.length > 0) {
                selectableSolutions = this.buildAvailableSolutionList(selectedSolutions)
            }
        }

        const projDlg = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={title} 
                msg={msg}
                css="zmodel-50"
                applyBtnTxt={saveBtnText}
                disableApply={(): boolean => { return disableApply; }}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { (applyFct)(); }}
            >
                <div className="create-project-padding">
                <ZInputTextBox 
                    label={Strs.projectName} 
                    value={projectName} 
                    ikey="addProj" 
                    onTextChange={(txt: string): void => { this.props.setProjectName(txt)}}
                    maxLength={MAX_PROJECT_NAME_LEN}
                    validationMsg={validationMsg}
                    placeholderText={Strs.projectName}
                />
                </div>
                {planMarkup}
                {currentSolutionsMarkup}
                {selectableSolutions}
            </GenDialog>
        );

        return projDlg;
    }

    // ******
    // create project handlers
    //
    private handleCreateProjectBtnClick = (): void => {
        this.props.toggleAdminPopup(ZFeaturePopupTypes.CreateProject, true);
    }

    private buildCreateProjectPopup = (): JSX.Element => {
        const numProjects = this.props.adminState.projectList.length;
        const maxNumProj = this.props.authNServerData.currentOrg.max_projects;

        const canAddProjects = numProjects < maxNumProj;        
        if (canAddProjects) {
            return this.createEditProjectPopup( false );
        } else {
            return this.cannotAddProject();
        }
    }

    private cannotAddProject = (): JSX.Element => {
        return (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.createProject} 
                msg={Strs.cannotCreateProject}
                css="zmodel-50"
                applyBtnTxt={''}
                hideApplyButton={true}
                cancelBtnTxt={MISC_STRINGS.close}
                disableApply={(): boolean => { return true; }}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { console.log('cannot happen') }}
            >
            </GenDialog>
        )
    }

    private applyCreateProject = async (): Promise<void> => {
        const { projectName, selectedSolutions } = this.props.adminUIState;
        let url = buildUrl(this.props.authNServerData, ZURLS.serverAdminProjectList);
        try {
            const create = await ZPost(url, JSON.stringify({ name: projectName.trim() }));
            const projectId = (create as ZProjectMin).project_id;

            const keys = Object.keys(selectedSolutions);
            for (let i=0, len=keys.length; i < len; i++) {
                const key = keys[i];
                // if the checkbox was checked, add the solution.
                if (selectedSolutions[key]) {
                    url = buildUrl(this.props.authNServerData, ZURLS.serverProjectSolutions(projectId));
                    await ZPost(url, JSON.stringify({solution_name: key}));
                }
            }

            this.props.showNotification(NotificationStyles.success, Strs.createProjectSuccess(projectName));
            this.props.applySettings( undefined, undefined, {project_id: projectId});
            this.closeNotification();
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    // ******
    // edit project handlers
    //
    private handleEditProjectBtnClick = (): void => {
        this.props.toggleAdminPopup(ZFeaturePopupTypes.EditProject, true);
    }

    private buildEditProjectPopup = (): JSX.Element => {
        return this.createEditProjectPopup( true );
        // return <div></div>
    }

    private applyEditProject = async (): Promise<void> => {
        const { projectName, selectedSolutions } = this.props.adminUIState;
        const projectId = this.props.adminUIState.selectedProject.project_id;
        let url = buildUrl(this.props.authNServerData, ZURLS.serverManageProject(projectId));

        try {
            await ZPut(url, JSON.stringify({ name: projectName.trim() }));
            const keys = Object.keys(selectedSolutions);
            for (let i=0, len=keys.length; i < len; i++) {
                const key = keys[i];
                if (selectedSolutions[key]) {
                    url = buildUrl(this.props.authNServerData, ZURLS.serverProjectSolutions(projectId));
                    await ZPost(url, JSON.stringify({solution_name: key}));
                }
            }
            this.props.showNotification(NotificationStyles.success, Strs.editProjectSuccess(projectName));
            this.props.applySettings();
            this.closeNotification();
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    // ******
    // delete project handlers
    //

    // &************* uncomment when we support delete
    // private handleDeleteProjectBtnClick = (): void => {
    //     this.props.toggleAdminPopup(ZFeaturePopupTypes.DeleteProject, true);
    // }

    private buildDeleteProjectPopup = (): JSX.Element => {
        const adminState = this.props.adminUIState;
        const projectName = adminState.selectedProject.name;
        const numProjServices = this.props.systemNavProjectState._currentProject.servicesList.length;
        const canDelete = numProjServices === 0;

        let body = <span></span>;
        if (canDelete) {
            body = (
                <>
                    <ul>
                        <li>{projectName}</li>
                    </ul>
                </>
            );
        } else {
            body = <div>Strs.cannotDeleteProj(projectName)</div>;
        }

        const projDlg = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.deleteProject} 
                msg={Strs.confirmDeleteProj}
                css="zmodel-50"
                applyBtnTxt={Strs.deleteProject}
                
                disableApply={() => {return !canDelete}}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { (this.applyDelete)(); }}
            >
                <br />
                {body}
            </GenDialog>
        );

        return projDlg;
    }

    private applyDelete = (): void => {
        const projectId = this.props.adminUIState.selectedProject.project_id;
        const url = buildUrl(this.props.authNServerData, ZURLS.serverManageProject(projectId));

        const promise = ZDelete(url, '');
        promise.then( ( ) => {
            this.props.setProjectListIndex(0);
            this.props.applySettings();
        });
        promise.catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        })
    }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        adminState: state.adminUIState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(ProjectAdmin);
