import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { State } from '../../App';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { ZRestResponse, AvailableBillingReports, BillingOrgUsageData, ReportOverviewData, FetchError,
         BillingBaseData, ServiceBillingData } 
            from '../../data/queryResultDefinitions';
    
import { SysFeatureEnums, ZSortDirection, UsageUnits, EProjectState, BillingUnitsMap, BTUnitsIdx, 
         TrafficBillingFieldsEnum as FieldsEnum, ZOpenRowsState, ZEnvironments } 
            from '../../reducers/reducerEnums';
import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { BillingDataUI, ProjectServiceBillingDataUI } from '../../reducers/reportState';

import { REPORT_STRINGS as STRs, MISC_STRINGS as MISC } from '../../shared/strings';
import { ZGet } from '../../shared/backend';
import { NotificationStyles } from '../../shared/constants';

import ZDropDown from '../../shared/ZDropDown';
import ZURLS from '../../shared/urls';
import ReportPanel from '../../shared/ReportPanel';
import logger from '../../shared/logUtilities';

interface TrafficUsageProps extends State {
    applySettings: (error?: string) => actionTypes;
    initFeature: (featurName: SysFeatureEnums) => actionTypes,
    sortTable: (feature: SysFeatureEnums, tableName: string, fieldName: string, direction: ZSortDirection) => actionTypes,
    setBillingReportData:(availableBillingReports: AvailableBillingReports) => actionTypes,
    setProjectTrafficUsage: (billingUsageData: BillingOrgUsageData, includesServices: boolean) => actionTypes,
    toggleBillingShowServices: (showingServices: boolean) => actionTypes,
    toggleBillingShowProjectServices: (projectIndex: number) => actionTypes,
    setBillingUnits: (units: UsageUnits) => actionTypes,
    setBillingMonthIndex: (index: number) => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
}

class TrafficUsage extends React.Component<TrafficUsageProps> {
    public componentDidMount(): void {
        logger.log('Change profile');
        this.props.initFeature(SysFeatureEnums.billingReportsTrafficUsage);
    }
    
    public componentDidUpdate(): void {
        const reportState = this.props.reportUIState.reportState;
        if (reportState === EProjectState.initStarted || reportState === EProjectState.unknown) {
            this.initTrafficUsage();
        } else if (reportState === EProjectState.dataLoadInProgress) {
            this.loadReportData();
        }
    }

    render(): JSX.Element {
        const reportUIState = this.props.reportUIState;
        const authState = this.props.authNServerData;
        let reportMarkup: JSX.Element = (<div></div>);

        switch (reportUIState.reportState) {
            case EProjectState.unknown:
                break;

            case EProjectState.ready: {
                const billingData: BillingOrgUsageData = reportUIState.billingOrgUsageData;
                if (billingData !== undefined && billingData.org_name && billingData.org_name.length > 0) {
                    const title = STRs.aggrTrafficReportTitle(authState.currentOrg.org_name);
                    const billingOptions = this.buildBillingOptions();
                    const reportHeader: JSX.Element = this.buildReportHeader();
                    const reportFooter: JSX.Element = this.buildReportFooter();
                    const reportBody: JSX.Element = this.buildReportBody();
                    const reportPanel = <ReportPanel renderHeader={(): JSX.Element => { return reportHeader }} 
                                                     renderBody={(): JSX.Element => { return reportBody }} 
                                                     renderFooter={(): JSX.Element => { return reportFooter }}
                                                     cssClass="header-fix" />

                    reportMarkup =  (<>
                        <div className="title">{title}</div>
                        <div className="billing-reports">
                            {billingOptions}
                            {reportPanel}
                        </div>
                        </>)
                    }
                }
                break;

            default:
                reportMarkup = <div>{MISC.loading}</div>;
                break;

        }
        
        return ( reportMarkup )
    }

    private buildBillingOptions = (): JSX.Element => {
        const reportState = this.props.reportUIState;

        const monthDropdown = this.buildMonthYearDropdown();
        const showServiceData =  reportState.showingServices;
        const unitsConverter: UsageUnits[] = [ UsageUnits.gigaBytes, UsageUnits.teraBytes, UsageUnits.petaBytes ];
        const currentUnits = reportState.billingUsageUnits;
        let unitsIdx = unitsConverter.findIndex((unit: UsageUnits): boolean => { return unit === currentUnits; })
        unitsIdx = unitsIdx !== -1 ? unitsIdx : 0;

        const unitsDropdown = ZDropDown([MISC.gigaBytes, MISC.terraBytes, MISC.petaBytes], 
            unitsIdx, 'billing_statements', 
            (index: number, optionName?: string, value?: string) => {
                this.props.setBillingUnits(value as UsageUnits)
            },
            { label: STRs.units }, unitsConverter);

            return (
                <div className="billing-report-controls">
                    <div>{monthDropdown}</div>
                    <div>
                        <input type="checkbox"  checked={showServiceData} 
                            onChange={(): void => {this.props.toggleBillingShowServices(! showServiceData)}}></input>
                            &nbsp;{STRs.includeServices}
                    </div>
                    <div>{unitsDropdown}</div>
                </div>
        );
    }

    private buildMonthYearDropdown = (): JSX.Element => {
        const reportState = this.props.reportUIState;
        const months = {
            '01': MISC.january, '02': MISC.february, '03': MISC.march, '04': MISC.april, 
            '05': MISC.may, '06': MISC.june, '07': MISC.july, '08': MISC.august, 
            '09': MISC.september, '10': MISC.october, '11': MISC.november, '12':MISC.december
        }

        const availableTrafficReports = reportState.availableBillingReports.traffic_usage;
        let statementDates: string[] = [STRs.noBillingData]
        if (availableTrafficReports && availableTrafficReports.length > 0) {
            statementDates = availableTrafficReports.map((report: ReportOverviewData) => {
                let monthStr = months[report.month];
                monthStr = monthStr !== undefined ? monthStr : STRs.unknownMonth;
                return STRs.monthYear(monthStr, report.year);
            });
        }

        return ZDropDown(statementDates, reportState.periodIdx, 'billing_statements', 
                         (index: number) => this.props.setBillingMonthIndex(index),
                         { label: STRs.statement });
    }

    private buildReportHeader = (): JSX.Element => {
        const reportUIState = this.props.reportUIState;
        let headerMarkup: JSX.Element = <div></div>;
        const {currentSortField , showingServices, allServicesOpen } = reportUIState;  //.currentSortField;

        const unitAbbrev = {
            [UsageUnits.bytes]: '',
            [UsageUnits.gigaBytes]: MISC.gbyteNoSpace,
            [UsageUnits.teraBytes]: MISC.tbyteNoSpace,
            [UsageUnits.petaBytes]: MISC.pbyteNoSpace
        }

        const buildHdrField = (str: string, dataField: FieldsEnum): JSX.Element => {
            let field: JSX.Element = <div></div>;
            if (dataField === currentSortField ) {
                const currentSortDir = reportUIState.columnSortMap[currentSortField];
                const icon = currentSortDir === ZSortDirection.l2h ? 'long-arrow-alt-down' : 'long-arrow-alt-up';

                const sortDir = (reportUIState.columnSortMap[currentSortField] === ZSortDirection.l2h) ? ZSortDirection.h2l : ZSortDirection.l2h;
                field = <div onClick={():void => {
                    this.props.sortTable(SysFeatureEnums.billingReportsTrafficUsage, 'billingUsageData', currentSortField, sortDir)
                }}>
                {str}&nbsp;<FontAwesomeIcon icon={icon as IconProp} /></div>;
            } else {
                field = <div className="field-underline" onClick={():void => {
                    this.props.sortTable(SysFeatureEnums.billingReportsTrafficUsage, 'billingUsageData', dataField, ZSortDirection.l2h)
                }}>{str}</div>
            }

            return field;
        }

        let projSvcToggle = <div><span></span></div>;
        if (showingServices) {
            let ticon = 'minus';
            if (allServicesOpen !== ZOpenRowsState.mixed) {
                ticon  = (allServicesOpen === ZOpenRowsState.all) ? 'caret-down' : 'caret-right';
            }

            projSvcToggle = (<div className="field-cursor-pointer" onClick={(): void => { this.props.toggleBillingShowProjectServices(-1) }}>
                                <FontAwesomeIcon icon={ticon as IconProp} />
                            </div>);
        }
        
        if (reportUIState.reportState !== EProjectState.unknown) {
            const units = reportUIState.billingUsageUnits;
            const unitString = unitAbbrev[units]

            headerMarkup =   (
                <>
                <div className="billing-projects-header">
                    <div>{projSvcToggle}</div>
                    <div>{ buildHdrField(STRs.projectName, FieldsEnum.projectName) }</div>
                    <div>{ buildHdrField(STRs.totalRequests,  FieldsEnum.totalRequests) }</div>
                    <div>{ buildHdrField(STRs.totalBytesOut(unitString),  FieldsEnum.totalBytesOut) }</div>
                    <div>{ buildHdrField(STRs.totalBytesIn(unitString),  FieldsEnum.totalBytesIn) }</div>
                    <div>{ buildHdrField(STRs.totalLogBytesOut(unitString), FieldsEnum.totalLogBytesOut) }</div>
                    <div></div>
                </div>
                </>
            );
        }

        return headerMarkup;
    }

    private buildReportFooter = (): JSX.Element => {
        const reportUIState = this.props.reportUIState;
        const org = this.props.authNServerData.currentOrg;
        const units = reportUIState.billingUsageUnits;

        const orgUsageData = reportUIState.billingOrgUsageData;

        const orgData = this.buildRecord(STRs.orgSummary(org.org_name), orgUsageData as BillingBaseData, units, 'billing-footer', 'billing-projects-footer');

        return orgData;
    }

    private buildGridLabel = (label: string, key: string): JSX.Element => {
        return (<React.Fragment key={key}>
            <div></div>
            <div><div>{label}</div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </React.Fragment>);
    }

    private buildGridData = (name: string | JSX.Element, data: BillingBaseData, units: UsageUnits, toggleService?: JSX.Element, key?: string): JSX.Element => {
        const tService: JSX.Element = (toggleService !== undefined) ? toggleService : <span></span>;
        const fldNames: string[] = BillingUnitsMap[units];

        const buildReportNumber = (n: number): string => {
            if (n === undefined) {
                console.log(`project: ${name} has bad data`)
                return "0";
            } else {
                const nStr = n.toFixed(2);
                n = parseFloat(nStr)
                return (Math.trunc(n) === n) ? n.toLocaleString() : nStr;
            }
        }

        return (<React.Fragment key={key}>
            <div>{tService}</div>
            <div><div>{name}</div></div>
            <div><div>{Math.round(data.total_requests).toLocaleString()}</div></div>
            <div><div>{buildReportNumber( data[fldNames[BTUnitsIdx.bytesOut]] )}</div></div>
            <div><div>{buildReportNumber( data[fldNames[BTUnitsIdx.bytesIn]])}</div></div>
            <div><div>{buildReportNumber( data[fldNames[BTUnitsIdx.logBytesOut]])}</div></div>
            <div></div>
        </React.Fragment>);
    }

    private buildRecord = (name: string | JSX.Element, data: BillingBaseData, units: UsageUnits, key: string, css?: string, toggleService?: JSX.Element,
                           svcData?: ServiceBillingData[]): JSX.Element => {
        const rowCSS = css ? css : 'billing-project-body-item';
        // logger.log(`---><---  Project: ${name};};`);
        const projData: JSX.Element[] = [];
        projData.push((<div className={rowCSS} key={'rcd'+name}>{this.buildGridData(name, data, units, toggleService, key+'p')}</div>));

        if (svcData) {
            projData.push(<div key={'dash'+name} className="billing-dash"></div>)

            projData.push((<div className={ rowCSS + ' billing-project-body-label'}>{this.buildGridLabel(STRs.staging, 'svcKey'+name+'staging')}</div>));
            for (let i=0, len = svcData.length; i < len; i++) {
                const sData: ServiceBillingData = svcData[i];

                if (sData.env === ZEnvironments.prod) continue;
                
                projData.push((<div className={ rowCSS + ' billing-project-body-svc'}>
                    {this.buildGridData(sData.zycadized_domain_name, sData, units, undefined, 'svcKey'+name+i)}
                </div>));
            }

            projData.push((<div className={ rowCSS + ' billing-project-body-label'}>{this.buildGridLabel(STRs.production, 'svcKey'+name+'prod')}</div>));
            for (let i=0, len = svcData.length; i < len; i++) {
                const sData: ServiceBillingData = svcData[i];

                if (sData.env === ZEnvironments.staging) continue;
                
                projData.push((<div className={ rowCSS + ' billing-project-body-svc'}>
                    {this.buildGridData(sData.zycadized_domain_name, sData, units, undefined, 'svcKey'+name+i)}
                </div>));
            }
            projData.push((<div className={ rowCSS + ' billing-project-body-label'}>{this.buildGridLabel(' ', 'svcKey'+name+'padding')}</div>));
        }

        return (
            <div key={key}>
                {projData}
            </div>
        )
    }

    private buildReportBody = (): JSX.Element => {
        const reportState = this.props.reportUIState;
        const reportData: JSX.Element[] = [];
        const showServices = reportState.showingServices

        const reportUnits = reportState.billingUsageUnits;

        const projects: BillingDataUI[] = reportState.billingUsageData;
        projects.forEach((data: BillingDataUI, idx: number): void => {
            let toggle = <div><span></span></div>;
            if (showServices) {
                const ticon  = data.showingServicesOpen ? 'caret-down' : 'caret-right';
                toggle = (<div className="field-cursor-pointer" onClick={(): void => { this.props.toggleBillingShowProjectServices(idx) }}>
                                    <FontAwesomeIcon icon={ticon as IconProp} />
                                </div>);
            }

            const svcData: ServiceBillingData[] | undefined = data.showingServicesOpen ? (data as ProjectServiceBillingDataUI).services : undefined
            reportData.push(this.buildRecord(data.name, data as BillingBaseData, reportUnits, 'billing' + idx, undefined, 
                                             toggle, svcData));
        })

        return (<>{reportData}</>);
    }

    private initTrafficUsage = async (): Promise<void> => {

        const url = buildUrl(this.props.authNServerData, ZURLS.serverBillingReportsAvailable);

        try {
            this.props.applySettings();
            const getData: ZRestResponse = await ZGet(url, {});
            this.props.setBillingReportData(getData as AvailableBillingReports);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`TrafficeUsage.initTrafficUsage:: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
                this.props.applySettings('serverError');
            })
        }
    }

    private loadReportData = async (): Promise<void> => {
        const reportState = this.props.reportUIState;

        const statement = reportState.availableBillingReports.traffic_usage[reportState.periodIdx];
        const url = buildUrl(this.props.authNServerData, ZURLS.serverBillingServicesReportsMonthly('traffic_usage'));
        const queryString = { month: statement.month, year: statement.year };
        try {
            const getData = await ZGet(url, queryString);
            this.props.setProjectTrafficUsage(getData as BillingOrgUsageData, true);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`TrafficeUsage.loadReportData: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
                this.props.applySettings('serverError');
            })
        }
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        reportUIState: state.reportUIState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default  connect (stateToProps, mapDispatchToProps)(TrafficUsage);
