import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { State } from '../App';
import { actionTypes } from '../actions/actionCreatorTypes';

import { ZSessionStorage } from '../data/metricsAndOptionsDefs';
import { FetchError, LoginData, OrgInfo, RestAPI, ServerConfig, ProjectSolutions,
         ZProjectMin, ZRestResponse, ZUserMin, ZUserTypes } 
        from '../data/queryResultDefinitions';

import { Redirect } from 'react-router-dom';
import { buildLoginServerURL, buildUrl } from '../reducers/serverEnvironAccessor'
import { authProgressStates, loginState, PwdResetState, SysFeatureEnums } from '../reducers/reducerEnums';

import {  getSessionStorage, removeSessionStorage } from '../shared/utilities';
import { validateEntry, ValidationResult } from '../shared/authUtils';
import { LOGIN_STRINGS as Strs, MISC_STRINGS, /*NAVSTRINGS*/ } from '../shared/strings';
import { ZPost, ZGet } from '../shared/backend';

import ZURLS from '../shared/urls';
import LoginPanel  from '../shared/LoginPanel';
import logger from '../shared/logUtilities';
import ZButton from '../shared/ZButton';
import { NotificationStyles, MAX_PASSWORD_LENGTH } from '../shared/constants';
import NotificationControl from '../shared/Notification';

interface LoginProps extends State {
    setSubNavTab: (navItem: string, feature: SysFeatureEnums) => actionTypes;
    loginUserFail: (errorMsg: string) => actionTypes;
    loginUserStart: () => actionTypes;
    loginUserSuccess: () => actionTypes;
    loginUserTFA: () => actionTypes;
    setUserInfo: (user: ZUserMin) => actionTypes;
    saveServerConfig: (serverConfig: ServerConfig) => actionTypes;
    setProjects: (projects: ZProjectMin[]) => actionTypes;
    setResetPasswordState: ( pwdResetState: PwdResetState) => actionTypes;
    updateEmailPassword: (email: string, password: string) => actionTypes;
    updateTFAToken: (token: string) => actionTypes;
    setOrgList: (orgList: OrgInfo[], selectedOrgName?: string)  => actionTypes;
    setCurrentOrg: (orgId: string) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    redirectTo: (url: string) => actionTypes;
    setSystemProjectSolutions: (solutions: ProjectSolutions[]) => actionTypes;
    setPwdExpiredAuthToken: (authToken: string) => actionTypes;
}

export interface LoginCredentials {
    email?: string;
    password?: string;
}

class Login extends React.Component<LoginProps> {
    private emailRef = React.createRef<HTMLInputElement>();
    private pwdRef = React.createRef<HTMLInputElement>();
    constructor(props: LoginProps) {
        super(props);

        this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
        this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
        this.handleActionLink = this.handleActionLink.bind(this);
        this.handleLoginOrReset = this.handleLoginOrReset.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.authNServerData.isLoggedIn === loginState.loggedIn) {
            logger.log('login: Already logged in...');
        }
        
        if (! this.props.authNServerData.serverConfigLoaded) {
            const configURL = (ZURLS.getSysConfigURL( ) as string) + 'conf.json';
            const promise = ZGet(configURL, '');
            promise.then(( lData: ZRestResponse ) => {
                const serverConfig = lData as ServerConfig;
                this.props.saveServerConfig(serverConfig);
                this.login('', serverConfig.login_url + ZURLS.serverLogin);
                logger.log('Login: Logging in using cookie!')
            })
            .catch((errStr) => {
                const p = errStr as Promise<FetchError>;
                p.then((err) => {
                    logger.log(`Login.componentDidMount: fetch failed: ${err}`);

                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.handleCloseNotification();
                })
            });
        }
    }

    public componentDidUpdate(): void {
        if (this.props.systemNavProjectState.redirectUrl.length > 0) {
            this.props.redirectTo('');
        }
    }

    public handleEmailUpdate(e: React.FormEvent<HTMLInputElement>): void {
        this.props.updateEmailPassword(e.currentTarget.value, this.props.authNServerData.password)
    }

    public handlePasswordUpdate(e: React.FormEvent<HTMLInputElement>): void {
        this.props.updateEmailPassword(this.props.authNServerData.email, e.currentTarget.value)
    }

    // This method flips the user from 
    public handleActionLink(): void {
        const pwdResetState = this.props.authNServerData.pwdResetState;
        if (pwdResetState === PwdResetState.normalLogin) {
            this.props.setResetPasswordState( PwdResetState.wantEmail  );
        } else {
            this.props.setResetPasswordState( PwdResetState.normalLogin  );
        }
    }

    public handleLoginOrReset(): void {
        const serverData = this.props.authNServerData;
        const { email, password, pwdResetState, authProgress, tfaToken } = serverData;
        // console.log(`----------> ${authProgress}`)

        const valid: ValidationResult = validateEntry(email, 'e');
        if (valid.isValid) {
            if (pwdResetState === PwdResetState.wantEmail) {
                const params = { email: email };
                const url = buildLoginServerURL(serverData, ZURLS.serverReqResetPwd)
                this.sendForgetEmailRequest(params, url);

            } else if (authProgress === authProgressStates.tfa) {
                const params = JSON.stringify({ login: email , password, 'otp_token': tfaToken })
                const url = buildLoginServerURL(serverData, ZURLS.serverTfaLogin);
                this.login(params, url);

            } else {
                this.props.loginUserStart();
                
                const params = JSON.stringify({ login: email , password })
                const url = buildLoginServerURL(serverData, ZURLS.serverLogin);
                this.login(params, url);
            }
        } else {
            this.props.setResetPasswordState( PwdResetState.invalidEmail );
        }
    }

    public render(): JSX.Element {
        let title: string;
        let buttonAction;
        let actionLink;
        let passwordField;
        let emailSent = false;

        if (this.props.systemNavProjectState.redirectUrl.length > 0) {
            // this.props.redirectTo('');
            return <Redirect to={this.props.systemNavProjectState.redirectUrl} />
        }
        
        const authNServerData = this.props.authNServerData;
        const { password, email, pwdResetState, tfaToken, authProgress } =  authNServerData ;
        const msg: string = authNServerData && authNServerData.error ? authNServerData.error : '';
        const showNotification: boolean = (authNServerData !== undefined) && 
                                          (authNServerData.error  !== undefined) && 
                                          (authNServerData.error.length > 0);
        let markup = <div></div>;

        // console.log(`----------> authProgress ${authProgress}`)
        const silentLoginInProgress = (authNServerData && 
                                      (authProgress === authProgressStates.inProgress) && 
                                       email.length === 0);

        // don't show anything if we are in the middle of silent login
        if (! silentLoginInProgress) {
            if (showNotification) {
                this.handleCloseNotification( 10000 );
            }
            // Are we going to show the login page or 'I want to reset my password' page
            //    login page needs - password entry field
            //    want reset page needs - 'back' link
            //    other fields are just settings and text
            if (pwdResetState !== PwdResetState.wantEmail && pwdResetState !== PwdResetState.emailSent) {
                title = '';
                buttonAction = Strs.login;
                actionLink = Strs.forgotMyPwd;
                passwordField = (
                    <div className="input-group input-group-lg">
                        <input id="password" type="password" className="form-control" placeholder={Strs.password}
                            value={password} onChange={this.handlePasswordUpdate} required ref={this.pwdRef} 
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
                                this.inputCheck(e, 'pwd')}} maxLength={MAX_PASSWORD_LENGTH} />
                        <span className="input-group-addon"><FontAwesomeIcon icon={['fas', 'lock']} /></span>
                    </div> ); 
            } else {            // reset password
                title = Strs.forgotPwd;
                emailSent = (pwdResetState as PwdResetState === PwdResetState.emailSent);
                buttonAction = !emailSent ? Strs.emailPwd : Strs.emailSent;
                actionLink = Strs.back;
                passwordField = ''
            }

            if (this.viewingMainLoginPage()) {
                const haveFormData = password.length > 0 || email.length > 0;
                if (haveFormData && this.props.authNServerData.authProgress === authProgressStates.tfa) {
                    const enableTFALogin = tfaToken.length !== 6;
                    markup = (
                        <LoginPanel title={title} msg="" lpCSS="display-box3">
                            <div className="tfa-login-msg">{Strs.tfaInstruct}</div>
                            <div>
                                <input type="text" className="form-control" value={tfaToken} size={6} 
                                    maxLength={ 6 } placeholder={MISC_STRINGS.tfaToken}
                                    onChange={(e: React.FormEvent<HTMLInputElement>): void  => { 
                                                    this.props.updateTFAToken(e.currentTarget.value); }}
                                />
                            </div>
                            <ZButton disabled={enableTFALogin} onClick={this.handleLoginOrReset} 
                                     btnCls="login-btn-style">
                                <span>{buttonAction}</span>
                            </ZButton>
                        </LoginPanel>
                    );
                } else if (this.props.authNServerData.authProgress !== authProgressStates.success) {
                    // console.log('---------> displaying regular login panel')
                    markup = (    
                        <LoginPanel title={title} msg="" lpCSS="display-box1">
                            <div id="loginForm">
                                <div className="input-group input-group-lg">
                                    <input id="email" type="email" className="form-control" 
                                           placeholder={ MISC_STRINGS.emailAddress } value={email} 
                                           onChange={this.handleEmailUpdate} ref={this.emailRef}
                                           onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void  => {
                                               this.inputCheck(e, 'email')}} 
                                    required />
                                    <span className="input-group-addon"><FontAwesomeIcon icon={['fas', 'envelope']} />
                                    </span>
                                </div>
                                {passwordField}
                            </div>
                            <ZButton disabled={emailSent} onClick={this.handleLoginOrReset} btnCls="login-btn-style">
                                <span>{buttonAction}</span>
                            </ZButton>

                            <div className="change-state">
                                <div onClick={this.handleActionLink}>{actionLink}</div>
                            </div>
                        </LoginPanel>
                    );
                }  else {
                    // console.log('---------> displaying logging in.....')

                    markup = <div></div>
                }
            } else {
                markup = ( <div>{msg}</div>)
            }
        }

        return (
            <div id="login">
                <NotificationControl show={showNotification} message={msg}
                    style={this.props.authNServerData.notificationStyle}
                    cssStyles="errorPosition"
                    closeHandler={(): void  => { this.handleCloseNotification() }} />                    
                {markup}
            </div>
        );
    }

    private viewingMainLoginPage = (): boolean => {
        const authNServerData = this.props.authNServerData;
        return  (authNServerData && authNServerData.serverConfigLoaded);
    }

    private inputCheck = (e: React.KeyboardEvent<HTMLInputElement>, type: string): void  => {
        if (e.key === '/n' || e.key === '/r') {
            console.log(`Login.tsx: got a carrage return. From ${type}`);
            // let email = this.emailRef.current;
            // let pwd = this.pwdRef.current;
            // if (this.props.authNServerData.email.length > 0) {

            // }
            // if (this.props.logsUIState.areLogOptionsDirty) {
            //     this.handleLoginOrReset();
            // }
        }
    }

    private login = async (params: string, loginUrl: string): Promise<void> => {
        this.props.loginUserStart();
        const doingSilentLogin = (params.length === 0);
        const serverData = this.props.authNServerData;
        try {
            let lData: ZRestResponse = {} as ZRestResponse;
            if (doingSilentLogin) {
                const silentUrl = buildLoginServerURL(serverData, ZURLS.serverCheckLoggedIn);
                lData = await ZGet(silentUrl, params);
                // console.log('silent login: login done');
            } else {
                lData = await ZPost(loginUrl, params);
                console.log('active login: login done');
                if (lData === undefined) {
                    window.location.reload();
                }
            }
            if (lData === undefined) {
                console.log('lData is undefined.')
                return;
            }
            serverData.currentApiURL = serverData.serverConfig.api_url_mappings.prod
            const url = buildUrl(serverData, ZURLS.serverAllProjectSolutions);
            const solutions = await ZGet(url, {});
            this.props.setSystemProjectSolutions(solutions as ProjectSolutions[])

            this.props.loginUserSuccess();
            this.initUser(lData);
            this.startApp(params);
        }
        
        catch(e) {
            const p = e as Promise<FetchError>;
            try {
                    p.then((err) => {
                    if (err && err.prompt_for_otp) {
                        this.props.loginUserTFA();
                    } else if (err !== undefined && err.auth_data !== undefined) {
                        this.props.setPwdExpiredAuthToken(err.auth_data);
                        this.props.redirectTo(ZURLS.expired);
                    } else {
                        let msg = err.message
                        if (msg === 'unauthorized' && params.length > 0) {
                            msg = Strs.loginError
                            
                        } else if ((msg === 'unauthorized' || msg === 'Authentication failure')
                                && params.length === 0) {
                            msg = '';
                        }
                        this.props.loginUserFail(msg);
                    }
                })
            } catch (e) {
                removeSessionStorage();
                const msg = Strs.loginError2;           // 'Error reaching server.  Please try again later!';
                this.props.loginUserFail(msg);
                logger.log(`Login: login failed.  We didn't even get an error back.  Server is down.`);
            }
        }
    }

    private initUser = (lData: ZRestResponse): void  => {
        const loginData = lData as LoginData
        let orgId = '';
        if (loginData.org_info === undefined) {
            loginData.user.role = ZUserTypes.user;
        } else {
            orgId = loginData.org_info.org_id;
        }
        this.props.setOrgList([loginData.org_info])
        this.props.setCurrentOrg(orgId)
        this.props.setUserInfo(loginData.user );

        logger.log('Login: login success.  Getting URI Codes.');
    }

    private startApp = (params: string): void  => {
        let navUrl = ZURLS.analytics
        if (params.length === 0) {
            const sessionData: ZSessionStorage = getSessionStorage();

            const browserUrl = sessionData.url;
            if (browserUrl !== undefined) {
                navUrl = browserUrl;
            }
        }

        this.props.redirectTo(navUrl);
    }

    private sendForgetEmailRequest(params: RestAPI, resetUrl: string): void  {
        const url = `${resetUrl}?email=${params.email}`;
        const promise = ZPost(url, '{}');
        // const that = this;
        promise.then( (  ) => {
            this.props.setResetPasswordState(PwdResetState.emailSent)
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                logger.log(`Login.setForgetEmailRequest: fetch failed: ${err.message}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.props.setResetPasswordState(PwdResetState.miscError)
                this.handleCloseNotification();
            })
        });
    }

    private handleCloseNotification(timeout = 10000): void  {
        window.setTimeout(() => { this.props.closeNotification(); }, timeout)
    }
}

export default Login;