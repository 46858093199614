import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { LOGIN_STRINGS as Strs } from './strings';
import { PwdValidationState } from './authUtils';

export interface PwdValidationProps {
  valState: PwdValidationState;
  background?: string;
}

// tslint:disable-next-line: variable-name
const PwdValidationCtrl:  React.FunctionComponent<PwdValidationProps> = (validation) => {
    const valState = validation.valState;
    const bckgrndCss = validation.background ? validation.background : 'panel-bgd';

    return (
      <div id="validate-instruct">
      <strong>{Strs.pwdRulesTitle}</strong>
        <div className={bckgrndCss}>
            <div className={valState.letters}>
                <span><FontAwesomeIcon icon={['fas', 'check']} /></span>
                {Strs.pwdRuleLetters}</div>
            <div className={valState.numbers}>
                <span><FontAwesomeIcon icon={['fas', 'check']} /></span>
                {Strs.pwdRuleNumbers}</div>
            <div className={valState.minChars}>
                <span><FontAwesomeIcon icon={['fas', 'check']} /></span>
                {Strs.pwdRuleMinChars}</div>
            <div className={valState.pwdMatch}>
                <span><FontAwesomeIcon icon={['fas', 'check']} /></span>
                {Strs.pwdRulePwdsMatch}</div>
            <div className="red">{valState.badCharMsg}&nbsp;&nbsp;&nbsp;{valState.badChars}</div>
                {Strs.pwdRuleInvalChars}
        </div>
      </div>
    );
  }

export default PwdValidationCtrl;