/* eslint-disable react/prop-types */
import * as React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core'
import moment from 'moment';
import logger from './logUtilities'
import { ANALYTICS_STRINGS as STRs, MISC_STRINGS as MSTRs } from './strings';
import TabControl, { TabItem } from './TabControl';
import AbsTimeControl from './AbsTimeControl';
import { hourIntervalData, TzAbbrev } from './constants';
import ZDropDown from './ZDropDown';

export interface TimeControlOptions {
    intervalList?: string[],
    tab1Label?: string,
    tab2Label?: string
    noTab1Icon?: boolean,
    noTab2Icon?: boolean,
    validDateTime?: (current:  moment.Moment, selected: moment.Moment) => boolean,
}

export interface TimeCtrlProps {
    showingRelativeTime: boolean,
    relativeTimeIdx: number,
    from: moment.Moment,
    to: moment.Moment,
    handleTabChange: (relativeSelected: number) => void,
    handleRelativeSelectChange: (index: number, optionName: string) => void,
    handleFromChange: (from: moment.Moment | string) => void,
    handleToChange: (to: moment.Moment | string) => void,
    tcOptions?: TimeControlOptions 
}

// const GenDialog: React.FunctionComponent<GenDlgProps> = props => {
const  TimeControlTab: React.FunctionComponent<TimeCtrlProps> = props => {
    const tco: TimeControlOptions = { }
    const tz = TzAbbrev.length > 0 ? ` (${TzAbbrev.trim()})` : '';
    if (props.tcOptions !== undefined) {
        tco.tab1Label = props.tcOptions.tab1Label !== undefined ? props.tcOptions.tab1Label : MSTRs.relTime;
        tco.tab2Label = props.tcOptions.tab2Label !== undefined ? props.tcOptions.tab2Label : MSTRs.absTime + tz;
        tco.noTab1Icon = !!props.tcOptions.noTab1Icon;
        tco.noTab2Icon = !!props.tcOptions.noTab2Icon;
        let validIntervalList = false;
        if ((props.tcOptions.intervalList !== undefined) && Array.isArray(props.tcOptions.intervalList)) {
            if (props.tcOptions.intervalList.length > 0 && typeof props.tcOptions.intervalList[0] === 'string') {
                validIntervalList = true;
            }
        }
        tco.intervalList = validIntervalList ? props.tcOptions.intervalList : hourIntervalData.map(entry => entry.name);
        tco.validDateTime = props.tcOptions.validDateTime;
    } else {
        tco.tab1Label = MSTRs.relTime;
        tco.tab2Label = MSTRs.absTime + tz;
        tco.intervalList = hourIntervalData.map(entry => entry.name);
        tco.noTab1Icon = false;
        tco.noTab2Icon = false;
    }
    const options: JSX.Element[] = [];
    const tabData: TabItem[] = [
        { text: tco.tab1Label, iconCSS: 'r-border', icon: 'arrow-left' as IconName, selected: props.showingRelativeTime },
        { text: tco.tab2Label, iconCSS: 'lr-border', icon: 'arrows-alt-h' as IconName, selected: !props.showingRelativeTime }
    ];
    if (tco.noTab1Icon) {
        tabData[0].icon = '' as IconName;
        tabData[0].iconCSS = 'no-border';
    }
    if (tco.noTab2Icon) {
        tabData[1].icon = '' as IconName;
        tabData[1].iconCSS = 'no-border';
    }
    options.push((
        <TabControl key="timeTabControl" tabData={tabData} tabSelectedHandler={selectedTabHandler} ></TabControl>
    ))

    if (props.showingRelativeTime) {
        const dropdown = ZDropDown(
            tco.intervalList as string[],
            props.relativeTimeIdx,
            'period',
            (index, optionName) => { handleOptionSelect(index, optionName as string) }, // optionName as string
            { label: STRs.periodLabel, optionName: 'period' });
        options.push((<div key="anl-periods">{dropdown}</div>));
    } else {
        const absTime = <AbsTimeControl from={props.from} to={props.to}  key="fromToTimeCtrl" validDateTime={tco.validDateTime}
                                        handleFromChange={fromChangeHandler} handleToChange={toChangeHandler} />
        options.push(absTime);
    }
    return (<div key="timecontrol">{options}</div>)

    function fromChangeHandler(fromDate: moment.Moment | string) {
        logger.log(fromDate);
        (props.handleFromChange)(fromDate as moment.Moment)
    }

    function toChangeHandler(toDate: moment.Moment | string) {
        logger.log(toDate);
        (props.handleToChange)(toDate)
    }

    function handleOptionSelect(index: number, optionName: string) {
        (props.handleRelativeSelectChange)(index, optionName)
    }

    function selectedTabHandler(selectedTab: number) {
        (props.handleTabChange)(selectedTab);
    }
}

export default TimeControlTab;