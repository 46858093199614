import React, { useState } from 'react';
import * as Diff from 'diff';
import * as Diff2Html from 'diff2html'
import 'diff2html/bundles/css/diff2html.min.css'

import { ZServiceExt, ZServiceVersion, FetchError, ServiceStatusEnum } from '../../data/queryResultDefinitions';
import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { ZService } from '../../reducers/systemState';
import { State as AuthNServerData } from '../../reducers/authentication';

import ZDropDown from '../../shared/ZDropDown';
import ZButton from '../../shared/ZButton';
import GenDialog from '../../shared/GenDialog';

import { ZGet  } from '../../shared/backend';
import ZURLS from '../../shared//urls';
import { toTitleCase } from '../../shared/utilities';
import { SERVICES_STRINGS as Strs, MISC_STRINGS} from '../../shared/strings';

export interface DiffSvcVersionsProps {
    currentSvc: ZServiceExt,
    versionList: ZServiceVersion[],
    serverDetail: AuthNServerData,
    done: () => void,
    notification: (message: string) => void,
}

const DiffServiceVersions: React.FunctionComponent<DiffSvcVersionsProps> = props => {
    const [ svcA,  setServiceA ] = useState({} as ZService);
    const [ svcB,  setServiceB ] = useState({} as ZService);
    const [ verIndexA, setVerIndexA ] = useState(0);
    const [ verIndexB, setVerIndexB ] = useState(0);
    const [ oldVerIndexA, setOldVerIndexA ] = useState(0);
    const [ oldVerIndexB, setOldVerIndexB ] = useState(0);
    const [ editorVisible, setEditorVisible] = useState(true)

    const disabled = verIndexA === 0 || verIndexB === 0 || 
                     (oldVerIndexA === verIndexA && oldVerIndexB === verIndexB)

    const getVersionDetail = async (serviceId: string, version: number): Promise<ZService>  => {
        const url = buildUrl(props.serverDetail, ZURLS.serverServiceManagement(serviceId));
        let service: ZService = { } as ZService;
        try {
            const serviceConfig = await ZGet(url, {version});
            service = serviceConfig as ZService;
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`DiffServiceVersions.getVersionDetail: fetch failed: ${err}`);
                props.notification( err.message )
            })
            .catch((err) => {
                console.log(`viewService.getVersionDetail: fetch failed: ${err}`);
            })
        }
        return new Promise(function(resolve) { resolve(service)});
    }

    const loadVersion = async (): Promise<void> => {
        try {
            const servA = await getVersionDetail(props.currentSvc.service_id, props.versionList[verIndexA - 1].version);
            const servB = await getVersionDetail(props.currentSvc.service_id, props.versionList[verIndexB - 1].version);
            setServiceA(servA as ZService);
            setServiceB(servB as ZService);
            setOldVerIndexA(verIndexA);
            setOldVerIndexB(verIndexB);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`viewService.loadServiceVersions: fetch failed: ${err}`);
                props.notification(err.message)
            })
            .catch((err) => {
                console.log(`viewService.loadServiceVersions: fetch failed: ${err}`);
            })
        }
    }

    const vList: string[] = [Strs.chooseAVersion];
    props.versionList.forEach((version: ZServiceVersion)=> { 
        const vnum = (version.status === ServiceStatusEnum.draft) ? toTitleCase(Strs.draft) : Strs.versionNum(version.version)
        vList.push(vnum)}
    )
    
    const ddown1 = ZDropDown ( vList, verIndexA, 'vlista',  (index) => { setVerIndexA(index)} );
    const ddown2 = ZDropDown ( vList, verIndexB, 'vlistb',  (index) => { setVerIndexB(index)} );
    
    const ctrlMarkup = (
        <div className="diff-ctrls">
            <div>{Strs.firstVersion}&nbsp;&nbsp;{ddown1}&nbsp;&nbsp;</div>
            <div>{Strs.secondVersion}&nbsp;&nbsp;{ddown2}&nbsp;&nbsp;</div>
            <div><ZButton onClick={() => {loadVersion()}} disabled={disabled}>{ Strs.diffBtnText }</ZButton></div>
        </div>
    )

    let diffMarkup = <div className="diff-ins">{ Strs.diffInstructs } <strong>{ Strs.diffBtnText }</strong></div>;
    if  ((svcA !== undefined && svcA.service_id !== undefined) && (svcB !== undefined && svcB.service_id !== undefined)) {
        const oldIndexA = oldVerIndexA !== 0 ? oldVerIndexA : verIndexA; 
        const oldIndexB = oldVerIndexB !== 0 ? oldVerIndexB : verIndexB; 

        const fmtSvcA = JSON.stringify(svcA, null, 4);
        const fmtSvcB = JSON.stringify(svcB, null, 4);

        const myDiff =  Diff.createTwoFilesPatch('file', 'file', fmtSvcA, fmtSvcB, undefined, undefined, { context: Number.MAX_SAFE_INTEGER });
        const outputHtml: string = Diff2Html.html(myDiff, {outputFormat: 'line-by-line', matching: 'lines'});

        diffMarkup =  (
            <div className="zy-diff">
                <div className="diff-data-header">
                    <div className="del">&nbsp;{ Strs.removedFromVersion(vList[oldIndexA])}&nbsp;</div>
                    <div className="ins">&nbsp;{ Strs.addToVersion(vList[oldIndexB])}&nbsp;</div>
                </div>
                <div className="diff-data">
                    <div dangerouslySetInnerHTML={{__html: outputHtml}} />
                </div>
            </div>
        )
    }

    const diff = (
        <GenDialog 
            show={editorVisible}
            title={Strs.diffVersionTitle(props.currentSvc.service_name)} 
            msg={''}
            size='xl'
            cancelBtnTxt={MISC_STRINGS.close}
            hideApplyButton={true}
            disableApply={(): boolean => {return true}}
            onHide={(): void => { setEditorVisible(false); props.done();} }
            handleApply={(): void => {setEditorVisible(false); props.done(); }}
        >
            <div className="list-boundary">
                {ctrlMarkup}
                {diffMarkup}
            </div>
        </GenDialog>
    );

    return diff;
}

export default DiffServiceVersions;
