import { State, AdminManagementTypes } from './adminState';
import { ZUserTypes, ZProjectMin, ZUserMin } from '../data/queryResultDefinitions';

export const getNumCheckedAdminItems = ( state: State, pageType: AdminManagementTypes ): number => {
    let count = 0;

    if (pageType === AdminManagementTypes.users) {
        const projectIds = Object.keys(state.checkedProjects);
        projectIds.forEach((projectId: string) => {
            if (state.checkedProjects[projectId]) {
                count++;
            }
        })
    } else if (pageType === AdminManagementTypes.projects) {
        const userIds = Object.keys(state.checkedUsers);
        userIds.forEach((userId: string) => {
            if (state.checkedUsers[userId]) {
                count++;
            }
        })
    } else if (pageType === AdminManagementTypes.apiKeys) {
        const keyIds = Object.keys(state.checkedApiKeys);
        keyIds.forEach((keyId: string) => {
            if (state.checkedApiKeys[keyId]) {
                count++;
            }
        })
    }

    return count;
}

export const userIsAdmin = (role: ZUserTypes): boolean => {
    return ((role === ZUserTypes.admin) || (role === ZUserTypes.zadmin));
}

export const canAddProjects = (state: State): boolean => {
    const user = state.selectedUser;
    const numPotentialProjects = state.potentialUserProjectList.length;
    return !userIsAdmin(user.role) && (numPotentialProjects > 0)
}

export const canAddUsers = (state: State): boolean => {
    return (state.potentialProjectUserList.length > 0)
}

export const getCheckedApiKeyList = (state: State): string[] => {
    const checkedKeyList = [];
    const keyIds = Object.keys(state.checkedApiKeys);
    for (let i = 0, len = keyIds.length; i < len; i++) {
        const id = keyIds[i];
        if (state.checkedApiKeys[id]) {
            checkedKeyList.push( id);
        }
    }

    return checkedKeyList;
}

export const getRemoveProjectList = (state: State): ZProjectMin[] => {
    const projects: ZProjectMin[] = [];

    const projectIds = Object.keys(state.checkedProjects);

    projectIds.forEach(projectId => {
        if (state.checkedProjects[projectId]) {
            projects.push(state.allProjectsMap[projectId]);
        }
    });

    return projects;
}

export const getRemoveUsersList = (state: State): ZUserMin[] => {
    const users: ZUserMin[] = [];

    const userIds = Object.keys(state.checkedUsers);

    userIds.forEach(userId => {
        if (state.checkedUsers[userId]) {
            users.push(state.allUsersMap[userId]);
        }
    });

    return users;
}

export const anyPopupCheckBoxesChecked = (state: State): boolean => {
    let anyChecks = false;
    state.popupCheckState.forEach((item) => { 
        if (item) { 
            anyChecks = true; 
        }
    });
    
    return anyChecks;
}