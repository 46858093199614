import * as React from 'react';

const ApiDocWhitelistAddDelete = (): JSX.Element => {
    return ( 
        <>
<div className="z-title">Add/Delete IP Addresses To/From Whitelist</div>

<div className="zsection">
    This API lets you to add or delete a set of IP addresses or netmasks to/from
    the whitelisted IPs of Zycada&apos;s firewall. The IP addresses are listed as
    an array in the request body. Use POST method to add IPs to the list and
    DELETE to remove IPs from the list.

    <div className="before-code"><h3> </h3></div>
    <table className="zlist">
        <tbody>
        <tr>
            <td>Method:</td>
            <td className="method">POST or DELETE</td>
        </tr>
        <tr>
            <td>Production URL:</td>
            <td className="zli-inner">https://api.zycada.com/v1/whitelisted_ips</td>
        </tr>
        <tr>
            <td>Staging URL:</td>
            <td className="zli-inner">https://staging-api.zycada.com/v1/whitelisted_ips</td>
        </tr>
        <tr>
            <td>Headers:</td>
            <td>
                <table className="izlist">
                    <tbody>
                    <tr>
                        <td>Authorization:</td>
                        <td>Bearer <i>[API_Key]</i></td>
                    </tr>
                    <tr>
                        <td>Content-Type:</td>
                        <td>application/json</td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>


    <div className="before-code"><h3>Arguments</h3></div>
    <ul className="simple-list">
        <li>The [API_KEY] is a string that  establishes authority to manipulate assets. You can only modify your own assets.</li>
    </ul>


    <div className="before-code"><h3>Request</h3></div>
    List of whitelisted IPs in the following format.

    <pre className="expected-response">{`{
    "whitelisted_ips": [            // Array of IP objects to be whitelisted.
                                    // The format of these objects are shown below.
        {
            "ip":  "x.x.x.x"        // string.  x.x.x.x represents the ip address you wish to whitelist
        },
        ...
    }
}`}</pre>

    <div className="before-code"><h3>Response</h3></div>
    Response is a JSON object that describes the status of the add/delete IPs
    to whitelist request. Response contains an array of IP objects with
    status.
    <pre className="expected-response">{`[
    {
        "ip": "x.x.x.x",            // IP address that was requested to be whitelisted
        "note": "",                 // Note from the server describing errors
        "status": ""                // One of the following status values: "success" | "failed"
    },
    ...
]`}</pre>

    <div className="before-code"><h3>HTTP Response Status</h3></div>
    <ul className="simple-list sample-response">
        <li>
            <h4>200</h4>
            Success.
        </li>
        <li>
            <h4>202</h4>
            Partial success. Some inputs are invalid.
        </li>
        <li>
            <h4>400</h4>
            Bad Request. (E.g. invalid JSON format, invalid field type in the
            request)
        </li>
    </ul>

    <div className="before-code"><h3>Sample HTTP Request</h3></div>
    <pre className="sample-response">{`{
    "whitelisted_ips": [
        {"ip": "1.2.3.4"},
        {"ip": "5.6.7.0/24"},
        {"ip": "999.999.999.999"}
    ]
}`}</pre>

    <div className="before-code"><h3>Sample HTTP Response</h3></div>
    <pre className="sample-response">{`[
    {
        "ip": "1.2.3.4",
        "status": "success"
    },
    {
        "ip": "5.6.7.0/24",
        "status": "success"
    },
    {
        "ip": "999.999.999.999",
        "status": "failed",
        "note": "Invalid IP"
    }
]`}</pre>
</div>
</>
    )
}

export default ApiDocWhitelistAddDelete;