import moment from 'moment';

import { actionTypes, ActionKeys, ASetSvcConfigIdx, ASetServiceVersionList, ASetSelectedServiceDetail,
        AToggleServicePopup, ASetServiceOverviewDetails, ASetProjectCertList, ASetDeploymentHistory,
        ASetServiceOriginDetails, ASetDeploymentHistoryViewIndex, ASetDeploymentDestinationIndex,
        ASetDeploymentVersionIndex, ASetServiceOptionIndex, ASetRoutingRule, AInitEditService, ADataGroupLoad,
        AApplySettings, ADataLoadInProgress, ASetServiceDnsDirectorDetails, ASetServiceCreationTemplate,
        ASetProjectFeaturesAttributes, ASetServiceHCData, AShowErrors, ADraftSaved, ASetUnsafePromoteState,
        ASetProjectPlatforms, ASetPlatformIdx} from '../actions/actionCreatorTypes';

import { ZServiceVersion, ZServiceExt, ZOrigin, Certificate, DeploymentHistoryItem, DeploymentRecord,ServerPlatforms,
         CacheFeature, FeatureDef, XffFeature, ZMatchFilter, ZCloudServiceMonitor, ZCSMEntity, DNSDirectorAttributes, ZProductFeatureAttributes,
         APISvrJobStatusEnum, RoutingRules, ZSecurePort, ZServiceFeatures, RoutingFeatures, ServiceStatusEnum, CSMAttributes
        } from '../data/queryResultDefinitions';

import { servicesTypeAssetRule, DisplayRoutingRules, BooleanMap, ZSessionStorage, ServiceFieldValidate, NumberMap } 
         from '../data/metricsAndOptionsDefs';

import { EProjectState, ZFeaturePopupTypes, ZEnvironments, HealthCheckIndex, BackupHCValues } from './reducerEnums';
import { editServiceVerifications } from './editServiceValidations'
import { getPublishPromoteValue } from './serverEnvironAccessor';
import { EditSvcFixedOptions } from '../shared/MiscEnums';
import { SERVICES_STRINGS as STRs, MISC_STRINGS } from '../shared/strings';
import { dateFixup, buildCSMEntry } from '../shared/utilities';
import { ServiceTemplateName } from '../shared/constants';
import logger from '../shared/logUtilities';
import cloneDeep from 'lodash/cloneDeep';

export interface ZServiceOrigin extends ZOrigin {
    tlsEnabled: boolean
}

export interface CurrentServerEnvStatusMap {
    [envName: string]: DeploymentRecord
}

export interface CurrentServerDeploymentInProgressMap {
    [envName: string]: boolean
}

export interface VersionListEntry {
    versionNumber: number,
    versionName: string,            // name used in the drop down
}


export enum HistoryViewIdxEnum {
    both = 0,
    staging = 1,
    prod = 2
}

export enum DestinationIdxEnum {
    staging = 0,
    prod = 1
}

// export interface SvcFeatureMap {
//     [featureStruct: string]: ZProductDef
// }

export interface DeployedItem  {
    prev_version: number,
    version: number,
    created_at: string, 
    updated_at: moment.Moment,
    status: string,
    env: string,
}

export interface State {
    serviceState: EProjectState
    svcVersionIdx: number
    svcVersions: ZServiceVersion[];
    selectedServiceVersion: ZServiceExt,
    selectedServiceVersionProd: ZServiceExt,
    serviceDeployable: boolean,
    popupOpen: boolean,
    popupType: ZFeaturePopupTypes,

    // certNameList: string[],
    certList: Certificate[],
    certsLoaded: boolean,
    // certMap: StringMap,
    certNameListIdx: number,
    serviceName: string,
    domainName: string,
    stagingCName: string,
    prodCName: string,
    certificateId: string,
    enableHTTP2: boolean,
    enableHTTPSRedirect: boolean,
    originList: ZServiceOrigin[],
    originListIdx: number,
    originName: string,
    originDomainName: string,
    domainRedirects: string,
    tlsEnabled: boolean,
    sni: string,
    popupDoneDestination: string,
    ddDomainName: string,
    zyTrafficPercentage: string,
    isLegacyService: boolean,

    versionIdx: number,
    destinationIdx: number,
    lastUpdate: moment.Moment,
    historyViewIdx: number,
    historyView: string[],
    deploymentHistory: DeploymentHistoryItem[],         // array of deployment information. 1 record per environment.
    deployedItems: DeployedItem[],                      // sorted list of the detail records (can be for prod & staging)
    destinationStatusMap: CurrentServerEnvStatusMap     // map where environment points to the current detail record
    availableDeploymentVersionList: VersionListEntry[],
    currentDeploymentInProgressStatus: CurrentServerDeploymentInProgressMap,

    editableService: ZServiceExt,
    svcNavListIdx: number,
    svcNavList: string[],
    routingRule: RoutingRules,
    routingRuleDisplay: DisplayRoutingRules,
    creationDate: moment.Moment,
    lastModified: moment.Moment,
    createdFrom: string,
    enabledRoutingRules: BooleanMap,
    isDirty: boolean,
    sessionStorage: ZSessionStorage,
    isDataLoading: boolean,
    dataLoading: boolean,
    serviceReloadInProgress: boolean,
    editSvcFieldValidate: ServiceFieldValidate,
    svcCreationTemplate: ServiceTemplateName,
    autoSwitch: boolean,
    csmHealthChecks: ZCSMEntity[],
    cdnTag: string,
    useCdnAsOrigin: boolean,
    ddAutoSwitchEnabled: boolean,
    canEditCDNTag: boolean,
    hasMoreThanOneCDN: boolean,
    isCDNReserve: boolean,
    showDeploymentErrors: boolean,
    projHasCSM: boolean,
    projHasDnsDir: boolean,
    projCsmHCIntervals:  NumberMap,
    serverPlatforms: ServerPlatforms,
    platforms: string[],
    platformIdx: number,

    unsafePromoteEnabled: boolean,
    unsafePromoteNeedConfirmation: boolean,
    unsafeCertNameMismatch: boolean,
}

const serviceValidations = editServiceVerifications();

const serviceInitData: State = {
    serviceState: EProjectState.ready,
    svcVersionIdx: 0,
    svcVersions: [],
    selectedServiceVersion: {} as ZServiceExt,
    selectedServiceVersionProd: {} as ZServiceExt,
    serviceDeployable: false,
    popupOpen: false,
    popupType: ZFeaturePopupTypes.NoPopup,
    isLegacyService: false,

    serviceName: '',
    domainName: '',
    certificateId: '',
    stagingCName: '',
    prodCName: '',
    enableHTTP2: false,
    enableHTTPSRedirect: false,
    originList: [],
    originListIdx: -1,
    originName: '',
    originDomainName: '',
    domainRedirects: '',
    tlsEnabled: false,
    sni: '',

    certsLoaded: false,
    certNameListIdx: 0,
    certList: [],

    ddDomainName: '',
    zyTrafficPercentage: '',
    canEditCDNTag: true,
    cdnTag: '',
    hasMoreThanOneCDN: false,
    popupDoneDestination: '',
    deploymentHistory: [],
    deployedItems: [],
    historyViewIdx: 0,
    destinationStatusMap: {},
    historyView: [ STRs.stagingProd, STRs.stagingEnv, STRs.productionEnv ],
    lastUpdate: moment(),
    versionIdx: 0,
    destinationIdx: 0,
    availableDeploymentVersionList: [],
    currentDeploymentInProgressStatus: {},

    editableService: {} as ZServiceExt,
    svcNavListIdx: -1,
    svcNavList: [],
    routingRule: {} as RoutingRules,
    enabledRoutingRules: {} as BooleanMap,
    isDirty: false,
    creationDate: moment(),
    lastModified: moment(),
    createdFrom: '',
    routingRuleDisplay: {} as DisplayRoutingRules,
    sessionStorage: {} as ZSessionStorage,
    isDataLoading: false,
    dataLoading: false,
    serviceReloadInProgress: false,
    editSvcFieldValidate: {} as ServiceFieldValidate,

    svcCreationTemplate: ServiceTemplateName.ecommerce,
    autoSwitch: false,
    useCdnAsOrigin: false,
    csmHealthChecks: [],
    ddAutoSwitchEnabled: false,
    isCDNReserve: false,
    showDeploymentErrors: false,
    projHasCSM: false,
    projHasDnsDir: false,
    projCsmHCIntervals:  {},

    serverPlatforms: {} as ServerPlatforms,
    platforms: [],
    platformIdx: 0,

    unsafePromoteEnabled: false,
    unsafePromoteNeedConfirmation: false,
    unsafeCertNameMismatch: false,
}

// ********************
// Rule #1 of redux....
// never mutate state!!!!!
//
// Bad:
//    case ActionKeys.SET_CURRENT_SERVICE_EVT_IDX:
//         state.svcVersionIdx = 0;
//
// Good:
//    case ActionKeys.SET_CURRENT_SERVICE_EVT_IDX:
//          state = { svcVersionIdx: 0 }
//
// Also Good:
//    case ActionKeys.SET_CURRENT_SERVICE_EVT_IDX:
//          state = {...initialState};
//          state.svcVersionIdx = 0
//
// In both of the good cases we do not mutate state!

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setProjectServices = (state: State, action: actionTypes): State => {
    const isDataLoading = state.isDataLoading;
    const intervalMap = state.projCsmHCIntervals
    state = Object.assign({}, serviceInitData);
    state.projCsmHCIntervals = intervalMap;

    state.certsLoaded = false;
    state.isDataLoading = isDataLoading;
    state.certList.length = 0;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeProject = (state: State, action: actionTypes): State => {
    state = Object.assign({}, serviceInitData);
    state.certsLoaded = false;
    state.projHasCSM = false;
    state.projHasDnsDir = false;
    state.certList.length = 0;

    return state;
}

const initNewServiceInfo = (state: State): State => {
    state.serviceName = '';
    state.domainName = '';
    state.certificateId = '';
    state.enableHTTP2 = false;
    state.enableHTTPSRedirect = false;
    state.stagingCName = '';
    state.prodCName = '';
    state.originList.length = 0;
    state.originListIdx = -1;
    state.originName = '';
    state.originDomainName = '';
    state.domainRedirects = '';
    state.tlsEnabled = false;
    state.sni = '';
    state.popupDoneDestination = '';
    state.deploymentHistory.length = 0;
    state.deployedItems.length = 0;
    state.historyViewIdx = 0;
    state.versionIdx = 0;
    state.destinationIdx = 0;
    state.availableDeploymentVersionList.length = 0
    state.isLegacyService = false;

    state.editableService = {} as ZServiceExt;
    state.selectedServiceVersion = {} as ZServiceExt;
    state.selectedServiceVersionProd = {} as ZServiceExt;

    state.svcNavListIdx = -1;
    state.svcNavList.length = 0;
    state.isDirty = false;
    state.createdFrom = '';
    state.routingRuleDisplay = {} as DisplayRoutingRules;
    state.enabledRoutingRules = {} as BooleanMap;
    state.sessionStorage = {} as ZSessionStorage;
    state.isDataLoading = false;

    state.ddDomainName = '';
    state.zyTrafficPercentage = '';
    state.editSvcFieldValidate = {
        serviceName: true,
        domainName: true,
        originName: true,
        originDomainName: true,
        domainsRedirect: true,
        ddDomainName: true,
        zyTrafficPercentage: true,
        sni: true,
        rules: [],
        healthChecks: [],
        cdnTag: true,
        domainRedirectErrors: {
            hasSvcDomainName: false,
            inValidDomainName: false,
            tooManyDomains: false,
        }
    };
    state.svcCreationTemplate = ServiceTemplateName.ecommerce,
    state.autoSwitch = false,
    state.useCdnAsOrigin = false,
    state.csmHealthChecks.length = 0;
    state.ddAutoSwitchEnabled = false;
    state.canEditCDNTag = true;
    state.cdnTag = '';
    state.hasMoreThanOneCDN = false;
    state.isCDNReserve = false;
    state.showDeploymentErrors = false;
    state.popupType = ZFeaturePopupTypes.NoPopup;
    state.unsafePromoteEnabled = false;
    state.unsafePromoteNeedConfirmation = false;
    state.unsafeCertNameMismatch = false;
    state.platforms = [];
    state.platformIdx = 0;
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const serviceChanged = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};

    const savedSession = state.sessionStorage;
    const projState = state.serviceState;

    state = initNewServiceInfo(state);
    if (projState !== EProjectState.initStarted) {
        state.serviceState = EProjectState.serviceChanged;
    } else {
        state.serviceState = projState;
        state.sessionStorage = savedSession;
    }
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const environmentChanged = (initialState: State, action: actionTypes): State => {
    let state = {...initialState};
    const savedSession = state.sessionStorage;
    const projState = state.serviceState;
    state = initNewServiceInfo(state);
    if (projState === EProjectState.initStarted) {
        state.serviceState = projState;
        state.sessionStorage = savedSession;
    } else {
        state.serviceState = EProjectState.serviceChanged;
    }
    return state;
}

const applySettings = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as AApplySettings;

    if (action.payload && action.payload.error) {
        state.serviceState = EProjectState.serverError;
    } else {
        state.serviceState = EProjectState.userClickedApply;
        state.isDataLoading = true;
    } 

    if (action.payload.optionalArgs && action.payload.optionalArgs.reloadCerts) {
        state.certsLoaded = false;
    }

    state.unsafePromoteEnabled = false;
    state.unsafePromoteNeedConfirmation = false;
    state.unsafeCertNameMismatch = false;


    return state;
}

const dataLoadInProgress = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ADataLoadInProgress;

    const sysAdminFeature = (action.payload.feature as string).indexOf('svcMgmt') !== -1;
    if (sysAdminFeature) {
        state.serviceState = EProjectState.dataLoadInProgress;
        state.isDataLoading = true;
    }
    return state;
}

const dataGroupLoad =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ADataGroupLoad;

    if (action.payload.feature === 'services') {
        state.dataLoading = action.payload.inProgress;
    }
    return state;
}

const setVersionIndex = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetSvcConfigIdx;
    state.svcVersionIdx = action.payload.idx;

    return state;
}

const setServiceVersionList = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetServiceVersionList;
    state.svcVersions.length = 0;
    const versionList = action.payload.svcVersions;

    for (let len = 0, i = versionList.length - 1; len <= i; i--) {
        state.svcVersions.push({...versionList[i]})
    }

    return state;
}

const findCert = (state: State, certId: string | undefined): number => {
    let certIndex = 0;
    const certList: Certificate[] = state.certList;

    if (certId !== undefined) {
        certIndex = certList.findIndex((cert: Certificate) => { return certId === cert.cert_id })
    }

    return certIndex;
}

const buildEditData = (state: State, service: ZServiceExt): State => {
    state.isDirty = false;
    state.svcNavList.length = 0;
    state.svcNavListIdx = 0;
    const rules: RoutingRules[] = service.rules;
    state.editableService = cloneDeep(service);

    state.serviceName = service.service_name;
    state.domainName = service.zycadized_domain_name;
    state.domainRedirects = service.redirect_from_zycadized_domain_names !== undefined ? service.redirect_from_zycadized_domain_names.join(', ') : '';

    if (service.env === 'staging') {
        state.stagingCName = service.cname;
        if (service.meta && service.meta.prod_info) {
            state.prodCName = service.meta.prod_info.cname;
        } else {
            state.prodCName = STRs.svcNeverDeployedToProd;
        }
    } else {
        state.prodCName = service.cname;
        if (service.meta && service.meta.staging_info) {
            state.stagingCName = service.meta.staging_info.cname;
        } else {
            state.stagingCName = '';
            const msg = `Production service ${service.service_name} (${service.service_id}) doesn't have a staging meta section.`
            logger.alert(msg);
            logger.log(msg);
        }
    }
    
    state.certNameListIdx = 0;
    if (service.ports) {
        const securePort: ZSecurePort = (service.ports['443'] as ZSecurePort);
        state.enableHTTP2 = false;

        if (service.ports['443'] !== undefined) {
            state.certNameListIdx = findCert(state, securePort.cert_id) + 1;
            state.enableHTTP2 = (securePort.http2_enabled !== undefined) ?  securePort.http2_enabled : false;
        }
    } else {
        state.certNameListIdx = 0;
        state.enableHTTP2 = false;
    }

    if (service.origins !== undefined) {
        const originNameList = Object.keys(service.origins);
        const origin = service.origins[originNameList[0]];
        state.originDomainName = origin.domain_name;
        state.originName = originNameList[0]
        state.tlsEnabled = (origin.port === 443);
        state.sni = (state.tlsEnabled) ? origin.sni as string : '';
    } else {
        state.originDomainName = '';
        state.originName = '';
        state.tlsEnabled = false;
        state.sni = '';
    }

    const ddDirector = service.dns_director
    state.isLegacyService = (ddDirector === undefined) || !state.projHasDnsDir;

    if (ddDirector !== undefined) {
        state.useCdnAsOrigin = ddDirector.use_cdns_as_origin;
        state.zyTrafficPercentage = ddDirector.zycada_traffic_percent.toString();
        state.autoSwitch = ddDirector.auto_switch;

        state.cdnTag = '';
        state.canEditCDNTag = true;
        state.ddDomainName = '';
        state.hasMoreThanOneCDN = false;
        state.isCDNReserve = false;

        if (ddDirector.cdns !== undefined) {
            const cdns = ddDirector.cdns;
            const cdnTags = Object.keys(cdns);

            state.cdnTag = cdnTags[0];
            state.canEditCDNTag = false;
            state.hasMoreThanOneCDN = cdnTags.length > 1;
            state.ddDomainName = cdns[cdnTags[0]].cdn_cname;
        } else {
            state.cdnTag = '';
            state.canEditCDNTag = true;
            state.ddDomainName = '';
            state.hasMoreThanOneCDN = false;
        }
    }
    state.isCDNReserve = state.ddAutoSwitchEnabled && state.autoSwitch && !state.isLegacyService;

    const hchk = state.csmHealthChecks;
    hchk.length = 0;

    // product and feature exists should have already been set
    if (state.projHasCSM) {
        const csm: ZCloudServiceMonitor = service.cloud_service_monitor as ZCloudServiceMonitor;


        if (csm) {
            // order is important here.  see the enum
            hchk.push(buildCSMEntry(csm.health_checks['cdn_check'] as ZCSMEntity));
            hchk.push(buildCSMEntry(csm.health_checks['origin_check'] as ZCSMEntity));
        } else {
            hchk.push(buildCSMEntry({} as ZCSMEntity));
            hchk.push(buildCSMEntry({} as ZCSMEntity));
        }
    }

    state.svcNavList.push(STRs.overview);
    state.svcNavList.push(STRs.origins);
    state.svcNavList.push(STRs.dnsDirector);
    state.svcNavList.push(STRs.cloudServiceMonitor);
    
    if (rules) {
        rules.forEach( (rule: RoutingRules ) => {
            const assetType = rule.asset_type;
            const uiAssetInfo = servicesTypeAssetRule[assetType]
            if (uiAssetInfo) {
                state.enabledRoutingRules[rule.asset_type] = true;
                state.svcNavList.push(uiAssetInfo.friendlyName)
            } else {
                logger.log(`serviceState.buildEditData: unknown rule asset_type (${assetType})`);
            }
        });
    }
    const features = state.editableService.features;
    const cache = features.cache as CacheFeature;
    if (features && (features.waf || features.xff || (cache && cache.default_age))) {
        state.svcNavList.push(STRs.dynamicAssets);
    }

    return state;
}

const setServiceDetail = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as ASetSelectedServiceDetail;

    state.selectedServiceVersion = {...action.payload.serviceConfig};
    if (action.payload.isEditable === true) {
        state = buildEditData(state, state.selectedServiceVersion);
    }
    if (action.payload.serviceConfigProd !== undefined) {
        state.selectedServiceVersionProd = action.payload.serviceConfigProd;
    }
    state.serviceState = EProjectState.ready;
    if (!state.serviceReloadInProgress) {
        state.isDataLoading = false;
    }

    state.editSvcFieldValidate = serviceValidations.validateAllFields({state, fieldValid: state.editSvcFieldValidate});

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const refresh = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    state.serviceState = EProjectState.reloadData;
    state.unsafePromoteNeedConfirmation = false
    state.unsafePromoteEnabled = false;
    state.unsafeCertNameMismatch = false;


    return state;
}

const togglePopup = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as AToggleServicePopup;

    state.popupOpen = action.payload.popupOpen;
    state.popupType = action.payload.popupType;
    switch (action.payload.popupType) {
        case ZFeaturePopupTypes.CreateService: {
            const platforms = state.platforms;
            state = initNewServiceInfo(state);
            state.platforms = platforms;
            }
            break;

        default:
            break;
    }

    return state;
}

const setServiceOverviewDetails = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetServiceOverviewDetails;

    state.serviceName = action.payload.serviceName;
    state.domainName = action.payload.domainName;

    const { certListIdx, enableHTTP2, enableHTTPSRedirect, domainsRedirect } = action.payload;
    if (certListIdx !== undefined && enableHTTP2 !== undefined && enableHTTPSRedirect !== undefined && domainsRedirect !== undefined) {
        state.certNameListIdx = certListIdx;
        state.enableHTTP2 = enableHTTP2;
        state.enableHTTPSRedirect = enableHTTPSRedirect;
        state.domainRedirects = domainsRedirect;
    }

    state.editSvcFieldValidate = serviceValidations.validateAllFields({state, fieldValid: state.editSvcFieldValidate})

    state.isDirty = true;

    return state;
}

const setServiceOriginDetails = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetServiceOriginDetails;

    state.originName = action.payload.originName;
    state.originDomainName = action.payload.originDomainName;
    state.tlsEnabled = action.payload.tlsEnabled;
    state.sni = (state.tlsEnabled) ? action.payload.sni : '';

    if (state.ddDomainName.length === 0 && state.cdnTag.length === 0) {
        state.zyTrafficPercentage = '100';
    }

    state.editSvcFieldValidate = serviceValidations.validateAllFields({state, fieldValid: state.editSvcFieldValidate})
    state.isDirty = true;

    return state;
}

const setServiceDnsDirectorDetails = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetServiceDnsDirectorDetails;

    state.ddDomainName = action.payload.ddDomainName;
    state.cdnTag = action.payload.cdnTag;
    state.autoSwitch = action.payload.autoSwitchEnabled;
    state.useCdnAsOrigin = state.autoSwitch ? action.payload.useCdnAsOriginEnabled : false;
    let zyTrafficPercentage = action.payload.zyTrafficPercentage;

    const ddPercent = parseInt(zyTrafficPercentage);
    if (isNaN(ddPercent)) {
        zyTrafficPercentage = '0';
    } else if (ddPercent < 0) {
        zyTrafficPercentage = '0';
    } else if (ddPercent > 100) {
        zyTrafficPercentage = '100';
    }
    
    state.isDirty = true;
    state.zyTrafficPercentage = zyTrafficPercentage;
    state.isCDNReserve = state.ddAutoSwitchEnabled && state.autoSwitch;
    state.editSvcFieldValidate = serviceValidations.validateAllFields({state, fieldValid: state.editSvcFieldValidate})

    return state;
}

const setCertList = (initialState: State, actions: actionTypes): State => {
    // logger.log('serviceState.setCertList: start');
    const state = {...initialState};
    const action = actions as ASetProjectCertList;

    const certList = action.payload.certList;
    state.certList.length = 0;

    certList.forEach((cert: Certificate) => { 
        cert.expires_at = dateFixup(cert.expires_at);
        state.certList.push(cert);
    });
    
    state.certNameListIdx = 0;
    state.certsLoaded = true;
    // logger.log('serviceState.setCertList: end')

    return state;
}

const buildDeploymentViewList = (state: State, history: DeploymentHistoryItem[]): State => {
    state.deployedItems.length = 0;

    const viewIndex = state.historyViewIdx as HistoryViewIdxEnum;

    for (let i = 0, len = history.length; i < len; i++) {
        const histItem = history[i];
        
        const env = histItem.env;
        let showVersion = false;
        if (viewIndex === HistoryViewIdxEnum.both) {
            showVersion = true;
        } else if (viewIndex === HistoryViewIdxEnum.prod && env === ZEnvironments.prod) {
            showVersion = true;
        } else if (viewIndex === HistoryViewIdxEnum.staging && env === ZEnvironments.staging) {
            showVersion = true;
        }

        if (showVersion) {
            for (let j = 0, jlen = histItem.details.length; j < jlen; j++) {
                const item = histItem.details[j];
                const record: DeployedItem = {
                    prev_version: item.prev_version,
                    version: item.version,
                    created_at: item.created_at, 
                    updated_at: (item.updatedAt !== undefined) ? item.updatedAt : moment(),
                    status: item.status,
                    env 
                };
                state.deployedItems.push(record); 
            }
        }
    }

    // still need to sort because we may have items from 2 lists
    state.deployedItems.sort((a, b) => {
        return a.updated_at.isBefore(b.updated_at) ? 1 : -1;
    })
    return state;
}

const buildVersionList = (state: State, dhistory: DeploymentHistoryItem[], isDestinationProd: boolean): State => {
    const versionList: VersionListEntry[] = state.availableDeploymentVersionList;

    // deploymentLookBack - how far back we will look to pick up old versions to include in the version list
    const publishPromoteLookbackValue = getPublishPromoteValue(); // obtained from zapicfg.json
    const deploymentLookBack = moment().subtract( publishPromoteLookbackValue );

    state.availableDeploymentVersionList.length = 0;

    const currentStagingSvcConfig = state.selectedServiceVersion;

    if ( !isDestinationProd && (currentStagingSvcConfig.status === ServiceStatusEnum.draft)) {
        versionList.push({
                versionNumber: currentStagingSvcConfig.version,
                versionName: STRs.draftVersion,            // name used in the drop down
            });
    }

    const deploymentEnv = (state.destinationIdx === DestinationIdxEnum.staging) ? ZEnvironments.staging : ZEnvironments.prod;
    const currentDeployedVersion = state.destinationStatusMap[deploymentEnv];
    let liveVersion: number | undefined = undefined;
    if (currentDeployedVersion) {
        liveVersion = currentDeployedVersion.version;
    }

    // use the version map so we don't get duplicates
    const versionMap = new Map();
    
    dhistory.forEach( (historyItem: DeploymentHistoryItem) => {
        if (historyItem.env === ZEnvironments.staging) {
            const details = historyItem.details;
            for (let i = 0, len = details.length; i < len; i++) {
                const deployment = details[i];

                // get rid of duplicates in the list
                if (versionMap.has(deployment.version)) {
                    continue;
                }
                versionMap.set(deployment.version, deployment.version);
                
                if (deployment.version === liveVersion) {
                    continue;
                }

                if (deployment.status === APISvrJobStatusEnum.in_progress) {
                    continue;
                }

                if (deployment.updatedAt && deployment.updatedAt.isAfter(deploymentLookBack)) {
                    if (!isDestinationProd || 
                        (isDestinationProd && deployment.status === APISvrJobStatusEnum.success)) {
                        versionList.push({
                                versionNumber: deployment.version,
                                versionName: STRs.versionStr(deployment.version),
                            });
                    }
                } else {
                    continue;
                }
            }

            versionList.sort((a, b) => {
                return a.versionNumber > b.versionNumber ? -1 : 1;
            })
        }
    })

    // nothing to deploy. put a dummy entry
    if (versionList.length === 0) {
        versionList.push({
            versionNumber: -1,
            versionName: MISC_STRINGS.hyphens,
        });
    }

    return state;
}

const setDeploymentHistory = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as ASetDeploymentHistory;

    let history: DeploymentHistoryItem[] = action.payload.deploymentHistory;
    history = history === null ? [] : history
    state.deploymentHistory.length = 0;

    state.serviceState = EProjectState.ready;
    state.lastUpdate = moment();
    state.destinationStatusMap = {};
    state.currentDeploymentInProgressStatus = {};

    history.forEach(item => {
        // save things to make it easier to tell state. For example, is an environment in
        // the middle of a deployment
        state.deploymentHistory.push(item);
        const liveVersion = item.live_version;
        const isInProgress = item.deployment_in_progress;
        state.currentDeploymentInProgressStatus[item.env] = isInProgress;

        // iterate through all the details taking the date and turning it into 
        // a moment object. 
        const details = item.details;
        for (let i = 0, len = details.length; i < len; i++) {
            const record = details[i];
            
            record.updatedAt = moment(record.updated_at)
            details[i] = record;
        }

        // sort the detail objects from newest to oldest
        details.sort((a, b) => {
            if (a.updatedAt !== undefined && b.updatedAt !== undefined) {
                return a.updatedAt.isBefore(b.updatedAt) ? 1 : -1;
            } else {
                return 0;
            }
        })

        // to make it easy to find the deployment record that matches the live version,
        // make a map where the env (staging/prod) is the key and points to the live version.
        for (let i = 0, len = details.length; i < len; i++) {
            const record = details[i];
            if (state.destinationStatusMap[item.env] === undefined) {
                if (isInProgress && record.status === APISvrJobStatusEnum.in_progress) {
                    state.destinationStatusMap[item.env] = record;
                } else if (record.version === liveVersion && 
                    (state.destinationStatusMap[item.env] === undefined)) {
                    state.destinationStatusMap[item.env] = record;
                }
            }
        }
    })

    state = buildDeploymentViewList(state, history)

    const viewIndex = state.destinationIdx as DestinationIdxEnum;
    state = buildVersionList(state, history, (viewIndex === DestinationIdxEnum.prod));
    
    return state;
}

const setDeploymentHistoryViewIndex = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as ASetDeploymentHistoryViewIndex;

    state.historyViewIdx = action.payload.index;
    state = buildDeploymentViewList(state, state.deploymentHistory)
    return state;
}

const setDeploymentDestinationIndex = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as ASetDeploymentDestinationIndex;

    state.destinationIdx = action.payload.index;
    const viewIndex = state.destinationIdx as DestinationIdxEnum;
    const history = state.deploymentHistory;
    state = buildVersionList(state, history, (viewIndex === DestinationIdxEnum.prod));
 
    return state;
}

const setDeploymentVersionIndex = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetDeploymentVersionIndex;

    state.versionIdx = action.payload.index;

    return state;
}

const updateRoutingRulesState = (state: State, editSvcNavIdx: number): State => {
    if (editSvcNavIdx === state.svcNavList.length - 1) {    // this is for dynamic stuff. It is always last.
        state.routingRuleDisplay = servicesTypeAssetRule.dynamic;

        // fake out the UI by making the top level features section look like a routing rule;
        const editableService = state.editableService;
        const features = editableService.features ? editableService.features : {} as ZServiceFeatures;
        const dFeatures: RoutingFeatures = {
            cache: features.cache as CacheFeature,
            waf: features.waf as FeatureDef,
            xff: features.xff as XffFeature,
            image_mux: { enabled: false },
        }

        const routingRule: RoutingRules = {
            asset_type: 'dynamic',
            name: 'dynamic',
            features: dFeatures,
            match_filter: {} as ZMatchFilter,
        }
        state.routingRule = routingRule;

    } else if (editSvcNavIdx >= EditSvcFixedOptions) {        // 3 indicates where the routing rules start
                                                              // index 0 is overview, index 1 is origins; index 2 is DNS Director
        state.routingRule  = {} as RoutingRules;
        state.routingRuleDisplay = {} as DisplayRoutingRules;

        const routingRule = state.editableService.rules[editSvcNavIdx - EditSvcFixedOptions];
        let type = ''
        if (routingRule) {
            type = routingRule.asset_type;
            const dispInfo = servicesTypeAssetRule[type];
            if (dispInfo) {
                state.routingRule  = routingRule;
                state.routingRuleDisplay = dispInfo;
            } else {
                logger.log(`setServiceOptionListIndex: no associated display info for ${type}`);
            }
        } else {
            logger.alert(`setServiceOptionListIndex: no associated routing rule for nav index: ${editSvcNavIdx}`);
        }
    }

    return state;
}

const setServiceOptionListIndex = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as ASetServiceOptionIndex;

    const index = action.payload.index;
    state.svcNavListIdx = index;
    state = updateRoutingRulesState( state, index);

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const discardServiceChanges = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};

    const save = state.svcNavListIdx
    state = buildEditData(state, state.selectedServiceVersion);
    state.svcNavListIdx = save;
    state = updateRoutingRulesState( state, save);
    state.editSvcFieldValidate = serviceValidations.validateAllFields({state, fieldValid: state.editSvcFieldValidate});

    return state;
}

const setRoutingRule =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetRoutingRule;
    state.isDirty = true;
    
    const rule = action.payload.rule;
    if (state.svcNavListIdx === state.svcNavList.length - 1) {
        const features: ZServiceFeatures = {} as ZServiceFeatures;
        const dyFeatures = rule.features;
        const dyNames = Object.keys(dyFeatures);
        for (let i = 0, len = dyNames.length; i < len; i++) {
            const name = dyNames[i];
            features[name] = dyFeatures[name];
        }

        state.editableService.features = features; 
    } else {
        const idx = state.svcNavListIdx - EditSvcFixedOptions;
        state.editableService.rules[idx] = rule;
    }

    state.routingRule = rule;
    state.editSvcFieldValidate = serviceValidations.validateAllFields({state, fieldValid: state.editSvcFieldValidate});

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const draftSaved = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ADraftSaved;

    state.isDirty = false;
    state.isDataLoading = true;
    state.serviceReloadInProgress = true;
    state.serviceState = EProjectState.serviceChanged;
    state.editableService = cloneDeep(action.payload.svc)

    return state;
}

const initEditService = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as AInitEditService;

    state.serviceState = EProjectState.initStarted;
    state.sessionStorage = {...action.payload.sessionStorage};
    return state;
}

const setServiceCreationTemplate = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetServiceCreationTemplate;

    state.svcCreationTemplate = action.payload.templateName;
    return state;
}

const setProjectFeaturesAttributes = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetProjectFeaturesAttributes;

    const products: ZProductFeatureAttributes = action.payload.svcFeatureAttributes;
    const dnsDir = products.dns_director;
    const foundDD = dnsDir !== undefined && dnsDir.enabled;

    state.ddAutoSwitchEnabled = false;

    state.projHasDnsDir = foundDD;
    if (foundDD) {
        const ddProduct: DNSDirectorAttributes = products['dns_director'] as DNSDirectorAttributes;
        // const ddir = ddProduct['dns_director'] as DNSDirectorAttributes;
        state.ddAutoSwitchEnabled = ddProduct.attributes && ddProduct.attributes.allow_auto_switch ? 
                                    ddProduct.attributes.allow_auto_switch : false;
    }
    const csm = products.cloud_service_monitor;
    state.projHasCSM = (csm !== undefined && csm.enabled);
    if (state.projHasCSM) {
        if ((products.cloud_service_monitor as CSMAttributes).attributes.check_interval) {
            state.projCsmHCIntervals = {... (products.cloud_service_monitor as CSMAttributes).attributes.check_interval};
        } else {
            state.projCsmHCIntervals = {...BackupHCValues}
        }
    }

    return state;
}

const setServiceHCData = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetServiceHCData;

    const healthCheckPaths: ZCSMEntity[] = action.payload.healthChecks;
    state.csmHealthChecks[HealthCheckIndex.cdnCheck] = healthCheckPaths[HealthCheckIndex.cdnCheck];
    state.csmHealthChecks[HealthCheckIndex.originCheck] = healthCheckPaths[HealthCheckIndex.originCheck];

    state.editSvcFieldValidate = serviceValidations.validateAllFields({state, fieldValid: state.editSvcFieldValidate});
    state.isDirty = true;
    return state;
}

const showDeploymentErrors = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as AShowErrors;

    if (action.payload.feature === 'edit-service') {
        state.showDeploymentErrors = action.payload.showErrors;
    }
    return state;
}

const setProjectPlatforms = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetProjectPlatforms;

    const serverPlatforms = action.payload.platforms;
    state.serverPlatforms = serverPlatforms;

    // force the entry 'other' (if it exists) to the end of the platform list.
    let platforms = Object.keys(serverPlatforms);
    const otherIdx = platforms.findIndex((pform: string) => {return pform === 'other'});
    if (otherIdx !== -1) {
        platforms.splice(otherIdx, 1);
    }
    platforms = platforms.sort((a: string, b: string) => {return a > b ? 1 : 0});
    if (otherIdx !== -1) {
        platforms.push('other')
    }
    state.platforms = platforms;
    return state;
}

const setPlatformIdx = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetPlatformIdx;

    state.platformIdx = action.payload.platformIdx;
    return state;
}

const setUnsafePromoteState = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetUnsafePromoteState;

    const { unsafePromoteCheckState, unsafePromoteNeedConfirmation, unsafeCertNameMismatch } = action.payload;
    state.unsafePromoteEnabled = unsafePromoteCheckState;
    state.unsafePromoteNeedConfirmation = unsafePromoteNeedConfirmation;
    state.unsafeCertNameMismatch = unsafeCertNameMismatch;

    return state;
}

type ActionFunction = ((state: State, action: actionTypes) => State);
type AdminActions = ActionKeys.CHANGE_PROJECTS | ActionKeys.SET_CURRENT_SERVICE_EVT_IDX |  // ActionKeys.RESET_PROJECT |
                    ActionKeys.SET_SVC_CONFIG_IDX | ActionKeys.SET_SERVICE_VERSION_LIST | ActionKeys.INIT_EDIT_SERVICE |
                    ActionKeys.CHANGE_ENVIRONMENT | ActionKeys.SET_SELECTED_SERVICE_DETAIL | ActionKeys.DATA_GROUP_LOAD |
                    ActionKeys.APPLY_SETTINGS | ActionKeys.TOGGLE_SERVICE_POPUP | ActionKeys.REFRESH_PAGE |
                    ActionKeys.SET_SERVICE_OVERVIEW_DETAILS | ActionKeys.SET_PROJECT_CERT_LIST | ActionKeys.SHOW_ERRORS |
                    ActionKeys.SET_SERVICE_ORIGIN_DETAILS | ActionKeys.SET_DEPLOYMENT_HISTORY | ActionKeys.SET_SERVICE_HC_DATA |
                    ActionKeys.SET_DEPLOYMENT_HISTORY_VIEW_IDX | ActionKeys.SET_DEPLOYMENT_VERSION_IDX | ActionKeys.SET_PLATFORM_IDX |
                    ActionKeys.SET_DEPLOYMENT_DESTINATION_IDX | ActionKeys.SET_SERVICE_OPTION_IDX | ActionKeys.SET_UNSAFE_PROMOTE_STATE |
                    ActionKeys.DISCARD_SERVICE_CHANGES | ActionKeys.SET_ROUTING_RULE | ActionKeys.DRAFT_SAVED |
                    ActionKeys.SET_PROJECT_SERVICES | ActionKeys.DATA_LOAD_IN_PROGRESS | ActionKeys.SET_SERVICE_DNS_DIRECTOR_DETAILS |
                    ActionKeys.SET_SERVICE_CREATION_TEMPLATE | ActionKeys.SET_PROJECT_FEATURES_ATTRIBUTES | ActionKeys.SET_PROJECT_PLATFORMS;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in AdminActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.CHANGE_PROJECTS]: changeProject,
    [ActionKeys.SET_PROJECT_SERVICES]: setProjectServices,
    [ActionKeys.SET_CURRENT_SERVICE_EVT_IDX]: serviceChanged,
    [ActionKeys.CHANGE_ENVIRONMENT]: environmentChanged,
    
    [ActionKeys.SET_SVC_CONFIG_IDX]: setVersionIndex,
    [ActionKeys.SET_SERVICE_VERSION_LIST]: setServiceVersionList,
    [ActionKeys.SET_SELECTED_SERVICE_DETAIL]: setServiceDetail,
    
    [ActionKeys.APPLY_SETTINGS]: applySettings,
    [ActionKeys.TOGGLE_SERVICE_POPUP]: togglePopup,
    [ActionKeys.REFRESH_PAGE]: refresh,
    
    // [ActionKeys.RESET_PROJECT]: resetProject,
    [ActionKeys.SET_SERVICE_OVERVIEW_DETAILS]: setServiceOverviewDetails,
    [ActionKeys.SET_PROJECT_CERT_LIST]: setCertList,
    [ActionKeys.SET_SERVICE_ORIGIN_DETAILS]: setServiceOriginDetails,
    [ActionKeys.SET_SERVICE_DNS_DIRECTOR_DETAILS]: setServiceDnsDirectorDetails,
    [ActionKeys.SET_ROUTING_RULE]: setRoutingRule,

    [ActionKeys.SET_DEPLOYMENT_HISTORY]: setDeploymentHistory,
    [ActionKeys.SET_DEPLOYMENT_HISTORY_VIEW_IDX]: setDeploymentHistoryViewIndex,
    [ActionKeys.SET_DEPLOYMENT_DESTINATION_IDX]: setDeploymentDestinationIndex,
    [ActionKeys.SET_DEPLOYMENT_VERSION_IDX]: setDeploymentVersionIndex,
    [ActionKeys.SET_UNSAFE_PROMOTE_STATE]: setUnsafePromoteState,
    [ActionKeys.SET_SERVICE_OPTION_IDX]: setServiceOptionListIndex,
    [ActionKeys.DISCARD_SERVICE_CHANGES]: discardServiceChanges,
    [ActionKeys.DRAFT_SAVED]: draftSaved,
    [ActionKeys.INIT_EDIT_SERVICE]: initEditService,
    [ActionKeys.DATA_LOAD_IN_PROGRESS]: dataLoadInProgress,
    [ActionKeys.DATA_GROUP_LOAD]: dataGroupLoad,
    [ActionKeys.SET_SERVICE_CREATION_TEMPLATE]: setServiceCreationTemplate,
    [ActionKeys.SET_PROJECT_PLATFORMS]: setProjectPlatforms,
    [ActionKeys.SET_PLATFORM_IDX]: setPlatformIdx,
    [ActionKeys.SET_PROJECT_FEATURES_ATTRIBUTES]: setProjectFeaturesAttributes,
    [ActionKeys.SET_SERVICE_HC_DATA]: setServiceHCData,
    [ActionKeys.SHOW_ERRORS]: showDeploymentErrors,
}

function serviceUIState(initialState: State, action: actionTypes ): State {
    let state: State = initialState;

    if ( initialState === undefined ) {
        state = Object.assign({}, serviceInitData);
    }

    const fct: ActionFunction | undefined = actionMap[action.type];
    if (fct) {
        // logger.log(`
    // =====>serviceState dispatcher: actionKey: ${action.type}`);
        state = (fct)(state, action);
 
        // logger.log(`
    // ====>>>serviceState dispatcher:  after update of serviceState\n`);
    } 

    return  state;
}

export default serviceUIState