import { actionTypes, ActionKeys, ACacheCmdCompleted, ASetCacheRowState, 
         ASetCacheSetIntervalId, AChangeEnvironment, ASetProjectServices, ACacheInitOptions,
       }  from '../actions/actionCreatorTypes';
import moment from 'moment';

import { ACacheInitModal } from '../actions/actionCreatorTypes';
import { CachePurgeReqStatusExt, ServiceIdMapping, ProjUriCode, CacheFeature } from '../data/queryResultDefinitions';
import { BooleanMap, SvcIdToSvcMap, StringMap } from '../data/metricsAndOptionsDefs';

import { EProjectState, ZEnvironments } from '../reducers/reducerEnums'
import { toTitleCase } from '../shared/utilities';
import { CACHE_STRINGS as Strs } from '../shared/strings';
// import logger from '../shared/logUtilities';

export interface CachePurgeReqStatus extends CachePurgeReqStatusExt {
    open: boolean;
}

export enum PurgeType {
    uriCode = 'uriCode',
    urlList = 'urlList',
    cacheTags = 'cacheTags',
}

export enum TableOpenState {
    closed = 'closed',
    open = 'open',
    mixed = 'mixed'
}
export interface ServiceIdName {
    [serviceId: string]: string;
}

export interface ServiceNameId {
    [serviceName: string]: string;

}
export interface State {
    cacheCmdSuccess: boolean;
    cacheCmdMsg: string;
    cacheState: EProjectState;
    environment: ZEnvironments;                 // 'prod' or 'staging'. starting env comes selected UI value. 
    isLoading: boolean;                         // update in progress (or not)
    intervalId: number;                         // id used for handling management of setIntervals
    updateTimestamp: moment.Moment;

    // follow are used for the list on the main cache purge page
    reqStatusData: CachePurgeReqStatus[];           // data for the currently selected environment
    reqStatusDataProd: CachePurgeReqStatus[];       // prod only requests
    reqStatusDataStaging: CachePurgeReqStatus[];    // staging only request
    
    // following are used for the modal purge request dialog
    modalEnv: string;                           // value of radio button in modal purge request dialog
    modalServiceIds: string[];                  // list of service id in current env
    purgeType: PurgeType;                       // uri_code, uris or cache tags
    services: string[];                         // array of UI version of services names & domains
    serviceListIdx: number;                     // currently selected service
    svcIdToSvc: SvcIdToSvcMap;                  // map service Id to full service
    servicesProd: ServiceIdMapping;             // deployed prod services
    servicesStaging: ServiceIdMapping;          // deployed staging services
    svcIdsToCacheTagHeader: StringMap;           // cache tag header name for service id
    serviceIdName: ServiceIdName;               // map service ids to names
    showModal: boolean;

    projUriCodes: ProjUriCode[];
    uriCodeListIdx: number;                     // currently selected code
    uriCodes: string[];                         // list of uri codes for selected service
    reqTblOpenState: TableOpenState;
    currentServiceId: string;
}

const cacheInitData: State = {
    cacheCmdSuccess: true,
    cacheCmdMsg: '',
    cacheState: EProjectState.ready,
    environment: ZEnvironments.prod,
    isLoading: false,
    intervalId: -1,
    updateTimestamp: moment(),
    
    reqStatusData: [] as CachePurgeReqStatus[],
    reqStatusDataProd: [] as CachePurgeReqStatus[],
    reqStatusDataStaging: [] as CachePurgeReqStatus[],
    svcIdToSvc: {} as SvcIdToSvcMap,
    
    modalEnv: ZEnvironments.prod,
    modalServiceIds: [],
    purgeType: PurgeType.uriCode,
    services: [],
    serviceListIdx: -1,
    servicesProd: {},
    servicesStaging: {},
    serviceIdName:  {},
    svcIdsToCacheTagHeader: {},
    showModal: false,

    projUriCodes: [],
    uriCodeListIdx: -1,
    uriCodes: [],
    reqTblOpenState: TableOpenState.closed,
    currentServiceId: ''
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function initCacheData(state: State, actions: actionTypes): State {
    state.cacheCmdSuccess =  true;
    state.cacheCmdMsg =  '';
    state.cacheState =  EProjectState.ready;
    state.environment =  ZEnvironments.prod;
    state.isLoading =  false;
    state.intervalId =  -1;
    state.updateTimestamp =  moment();
    
    state.reqStatusData.length = 0;
    state.reqStatusDataProd.length = 0;
    state.reqStatusDataStaging.length = 0;
    
    // hasUriCodes =  false;
    state.modalEnv =  ZEnvironments.prod;
    state.modalServiceIds.length = 0;
    state.purgeType =  PurgeType.uriCode;
    state.services.length = 0;
    state.servicesProd =  {};
    state.servicesStaging =  {};
    state.serviceIdName =   {};
    state.showModal =  false;

    state.uriCodeListIdx =  -1;
    state.uriCodes.length = 0;
    state.projUriCodes.length = 0;
    state.reqTblOpenState = TableOpenState.closed;
    state.svcIdToSvc = {} as SvcIdToSvcMap;
    state.currentServiceId = '';

    return state;
}

function initCacheDataForProject(initialState: State, actions: actionTypes): State {
    const state: State = initCacheData(initialState, actions);

    return state;
}

function selectDataForEnvironment(state: State): State {
    const env = state.environment;
    const tcEnv = toTitleCase(env)
    const reqData = 'reqStatusData' + tcEnv;

    state.reqStatusData.length = 0;
    const copyAll =  (state.currentServiceId === undefined || state.currentServiceId.length === 0);
    const serviceId = state.currentServiceId;

    state[reqData].forEach((pReq: CachePurgeReqStatus) => {
        if (copyAll || pReq.service_id ===  serviceId) {
            state.reqStatusData.push(pReq)
        }
    })

    return state;
}

const setProjectServices = (initialState: State, actions: actionTypes): State => {
    const state: State = { ...initialState };
    const action = actions as ASetProjectServices;

    const savedEnv = action.payload.env !== undefined ? action.payload.env : state.environment;
    action.payload.serviceList.forEach(sd => {
        if (sd.is_live) {
            const env = ((sd.env as ZEnvironments) === ZEnvironments.prod) ? 'Prod' : 'Staging';
            
            state['services' + env][sd.service_id] = sd.zycadized_domain_name;
            state.svcIdToSvc[sd.service_id] = sd;
            state.serviceIdName[sd.service_id] = sd.service_name;
            if (sd.features && sd.features.cache) {
                const feature = sd.features.cache as CacheFeature
                if (feature.purge && feature.purge.cache_tag_header_name) {
                    state.svcIdsToCacheTagHeader[sd.service_id] = feature.purge.cache_tag_header_name;
                }
            }
        }
    });

    state.environment = savedEnv;
    selectDataForEnvironment(state);

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function initCacheOptions(initialState: State, actions: actionTypes): State {
    const state: State = { ...initialState };
    const action = actions as ACacheInitOptions;

    state.cacheState = EProjectState.initComplete;

    state.environment = action.payload.environment as ZEnvironments;
    state.currentServiceId = action.payload.serviceId;

    return selectDataForEnvironment(state);
}

function cacheCmdStarted(initialState: State): State {
    const state: State = {...initialState};
    state.cacheState = EProjectState.userClickedApply;
    state.isLoading = true;
    return state;
}

function cacheCmdCompleted(initialState: State, actions: actionTypes): State {
    const action = actions as ACacheCmdCompleted;
    const state: State = {...initialState};
    state.isLoading = false;
    state.cacheState = EProjectState.ready;
    state.updateTimestamp = moment();
    state.cacheCmdSuccess = action.payload.success;
    state.cacheCmdMsg = action.payload.msg;
    let reqStatusData: CachePurgeReqStatus[] = [];

    // go through the request.  Add the open flag,  Sort by data and then divide them
    // into staging and prod arrays

    const openMap: BooleanMap = {} as BooleanMap;
    state.reqStatusDataProd.forEach((req => {
        openMap[req.job_id] = req.open;
    }))
    state.reqStatusDataStaging.forEach((req => {
        openMap[req.job_id] = req.open;
    }))

    if (state.cacheCmdSuccess) {
        const data = action.payload.data;
        if (data && Array.isArray(data)) {
            reqStatusData = data.map((req): CachePurgeReqStatus => {
                const uiReq = req as CachePurgeReqStatus;
                uiReq.open = openMap[req.job_id] !== undefined ? openMap[req.job_id] : false;
                return uiReq;
            });
            
            reqStatusData.sort((a: CachePurgeReqStatus, b: CachePurgeReqStatus): number => {
                let rc = 0;
                if ( a.created_at > b.created_at) { rc = -1; }
                if ( a.created_at < b.created_at) { rc = 1; }
                return rc;
            })

            state.reqStatusDataProd.length = 0;
            state.reqStatusDataStaging.length = 0;
            
            // divide requests between staging and prod
            reqStatusData.forEach( req => {
                if (state.servicesProd[req.service_id]) {
                    state.reqStatusDataProd.push(req);
                } else if (state.servicesStaging[req.service_id]) {
                    state.reqStatusDataStaging.push(req);
                }
            })
        }
    }
    return selectDataForEnvironment(state);

}

function changeEnvironment(initialState: State, actions: actionTypes): State {
    const action = actions as AChangeEnvironment;
    const state: State = {...initialState};
    state.environment = action.payload.environment as ZEnvironments;

    return selectDataForEnvironment(state);
}

function setCacheRowState(initialState: State, actions: actionTypes): State {
    const action = actions as ASetCacheRowState;
    const state: State = {...initialState};
    const reqStatusData = state.reqStatusData;
    
    const rowNum = action.payload.rowNum;
    let tableOpenState = state.reqTblOpenState;

    if (rowNum === -1) {
        if (tableOpenState === TableOpenState.closed || tableOpenState === TableOpenState.mixed) {
            tableOpenState = TableOpenState.open;
        } else {
            tableOpenState = TableOpenState.closed;
        }
        const openState = tableOpenState === TableOpenState.open
        reqStatusData.forEach((item: CachePurgeReqStatus) => {
            item.open = openState;
        })
        
    } else {
        if (reqStatusData && reqStatusData.length > rowNum) {
            reqStatusData[rowNum].open = !(reqStatusData[rowNum].open);
            tableOpenState = getTableOpenState(reqStatusData);
        }
    }

    state.reqTblOpenState = tableOpenState;
    
    return state;
}

const getTableOpenState = (statusData: CachePurgeReqStatus[]): TableOpenState => {
    let openState = true;
    let tableOpenState = TableOpenState.closed;

    if (statusData.length > 0) {
        openState = statusData[0].open;

        for (let i=0, len = statusData.length; i < len; i++) {
            if (openState !== statusData[i].open) {
                tableOpenState = TableOpenState.mixed;
            }
        }
        if (tableOpenState !== TableOpenState.mixed) {
            tableOpenState = openState ? TableOpenState.open : TableOpenState.closed;
        }
    }

    return tableOpenState;
}

const initModalEnv = (state: State, uriCodes: ProjUriCode[]): State => {
    state.modalEnv = state.environment;
    const serviceMapName = 'services' + toTitleCase(state.modalEnv);
    state.services.length = 0;
    state.services.push( Strs.chooseService );
    
    const keys = Object.keys(state[serviceMapName]);
    state.modalServiceIds.length = 0;
    keys.forEach((svcId: string) => {
        state.modalServiceIds.push(svcId);
        state.services.push(`${state.serviceIdName[svcId]} [${(state[serviceMapName])[svcId]}]`);
    });

    state.uriCodes.length = 0;
    const codes: string[] = state.uriCodes;
    codes.push(Strs.chooseURICode);
    uriCodes.forEach((code: ProjUriCode) => { codes.push(`[${code.name}] ${code.uri_regex}`)} )
    state.projUriCodes = uriCodes;
    state.uriCodeListIdx = 0;

    state.purgeType = PurgeType.urlList

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cacheInitModal(initialState: State, actions: actionTypes): State {
    let state: State = {...initialState};
    const action = actions as ACacheInitModal;
    state.showModal = true;
 
    state = initModalEnv(state, action.payload.uriCodes );

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cacheSubmitPurgeRequest(initialState: State, actions: actionTypes): State {
    // let action = actions as ASubmitCachePurgeRequest;
    const state: State = {...initialState};
    state.showModal = false;
    state.cacheState = EProjectState.reloadData;
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cacheCloseModal(initialState: State, actions: actionTypes): State {
    // let action = actions as ACacheCloseModal;
    const state: State = {...initialState};
    state.showModal = false;

    return state;
}

function cacheSetIntervalId(initialState: State, actions: actionTypes): State {
    const action = actions as ASetCacheSetIntervalId;
    const state: State = {...initialState};
    state.intervalId = action.payload.intervalId;
    state.cacheState = EProjectState.reloadData;
    return state;

}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function refreshCacheStatus(initialState: State, actions: actionTypes): State {
    const state: State = {...initialState};
    state.cacheState = EProjectState.reloadData
    return state;
}

type ActionFunction = ((state: State, action: actionTypes) => State);
type CacheActions = ActionKeys.SET_PROJECTS | ActionKeys.CHANGE_PROJECTS | ActionKeys.CACHE_INIT_OPTIONS |
                    ActionKeys.CACHE_CMD_STARTED | ActionKeys.CACHE_CMD_COMPLETED | ActionKeys.CACHE_SET_ROW_STATE |
                    ActionKeys.CACHE_INIT_MODAL | ActionKeys.CACHE_SET_INTERVAL_ID |
                    ActionKeys.CACHE_SUBMIT_PURGE_REQUEST | ActionKeys.CACHE_CLOSE_MODAL |  ActionKeys.SET_PROJECT_SERVICES |
                    ActionKeys.CACHE_REFRESH_CACHE_STATUS | ActionKeys.CHANGE_ENVIRONMENT;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in CacheActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.SET_PROJECTS]: initCacheData,
    [ActionKeys.CHANGE_PROJECTS]: initCacheDataForProject,
    [ActionKeys.CHANGE_ENVIRONMENT]: changeEnvironment,
    [ActionKeys.CACHE_INIT_OPTIONS]: initCacheOptions,
    [ActionKeys.CACHE_CMD_STARTED]: cacheCmdStarted,
    [ActionKeys.CACHE_SET_ROW_STATE]: setCacheRowState,
    [ActionKeys.CACHE_CMD_COMPLETED]: cacheCmdCompleted,
    [ActionKeys.CACHE_INIT_MODAL]: cacheInitModal,
    [ActionKeys.CACHE_SUBMIT_PURGE_REQUEST]: cacheSubmitPurgeRequest,
    [ActionKeys.CACHE_CLOSE_MODAL]: cacheCloseModal,
    [ActionKeys.CACHE_SET_INTERVAL_ID]: cacheSetIntervalId,
    [ActionKeys.CACHE_REFRESH_CACHE_STATUS]: refreshCacheStatus,
    [ActionKeys.SET_PROJECT_SERVICES]: setProjectServices,
}

function cacheUIState(initialState: State, action: actionTypes ): State {
    let state: State = initialState;

    if ( initialState === undefined ) {
        state = Object.assign({}, cacheInitData);
    }

    // console.log(`cacheState: ${action.type}`)
    const fct: ActionFunction | undefined = actionMap[action.type];
    if (fct) {
        state = (fct)(state, action);
    } 

    return  state;
}

export default cacheUIState