/* eslint-disable react/prop-types */
import * as React from 'react';
import Select, { SingleValue, StylesConfig }  from 'react-select';

import { CssColor } from './constants';
import logger from './logUtilities';

export interface SearchDropdownOptions {
    disabled?: boolean;
    placeHolder?: string,
    optionName?: string; // Value passed along with index to help id dropdown.
    // maxButtonTextLength?: number;
    // bIgnoreLabelIfNotAll?: boolean;
    // label?: string;
    // pullRight?: boolean;
}

const emptyOptions: SearchDropdownOptions = {
        disabled: false,
        placeHolder: '',
        optionName: '',
//     maxButtonTextLength: 32768,
//     bIgnoreLabelIfNotAll: false,
//     label: '',
//     pullRight: false,
};

export interface Option {
    readonly value: string,
    readonly label: string
}

export interface DropdownProps {
    optionList: string[], // dropdown items
    selectedIdx: number, // selected item
    ctrlId: string, // required HTML id
    handleOptionsSelect: (index: number, optionName?: string, value?: string) => void, // select calback function
    options?: SearchDropdownOptions, // modifications for the dropdown.
    values?: string[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ZDropdownSearch: React.FunctionComponent<DropdownProps> = props => {
    const handleSelect = (selOption: SingleValue<Option> | unknown): void => {
        if (selOption) {
            const myOption: Option = selOption as Option;
            const labelList = props.optionList;
            const valueList = props.values; 

            const idx: number = labelList.findIndex((label: string, index: number) => { 
                // if values were passed in to the control, make sure that values match
                return (label === myOption.label) && (valueList ? valueList[index] === myOption.value : true);
            });
            (props.handleOptionsSelect)(idx, ddOptions.optionName, myOption.value )
        }
    }

    const getRandomLabel = (): string => {
        return (Math.random() * 1000).toString(10);
    };

    if (props.selectedIdx === undefined || props.optionList.length === 0) {
        return <span key={'x' + getRandomLabel()} />;
    }

    let ddOptions: SearchDropdownOptions;
    const { optionList, selectedIdx, options, values} = props;

    if (options) {
        ddOptions = options;
        ddOptions.disabled = !!ddOptions.disabled;
        ddOptions.placeHolder = (ddOptions.placeHolder) ? ddOptions.placeHolder : '';
        ddOptions.optionName = (ddOptions.optionName) ? ddOptions.optionName : '';
    //     const maxButtonTextLen = options.maxButtonTextLength;
    //     maxTitleLength = maxButtonTextLen && !Number.isNaN(maxButtonTextLen) ? maxButtonTextLen : maxTitleLength;
    //     ddOptions.pullRight = !!ddOptions.pullRight;
    //     ddOptions.bIgnoreLabelIfNotAll = !!ddOptions.bIgnoreLabelIfNotAll;

    } else {
        ddOptions = emptyOptions;
    }

    let maxChar = 0;
    const soptions: Option[] = [];

    // build the official list for the search edit box.
    if (values) {
        if (values.length !== optionList.length) {
            logger.alert('ZDropDownNew - optionList and values are different lengths');
            return <span>ZDropDownNew - optionList and values are different lengths</span>
        }
        for (let i=0, len=values.length; i < len; i++) {
            maxChar = values[i].length > maxChar ? values[i].length : maxChar;
            soptions.push({label: optionList[i], value: values[i]})
        }
    } else {
        for (let i=0, len=props.optionList.length; i < len; i++) {
            maxChar = optionList[i].length > maxChar ? optionList[i].length : maxChar;
            soptions.push({label: optionList[i], value: optionList[i]})
        }
    }

    const dropdownWidth = maxChar*.7;

    // this is styling to make the search drop down match our other drop downs.
    const mystyles: StylesConfig = {
        // these are for the dropdown control itself
        container: (styles) => ({ ...styles, width: `${dropdownWidth}em`}),
        valueContainer: (styles) => ({ ...styles, color: 'white', height: '33px'}),
        control: (styles, {isFocused, isDisabled}) => ( {...styles, padding: 0, 
            backgroundColor: isFocused ? CssColor.$GREY_Z3 : CssColor.$GREY_Z1,  
            color: CssColor.$PRIMARY_WHITE,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            borderColor: CssColor.$GREY_Z3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ':hover': {
                ...styles[':hover'],
                color: CssColor.$PRIMARY_WHITE,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            },
            ':active': {
                ...styles[':hover'],
                color: CssColor.$PRIMARY_WHITE,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            }
            }), 
        input: (styles ) => ( {
            ...styles,
            color: CssColor.$PRIMARY_WHITE,
            textOverflow: 'ellipsis'
        }),
        singleValue: (styles) => ({...styles, color: 'white', input: {color: CssColor.$PRIMARY_WHITE}}),
        // These are control the control's indicator icon on the right side of the box
        dropdownIndicator: (styles, { isDisabled }) => ({...styles, 
            color: CssColor.$PRIMARY_WHITE,
            ':hover': {
                ...styles[':hover'],
                color: CssColor.$GREY_Z4,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            },
            ':active': {
                ...styles[':active'],
                color: CssColor.$GREY_Z4,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            }
        }),
        indicatorsContainer: (styles, {isDisabled}) => ({ ...styles,  
            ':hover': {
                ...styles[':hover'],
                color: CssColor.$PRIMARY_WHITE,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            },
            ':active': {
                ...styles[':active'],
                color: CssColor.$PRIMARY_WHITE,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            }
        }),
        indicatorSeparator: (styles, { isDisabled }) => ({...styles, 
            ':hover': {
                ...styles[':hover'],
                color: CssColor.$GREY_Z3,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            },
            ':active': {
                ...styles[':active'],
                color: CssColor.$GREY_Z4,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z3 : undefined,
                borderColor: CssColor.$GREY_Z3,
            }
        }),
        // These are for the list itself
        menu: (styles) => ({ ...styles, backgroundColor: CssColor.$GREY_Z3, padding: '0', marginTop: '3px',
        }),
        menuList: (styles) => ({ ...styles, 
            padding: '0', margin: '0', 
            borderStyle: 'solid',
            borderRadius: '3px', 
            borderColor: CssColor.$GREY_Z3,
            borderWidth: '1px',
            width: `${dropdownWidth}em`,
        }),
        // This is the individual item in the list
        option: (styles, {isSelected, isDisabled}) => ( { ...styles, 
            backgroundColor: isSelected ? CssColor.$GREY_Z1 : CssColor.$GREY_Z3, 
            borderColor: CssColor.$GREY_Z3,
            color: CssColor.$PRIMARY_WHITE,
            fontSize: '13px',
            lineHeight: .8,
            ':hover': {
                ...styles[':hover'],
                color: CssColor.$GREY_Z4,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z1 : CssColor.$GREY_Z3,
                borderColor: CssColor.$GREY_Z3,
            },
            ':active': {
                ...styles[':active'],
                color: CssColor.$PRIMARY_WHITE,
                backgroundColor: !isDisabled ? CssColor.$GREY_Z1 : CssColor.$GREY_Z3,
                borderColor: CssColor.$GREY_Z3,
            },
        }),
    }

    return (
        <>
        <div style={{ marginBottom: '16px' }}>
            <Select
                id={props.ctrlId}
                placeholder={ddOptions.placeHolder}
                options={soptions}
                isSearchable={true}
                onChange={(value) => {handleSelect(value)}}
                defaultValue={soptions[selectedIdx]}
                isDisabled={ddOptions.disabled}
                styles={mystyles}
                // closeMenuOnSelect={false}
                // blurInputOnSelect={false}
                // menuIsOpen={true}

            />
        </div>
        </>)
}

export default ZDropdownSearch;