// Cloud Service Acceleration
/* eslint-disable react/prop-types */
import * as React from 'react';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../actions/actionCreators';
import { actionTypes } from '../actions/actionCreatorTypes';

import { State } from '../App';

import { StringMap } from '../data/metricsAndOptionsDefs';
import { FetchError, CSAHistoryItem } from '../data/queryResultDefinitions';

import { buildUrl } from '../reducers/serverEnvironAccessor';
import { EProjectState, SysFeatureEnums, ZFeaturePopupTypes } from '../reducers/reducerEnums';

import { DATETIME_FORMAT, NotificationStyles, CACHE_REFRESH_INTERVAL } from '../shared/constants';
import { CSALite_STRINGS as STRs, MISC_STRINGS, SHARED_JOB_STRINGS as JSTRs } from '../shared/strings';
import { ZGet, ZPost } from '../shared/backend';

import GenDialog from '../shared/GenDialog';
import ZButton from '../shared/ZButton';
import logger from '../shared/logUtilities';
import ZURLs from '../shared/urls';

const statusMapping = {
    "aborted": JSTRs.aborted,
    accepted: JSTRs.accepted,
    failed: JSTRs.failed,
    "in_progress": JSTRs.in_progress,
    success: JSTRs.success,
};

export interface CSALiteConfigProps extends State {
    controlModalDlg: (feature: SysFeatureEnums, setOpen: boolean, dialogName?: ZFeaturePopupTypes) => actionTypes;
    setIntervalId: (intervalId: number) => actionTypes;
    csaFileVerified: () => actionTypes;
    setCSAFile: (selectedFile: FormData) => actionTypes;
    setCSAJobList: (csaJobList: CSAHistoryItem[]) => actionTypes;
    initCSALite: () => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    applySettings: (error?: string) => actionTypes;
}

class CSALiteConfig extends React.Component<CSALiteConfigProps> {
    public componentDidMount(): void {
        this.props.initCSALite();
    }

    public componentDidUpdate(): void {
        const csaLiteState = this.props.csaLiteUIState;
        const systemState = this.props.systemNavProjectState;

        if (csaLiteState.jobListStatus === EProjectState.reloadData || csaLiteState.jobListStatus === EProjectState.initComplete) {
            const currentEnv = systemState._currentProject.currentEnv;
            const svcName = systemState.serviceEvents[systemState.serviceEvtIdx];
            const serviceId = currentEnv.serviceNameToId[svcName];
    
            if (serviceId === undefined) {
                return;
            }

            this.getJobList(serviceId);
        }
    }
    
    public componentWillUnmount(): void {
        // console.log('componentWillUnmount entered.')
        this.clearInterval();
    }

    private clearInterval = (): void => {
        const interval: number =  this.props.csaLiteUIState.intervalId;
        // console.log('clearInterval entered.');
        if (interval >= 0) {
            // console.log(`clearInterval: Clearing interval ${interval}`);
            clearInterval(interval);
            this.props.setIntervalId(-1);
        }
    }

    private setRefresh = (): void => {
        let interval: number =  this.props.csaLiteUIState.intervalId;

        // console.log(`setRefesh: intervalId is ${interval}`)
        if (interval <= 0) { 
            // console.log(`setRefresh: Setting interval`)
            interval = window.setInterval(() => {
                if (! this.props.csaLiteUIState.showModal) {
                    this.props.applySettings();
                }
            }, CACHE_REFRESH_INTERVAL);
            
            if (interval > 0) {
                // console.log(`setRefresh: new interval is ${interval}`)
                this.props.setIntervalId(interval);
            }
        }
    } 

    render(): JSX.Element {
        const csaLiteState = this.props.csaLiteUIState;
        const systemState = this.props.systemNavProjectState;
        let csaList = <span></span>;
        let copyrightCSS = 'copyright-bottom';
        let newRequestDisabled = false;

        if (csaLiteState.jobListStatus === EProjectState.dataLoaded || 
            csaLiteState.jobListStatus === EProjectState.reloadData || 
            csaLiteState.jobListStatus === EProjectState.dataLoadInProgress) {
            if (csaLiteState.csaJobList.length === 0) {
                csaList = (<div className="no-requests">{STRs.noRequests}</div>);
            } else {
                const header = this.buildCSAJobListHeader();
                const jobListDetail = this.buildJobListDetail()
                csaList = (
                    <>
                    {header}
                    <div className="csaUpdloadTbl">
                    {jobListDetail}
                    </div>
                    </>);
                copyrightCSS = 'copyright';
            } 
        } else if (csaLiteState.jobListStatus === EProjectState.initComplete && systemState._currentProject.servicesList.length === 0) {
            csaList = <div className="no-requests">{MISC_STRINGS.descNoDeployedServices(systemState._currentProject.currentServiceEnvironment)}</div>
            newRequestDisabled = true;
        } else {
            csaList = <div className="no-requests">{MISC_STRINGS.serverError}</div>
        }

        const button = (                 
            <ZButton btnCls="csaUploadBtn" onClick={this.handleNewRequestBtn} btnType="primary" disabled={newRequestDisabled}>
                {STRs.uploadNewCSAData}
            </ZButton>);
        const dialog = csaLiteState.showModal ? this.buildUploadDialog() : <div></div>;

        return (
            <div className="feature-panel">
                <div className="servicesFeature">
                    <div className="csaData">
                        <div className="title">{STRs.statusRecentCSAReq}</div>
                        <div className="refresh">
                            <ZButton onClick={() => { this.props.applySettings(); }} disabled={newRequestDisabled}>{MISC_STRINGS.refresh}</ZButton> 
                            <span className="padding">{MISC_STRINGS.lastUpdateLabel}&nbsp;
                            {csaLiteState.updateTimestamp.format(DATETIME_FORMAT)}</span>
                        </div>
                        {csaList}
                        {button}
                    </div>
                    <div className={copyrightCSS} dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                </div>
                {dialog}
            </div>
        )
    }

    private buildCSAJobListHeader = (): JSX.Element => {
        const csaState = this.props.csaLiteUIState;
        const header: JSX.Element = ((csaState.jobListStatus !== EProjectState.dataLoaded) || csaState.csaJobList.length === 0) ? 
                <span></span> : 
                (
                    <div key="header" className="csa-row tbl-header-bkg">
                        <div className="header csa-first-column-space">{JSTRs.jobID}</div>
                        <div className="header">{JSTRs.submitTime}</div>
                        <div className="header">{JSTRs.completionTime}</div>
                        <div className="header">{MISC_STRINGS.status}</div>
                    </div>
                );
        
        return header;
    }

    private formatStatusCell = (status: string): string  => {
        let cellCss = '';

        if (status !== undefined) {
            status = status.toLowerCase();
            if (status.indexOf('succ') !== -1) {
                cellCss = 'check';
            } else if (status.indexOf('fail') !== -1 || status.indexOf('abort') !== -1) {
                cellCss = 'times-circle';
            } else if (status.indexOf('prog') !== -1 || status.indexOf('accepted') !== -1) {
                cellCss = 'sync';
            }
        }
        return cellCss;
    };

    private formatDate = (opDate: moment.Moment | string): string => {
        let dateStr = '';
        if (moment.isMoment(opDate)) {
            dateStr = opDate.format(DATETIME_FORMAT);
        } else {
            dateStr = opDate as string;
        }
        return dateStr;
    };

    private buildJobListDetail = (): JSX.Element => {
        const csaState = this.props.csaLiteUIState;
        const data: CSAHistoryItem[] = csaState.csaJobList;
        // 'iconColor' - the attribute names are the names of font-awesome characters we show for the icon
        const iconColor = {
            check: 'green-check',
            'times-circle': 'red-x',
            'sync': 'white-icon'
        }

        const rowData: JSX.Element[] = data.map((row: CSAHistoryItem, i: number): JSX.Element => {
            let completionTime: string;
            const updateDate = this.formatDate( moment(row.updated_at));
            const statusStr = statusMapping[row.status]

            const status: string = row.status;
            const icon = this.formatStatusCell(status);
            const spin = (status === 'in_progress');
            const rowCSS = i % 2 ? ' odd-row' : ' even-row';

            const statusIcon = (icon.length > 0) ? 
                        ( <FontAwesomeIcon className={iconColor[icon]} spin={spin} icon={icon as IconProp} /> ) : '';
            if (status === 'accepted' || status === 'in_progress') {
                completionTime = '---'
            } else {
                completionTime = updateDate;
            }

            return (
                <div key={ 'csaRow' + i} className={rowCSS}>
                <div key={'csaLine' + i} className={ 'csa-row'}>
                    <div>{row.job_id}</div>
                    <div>{this.formatDate(moment(row.created_at))}</div>
                    <div>{completionTime}</div>
                    <div>{statusIcon}&nbsp;{statusStr} </div>
                </div>
                </div>)
            }
        );

        return (<>{rowData}</>);
    }

    private handleNewRequestBtn = (): void => {
        this.props.controlModalDlg(SysFeatureEnums.csaLiteConfig, true, ZFeaturePopupTypes.CSALite)
        logger.log('new csa upload request');
    }

    private buildUploadDialog = () => {
        const applyBtnLabel = STRs.uploadFile;
        this.clearInterval();
        return (
            <GenDialog 
                show={this.props.csaLiteUIState.showModal}
                title={STRs.uploadNewCSAData} 
                msg={''}
                css="zmodel-50"
                applyBtnTxt={applyBtnLabel}
                disableApply={(): boolean => { return !this.props.csaLiteUIState.haveUploadFile} }
                onCancel={(): void => { this.props.controlModalDlg(SysFeatureEnums.csaLiteConfig, false); this.setRefresh(); }}
                dontCloseOnApply={true}
                onHide={(): void => {  this.props.controlModalDlg(SysFeatureEnums.csaLiteConfig, false); this.setRefresh(); }}
                handleApply={(): void => { this.uploadFile(); }}
                >
                    <span className="warning-text">{STRs.uploadFileWarning}</span><br/><br/>
                        {STRs.uploadInstruct}<br/> <br/> 
                        <input type="file" name="caLiteFile" onChange={(e) => { this.handleFileSelect(e)}} accept=".csv"></input> 
                        <br/>
            </GenDialog>
        )
    }

    private handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files === null) {
            return;
        }

        const file: File = e.target.files[0];
        const formData = new FormData();
        formData.append("csa", file, file.name);

        this.props.setCSAFile(formData);
    }

    private uploadFile = async (): Promise<void> => {
        if (!this.props.csaLiteUIState.haveUploadFile) {
            return;
        }
        
        const systemState = this.props.systemNavProjectState;
        const currentEnv = systemState._currentProject.currentEnv;
        const svcName = systemState.serviceEvents[systemState.serviceEvtIdx];
        const serviceId = currentEnv.serviceNameToId[svcName];

        const url = buildUrl(this.props.authNServerData, ZURLs.serverCSAUpload(serviceId));
        
        this.props.controlModalDlg(SysFeatureEnums.csaLiteConfig, false);
        try {
            await ZPost(url, this.props.csaLiteUIState.selectedFile, { mimeType: '', accept: '*/*' }); 
            this.props.showNotification(NotificationStyles.success, 'Upload has been successful');   
            this.closeNotification();
            setTimeout((): void => { 
                this.props.applySettings();
                this.setRefresh()}, 1000);
        }
        catch(err) {
            this.setRefresh();
            const p = err as Promise<FetchError>;
            const fError = err as FetchError;
            if (fError.message) {
                console.log(`error: ${fError.message}`)
                this.props.applySettings('serverError');
            } else {
                p.then((err) => {
                    console.log(`updateSystemData: fetch failed: ${err}`);
                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification();
                    this.props.applySettings('serverError');
                })
            }
        }
    }

    private getJobList = async (serviceId: string): Promise<void> => {
  
        const url = buildUrl(this.props.authNServerData, ZURLs.serverCSAJobList(serviceId));

        try {
            const userIdParam: StringMap = {} as StringMap;
            if (this.props.authNServerData.user !== undefined) {
                userIdParam["user_id"] = this.props.authNServerData.user.user_id;
            }
            
            const csaJobList = await ZGet(url,  userIdParam); 
            this.props.setCSAJobList(csaJobList as CSAHistoryItem[]);
            this.setRefresh();
        }
        catch(err) {
            this.setRefresh();

            const p = err as Promise<FetchError>;
            const fError = err as FetchError;
            if (fError.message) {
                console.log(`error: ${fError.message}`)
                this.props.applySettings('serverError');
            } else {
                p.then((err) => {
                    console.log(`updateSystemData: fetch failed: ${err}`);
                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification();
                    this.props.applySettings('serverError');
                })
            }
        }
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        csaLiteUIState: state.csaLiteUIState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(CSALiteConfig);
