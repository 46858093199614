import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import { actionTypes, ActionKeys, AInitMetricCheckBoxes, ASetMetricCheckBox, AInitAnalyticOptions, 
         ASetCurrentProjectKeys, ASetAnalyticOption, ASetAnalyticsGraphData, ASetAnalyticsRefreshId, 
         ASetQueryTimeFormat, ASetFromToTime, ASetNavItem, ASetUriCodeIdx, ASetAnalyticsMoreData,
         AApplySettings, ASetCurrentPage } 
        from '../actions/actionCreatorTypes';

import { AnalyticsMetricData, ProjectMetricKeyResponse, ZServiceFeatures, MetricsDataV1Response, SvrMetricTimeData, 
         SvrMetricDataPoint, SvrMetricTagEntry, MetricDataV1, MetricV1ExtraData, CsmLogsResponse} from '../data/queryResultDefinitions';
import { allMetrics, AnalyticsOptions, MetricsUIDefinitions, BooleanMap, NumberMap, PaginationData, StringMap } from '../data/metricsAndOptionsDefs';

import { EProjectState, SysFeatureEnums, MetricDataTypes } from './reducerEnums';
import { ProjectMetricDefinitions,  } from './uiAccessors'

import { hourIntervalsConstants, hourIntervalData, minuteIntervalData, STATISTICS, CDN_GEOs, GEOs_DATA, CSM_PAGE_SIZE,
         MAX_PAGE_CTRL_ITEMS_DISPLAYABLE } from '../shared/constants';
import { MISC_STRINGS } from '../shared/strings';

// import logger from '../shared/logUtilities'

export interface OptionStateDefinition {
    [optionName: string]: number;
}

// Note: In AnalyticsOptionsState the BooleanMaps have names that are composed of a feature name with the string 'Selected' 
//       appended to the end.  If we change feature names or add new features these have to be updated as well.
export interface AnalyticOptionsState {
    to: moment.Moment;
    from: moment.Moment;
    showingRelativeTime: boolean;
    uriCodeIdx: number;
    showCsmFailuresOnly: boolean,

    analyticsTrafficSelected: BooleanMap;
    analyticsEFRSelected: BooleanMap;
    analyticsNRUMSelected: BooleanMap;
    analyticsCacheSelected: BooleanMap;
    analyticsWAFSelected: BooleanMap;
    analyticsBOTSelected: BooleanMap;
    analyticsDnsDirSelected: OptionStateDefinition;
    analyticsCSMSelected: OptionStateDefinition;
    analyticsCSASelected: OptionStateDefinition;
    selectedAnalyticOptions: OptionStateDefinition;
}

export interface State {
    currentFeature: SysFeatureEnums;
    analyticsTimeoutId: number;
    futureAnalyticState: AnalyticOptionsState;
    appliedAnalyticState: AnalyticOptionsState;
    analyticOptions: AnalyticsOptions;
    aanalyticsState: EProjectState;
    graphData: AnalyticsMetricData[];
    extraGraphInfo: MetricV1ExtraData[],
    csmLogData: CsmLogsResponse,
    csmLogNextData: StringMap,
    pagingData: PaginationData,
    lastUpdateTime: number;
    isAnalyticsTabDirty: boolean;           // options or metrics have changed on the Analytics page
    isLoading: boolean;                     // the user has hit reply or refresh but it hasn't completed yet
}

const analyticsInitData: State = {
    currentFeature: SysFeatureEnums.analytics,
    analyticsTimeoutId: -1,
    futureAnalyticState: {} as AnalyticOptionsState,
    appliedAnalyticState: {} as AnalyticOptionsState,
    analyticOptions: {                  // minimum option set
        period: [],
        refresh: [],
    },
    aanalyticsState: EProjectState.ready,
    graphData: [],
    extraGraphInfo: [],
    csmLogData: {} as CsmLogsResponse,
    csmLogNextData: {},
    pagingData: {
        pageSize: CSM_PAGE_SIZE,
        totalItems: 0,
        qryTotalItems: 0,
        pageToShow: MAX_PAGE_CTRL_ITEMS_DISPLAYABLE,
        currentPage: 1,
        moreThanMax: false,
        isGoodData: true
    },
    lastUpdateTime: -1,
    isAnalyticsTabDirty: false,           // options or metrics have changed on the Analytics page
    isLoading: false,   
}

// Utility functions
// Init the arrays which contain the current set of options dropdowns that are displayed on the
// Analytics page
function initAnalyticsOptionsForFeatures(features: ZServiceFeatures, 
                                         optionKeys: ProjectMetricKeyResponse,
                                         currentFeature: SysFeatureEnums): AnalyticsOptions {
    const hourIntervals = hourIntervalData.map(entry => entry.name);
    const refreshIntervals = minuteIntervalData.map(entry => entry.name)
    
    /* tslint:disable:object-literal-sort-keys */
    // Need to keep in order we display them
    const options: AnalyticsOptions = {
        period: hourIntervals,
        refresh: refreshIntervals
    }

    /* tslint:enable:object-literal-sort-keys */
    const haveOptions = optionKeys;
    const metricKeys: ProjectMetricKeyResponse = optionKeys;

    if (features.network_real_user_metrics.enabled && currentFeature === SysFeatureEnums.nrumAnl) {
        options.statistics = STATISTICS;
    }

    if (currentFeature === SysFeatureEnums.dnsDirAnl || currentFeature === SysFeatureEnums.csmAnl) {
        if (features.dnsDir && features.dnsDir.enabled) {
            options.cdns = [MISC_STRINGS.allOption];
            options.geos = [MISC_STRINGS.allOption]
            
            if (haveOptions && metricKeys.cdns && metricKeys.cdns.length > 0) {
                const CDN_GEOS: string[] = CDN_GEOs.map((item: GEOs_DATA) => {return item.name})
                options.cdns = options.cdns.concat( metricKeys.cdns );
                options.geos = options.geos.concat(CDN_GEOS);
            }
            if (currentFeature === SysFeatureEnums.csmAnl && metricKeys.healthChecks) {
                options.healthChk = [MISC_STRINGS.allOption];
                options.healthChk = options.healthChk.concat(metricKeys.healthChecks)
            }
        }
    }

    if (features.metrics_by_geo.enabled) {
        options.stateName = [MISC_STRINGS.allOption]
        if (haveOptions) {
            options.stateName = options.stateName.concat( metricKeys.geo);
        }
    }

    return options;
}

// Determine which Analytics dropdown options should be selected by after we get new option
// keys from the server.  We have to handle the case where there were existing selections and
// where we are initializing from scratch.  Where there are existing selections, we need to 
// verify that the options are still present in our list after the update.
function initAnalyticOptionsSelections( fullInit: boolean,  isDirty: boolean, oldSelected: OptionStateDefinition, 
                                        options: AnalyticsOptions): OptionStateDefinition {
    const oldKeys = Object.keys(oldSelected);
    let init = false;
    let selectedOptions: OptionStateDefinition = {};

    if (oldKeys.length === 0) { 
        init = true; 
    } else if (isDirty) {
        init = true;
    } 
    
    if (init) {
        if (fullInit) {
            selectedOptions.period = 0;
            selectedOptions.refresh = 0;
        } else {
            selectedOptions.period = oldSelected.period ? oldSelected.period : 0;
            selectedOptions.refresh = oldSelected.refresh ? oldSelected.refresh : 0;
        }

        if (options.statistics !== undefined) {
            selectedOptions.statistics = 0;
        }

        if (options.stateName !== undefined) {
            selectedOptions.stateName = 0;
        }

        if (options.cdns !== undefined) {
            selectedOptions.cdn = 0
        }

        if (options.geos !== undefined) {
            selectedOptions.geos = 0
        }

        if (options.healthChk !== undefined) {
            selectedOptions.healthChk = 0
        }
    } else {    // only reInit if the old selected option is no longer there.
        selectedOptions = {...oldSelected};

        if (options.statistics !== undefined) {
            selectedOptions.statistics = 0;
        }

        if (selectedOptions.stateName && options.stateName &&
            options.stateName[oldSelected.stateName]) {
            selectedOptions.stateName = 0;
        }
    }

    return selectedOptions;
}

function updateSelectedOptions(options: AnalyticsOptions, selectedOptions: OptionStateDefinition): 
    OptionStateDefinition {
    // if (options.origin) {
    //     selectedOptions.origin = (selectedOptions.origin && 
    //                              (options.origin.indexOf[selectedOptions.origin]) !== -1) ? 
    //                              selectedOptions.origin : options.origin[0];
    // }

    if (options.stateName) {
        selectedOptions.stateName = (options.stateName[selectedOptions.stateName]) ? 
                                 selectedOptions.stateName : 0;
    }
    return selectedOptions;
}

function updateOptions(options: AnalyticsOptions, metricKeys: ProjectMetricKeyResponse): AnalyticsOptions {
    // if (options.origin) {
    //     const ipAddrs = buildOriginOptions(metricKeys);
    //     options.origin = [MISC_STRINGS.allOption].concat(Object.keys(ipAddrs));
    // }

    if (options.stateName) {
        options.stateName = [MISC_STRINGS.allOption].concat( metricKeys.geo)
    }
    
    return options;
}

function resetAnalyticOptionMetricSettings(state: State): void {
    state.futureAnalyticState = cloneDeep(state.appliedAnalyticState);
    state.isAnalyticsTabDirty = false;
}

const initAnalyticsData = (): State => {
    const state = Object.assign({}, analyticsInitData);
    const appliedAnalyticState: AnalyticOptionsState = {
        showingRelativeTime: true,
        to: moment(),
        from: moment().subtract(1, 'h'),
        uriCodeIdx: 0,
        showCsmFailuresOnly: false,
        analyticsTrafficSelected: {},
        analyticsEFRSelected: {},
        analyticsNRUMSelected: {},
        analyticsCacheSelected: {},
        analyticsWAFSelected: {},
        analyticsBOTSelected: {},
        analyticsDnsDirSelected: {},
        analyticsCSMSelected: {},
        analyticsCSASelected: {},
        selectedAnalyticOptions: {},
    }

    state.appliedAnalyticState = appliedAnalyticState;
    state.futureAnalyticState = cloneDeep(state.appliedAnalyticState);
 
    return state;
}

// ****************************
// Reducers
// ****************************
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setProjectServices = (initialState: State, action: actionTypes): State => {
    let state = {...initialState};

    const feature = state.currentFeature;
    const selectedAnalytics = state.futureAnalyticState.selectedAnalyticOptions;
    const showingRelativeTime = state.futureAnalyticState.showingRelativeTime;
    const from = state.futureAnalyticState.from;
    const to = state.futureAnalyticState.to;
    const isDirty = state.isAnalyticsTabDirty;

    state = initAnalyticsData();
    state.currentFeature = feature;
    if (selectedAnalytics) {
        state.futureAnalyticState.selectedAnalyticOptions = selectedAnalytics;
        state.futureAnalyticState.from = from;
        state.futureAnalyticState.to = to;
        state.futureAnalyticState.showingRelativeTime = showingRelativeTime;
        state.isAnalyticsTabDirty = isDirty;
    }

    state.aanalyticsState = EProjectState.serviceChanged;

    return state; 
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeProject = (initialState: State, action: actionTypes): State => {
    let state = {...initialState};

    state = initAnalyticsData();

    return state; 
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeServiceEnvironment = (initialState: State, action: actionTypes): State => {
    const state = {...initialState};

    state.aanalyticsState = EProjectState.serviceEnvironmentChanged;
    return state; 
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetProject = (initialState: State, action: actionTypes): State => {
    const state = {...initialState};

    return state; 
}

// Initialize the metric check boxes.  We init with all checkboxes checked.  We build these
// for each Analytics tab. 
const initMetricChkBoxes = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as AInitMetricCheckBoxes;
    const checkboxData: ProjectMetricDefinitions = action.payload.checkboxData;

    let defaults: BooleanMap = {};       // change to let when we add 'api' support
    let data: MetricsUIDefinitions[] = [];

    data = checkboxData.analyticsTraffic;
    for (let i = 0, len = data.length; i < len; i++) {
        defaults[data[i].key] = true;
    }

    // utility function to init the checkbox group
    const buildCheckboxData = (metricGroup: string): void => {
        if (checkboxData[metricGroup]) {
            defaults = {};
            data = checkboxData[metricGroup] as MetricsUIDefinitions[];
            for (let i = 0, len = data.length; i < len; i++) {
                defaults[data[i].key] = true;
            }
            state.futureAnalyticState[metricGroup + 'Selected'] = defaults;
            state.appliedAnalyticState[metricGroup + 'Selected'] = Object.assign({}, defaults);
        } else {
            state.futureAnalyticState[metricGroup + 'Selected'] = {};
            state.appliedAnalyticState[metricGroup + 'Selected'] = {};
        }
    }

    state.futureAnalyticState.analyticsTrafficSelected = defaults;
    state.appliedAnalyticState.analyticsTrafficSelected = Object.assign({}, defaults);

    buildCheckboxData(SysFeatureEnums.cacheAnl);
    buildCheckboxData(SysFeatureEnums.nrumAnl);
    buildCheckboxData(SysFeatureEnums.efrAnl);
    buildCheckboxData(SysFeatureEnums.wafAnl);
    buildCheckboxData(SysFeatureEnums.botAnl);
    buildCheckboxData(SysFeatureEnums.dnsDirAnl)
    buildCheckboxData(SysFeatureEnums.csmAnl)
    buildCheckboxData(SysFeatureEnums.csaAnl)
    // buildCheckboxData('push');

    state.aanalyticsState = EProjectState.userClickedApply;
    state.isLoading = true;

    return state;
}

const setMetricCheckBoxes = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetMetricCheckBox;
    const state = {...initialState}; 

    const nsTabStr: string = state.currentFeature + 'Selected';

    const key: string = action.payload.metricKey;
    if (key === MISC_STRINGS.allOption) {
        const metrics: MetricsUIDefinitions[] = allMetrics[state.currentFeature];
        const currentCheckedMetrics = state.futureAnalyticState[nsTabStr];
        for (let i = 0, len = metrics.length; i < len; i++) {
            const metricDef = metrics[i];
            if (currentCheckedMetrics[metricDef.key] !== undefined) {
                if (metricDef.required) {
                    currentCheckedMetrics[metricDef.key] = true;
                } else {
                    currentCheckedMetrics[metricDef.key] = action.payload.value;
                }
            }
        }
        state.isAnalyticsTabDirty = true;
    } else {
        if (state.futureAnalyticState[nsTabStr]) {
            if ((state.futureAnalyticState[nsTabStr])[key] !== undefined) {
                (state.futureAnalyticState[nsTabStr])[key] = action.payload.value;
                state.isAnalyticsTabDirty = true;
            } else {
                console.error('UIState: Bad key "' + key + '" passed to SET_METRIC_CHKBOXES');
            }
        } else {
            console.error('UIState: Unknown metric key state container "' + nsTabStr + 
                            '" passed to SET_METRIC_CHKBOXES');
        }
    }

    return state;
}

const initAnalyticsOptions = (initialState: State, actions: actionTypes): State => {
    const action = actions as AInitAnalyticOptions;
    const state = {...initialState}; 

    const {fullInit, features, optionKeys, analyticFeature} = action.payload;
    // logger.log(`%c analyticsState.initAnalyticsOptions: new analytics tab is = ${analyticFeature}`, 'background: red, color: white;');
    state.currentFeature = analyticFeature;

    state.analyticOptions = initAnalyticsOptionsForFeatures(features, optionKeys, state.currentFeature);
    state.futureAnalyticState.selectedAnalyticOptions = 
                initAnalyticOptionsSelections(fullInit, state.isAnalyticsTabDirty, 
                                              state.futureAnalyticState.selectedAnalyticOptions, 
                                              state.analyticOptions);
    state.appliedAnalyticState.selectedAnalyticOptions = {...state.futureAnalyticState.selectedAnalyticOptions};
    state.aanalyticsState = EProjectState.initComplete;

    return state;
}

const setCurrentProjectKeys =  (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetCurrentProjectKeys;
    const state = {...initialState};

    const oKeys = Object.assign({}, action.payload.optionKeys);
    state.analyticOptions = updateOptions(state.analyticOptions, oKeys);

    state.futureAnalyticState.selectedAnalyticOptions = 
            updateSelectedOptions(state.analyticOptions, state.futureAnalyticState.selectedAnalyticOptions);
    if (state.aanalyticsState === EProjectState.newProjectLoaded || 
        state.aanalyticsState === EProjectState.tabChanged) {
            state.aanalyticsState = EProjectState.projectKeysLoaded;
    } else {
        state.aanalyticsState = EProjectState.ready;              
    }

    return state;
}

const setAnalyticOptions = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetAnalyticOption;
    let state = {...initialState};

    state = {...initialState};
    const optionName = action.payload.optionName;
    if (state.futureAnalyticState.selectedAnalyticOptions[optionName] !== undefined) {
        const optionValue = action.payload.value;
        if (optionValue !== state.futureAnalyticState.selectedAnalyticOptions[optionName]) {
            state.futureAnalyticState.selectedAnalyticOptions[optionName] = optionValue;
            state.isAnalyticsTabDirty = true;
        }
    } else {
        // This is a hack because we don't have a standard way of handling checkboxes in the page 
        // and since I don't want to make a new interface for this case yet (i.e., if it becomes common)
        // we go with the hack.
        //
        // So for right CSM's checkbox is handled by call this.
        if (optionName.indexOf("csm-hc") !== -1) {
            state.futureAnalyticState.showCsmFailuresOnly = action.payload.value === 1;
            state.isAnalyticsTabDirty = true;
        } else {
            console.log('analyticsState: bad option name "' + optionName + '" passed to SetAnalyticOption');
        }
    }

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applySettings = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as AApplySettings;

    if (action.payload && action.payload.error) {
        state.aanalyticsState = EProjectState.serverError;
        state.isLoading = false;
    } else if (state.isAnalyticsTabDirty) {
        state.appliedAnalyticState = cloneDeep(state.futureAnalyticState);
        state.isAnalyticsTabDirty = false;
        state.csmLogData = {} as CsmLogsResponse;
        state.aanalyticsState = EProjectState.userClickedApply;
        state.isLoading = true;
    }

    return state;
}

const setAnalyticsGraphData = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as ASetAnalyticsGraphData;

    if (action.payload.dataType !== MetricDataTypes.dnsdir) {
        const { appendResults, graphData, totalItems } = action.payload;
        state = setLegacyGraphData(state, appendResults, graphData as AnalyticsMetricData[], totalItems);
    } else {
        const { graphData, totalItems} = action.payload;
        state = setMetricV1GraphData(state, graphData as MetricsDataV1Response, totalItems); 
    }

    state.lastUpdateTime = Date.now();
    state.isLoading = false;
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setMetricV1GraphData = (state: State, graphData: MetricsDataV1Response, totalItems: number): State => {
    state.graphData = [buildMetricV1Data(state, graphData)];
    state.extraGraphInfo.length = 0;
    state.pagingData.currentPage = 1;
    state.csmLogNextData = {};

    const aggregateValue: NumberMap = {}
    if (state.graphData.length > 0) {
        graphData.results[0].series.forEach((data: MetricDataV1) => {
            aggregateValue[data.name] = data.aggregate_value;
        });
        state.extraGraphInfo.push({aggregateValue})
    }

    state.aanalyticsState = EProjectState.dataLoaded;
    state.aanalyticsState = EProjectState.ready;

    return state;
}

const buildMetricV1Data = (state: State, chartData: MetricsDataV1Response): AnalyticsMetricData => {
    const v1Labels = {
        [SysFeatureEnums.dnsDirAnl]: {
            ZY: 'traffic_perc_zy',
            CDN: 'traffic_perc_cdn',
            metric: 'traffic_perc',
        },
        [SysFeatureEnums.csmAnl]: {
            ZY: 'uptime_perc_zy',
            CDN: 'uptime_perc_cdn',
            metric: 'uptime_perc'
        }
    }

    const makeData = (fakeMetric: string, values: number[][]): SvrMetricTimeData => {
        const tags = {} as SvrMetricTagEntry;
        tags.metric_name = fakeMetric;
        tags.origin_ip = '';
        tags.uricode = '';

        const timeValues: SvrMetricDataPoint[] = values.map((value: number[]): SvrMetricDataPoint => { 
            return { epochmillis: (value[0]*1000), value: value[1] }
        })

        return { tags, time_values: timeValues };
    }

    const dataArr: SvrMetricTimeData[] = [];

    if ( chartData && chartData.results && chartData.results[0].series && chartData.results[0].series.length === 2) {
        chartData.results[0].series.forEach((dnsDirData: MetricDataV1) => {
            // NOTE: HELP
            const metric = v1Labels[state.currentFeature][dnsDirData.name];
            dataArr.push(makeData(metric, dnsDirData.values))
        })
    }

    const myChartData: AnalyticsMetricData = {
        data: dataArr,
        metric: v1Labels[state.currentFeature].metric,
        stats: ''
    }

    return myChartData;
}

const setLegacyGraphData = (state: State, appendResults: boolean, graphData: AnalyticsMetricData[], 
                                totalItems: number): State => {
    if (appendResults) {
        if (state.aanalyticsState === EProjectState.ready || 
            state.aanalyticsState === EProjectState.userClickedApply ||
            state.aanalyticsState === EProjectState.reloadData ) {
            state.graphData = graphData;
            state.aanalyticsState = EProjectState.dataLoadInProgress;
        } else {
            state.graphData = (state.graphData as AnalyticsMetricData[]).concat(graphData);
            state.aanalyticsState = EProjectState.dataLoaded;
        }
    } else {
        state.graphData = graphData;
        state.aanalyticsState = EProjectState.dataLoaded;
    }

    if (state.graphData.length === totalItems) {
        state.aanalyticsState = EProjectState.ready;
    }

    return state;
}

const setAnalyticsMoreData = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetAnalyticsMoreData;

    const { currentFeature, type, data } = action.payload;

    if (currentFeature === SysFeatureEnums.csmAnl) {
        if (type === 'hcLogs') {
            state.csmLogData = data;
            const pagingData = state.pagingData;
            if (data.meta === undefined) {
                data.meta = {
                    total_records: 0,
                    num_pages: 0,
                    next: ''

                };
            }

            const max_pages = data.meta.num_pages;

            if (state.csmLogNextData.url === undefined) {
                if (max_pages > 1) {
                    const url = data.meta.next;
                
                    const csmLogHashData: StringMap = {}
                    let urlPieces = url.split("?");
                    csmLogHashData.url = urlPieces[0];
    
                    urlPieces = urlPieces[1].split("&")
                    const pieces = urlPieces[0].split("=");
                    csmLogHashData.hash = pieces[1];
                    state.csmLogNextData = csmLogHashData;
                } else if (max_pages === 1) {
                    state.csmLogNextData = {};
                } else {
                    pagingData.currentPage = 0;
                    pagingData.totalItems = 0;
                    pagingData.qryTotalItems = 0;
                }
            }
            
            pagingData.pageSize = CSM_PAGE_SIZE;
            pagingData.totalItems = data.meta.total_records;
            pagingData.qryTotalItems = data.meta.total_records;
            pagingData.moreThanMax = false;
            pagingData.isGoodData = true;    
            state.aanalyticsState = EProjectState.ready;
        }
    }

    return state;
}

const setCurrentLogPage = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetCurrentPage;

    if (action.payload.component === 'cms-hclogs') {
        state.aanalyticsState = EProjectState.userChangedPages;
        state.pagingData.currentPage = action.payload.pageNum;
        state.csmLogData.logs.length = 0;
    }

    return state;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const refreshPage = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    state.aanalyticsState = EProjectState.reloadData;
    state.isLoading = true;

    return state;
}

const setAnalyticsRefreshId  = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetAnalyticsRefreshId;

    state.analyticsTimeoutId = action.payload.timeoutId;

    return state;
}

const setQueryTimeFormat = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetQueryTimeFormat;

    if (action.payload.component === 'ANALYTICS') {
        state.futureAnalyticState.showingRelativeTime = action.payload.relativeTime;
        if (!state.futureAnalyticState.showingRelativeTime) {
            const currentOptions = state.futureAnalyticState.selectedAnalyticOptions;
            const timePeriodIdx = (currentOptions && (currentOptions.period !== undefined)) ? 
                currentOptions.period : 0;
            state.futureAnalyticState.to = moment();
            state.futureAnalyticState.from = moment().subtract(hourIntervalsConstants[timePeriodIdx], 'h');
        }
        state.isAnalyticsTabDirty = true;
    }

    return state;
}

const setFromToTime = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetFromToTime;

    state.futureAnalyticState.to = action.payload.to;
    state.futureAnalyticState.from = action.payload.from;
    state.isAnalyticsTabDirty = true;

    return state;
}


const setNavItem = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetNavItem;
    const state = {...initialState}; 

    const feature = action.payload.feature;
    state.currentFeature = feature;

    const selectedAnalytics = state.futureAnalyticState.selectedAnalyticOptions;
    const showingRelativeTime = state.futureAnalyticState.showingRelativeTime;
    const from = state.futureAnalyticState.from;
    const to = state.futureAnalyticState.to;
    const isDirty = state.isAnalyticsTabDirty;

    resetAnalyticOptionMetricSettings(state);
    if (selectedAnalytics) {
        state.futureAnalyticState.selectedAnalyticOptions = selectedAnalytics;
        state.isAnalyticsTabDirty = isDirty;
        state.futureAnalyticState.from = from;
        state.futureAnalyticState.to = to;
        state.futureAnalyticState.showingRelativeTime = showingRelativeTime;
    }
    
    return state;
}

const setUriCodeIdx = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetUriCodeIdx;
    const state = {...initialState}; 

    state.futureAnalyticState.uriCodeIdx = action.payload.index;
    state.isAnalyticsTabDirty = true;

    return state;
}

type ActionFunction = ((state: State, action: actionTypes) => State);
type AdminActions = ActionKeys.CHANGE_PROJECTS | ActionKeys.INIT_METRIC_CHKBOXES | ActionKeys.SET_METRIC_CHKBOX |
                    ActionKeys.INIT_ANALYTIC_OPTIONS | ActionKeys.SET_CURRENT_PROJECT_KEYS | ActionKeys.REFRESH_PAGE |
                    ActionKeys.SET_ANALYTIC_OPTION | ActionKeys.APPLY_SETTINGS | ActionKeys.SET_ANALYTICS_GRAPH_DATA |
                    ActionKeys.SET_ANALYTICS_REFRESH_ID | ActionKeys.SET_QUERY_TIME_FORMAT | ActionKeys.RESET_PROJECT |
                    ActionKeys.SET_FROM_TO_TIME |  ActionKeys.SET_NAV_ITEM | ActionKeys.SET_ANALYTICS_MORE_DATA |
                    ActionKeys.CHANGE_ENVIRONMENT | ActionKeys.SET_URI_CODE_IDX | ActionKeys.SET_PROJECT_SERVICES |
                    ActionKeys.SET_CURRENT_PAGE;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in AdminActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.CHANGE_PROJECTS]: changeProject,
    [ActionKeys.CHANGE_ENVIRONMENT]: changeServiceEnvironment,
    [ActionKeys.RESET_PROJECT]: resetProject,
    [ActionKeys.SET_NAV_ITEM]: setNavItem,
    [ActionKeys.INIT_ANALYTIC_OPTIONS]: initAnalyticsOptions,
    [ActionKeys.INIT_METRIC_CHKBOXES]: initMetricChkBoxes,
    [ActionKeys.SET_METRIC_CHKBOX]: setMetricCheckBoxes,
    [ActionKeys.SET_ANALYTIC_OPTION]: setAnalyticOptions,
    [ActionKeys.SET_ANALYTICS_GRAPH_DATA]: setAnalyticsGraphData,
    [ActionKeys.SET_ANALYTICS_MORE_DATA]: setAnalyticsMoreData,
    [ActionKeys.SET_CURRENT_PAGE]: setCurrentLogPage,
    [ActionKeys.SET_ANALYTICS_REFRESH_ID]: setAnalyticsRefreshId,
    [ActionKeys.SET_QUERY_TIME_FORMAT]: setQueryTimeFormat,
    [ActionKeys.SET_FROM_TO_TIME]: setFromToTime,
    [ActionKeys.SET_URI_CODE_IDX]: setUriCodeIdx,
    [ActionKeys.APPLY_SETTINGS]: applySettings,
    [ActionKeys.REFRESH_PAGE]: refreshPage,
    [ActionKeys.SET_CURRENT_PROJECT_KEYS]: setCurrentProjectKeys,   
    [ActionKeys.SET_PROJECT_SERVICES]: setProjectServices,
}

function analyticsUIState(initialState: State, action: actionTypes ): State {
    let state: State = initialState;

    if ( initialState === undefined ) {
        state = Object.assign({}, analyticsInitData);
    }

    const fct: ActionFunction | undefined = actionMap[action.type];
    if (fct) {
        // logger.log(`%c ---analyticsState:  actionKey: ${action.type}; isLegacyFeature: ${state.isLegacyFeature}`, 'background: red; color: white;');
        state = (fct)(state, action);
        // logger.log(`%c ---after update of analyticsState. ${state.aanalyticsState};  isLegacyFeature: ${state.isLegacyFeature}`, 'background: red; color: white;')
    } 

    return  state;
}

export default analyticsUIState