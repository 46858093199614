import * as React from 'react';
import moment from 'moment';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu';

// graphics and constants
import { State } from '../App';
import zlogo from '../img/zycada-icon-clipped.png';
import logo from '../img/Zycada_Horizontal_White300.png';

// import logo from '../img/zycada-logo-digital-reversed.svg';

// state
import { actionTypes } from '../actions/actionCreatorTypes';

// Components
import { ZServiceFeatures, ZUserMin, ZUserTypes, ZProjectMin, ZRestResponse, ZServiceExt, FetchError, RestAPI, 
         ZApiKey, ApiKeyPermission, BillingOrgUsageData, AvailableBillingReports, ProjectSolutions, ProjUriCode, 
         CSAHistoryItem, OrgInfo, ZProductFeatureAttributes } from '../data/queryResultDefinitions';
import { ZSessionStorage } from '../data/metricsAndOptionsDefs';

import { serviceDisplayEnum, sessionStorageStates, loginState, SysFeatureEnums, ZEnvironments, 
            ZSortDirection, UsageUnits, ZAdminMapType, ZFeaturePopupTypes
        } from '../reducers/reducerEnums'
import { getProjectLogo, getProjectList, getProjectFeatures, getProjectByIndex, getCurrentProjectIndex, 
         findProjectById } from '../reducers/projectAccessors';
import { getProjectDspFeatures, getServiceEventList, getCurrentServiceId, /* findServiceEventIdxForService */ } 
        from '../reducers/uiAccessors';
import { orgAccessToNewFeatures, buildUrl, orgAccessToCSAFeatures, orgAccessToReports } from '../reducers/serverEnvironAccessor';
import { enumToFeatureMapping, SvcDDFeatureItem } from '../reducers/systemState';

// import Dashboard from './Dashboard';
import Account from './Account';
import Analytics from './Analytics';
import Cache from './Cache';
import Logout from './Logout';
import Reports from './Reports';
import Admin from './Admin';
import CSALiteConfig from './CSALiteCfg';
import ServiceManagement from './ServiceManagement';
import Organizations from './Organizations';
import UnknownAction from './UnknownAction';
import ZNavItem from './ZNavItem';
import BillingReports from './BillingReports';

import ZDropDown from '../shared/ZDropDown';
import ZButton from '../shared/ZButton';
import { NotificationStyles, ProjDTypeFeatures, NAV_PANEL_DDOWN_MAX_TITLE_LEN } from '../shared/constants';
import NotificationControl from '../shared/Notification';
import { MISC_STRINGS, NAVSTRINGS as NStrs, SUBNAVSTRINGS, BURGERSTRINGS as BStrs, ACCOUNT_STRINGS as AcctStrs, ACCOUNT_STRINGS } from '../shared/strings';
import { toTitleCase, setSessionStorage, getSessionStorage, pwdExpiryUtils } from '../shared/utilities';
import logger from '../shared/logUtilities';
import { ZGet } from '../shared/backend';
import ZURLS from '../shared/urls';
import GenDialog from '../shared/GenDialog';

interface MainProps extends State {
    changeProjects: (projectId: string, environment?: ZEnvironments) => actionTypes;
    setNavItem: (analyticsTab: string, feature: SysFeatureEnums) => actionTypes;
    setCurrentServiceEvtIdx: (index: number) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    setSvcConfigIdx: (idx: number) => actionTypes;
    setProjURICodes: (uriCodeItems: ProjUriCode[]) => actionTypes;
    changeServiceEnvironment: (env: ZEnvironments) => actionTypes;
    resetProject: (projectId: string) => actionTypes;
    dataLoadInProgress: (feature: string) => actionTypes;
    dataGroupLoad: (inProgress: boolean, feature: string) => actionTypes;
    setHamburgerOpen: (isOpen: boolean) => actionTypes;
    sessionStateSaved: () => actionTypes;
    initSessionData: () => actionTypes;
    applySettings: (error?: string) => actionTypes;
    featureReady: () => actionTypes;
    redirectTo: (url: string) => actionTypes;
    setProjectServices: (projectId: string, serviceList: ZServiceExt[], env?: ZEnvironments, serviceId?: string) => actionTypes;
    setProjects: (projects: ZProjectMin[], projectId?: string) => actionTypes;
    setProjectFeaturesAttributes: (svcFeatureAttributes: ZProductFeatureAttributes) => actionTypes,
    deleteService: (serviceId: string) => actionTypes;

    setAdminUserList: (userList: ZUserMin[]) => actionTypes;
    setUserListIndex: (index: number) => actionTypes;
    setUserApiKeyList: (apikeys: ZApiKey[]) => actionTypes;
    setApiKeyListIndex: (index: number) => actionTypes;
    toggleAdminSelection: (collectionName: ZAdminMapType, id: string) => actionTypes;
    toggleAdminPopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean, 
                       value?: number | string | ZApiKey) => actionTypes;
    toggleShowExpiredApiKeys: () => actionTypes;
    setApiKeyState: (friendlyName: string, expirationDate: moment.Moment, 
                     permission?: ApiKeyPermission) => actionTypes;
    
    csaFileVerified: () => actionTypes;
    setCSAFile: (selectedFile: FormData) => actionTypes;
    initCSALite: () => actionTypes;
    setCSAJobList: (csaJobList: CSAHistoryItem[]) => actionTypes;
    
    controlModalDlg: (feature: SysFeatureEnums, setOpen: boolean, dialogName?: ZFeaturePopupTypes) => actionTypes;
    setIntervalId: (intervalId: number) => actionTypes;

    setOrgListIdx: (orgIndex: number) => actionTypes;
    setOrgList: (orgList: OrgInfo[], selectedOrgName?: string)  => actionTypes;
    setCurrentOrg: (orgId: string) => actionTypes;
    setUserInfo: (user: ZUserMin) => actionTypes;
    setOrgData: (orgData: OrgInfo) => actionTypes,
    setOrgName: (orgName: string) => actionTypes,
    setSystemProjectSolutions: (solutions: ProjectSolutions[]) => actionTypes;

    setReportPeriodIndex: (periodIndex: number) => actionTypes,
    initFeature: (featurName: SysFeatureEnums) => actionTypes,
    sortTable: (feature: SysFeatureEnums, tableName: string, fieldName: string, direction: ZSortDirection) => actionTypes,
    setBillingReportData:(availableBillingReports: AvailableBillingReports) => actionTypes,
    setProjectTrafficUsage: (billingUsageData: BillingOrgUsageData, includesServices: boolean) => actionTypes,
    toggleBillingShowServices: (showingServices: boolean) => actionTypes,
    toggleBillingShowProjectServices: (projectIndex: number) => actionTypes,
    setBillingUnits: (units: UsageUnits) => actionTypes,
    setBillingMonthIndex: (index: number) => actionTypes,
}

class Main extends React.Component<MainProps> {
    constructor(props: MainProps) {
        super(props);
        this.handleProjectSelect = this.handleProjectSelect.bind(this);
        this.handleServiceEvtSelect = this.handleServiceEvtSelect.bind(this);
        this.handleCloseNotification = this.handleCloseNotification.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.authNServerData.isLoggedIn !== loginState.loggedIn) {
            // HACK!!! - can't figure out how to psych out react-router so it can handle angularJS's router syntax
            // check for password reset link
            if (window.location.href.indexOf(ZURLS.angularResetLink) !== -1) {
                logger.log('main.tsx: Backward compatibility - discovered "angularjs" reset password url -' + 
                             ' rerouting to reset password');
                const resetKeyIdx = window.location.href.indexOf('?reset_key');
                const resetKey = window.location.href.substring(resetKeyIdx)
                this.props.redirectTo(ZURLS.reset + resetKey);
            }  else {
                this.props.redirectTo(ZURLS.login);

            }
        } else {
            const sessionState = getSessionStorage();
            const systemState = this.props.systemNavProjectState;

            const url = sessionState.url;
            if (url !== undefined) {
                if (systemState._projectList.length !== 0) {
                    const currentProj: ZProjectMin = findProjectById(systemState, sessionState.projectId);
                    this.props.changeProjects(currentProj.project_id, sessionState.environment as ZEnvironments);
                }
                this.props.initSessionData();
            } else  if (! systemState.servicesLoaded || !systemState.projectsLoaded) {
                const currentProj: ZProjectMin = findProjectById(systemState, sessionState.projectId);

                logger.log(` **** main.componentDidMount: UI state: ${systemState.systemUIState}, projectsLoaded '${systemState.projectsLoaded}', servicesLoaded '${systemState.servicesLoaded}' `)
                this.updateSystemData(currentProj.project_id, '', ZEnvironments.unknown);
            }
        }
    }

    public componentDidUpdate(prevProps: MainProps): void {
        const systemState = this.props.systemNavProjectState;
        const authState = this.props.authNServerData;

        // logger.log(`%c Main.componentDidUpdate: dataloading: ${systemState.dataLoading}`, 'background: cyan; color: black;')
        // logger.log(` **** main.componentDidUpdate: enter - UI state: ${systemState.systemUIState}, serviceIdx ${systemState.serviceEvtIdx}; projectsLoaded '${systemState.projectsLoaded}', servicesLoaded '${systemState.servicesLoaded}' `)

        const currentProj = systemState._currentProject;
        if ((prevProps.systemNavProjectState._currentProject.name !== systemState._currentProject.name) ) {
            const oldProjName = prevProps.systemNavProjectState._currentProject.name;
            const projName = currentProj.name;
            logger.log(` **** main.componentDidUpdate:  project changed from "${oldProjName}" to "${projName}".`);
        }

        if (systemState.sessionStateChanged === sessionStorageStates.init) {      
            const sessionState = getSessionStorage();
            const tab = sessionState.tab;
            if (tab) {
                const feature = sessionState.feature;
                const projectId = sessionState.projectId !== undefined ? sessionState.projectId : 'couldnt find project';
                const serviceId = sessionState.serviceId !== undefined ? sessionState.serviceId : 'couldnt find service';
                const env = sessionState.environment !== undefined ? sessionState.environment as ZEnvironments: ZEnvironments.prod;
                this.updateSystemData(projectId, serviceId, env, tab, feature)
            } else {
                this.props.featureReady();
            }
            // this.props.sessionStateSaved();
        } else if (systemState.sessionStateChanged === sessionStorageStates.dirty) {

            if (systemState._projectList.length > 0) {
                const svcIdx = systemState.serviceEvtIdx;
                const currentService = systemState._currentProject.currentEnv.allServices[svcIdx];
                let serviceId = 'couldnt find service';
                if (currentService !== undefined) {
                    serviceId = currentService.service_id;
                } 
                const env = systemState._currentProject.currentServiceEnvironment;
                logger.log(` **** main.componentDidUpdate: session state changed - new env = ${env}; new svcIdx = ${svcIdx}; new serviceId = ${serviceId}`)
            }
            this.saveSessionState();
        } else {
            if (authState.serverConfigLoaded) {
                const env = systemState._currentProject.currentServiceEnvironment;
                const serviceId = getCurrentServiceId(systemState);
                
                if (!(systemState.projectsLoaded && systemState.servicesLoaded )) {
                    this.updateSystemData(currentProj.project_id, serviceId, env)
                }

                if ( systemState.redirectUrl && systemState.redirectUrl.length > 0) {
                    this.props.redirectTo('');
                }
            }
        }

        // logger.log(` **** main.componentDidUpdate: exit -- UI state: ${systemState.systemUIState}, projectsLoaded '${systemState.projectsLoaded}', servicesLoaded '${systemState.servicesLoaded}'\n`)
    }

    private updateSystemData = async (projectId: string, serviceId: string, env: ZEnvironments, initTab?: string, initFeature?: SysFeatureEnums ): Promise<void> => {
        const systemState = this.props.systemNavProjectState;
        
        if (systemState.dataLoading) {
            // logger.log('%c--> updateSystemData: data loading in progress', 'background: cyan; color: black');
            return;
        }
        // logger.log( `%c--> updateSystemData: enter: pid: ${projectId}; sid: ${serviceId}; initTab: ${initTab}; initFeature: ${initFeature}`, 'background: red; color: white');
        try {
            let projectLoaded = false;
            let projects: ZProjectMin[] = [];
            if (! systemState.projectsLoaded ) {
                logger.log(' **** main.updateSystemData: project load start')
                const url = buildUrl(this.props.authNServerData, ZURLS.serverAdminProjectList);

                this.props.dataLoadInProgress('main');
                const projData: ZRestResponse = await ZGet(url, {});
                logger.log(' **** main.updateSystemData: project load done')

                projects = projData as ZProjectMin[];
                projects = (projects !== undefined) ? projects : [];
                if (projects) {
                    this.props.setProjects(projects, projectId );
                    projectLoaded = true;
                }
                return;
            } else {
                projectLoaded = true;
            }
            
            if (!projectLoaded) {
                return;
            } else if (projectLoaded && systemState._projectList.length > 0 && projectId !== systemState._currentProject.projectId) {
                this.props.changeProjects(projectId, ZEnvironments.unknown);
            }

            this.props.dataGroupLoad(true, 'main');
            // logger.log('%c--> updateSystemData: starting group dataload', 'background: cyan; color: black');

            if (!systemState.projectFeaturesLoaded) {
                if (projectId === undefined) {
                    console.log('<--> Main.updateSystemData undefined projectId ');
                }

                let url = ZURLS.serverProductsAndAttributes(projectId);
                url = buildUrl(this.props.authNServerData, url);
        
                let features: ZRestResponse | undefined = undefined;
                features = await ZGet(url, { include_features: true });
                this.props.setProjectFeaturesAttributes((features as ZProductFeatureAttributes));
            }

            if (!systemState.uriCodesLoaded && projectId !== undefined) {
                
                const url = buildUrl(this.props.authNServerData, ZURLS.serverUriCodeManagement(projectId));
                // console.log('login: uri code load start')
                const uCodes = await ZGet(url, '');
                // console.log('login: uri code load done')

                const uriCodes = uCodes as ProjUriCode[];
                this.props.setProjURICodes(uriCodes)
            }

            if (!systemState.servicesLoaded) {
                // logger.log('%c ---> servicesLoaded = ' + systemState.servicesLoaded, 'background: cyan; color: black');
                const force = !(initTab === undefined || initFeature === undefined)
                const feature =  force ? initFeature as SysFeatureEnums: systemState.currentFeature;
                if (systemState._projectList.length > 0) {
                    const viewMapping: SvcDDFeatureItem =  enumToFeatureMapping[feature];
                    if (!viewMapping ) {
                        logger.alert(feature);
                    }
                    // logger.log('%c ---> servicesLoaded = ' + systemState.servicesLoaded, 'background: cyan; color: black');

                    if (viewMapping.loadService !== serviceDisplayEnum.none) {
                        const svcUrl = buildUrl(this.props.authNServerData, ZURLS.serverServiceMgmtProjectLvl);
                        const svcParams: RestAPI = {
                            "project_id": projectId,
                        }

                        if (viewMapping.loadService === serviceDisplayEnum.deployed || 
                            viewMapping.loadService === serviceDisplayEnum.deployedSingleService) {
                            svcParams.is_live = true;
                        }

                        const svcList: ZRestResponse = await ZGet(svcUrl, svcParams);
                        const serviceList: ZServiceExt[] = svcList as ZServiceExt[];
                        // console.log(`###### ----- main.updateSystemData: ${projectId} - env: ${env}; serviceId: ${serviceId}`);
                        this.props.setProjectServices(projectId, serviceList, env, serviceId);
                        if (force) {
                            this.props.setNavItem(initTab as string, initFeature as SysFeatureEnums);
                            this.props.featureReady();
                        }
                        logger.log('.......... services saved')
                    } else {    // need to tell system we don't need services.
                        this.props.setProjectServices(projectId, [] as ZServiceExt[], env, serviceId);
                        this.props.featureReady();
                        this.props.setNavItem(initTab as string, initFeature as SysFeatureEnums);
                    }
                } else {
                    this.props.setProjectServices(projectId, [] as ZServiceExt[], env, serviceId);
                    this.props.setNavItem(initTab as string, initFeature as SysFeatureEnums);
                    this.props.featureReady();
                }
            } else {
                if (initTab && initFeature) {
                    this.props.setNavItem(initTab as string, initFeature as SysFeatureEnums);
                }
                this.props.featureReady();
                logger.log(` **** main.changeEnvironment:  services already loaded`);
            }
        }
        catch(err) {
            const p = err as Promise<FetchError>;
            const fError = err as FetchError;
            if (fError.message) {
                console.log(`error: ${fError.message}`);
                this.props.applySettings('serverError');
            } else {
                p.then((err) => {
                    console.log(`updateSystemData: fetch failed: ${err}`);
                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification();
                    if (!systemState.projectFeaturesLoaded) {
                        this.props.setProjectFeaturesAttributes(({} as ZProductFeatureAttributes));
                    }
                    if (!systemState.uriCodesLoaded) {
                        this.props.setProjURICodes([])
                    }
                    this.props.setProjectServices(projectId, [], env, serviceId);
                    this.props.applySettings('serverError');
                })
                .catch(() => {
                    this.props.setProjectFeaturesAttributes(({} as ZProductFeatureAttributes));
                    this.props.setProjectServices(projectId, [], env, serviceId);
                    this.props.applySettings('serverError');
                })
            }
        }

        this.props.dataGroupLoad(false, 'main');
        // logger.log('%c--> updateSystemData: turning off group data load', 'background: cyan; color: black');
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }

    private saveSessionState = (): void => {
        let url = window.location.href;
        const tabIdx = url.lastIndexOf('/')
        url = url.substring(tabIdx);

        const systemState = this.props.systemNavProjectState;
        const project = systemState._currentProject;
        const feature = systemState.currentFeature;
        const tab = systemState.navItem;

        let serviceId = '';
        let projectId = '';
        let environment = '';
        if (systemState._projectList.length > 0) {
            serviceId = getCurrentServiceId(systemState);
            projectId = project.project_id;
            environment = project.currentServiceEnvironment;
        }

        const sessionState: ZSessionStorage = { feature, tab, url, projectId, serviceId, environment }

        if (systemState.currentFeature !== SysFeatureEnums.logout) {
            setSessionStorage(sessionState)
        }
        this.props.sessionStateSaved();
        
        logger.log(` ***** main (saveSessionState) sessionState: ${JSON.stringify(sessionState)}`);
    }

    public render(): JSX.Element {
        const systemState = this.props.systemNavProjectState;

        if (this.props.authNServerData.isLoggedIn !== loginState.loggedIn) {
            // console.log('we are not logged in anymore.')
            if (systemState.redirectUrl.length > 0) {
                // history.push(ZURLS.login);
                return <Redirect to={ZURLS.login}  push={true} />
            } else {
                return <Redirect to={ZURLS.login} push={true}/>
            }
        }

        if ( systemState.redirectUrl && systemState.redirectUrl.length > 0) {
            return <Redirect to={systemState.redirectUrl} />
        } else if (systemState.redirectUrl) {
            logger.alert('redirect URL is undefined.')
        }
        if (! systemState.projectsLoaded) {
            return <div></div>;
        }
        const notification = systemState.notificationData;

        // const closeIcon = false ?
        //     (<div className="nav-closer-ctrl"><FontAwesomeIcon icon={['fas', 'angle-left']} /></div>) :
        //     '';

        // const dashboardNav = false ?
        //     (<ZNavItem icon={'dot-circle' as IconName} itemName={NStrs.dashboard}
        //         navUrl={ZURLS.dashboard} feature={SysFeatureEnums.dashboard}
        //         selectedFeature={selectedFeature} 
        //         handler={ (feature: SysFeatureEnums) => {this.props.setNavItem(NStrs.dashboard, feature)}} />) :
        //     '';

        const topBar = this.buildTopBar();  
        const navbar = this.buildNavBar();
        const burger = this.buildHamburger();
        const expiringPwd = this.handleExpiringPwd();
        
        // logger.log(`main.render: Location: ${window.location.href}`)
        const markup = (
            <Router>
            {burger}
            <div id="outerContainer" key="app" className="App">
                {topBar}

                <div className="znav-panel">
                    {navbar}
                    <div key="logo" className="logo">
                        <img className="logo" src={logo} title="logo" alt="logo" />
                    </div>
                </div>

                <div className="main">
                    <div className="mainContainer">
                        <NotificationControl key="note" show={systemState.notificationData.show} 
                                style={notification.style} closeHandler={(): void => {this.handleCloseNotification()}} 
                                message={notification.message}
                        />
                        <div className="routes">
                            <Switch>
                                <Route path={ZURLS.analytics} render={(): JSX.Element=> (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.dnsDirector} render={(): JSX.Element=> (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.cloudSvcMonitor} render={(): JSX.Element=> (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.traffic} render={(): JSX.Element => (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.efr} render={(): JSX.Element => (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.nrum} render={(): JSX.Element => (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.cacheAnalytics} render={(): JSX.Element => (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.cssWaf} render={(): JSX.Element => (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.cssBot} render={(): JSX.Element => (<Analytics {...this.props} />)} />
                                <Route path={ZURLS.logs} render={(): JSX.Element => (<Analytics {...this.props} />)} />
                                
                                <Route path={ZURLS.account} render={(): JSX.Element => (<Account {...this.props} />)} />
                                <Route path={ZURLS.updateProfile} render={(): JSX.Element => (<Account {...this.props} />)} />
                                <Route path={ZURLS.changePwd} render={(): JSX.Element => (<Account {...this.props} />)} />
                                <Route path={ZURLS.documentation} render={(): JSX.Element => (<Account {...this.props} />)} />
                                <Route path={ZURLS.viewUsersApiKeys} render={(): JSX.Element => (<Account {...this.props} />)} />
                                
                                <Route path={ZURLS.admin} render={(): JSX.Element => (<Admin {...this.props} />)} />

                                <Route path={ZURLS.organizations} render={(): JSX.Element => (<Organizations {...this.props} />)} />
                                <Route path={ZURLS.CSALite} render={(): JSX.Element => (<CSALiteConfig {...this.props} />)} />

                            { /* <Route path={ZURLS.service} render={() => (<Services {...this.props} />)} */ }
                                <Route path={ZURLS.cache} render={(): JSX.Element => (<Cache  {...this.props} />)} />
                                <Route path={ZURLS.reports} render={(): JSX.Element => (<Reports {...this.props} />)} />
                                <Route path={ZURLS.logout} render={(): JSX.Element => (<Logout {...this.props} />)} />
                        
                                <Route path={ZURLS.billingReports} render={(): JSX.Element => (<BillingReports {...this.props} />)} />

                                BillingReports
                                <Route path={ZURLS.svcMgmt} render={(): JSX.Element => 
                                                                        (<ServiceManagement {...this.props} />)} />
                                <Route path="/*" component={UnknownAction} />
                            </Switch>
                        </div>
                    </div>

                </div>
                {expiringPwd}
            </div>
            </Router>
        );

        return markup;
    }

    private handleCloseNotification(): void {
        this.props.closeNotification();
    }

    private handleExpiringPwd = (): JSX.Element => {
        const authState = this.props.authNServerData;
        // const user = authState.user as ZUserMin;

        const expiryInfo: pwdExpiryUtils = authState.pwdExpiryInfo;
        if (expiryInfo.isExpiring) {
            if (!authState.popupOpen) {
                this.props.controlModalDlg(SysFeatureEnums.pwdExpiring, true, ZFeaturePopupTypes.ExpiringPassword);
            }
        }

        let expiringDlg = <div></div>;
        if (authState.popupOpen && authState.popupType === ZFeaturePopupTypes.ExpiringPassword) {
            const expireMsg = expiryInfo.timeString;
            expiringDlg = (<div>
                    <GenDialog
                    show={authState.popupOpen}
                        title={AcctStrs.expiringPwdTitle} 
                        msg={expireMsg}
                        css="zmodel-33"
                        applyBtnTxt={AcctStrs.updatePassword}
                        cancelBtnTxt={AcctStrs.doItLater}
                        disableApply={(): boolean => {return false}}
                        onHide={(): void => {
                            this.props.controlModalDlg(SysFeatureEnums.pwdExpiring, false, ZFeaturePopupTypes.ExpiringPassword)}}
                        handleApply={(): void => {
                            this.props.controlModalDlg(SysFeatureEnums.pwdExpiring, false, ZFeaturePopupTypes.ExpiringPasswordUpdate);
                            this.props.setNavItem(NStrs.account, SysFeatureEnums.accountChgPwd);
                            this.props.redirectTo(ZURLS.changePwd);
                        }}>
                            <br />
                            {ACCOUNT_STRINGS.passwordExiringQuestion}
                    </GenDialog>
                </div>);
        }

        return expiringDlg;
    }

    private buildTopBar = (): JSX.Element => {
        const authData = this.props.authNServerData
        const companyLogo = getProjectLogo(this.props.systemNavProjectState, authData.user as ZUserMin, authData.currentOrg );
        const featureName: string = this.props.systemNavProjectState.navItem;
        
        return (
            <div className="top-bar">
                <div><img className="logo" src={zlogo} title="logo" alt="logo" /></div>
                <div className="title">{featureName}</div>
                <div className="project-logo">
                {companyLogo}
                </div>
                <div></div>
            </div>
        );
    }

    private buildHamburger = (): JSX.Element => {
        const authState = this.props.authNServerData;
        const user = authState.user as ZUserMin;
        const userType: ZUserTypes = user.role;
        const hasOrg = authState.currentOrg.org_id !== undefined;
        let hasProjects = false;

        if (hasOrg) {
            hasProjects = this.props.systemNavProjectState._projectList.length > 0;
        }

        // tslint:disable-next-line: variable-name
        const ZBurgerItem = (label: string, bIcon: string, itemName: string, fName: SysFeatureEnums, url: string): 
                            JSX.Element => {
            if (!url) {
                logger.alert('undefined URL for burger menu.')
            }
            return (
            <div key={itemName} id={itemName} className="menu-item" onClick={(): void => {
                this.props.setNavItem(itemName, fName);
                this.props.redirectTo(url);
                }}>
                <div className="zburger-item">
                    <div><FontAwesomeIcon icon={['fas', bIcon as IconName]} /></div><div>{toTitleCase(label)}</div>
                </div>
            </div>
            );
        }

        const orgMarkup = (hasOrg) ? <div>{ZBurgerItem(BStrs.organizations, 'sitemap', NStrs.organizations, SysFeatureEnums.orgYourOrgs, ZURLS.organizations)} </div> : <div></div>;
        const bottomItems = (
            <div className="hamburger-bottom-items">
                <div className="hamburger-email">{user.email}</div>
                <div></div>
                {orgMarkup}
            </div>
        )

        const items = [];
        if (hasOrg && hasProjects) {
            items.push(ZBurgerItem(BStrs.analytics, 'chart-line', NStrs.analytics, SysFeatureEnums.analytics, 
                               ZURLS.analytics));
        }

        if (hasProjects) {
            items.push(ZBurgerItem(BStrs.cache, 'cloud', NStrs.cache, SysFeatureEnums.cache, ZURLS.cache));
            items.push(ZBurgerItem(BStrs.svcMgmt, 'cogs', NStrs.svcMgmt, SysFeatureEnums.viewServices, ZURLS.svcMgmt));
        }
        
        if (userType !== ZUserTypes.user && this.props.authNServerData.serverConfig.automatEnabled) {
            const feature = (userType === ZUserTypes.dev) ? SysFeatureEnums.certAdmin : SysFeatureEnums.usersAdmin;
            items.push(ZBurgerItem(BStrs.admin, 'users-cog', NStrs.admin, feature, ZURLS.admin));
        }

        if (orgAccessToCSAFeatures(authState, authState.currentOrg.org_id) && hasProjects) {
            items.push(ZBurgerItem(BStrs.CSALite, 'tachometer-alt', NStrs.csa, SysFeatureEnums.csaLiteConfig, ZURLS.CSALite));
        }

        if (orgAccessToReports(authState, authState.currentOrg.org_id) && 
            ((userType === ZUserTypes.admin || userType === ZUserTypes.zadmin) && hasProjects)) {
            items.push(ZBurgerItem(BStrs.billingReports, 'file-alt', NStrs.billingReports, SysFeatureEnums.billingReportsTrafficUsage, ZURLS.billingReports));
        }

        items.push(ZBurgerItem(BStrs.account, 'sliders-h', NStrs.account, SysFeatureEnums.accountUpdateProfile, 
                               ZURLS.account));        
        items.push(ZBurgerItem(BStrs.logout, 'sign-out-alt', NStrs.logout, SysFeatureEnums.logout, ZURLS.logout));

        const burger = (<>
            <Menu right={true} isOpen={this.props.systemNavProjectState.hamburgerOpen} 
                outerContainerId={'outerContainer'}
                onStateChange={ (isOpen ): void => { this.props.setHamburgerOpen(isOpen.isOpen); } }>
                {items}
                {bottomItems}
            </Menu>
        </>)

        return burger;
    }

    private buildNavBar(): JSX.Element {

        const selectedFeature = this.props.systemNavProjectState.currentFeature;

        const analytics = this.buildAnalyticsNav(selectedFeature)
        const admin = this.buildAdminNav(selectedFeature);
        const account = this.buildAccountNav(selectedFeature);
        const svcMgnt = this.buildSvcMgntNav(selectedFeature);
        const cache = this.buildCachePurgeNav(selectedFeature);
        const org = this.buildOrgNav(selectedFeature);
        const csa = this.buildCSANavBar(selectedFeature);
        const billing = this.buildBillingReportNav(selectedFeature)
        return (
            <div className="nav-btn-panel">
                {analytics}
                {cache}
                {admin}
                {svcMgnt}
                {account}
                {org}
                {csa}
                {billing}
            </div>
        )
    }

    private buildProjectDropdown(projects: string[]): JSX.Element {
        let projectNameDropdown: JSX.Element;
        const enabled = (this.props.systemNavProjectState.loadService !== serviceDisplayEnum.none);

        if (enabled) {
            // if (projects.length > 1) {
                const currentProjs: string[] = projects;
                const index = getCurrentProjectIndex(this.props.systemNavProjectState);

                projectNameDropdown = ZDropDown(
                    currentProjs,
                    index,
                    'project-selection',
                    (idx) => { this.handleProjectSelect(idx) },
                    { bIgnoreLabelIfNotAll: false, label: MISC_STRINGS.project, 
                      maxButtonTextLength: NAV_PANEL_DDOWN_MAX_TITLE_LEN });
        } else {
            projectNameDropdown = ZDropDown(
                [MISC_STRINGS.notApplicable],
                0,
                'project-selection',
                () => { logger.log('should never happen') },
                { bIgnoreLabelIfNotAll: true });
        }

        return projectNameDropdown;
    }

    private buildEnvControl(): JSX.Element {
        const env = this.props.systemNavProjectState._currentProject.currentServiceEnvironment;
        const systemNavProjectState = this.props.systemNavProjectState;

        let enabled = (systemNavProjectState.loadService !== serviceDisplayEnum.none) && 
                      (this.props.systemNavProjectState._projectList.length > 0);
        // TODO:   HACK to hide environment during edit service and deploy service;
        enabled = enabled && !(systemNavProjectState.currentFeature === SysFeatureEnums.editDraftService ||
                               systemNavProjectState.currentFeature === SysFeatureEnums.deployService ||
                               systemNavProjectState.currentFeature === SysFeatureEnums.deleteService);
        let envDropdown: JSX.Element = <div></div>;

        if (enabled) {
            envDropdown = ZDropDown(
                [MISC_STRINGS.prod, MISC_STRINGS.staging],
                (env === 'prod') ? 0 : 1,
                'env-selection',
                (idx) => { this.handleEnvSelect(idx) },
                { bIgnoreLabelIfNotAll: false, label: MISC_STRINGS.environment });
        }

        return envDropdown;
    }
    
    private buildServiceDropdown(services: string[]): JSX.Element {
        const currentFeature = this.props.systemNavProjectState.currentFeature;
        let serviceNameDropdown: JSX.Element;
        const features = enumToFeatureMapping;
        
        // logger.log('buildServiceDropdown', services);
        // logger.log(`services length = ${services.length}`)
        if (services.length > 0 && features[currentFeature] && features[currentFeature].loadService !== serviceDisplayEnum.none &&
            features[currentFeature].hideServiceDropdown !== true) {
            serviceNameDropdown = ZDropDown(
                services,
                this.props.systemNavProjectState.serviceEvtIdx,
                'svcEvt-selection',
                (idx) => { this.handleServiceEvtSelect(idx) },
                { bIgnoreLabelIfNotAll: false, label: MISC_STRINGS.servicesLabel, 
                  maxButtonTextLength:  NAV_PANEL_DDOWN_MAX_TITLE_LEN});
        } else {
            serviceNameDropdown = <div></div>;
        }

        return serviceNameDropdown;
    }

    private accordianCSSOpenClose = (category: string): string => {
        const selectedFeature = this.props.systemNavProjectState.currentFeature;
        return (selectedFeature.indexOf(category) !== -1) ? 'accordian-content-open' : 'accordian-content-closed';
    }

    private handleEnvSelect(envIdx: number): ZEnvironments {
        const env = (envIdx === 0) ? ZEnvironments.prod : ZEnvironments.staging;
        this.props.changeServiceEnvironment(env);
        return env;
    }

    private handleServiceEvtSelect(index: number): void {
        this.props.setCurrentServiceEvtIdx(index);
    }

    private handleProjectSelect(index: number): void {
        // logger.log(['handleChangeProject: project before change', this.props.systemNavProjectState]);
        const project: ZProjectMin = getProjectByIndex(this.props.systemNavProjectState, index);

        if (this.props.systemNavProjectState._currentProject.projectId !== project.project_id) {
            if (project.name !== undefined) {
                this.props.changeProjects(project.project_id, ZEnvironments.unknown);
            } else {
                this.props.redirectTo(ZURLS.home);
            }
        }
    }

    private buildMgmtPanel = (): JSX.Element => {

        const projects = getProjectList(this.props.systemNavProjectState);
        const uiState = this.props.systemNavProjectState;
        const servicesNames = getServiceEventList(uiState);
        const projectNameDropdown: JSX.Element = this.buildProjectDropdown(projects);
        const serviceNameDropdown: JSX.Element = this.buildServiceDropdown(servicesNames);
        const snDropdownCSS = (servicesNames.length > 0 && uiState.currentFeature !== SysFeatureEnums.deleteService) ?
                                 'show_control' : 'hide_control';

        // TODO:   HACK to hide environment during edit service and deploy service;
        const envDropdownCSS = !(uiState.currentFeature === SysFeatureEnums.editDraftService ||
            uiState.currentFeature === SysFeatureEnums.deployService)  ? 
            'nav-dropdown' : '';
        const env = this.buildEnvControl();

        return (
            <div id="mgmt-ddown-panel">
                <div className="nav-dropdown">{projectNameDropdown}</div> 
                <div className={envDropdownCSS}>{env}</div>
                <div className={'service-dropdown ' + snDropdownCSS}>
                    {serviceNameDropdown}
                </div>
            </div>
        )
    }

    /*
     * CSALite nav stuff
     */ 
    private buildCSANavBar( selectedFeature: SysFeatureEnums ): JSX.Element {
        if (selectedFeature !== SysFeatureEnums.csaLiteConfig) {
            return (<div></div>);
        }

        // csaLiteConfig = 'csaLiteConfig',
        const mgmtPanel = this.buildMgmtPanel();
        const csa = (
            <>
            {mgmtPanel}
            <div className="nav-item-container">
                <ZNavItem icon={'tachometer-alt' as IconName} itemName={NStrs.csa}
                    navUrl={ZURLS.CSALite} 
                    selectedFeature={selectedFeature} feature={SysFeatureEnums.csaLiteConfig}
                    handler={(feature: SysFeatureEnums): void => { 
                        this.props.setNavItem(NStrs.csa, feature)}} />
            </div>
            </>
        );

        return csa;
    }

    /*
     *  Analytics nav stuff
     */
    private buildAnalyticsNav( selectedFeature: SysFeatureEnums ): JSX.Element {
        // the list of features that are under the analytics tab
        const analyticSubTabs: SysFeatureEnums[] = [
            SysFeatureEnums.dnsDirAnl, SysFeatureEnums.csmAnl, SysFeatureEnums.csaAnl,
            SysFeatureEnums.trafficAnl, SysFeatureEnums.cacheAnl,  SysFeatureEnums.efrAnl, 
            SysFeatureEnums.logsAnl, SysFeatureEnums.wafAnl, SysFeatureEnums.botAnl,
        ];
        if (!(selectedFeature === SysFeatureEnums.analytics || selectedFeature === SysFeatureEnums.reports || 
              analyticSubTabs.find(feature => feature === selectedFeature))) {
            return (<div></div>);
        }

        const hasProjects = this.props.systemNavProjectState._projectList.length > 0;
        const features = getProjectFeatures(this.props.systemNavProjectState);
        const dispFeatures: ProjDTypeFeatures = getProjectDspFeatures(this.props.systemNavProjectState);

        const mgmtPanel = hasProjects ? this.buildMgmtPanel() : <div></div>;

        const analyticsSubNav = this.buildAnalyticsSubNav(features, dispFeatures, selectedFeature);
        const analyticsSubnavCss = this.accordianCSSOpenClose('analy');

        const reporting = hasProjects ? (
            <div className="nav-item-container">
                <ZNavItem icon={'file' as IconName} itemName={NStrs.reports}
                    navUrl={ZURLS.reports}
                    feature={SysFeatureEnums.reports}
                    selectedFeature={selectedFeature}
                    handler={(feature: SysFeatureEnums): void => { this.props.setNavItem(NStrs.reports, feature) }} 
                />
            </div>
        ) : <div></div>;

        return (
            <>
                {mgmtPanel}
                <div className="nav-item-container">
                    <ZNavItem icon={'chart-line' as IconName} itemName={NStrs.analytics} 
                        spkey="navAnalytics"
                        navUrl={ZURLS.analytics} 
                        feature={SysFeatureEnums.analytics}
                        featureSet={analyticSubTabs}
                        selectedFeature={selectedFeature}
                        handler={(feature: SysFeatureEnums): void => {
                            this.props.setNavItem(NStrs.analytics, feature)} }
                        />
                    <div  className={analyticsSubnavCss}>
                        {analyticsSubNav}
                    </div>
                </div>
                {reporting}
            </>
        )
    }

    private buildAnalyticsSubNav(features: ZServiceFeatures, dispFeatures: ProjDTypeFeatures, 
                                 selectedFeature: SysFeatureEnums): JSX.Element[] {
        const subNav: JSX.Element[] = [];
        const systemState = this.props.systemNavProjectState;
        const hasDeployedServices = ((systemState._projectList.length > 0) && (systemState._currentProject.servicesList.length > 0));
        const contentFeatureSet: SysFeatureEnums[] = [ SysFeatureEnums.trafficAnl, SysFeatureEnums.cacheAnl ];
        const CSSFeatureSet: SysFeatureEnums[] = [ SysFeatureEnums.wafAnl, SysFeatureEnums.botAnl ];

        const buildSubNav = ((label: string, feature: SysFeatureEnums, urlName: string, featureSet?: SysFeatureEnums[]): JSX.Element => {
            return (
                <ZNavItem icon={'minus' as IconName} itemName={label} feature={feature} navUrl={ZURLS[urlName]}
                          selectedFeature={selectedFeature} key={feature}  featureSet={featureSet} hideOpenIcon={true}
                          handler={(featureEnum: SysFeatureEnums): void => {
                                    this.props.setNavItem(NStrs.analytics, featureEnum); }}/>);
        });

        const buildSubSubNav = (label: string, feature: SysFeatureEnums, urlName: string, key: string): JSX.Element => {
            const myKey = key === undefined ? feature : key;
            return (
                <div className="pad-left-30" key={'subsubnav-'+key}>
                    <ZNavItem icon={'square' as IconName} itemName={label} feature={feature} navUrl={ZURLS[urlName]}
                          selectedFeature={selectedFeature} key={myKey} iconSize="xs"
                          handler={(featureEnum: SysFeatureEnums): void => {
                                    this.props.setNavItem(NStrs.analytics, featureEnum); }}/>
                </div>
            );
        }

        // if the selected feature is SysFeatureEnums.analytics, this means the user clicked the top level 'Analytics' menu item.
        // In that case we want to selected the first item in the analytics nav list.  Unfortunately there are 3 choices,
        // 'content delivery', dnsDir and CSM.  The following code identifies which of those 3 to choose.
        if (selectedFeature === SysFeatureEnums.analytics) {
            selectedFeature = SysFeatureEnums.trafficAnl;
            if (features.dnsDir && features.dnsDir.enabled) {
                selectedFeature = SysFeatureEnums.dnsDirAnl;
            } else if (features.csm && features.csm.enabled) {
                selectedFeature = SysFeatureEnums.csmAnl;
            }
        }

        if (hasDeployedServices) {
            if (features.dnsDir && features.dnsDir.enabled) {
                subNav.push(buildSubNav(SUBNAVSTRINGS.dnsDirector, SysFeatureEnums.dnsDirAnl, 'dnsDirector'));
            }
            if (features.csm && features.csm.enabled) {
                subNav.push(buildSubNav(SUBNAVSTRINGS.cloudSvcMonitor, SysFeatureEnums.csmAnl, 'cloudSvcMonitor'));
            }
            if (features.csa && features.csa.enabled) {
                subNav.push(buildSubNav(SUBNAVSTRINGS.cloudSvcAccelerator, SysFeatureEnums.csaAnl, 'cloudSvcAccelerator'));
            }
        }

        subNav.push(buildSubNav(SUBNAVSTRINGS.contentDelivery, SysFeatureEnums.trafficAnl, 'traffic', contentFeatureSet));
        subNav.push(buildSubSubNav(SUBNAVSTRINGS.traffic, SysFeatureEnums.trafficAnl, 'traffic', 'traffic1'));
        if (hasDeployedServices) {
            if (dispFeatures.cacheAnalyticsVisible) {
                subNav.push(buildSubSubNav(SUBNAVSTRINGS.cache, SysFeatureEnums.cacheAnl, 'cacheAnalytics', SysFeatureEnums.cacheAnl));
            }
            if (features.network_real_user_metrics && features.network_real_user_metrics.enabled) {
                subNav.push(buildSubNav(SUBNAVSTRINGS.nrum, SysFeatureEnums.nrumAnl, 'nrum'));
            }
            if (features.error_free_response && features.error_free_response.enabled) {
                subNav.push(buildSubNav(SUBNAVSTRINGS.efr, SysFeatureEnums.efrAnl, 'efr'));
            }
            if (features.waf && features.waf.enabled) {
                subNav.push(buildSubNav(SUBNAVSTRINGS.cloudServiceShield, SysFeatureEnums.wafAnl, 'waf', CSSFeatureSet));
                subNav.push(buildSubSubNav(SUBNAVSTRINGS.cssWaf, SysFeatureEnums.wafAnl, 'cssWaf', 'waf1'));
                subNav.push(buildSubSubNav(SUBNAVSTRINGS.cssBots, SysFeatureEnums.botAnl, 'cssBot', 'bot'));
            }
            if (dispFeatures.loggingVisible) {
                subNav.push(buildSubNav(SUBNAVSTRINGS.logs, SysFeatureEnums.logsAnl, 'logs'));
            }
        }
        
        return subNav;
    }
    
    /*
     *  Cache nav stuff
     */
    private buildCachePurgeNav( selectedFeature: SysFeatureEnums ): JSX.Element {
        if (selectedFeature !== SysFeatureEnums.cache) {
            return <div></div>;
        }
        const mgmtPanel = this.buildMgmtPanel();
        const caching = (
            <>
            {mgmtPanel}
            <div className="nav-item-container">
                <ZNavItem icon={'cloud' as IconName} itemName={NStrs.cache}
                    navUrl={ZURLS.cache} 
                    selectedFeature={selectedFeature} feature={SysFeatureEnums.cache}
                    handler={(feature: SysFeatureEnums): void => { 
                        this.props.setNavItem(NStrs.cache, feature)}} />
            </div>
            </>
        );

        return caching
    }

    /*
     *  System Administration nav stuff
     */
    private buildAdminNav(selectedFeature: SysFeatureEnums): JSX.Element {
        const adminSubTabs: SysFeatureEnums[] = [
            SysFeatureEnums.usersAdmin, SysFeatureEnums.projectsAdmin, SysFeatureEnums.apiKeyAdmin, SysFeatureEnums.certAdmin
        ];
        if (!(selectedFeature === SysFeatureEnums.admin || adminSubTabs.find(feature => feature === selectedFeature))) {
            return (<div></div>);
        }

        const user = this.props.authNServerData.user as ZUserMin;
        const userType: ZUserTypes = user.role;
        const adminSubNav = this.buildAdminSubNav(userType, selectedFeature);
        const adminSubnavCss = this.accordianCSSOpenClose('admin')
        const subNavFeature = (userType === ZUserTypes.dev) ? SysFeatureEnums.certAdmin : SysFeatureEnums.usersAdmin;

        const admin = (userType !== ZUserTypes.user) ? (
            <>
            <div className="nav-spacer"></div>
            <div className="nav-item-container">
                <ZNavItem icon={'users-cog' as IconName} itemName={NStrs.admin}
                        navUrl={ZURLS.admin}
                        feature={subNavFeature}
                        featureSet={adminSubTabs}  hideOpenIcon={true}
                        selectedFeature={selectedFeature}
                        handler={(feature: SysFeatureEnums): void => { this.props.setNavItem(NStrs.admin, feature)}} 
                />
                <div  className={adminSubnavCss}>
                    {adminSubNav}
                </div>
            </div>
            </>
        ) : <span></span>;

        return admin;
    }

    private buildAdminSubNav(userType: ZUserTypes, selectedFeature: SysFeatureEnums): JSX.Element | JSX.Element[] {
        const subNav = [];

        if (userType === ZUserTypes.user) {
            return <span></span>;
        }
        
        const buildSubNav = ((label: string, feature: SysFeatureEnums, urlName: string): JSX.Element => {
            return (
                <ZNavItem icon={'minus' as IconName} itemName={label} feature={feature} navUrl={ZURLS[urlName]}
                                 selectedFeature={selectedFeature} key={feature}
                                 handler={(featureEnum: SysFeatureEnums): void => {
                                          this.props.setNavItem(NStrs.admin, featureEnum); }}/>);
        });

        // zadmin and admin get everything.
        if (userType !== ZUserTypes.dev) {
            subNav.push(buildSubNav(SUBNAVSTRINGS.users, SysFeatureEnums.usersAdmin, 'admin'));
            subNav.push(buildSubNav(SUBNAVSTRINGS.projects, SysFeatureEnums.projectsAdmin, 'admin'));
            subNav.push(buildSubNav(SUBNAVSTRINGS.apiKeys, SysFeatureEnums.apiKeyAdmin, 'admin'));
        }
        subNav.push(buildSubNav(SUBNAVSTRINGS.certs, SysFeatureEnums.certAdmin, 'admin'));

        return subNav;
    }

    /*
     *  Service management nav stuff
     */
    private buildSvcMgntNav(selectedFeature: SysFeatureEnums): JSX.Element {
        const svcMgmtSubTabs: SysFeatureEnums[] = [
            SysFeatureEnums.viewServices, SysFeatureEnums.editDraftService, SysFeatureEnums.deployService
        ];
        const svcMgmtSubTabsZAdmin: SysFeatureEnums[] = [
            SysFeatureEnums.viewServices, SysFeatureEnums.editDraftService, SysFeatureEnums.deployService, SysFeatureEnums.deleteService
        ];

        const mgmtPanel = this.buildMgmtPanel();
        const user = this.props.authNServerData.user as ZUserMin;
        const orgId = this.props.authNServerData.currentOrg.org_id;
        const userType: ZUserTypes = user.role;
        const viewFullFeature = (user.role === ZUserTypes.zadmin) || 
                                (orgAccessToNewFeatures(this.props.authNServerData, orgId) && (userType !== ZUserTypes.user));

        const featureSet = viewFullFeature ? svcMgmtSubTabsZAdmin : svcMgmtSubTabs ;

        if (!(selectedFeature === SysFeatureEnums.svcMgmt || featureSet.find(feature => feature === selectedFeature))) {
            return (<div></div>);
        }

        const svcMgmtSubNav = this.buildSvcMgmtSubNav(userType, selectedFeature, viewFullFeature);

        const svcMgmtSubnavCss = this.accordianCSSOpenClose('svcMgmt');

        const button = (viewFullFeature) ? (
            <div id="nav-action-button">
                <ZButton onClick={(): void => { 
                    this.props.setNavItem(NStrs.svcMgmt,  SysFeatureEnums.createService); }} >
                {MISC_STRINGS.createService}
                </ZButton>
            </div>
        ) : <div></div>;

        const svcMgtMarkup = (
            <>
            { mgmtPanel }
            <div className="nav-item-container">
                <ZNavItem icon={'cogs' as IconName} itemName={NStrs.svcMgmt}
                        navUrl={ZURLS.svcMgmt}
                        feature={SysFeatureEnums.viewServices}
                        featureSet={featureSet}  hideOpenIcon={true}
                        selectedFeature={selectedFeature}
                        handler={(feature: SysFeatureEnums): void => { 
                            setTimeout(() => { 
                                this.props.setNavItem(NStrs.svcMgmt, feature);
                            });
                        }}
                />
                <div  className={svcMgmtSubnavCss}>
                    {svcMgmtSubNav}
                </div>
                {button}
            </div>
            </>
        );

        return svcMgtMarkup;
    }

    private buildSvcMgmtSubNav(userType: ZUserTypes, selectedFeature: SysFeatureEnums, viewFullFeature: boolean): JSX.Element[] {
        const subNav = [];
        
        const buildSubNav = ((label: string, feature: SysFeatureEnums, urlName: string): JSX.Element => {
            // console.log(`urlname is ${urlName}; url is ${ZURLS[urlName]}`);
            return (
                <ZNavItem icon={'minus' as IconName} itemName={label} feature={feature} navUrl={ZURLS[urlName]}
                                 selectedFeature={selectedFeature} key={feature}
                                 handler={(featureEnum: SysFeatureEnums): void => {
                                          this.props.setNavItem(NStrs.svcMgmt, featureEnum); }}/>);
        });

        // zadmin and admin get everything.
        subNav.push(buildSubNav(SUBNAVSTRINGS.viewServices, SysFeatureEnums.viewServices, 'svcMgmt'));

        if (viewFullFeature) {
            subNav.push(buildSubNav(SUBNAVSTRINGS.editDraftService, SysFeatureEnums.editDraftService, 'svcMgmt'));
            subNav.push(buildSubNav(SUBNAVSTRINGS.deployService, SysFeatureEnums.deployService, 'svcMgmt'));
            subNav.push(buildSubNav(SUBNAVSTRINGS.deleteService, SysFeatureEnums.deleteService, 'svcMgmt'))
        }

        return subNav;
    }

    /*
     *  Account management nav stuff
     */
    private buildAccountNav( selectedFeature: SysFeatureEnums ): JSX.Element {
        const accountSubTabs: SysFeatureEnums[] = [
            SysFeatureEnums.accountUpdateProfile, SysFeatureEnums.accountChgPwd, SysFeatureEnums.apiKeys, 
            SysFeatureEnums.tfaMgmt
        ]
        if (!(selectedFeature === SysFeatureEnums.account || accountSubTabs.find(feature => feature === selectedFeature))) {
            return (<div></div>);
        }
        const accountSubNav = this.buildAccountSubNav(selectedFeature);
        const accountSubnavCss = this.accordianCSSOpenClose('account');

        return (
            <>
            <div className="nav-spacer"></div>
            <div className="nav-item-container">
                <ZNavItem icon={'sliders-h' as IconName} itemName={NStrs.account}
                    navUrl={ZURLS.account} feature={SysFeatureEnums.accountUpdateProfile}
                    featureSet={accountSubTabs}
                    selectedFeature={selectedFeature} hideOpenIcon={true}
                    handler={(feature: SysFeatureEnums): void => { 
                        this.props.setNavItem(NStrs.account, feature)}} />
                <div  className={accountSubnavCss}>
                    {accountSubNav}
                </div>
            </div>
            </>
        )
    }

    private buildAccountSubNav(selectedFeature: SysFeatureEnums): JSX.Element[] {
        const subNav = [];
        const user = this.props.authNServerData.user;
        const buildSubNav = ((label: string, feature: SysFeatureEnums, urlName: string): JSX.Element => {
        return (
            <ZNavItem icon={'minus' as IconName} itemName={label} feature={feature} navUrl={ZURLS[urlName]}
                    selectedFeature={selectedFeature} key={feature}
                    handler={(featureEnum: SysFeatureEnums): void => {
                        this.props.setNavItem(NStrs.account, featureEnum); }}/>);
        });
    
        subNav.push(buildSubNav(SUBNAVSTRINGS.viewEditProfile, SysFeatureEnums.accountUpdateProfile, 'updateProfile'));
        subNav.push(buildSubNav(SUBNAVSTRINGS.changePwd, SysFeatureEnums.accountChgPwd, 'changePwd'));
        if (user && user.role !== ZUserTypes.user) {
            subNav.push(buildSubNav(SUBNAVSTRINGS.apiKeys, SysFeatureEnums.apiKeys, 'viewUsersApiKeys'));
        }
        subNav.push(buildSubNav(SUBNAVSTRINGS.tfa, SysFeatureEnums.tfaMgmt, 'TFAMgmt'));
        // subNav.push(buildSubNav(SUBNAVSTRINGS.documentation, SysFeatureEnums.documentation, 'documentation'));

        return subNav;
    }

    /*
     *  Organization nav stuff
     */
    private buildOrgNav( selectedFeature: SysFeatureEnums ): JSX.Element {
        const orgSubTabs: SysFeatureEnums[] = [
            SysFeatureEnums.orgYourOrgs,
            SysFeatureEnums.orgManageOrgs,
        ]
        if (!(selectedFeature === SysFeatureEnums.orgManageOrgs || orgSubTabs.find(feature => feature === selectedFeature))) {
            return <div></div>;
        }

        const orgSubNav = this.builOrganizationSubNav(selectedFeature);
        const orgSubnavCss = this.accordianCSSOpenClose('org');

        return (
            <>
            <div className="nav-spacer"></div>
            <div className="nav-item-container">
                <ZNavItem icon={'sitemap' as IconName} itemName={NStrs.organizations}
                    navUrl={ZURLS.organizations} feature={SysFeatureEnums.orgYourOrgs}
                    featureSet={orgSubTabs}
                    selectedFeature={selectedFeature} hideOpenIcon={true}
                    handler={(feature: SysFeatureEnums): void => { 
                        this.props.setNavItem(NStrs.organizations, feature)}} />
                <div  className={orgSubnavCss}>
                    {orgSubNav}
                </div>
            </div>
            </>
        );
    }

    private builOrganizationSubNav(selectedFeature: SysFeatureEnums): JSX.Element[] {
        const subNav = [];
        const buildSubNav = ((label: string, feature: SysFeatureEnums, urlName: string): JSX.Element => {
            return (
                <ZNavItem icon={'minus' as IconName} itemName={label} feature={feature} navUrl={ZURLS[urlName]}
                        selectedFeature={selectedFeature} key={feature}
                        handler={(featureEnum: SysFeatureEnums): void => {
                            this.props.setNavItem(NStrs.organizations, featureEnum); }}/>);
        });
    
        subNav.push(buildSubNav(SUBNAVSTRINGS.yourOrganizations, SysFeatureEnums.orgYourOrgs, 'organizations'));

        const user = this.props.authNServerData.user as ZUserMin;
        if (user.role === ZUserTypes.zadmin) {
        // if (user.role === '' as ZUserTypes) {
            subNav.push(buildSubNav(SUBNAVSTRINGS.manageOrganizations, SysFeatureEnums.orgManageOrgs, 'organizations'));
        }
        // subNav.push(buildSubNav(SUBNAVSTRINGS.documentation, SysFeatureEnums.documentation, 'documentation'));

        return subNav;
    }

    private buildBillingReportNav(selectedFeature: SysFeatureEnums): JSX.Element {
        const user = this.props.authNServerData.user as ZUserMin;
        if ((selectedFeature !== SysFeatureEnums.billingReports) || 
            (user.role !== ZUserTypes.admin && user.role !== ZUserTypes.zadmin)) {
            return <div></div>;
        }

        const billingSubNavItems = this.buildBillingReportSubNav(selectedFeature);
        const reportsSubnavCss = this.accordianCSSOpenClose('billing');
        const reportsSubTabs: SysFeatureEnums[] = [
            SysFeatureEnums.billingReportsTrafficUsage,
            SysFeatureEnums.billingReportsDownloads,
        ]

        return (
            <>
            <div className="nav-spacer"></div>
            <div className="nav-item-container">
                <ZNavItem icon={'file-alt' as IconName} itemName={NStrs.reports}
                    navUrl={ZURLS.billingReports} feature={SysFeatureEnums.billingReportsTrafficUsage}
                    featureSet={reportsSubTabs}
                    selectedFeature={selectedFeature} hideOpenIcon={true}
                    handler={(feature: SysFeatureEnums): void => { 
                        this.props.setNavItem(NStrs.billingReports, feature)}} />
                <div  className={reportsSubnavCss}>
                    {billingSubNavItems}
                </div>
            </div>
            </>
        );
    }

    private buildBillingReportSubNav(selectedFeature: SysFeatureEnums): JSX.Element[] {

        const subNav = [];
        const buildSubNav = ((label: string, feature: SysFeatureEnums, urlName: string): JSX.Element => {
            return (
                <ZNavItem icon={'minus' as IconName} itemName={label} feature={feature} navUrl={ZURLS[urlName]}
                        selectedFeature={selectedFeature} key={feature}
                        handler={(featureEnum: SysFeatureEnums): void => {
                            this.props.setNavItem(NStrs.billingReports, featureEnum); }}/>);
        });
    
        subNav.push(buildSubNav(SUBNAVSTRINGS.monthlyTraffic, SysFeatureEnums.billingReportsTrafficUsage, 'billingReports'));
        subNav.push(buildSubNav(SUBNAVSTRINGS.downloadReportsData, SysFeatureEnums.billingReportsDownloads, 'billingReports'));

        return subNav;
        
    }
}

export default Main;
