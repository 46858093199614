import * as React from 'react';

// tslint:disable
const ApiDocCachePurge = (): JSX.Element => {
    return ( <>
<div className="z-title">Submit a Cache Purge </div>

<div className="zsection">
    Zycada&apos;s Cache Purge Request API allows you to purge one or more assets from the delivery network, with one request.
    The data specifying the purge target is a JSON object in the body of the POST request.

    <div className="before-code"><h3> </h3></div>
    <table className="zlist">
        <tbody>
        <tr>
            <td>Method</td>
            <td className="method">POST</td>
        </tr>
        <tr>
            <td>Production URL:</td>
            <td className="zli-inner">https://api.zycada.com/v1/cache/purge</td>
        </tr>
        <tr>
            <td>Staging URL:</td>
            <td className="zli-inner">https://staging-api.zycada.com/v1/cache/purge</td>
        </tr>
        <tr>
            <td>Header:</td>
            <td>
                <table className="izlist">
                    <tbody>
                    <tr>
                        <td>Authorization:</td>
                        <td>Bearer <i>[API_Key]</i></td>
                    </tr>
                    <tr>
                        <td>Content-Type:</td>
                        <td> application/json</td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>

    <div className="before-code"><h3>Arguments</h3></div>
    <ul className="simple-list">
        <li>The [API_KEY] is a string that  establishes authority to manipulate assets. You can only modify your own assets.</li>
    </ul>

    <div className="before-code"><h3>Request</h3></div>
    The actual data describing what should be purged is stored as a JSON object in the request body of the POST command. That JSON
    has the following format:

    <pre className="expected-response">{`{
    "uris": [ ],            // Array of strings of individual asset URIs each representing an object you wish to purge.
    "uri_codes": [ ]        // Array of URI codes which is used to specify a tree of data to purge.
                            // URI codes are defined below.
}

URI Code JSON Object Format:
{
    "domain_name": "",      // string.  domain name the URI code resides in
    "uri_code": ""          // string.  URI Code which indicates a predefined path in the users origin space.
}`}</pre>

    <div className="before-code"> <h3>Response</h3></div>
    The response body is a JSON object that describes the status of the purge request.
    The following describes the JSON object returned by the server.

    <pre className="expected-response">{`{
    "status": "",            // string.  One of the following status values: "accepted" | "failed"
    "job_id": "xxxxx"        // string.  The string is the job id by which this purge request can be tracked.
}`}</pre>

    <div className="before-code"><h3>HTTP Response Status</h3></div>
    <ul className="simple-list sample-response">
        <li><h4>202</h4> Accepted</li>
        <li><h4>400</h4> Bad JSON or invalid parameters (missing field, wrong type, etc)</li>
        <li><h4>401</h4> Invalid API Key</li>
        <li><h4>403</h4> You are trying to purge URIs with domains that aren&apos;t yours.</li>
    </ul>


    <div className="before-code"><h3>Sample HTTP Request</h3></div>
    <pre className="sample-response">{`{
   "uris": [
       "https://zycada-images.zycada.com/1569355_fpix.tif"
    ],
    "uri_codes": [
    {
            "domain_name": "zycada-images.zycada.com",
            "uri_code": "prod_1_img"
        },
    {
            "domain_name": "zycada-images.zycada.com",
            "uri_code": "prod_2_img"
        },
    ]
} `}</pre>


    <div  className="before-code"><h3>Sample HTTP Response</h3></div>
    <pre className="sample-response">{`{
    "status": "accepted"
    "job_id": "purge-37B6B470D2F1537BC5808B55CC1F5EF9"
}`}</pre>
</div>
</>);
}

export default ApiDocCachePurge;
