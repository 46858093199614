const NAVSTRINGS = {
    account: 'ACCOUNT',
    admin: 'ADMINISTRATION',
    analytics: 'ANALYTICS',
    cache: 'CACHE PURGE',
    dashboard: 'DASHBOARD',
    services: 'SERVICES',
    reports: 'REPORTS',
    svcMgmt: 'MANAGE SERVICES',
    organizations: 'ORGANIZATIONS',
    csa: 'CSALite CONFIGURATION',
    billingReports: 'Billing Reports',
    logout: 'LOGOUT',
};

const BURGERSTRINGS = {
    analytics: 'Analytics and Reports',
    cache: 'Cache Purge',
    admin: 'Administration',
    svcMgmt: 'Service Management',
    account: 'Your Account',
    CSALite: 'CSALite Configuration',
    organizations: 'Your Organizations',
    billingReports: 'Reports',
    logout: 'Logout',
}

const SUBNAVSTRINGS = {
    dnsDirector: 'DNS Director',
    cloudSvcMonitor: 'Cloud Service Monitor',
    cloudSvcAccelerator: 'Cloud Service Accelerator',
    contentDelivery: 'Content Delivery',
    cloudServiceShield: 'Cloud Service Shield',
    traffic: 'Traffic',
    efr: 'EFR',
    nrum: 'Last Mile',
    push: 'Push',
    cache: 'Cache',
    logs: 'Logs',
    changePwd: 'Change Password',
    viewEditProfile: 'View/Edit Profile',
    tfa: 'Two-Factor Authentication',
    myApiKeys: 'User API Keys',
    documentation: 'API Documentation',
    users: 'Users',
    projects: 'Projects',
    apiKeys: 'API Keys',
    cssWaf: 'WAF',
    cssBots: 'Bots',

    certs: 'TLS Certificates',
    viewServices: 'View Service',
    editDraftService: 'Edit Draft',
    deployService: 'Deploy Service',
    deleteService: 'Delete Service',
    yourOrganizations: 'Change Organizations',
    manageOrganizations: 'Manage Organizations',
    monthlyTraffic: 'Monthly Traffic',
    downloadReportsData: 'Download Report Data',

    // streamLB: 'CDN Load Balancing',
    // streamSO: 'Streaming Optimization',
    // streamWMA: 'Wireless Media Acceleration',
    // streaming: 'Streaming',
}

const ANALYTICS_STRINGS = {
    autoRefreshLabel: 'Auto-Refresh',
    calendarFrom: 'From',
    calendarTo: 'To',
    chartLoading: 'Loading',
    clickLegend: `Note: Click the name in legend to hide or show item`,
    dayOption: ' day',
    daysOption: ' days',
    filters: 'Filters',
    noAdditionalFilter: 'No additional filters.',
    
    descAll: 'Select or deselect all of the metrics',
    descAssocTraffic: 'Number of requests and associated traffic status per minute',
    descBytesIn: 'Total data received from users',
    descBytesOut: 'Total data sent to users',
    descConnTime: 'Time taken to receive TCP connect response',
    descCacheExpiredReqs:  'Number of Expired Cache Requests per Minute',
    descCacheHits: 'Number of Cache Hits per Minute',
    descCacheHitRatio: 'Cache Hit Ratio per Minute',
    descCacheMisses: 'Number of Cache Misses per Minute',
    descCacheSkips: 'Number of Cache Skips per Minute',
    descCacheStaleReqs: 'Number of Stale Cache Requests per Minute',
    descCacheValidations: 'Number of Cache Revalidations per Minute',
    descDLTime: 'Time taken from TTFB to download response',
    descExternalGets: 'Number of HTTP Get requests per minute',
    descHttpErr: 'Number of responses with HTTP Error status codes',
    descOriginReqsPrefetch: 'Zycada BOT Origin Requests Per Minute',        // 'Prefetch Requests Per Minute',
    descPercentPacketLoss: 'Percentage of requests that experienced packet loss',
    descPushedClaimed: 'Number of assets pushed per minute',
    descReqsWithPacketLoss: 'Number of requests that experienced packet loss',
    descReqsWithSession: 'Number of API calls with session Id per minute',
    descReqsWithoutSession: 'Number of API calls without session Id per minute',
    descRespSize: 'Size of received response',
    descResponseTime: 'Response latency',
    descRetrans: 'Number of packets lost per minute',
    descSSLHandshake: 'Time taken to complete SSL handshake',
    descTotalRequests: 'Total requests received at network edge',
    descTTFB: 'Time taken to receive first response byte since request was sent out',
    descThroughput: 'Total data downloaded divided by download time',    
    descWAFTraffic: 'Attacks per Minute',
    descBotTraffic: 'BOT Detections per Minute',

    descCSAAcceleration: 'Acceleration Ratio',
    descCSAResponseTimeMean: 'Mean Client and Origin Response Time',
    descCSARequests: 'Client, Origin and Accelerated Requests Per Minute',
    descCSABytesOut: 'Client and Origin Bytes per Minute',

    descWAFAttacks: 'WAF Attack by Type',
    descWAFBlockedType: 'WAF Attack Types Blocked',
    descWAFDetectedType: 'WAF Attack Types Detected',

    descWAFCountry: 'WAF Attack by Country',
    descWAFBlockedCountry: 'WAF Attack Blocked by Country',
    descWAFDetectedCountry: 'WAF Attack Detected by Country',
    descWAFIIAS: 'Detected BOTs from IAAS',
    descWAFBOT: 'Detected BOT Types',

    hourLabel: ' h',
    hourOption: 'hr',
    hoursOption: 'hrs',
    minuteOption: 'min',
    minutesOption: 'mins',

    mCSAAcceleration: 'CSA Acceleration Ratio',
    mCSAResponseTimeMean: 'CSA Mean Response Time',
    mCSARequests: 'CSA Requests Per Minute',
    mCSABytesOut: 'CSA Bytes Per Minute',

    mAssocTraffic: `Requests And Traffic Status Per Minute`,
    mBytesIn: 'Bytes In Per Minute',
    mBytesOut: 'Bytes Out Per Minute',
    mCacheExpiredReqs:  'Expired Cache Requests Per Minute',
    mCacheHits: 'Cache Hits Per Minute',
    mCacheHitRatio: 'Cache Hit Ratio Per Minute',
    mCacheMisses: 'Cache Misses Per Minute',
    mCacheSkips: 'Cache Skips Per Minute',
    mCacheStaleReqs: 'Stale Cache Requests Per Minute',
    mCacheValidations: 'Cache Revalidations Per Minute',
    mConnTime: 'Connect Time',
    mDLTime: 'Download Time',
    mExternalGets: 'Get Requests Per Minute',
    mFailures: 'Failures per Minute',
    mHttpErr: 'Responses with HTTP Error Status Codes Per Minute',
    mOriginReqsPrefetch: 'Zycada BOT Origin Requests',           // 'Origin Requests with Prefetch',
    mPercentPacketLoss: 'Percentage of Request with Packet Loss',
    mProcessingTime: 'Processing Time',
    mPushedClaimed: 'Assets Pushed Per Minute',
    mReqsPM: 'Requests Per Minute',
    mReqsWithPacketLoss: 'Packet Loss Occurrence Per Minute',
    mReqsWithSession: 'Requests with Session Id Per Minute',
    mReqsWithoutSession: 'Requests without Session Id Per Minute',
    mRespSize: 'Response Size',
    mResponseTime: 'Response Time',
    mRetrans: 'Last Mile Packet Loss Per Minute',
    mSSLHandshake: 'SSL Handshake Time',
    mTotalRequests: 'Number Of Requests Per Minute',
    mTTFB: 'Time To First Byte',
    mThroughput: 'Throughput',
    mWAFTraffic: `WAF Attacks Per Minute`,
    mBotTraffic: `BOT Detections Per Minute`,

    mWAFAttacks: 'WAF Attack by Type',
    mWAFTypeBlocked: 'WAF Attacks Blocked by Type',
    mWAFTypeDetected: 'WAF Attack Detected by Type',
    mWAFCountry: 'WAF Attack by Country',
    mWAFBlockedCountry: 'WAF Attacks Blocked by Country',
    mWAFDetectedCountry: 'WAF Attack Detected by Country',
    mWAFIAAS: 'Detected IAAS BOTs',
    mWAFBOT: 'Detected BOT Types',

    mTrafficPercentage: 'Traffic Percentage',
    mHealthCheckStatus: 'Health Check Status',
    altZyTraffic: 'Zycada Percentage',
    altCDNTraffic: 'CDN Percentage',
    altZyUptime: 'Zycada Uptime',
    altCdnUptime: 'CDN Uptime',
    dnsDirStatZyLabel: 'Zycada Traffic %',
    dnsDirStatCDNLabel: 'CDN Traffic %',

    altReqPM: '# Requests',
    altHTTP2xx: 'HTTP 2xx',
    altHTTP3xx: 'HTTP 3xx',
    altHTTP4xx: 'HTTP 4xx',
    altHTTP5xx: 'HTTP 5xx',
    altWAFAttacks: 'WAF Attacks',
    altBytesIn: 'Bytes In',
    altBytesOut: 'Bytes Out',
    altBOTSDetected: 'BOTs Detected',
    altAttacksDetected: 'Attacks Detected',
    altAttacksBlocked: 'Attacks Blocked',
    altPercentPacketLoss: '% Packet Loss',
    altOriginReqsPrefetch: '# Requests by Zycada BOT',        // '# Requests /w Prefetch',
    altAttackType: 'Attack Type',
    altAttackTypeBlocked: 'Attack Type - Blocked',
    altAttackTypeDetected: 'Attacking Country - Detected',
    altAttackByCountry: 'Attacking Country',
    altAttackByCountryBlocked: 'Attacking Country - Blocked',
    altAttackByCountryDetected: 'Attacking Country - Detected',
    altAttackByIAAS: 'IAAS BOTs',
    altAttackByBOT: 'BOT Types',
    altCacheHits: 'Cache Hits',
    altCacheMisses: 'Cache Misses',
    altCacheHitRatio: 'Cache Hit Ratio',
    altCacheStale: 'Stale Requests',
    altCacheExpired: 'Expired Requests',

    altNRUMPacketLoss: 'Packets Loss',
    altNRUMTTFB: 'TTFB',
    altNRUMResponseTime: 'Response Latency',
    altNRUMConnectTime: 'Connect Time',
    altNRUMSSLHandshake: 'SSL Handshake',
    altNRUMDownload: 'Download Time',
    altNRUMResponseSize: 'Response Size',
    altNRUMThroughput: 'Throughput',
    
    altWAFOther: 'Other',
    altWAFRestrictedFileAccess: 'Restricted File Access',
    altWAFUrlEncodingAbuse: 'URL Encoding Abuse',
    altWAFRestrictedFileExtention: 'Restricted File Extention',
    altWAFRemoteCommandException: 'Remote Command Execution',
    altWAFPHPInjection: 'PHP Injection Attack',
    altWAFSQLInjection: 'SQL Injection Attack',
    altWAFPathTraversal: 'Path Traversal Attack',
    altWAFNodejsInjection: 'NodeJS Injection Attack',
    altWAFRemoteFileInclusion: 'Remote File Inclusion Attack',
    altWAFOSFileAccessAttempt: 'OS File Access Attempt',
    altWAFXSSFilter: 'XSS Filter Attack',
    altWAFInvalidCharacter: 'Invalid Request Character',
    altWAFCVE_2014_6271: 'CVE-2014-6271',
    altWAFCVE_2015_5842: 'CVE-2015-5842',
    altWAFCVE_2017_9805: 'CVE-2017_9805',
    altWAFCVE_2021_44228: 'CVE-2021-44228',
    altWAFDataLeakage: 'Data Leakage',
    altWAFJavaApplicationAttack: 'Java Application Attack',
    altWAFProtocolAttack: 'Protocol Attack',
    altWAFProtocolEnforcement: 'Protocol Enforcement',
    altWAFRemoteCommandExecution: 'Remote Command Execution',
    alWAFtRestrictedFileAccess: 'Restricted File Access',
    altWAFScannerDetection: 'Scanner Detection',
    altWAFSessionFixation: 'Session Fixtation',
    altWAFXssInjection: 'XSS Injection',

    altWAFIAASGoogle: 'Google',
    altWAFIAASAmazon: 'Amazon',
    altWAFIAASMicrosoft: 'Microsoft',
    altWAFIAASOracle: 'Oracle', 
    altWAFIAASIBM: 'IBM',

    altWAFBOTSeo: 'SEO',
    altWAFBOTMarketing: 'Marketing',
    altWAFBOTSearch: 'Search',
    altWAFBOTCrawer: 'Crawler',
    altWAFBOTScraper: 'Scraper',

    altCSAAccelerationRation: 'Acceleration Ratio',
    altCSAAvgClientResponseTIme: 'Mean Client Response Time',
    altCSAAvgOriginResponseTime: 'Mean Origin Response Time',
    // altCSAClientRequest: 'Client Requests',
    // altCSAOriginRequest: 'Origin Requests',
    // altCSAAcceleratedRequest: 'Accelerated Requests',
    // altCSABytesOutClient: 'Client Bytes',
    // altCSABytesOutOrigin: 'Origin Bytes',

    maxOption: 'Max',
    meanOption: 'Mean',    
    medianOption: 'Median',
    metricsLabel: 'Metrics',
    minOption: 'Min',
    noChartData: 'There is no data for this metric',
    csmNotConfigured: 'Cloud Service Monitor is not configured for this service.',
    dnsDirNotConfigured: 'DNS Director is not configured for this service.',
    observerLabel: 'Observer',
    offOption: 'Off',
    onOption: 'On',
    originOptionLabel: 'Origin',    // used for both an option & a label
    clientOptionLabel: 'Client',

    periodLabel: 'Period',
    stateLabel: 'State',
    statisticsLabel: 'Statistics',
    uriCodeLabel: 'URI Code',
    cdns: 'CDNs',
    geos: 'GEOs',
    healthChecks: 'Health Check',

    geoNA: 'North America',
    geoSA: 'South America',
    geoEU: 'Europe',
    geoAS: 'Asia',
    geoOC: 'Oceania',
    
    showFailures: 'Show only failures',
    reason: 'Reason',
    time: 'Time',
    cdn: 'CDN',
    geo: 'GEO',
    pass: 'Pass',
    fail: 'Fail',
}

const LOGIN_STRINGS = {
    changePasswordTitle: 'Change Password',
    back: 'Back',
    badCharacters: 'Illegal Special Characters:',
    confirmPassword: 'Confirm Password *',
    emailPwd: 'Email Reset Link',
    emailSent: 'Reset Email Sent',
    emailSentMsg: 'If the specified email exists in our system, it will receive an email with a reset link.',
    forgotPwd: 'Forgot Password',
    forgotMyPwd: 'Forgot My Password',
    invalidEmail: 'Please provide a valid email address',
    login: 'Login',
    newPasswordLabel: 'Enter New Password',
    oldPasswordLabel: 'Enter Your Current Password',
    verifyPasswordLabel:  'Enter your current Zycada password',
    confirmPasswordLabel: 'Confirm Your New Password',
    newPassword: 'New Password *',
    oldPassword: 'Current Password *',
    password: 'Password',
    pwdRulesTitle: 'Valid Password Requirements',
    pwdDoNotMatch: 'Passwords do not match',
    pwdRuleLetters: 'at least 1 letter (A-Z; a-z)',
    pwdRuleNumbers: 'at least 1 number (0-9)',
    pwdRuleMinChars: 'Password length 8 - 72 characters',
    pwdRulePwdsMatch: 'New and Confirm Passwords match',
    pwdRuleInvalChars: 'The special characters "@$!%*#?&" are allowed (but not required)',
    resetPwd: 'Reset Password',
    activateUser: 'Activate User',
    confirmInvitation: 'Confirm Invitation',
    acceptInvitation: (org: string): string =>`Accept Invitation to Organization ${org}`,
    pwdResetSuccess: 'Your password has been reset succesfully!',
    pwdResetFail: 'The reset link has expired.  Please request a new one.',
    loginError: 'Incorrect username or password.  Please try again.',
    loginError2: 'Error reaching server.  Please try again later!',
    changePwd: 'Save New Password',
    pwdChangeSuccessful: 'Password has been updated successfully.  In a moment you will be taken to the login page.',
    accountIsActivated: 'Your account has been activated.  In a moment you will be taken to the login page.',
    acceptedInvitation: 'You have accepted your invitation to this organization.  In a moment you will be taken to the login page.',
    newActivateEmailSent: 'Your request is successful.  A new activation request is being sent to your email.',
    newResetEmailSent: 'Your request is successful.  A new password reset request is being sent to your email.',
    newInvitationEmailSent: 'Your request is successful.  A new organization invitation request is being sent to your email',
    pwdChangeFailed: 'Error changing Password.  Please try again later',
    tfaInstruct: 
            'You have Two Factor Authentication enabled.  Enter your token from your Authenticator application below.',
    
    activateInstruct: 'Welcome to Zycada. To finish account activation, complete your profile and specify a password for your account below.',
    resetPwdInstruct: 'Enter a new password for your Zycada account below.',
    acceptInvitationInstruct: 'Enter the password for your Zycada account to confirm your invitation below.',
    invalidToken: 'The link you used to get to this page is invalid.  Please contact your Administrator.',
    resetPwdTokenExpired: 'The link you used to get to this page has expired.  Click the button below to have a new ' + 
                          'link sent to your email.',
    resendActivateLink: 'Re-send Activation Link',
    resendResetLink: 'Re-send Reset Link',
    resendAcceptInvitationLink: 'Re-send Accept Invitation Link',
    invitationRetryTime: (numHours: number): string => `You may attempt to accept your invitation to this organization ` + 
                        `again by clicking the email link that brought you here in ${numHours} hours.`,
    resetPwdTryToOften: `Too many attempts to reset this account's password have occurred in too ` + 
                        `short a time period.  `,
    resetPwdRetryTime: (numHours: number): string => `You may attempt to reset your password again by clicking the email ` + 
                                             `link that brought you here in ${numHours} hours.`,
    activateUserTryToOften: `Too many attempts to activate this account have occurred in too ` + 
                            `short a time period.`,
    activateRetryTime: (numHours: number): string => `You may attempt to activate your user account again by clicking the email ` +
                                           `link that brought you here in ${numHours} hours.`,
                                           
    resetPwdMaxTryReached: 'Too many invalid attempts have been made to reset your password.  Please ' +
                           'contact your Administrator.',
    activateMaxTryReached: 'Too many invalid attempts have been made to activate your account.  Please ' +
                           'contact your Administrator.',
    acceptInvitationTryReached: 'Too many invalid attempts have been made to accept the origanization invitation. Please ' +
                            'contact your Administrator.',
    expiredTitle: 'Expired Password',
    expiredInstruct: 'Your Zycada password has expired.  Please enter a new password for your Zycada account below.',
    refreshedExpireData:   `You have refreshed the page.  Please click 'Go To Login' to restart the login process.`,
    gotoLogin: 'Go To Login',
}

const MISC_STRINGS = {
    save: 'Save',
    refresh: 'Refresh',
    absTime: 'Absolute Time',
    allOption: 'All',
    applyBtn: 'Apply',
    updateBtn: 'Update',
    byteLabel: 'byte',
    cancelBtn: 'Cancel',
    close: 'Close',
    dataNotAvailable: 'This data is not available during the POC Period.',
    defaultURICode: '/*',
    emailAddress: 'Email Address',
    email: 'Email',
    kilobyteLabel: 'kilobyte',
    lastUpdateLabel: 'Last Update: ',
    loading: 'Loading...',
    millisecondLabel: 'millisecond',
    noPublishedServices: 'No Published Services',
    notApplicable: 'Not Applicable',
    percentLabel: 'percent',
    relTime: 'Relative Time',
    total:  'Total',
    updating: 'Updating...',
    tfaTokenLabel: 'Token:',
    tfaToken: 'Token',
    true: 'True',
    false: 'False',
    notAvailable: 'N/A',
    hyphens: '---',
    blankDate: '--/--/--',
    environment: 'Environment',
    staging: 'Staging',
    prod: 'Prod',
    project: 'Project',
    servicesLabel: 'Service',
    status: 'Status',
    noData:             'No data available for the selected time period.',
    noHcData:   'No Health Check Logs are available for the selected time period',
    illFormedUrl: 'The server was not able to process you request.',
    serverDataChanged: 'Data in the server has changed.  Please refresh this page to see the latest data.',
    serverError: 'The server is currently having difficulties.  Please try again in a few minutes',
    serverError2: 'Zycada is experiencing some server issues.  Please try again later.',
    serviceNotInProj: 'The current user does not have access to this service.',
    noProjectsForUser:  'The current user is not associated with any projects.  Please contact your Zycada system ' + 
                 'administrator to have them assign you to projects.',
    createService: 'Create Service',
    yes: 'Yes',
    no: 'No',
    projectOrgValidationRules: `Valid characters: A-Z, a-z, 0-9, space, '-' and '_' .  Begin with letter.`,
    entriesPart1:       'Entries ',
    entriesPart2:       ' of ',
    unknowExpiry: '--- Unknown ---',
    none: 'None',
    valid: 'Valid',
    expiredCert: 'Expired',
    expiration: ' - Expiration: ',
    nearExpiration: 'Near Expiration Date',

    am:     ' am',
    byte:   ' B',
    ebyte:  ' EB',
    gbyte:  ' GB',
    kbyte:  ' KB',
    mbyte:  ' MB',
    msec:   ' ms',
    pbyte:  ' PB',
    pm:     ' pm',
    sec:    ' s',
    tbyte:  ' TB',
    amNoSpace:     'am',
    byteNoSpace:   'B',
    ebyteNoSpace:  'EB',
    gbyteNoSpace:  'GB',
    kbyteNoSpace:  'KB',
    mbyteNoSpace:  'MB',
    msecNoSpace:   'ms',
    pbyteNoSpace:  'PB',
    pmNoSpace:     'pm',
    secNoSpace:    's',
    tbyteNoSpace:  'TB',

    gigaBytes: 'Gigabytes',
    terraBytes: 'Terabytes',
    petaBytes: 'Petabytes',

    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    basic: 'Basic',
    business: 'Business',
    enterprise: 'Enterprise',

    // eslint-disable-next-line 
    getCopyright: (): any => { 
        return {__html: '&copy; Copyright 2014-2023 Zycada Networks, Inc.&trade; All Rights Reserved'}; 
    },
    descNoDeployedServices: (env: string): string => {
        return `Currently this project has no live services in the '${env}' environment.`
    },
}

const LOG_STRINGS = {
    addFilter:          'Add Filter',
    addHeaderFilter:    'Add Header Filters',
    addReqOrRespFilterTitle: 
                        'Add Request or Response Header Filters',
    method:             'Method',
    assetURL:           'URL',
    cacheStatus:        'Cache Status',
    clientIP:           'Client IP',
    // descNoDeployedServices: `Currently none of the staging services associated with this project are live`
    // old:
    //                     'Currently none of the services associated with this project have been published. ' + 
    //                     'Until this occurs, no log data is available.',
    doesNotExist:       'does not exist',
    done:               'Done',
    exampleHeader:      'Example: Content-Types',
    exampleIP:          'Example: 101.101.0.1',
    exampleRespCode:    'Example: 400-499',
    exampleURL:         'Example: /index.html',
    exists:             'exists',
    fullUri:            'Full URI:',
    filterType:         'Filter Type:',
    headerField:        'Header Field:',
    hideRequestHeaders: 'Hide Request Headers',
    hideResponseHeaders: 'Hide Response Headers',
    is:                 'is',
    isNot:              'is not',
    maxAge:             'Max Age:',
    noDataInPOC:        'Data log analysis is not available during the POC period.',
    noteLabel:          'Note: ',
    noteMaxRecs:        '',
    // in noteTruncationMsg:  it is expected that 'noteString' is always the 'noteMaxRecs' string.
    //                        Seemed stupid to repeat the string.
    noteTruncationMsg:   (noteString: string, totalItems: string): string =>        
                            `${noteString}Showing only 10,000 of ${totalItems} matching records.`,
    originIP:           ' Origin IP', 
    pushIndicator:      'Push Indicator:',
    request:            'request_headers.',
    requestHeader:      'Request Headers',
    requestUri:         'Request URI',
    response:           'response_headers.',
    responseCode:       'Status Code',
    responseHeader:     'Response Headers',
    responseSize:       'Response Size:',
    responseTime:       'Response Time (ms)',
    serverError:        'A server error has occurred.  Please try again later.',
    showRequestHeaders: 'Show Request Headers',
    showResponseHeaders: 'Show Response Headers',
    timestamp:          'Timestamp',
    requestMethod:      'Method',
    userAgent:          'User-Agent:',
    value:              'Value...',
    noneFltLabel:       '',
    isFltLabel:         ' = ',
    isNotFltLabel:      ' != ',
    existsFltLabel:     ' - exists',
    doesNotExistFltLabel: ' - doesn\'t exist',
    filters:            'Filters:',
    zyError:            'Zy Error:',
    zyPragma:           'Zy Pragma:',

    // The following are names of HTTP request methods.  I suspect they should not need to be
    // translated
    methodGet:          'GET',
    methodPost:         'POST',
    methodPut:          'PUT',
    methodDelete:       'DELETE',
    methodOptions:      'OPTIONS',
	methodHead:         'HEAD',
	methodPatch:        'PATCH',
    methodConnect:      'CONNECT',
	methodTrace:        'TRACE',
};

const CACHE_STRINGS = {
    jobSubmitOK: (id: string): string  => `Job Id ${id} was successfully submitted`,
    goodUriCode: (serviceName: string, uriCode: string, uriPattern: string): string => 
                `Domain: ${serviceName}; URI Code: ${uriCode}; URI Pattern: ${uriPattern}`,
    badUriCode: (serviceName: string, uriCode: string): string => 
                `Unknown URI Code in service.  '${serviceName}', URI code: ${uriCode}`,
    tooManyChars: (maxChars: number): string => `More than ${maxChars} characters entered!`,
    errorSubmittingReq: (msg: string): string => `Error submitting Purge Request - ${msg}`,
    errorCreatingReq: (msg: string): string => `Error submitting Purge Request.  Status - ${msg}`,
    domainUripattern: (serviceName: string, code: string, pattern: string): string => 
                `Domain: ${serviceName}; URI Code: ${code}; URI Pattern:  ${pattern}`,
    unknownUriCode: (domain: string, code: string): string => 
                `Unknown URI Code in service.  Domain: '${domain}', URI code: ${code}`,
    charactersLeft: (nc: number, maxChars: number): string => {
                const rc = maxChars - nc;                     // remaining characters
                return `Characters Remaining: ${rc}  ${(nc === 1) ? 'characters' : 'character'} can still be entered.`;
        },
    tooManyPaths: (maxPaths: number): string => {return  `The maximum number of paths (${maxPaths}) in one request exceeded!` },
    tooManyTagLines: (maxCacheTags: number): string => {return  `The maximum number of cache tag lines (${maxCacheTags}) in one request exceeded!` },

    completionTime: 'Completion Time',
    domainURICodes: 'Domain and URI Codes',
    domain: 'Domain:',
    chooseService: '--- Choose a Service ---',
    chooseURICode: '--- Choose URI Code ----',
    createCachePurgeRequest: 'Create a Cache Purge Request',
    domainAndUriCodes: 'URI Codes',
    environmentToPurge: 'Environment To Purge',
    errorProcessingReq: 'Error processing request.',
    listOfPaths: 'List of Paths (Case sensitive)',
    newPurgeRequest: 'New Purge Request',
    noRequests: 'There are currently no cache requests for this project.',
    noSvcForEnv:        'There are no deployed services for this environment.',
    noUriCodes: 'This service has no URI Codes',
    pathInstructions: `Paths are case sensitive. Each path must begin with '/' (for prefix purges) or '~' (for regex purges).`,
    tagInstructions: `Tags are case sensitive.  There can be up to 8 tags.  Each tag has a max length of 128 characters. The following characters are allowed: `,
    legalTagChars: `a-z, A-Z, 0-9, '.', '-' and '_'`,
    tagInfo: (headerName: string): string => { return `Purge will be based on header field '${headerName}'`},
    pathListNoSlashError: `One of the paths doesn't start with a '/' or '~'.`,
    embeddedStar: `One of the paths contains an embedded '*'. This is an illegal character.`,
    tooManyTags: 'Too many cache tag for request.',
    illegalCharsInTags: 'There is one or more illegal characters in the cache tags,',
    tagLineTooLong: 'A cache tag line is more than 128 characters.',
    purge: 'Purge',
    purgeMessages: 'Purge Messages',
    services: 'Services',

    statusRecentPurgeReq: 'Status of Recent Purge Requests',
    uriCode: 'URI Code:',
    urlList: 'URL List:',
    ctagList: 'Cache Tag List:',
    urlListPlaceHolder: `Example: /Casesensitive/Path?QueryParameter=Value
Example: ~/Path/To/Images/.+(.png|.jpg)`,
    cacheTagPlaceHolder: `Example: hello%world`,
    waitingMsg: 'Waiting for data from the server...',
    URLsCopied: 'Paths successfully copied to the clipboard',
    URLsNotCopied: 'Unable to copy Paths to the clipboard',
    cTagsCopied: 'Cache tags successfully copied to the clipboard',
    cTagsNotCopied: 'Unable to copy Cache tags to the clipboard',
    pathList: 'List of Paths',
    cacheTags: 'Cache Tags',
    purgeType: 'Purge Type',
}

const SHARED_JOB_STRINGS = {
    jobID: 'JOB-ID',
    serviceName: 'Service Name',
    submitTime: 'Submit Time',
    lastStatusChange: 'Last Status Change',
    completionTime: 'Completion Time',
    aborted: 'Aborted',
    accepted: 'Accepted',
    failed: 'Failed',
    "in_progress": 'In Progress',
    success: 'Success',
}

const ACCOUNT_STRINGS = {
    updateProfileTitle: 'Update User Profile',
    tfaMgmtTitle: 'Two Factor Authentication Management',
    firstName: 'First Name',
    lastName: 'Last Name',
    companyName: 'Company',
    phoneNumber: 'Phone Number (optional)',
    phoneNumberSample: 'US example: +1 xxx xxx xxxx',
    saveProfile: 'Save Profile',

    updatePassword: 'Update Password',
    doItLater: 'Do it Later',
    nameRule: `Field accepts the characters A-Z, a-z, '-' and .`,
    phoneRule: 'Field accept US and international phone numbers. Country codes are required.',
    emailRule: 'Field only accepts valid email addresses',
    profileUpdatedSuccess: 'User profile has been updated.',
    profileUpdatedFailed: 'Error saving User Profile.',
    twoFactorAuthEnabled: 'Two Factor Authentication: Enabled for this account',
    twoFactorAuthDisabled: 'Two Factor Authentication: Disabled for this account',
    TFACurrentlyDisabled: 'Two Factor Authentication is currently disabled for this account.',
    TFACurrentlyEnabled: 'Two Factor Authentication is currently enabled for this account.',
    // TFABeginGen: 'To begin enabling Two Factor Authentication, click the button below.',
    TFADisableStart: 'To disable Two Factor Authentication, click the button below.',
    TFAEnable: 'Enable 2-Factor Authentication',
    FTADisable: 'Disable 2-Factor Authentication',
    TFAActivate: 'Finish Setup',
    TFADeactivate: 'Finish Deactivation',
    TFAGenIntro: (email: string): string => 'Please scan the QR code shown below with an authenticator app like ' + 
                    'Google Authenticator, Apple Authenticator or Microsoft Authenticator. The new security key ' + 
                    `will show up in your app as 'Zycada (${email})'.`,
    TFAGenSetToken: 'To complete Two Factor Authentication set up, please enter the security code shown in your ' + 
                    'Authenticator app.',
    gotoLogin: 'Take me to Login',
    tfaSuccess1: 'You have successfully enabled Two Factor Authentication.',
    tfaSuccess1a: 'Backup Codes:',
    tfaSuccess2: 'If you lose your Security Key Generator (i.e., lose your phone), you can use the backup codes ' +
                 'below in place of your security token when you log into your account.',
    tfaSuccess3: 'Each backup token can be used only once.',
    tfaSuccess4: 'NOTE: The backup codes will never be shown again so please save them.',
    tfaSuccess5: 'To ensure your protection and because you have increased your security, you have been logged ' +
                 'out of system.  To log back in, click the button below.',

    // password expiry strings
    expiringPwdTitle: 'Password Is Expiring',
    passwordReuse: (numPwds: number): string => { return `NOTE:  Do not reuse your last ${numPwds} passwords.`},
    passwordExiringQuestion: `  Do you wish to update it now?`,

    pwdExpiring: (days: number): string => { return `Your password is expiring in ${days} days. `},
    pwdExpringTommorrow: `Your password is expiring tomorrow. `,
    pwdExpiringInNHours: (hours: number, dateTimeStr: string): string => { 
                            return `Your password is expiring in less than ${hours} hours.  ${dateTimeStr}`},
    inUnderHour: (dateTimeStr: string): string => { return `Your password is expiring in less than an hour. ${dateTimeStr}`},
}

const API_DOCUMENTATION = {
    overview: 'Overview',
    analytics: 'Analytics',
    cacheManagement: 'Cache Management',
    submitPurgeReq: 'Submit Purge Request',
    getPurgeStatus: 'Obtain Purge Status',
    firewallMgmt: 'Firewall Management',
    addDelWhitelistIPs: 'Add/Delete Whitelist IPs',
    addDelBlacklistIPs: 'Add/Delete Blacklist IPs',
    getBlacklistIPs: 'Get Blacklist IPs',
    getWhitelistIPs: 'Get Whitelist IPs',
    logMgmt: 'Log Management',
}

const REPORT_STRINGS = {
    reportingPeriods: 'Reporting Periods',
    customPeriod: 'Custom Period',
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This Week',
    thisMonth: 'This Month',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    last7Days: 'Last 7 Days',
    last30Days: 'Last 30 Days',
    yearToDate: 'Year To Date',
    metrics: 'Metric',
    totals: 'Totals',
    percentage: 'Percentage',
    requests: 'Requests',
    success: 'Success (2xx and 3xx)',
    others: 'Others',
    totalDataXfer: 'Data Transferred',
    loading: 'Please wait while we load the data for your report...',
    projectName: 'Project Name',
    totalRequests: 'Total Requests',
    includeServices: 'Include Service Data',
    unknownMonth: 'Unknown Month',
    statement: 'Statement',
    units: 'Units',
    noBillingData: 'None',
    staging: 'Staging:',
    production: 'Production:',
    noReportsForOrg:  'Currently there are no reports for this orginization',
    monthYear: (month: string, year: string): string => { return `${month} ${year}`},
    aggrTrafficReportTitle: (name: string): string => { return `Monthly Traffic Usage Report for ${name}` },
    envService: (env: string, serviceName: string): string => {return `'${serviceName}' Service - ${env} Environment`},
    envAllServices: (env: string): string => {return `All Services - ${env} Environment`},
    dateRange: (from: string, to: string): string => {return `${from} to ${to}`},
    trafficReportTitle: (org: string): string => { return `Traffic Report for ${org}`},
    totalBytesOut: (units: string): string => { return `Total Bytes Out (${units})`},
    totalBytesIn: (units: string): string => { return `Total Bytes In (${units})`},
    totalLogBytesOut: (units: string): string => { return `Log Bytes Out (${units})`},
    orgSummary: (org: string): string => { return `${org} Aggregated Totals`},
    dataDownloadsTitle: (name: string): string => { return `Download Monthly Traffic Usage Data for ${name}` },
    dataDownloadsInstructs: (name: string): string => { return `The following is downloadable monthly usage data for ${name}.`; },
}

const ERROR_STRINGS = {
    absTimeInvalidFrom: '"From" is an invalid date.',
    absTimeInvalidTo: '"To" is an invalid date.',
    absTimeFromAfterTo: '"From" date is more recent than "To" date.',
    absTimeFutureToDate: '"To" date is in the future.',
    absTimeFromDateToOld: '"From" date is more than a year in the past.',
    absTimeFromWithin7ofTo: '"From" date must be within 7 days of the "To" date.',
    responseCodeError:  'Invalid status code.',
    clientIPError: 'Invalid client IP.'
}

const ADMIN_STRINGS = {
    projectFor: (name: string): string => {return `${name} - Projects`},
    userEmail: (firstName: string, lastName: string, email: string): string => {return `${firstName} ${lastName} (${email})`},
    confirmProjectRemoval: 
        (username: string): string => {
            return `Are you sure you want to remove access to the following projects from user '${username}':`
        },
    addUserToProject: (project: string): string => { return `Add Users to project '${project}'`},
    confirmUserRemoval: 
        (projectName: string, plural: boolean): string => {
            const user = plural ? 'users' : 'user';
            return `Are you sure you want to remove the following ${user} from project '${projectName}':`
        },
    confirmApiKeyExpiration: (userName: string, plural: boolean): string => {
        const key = plural ? 'keys' : 'key';
        return `Are you sure you want to expire the following ${key} from user '${userName}':`
    },
    numUsedKey: (maxOrgKeysPerUser: number): string => {
        return `You have 1 active API Key.  You can have up to ${maxOrgKeysPerUser}.`},
    numUsedKeys: (maxOrgKeysPerUser: number, activeUserKeys: number): string => {
        return `You have ${activeUserKeys} active API Keys.  You can have up to ${maxOrgKeysPerUser}.`},
    createProjectSuccess: (projName: string): string => {return `Project "${projName}" was successfully created.`},
    editProjectSuccess: (projName: string): string => { return `Project "${projName}" has been successfully updated.`},
    cannotExpireKey: `Editing expired API keys is not supported at the current time.  If you need to ` + 
                     `un-expire a key, please contact Zycada Operations.`,
    apiKeysNotSupportedForUser: `API Keys are not supported for users who have a 'user' system role.`,
    noActiveApiKeysForUser: 'There are no active API Keys for this user.',
    newApiKey: 'New API Key Created',
    newKeyCreated: `A new API Key has been created with the following value:`,
    newKeyCreated1: 'This key will only be displayed once.  Please write down the key or ' + 
                    'copy it to the clipboard using the button below.',
    copyKey: 'Copy API Key',
    keyLabel: 'Key:',
    timeZone: (tzone: string): string => { return tzone ? `Times are in ${tzone}` : 'Times are in the local timezone.'},
    updateCertInstructionMsg: (commonName: string ): string => {
                return `You are updating the certificate with the common name '${commonName}'.  ` +
                    `Enter the new contents of your '.crt' and '.key' files in the fields below`},
    confirmDelete: (commonName: string, id: string): string => { 
                return `Are you sure you want to delete the certificate with the ` + 
                ` Common Name '${commonName}' and Zycada Certificate Id ${id}.`},
    resentInviteMsg: (email: string): string => { return `A new activate/invite email has been sent to ${email}`},
    confirmDeleteUser: `Are you sure you want to permanently delete the following user:`,
    userManagement: 'User Management',
    keyManagement: 'API Key Management',
    projectManagement: 'Project Management',
    usersNotActivated: `'*' indicates that this user is not activated/invited`,
    user: 'User',
    noProjects: 'No Projects',
    noProjectsInOrg: 'There are no projects in this organization',
    noUsersInOrg: 'There are no users in this organization.  Please create an admin user.',
    noUserNoKeys: 'There are no users in this organization.  Please create some users.',
    numProjects: '# PROJECTS',
    role: 'Role',
    resendEmail: 'Resend Invite Email',
    createUser: 'Add User',
    deleteUser: 'Delete User',
    editUser: 'Edit User',
    userUpdated: 'The users information has been updated.',
    addProjects: 'Add Projects',
    removeProjects: 'Remove Projects',
    createNewUser: 'Add or Invite User',
    userProfile: 'User Profile',
    password: 'Password',
    selectUserRole: 'Select User Role',
    changeUserRole: 'Change User Role',
    admin: 'Admin',
    zadmin: 'Zycada Admin',
    dev: 'Dev',
    updateUser: 'Update User',
    currentRole: 'Current Role:  ',
    userDesc: `View data for associated projects.  View the User's Read-Only API Keys.`,
    devDesc: `View data for associated projects.  View the User's Read-Only and Read/Write API Keys.  Manage ` + 
             'those keys.',
    adminDesc: 'View data for all projects.  Manage Projects and Users.  Manage and View API Keys for all users.',
    zadminDesc: 'Can view data from multiple organizations. View data for all projects.  Manage Projects and Users.' + 
                '  Manage and View API Keys for all users.',
    editRoleSelf: `Note:  An 'Admin' user cannot change their own role.  A different 'Admin' user can change the ` +
                  `role of this user.`,
    project: 'Project',
    numUsers: '# Users',
    addUsers: 'Add Users',
    removeUsers: 'Remove Users',
    createProject: 'Create Project',
    cannotCreateProject: 'You cannot create any more projects for this organization.  If you need to create more projects contact your Zycada sales team.',
    editProject: 'Edit Project',
    deleteProject: 'Delete Project',
    confirmDeleteProj: 'Are you sure you want to delete the following project:',
    cannotDeleteProj:  (pname: string): string => {return `Project '${pname}' still has services associated with it so it cannot be deleted at this time.`},
    cannotDelProject2: `Navigate to services management by going to 'Service Management' and selecting `,
    usedProjInOrg: (numProj: string, maxNumProj: string): string => { return `You have ${numProj} projects.  You can have up to ${maxNumProj}.` },
    enterNewProjectName: 'Enter the name of your new project below:',
    editProjectName: 'Enter the modified project name below:',
    projectName: 'Project Name',
    chooseUsers: 'Select the users you would like to add to this project:',
    expireKeys: 'Expire Keys',
    createKeys: 'Create Key',
    showExpiredKeys: 'Include Expired Keys',
    numKeys: '# Keys',
    keyName: 'Key Name',
    creationDate: 'Creation Date',
    type: 'Type',
    validTilLabel: 'Valid Until',
    lastUsed: 'Last Used',
    key: 'Key',
    never: '-- Never --',
    apiKeyCopied: 'API Key successfully copied to clipboard',
    apiKeyNotCopied: 'Unable to copy API Key to clipboard',
    generateApiKey: 'Generate API Key',
    editKey: 'Edit Key',
    editApiKey: 'Edit API Key',
    friendlyKeyName: 'Friendly Name',
    validUntil: 'Valid Until',
    keyType: 'Key Permission',
    readWrite: 'Read/Write',
    readOnly: 'Read/Only',
    updateKey: 'Update Key',
    expired: '-- Expired --',
    keyExpirationDate: 'Key Expiration Date',
    friendlyPlaceholder: 'Key description (no spaces)',
    viewMyKeys: 'My API Keys',
    userExists: 'A user with this email already exists.',
    noApiKeys: 'You currently have no API Keys associated with your account.  ' + 
               'See your administrator for more information.',

    certManagement: 'TLS Certificates',
    numCerts: '# Certs',
    commonName: 'Common Name',
    certId: 'Cert ID',
    certStatus: 'Cert Status',
    uploadCert: 'Upload Cert',
    uploadNewCert: 'Upload New Cert',
    editCert: 'Update Cert',
    deleteCert: 'Delete Cert',
    expirationDate: 'Expiration Date',
    issueDate: 'Issue Date:  ',
    certIssuer: 'Issuer:   ',
    certIdCopied: 'Certificate Id successfully copied to the clipboard',
    certIdNotCopied: 'Unable to copy Certificate Id to the clipboard',
    unknown: 'Unknown',
    lastUpdate: 'Last Update:',
    updateStatus: 'Update Status:',
    assocServices: 'Associated Services',
    sanInfo: 'SAN Info',
    noSanInfo: '--- No San Info ---',
    expiringSoon: 'Expiring Soon',
    validTil: (date: string): string => { return `Valid til ${date}`},
    expiredOn: (date: string): string => { return `Expired on ${date}`},
    certInfoCM: (commonName: string): string => { return `CN: ${commonName}`},
    deleteExistingCert: 'Delete a Certificate',
    updateExistingCert: 'Update an Existing Certificate',
    keyChainPlaceHolder: `Example:\n-----BEGIN CERTIFICATE-----\nxxxx ....\n-----END CERTIFICATE-----`,
    privateKeyPlaceHolder: `Example:\n-----BEGIN PRIVATE KEY-----\nzzzz ....\n-----END PRIVATE KEY-----`,
    certChainInstructions: `Certificate Chain - Place the entire contents of your '.crt' file below`,
    certKeyInstructions: `Private Key - Place the entire contents of your '.key' file below`,
    certInstructionMsg: `Enter the contents of your '.crt' and '.key' files in the fields below`,

    certUpdateSuccess: 'The Certficate has been successfully updated.',
    certUploadSuccess: 'The Certificate has been successfully uploaded.',
    apiKeyUpdateSuccess: 'The API Key has been successfully updated.',
    apiKeyCreateSuccess: 'The API Key has been successfully created.',
    certAlreadyLoaded:  'This certificate has already been uploaded.',
    addUserToAllProjects: 'Add this user to all current and future projects in this organization.',
    admAreAlwaysAdded: ' - Admin users are always added to all projects',
    admAddToAllProjUncheck: ' If this checkbox is uncheck, this user will be removed from all projects.',

    noteAddUserToProjects: 'Note: Admins are always added to all current and future projects.',
    noteAdminDemotion:`Note: Changing an admin to a 'dev' or 'user' roles will cause the user to be removed from all projects unless
    the 'Add user to all projects' checkbox is checked.`,
    // noteAdminDemotion: `Note: If the 'Add user to all projects' checkbox is not checked, changing this user's role ` + 
    //                    `will cause the user to be removed from all projects in the Organization. The user must then be ` + 
    //                    `explicitly re-added to the appropriate projects.`,
    editRoleDescIsAdmin: `Note: Changing this user's role will cause the user to be removed from all projects in ` + 
                         `the Organization.  The user must be explicitly re-added to the appropriate projects.`,
    editRoleDescNotAdmin: `Note: Changing this user's role to 'Admin' will cause all projects to be added to the user.`,
    autoAdd: '(auto add)',
    // Cert Errors
    startEndMismatch: 'Mismatched Certificate begin and ends',
    lineTooLong: 'A Certificate data line is too long (> 64 characters)',
    misformatted: 'Certificate data is incorrectly formatted',
    notBase64: 'Certificate data is not base64',
    certNoData: 'Certificate has a beginning and end but no data',
    tooManyInChain: 'There are too many in the certificate chain.  Max is 5',
    tooManyInKey:  'There is more than one key in the private key',
    exceedMaxCertLimitError: 'You have reached the limit for the number of Certificates that can be in a Project.' +
                                '  If you need to increase the limit, please contact your Zycada representative.',
    csaName: 'Cloud Service Acceleration',
    cdn_reserveName: 'CDN Reserve',
    chooseSolutions: 'Choose the solutions you wish to use in this project:',
    currentSolutions: 'Currently these solutions are associated with this project.',
    addSolutions: 'Choose the solutions you wish to add to this project:',
    solution_csa: "Cloud Service Accelerator",
    solution_cdn_reserve: "CDN Reserve",
}

const SERVICES_STRINGS = {
    title: (sname: string): string => `Configuration of Service: '${sname}'`,
    viewServiceTitle: (sname: string): string => `Configuration History for Service '${sname}'`,
    noServicesForEnv: (env: string): string => `Currently there are no services created for the ${env} environment.`,
    confirmCopyAsDraft: (version: number): string => `Do you want to replace the existing draft service with a copy of ` +
                                             `service version #${version}?`,
    confirmMakeNewDraft: (version: number): string => `Do you want to make a new configuration draft using service version ` +
                                              `#${version} as a template?`,
    serviceCreated: (serviceName: string): string => `Service '${serviceName}' has been successfully created.`,     
    deployServiceTitle: (serviceName: string): string => `Deploy Service - '${serviceName}'`,
    deployedVersion: (version: number): string => `Deployed Version: ${version}`,
    updatedAt: (date: string): string => `Updated At: ${date}`,
    deploySuccess: (env: string): string => `Successfully deployed to ${env}`,
    deployFailed: (env: string): string => `Failed deployment to ${env}`,
    deployTo: (env: string): string => `Deploying to ${env}`,
    noDeploymentYet: (env: string): string => `Nothing has been deployed to ${env} yet`,
    attemptedDeploymentFailed: (ver: number): string => `Attempted Deployment to version ${ver}:`,
    versionStr: (ver: number): string => `Version ${ver}`,
    cacheStatusLabel: (status: string): string => `Status ${status}`,
    draftUpdated: (serviceName: string): string => `Draft for service '${serviceName}' has been saved`,
    editServiceTitle: (serviceName: string ): string => `Edit Draft Service - '${serviceName}'`,
    draftCreatedAt: (cdate: string): string => `Draft created at ${cdate}`,
    viewingSvcVersion: (version: number): string => `Viewing configuration version ${version}`,
    modifiedAt: (mdate: string): string => `Modified at ${mdate}`,
    clonedFrom: (version: number): string => `Created from version ${version}`,
    configVersion: (version: number | string): string => { return `Configuration Version ${version}` },
    configDraft: 'Draft Configuration',
    cdnTraffic: (zTrafficWeight: number): string => { return `Amount of traffic your CDN will handle: ${zTrafficWeight}%`},
    asterick: '*',
    asterickMsg:  '* Service Name has been changed. This will take effect when this configuration is deployed.',
    cachableService: 'Service is Cacheable',
    cname: 'CNAME',
    stagingCname: 'Staging CNAME',
    prodCname: 'Production CNAME',
    svcNeverDeployedToProd: '--- Never been deployed to production ---',
    domainName: 'Domain Name',
    environment: 'Environment',
    healthChecks: 'Health Checks', 
    name: 'Name',
    noHealthChecks: 'No Cloud Service Monitor health checks are configured for this service',
    noOrigin: 'No Origins are configured for this service',
    noDnsDirector: 'The DNS Director is not configured for this service',
    ports: 'Ports',
    noPorts: 'No ports are currently configured for this service',
    overview: 'Overview',
    origins: 'Origins',
    port: 'Port',
    project: 'Project ',
    manageServices: 'Manage Services',
    redirectFromDomains: 'Redirect from Domains',
    redirectRules: `16 domains max; Comma separated domains; no wild cards
    Example: a.com, b.com`,
    domainRedirErrHasSvcDomainName: `The service's domain is included as a redirect domain`,
    domainRedirErrInValidDomainName: 'One of the domain redirects is invalid',
    domainRedirErrTooManyDomains: 'There are too many domain redirects.',
    active: 'active',
    deleting: 'deleting',
    "delete_failed": 'delete failed',
    draft: 'draft',
    published: 'published',
    publishing: 'publishing',
    "publish_failed": 'publish failed',
    lastUpdate: 'Last Update',
    certCommonName: 'Certificate Info',
    
    requestPath: 'Request Path',
    service: 'service',
    serviceName: 'Service Name',
    sni: 'SNI',
    successStatusCode: 'Success Status Code',
    version: 'Version',
    copyAsDraft: 'Copy as Draft',
    showServiceJson: 'View Config JSON',
    diffServiceVersions: 'Diff Service Versions',
    replaceDraft: 'Replace Existing Draft',
    createNewDraft: 'Create New Draft',
    createNewService: 'Create New Draft Service',
    serviceAsJson: (serviceName: string): string => {return `Config JSON for service '${serviceName}'`},
    createService: 'Create Service',
    certificate: 'Certificate',
    enableHTTP2: 'Enable HTTP2',
    redirectHTTP: 'Redirect HTTP to HTTPS',
    originId: 'Origin Id',
    originIdHelp: `Allowed characters: a-z, A-Z, 0-9, '-', '_'`,
    originDomainName: 'Origin Domain Name',
    tlsEnabled: 'TLS Enabled',
    enabled: 'Enabled',
    friendlySniHostname: 'SNI Hostname',
    exceedMaxServiceLimitError: 'You have reached the limit for the number of Services that can be in a Project.' +
                                '  If you need to increase the limit, please contact your Zycada representative.',
    noCertsInProject: `There are no Certificates associated with this Project.  Please go to 'CERT MANAGEMENT' ` +
                      `in ADMINISTRATION.`,
    uploadCerts: `Go to Certs Management`,
    noCertsAvailable: 'No Certificates',
    originInfo: 'Origin Information',
    dnsDirectorInfo: 'DNS Director Information',
    CSMInfo: 'Cloud Service Monitor Information',
    createServiceInstruct: 'Enter the basic information for the new service here.  After the service is created you ' +
                           'will be taken to a page where you can finish configuring the service.  Once completed ' +
                           'you deploy the service to the staging network.',
    none: '--- None ---',
    cantCreateService: 'You have already reached the limit for the number of services that can be in a project.',
    maxServicePerProject: 'Maximum Services Per Project:',
    contactZycada: 'Contact Zycada support if you would like to have this number increased.',
    tooManyServicesTitle: 'Unable to Create Service',

    deployDestination: 'Deployment Destination',
    deployVersion: 'Deployment Version',
    deployToStaging: 'Deploy to Staging',
    deployToProduction: 'Deploy To Production',
    stagingStatus: 'Staging Status',
    prodStatus: 'Production Status',

    deploymentHistory: 'Deployment History',
    cloudServiceMonitor: 'Cloud Service Monitor',
    contentDelivery: 'Content Delivery',
    selectServiceTemplate: 'Select Service Template',
    selectServicePlatform: 'Select the platform you will use with this service',
    noPlatforms:  '--- no platform ---',
    platform: 'Platform',
    serviceTemplateNone: 'None',
    serviceTemplateEcommerce: 'ECommerce',
    serviceTemplateCdnReserve: 'CDN Reserve',
    serviceTemplateDefault: 'Default',
    view: 'View',
    stagingProd: 'Staging/Production',
    staging: 'staging',
    prod: 'prod',
    date: 'Last Update',
    fromVersion: 'From Version',
    toVersion: 'To Version',
    status: 'Status',
    new: 'New',
    productionEnv: 'Production',
    stagingEnv: 'Staging',
    deploymentAccepted: 'Deployment has been accepted',
    deletingSvc: 'Deleting Service...',
    deleteFailed: 'Service deletion failed',

    deployInProgress: 'Deploying ...',
    deploying: 'DeployIng: ',

    newServiceNoDeployment: 'This is a new service.  Nothing has been deployed.',
    noDeploymnetHistory: 'There is no recent deployment history for this service.',
    attemptFailed: 'Deployment Failed',
    draftVersion: 'Draft Version',
    loadingDeploymentInformation: 'Loading deployment information...',
    badEnvironment:  `The 'Prod' environment does not currently have any services.  Please change the environment ` +
                     `to 'Staging'.`,
    noServices:  'There are currently no services for this project. Please create a service. ',
    plainDeployServices: 'Deploy Services',
    plainEditServices: 'Edit Draft Service',

    discardDraftBtn: 'Discard Changes',
    saveDraftBtn: 'Save Draft',
    dnsDirector: 'DNS Director',
    javascriptAndCss: 'JS and CSS',
    staticAssets: 'Static Assets',
    dynamicAssets: 'Dynamic Assets',
    images: 'Images',
    video: 'Video',
    primary: 'Primary',
    ddDomain: 'CDN Host Name',

    cdnTag: 'CDN Name',
    cdnTagHelp: `a-z, A-Z, 0-9, '-', '_'`,
    healthCheckHelp: `Path on the server. Start with '/'`,
    cdnReserve: 'CDN Reserve',
    autoSwitch: 'Auto Switch',
    autoSwitchEnabled: 'Auto Switch Enabled',
    useCDNEnabled: 'Use CDN as default Enabled',
    useCdnAsOrigin: 'Use CDN as default origin',
    healthChecksDetectFailures: 'Health Checks Used to Detect Failures',
    cdnCheck: 'CDN Check',
    originCheck: 'Origin Check',
    staticAssetHelp: 'Static Asset like image or css to check CDN health',
    dynamicAssetHelp: 'Dynamic Asset like api or home page to check Origin health',
    checkInterval: 'Check Interval',
    // checkIntervalTime: '30 seconds',

    thresholds: 'Thresholds',
    failureThresholds: 'Failure Threshold',
    successThresholds: 'Success Threshold',
    consecutiveFailures: '2 consecutive failures',
    consecutiveSuccess: '5 consecutive successes',
    hcConfigHelp: 'Configure in Cloud Service Monitor', 

    noCertAssigned: 'None Assigned',
    requiresCertificate: '(requires that the service has a certificate)',

    cdnTagName: (cdnName: string): string => {return `CDN '${cdnName}'`;},
    intervalSecValue: (intervalName: string, time: number): string => { return `${intervalName} - ${time} seconds`; },
    cdnTrafficPercentage: `Percentage of CDN Traffic`,
    ddTrafficPercentage: 'CDN Traffic Percentage',
    zycadaTrafficPercentage: 'Zycada Traffic Percentage',
    originUsesHTTP: 'Origin uses HTTP only',
    enabledHTTPOnly: 'HTTP only',
    extension: 'Extensions',
    cacheStatus: 'Cache enabled',
    enableWaf: 'Enable WAF Support',
    enableImageMux: 'Enable Image MUX Support',
    enableXff: 'Enable X-Forward-For Header',
    xffHeaderValue: 'XFF Header Value',
    HeaderValue: 'Header Value',
    seconds: 'seconds',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    wafStatus:  'WAF',
    imgMuxStatus: 'ImageMUX',
    XffStatus:  'XFF',
    cacheAge: 'Cache Age',
    disabled: 'Disabled',
    asciiCharsNoColon: `ASCII Characters 0x21 - 0x7E; exclude colon (0x3A)`,
    orClickHereForCert: ' Click here to be taken to Certificate Management.',
    needCertForService: 'Currently there are no valid certificates associated with this project.  ',
    
    // Errors preventing deployment
    serviceNotDeployable: '(Service cannot be deployed)',
    fixErrors: 'Please fix the following error(s) to proceed:',
    supplyOriginInfo: 'Specify Origin information.',
    supplyDnsDirInfo: 'Specify DNS Director information.',
    supplyTwoValidHealthChks: 'Two valid Cloud Service Monitor health checks must be specified.',
    supplyOriginOrDnsDirInfo: 'Specify an Origin or DNS Director information.  You may also provide both.',
    supplyValidHealthChecks: 'Valid Cloud Service Monitor health check paths must be provided.',
    
    unsafePromoteToProd: 'Simultaneously deploy to production',
    unsafeIgnoreCertMismatch: 'Ignore Cerficate name mismatch',
    confirmUnsafePromote: 'It is recommended to test in staging before deploying to production.',
    confirmUnsafePromote1: 'Are you sure you want to simultaneously deploy this service to both staging and production?',
    confirmUnsafePromoteTitle: "Confirm Unsafe Simultaneous Deployment",

    chooseAVersion: 'Choose a version',
    versionNum: ((version: number): string => { return `Version ${version}` }),
    diffInstructs: 'Please select your versions and click ',
    diffBtnText: 'Diff Versions',
    addToVersion: (version: string): string => {return `+ Service ${version}` },
    removedFromVersion: (version: string): string => { return `- Service ${version}` },
    diffVersionTitle: (serviceName: string): string => { return `Diff Versions of Service "${serviceName}"` },
    editServiceJSONTitle: (serviceName: string): string => {return `Edit JSON for Service "${serviceName}"`},
    firstVersion: 'First Version',
    secondVersion: 'Second Version',
    deleteService: 'Delete Service',
    deleteStagingServiceWarning: `Warning:  Clicking 'Delete Service' will ONLY delete all the 'staging' versions of this service.  'prod' versions of the service will NOT be deleted.`,
    deleteStagingServiceWarning1: `If you want to entirely delete all versions of the service, cancel this request and change your environment to 'prod' and click 'Delete Service' again.`,
    deleteStagingAndProdServiceWarning: `Warning:  Clicking 'Delete Service' will cause all versions of this service (staging and prod) to be deleted.`,
    deleteServiceTitle: (serviceName: string): string => { return `Delete Service - "${serviceName}"` },
    confirmDelete: 'Are you sure you want to delete this service?',
    successDeletedService: (serviceName: string): string => { return `All versions for Service "${serviceName}" have been successfully deleted.`},
    editServiceJSON: 'Edit the service below.  The JSON must be valid in order for it to be saved.',
    invalidJSON: 'JSON is invalid',
    validJSON: 'JSON is valid',
    serviceUnchanged: 'The service has not changed yet',
    currentProject: 'Current Project:',
    noServicesForDelete: 'This project has no services.',
    deleteServicesFrom: `Delete services from project`,
    serviceDropdownLabel: 'Service',
    svcDeletionInProgress: 'This service in the process of being deleted',
    dropdownNoServices: 'No Services',
}

const ORGANIZATION_STRINGS = {
    orgDataLabel: (orgName: string): string => { return `Data for ${orgName}`},
    createOrgSuccess: (orgName: string): string => {return `Project "${orgName}" was successfully create.`},
    editOrgSuccess: (orgName: string): string => { return `Project "${orgName}" has been successfully updated.`},
    orgLimitRules: (min: number, max: number): string => {return `Limit is ${min} to ${max}`},
    changeOrgsTitle: 'Change Organizations',
    yourCurrentOrg: 'Your Current Organization: ',
    numAssociatedOrgs: 'Number of organizations you are associated with:',
    projRefreshInstruct: 'Refresh your project list from your current organization',
    refreshProjects: 'Refresh Projects',
    changeOrgs: 'Change Organization',
    chooseOrgs: 'Choose the organization you wish to change to:',
    manageOrganizations: 'Manage Organizations',
    editOrg: 'Edit Organization Data',
    createOrg: 'Create Organization',
    orgName: 'Organization Name',
    maxProjects: 'Maximum Number of Projects',
    maxServicesPerProject: 'Maximum Services per Project',
    maxCerts: 'Maximum Certificates per Project',
    maxApiKeysPerUser: 'Maximum API Keys per User',
    maxFirewallRules: 'Maximum Firewall Rules',
    enterOrgName: 'Enter the name of the new organization below.',
    organizations: 'Organizations',
    orgLimits: 'The following limits are set for this organization',
    noOrgs: 'No organizations exist.',
    editLimits: 'Edit organization name and setting limits below',
    saveOrgInfo: 'Save Organization Data',
}

const CSALite_STRINGS = {
    CSALite_title: 'CSA Lite Configuration',
    statusRecentCSAReq: 'Status of Recent CSA Lite Requests',
    uploadNewCSAData: 'Upload New CSALite Configuration',
    verifyCSV: 'Verify CSA CSV File',
    uploadFile: 'Upload File',
    uploadFileWarning: 'The file you select will overwrite previous versions of your configurations.',
    uploadInstruct: 'Choose a configuration file to upload. ',
    noRequests: 'There are currently no CSA Uploads for this service.',
    dataLoadInProject: 'CSA Data load is in progress',
}

export {
    ACCOUNT_STRINGS,
    ANALYTICS_STRINGS,
    CACHE_STRINGS,
    LOG_STRINGS,
    LOGIN_STRINGS,
    MISC_STRINGS,
    NAVSTRINGS,
    SERVICES_STRINGS,
    SUBNAVSTRINGS,
    BURGERSTRINGS,
    API_DOCUMENTATION,
    REPORT_STRINGS,
    ERROR_STRINGS,
    ADMIN_STRINGS,
    ORGANIZATION_STRINGS,
    CSALite_STRINGS,
    SHARED_JOB_STRINGS,
}
