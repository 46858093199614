import * as React from 'react';
// import * as ReactDom from 'react-dom';

export interface SPProps {
    hideTitle?: boolean,
    panelClasses?: string,
    panelId: string,
    title: string | JSX.Element,
    titleClasses?: string,
}

class SharedPanel extends React.Component<SPProps> {
    public render(): JSX.Element {
        // eslint-disable-next-line react/prop-types
        const panelChildren = React.Children.map(this.props.children, child => {
            return child;
        });

        const panelClasses: string = 'shared-panel ' + 
                            (this.props.panelClasses ? this.props.panelClasses : '');
        const hideTitle = this.props.hideTitle;
        let titleClasses: string = (this.props.titleClasses ? this.props.titleClasses : '');
        titleClasses = titleClasses + ((hideTitle !== undefined && hideTitle === true) ? ' hide' : '');

        return (
            <div className={panelClasses} >
                <div className={titleClasses}>{this.props.title}</div>
                {panelChildren}
            </div>
        );
    }
}

export default SharedPanel;