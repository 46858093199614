import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { State } from '../App';
import { actionTypes } from '../actions/actionCreatorTypes';
import { redirectTo } from '../actions/actionCreators';

import { ZRestResponse, ServerConfig, FetchError, ResetTokenInfo, CreateUserBody } from 
            '../data/queryResultDefinitions';

import { State as AuthNServerData, AuthenticationType } from '../reducers/authentication';
import { buildLoginServerURL } from '../reducers/serverEnvironAccessor'
import { PwdResetState } from '../reducers/reducerEnums';

import { ZGet, ZPost } from '../shared/backend';
import { LOGIN_STRINGS as Strs, ADMIN_STRINGS, MISC_STRINGS } from '../shared/strings';
import { PwdValidationState } from '../shared/authUtils';
import { NotificationStyles } from '../shared/constants';
import LoginPanel from '../shared/LoginPanel';
import ChangePwdCtrl from '../shared/ChangePwdCtrl';
import ChangeProfileCtrl from '../shared/ChangeProfileCtrl';
import ZURLs from '../shared/urls';
import ZButton from '../shared/ZButton';
import ZInputTextBox from '../shared/ZInputText'; 
import NotificationControl from '../shared/Notification';
// import { zhistory } from '../store';
import { store } from '../store';

import logger from '../shared/logUtilities';

interface ResetProps extends State {
    authNServerData: AuthNServerData;
    saveServerConfig: (serverConfig: ServerConfig) => actionTypes;
    validatePassword: (password: string, newPassword: string, confirmPassword: string) => actionTypes;
    validateProfile: (fname: string, lname: string, email: string, phone: string) => actionTypes;
    resetPassword: ( success: boolean) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    setPwdResetTokenInfo: (authenticationType: AuthenticationType, token: string, tokenStatus: ResetTokenInfo) => actionTypes;
    redirectTo: (url: string) => actionTypes;
}

class Reset extends React.Component<ResetProps> {
    public componentDidMount(): void {
        if (! this.props.authNServerData.serverConfigLoaded) {
            const configURL = (ZURLs.getSysConfigURL( ) as string) + 'conf.json';
            const promise = ZGet(configURL, '');
            promise.then(( lData: ZRestResponse ) => {
                const serverConfig = lData as ServerConfig;
                this.props.saveServerConfig(serverConfig);
            })        
            .catch((errStr) => {
                const p = errStr as Promise<FetchError>;
                p.then((err) => {
                    console.log(`Reset.componentDidMount: fetch failed: ${err}`);

                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.handleCloseNotification();
                })
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public componentDidUpdate(prevProps: ResetProps): void {
        const authData = this.props.authNServerData;
        const systemState = this.props.systemNavProjectState;
        if ( systemState.redirectUrl && systemState.redirectUrl.length > 0) {
            this.props.redirectTo('');
        }
        if (authData && authData.serverConfigLoaded && authData.pwdResetState === PwdResetState.unknown) {
            const resetKeyIdx = window.location.href.indexOf('token');
            const resetKey = window.location.href.substring(resetKeyIdx + 6);
            this.getTokenStatus(resetKey);
        }
    }

    public handleResetActivateButtonClick(): void {
        const authData = this.props.authNServerData;
        const msgList = {
            [AuthenticationType.resetpwd]: Strs.pwdChangeSuccessful,
            [AuthenticationType.activate]: Strs.accountIsActivated,
            [AuthenticationType.invite]: Strs.acceptedInvitation
        }

        const json = this.buildJsonForResetActivate(authData)

        const msg = msgList[authData.authenticationType];
        const url = authData.resetTokenInfo.url ? authData.resetTokenInfo.url : authData.resetTokenInfo['reset_password_url'];
        this.executeServerCmd(url, json, msg, true);
    }

    private buildJsonForResetActivate(authData: AuthNServerData): string {
        let json = ''
        switch(authData.authenticationType) {
            case AuthenticationType.resetpwd:
                json = JSON.stringify({'new_password': authData.newPassword});
                break;


            case AuthenticationType.invite:
                json = JSON.stringify({'password': authData.newPassword});
                break;

            case AuthenticationType.activate: {
                const userBody: CreateUserBody = {
                    'email': authData.email,
                    'first_name': authData.firstName,
                    'last_name':  authData.lastName,
                    'password': authData.newPassword,
                }
                if (authData.phoneNumber.length > 0) {
                    userBody.phone = authData.phoneNumber;
                }

                json = JSON.stringify(userBody)
            }
            break;

            default:
                break;
        }
        
        return json;
    }

    public handleResendButtonClick(): void {
        const authData = this.props.authNServerData;
        const msgList = {
            [AuthenticationType.resetpwd]: Strs.newResetEmailSent,
            [AuthenticationType.activate]: Strs.newActivateEmailSent,
            [AuthenticationType.invite]: Strs.newInvitationEmailSent
        }
        const msg = msgList[authData.authenticationType];
        const url = authData.resetTokenInfo.url ? authData.resetTokenInfo.url : authData.resetTokenInfo['reset_password_url'];
        this.executeServerCmd(url, '', msg, false);
    }

    public render(): JSX.Element { 
        const resetToken = this.props.authNServerData.resetTokenInfo;
        const orgName = resetToken.meta && resetToken.meta.org_name ? resetToken.meta.org_name : '';
        const titleList = {
            [AuthenticationType.resetpwd]: Strs.resetPwd,
            [AuthenticationType.invite]: Strs.acceptInvitation(orgName),
            [AuthenticationType.activate]: '' //Strs.activateUser
        }
        
        const instructList = {
            [AuthenticationType.resetpwd]: Strs.resetPwdInstruct,
            [AuthenticationType.invite]: '',
            [AuthenticationType.activate]: Strs.activateInstruct
        }
        
        const invalidTokenMsgList = {
            [AuthenticationType.resetpwd]: Strs.resetPwdMaxTryReached,
            [AuthenticationType.invite]: Strs.acceptInvitationTryReached,
            [AuthenticationType.activate]: Strs.activateMaxTryReached
        }
        
        const systemState = this.props.systemNavProjectState;
        const msg: string = systemState.notificationData.message ? systemState.notificationData.message : '';
        const authData = this.props.authNServerData;
        const authType = authData.authenticationType;
        const title = titleList[authType];

        let instruct: JSX.Element = <span></span>;
        let markup = <div></div>;
        let copyrightPanelBottom = false;
        let resetCSS = 'display-box1';
        let instructMarkup = <div></div>
        let buildInstructionMarkup = true;

        if (this.props.authNServerData && this.props.authNServerData.serverConfigLoaded) {
            if (authData.resetTokenInfo.token_valid === undefined || authData.pwdResetState === PwdResetState.unknown) {
                return <span></span>;
            }

            if ( systemState.redirectUrl && systemState.redirectUrl.length > 0) {
                return <Redirect to={systemState.redirectUrl} />
            } else if (systemState.redirectUrl) {
                logger.alert('redirect URL is undefined.')
            }

            switch(authData.pwdResetState) {
                case PwdResetState.validTokenGetPwd:
                    instruct = <span>{instructList[authType]}</span>
                    markup = this.buildResetActivatePanel(authType);
                    copyrightPanelBottom = authType === AuthenticationType.activate;
                    if (authType === AuthenticationType.invite) {
                        resetCSS = 'display-box2';
                        buildInstructionMarkup = false;
                    }
                    break;

                case PwdResetState.validTokenExpired:
                    instruct = <span>{Strs.resetPwdTokenExpired}</span>
                    markup = this.renderValidTokenExpired(authType);
                    break;

                case PwdResetState.invTokenMaxAttempts:
                    instruct = <span>{invalidTokenMsgList[authType]}</span>
                    break;

                case PwdResetState.invTokenMaxRateReached:
                    instruct = this.renderMaxRateMsg(authType, authData.resetTokenInfo.retry_after_hours)
                    break;


                default:
                    instruct = <span>{Strs.invalidToken}</span>
                    resetCSS = 'display-box2';
                    break;
            }

            if (buildInstructionMarkup) {
                instructMarkup = (
                    <div className="row popup">
                        <div className="col-md-1"></div>
                        <div className="col-md-11 instructions">{instruct}</div>
                    </div>
                )
            }

        } else {
            if (msg.length === 0) {
                return markup;
            }
            markup = ( <div>{msg}</div>);
        }

        return (
            <div id="password-reset">                       
                <NotificationControl show={this.props.systemNavProjectState.notificationData.show} message={msg}
                                style={this.props.systemNavProjectState.notificationData.style}
                                cssStyles="errorPosition"
                                closeHandler={(): void => { this.handleCloseNotification() }} />
                <LoginPanel title={title} msg={''} lpCSS={resetCSS} copyrightPanelBottom={copyrightPanelBottom}>
                    {instructMarkup}
                    {markup}
                </LoginPanel>
            </div>
        );
    }

    private renderMaxRateMsg = (authType: AuthenticationType, retryTime: number): JSX.Element => {
        const maxRateInstruct1List = {
            [AuthenticationType.resetpwd]: Strs.resetPwdTryToOften,
            [AuthenticationType.invite]: Strs.resendAcceptInvitationLink,
            [AuthenticationType.activate]: Strs.activateUserTryToOften
        }
        const maxRateInstruct2List = {
            [AuthenticationType.resetpwd]: Strs.resetPwdRetryTime,
            [AuthenticationType.invite]: Strs.invitationRetryTime,
            [AuthenticationType.activate]: Strs.activateRetryTime
        }
        const instruct1 = maxRateInstruct1List[authType];               // (isActivateUser) ? Strs.activateUserTryToOften : Strs.resetPwdTryToOften;
        const instruct2 = maxRateInstruct2List[authType](retryTime);    // (isActivateUser) ? Strs.activateRetryTime(retryTime) : Strs.resetPwdRetryTime(retryTime);
        const instruct = (
            <>
                <div>{instruct1}</div>
                <br/>
                <div>{instruct2}</div>
            </>
        )
        return instruct;
    }

    private renderValidTokenExpired = (authType: AuthenticationType): JSX.Element => {
        const tokenExpiredMsg = {
            [AuthenticationType.resetpwd]: Strs.resendResetLink,
            [AuthenticationType.invite]: Strs.resendAcceptInvitationLink,
            [AuthenticationType.activate]: Strs.resendActivateLink
        }
        const resendBtn =tokenExpiredMsg[authType];
        const markup: JSX.Element = (<div>
            <ZButton 
            btnCls="login-btn-style"
            onClick={(): void => {this.handleResendButtonClick()}} 
            btnType="primary">{resendBtn}</ZButton>
         </div>)

         return markup
    }

    private buildResetActivatePanel = (authType: AuthenticationType): JSX.Element => {
        const pwdValidation: PwdValidationState = this.props.authNServerData.pwdValidation;

        const resetPanelBuilder = {
            [AuthenticationType.resetpwd]: this.buildPwdResetPanel,
            [AuthenticationType.invite]:   this.buildPwdVerifyPanel,
            [AuthenticationType.activate]: this.buildActivatePanel
        }
        
        const resetBtnText = {
            [AuthenticationType.resetpwd]: Strs.resetPwd,
            [AuthenticationType.invite]:   Strs.confirmInvitation,
            [AuthenticationType.activate]: Strs.activateUser
        }

        const markup: JSX.Element = (resetPanelBuilder[authType])();
        const btnText = resetBtnText[authType];

        return (
            <>
            {markup}
            <div className="row">
                <div className="col-md-12">
                    <ZButton disabled={! pwdValidation.validPwd } btnCls="login-btn-style"
                                onClick={(): void => {this.handleResetActivateButtonClick()}}  btnType="primary">
                    <span><FontAwesomeIcon icon={['fas', 'lock']} /> {btnText}</span>
                    </ZButton>
                </div>
            </div>
            </>
        )
    }

    private buildPwdResetPanel = (): JSX.Element => {
        const { newPassword, confirmPassword } =  this.props.authNServerData;
        const pwdValidation: PwdValidationState = this.props.authNServerData.pwdValidation;

        const markup = (
            <div id="pwd-reset" className="row">
                <div className="col-md-1"></div>
                <div className="col-md-11 popup">
                    <ChangePwdCtrl
                        newPassword={ newPassword } 
                        confirmPassword={ confirmPassword } 
                        validationState={pwdValidation}
                        handleValidation={(oldPwd, newPwd: string, confirmPwd: string): void => {
                            this.props.validatePassword('not-needed',  newPwd, confirmPwd); }}
                        validatationBkgCss="background-transparent"
                    />
                </div>
            </div>
        )
        return markup;
    }

    private buildPwdVerifyPanel = (): JSX.Element => {
        const { newPassword, resetTokenInfo } =  this.props.authNServerData;
        const email = resetTokenInfo.meta && resetTokenInfo.meta.email ? resetTokenInfo.meta.email : ''

        const markup = (
            <>
            <div id="invite">
                <div>{Strs.acceptInvitationInstruct}</div>
                <div>{ MISC_STRINGS.email }:&nbsp;&nbsp;{email}</div>
                <div>
                    <ZInputTextBox 
                        label={Strs.verifyPasswordLabel}
                        value={newPassword}
                        ikey="pwd"
                        inputType="password"
                        onTextChange={(pwd: string): void => { this.props.validatePassword('not-needed', pwd, pwd)}}
                        disabled={false}
                        maxLength={255}
                        placeholderText={Strs.password}
                        validationMsg={''}
                        extraCSS=""
                    />
                </div>
            </div>
            </>
        )
        return markup;
    }

    private buildActivatePanel = (): JSX.Element => {
        const { newPassword, confirmPassword, firstName, lastName, phoneNumber, profileValidation, resetTokenInfo } =  this.props.authNServerData;
        const pwdValidation: PwdValidationState = this.props.authNServerData.pwdValidation;
        const email = resetTokenInfo.meta !== undefined ? resetTokenInfo.meta.email : '';

        const markup = (
            <div id="activate">
                <div className="title">{ADMIN_STRINGS.userProfile}</div>
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">
                        <ChangeProfileCtrl firstName={firstName} lastName={lastName} readOnlyEmail={true}
                            phoneNumber={phoneNumber} email={email}   
                            profileValidation={profileValidation}
                            validationBkgCss="yellow"
                            validateProfile={
                                (fName: string, lstName: string, emailAddr: string, phoneNum: string): void => {
                                    this.props.validateProfile(fName, lstName,  emailAddr, phoneNum);
                                }}
                        />
                    </div>
                </div>
                <div className="title pad-top-3">{ADMIN_STRINGS.password}</div>
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">
                        <ChangePwdCtrl
                            newPassword={ newPassword } 
                            confirmPassword={ confirmPassword } 
                            validationState={pwdValidation}
                            handleValidation={(oldPwd, newPwd: string, confirmPwd: string): void => {
                                this.props.validatePassword('not-needed',  newPwd, confirmPwd); }}
                            validatationBkgCss="background-transparent smaller-text"
                        />
                    </div>
                </div>
            </div>
        )
        return markup;
    }

    private getTokenStatus = (token: string): void => {
        let authType = AuthenticationType.login;

        if (window.location.href.indexOf(ZURLs.activate) !== -1) {
            authType = AuthenticationType.activate;
        } else if (window.location.href.indexOf(ZURLs.reset) !== -1) {
            authType = AuthenticationType.resetpwd;
        } else if (window.location.href.indexOf(ZURLs.invite) !== -1) {
            authType = AuthenticationType.invite;
        }

        if (token.length > 1) {
            const tokenStatus = buildLoginServerURL(this.props.authNServerData, ZURLs.serverTokenValidation);
            const promise = ZGet(tokenStatus, {token});

            promise.then( ( lData: ZRestResponse ) => {
                this.props.setPwdResetTokenInfo(authType, token, lData as ResetTokenInfo );
            })
            .catch((errStr) => {
                const p = errStr as Promise<FetchError>;
                p.then((err) => {
                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.handleCloseNotification();
                })
            })
        } else {
            console.log('Reset.gettokenStatus - auth token length is 0.  Ignoring request')
        }
    }

    public executeServerCmd(resetUrl: string, params: string, successMsg: string, changeToLoginPage: boolean): void {
        const url = buildLoginServerURL(this.props.authNServerData, resetUrl);

        const promise = ZPost(url, params);

        promise.then( ( ) => {
            this.props.showNotification(NotificationStyles.success, successMsg);
            this.handleCloseNotification();
            this.props.resetPassword( true );
            if (changeToLoginPage) {

                setTimeout( () => { 
                    store.dispatch(redirectTo(ZURLs.login));
                }, 5000);
            }
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.handleCloseNotification();
            })
        })
    }

    private handleCloseNotification(): void {
        setTimeout(() => {this.props.closeNotification()}, 5000);
    }
}

export default Reset;
