// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../actions/actionCreators';
import { actionTypes } from '../../actions/actionCreatorTypes';

import { State } from '../../App';

import { AnalyticsOptions, BooleanMap } from '../../data/metricsAndOptionsDefs';
import { ZServiceFeatures } from '../../data/queryResultDefinitions';

import { getProjectFeatures } from '../../reducers/projectAccessors';
import { getCurrentMetricSelectionStates } from '../../reducers/uiAccessors';
import {  OptionStateDefinition, } from '../../reducers/analyticsState';

import { ANALYTICS_STRINGS as STRs, MISC_STRINGS, ERROR_STRINGS as ERRs } from '../../shared/strings';
import { NotificationStyles } from '../../shared/constants';
import logger from '../../shared/logUtilities';
import ZDropDown from '../../shared/ZDropDown';
import TimeControlTab from '../../shared/TimeControl'
import ZButton from '../../shared/ZButton';
import { SysFeatureEnums } from '../../reducers/reducerEnums';

interface OptionProps extends State {
    applyHandler(): boolean;
    refreshHandler(): boolean;
    setAnalyticOption: (optionName: string, value: number) => actionTypes; 
    applySettings: () => actionTypes;
    setQueryTimeFormat: (relativeTime: boolean, component: string) => actionTypes;
    setUriCodeIdx: (index: number) => actionTypes;
    setFromToTime: (from: moment.Moment, to: moment.Moment) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
}

// eslint-disable-next-line 
class Options extends React.Component<any, OptionProps> {
    
    public render(): JSX.Element {
        // const showOrigin: boolean = this.props.uiState.selectedSubNavTab === SysFeatureEnums.efrAnl;
        const analyticsState = this.props.analyticsUIState;
        const currentOptions: AnalyticsOptions = analyticsState.analyticOptions;
        // const uriCodes: ProjUriCode[] = getCurrentUriCodes(this.props.systemNavProjectState);
        const selectedOptions: OptionStateDefinition = analyticsState.futureAnalyticState.selectedAnalyticOptions;
        const features: ZServiceFeatures = getProjectFeatures(this.props.systemNavProjectState);
        const applyBtnDirty = (analyticsState.isAnalyticsTabDirty) && this.areMetricsSelected();
        const isLoading = analyticsState.isLoading;

        const block1: JSX.Element[] = this.buildBlock1(selectedOptions);
        const block2: JSX.Element = this.buildBlock2(currentOptions, selectedOptions, 
                                                     features, applyBtnDirty, isLoading);
        const dividerClassName = this.needDivider(features, currentOptions) ? 'divider' : 'no-divider'
        return (
            <div className="analytics-options">
                <div className="block1">
                    {block1}
                </div>
                <div className={dividerClassName}></div>
                <div className="block2">
                    {block2}
                </div>
            </div>
        )
    }

    private selectedTabHandler(selectedTab: number): void {
        this.props.setQueryTimeFormat( selectedTab === 0, 'ANALYTICS');
    }

    private buildBlock1(selectedOptionIndexs: OptionStateDefinition): JSX.Element[] {
        const options: JSX.Element[] = [];
        const {analyticsUIState} = this.props;
        
        if (selectedOptionIndexs === undefined || selectedOptionIndexs.period === undefined) {
            options.push( <div key='loading'>{MISC_STRINGS.loading}</div>);
            return options;
        }

        const tc = <TimeControlTab key="analytic-tc"
            showingRelativeTime={analyticsUIState.futureAnalyticState.showingRelativeTime}
            relativeTimeIdx={selectedOptionIndexs.period}
            from={analyticsUIState.futureAnalyticState.from}
            to={analyticsUIState.futureAnalyticState.to}
            handleTabChange={(selectedTab: number) => { this.selectedTabHandler(selectedTab)}}
            handleRelativeSelectChange={(index, optionName) => {this.handleOptionSelect(index as number, optionName as string)}}
            handleFromChange={(from: moment.Moment | string) => {this.fromChangeHandler(from as moment.Moment)}}
            handleToChange={(to: moment.Moment | string) => {this.toChangeHandler(to as moment.Moment)}}
        />;
        options.push(tc);

        // if (uriCodes.length > 0) {
        //     const names: string[] = uriCodes.map(uriCode => { return `[${uriCode.name}] ${uriCode.uri_prefix}`});
        //     const uriDropdown = ZDropDown(    
        //         names,
        //         analyticsUIState.futureAnalyticState.uriCodeIdx, 
        //         'uri-code',
        //         (index, optionName) => {this.handleOptionSelect(index, optionName as string)},
        //         {label: STRs.uriCodeLabel, optionName: 'uriCode'}
        //     )
        //     options.push(uriDropdown);
        // }

        // if (!uriCodes) {
        //     logger.alert('help');
        // }

        return options;
    }

    private needDivider(features: ZServiceFeatures, currentOptions: AnalyticsOptions): boolean {
        const analyticsState = this.props.analyticsUIState;
        const currentFeature = analyticsState.currentFeature;

        let needIt = (features.metrics_by_geo && features.metrics_by_geo.enabled &&
                      (currentOptions.stateName !== undefined));

        needIt = needIt || (features.network_real_user_metrics && features.network_real_user_metrics.enabled && 
                            // bViewingAnalyticTab(this.props.systemNavProjectState, analyticsTabs.nrum) &&
                            (currentOptions.statistics !== undefined) && 
                            (this.props.analyticsUIState.analyticOptions !== undefined))

        needIt = needIt || ( currentFeature === SysFeatureEnums.csmAnl || currentFeature === SysFeatureEnums.dnsDirAnl );
        return needIt;
    }

    private buildBlock2(currentOptions: AnalyticsOptions, selectedOptionIndexs: OptionStateDefinition,
                        features: ZServiceFeatures, applyBtnDirty: boolean, isLoading: boolean): JSX.Element {
        const options: JSX.Element[] = [];
        const anlState = this.props.analyticsUIState;

        if (selectedOptionIndexs === undefined || selectedOptionIndexs.period === undefined) {
            return <div key='loading'>{MISC_STRINGS.loading}</div>;
        }

        if (features.metrics_by_geo && features.metrics_by_geo.enabled && currentOptions.stateName) {
            options.push(
                ZDropDown(
                    currentOptions.stateName,
                    selectedOptionIndexs.stateName, 
                    'states',
                    (index, optionName) => {this.handleOptionSelect(index, optionName as string)},
                    {label: STRs.stateLabel, optionName: 'stateName'}
                    ));
        }

             // make sure we are viewing the NRUM or API tab
        if (features.network_real_user_metrics && features.network_real_user_metrics.enabled && 
            currentOptions.statistics && this.props.analyticsUIState.analyticOptions) {
                options.push(ZDropDown( 
                    currentOptions.statistics,
                    selectedOptionIndexs.statistics, 
                    'statistics',
                    (index, optionName) => {this.handleOptionSelect(index, optionName as string)},
                    {label: STRs.statisticsLabel, optionName: 'statistics'}));
        }

        if (!anlState.isLegacyFeature && currentOptions.cdns !== undefined) {
            options.push(ZDropDown( 
                currentOptions.cdns,
                selectedOptionIndexs.cdn, 
                'cdns',
                (index, optionName) => {this.handleOptionSelect(index, optionName as string)},
                {label: STRs.cdns, optionName: 'cdn'}));
        }

        if (!anlState.isLegacyFeature && currentOptions.geos !== undefined) {
            options.push(ZDropDown( 
                currentOptions.geos,
                selectedOptionIndexs.geos, 
                'geos',
                (index, optionName) => {this.handleOptionSelect(index, optionName as string)},
                {label: STRs.geos, optionName: 'geos'}));
        }

        if (!anlState.isLegacyFeature && currentOptions.healthChk !== undefined) {
            options.push(ZDropDown( 
                currentOptions.healthChk,
                selectedOptionIndexs.healthChk, 
                'healthChk',
                (index, optionName) => {this.handleOptionSelect(index, optionName as string)},
                {label: STRs.healthChecks, optionName: 'healthChk'}));
        }
            
        const filterLabel = options.length > 0 ? STRs.filters : '';
        let rowItem1 = <span></span>;
        let rowItem2 = <span></span>;
        let rowItem3 = <span></span>;

        if ( options.length > 0 ) {
            rowItem1 = options[0];
            rowItem2 = options.length >= 2 ? options[1] : rowItem2;
            rowItem3 = options.length >= 3 ? options[2] : rowItem3;
        }

        const block = (
            <>
                <div className="block2Grid" key="options-block2">
                    <div>{filterLabel}</div>   
                    <div className="apply-items"></div>
                    <div>{rowItem1}</div>
                    <div>{rowItem2}</div>
                    <div>{rowItem3}</div>
                    <div className="refreshItems">
                        <ZButton onClick={(): void => {this.handleApplyBtn() }} disabled={!applyBtnDirty}  btnCls="apply" 
                                 btnType="primary">
                            {MISC_STRINGS.applyBtn}</ZButton>
                        <div className="refresh" onClick={this.props.refreshHandler}>
                            <FontAwesomeIcon spin={isLoading} icon={['fas', 'sync']} /></div>
                    </div>
                </div>
            </>
        );
        return block;
    }

    private fromChangeHandler(from: moment.Moment | string): void {
        logger.log(from);
        this.props.setFromToTime(from as moment.Moment, this.props.analyticsUIState.futureAnalyticState.to)
    }

    private toChangeHandler(to: moment.Moment | string): void {
        logger.log(to);
        this.props.setFromToTime(this.props.analyticsUIState.futureAnalyticState.from, to as moment.Moment )
    }

    private handleOptionSelect(index: number, optionName: string ): void {
        logger.log('optionName: ' + optionName + '; option:  ' + index);
        if (optionName === 'uriCode') {
            if (index !== -1) {
                this.props.setUriCodeIdx(index);
            }
        } else {
            this.props.setAnalyticOption(optionName, index);
        }
    }

    private handleApplyBtn(): void {
        let errorIdx = 0;
        const errorMsg = [
            ERRs.absTimeInvalidFrom,
            ERRs.absTimeInvalidTo,
            ERRs.absTimeFutureToDate,
            ERRs.absTimeFromAfterTo,
            ERRs.absTimeFromDateToOld,
            ERRs.absTimeFromWithin7ofTo
        ];
        if (!this.props.analyticsUIState.futureAnalyticState.showingRelativeTime) {
            const {to, from} = this.props.analyticsUIState.futureAnalyticState;

            if (typeof from === 'string') {
                errorIdx = 1;
            } else if (typeof to === 'string') {
                errorIdx = 2;
            }

            if (errorIdx === 0) {
                const now = moment();
                const fromPlus7 = moment(from.toObject()).add(1, 'week');
                const oneYearBack = moment().subtract(1, 'y');

                if (to.isAfter(now)) {
                    errorIdx = 3;  // to past now
                } else if (from.isAfter(to)) {
                    errorIdx = 4;  // from is after to
                } else if (from.isBefore(oneYearBack)) {
                    errorIdx = 5;  // from is older than a year back
                } else if (fromPlus7.isBefore(to)) {
                    errorIdx = 6;
                }
            }
            if (errorIdx > 0) {
                this.props.showNotification(NotificationStyles.danger, errorMsg[errorIdx - 1]);
                setTimeout(() => this.props.closeNotification(), 5000);
            } else {
                console.log( 'options.tx.  Bad errorIdx in handleApplyBtn');

            }
        }
        if (errorIdx === 0) {
            (this.props.applyHandler)();
        }
    }

    private areMetricsSelected(): boolean {
        let metricsSelected = false;
        
        if (this.props.analyticsUIState.isLegacyFeature) {
            const metricSelections: BooleanMap = getCurrentMetricSelectionStates(this.props.analyticsUIState);
            const keys = Object.keys(metricSelections);
            for (let i = 0, len = keys.length; i < len; i++) {
                if (metricSelections[keys[i]]) {
                    metricsSelected = true;
                    break;
                }
            }
        } else {
            metricsSelected = true;
        }

        return metricsSelected;
    }
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        analyticsUIState: state.analyticsUIState,
        systemNavProjectState: state.systemNavProjectState,
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(Options);

// commented out code from buildBlock2

        // if (features.origin_tracking.enabled && currentOptions.observer) {
        //     options.push(this.buildOptionDropdown(
        //         STRs.observerLabel, 
        //         'observer',
        //         selectedOptions.observer, 
        //         currentOptions.observer));
        // }

        // // EFR always gets the origin list
        // // Others get the origin list only if the observer dropdown is set to origin.
        // // Note: The currentOptions.origins test is to make Typescript happy (field is marked as optional)
        // if ((showOrigin || features.origin_tracking.enabled && selectedOptions.observer === STRs.originOptionLabel) 
        //       && currentOptions.origin) {
        //     options.push(this.buildOptionDropdown(
        //         STRs.originOptionLabel, 
        //         'origin',
        //         selectedOptions.origin, 
        //         currentOptions.origin));
        // }

// another commented out function

/*
    private buildOptionDropdown(label: string, optionName: string, selected: string, optionList: string[],
                                bIgnoreLabelIfNotAll?: boolean, altNameList?: string[], ctrlId?: string): JSX.Element {
        if (! selected ) {
            return (<span key={ 'x' + label} />);
        }
        const dropdownItems: JSX.Element[] = optionList.map((name: string, index: number) => {
            let itemName = name;
            if (altNameList) {
                itemName = altNameList[index];
            }
            return (
              <MenuItem
                bsClass="nav-menu-item zoption"
                key={name}
                eventKey={name}
                onSelect={() => this.handleOptionSelect(optionName, name)}>{itemName}
              </MenuItem>
            )
          });
 
        let dropdown: JSX.Element;

        const title = (bIgnoreLabelIfNotAll && selected !== MSTRs.allOption) ? 
                       selected : label + ': ' + selected;
        if (! title) {
            alert('no option title');

        }
        
        ctrlId = ctrlId ? ctrlId : 'anlOption' + label;
        dropdown = (
              <DropdownButton id={ctrlId} title={title} disabled={optionList.length === 1}
                key={ctrlId}>
                {dropdownItems}
              </DropdownButton>
            );
      
        return dropdown;
    }
  */