import * as React from 'react';

const ApiDocPurgeStatus = (): JSX.Element => {
    return ( 
        <>
        <div className="z-title">Get Cache Purge Status</div>

<div className="zsection">
    Zycada&apos;s Cache Purge Status API allows you to get the status of a purge request that was previously submitted to the
    delivery network. The job id of the purge request must be included in the URL.

    <div className="before-code"><h3> </h3></div>
    <table className="zlist">
        <tbody>
        <tr>
            <td>Method</td>
            <td className="method">GET</td>
        </tr>
        <tr>
            <td>Production URL:</td>
            <td className="zli-inner">
                https://api.zycada.com/v1/jobs/<i>[JOB_ID]</i>
            </td>
        </tr>
        <tr>
            <td>Staging URL</td>
            <td className="zli-inner">
                https://staging-api.zycada.com/v1/jobs/<i>[JOB_ID]</i>
            </td>
        </tr>
        <tr>
            <td>Header:</td>
            <td>
                <table className="izlist">
                    <tbody>
                    <tr>
                        <td>Authorization:</td>
                        <td>Bearer <i>[API_Key]</i></td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>

    <div className="before-code"><h3>Arguments</h3></div>
    <div className="simple-list">
        <li>The [JOB_ID] is a string returned by the purge endpoint</li>
        <li>The [API_KEY] is a string that establishes authorization to modify your assets. You can only modify your own assets.</li>
    </div>

    <div className="before-code"> <h3>Response</h3></div>
    The response body is a JSON object that describes the status of the purge request endpoint.
    The following describes the JSON object returned by the server.

    <pre className="expected-response">{`{
    "job_id": "JOB_ID",     // string, where "JOB_ID" is the job  supplied in the request URL.
    "uris": [ ],            // Array of strings. Each string is a purged URI.
    "uri_codes": [ ],        // Array of URI Codes which was used to specify a tree of data to purge.
                            // URI codes are defined below.
    "status": ""            // string indicating the status of the request.  One of the following values:
                            //      "accepted" | "in_progress" | "success" | "failed"
    "created_at": "",       // string. The creation time expressed in ISO 8601 format.
    "updated_at": "",       // string. The last update time expressed in ISO 8601 format.
    "message": ""           // string. Addition status information returned by the server.
}

URI code JSON object format:
{
    "domain_name": "",      // string. The  domain name the URI code resides in (e.g: zycada-images.zycada.com)
    "uri_code": ""          // string. URI code indicates a predefined path in the user's origin space.
}`}</pre>


    <div className="before-code"><h3>HTTP Response Status</h3></div>
    <ul className="simple-list sample-response">
        <li><h4>200</h4> Success</li>
        <li><h4>401</h4> Invalid API Key</li>
        <li><h4>400</h4> Missing job ID, or job ID doesn&apos;t exist </li>
    </ul>


    <div className="before-code"><h3>Sample Response</h3></div>
    The following demonstrates what the response from a purge status request might look like.
    <pre className="sample-response">{`{
    "created_at": "2019-01-14T09:10:10Z",
    "job_id": "purge-37B6B470D2F1537BC5808B55CC1F5EF9",
    "message": ""
    "status": "success",
    "updated_at": "2019-01-14T09:11:08Z",
    "uris": [
        "https://zycada-images.example.com/6$filterxlrg$"
    ],
    "uri_codes": [
        {
            "domain_name": "zycada-images.example.com",
            "uri_code": "product_5_image"
        }
    ],
}`}</pre>
</div>
</>
    )
}


export default ApiDocPurgeStatus;