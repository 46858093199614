import { LOGIN_STRINGS as Strs } from './strings'
import { ZUserTypes } from '../data/queryResultDefinitions';
import { parsePhoneNumberFromString, PhoneNumber, AsYouType } from 'libphonenumber-js'
import logger from './logUtilities';

export interface ProfileValidationState {
    validProfile: boolean;
    firstNameValid: boolean;
    lastNameValid: boolean;
    emailValid: boolean;
    phoneNumberValid: boolean;
}
  
export interface PwdValidationState {
    badCharMsg: string;
    badChars: string;
    letters: string;
    minChars: string;
    numbers: string;
    pwdMatch: string;
    validPwd: boolean;
}

export interface ValidationResult {
    isValid: boolean;
    s: string;
}

export interface ProfilePwdValidationState {
    profileValidation: ProfileValidationState;
    pwdValidation: PwdValidationState;

    password: string;               // this is set to empty string when the user logs in
    newPassword: string;            // this is set to empty string when the user logs in
    confirmPassword: string;        // this is set to empty string after pwd modification

    email: string;                  // this is set to empty string when the user logs in
    firstName: string;
    lastName: string;
    phoneNumber: string;

    role: ZUserTypes;
}

export const resetPwdProfileData = ( state: ProfilePwdValidationState, initProfile?: boolean ): void => {

    if (initProfile !== undefined && initProfile) {
        state.email =  '';
        state.firstName =  '';
        state.lastName =  '';
        state.phoneNumber =  '';

        state.profileValidation = {
            validProfile: false,
            firstNameValid: false,
            lastNameValid: false,
            phoneNumberValid: false,
            emailValid: false
        }
    }

    state.password =  '';
    state.newPassword =  '';
    state.confirmPassword =  '';
    
    state.pwdValidation = {
        badCharMsg: '',
        badChars: '',
        letters: 'red',
        minChars: 'red',
        numbers: 'red',
        pwdMatch: 'red',
        validPwd: false
    }
}

// r - required,
// n - numbers,
// a - a-z/A-Z/0-9; also '.' and '-'
// l - a-z/A-Z; also '.' and '-'
// e - email
// p - phone
export function validateEntry(s: string, valType: string): ValidationResult {
// tslint:disable no-shadowed-variable
    const regn = /^[0-9\s]*$/;
    const rega = /^[a-zA-Z0-9\s.-]*$/;
    const regl = /^[a-zA-Z\s.-]*$/;
    const regeml = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// tslint:enable no-shadowed-variable

    // make sure the field is not undefined and is a string.  Also trim it.
    s = (s) ? s : '';
    s = s.trim();

    if (valType.indexOf('r') !== -1) {      // check to make sure required field not empty
        if (!s.length) {
            return {isValid: false, s: ''};
        }
    }

    if (valType.indexOf('n') !== -1) {      // validate numbers
        if (!regn.test(s)) {
            return {isValid: false, s};
        }
    }
    if (valType.indexOf('l') !== -1) {      // Validate letters (and '.')
        if (!regl.test(s)) {
            return {isValid: false, s};
        }
    }
    if (valType.indexOf('a') !== -1) {      // Validate alphanumerics (a-z, A-Z, 0-9, '.' and '-'
        if (!rega.test(s)) {
            return {isValid: false, s};
        }
    }
    if (valType.indexOf('e') !== -1) {      // validate email
        if (!regeml.test(s)) {
            return {isValid: false, s};
        }
    }
    if (valType.indexOf('p') !== -1) {      // validate phone number (does international numbers too!)
        if (s.length > 0) {
            s = (s[0] === '0') ? s.substring(1) : s;
            s = (s[0] !== '+') ? '+' + s : s;
            const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(s);
            logger.log('phone number: ' + JSON.stringify(phoneNumber));
            if (!phoneNumber || !phoneNumber.isValid() ) {
                const asut = new AsYouType();
                const phInput = asut.input(s)
                const fpn = phInput;
                return {isValid: false, s: fpn};
            } else {
                const fpn = phoneNumber.formatInternational() as string;
                s = fpn;
            }
        } else {
            s = '';
        }
    }

    return {isValid: true, s};

}
    
const pwdRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,}$/;
const pwdRegex1 = /^[A-Za-z0-9$@$!%*#?&]$/;
const regn = /^(?=.*[0-9])/;
const rega = /^(?=.*[a-zA-z])/;
// const regsc = /^(?=.*[d$@$!%*#?&])/;

    
function getIllegalPwdChars(s: string): string {
    let badChars = '';
    for (let i = 0, len = s.length; i < len; i++) {
        const c = s.charAt(i);
        if (!pwdRegex1.test(c)) {
            badChars += c;
        }
    }

    return badChars;
}

// pass a dummy string for oldPwd (such as 'not-needed') if creating a new user or reseting a password
export function validatePwd(oldPwd: string, newPwd: string, confirmPwd: string): PwdValidationState {
    let validPassword = true;
    const results: PwdValidationState = {
        pwdMatch: 'red',
        numbers: 'red',
        letters: 'red',
        minChars: 'red',
        validPwd: true,
        badCharMsg: '',
        badChars: '',
    };

    if (!oldPwd || oldPwd.length === 0) {
        validPassword = false;
    }

    if (newPwd) {    // Make sure we have a good new password
        if (!pwdRegex.test(newPwd)) {    // check if it passes validity rules
            if (regn.test(newPwd)) {
                results.numbers = 'green';
            }
            if (rega.test(newPwd)) {
                results.letters = 'green';
            }

            if (newPwd.length >= 8) {
                results.minChars = 'green'
            }

            // check for illegal chars in the password
            const badChars = getIllegalPwdChars(newPwd);
            if (badChars.length > 0) {
                validPassword = false;
                results.badCharMsg = Strs.badCharacters;
                results.badChars = badChars;
            }
        } else {    // password is valid against rules
            results.numbers = results.letters = results.minChars = 'green';
        }

        if (confirmPwd) {
            if ((newPwd.length > 0 && confirmPwd.length > 0) &&
                (newPwd !== confirmPwd)) {
                validPassword = false;
            } else {
                results.pwdMatch = 'green'
            }
        } else {
            validPassword = false;
        }
    } else {
        validPassword = false;
    }

    results.validPwd = validPassword;
    return results;
}

const validateProfileField = (state: ProfilePwdValidationState, 
    entryName: string, value: string,  validationTest: string): ValidationResult => {
    const validationName = entryName + 'Valid';

    state[entryName] = value;
    const vResults = validateEntry(value, validationTest );
    state.profileValidation[validationName] = vResults.isValid;
    state.profileValidation.validProfile = (state.profileValidation.validProfile && vResults.isValid);

    return vResults;
}


export const initProfileData = ( state: ProfilePwdValidationState, firstName: string | undefined, 
                                 lastName: string | undefined, email: string | undefined, 
                                 phoneNumber: string | undefined, role?: ZUserTypes | undefined ): ProfilePwdValidationState => {
    state.firstName = firstName ? firstName : '';
    state.lastName = lastName ? lastName : '';
    state.email = email ? email : '';
    state.phoneNumber = phoneNumber ? phoneNumber : '';

    validateProfileField(state, 'firstName', state.firstName, 'rl');
    validateProfileField(state, 'lastName', state.lastName, 'rl');
    validateProfileField(state, 'email', state.email, 're');
    validateProfileField(state, 'phoneNumber', state.phoneNumber, 'p');

    state.role = role ? role : ZUserTypes.user;

    return state;
}

export const validateProfileData = ( state: ProfilePwdValidationState, firstName: string, 
                                     lastName: string, email: string, phoneNumber: string ): ProfilePwdValidationState => {
    state.profileValidation.validProfile = true;

    validateProfileField(state, 'firstName', firstName, 'rl');
    validateProfileField(state, 'lastName', lastName, 'rl');
    validateProfileField(state, 'email', email, 're');
    const vr = validateProfileField(state, 'phoneNumber', phoneNumber, 'p');
    state.phoneNumber = vr.s;

    return state;
}

export const validatePwdData = ( state: ProfilePwdValidationState, oldPwd: string, newPwd: string, confirmPwd: string ): 
                        ProfilePwdValidationState => {
    state.password = oldPwd;
    state.newPassword = newPwd;
    state.confirmPassword = confirmPwd;
    state.pwdValidation = validatePwd(state.password, state.newPassword, state.confirmPassword);

    return state;
}