import * as React from 'react';

import { actionTypes } from '../actions/actionCreatorTypes';
// import * as actionCreators from '../actions/actionCreators';
import { State } from '../App';

// import { ZUserMin } from '../data/queryResultDefinitions';
import { ZUserMin, OrgInfo, ProjectSolutions } from '../data/queryResultDefinitions';

import { SysFeatureEnums } from '../reducers/reducerEnums';

import { NotificationStyles, } from '../shared/constants';
import { MISC_STRINGS } from '../shared/strings'; 

import ChangeOrgs from './orgComps/ChangeOrgs';
import ManageOrgs from './orgComps/ManageOrgs';

interface OrgProps extends State {
    setOrgListIdx: (orgIndex: number) => actionTypes;
    setOrgList: (orgList: OrgInfo[], selectedOrgName?: string)  => actionTypes;
    setCurrentOrg: (orgId: string) => actionTypes;
    setUserInfo: (user: ZUserMin) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    applySettings: (error?: string) => actionTypes;
    redirectTo: (url: string) => actionTypes
    setSystemProjectSolutions: (solutions: ProjectSolutions[]) => actionTypes;

}

class Organizations extends React.Component<OrgProps> {
    public render(): JSX.Element {
        const uiState = this.props.systemNavProjectState;
        let page: JSX.Element = <span></span>;
        if (uiState.currentFeature === SysFeatureEnums.orgYourOrgs) { 
            page = <ChangeOrgs  {...this.props} /> 
        } else if (uiState.currentFeature === SysFeatureEnums.orgManageOrgs) { 
            page = <ManageOrgs  {...this.props} /> 
        } else {
            page = <div>{MISC_STRINGS.loading}</div>
        }

        return (
            <div className="feature-panel">
                <div className="servicesFeature">
                    {page}
                </div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
        )
    }
}

export default Organizations;