/* eslint-disable react/prop-types */
import * as React from 'react';

export interface LPProps {
    renderHeader: () => JSX.Element,
    renderBody: () => JSX.Element,
    renderFooter?: () => JSX.Element,
    cssClass?: string,              // must include height of panel if you want it to fill the page.
}

// tslint:disable-next-line: variable-name
const ListPanel: React.FunctionComponent<LPProps> = (props) => {
    const cssClass = props.cssClass ? props.cssClass : 'default-listpanel-css';
    const panelCSS = props.renderFooter ? 'list-panel ' + cssClass : 'list-panel-nofooter ' + cssClass;
    const footer = props.renderFooter ? (<div>{props.renderFooter()}</div>) : <span></span>;
    return (
        <div className={panelCSS}>
            <div>{props.renderHeader()}</div><div></div>
            <div>{props.renderBody()}</div>
            {footer}
        </div>
    )
}

export default ListPanel;