import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import { MISC_STRINGS as MSTRs } from './strings';
import logger from './logUtilities';

const dropdownIcon = () => {
    return (
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
    )
}
export interface DropdownOptions {
  bIgnoreLabelIfNotAll?: boolean;
  disabled?: boolean;
  label?: string;
  optionName?: string; // Value passed along with index to help id dropdown.
  pullRight?: boolean;
  maxButtonTextLength?: number;
}

const emptyOptions: DropdownOptions = {
    bIgnoreLabelIfNotAll: false,
    disabled: false,
    optionName: '',
    label: '',
    pullRight: false,
    maxButtonTextLength: 32768,
};

// tslint:disable-next-line: variable-name
const ZDropDown = (
    optionList: string[], // dropdown items
    selectedIdx: number, // selected item
    ctrlId: string, // required HTML id
    handleOptionsSelect: (index: number, optionName?: string, value?: string) => void, // select calback function
    options?: DropdownOptions, // modifications for the dropdown.
    values?: string[]
): JSX.Element => {
    const getRandomLabel = (): string => {
      return (Math.random() * 1000).toString(10);
    };

    let ddOptions: DropdownOptions;

    if (selectedIdx === undefined || optionList.length === 0) {
        return <span key={'x' + getRandomLabel()} />;
    }

    const selectedItem = optionList[selectedIdx];
    let maxTitleLength = 32768

    if (options) {
        ddOptions = options;
        ddOptions.optionName = ddOptions.optionName ? ddOptions.optionName : '';
        ddOptions.pullRight = !!ddOptions.pullRight;
        ddOptions.disabled = !!ddOptions.disabled;
        ddOptions.bIgnoreLabelIfNotAll = !!ddOptions.bIgnoreLabelIfNotAll;

        const maxButtonTextLen = options.maxButtonTextLength;
        maxTitleLength = maxButtonTextLen && !Number.isNaN(maxButtonTextLen) ? maxButtonTextLen : maxTitleLength;
    } else {
        ddOptions = emptyOptions;
    }

    const dropdownItems: JSX.Element[] = optionList.map(
        (dropdownItem: string, index: number) => {
            return (
              <Dropdown.Item
                key={dropdownItem + index} eventKey={dropdownItem}
                onSelect={(): void => { 
                    const value = (values !== undefined && values[index] !== undefined) ? values[index] : undefined;
                    handleOptionsSelect(index, ddOptions.optionName, value); }}
              > {dropdownItem}</Dropdown.Item>
            );
        }
    );

    
    let title;
    if (ddOptions.label) {
        title = (ddOptions.bIgnoreLabelIfNotAll && selectedItem !== MSTRs.allOption) ?
                selectedItem : ddOptions.label + ': ' + selectedItem;
    } else {
        title = selectedItem;
    }

    title = title.length > maxTitleLength ? (title.substring(0, maxTitleLength) + '...') : title;

    if (!title) {
        logger.alert('ZDropDown: no option title');
    }
    if (!ctrlId) {
        logger.alert('ZDropDown: no CtrlId');
    }
    const dropdownDisabled = (optionList.length === 1) || ddOptions.disabled;
    const dropdown: JSX.Element = (
        <div className="zdropdown inline" key={ctrlId + 'Main'}>
        <DropdownButton className="zdropdown-item" alignRight={ddOptions.pullRight} id={ctrlId} 
                        title={<span className="zdropdown-with-svg">{title}&nbsp;{dropdownIcon()}</span>} disabled={dropdownDisabled} key={ctrlId}>
            {dropdownItems}
        </DropdownButton>
        </div>
    );

    return dropdown;
};

export default ZDropDown;
