import * as React from 'react';

const ApiDocWhitelistGet = (): JSX.Element => {
    return ( 
        <>
<div className="z-title">Get Whitelisted IP Addresses</div>

<div className="zsection">
    This API lets you to get the list of whitelisted IP addresses from
    Zycada&apos;s firewall.

    <table className="zlist">
        <tbody>
        <tr>
            <td>Method:</td>
            <td className="method">GET</td>
        </tr>
        <tr>
            <td>URL:</td>
            <td className="zli-inner">https://api.zycada.com/v1/whitelisted_ips</td>
        </tr>
        <tr>
            <td>Headers:</td>
            <td>
                <table className="izlist">
                    <tbody>
                    <tr>
                        <td>Authorization:</td>
                        <td>Bearer <i>[API_Key]</i></td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>

    <div className="before-code"><h3>Arguments</h3></div>
    <ul className="simple-list">
        <li>The [API_KEY] is a string that  establishes authority to manipulate assets. You can only modify your own assets.</li>
    </ul>

    <div className="before-code"><h3>Request</h3></div>
    GET request to the API URL above.

    <div className="before-code"><h3>Response</h3></div>
    The response is a JSON object that contains an array of whitelisted IP
    addresses. If there are no whitelisted IP addresses, then an empty array is
    returned.
    <pre className="expected-response">{`[
    {
        "ip": "x.x.x.x"            // IP address.
    },
    ...
]`}</pre>

    <div className="before-code"><h3>HTTP Response Status</h3></div>
    <ul className="simple-list sample-response">
        <li>
            <h4>200</h4>
            Success.
        </li>
        <li>
            <h4>401</h4>
            Invalid API key.
        </li>
    </ul>

    <div className="before-code"><h3>Sample Response</h3></div>
    <pre className="sample-response">{`[
    {
        "ip": "1.2.3.4"
    ,
    {
        "ip": "5.6.7.0/24"
    
]`}</pre>
</div>
</>
    )
}

export default ApiDocWhitelistGet;