import * as React from 'react';
import Alert from 'react-bootstrap/Alert';
import { NotificationStyles } from './constants'

export interface NotificationData {
    show: boolean,
    style: NotificationStyles,
    message: string,
    cssStyles?: string,
}

export interface NotificationProps extends NotificationData {
    closeHandler: () => void;
}

// tslint:disable-next-line: variable-name
const NotificationControl: React.FunctionComponent<NotificationProps> = (props) => {
    let alert;
    const cssStyle = props.cssStyles;
    const css = `iz ${cssStyle && cssStyle.length > 0 ? cssStyle : ''}`;
    const alertMarkup = (<><div>{props.message}</div>
            <div className="zalert-close" onClick={props.closeHandler}>close</div></>)

    if (props.show) {
       alert = ( <div id="notification" className={css}>
                    <Alert className="zalert fadeIn" variant={props.style}>{alertMarkup}</Alert>
                 </div>)
    } else {
        alert = (<div></div>);
    }
    return alert;
}

export default NotificationControl;