
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../App';
import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { FetchError, OrgInfo, OrgLimit, ZRestResponse, ZUserTypes, ZUserMin } from '../../data/queryResultDefinitions';

import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { ZFeaturePopupTypes, SysFeatureEnums } from '../../reducers/reducerEnums';

import { NotificationStyles, MAX_ORG_NAME_LEN } from '../../shared/constants'

import { ORGANIZATION_STRINGS as STRs, MISC_STRINGS, /* NAVSTRINGS as NStrs */} from '../../shared/strings'; 
import { ZGet, ZPost, ZPut } from '../../shared/backend';
import { closePopupNotification, isValidProjectOrgName, validateOrgLimits } from '../../shared/utilities';

import zurls from '../../shared/urls';
import ListPanel from '../../shared/ListPanel';
import logger from '../../shared/logUtilities';
import ZButton from '../../shared/ZButton';
import ZInputTextBox from '../../shared/ZInputText';
import { inputBoxStyle } from '../../shared/ZInputText';
import GenDialog from '../../shared/GenDialog';

export interface ManageOrgProps extends State {
    setOrgListIdx: (orgIndex: number) => actionTypes;
    setOrgList: (orgList: OrgInfo[], selectedOrgName?: string)  => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes,
    closeNotification: () => actionTypes,
    applySettings: (error?: string) => actionTypes;
    controlModalDlg: (feature: SysFeatureEnums, setOpen: boolean, dialogName?: ZFeaturePopupTypes) => actionTypes,
    setOrgData: (orgData: OrgInfo) => actionTypes,
    setOrgName: (orgName: string) => actionTypes,
    setOrgLimits: (limits: OrgLimit[]) => actionTypes,
    redirectTo: (url: string) => actionTypes
}

class ManageOrgs extends React.Component<ManageOrgProps> {
    componentDidMount(): void {
        this.initOrgManagement();
    }

    render(): JSX.Element {
        const orgListPanel = this.buildOrgListPanel();
        const orgDataPanel = this.buildOrgDataPanel();

        const popupBuilders = {
            [ZFeaturePopupTypes.CreateOrg]: this.buildCreateOrgPopup,
            [ZFeaturePopupTypes.EditOrg]: this.buildEditOrgPopup,
        }
        let orgPopup = <div></div>
        if (popupBuilders[this.props.authNServerData.popupType]) {
            orgPopup = (popupBuilders[this.props.authNServerData.popupType])();
        }
        // const popup = this.buildCreatePopup();
        const markup =  (

                <div id="manage-orgs">
                    <div className="title">{STRs.manageOrganizations}</div>
                    <div className="manage-org-tables">
                        <div className="org-panel">{orgListPanel}</div>
                        <div className="org-panel">{orgDataPanel}</div>
                    </div>
                    {orgPopup}
                </div>
        )

        return markup;
    }

    private buildOrgListPanel = (): JSX.Element => {
        const user = this.props.authNServerData.user as ZUserMin;

        const orgListHeader: JSX.Element = (
            <div className="org-list-header">
                <div></div><div>{STRs.organizations}</div>
            </div>
        )

        let orgListFooterBtn = <span></span>;
        if ( user.role === ZUserTypes.zadmin ) {
            orgListFooterBtn = (
                <ZButton onClick={(): void => { this.handleCreateOrg(); }} >{STRs.createOrg}</ZButton>)
        }

        const orgListFooter:  JSX.Element = (
            <div className="list-panel-footer">
                <div>
                    {orgListFooterBtn}
                </div>
            </div>
        );
        const orgList = this.buildOrgList()
        const markup = (
            <>
                <ListPanel
                    renderHeader={(): JSX.Element => { return orgListHeader }}
                    renderBody={(): JSX.Element => { return <>{orgList}</>}}
                    renderFooter={(): JSX.Element => { return orgListFooter}}
                />
            </>
        )
        return (<>{markup}</>)
    }

    private buildOrgList = (): JSX.Element => {
        const authState = this.props.authNServerData;
        const orgIdx = authState.orgListIdx;
        const orgList = authState.orgList;

        const orgNames = orgList.map((org: OrgInfo) => { return org.org_name });

        const orgs: JSX.Element[] = orgNames.map((name:string, i: number) => {
            const selected =  (i === orgIdx) ? 'selected' : '';
            return (
                <div key={'orgnamelist' + i} className={'org-list-item ' + selected}  onClick={(): void => { this.props.setOrgListIdx(i); }}>
                    <div></div>
                    <div>{name}</div>
                </div>
        )});

        return <>{orgs}</>;
    }

    private buildOrgDataPanel = (): JSX.Element => {
        const authState = this.props.authNServerData;
        const orgIdx = authState.orgListIdx;
        let markup = <span></span>;

        if (orgIdx !== -1) {
            const user = this.props.authNServerData.user as ZUserMin;

            const org = authState.orgList[orgIdx];
            const orgData = this.buildOrgData(org);

            const orgDataHeader: JSX.Element = (
                <div className="org-list-header">
                    <div></div><div>{STRs.orgDataLabel(org.org_name)}</div>
                </div>
            )

            let orgDataFooterBtn = <span></span>;
            if ( user.role === ZUserTypes.zadmin ) {
                orgDataFooterBtn = (
                    <ZButton onClick={(): void => { this.handleEditOrgData(); }} >{STRs.editOrg}</ZButton>)
            }

            const orgDataFooter:  JSX.Element = (
                <div className="list-panel-footer">
                    <div>
                        {orgDataFooterBtn}
                    </div>
                </div>
            );

            markup = (
                <>
                    <ListPanel
                        renderHeader={(): JSX.Element => { return orgDataHeader }}
                        renderBody={(): JSX.Element => { return <>{orgData}</>}}
                        renderFooter={(): JSX.Element => { return orgDataFooter}}
                    />
                </>
            )
        } else {
            markup = <div>{STRs.noOrgs}</div>
        }

        return (<>{markup}</>)
    }   

    private buildOrgData = (org: OrgInfo): JSX.Element => {
            let markup = <span></span>;

            markup = (
                <div className="org-data">
                    <div>{STRs.orgLimits}</div>
                    <div>                        
                        <div></div>
                        <div>{STRs.maxCerts}</div>
                        <div>{org.max_certs_per_project}</div>
                        <div></div>

                        <div></div>
                        <div>{STRs.maxProjects}</div>
                        <div>{org.max_projects}</div>
                        <div></div>
                        
                        <div></div>
                        <div>{STRs.maxServicesPerProject}</div>
                        <div>{org.max_services_per_project}</div>
                        <div></div>

                        <div></div>
                        <div>{STRs.maxApiKeysPerUser}</div>
                        <div>{org.max_api_keys_per_user}</div>
                        <div></div>

                        <div></div>
                        <div>{STRs.maxFirewallRules}</div>
                        <div>{org.max_firewall_rules}</div>
                        <div></div>
                    </div>
                </div>
            )

        return markup;
    }

    private handleCreateOrg = (): void => {
        this.props.controlModalDlg(SysFeatureEnums.organizations, true, ZFeaturePopupTypes.CreateOrg);
    }
    
    private buildCreateOrgPopup = (): JSX.Element => {

        const authState = this.props.authNServerData;
        const orgName = authState.newOrgName;
        const disableApply = !(orgName.length > 0 && isValidProjectOrgName(orgName));

        const title = STRs.createOrg;
        const msg = STRs.enterOrgName;
        const validationNameMsg = disableApply ? MISC_STRINGS.projectOrgValidationRules : '';

        const projDlg = (
            <GenDialog 
                show={authState.popupOpen}
                title={title} 
                msg={msg}
                css="zmodel-50"
                applyBtnTxt={title}
                disableApply={(): boolean => { return disableApply; }}
                onHide={(): void => { 
                    this.props.controlModalDlg(SysFeatureEnums.organizations, false); 
                } }
                handleApply={(): void => { this.applyCreateOrg(); }}
            >
                <div className="create-project-padding">
                <ZInputTextBox 
                    label={STRs.orgName} 
                    value={orgName} 
                    ikey="createOrg" 
                    onTextChange={(txt: string): void => { this.props.setOrgName(txt)}}
                    maxLength={MAX_ORG_NAME_LEN}
                    validationMsg={validationNameMsg}
                    placeholderText={STRs.orgName}
                />
                </div>
            </GenDialog>
        );

        return projDlg;
    }

    private applyCreateOrg = async (): Promise<void> => {
        const authState = this.props.authNServerData;
        this.props.controlModalDlg(SysFeatureEnums.organizations, false);
        const url = buildUrl(authState, zurls.serverOrgs);

        try {
            const orgName = authState.newOrgName;
            await ZPost(url, JSON.stringify({org_name: orgName}));

            this.props.showNotification(NotificationStyles.success, STRs.createOrgSuccess(orgName));
            closePopupNotification();
            this.loadOrgs(orgName);
        }
        catch(err) {
            const p = err as Promise<FetchError>;
            p.then((err) => {
                console.log(`ManageOrgs.applyCreateOrg: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                closePopupNotification();
                this.props.applySettings('serverError');
            })
        }
    }
    
    private handleEditOrgData = async (): Promise<void> => {
        logger.log(' **** ManageOrgs.handleEditOrgData:  load org limt')
        const { editableOrg } = this.props.authNServerData;
        const url = buildUrl(this.props.authNServerData, zurls.serverOrgLimits(editableOrg.org_id));
        try {
            const response: ZRestResponse = await ZGet(url, {include_limits: true});
            this.props.setOrgLimits(response as OrgLimit[])
            this.props.controlModalDlg(SysFeatureEnums.organizations, true, ZFeaturePopupTypes.EditOrg);
        }
        catch(err) {
            const p = err as Promise<FetchError>;
            p.then((err) => {
                console.log(`ManageOrgs.handleEditOrgData: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                closePopupNotification();
                this.props.applySettings('serverError');
            })
        }


    }
    
    private buildEditOrgPopup = (): JSX.Element => {
        const authState = this.props.authNServerData;
        const editableOrg = authState.editableOrg;
        const initialOrgData = authState.orgList[authState.orgListIdx]
        const orgName = editableOrg.org_name
        const limits = authState.orgLimits;
        
        const validLimits = validateOrgLimits(initialOrgData, authState.editableOrg, limits)
        const validOrgName = isValidProjectOrgName(orgName)
        const haveAllLegalValues = (validOrgName && validLimits.isValid);
        let anyValuesChanged = (initialOrgData.org_name !== orgName);
        for (let i=0, len = limits.length; i < len; i++) {
            const limitInfo = limits[i];
            const name = limitInfo.name;
            anyValuesChanged = anyValuesChanged || (editableOrg[name] !== initialOrgData[name])
        }
        const enableApply = haveAllLegalValues && anyValuesChanged;

        const limitMap = {};
        for (let i=0, len=limits.length; i < len; i++) {
            const limit = limits[i];
            limitMap[limit.name] = limit;
        }

        // the order of the labels in the map is the order in which they will displayed in the popup.
        const limitLabels = {
            max_certs_per_project: STRs.maxCerts,
            max_projects: STRs.maxProjects, 
            max_services_per_project: STRs.maxServicesPerProject,
            max_api_keys_per_user: STRs.maxApiKeysPerUser,
            max_firewall_rules: STRs.maxFirewallRules,
        }
        const limitLabelKeys = Object.keys(limitLabels);

        const title = STRs.editOrg;
        const msg = STRs.editLimits; 
        const orgNameValidationMsg = !validOrgName ? MISC_STRINGS.projectOrgValidationRules : '';

        const editData: JSX.Element[] = [];
        const buildInputField = (name: string, label: string, value: string, validationMsg: string, maxChars: number, css: string): void => {
            editData.push((
                <div key={name+'1'}>{label}</div>
                ));
            editData.push((
                <div key={name+'2'}>
                <ZInputTextBox 
                    label={''}
                    value={value} 
                    ikey={name + '1'} 
                    onTextChange={(txt: string): void => { this.setLimitField(txt, name) }}
                    layoutStyle={inputBoxStyle.SingleLine}
                    maxLength={maxChars}
                    extraCSS={css}
                    validationMsg={validationMsg}
                    placeholderText={label}
                />
                </div>
            ))
        }

        buildInputField('org_name', STRs.orgName, orgName, orgNameValidationMsg, MAX_ORG_NAME_LEN, '');
        for (let i=0, len = limitLabelKeys.length; i < len; i++) {
            const key = limitLabelKeys[i];
            const {name, min, max} = limitMap[key]
            const validationMsg = !validLimits[name] ? STRs.orgLimitRules(min, max) : '';
            let maxChars = max.toString().length;
            maxChars = (maxChars <= 2) ? 2 : maxChars;
           buildInputField(name, limitLabels[key], editableOrg[name], validationMsg,  maxChars, 'number-limit');
        }

        const projDlg = (
            <GenDialog 
                show={authState.popupOpen}
                title={title} 
                msg={msg}
                css="zmodel-50"
                applyBtnTxt={STRs.saveOrgInfo}
                disableApply={(): boolean => { return !enableApply; }}
                onHide={(): void => { 
                    this.props.controlModalDlg(SysFeatureEnums.organizations, false); 
                } }
                handleApply={(): void => { this.applyEditOrg(); }}
            >
                <div className="validate">
                    <div className="org-edit-grid">
                        {editData}
                    </div>
                </div>
            </GenDialog>
        );

        return projDlg;
    }

    private setLimitField = async (value: string, name: string): Promise<void> => {
        const editOrg: OrgInfo = {...this.props.authNServerData.editableOrg};

        if (name !== "org_name") {
            editOrg[name] = parseInt(value);
            if (isNaN(editOrg[name] )) {
                editOrg[name] = 0
            }
        } else {
            editOrg[name] = value;
        }
        this.props.setOrgData(editOrg);
    }

    private applyEditOrg = async (): Promise<void> => {
        const authState = this.props.authNServerData;
        const editedOrg = authState.editableOrg;
        const orgLimits = authState.orgLimits;
        const initialOrgData = authState.orgList[authState.orgListIdx];
        const newOrgInfo = {} as OrgInfo;
        const newOrgLimits: OrgLimit[] = [];

        try {
            // handle org name change
            const orgName = editedOrg.org_name;
            if (orgName !== initialOrgData.org_name) {
                newOrgInfo.org_name = orgName;
                logger.log(' **** ManageOrgs.applyEditOrg:  load start')
                const url = buildUrl(this.props.authNServerData, zurls.serverUpdateOrgInfo(editedOrg.org_id));
                await ZPut(url, JSON.stringify(newOrgInfo));
            }

            // handle org settings change
            for (let i=0, len = orgLimits.length; i < len; i++) {
                const limitInfo = orgLimits[i];
                const name = limitInfo.name;
                if (editedOrg[name]!== initialOrgData[name]) {
                    newOrgLimits.push({name: limitInfo.name, value: editedOrg[name], min: 0, max: 0})
                }
            }

            if (newOrgLimits.length > 0) {
                logger.log(' **** ManageOrgs.applyEditOrg:  load start')
                const url = buildUrl(this.props.authNServerData, zurls.serverOrgLimits(editedOrg.org_id));
                await ZPut(url, JSON.stringify(newOrgLimits));
            }

            this.props.showNotification(NotificationStyles.success, `Successfully updated organization '${orgName}'`);
            closePopupNotification();
            this.loadOrgs(orgName);
        }
        catch(err) {
            const p = err as Promise<FetchError>;
            p.then((err) => {
                console.log(`ManageOrgs.applyEditOrg: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                closePopupNotification();
                this.props.applySettings('serverError');
            })
        }
    }
    
    private initOrgManagement = (): void => {
            logger.log(' **** ManageOrgs.initOrgManagement:  load start')
            this.loadOrgs(undefined);
    }

    private loadOrgs = async (orgName: string | undefined): Promise<void> => {
        try {
            logger.log(' **** ManageOrgs.loadOrgs:  load start')
            const url = buildUrl(this.props.authNServerData, zurls.serverOrgs);

            const response: ZRestResponse = await ZGet(url, {});
            const orgData = response as OrgInfo[]
            logger.log(' **** ManageOrgs.loadOrgs: load done')

            this.props.setOrgList(orgData as OrgInfo[], orgName);
            if (orgName === undefined) {
                this.props.setOrgListIdx(0);
            }
        }
        catch(err) {
            const p = err as Promise<FetchError>;
            p.then((err) => {
                console.log(`ManageOrgs.loadOrgs: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                closePopupNotification();
                this.props.applySettings('serverError');
            })
        }
    }

    // private closeNotification = (): void => {
    //     setTimeout(() => { this.props.closeNotification(); }, 5000);
    // }
}


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        authNServerData: state.authNServerData
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(ManageOrgs);