import moment from 'moment';
import { actionTypes, ActionKeys, ASetLogsFromToTime, ASetLogsOptionData,
         ASetLogOptionsFieldState, ASetVisibilityLogFilterModal, ASetHeaderTypeIndex, AAddHeaderFilter, 
         ADeleteHeaderFilter, ASetHeaderFilterData, AToggleHeaderFilter, ALogDataObtained, ASetLogRowState, 
         ASetCurrentPage, ASetQueryTimeFormat, ASortTable } from '../actions/actionCreatorTypes';
import { PaginationData } from '../data/metricsAndOptionsDefs';
import { EProjectState, HeaderFilterOpsIndexEnum, SysFeatureEnums, ZSortDirection, ZSortFields } from './reducerEnums'
import { PAGE_SIZE, MAX_PAGE_CTRL_ITEMS_DISPLAYABLE, MAX_ESEARCH_RECORDS_AVAILABLE, hourIntervalsConstants } from '../shared/constants';
import logger from '../shared/logUtilities';

export interface LogOptionsFieldErrorState {
    clientIP: boolean,
    originIP: boolean,
    responseCode: boolean,
    assetURL: boolean,
    dateRange: boolean,
    badFrom: boolean,
}

export interface ResponseCodeData {
    type: string,
    codes: string[],
    errorState: LogOptionsFieldErrorState,
}

export interface HeaderFilterItem {
    isRequest: boolean,
    isEnabled: boolean,
    filterName: string,
    filterValue?: string,
    filterOp: HeaderFilterOpsIndexEnum
}

export interface LogHeaderDisplay {
    name: string,
    value: string,
    css: string
}

export interface LogRowData {
    timestamp: moment.Moment,
    requestMethod: string,
    requestURI: string,
    responseCode: number,
    cacheStatus: string,
    responseTime: number,
    clientIP: string,
    originIP: string,
    zyError: string,
    userAgent: string,
    maxAge: string,
    fullRequestURI: string,
    zyPragma: '',
    pushIndicator: string,
    responseSize: string,
    reqHeaderData: LogHeaderDisplay[],
    respHeaderData:  LogHeaderDisplay[],
    open: boolean,
    showReqHeaders: boolean,
    showRespHeaders: boolean
}

export interface State {
    areLogOptionsDirty: boolean,
    loading: boolean,
    logState: EProjectState,

    updateTimestamp: moment.Moment,

    assetURL: string,                               // current state of options field (not in affect to 'apply')
    clientIP: string,                               // current state of options field (not in affect to 'apply')
    fieldErrorState: LogOptionsFieldErrorState,     // field validation of options
    filterName: string,                             // filter being edited
    filterValue: string,                            // filter being edited
    filterOps: HeaderFilterOpsIndexEnum,            // filter being edited
    from: moment.Moment,                            // current state of options field (not in affect to 'apply')
    headerTypeIdx: number,                          // creating a request or response header filter
    logPagingData: PaginationData,                   // pagination data
    logRowData: LogRowData[],                       // data that is being shown (returned from server and processed)
    originIP: string,                               // current state of options field (not in affect to 'apply')
    responseCode: string,                           // current state of options field (not in affect to 'apply')
    responseCodeData: ResponseCodeData,             // verified response code 
    logFilters: HeaderFilterItem[],                 // current list of header filters
    showModal: boolean,                             // showing modal for creating header filters
    to: moment.Moment,                              // current state of options field (not in affect to 'apply')
    showRelativeTime: boolean,
    requestMethodIdx: number,
    periodIdx: number,
    validDateRange: boolean,
    sortField: ZSortFields,
    sort: ZSortDirection,
    currentOpts: {                                  // options state as of last time user hit 'apply'
        to: moment.Moment,
        from: moment.Moment,
        logFilters: HeaderFilterItem[],
        assetURL: string,
        clientIP: string,
        originIP: string,
        responseCodeData: ResponseCodeData,
        requestMethodIdx: number,
        periodIdx: number,
        showRelativeTime: boolean,
    }
}

const logInitData = {
    areLogOptionsDirty: false,
    loading: false,
    logState: EProjectState.ready,
    updateTimestamp: moment(),

    assetURL: '',
    clientIP: '',
    fieldErrorState: {
        clientIP: false,
        originIP: false,
        responseCode: false,
        assetURL: false,
        dateRange: false,
        badFrom: false,
    },
    filterName: '',
    filterValue: '',
    filterOps: HeaderFilterOpsIndexEnum.opIs,
    from: moment().subtract(1, 'h'),
    headerTypeIdx: 0,

    logRowData: [] as LogRowData[],
    logPagingData: {
        pageSize: PAGE_SIZE,
        totalItems: 0,
        qryTotalItems: 0,
        pageToShow: MAX_PAGE_CTRL_ITEMS_DISPLAYABLE,
        currentPage: 1,
        moreThanMax: false,
        isGoodData: true
    },
    originIP: '',
    logFilters: [],
    responseCode: '',
    responseCodeData: {
        type: '',
        codes: [] as string[],
        errorState: {} as LogOptionsFieldErrorState,
    },
    showModal: false,
    to: moment(),
    showRelativeTime: true,
    requestMethodIdx: 0,
    periodIdx: 0,
    validDateRange: true,
    sortField: ZSortFields.timestamp,
    sort: ZSortDirection.desc,
    currentOpts: {
        to: moment(),
        from: moment(),
        logFilters: [] as HeaderFilterItem[],
        assetURL: '',
        clientIP: '',
        originIP: '',
        responseCodeData: {} as ResponseCodeData,
        selectedServiceIds: '',
        requestMethodIdx: 0,
        periodIdx: 0,
        showRelativeTime: true,
    }
}

const fullStateInit = (): State => {
    // ES6 wisdom: remember that spread {...xxx} is not a deep copy.
    // that is why we need to explicitly empty arrays
    const state: State = {...logInitData};

    state.logFilters.length = 0;
    state.logRowData.length = 0;
    state.currentOpts.logFilters.length = 0;
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initState = (state: State): State => {
    const newState: State = fullStateInit();

    return newState;
}

// Start of action workers 
// First general actions that all pages handle
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setProject = (initialState: State, action: actionTypes ): State => {
    const state = fullStateInit();
    state.logState = EProjectState.projectsUpdated;
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeProject = (initialState: State, actions: actionTypes ): State => {
    const state = fullStateInit();
    state.logState = EProjectState.newProjectLoaded;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setCurrentServiceEvtIdx = (initialState: State, actions: actionTypes ): State => {
    let state = fullStateInit();
    // let action = actions as ASetCurrentServiceEvtIdx;

    state = {...initialState};
    state.areLogOptionsDirty = true;
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setNavItem = (initialState: State, actions: actionTypes ): State => {
    const state = {...initialState}; 
    state.logState = EProjectState.tabChanged;
    return state;
}

// Start of log specific action workers
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyLogOptions = (initialState: State, actions: actionTypes ): State => {
    // let action = actions as AApplyLogOptions;
    const state = {...initialState}; 
    
    if (state.showRelativeTime) {
        state.to = moment();
        state.from = moment().subtract(hourIntervalsConstants[state.periodIdx], 'h');
    }

    state.logState = EProjectState.userClickedApply;
    state.fieldErrorState = {
        clientIP: false,
        originIP: false,
        responseCode: false,
        assetURL: false,
        dateRange: false,
        badFrom: false,
    }
    state.logRowData.length = 0;
    state.areLogOptionsDirty = false;
    state.loading = true;
    state.logPagingData.isGoodData = true;
    state.logPagingData.currentPage = 1;
    state.logPagingData.totalItems = 0;
    state.updateTimestamp = moment();
    state.currentOpts = {
        to: state.to,
        from: state.from,
        logFilters: [...state.logFilters],
        assetURL: state.assetURL,
        clientIP: state.clientIP,
        originIP: state.originIP,
        responseCodeData: {
            type: state.responseCodeData.type,
            codes: state.responseCodeData.codes.splice(0),
            errorState: {...state.responseCodeData.errorState}
        },
        showRelativeTime: state.showRelativeTime,
        requestMethodIdx: state.requestMethodIdx,
        periodIdx: state.periodIdx
    }

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initLogOptions = (initialState: State, actions: actionTypes ): State => {
    const state = initState(initialState);

    state.to = moment();
    state.from = moment().subtract(1, 'h');

    state.logState = EProjectState.initComplete;
    return state;
}

const setLogsFromToTime = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetLogsFromToTime;
    const state = {...initialState};
    const { to, from, invalidTime, relTimeIdx } = action.payload;
    
    if (state.showRelativeTime) {
        state.periodIdx = relTimeIdx;
        state.validDateRange = true;
    } else {
        if (invalidTime) {
            state.validDateRange = false;
        } else {
            state.to = to;
            state.from = from;
        }
    }

    state.areLogOptionsDirty = true;
    state.logState = EProjectState.ready;
    
    return state;
}

const setLogsOptionData = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetLogsOptionData;
    const state = {...initialState};

    state.assetURL = action.payload.assetURL;
    state.responseCode = action.payload.responseCode;
    state.originIP = action.payload.originIP;
    state.clientIP = action.payload.clientIP;
    state.logState = EProjectState.ready;
    state.requestMethodIdx = action.payload.requestMethodIdx;
    state.areLogOptionsDirty = true;

    return state;
}

// save field validation of options
const setLogsOptionsFieldState = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetLogOptionsFieldState;
    const state = {...initialState};

    state.fieldErrorState = { ...action.payload.errorState };
    return state;
}

const setVisibilityLogFilterModal = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetVisibilityLogFilterModal;
    const state = {...initialState};

    state.showModal = action.payload.showModal;
    if (!state.showModal) {
        state.filterOps = HeaderFilterOpsIndexEnum.opIs;
        state.filterName = '';
        state.filterValue = ''
        state.headerTypeIdx = 0;
    }
    return state;
}

// response/request header type in add filter dropdown
const setHeaderTypeIdx = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetHeaderTypeIndex;
    const state = {...initialState};

    state.headerTypeIdx = action.payload.idx;

    return state
}

const setHeaderFilterData = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetHeaderFilterData;
    const state = {...initialState};

    state.filterOps = action.payload.filterOps;
    state.filterName = action.payload.filterName;
    state.filterValue = action.payload.filterValue;

    return state;
}
const addHeaderFilter = (initialState: State, actions: actionTypes ): State => {
    const action = actions as AAddHeaderFilter;
    const state = {...initialState};

    const filter: HeaderFilterItem = {
        isRequest: action.payload.isRequestHeader,
        isEnabled: true,
        filterName: action.payload.filterName,
        filterValue: action.payload.filterValue,
        filterOp: action.payload.filterOps
    }
    state.logFilters.push(filter);

    state.filterName = '';
    state.filterValue = ''
    state.areLogOptionsDirty = true;

    return state;
}

const deleteHeaderFilter = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ADeleteHeaderFilter;
    const state = {...initialState};

    const idx = action.payload.index;

    if (idx >= state.logFilters.length) {
        logger.alert(`DELETE_HEADER_FILTER: went off end of array.  index = ${action.payload.index}`);
    } else {
        state.logFilters.splice(idx, 1);
        state.areLogOptionsDirty = true;
    }

    return state;
}

const toggleHeaderFilter = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AToggleHeaderFilter;
    const state = {...initialState};

    const tidx = action.payload.index;

    if (tidx >= state.logFilters.length) {
        logger.alert(`TOGGLE_HEADER_FILTER: went off end of array.  index = ${action.payload.index}`);
    } else {
        state.logFilters[tidx].isEnabled = !state.logFilters[tidx].isEnabled;
        state.areLogOptionsDirty = true;
    }

    return state;
}

const logDataObtained = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ALogDataObtained;
    const state = {...initialState};

    state.loading = false;
    state.logState = EProjectState.ready;
    state.updateTimestamp = moment();

    const pdata = state.logPagingData;
    pdata.isGoodData = action.payload.status;
    if (pdata.isGoodData) {
        state.logRowData = action.payload.logRowData as LogRowData[];
        pdata.qryTotalItems = action.payload.totals;
        pdata.totalItems = pdata.qryTotalItems;
        if (pdata.qryTotalItems > MAX_ESEARCH_RECORDS_AVAILABLE) {
            pdata.totalItems = MAX_ESEARCH_RECORDS_AVAILABLE;
            pdata.moreThanMax = true;
            pdata.pageSize = action.payload.logRowData.length;
        } else {
            pdata.moreThanMax = false;
        }
    } else {
        state.logRowData = [] as LogRowData[];
        pdata.qryTotalItems = 0;
        pdata.totalItems = 0;
        pdata.moreThanMax = false;
        pdata.pageSize = 0;
    }

    return state;
}

const setLogRowState = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetLogRowState;
    const state = {...initialState};

    if (state.logRowData.length < action.payload.rowIdx) {
        logger.alert('bad log row idx');
    } else {
        const row: LogRowData = state.logRowData[action.payload.rowIdx];
        row.open = action.payload.rowOpen;
        row.showReqHeaders = action.payload.showReqHeader;
        row.showRespHeaders = action.payload.showRespHeader;
    }

    return state;
}

const setCurrentLogPage = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetCurrentPage;
    const state = {...initialState};

    if (action.payload.component === 'logs') {
        state.logState = EProjectState.userChangedPages;
        state.logPagingData.currentPage = action.payload.pageNum;
        state.logRowData.length = 0;
        state.loading = true;
    }

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetProject = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};
    state.logState = EProjectState.serviceChanged

    return state;
}

const setQueryTimeFormat = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetQueryTimeFormat;

    if (action.payload.component === 'LOGS') {
        state.showRelativeTime = action.payload.relativeTime;
        if (!state.showRelativeTime) {
            state.to = moment();
            state.from = moment().subtract(hourIntervalsConstants[state.periodIdx], 'h');
        }
        state.areLogOptionsDirty = true;
    }
    return state;
}

const sortTable = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState};
    const action = actions as ASortTable;

    const { feature, fieldName, direction} = action.payload;
    if (feature === SysFeatureEnums.logsAnl) {
        state.sort = direction;
        state.sortField = fieldName as ZSortFields;
        state = applyLogOptions(state, {} as actionTypes)
    }

    return state;
}

type ActionFunction = ((state: State, action: actionTypes) => State);
type LogActions = ActionKeys.SET_PROJECTS | ActionKeys.CHANGE_PROJECTS | ActionKeys.SET_NAV_ITEM |
                    ActionKeys.SET_CURRENT_SERVICE_EVT_IDX | ActionKeys.SET_CURRENT_PAGE | 
                    ActionKeys.INIT_LOG_OPTIONS | ActionKeys.SET_LOGS_FROM_TO_TIME | ActionKeys.SET_QUERY_TIME_FORMAT |
                    ActionKeys.SET_LOGS_OPTION_DATA | ActionKeys.SET_LOG_OPTIONS_FIELD_STATE | 
                    ActionKeys.SET_VISIBILITY_LOG_FILTER_MODAL | ActionKeys.SET_HEADER_TYPE_IDX | 
                    ActionKeys.ADD_HEADER_FILTER | ActionKeys.DELETE_HEADER_FILTER | ActionKeys.SET_HEADER_FILTER_DATA |
                    ActionKeys.TOGGLE_HEADER_FILTER | ActionKeys.LOG_DATA_OBTAINED | ActionKeys.SET_LOG_ROW_STATE |
                    ActionKeys.APPLY_SETTINGS | ActionKeys.RESET_PROJECT | ActionKeys.SORT_TABLE;
                    
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in LogActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.SET_PROJECTS]: setProject,
    [ActionKeys.CHANGE_PROJECTS]: changeProject,
    [ActionKeys.SET_CURRENT_SERVICE_EVT_IDX]: setCurrentServiceEvtIdx,
    [ActionKeys.SET_NAV_ITEM]: setNavItem,
    [ActionKeys.APPLY_SETTINGS]: applyLogOptions,
    [ActionKeys.INIT_LOG_OPTIONS]: initLogOptions,
    [ActionKeys.SET_LOGS_FROM_TO_TIME]: setLogsFromToTime,
    [ActionKeys.SET_LOGS_OPTION_DATA]: setLogsOptionData,
    [ActionKeys.SET_LOG_OPTIONS_FIELD_STATE]: setLogsOptionsFieldState,
    [ActionKeys.SET_VISIBILITY_LOG_FILTER_MODAL]: setVisibilityLogFilterModal,
    [ActionKeys.SET_HEADER_TYPE_IDX]: setHeaderTypeIdx,
    [ActionKeys.SET_HEADER_FILTER_DATA]: setHeaderFilterData,
    [ActionKeys.ADD_HEADER_FILTER]: addHeaderFilter,
    [ActionKeys.DELETE_HEADER_FILTER]: deleteHeaderFilter,
    [ActionKeys.TOGGLE_HEADER_FILTER]: toggleHeaderFilter,
    [ActionKeys.LOG_DATA_OBTAINED]: logDataObtained,
    [ActionKeys.SET_LOG_ROW_STATE]: setLogRowState,
    [ActionKeys.SET_CURRENT_PAGE]: setCurrentLogPage,
    [ActionKeys.RESET_PROJECT]: resetProject,
    [ActionKeys.SET_QUERY_TIME_FORMAT]: setQueryTimeFormat,
    [ActionKeys.SORT_TABLE]: sortTable,
}
function logsUIState(initialState: State, action: actionTypes ): State {
    let state = initialState;

    if ( initialState === undefined ) {
        state = fullStateInit();
        state.to = moment();
        state.from = moment().subtract(1, 'h');
    }

    const fct: ActionFunction | undefined = actionMap[action.type];

    if (fct) {
        // logger.log(`action: ${action.type}; requestMethodIdx before: ${state.requestMethodIdx}`)
        state = (fct)(state, action);
        // logger.log(`requestMethodIdx after: ${state.requestMethodIdx}`)

    }
    //  else {
    //     logger.log(`logState: action ${ action.type } not processed`)
    // }

    return state
}

export default logsUIState;