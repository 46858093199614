import * as React from 'react';

const ApiDocLogs = (): JSX.Element => {
    return ( 
        <>
<div className="z-title">Log Managment</div>

<div className="zsection">
    The log management API is used to retrieve logs from Zycada platform. The API response is in JSON format. A maximum of
    1000 log records are returned per API call. When there are more than 1000 log records matching the filter criteria, the &quot;next&quot;
    field in the response can be used to paginate.

    <div className="before-code"><h3> </h3></div>
    <table className="zlist">
        <tbody>
        <tr>
            <td>Method:</td>
            <td className="method">GET</td>
        </tr>
        <tr>
            <td>Production URL:</td>
            <td className="zli-inner">https://api.zycada.com/v1/logs</td>
        </tr>
        <tr>
            <td>Staging URL:</td>
            <td className="zli-inner">https://staging-api.zycada.com/v1/logs</td>
        </tr>
        <tr>
            <td>Headers:</td>
            <td>
                <table className="izlist">
                    <tbody>
                    <tr>
                        <td>Authorization:</td>
                        <td>Bearer <i>[API_Key]</i></td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>


    <div className="before-code"><h3>Arguments</h3></div>
    <ul className="simple-list">
        <li>The [API_KEY] is a string that  establishes authority to manipulate assets. You can only modify your own assets.</li>
    </ul>


    <div className="before-code"><h3>Request</h3></div>
    The following query parameters can be specified on the URL used in fetching logs.

    <table className="qlist">
        <tbody>
        <tr>
            <th>Query Parameter:</th>
            <th className="method">Description</th>
        </tr>
        <tr>
            <td>from  [manditory]</td>
            <td>
                <h4>Starting time (inclusive)</h4>
                <h4>Could be in one of the following formats:</h4>
                <h5>Relative time:</h5>
                <p>-Ns - N seconds earlier</p>
                <p>-Nm - N minutes earlier</p>
                <p>-Nh - N hours earlier</p>
                <p>-Nd - N days earlier</p>
                <p className="tspacer">Can be combined and specified as NmNhNd</p>
                <h5 className="tspacer">Absolute time <span className="normal">(ISO 8601 format)</span>:</h5>
                <p>MM/DD/YYYY:HH:MM:SS</p>
                <h4 className="tspacer">Epoch seconds</h4>
                This is a mandatory field.
            </td>
        </tr>
        <tr>
            <td>to</td>
            <td>
                <h4>End time (exclusive). Accepts the same format as above.</h4>
                <h4>A special value of &quot;now&quot; can be specified to retrieve logs up to but not including the current time.</h4>
                This is a mandatory field.
            </td>
        </tr>
        <tr>
            <td>service_id</td>
            <td>
                <h4>Comma separated list of the service ids whose log data should be received.</h4>
                This is a mandatory field
            </td>
        </tr>
        <tr>
            <td>client_ip</td>
            <td>Filter messages only from the client IP.</td>
        </tr>
        <tr>
            <td>origin_ip</td>
            <td>Filter messages only relevant to the origin IP address.</td>
        </tr>
        <tr>
            <td>url</td>
            <td>Filter messages matching the URL exactly.</td>
        </tr>
        <tr>
            <td>response_code</td>
            <td>
                <h4>Filter messages matching the response code. You can specify ranges like:</h4>
                response_code=200-205
            </td>
        </tr>
        <tr>
            <td>request_headers.XXX</td>
            <td>
                <h4>Filter messages that contain the request header matching the value, like:</h4>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;request_headers.Content-Type=application/json</h4>
                <h4>&nbsp;&nbsp;&nbsp;&nbsp;request_headers.Host=www.example.com</h4>
                <h4>Both header name and values are case sensitive. Can be specified multiple times.</h4>
            </td>
        </tr>
        <tr>
            <td>response_headers.XXX</td>
            <td>
                <h4>Filter messages that contain the response header matching the value, like:</h4>
                <h4>&nbsp;&nbsp;&nbsp;response_headers.X-Zy-Cache-Hit=MISS</h4>
                <h4>Both header name and values are case sensitive. Can be specified multiple times.</h4>
            </td>
        </tr>
        </tbody>
    </table>

    <div className="before-code"><h3>Response</h3></div>
    The response body is a JSON object that contains the logs. The following describes
    the JSON object returned by the server.
    <pre className="expected-response">{`[
    {
        "status": "success",                    // string. API response status.
        "next": "",                             // string. URL to fetch the next page of the response.
        "logs": [ ]                             // array of log entry objects which are defined below.
    }
]

The following is the format of the log entry objects:

{
    "@timestamp": "",               // string. Log creation timestamp in ISO 8601 format.
    "client_ip": "x.x.x.x",         // string. IP Address of the end client.
    "client_port": 61938,           // number. Protocol port used at the end client.
    "http2_stream_id": 3,           // number. HTTP2 Stream ID of the incoming request.
    "request_headers":              // object. Request headers in the incoming request.
        {
            "accept": "",           // string. accept header in the incoming request.
            "accept-encoding": "",  // string. Accept-encoding header in the incoming request.
            "accept-language": "",  // string. Accept-language header in the incoming request.
            "host": "",             // string. Host header in the incoming request.
            "referer": "",          // string. Referer header in the incoming request.
            "user-agent": ""        // string. User-agent header in the incoming request
        },
    "request_method": "GET",        // string. HTTP Request method corresponding to the incoming request
    "request_size": 123,            // number. Size in bytes of the incoming request.
    "request_uri": "",              // string. URI in the incoming request.
    "request_version": "",          // string. HTTP Protocol version used in the incoming request.
    "response_headers":             // object. Response headers in the outgoing response
        {
            "cache-control": "",            // string. Cache-control header in the outgoing response.
            "content-encoding": "",         // string. content-encoding header in the outgoing response.
            "content-type": "text/html",    // string. content-type header in the outgoing response
            "expires": "",                  // string: expires header in the outgoing response.
            "strict-transport-security": "",// string. strict-transport-security header in the outgoing response
            "timing-allow-origin": ""       // string. timing-allow-origin header in the outgoing response.
        },
    "response_size": 8675,          // number. Size in bytes of the outgoing response.
    "response_status": 200,         // number. HTTP Status code in the outgoing response.
    "response_time_ms": 3,          // number. Time taken to respond to the request.
    "ssl_cipher_suite": "",         // string. Cipher Suite used for SSL handshake.
    "ssl_protocol": "",             // string. SSL Protocol used for SSL handshake
    "ssl_sess_reused": 0,           // number. SSL session reuse indicator.
}

Note: Additional fields may be included.
`}</pre>

    <div className="before-code"><h3>HTTP Response Status</h3></div>
    <ul className="simple-list sample-response">
        <li><h4>200</h4> Success.</li>
        <li><h4>400</h4> Bad Request. (e.g. invalid field type in the request)</li>
        <li><h4>401</h4> Invalid API key.</li>
        <li><h4>403</h4> You are trying to obtain logs for domains that aren&apos;t yours.</li>
    </ul>

    <div className="before-code"><h3>Sample HTTP Request</h3></div>
    <pre className="sample-response">{`

    https://api.zycada.com/v1/logs?from=-5m&to=now&request_headers.Host=www.example.com

`}</pre>

    <div className="before-code"><h3>Sample HTTP Response</h3></div>
    <pre className="sample-response">{`{
	"status": "success",
	"next": "https://api.zycada.com/v1/logs?from=-5m&to=now&request_headers.Host=www.example.com&skip=1000&limit=1000",
	"logs": [{
		"@timestamp": "2019-03-13T19:42:24Z",
		"client_ip": "x.x.x.x",
		"client_port": 61938,
		"http2_stream_id": 3,
		"request_headers": {
			"accept": "*/*",
			"accept-encoding": "br, gzip, deflate",
			"accept-language": "en-us",
			"host": "www.example.com",
			"referer": "https://www.example.com/index.html",
			"user-agent": "Mozilla/5.0 (iPhone; CPU iPhone..."
		},
		"request_id": 2,
		"request_method": "GET",
		"request_size": 18,
		"request_time_ms": 0,
		"request_uri": "/search.html",
		"request_version": "HTTP/2.0",
		"response_headers": {
			"cache-control": "max-age=2592000",
			"content-encoding": "gzip",
			"content-type": "text/html",
			"expires": "Fri, 12 Apr 2019 19:42:24 GMT",
			"strict-transport-security": "max-age=15768000",
			"timing-allow-origin": "*"
		},
		"response_size": 8675,
		"response_status": 200,
		"response_time_ms": 3,
		"ssl_cipher_suite": "ECDHE-RSA-CHACHA20-POLY1305",
		"ssl_protocol": "TLSv1.2",
		"ssl_sess_reused": 0,
        ...
	},
    ...
    ]
}`}</pre>
</div>
</>
    )
}


export default ApiDocLogs;