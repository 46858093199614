import { State } from './authentication';
import logger from '../shared/logUtilities'
import { StringMap } from '../data/metricsAndOptionsDefs';

export function buildUrl(state: State, url: string): string {
  let newUrl = '';
  if (state && state.currentApiURL && state.currentApiURL.length > 0 ) {
    newUrl =  (url.startsWith('##')) ? url : state.currentApiURL + url;
    newUrl = (url.startsWith('##')) ? newUrl.substring(2) : newUrl;
  } else {
    logger.alert('bad state.currentAPIURL');
  }
  return newUrl;
}

export const orgAccessToNewFeatures = (state: State, orgId: string): boolean => {
  let featuresAreSupported = false;

  if (state.serverConfig.features) {
    const orgs = state.serverConfig.features.split(',');
    const orgIdx = orgs.findIndex((oId: string) => {
      return oId === orgId;
    })
  
    if (orgIdx !== -1) {
      featuresAreSupported = true;
    }
  }

  return featuresAreSupported;
}

export const orgAccessToCSAFeatures = (state: State, orgId: string): boolean => {
  let csaIsSupported = false;

  if (state.serverConfig.CSALite) {
    const orgs = state.serverConfig.CSALite.split(',');
    const orgIdx = orgs.findIndex((oId: string) => {
      return oId === orgId;
    })
  
    if (orgIdx !== -1) {
      csaIsSupported = true;
    }
  }

  return csaIsSupported;
}

export const orgAccessToReports = (state: State, orgId: string): boolean => {
  const featuresAreSupported = false;

// This code is a placeholder used to hide the billing feature until we are ready to release it.
  if (featuresAreSupported) {
    console.log(state.serverConfig, orgId)
  }

  return featuresAreSupported;
}

export function buildLoginServerURL(state: State, url: string): string {
  let newUrl = '';

  if (url && url.length > 0 && 
      state && state.serverConfig && state.serverConfig.login_url && state.serverConfig.login_url.length > 0 ) {
         newUrl =  (url.startsWith('##')) ? url : state.serverConfig.login_url + url;
  } else {
    logger.alert('bad Login URL');
  }
  return newUrl;
}

export function isAutomatEnabled(state: State): boolean {
  return state.serverConfig.automatEnabled;
}

let publishPromoteObj: StringMap = { 'days': '30' };

export function setPublishPromoteValue(o: StringMap): void {
  if (o !== undefined) {
    publishPromoteObj = o;
  }
}

export function getPublishPromoteValue(): StringMap {
  return publishPromoteObj;
}