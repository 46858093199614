import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faArrowLeft, faArrowRight, faArrowsAltH, faCalendarAlt, faCaretDown, faCaretRight, faCaretSquareRight, faCaretSquareDown, faCaretUp, faCaretLeft, faChartLine, 
         faCheck, faCheckCircle, faCloud, faCog, faCogs, faCopy, faDotCircle, faEnvelope, faFileAlt, faLock, faLockOpen, faKey, faLongArrowAltUp, faLongArrowAltDown,
         faMinus, faPencilAlt, faShieldAlt, faSitemap, faSignOutAlt, faSlidersH, faSort, faSortDown, faSortUp, faSync, faSquare, faTachometerAlt, faToggleOff, faToggleOn, 
         faTrashAlt, faTimesCircle, faSpinner, faExclamationTriangle, faFile, faUsersCog } 
         from '@fortawesome/free-solid-svg-icons';

export default {
    init: (): void => library.add(
      faAngleLeft,
      faArrowLeft,
      faArrowRight,
      faArrowsAltH,
      faCalendarAlt,
      faCaretUp,
      faCaretDown,
      faCaretSquareRight,
      faCaretSquareDown,
      faCaretRight,
      faCaretLeft,
      faChartLine,
      faCheck,
      faCheckCircle,
      faCloud,
      faCog,
      faCogs,
      faCopy,
      faDotCircle,
      faExclamationTriangle,
      faEnvelope,
      faFile,
      faFileAlt,
      faKey,
      faLongArrowAltUp, 
      faLongArrowAltDown,
      faLock,
      faLockOpen,
      faMinus,
      faPencilAlt,
      faShieldAlt,
      faSitemap,
      faSignOutAlt,
      faSlidersH,
      faSort,
      faSortDown,
      faSortUp,
      faSpinner,
      faSquare,
      faSync,
      faTachometerAlt,
      faTimesCircle,
      faToggleOff,
      faToggleOn,
      faTrashAlt,
      faUsersCog,
    )
};
