import * as React from 'react';

const apiDocCache = (): JSX.Element => {
    return (
<>
<div className="z-title">Zycada Cache Purge API</div>
<div className="zsection">
    Zycada&apos;s Cache Purge API allows you manage one or more assets in the Zycada Delivery Network.

    <div className="before-code"><h3>Supported Operations</h3></div>
    <ul>
        <li>Purge -  Purge one or more assets from the cache. Use this when you need to update a URI manually.</li>
        <li>Status - Obtain the status of a purge request.</li>
    </ul>

    <div className="zheading">API methods and endpoints:</div>
    <table className="zcmd-summary">
        <thead>
        <tr>
            <th>URL / Command</th><th>GET</th><th>POST</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>
                https://api.zycada.com/v1/cache/purge<br/>
                https://staging-api.zycada.com/v1/cache/purge
            </td>
            <td>N/A</td>
            <td>Create a Cache Purge request</td>
        </tr>
        <tr>
            <td>
                https://api.zycada.com/v1/jobs<br/>
                https://staging-api.zycada.com/v1/jobs
            </td>
            <td>Gets the status of the specified Purge request</td>
            <td>N/A</td>
        </tr>
        </tbody>
    </table>
</div>
</>
    )
}

export default apiDocCache;
