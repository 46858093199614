import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';

import * as actionCreators from '../../actions/actionCreators';
import { actionTypes } from '../../actions/actionCreatorTypes';

import { ZRestResponse, FetchError, DeploymentHistory, DeploymentHistoryItem, ZUserMin, ServiceStatusEnum,
         APISvrJobStatusEnum, DeploymentRecord, DeploymentPublishResponse, ZUserTypes } 
        from '../../data/queryResultDefinitions';
import { DeployedItem, HistoryViewIdxEnum, VersionListEntry, DestinationIdxEnum } 
        from '../../reducers/serviceState';

import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { EProjectState, ZEnvironments } from '../../reducers/reducerEnums';
import { _CurrentProject, ZService } from '../../reducers/systemState';
import { isServiceDeployable } from '../../reducers/editServiceValidations';

import { NotificationStyles, DATETIME_FORMAT_NOSECS, DATETIME_FORMAT } from '../../shared/constants';
import { SERVICES_STRINGS as STRs, MISC_STRINGS, SUBNAVSTRINGS } from '../../shared/strings'; 
import { dateFixup } from '../../shared/utilities';
import { ZGet, ZPost } from '../../shared/backend';

import ZDropdown from '../../shared/ZDropDown';
import ZButton from '../../shared/ZButton';
import ListPanel from '../../shared/ListPanel';
import logger from '../../shared/logUtilities';
import ZURLS, { makeQueryParams } from '../../shared/urls';
import GenDialog from '../../shared/GenDialog';

export interface DeployServiceProps extends State {
    applySettings: (error?: string) => actionTypes;
    changeServiceEnvironment: (env: ZEnvironments) => actionTypes,
    setSelectedServiceDetail: (serviceConfig: ZService, isEditable?: boolean, serviceConfigProd?: ZService) => actionTypes,
    setDeploymentHistory: (deploymentHistory: DeploymentHistoryItem[]) => actionTypes,
    setDeploymentHistoryViewIndex: (index: number) => actionTypes,
    setDeploymentDestinationIndex: (index: number) => actionTypes,
    setDeploymentVersionIndex: (index: number) => actionTypes,
    setUnsafePromoteState: (unsafePromoteCheckState: boolean, unsafePromoteNeedConfirmation: boolean,
                            unsafeCertNameMismatch: boolean) => actionTypes, 
    refreshPage: () => actionTypes,
    featureReady: () => actionTypes,

    showNotification: (style: NotificationStyles, message: string) => actionTypes,
    closeNotification: () => actionTypes,
}

const iconColor = {
    'check-circle': 'green-check',
    'exclamation-triangle': 'red-x',
    'fa-spinner': '',
}

class DeployService extends React.Component<DeployServiceProps> {
    componentDidMount() {
        logger.log('mounting DeployServices');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: DeployServiceProps) {
        const serviceState = this.props.serviceUIState.serviceState;
        const systemState = this.props.systemNavProjectState;
        const systemUIState = systemState.systemUIState;
        // const project = this.props.systemNavProjectState._currentProject;

        // logger.log('--> componentDidUpdate - deploy services')
        // logger.log(`---> serviceState: ${serviceState}; systemState: ${systemUIState}`);
        // logger.log(`---> env: ${project.currentServiceEnvironment}; envData:`, project.currentEnv)

        try {
            if (systemUIState === EProjectState.serverError) {
                return;
            }

            if (! systemState.servicesLoaded) {
                return;
            }

            if (systemUIState === EProjectState.servicesUpdated) {
                this.props.featureReady();
                this.initDeployServicesPage();
                return;
            }

            if (systemUIState === EProjectState.serviceEnvironmentChanged) {
                this.initDeployServicesPage();
                this.props.featureReady();
            }

            if (serviceState === EProjectState.reloadData) {
                this.initDeployServicesPage();
            }

            if (systemUIState === EProjectState.serviceChanged) {
                this.props.featureReady();
                this.initDeployServicesPage();
            }
        } catch (e) {
            console.log('DeployService.componentDidUpdate: catch error:' + e);
        }
    }

    render() {
        const systemState = this.props.systemNavProjectState;
        const serviceState = this.props.serviceUIState;
        const svcIdx = systemState.serviceEvtIdx;
        const serviceName = systemState.serviceEvents[svcIdx];
        const currentProj = systemState._currentProject;
        let markup = <span></span>;
        
        if (currentProj.servicesList.length === 0) {
            markup = (
                <div className="feature-panel">
                <div className="servicesFeature">
                    <div>
                        <div className="title">{STRs.plainDeployServices}</div>
                        <div>{STRs.noServices}</div>
                    </div>
                </div>
                </div>
            );
        } else if (serviceState.serviceState !== EProjectState.serverError) {
            const svcMap = currentProj.serviceIdMap;
            const serviceId = currentProj.currentEnv.serviceNameToId[serviceName];
            let deployPanel = <div></div>;
            if (svcMap[serviceId] && svcMap[serviceId].status !== ServiceStatusEnum.deleting) {
                const status = this.buildStatusPanel(serviceName);
                const history = this.buildHistoryPanel();
                deployPanel = (
                    <>
                        <div>
                            {status}
                        </div>
                        <div>
                            {history}
                        </div>
                    </>
                )
            } else {
                deployPanel = (
                    <div><strong>{STRs.svcDeletionInProgress}</strong></div>
                )
            }
            
            const unsafePromoteConfirmationMarkup = (serviceState.unsafePromoteNeedConfirmation) ? this.buildUnsafePromoteConf() : <div></div>;
            markup = (
                <div className="feature-panel">
                    <div className="servicesFeature">
                        <div className="title-with-refresh">
                            <div className="title">{STRs.deployServiceTitle(serviceName)}</div>
                            <div>
                                <ZButton onClick={() => { this.props.refreshPage() }}>{MISC_STRINGS.refresh}</ZButton> 
                                <span className="padding">{MISC_STRINGS.lastUpdateLabel}&nbsp;
                                {serviceState.lastUpdate.format(DATETIME_FORMAT)}</span>
                            </div>
                        </div>
                        <div className="deploy-panel">
                            {deployPanel}
                        </div>
                    </div>
                    {unsafePromoteConfirmationMarkup}
                </div>
            )
        } else {
            markup = (
            <div className="feature-panel">
                <div className="servicesFeature">
                {MISC_STRINGS.serverError2}
                </div>
            </div>
            );
        }

        return markup;
    }

    private buildStatusPanel = (serviceName: string): JSX.Element => {
        const {selectedServiceVersion, availableDeploymentVersionList} = this.props.serviceUIState;
        if (!selectedServiceVersion || availableDeploymentVersionList.length === 0) {
            return <div>{STRs.loadingDeploymentInformation}</div>;
        }

        const controlPanel = this.buildControls();
        const stagingStatus = this.buildDestStatusPanel(STRs.stagingStatus, ZEnvironments.staging, STRs.stagingEnv);
        const prodStatus = this.buildDestStatusPanel(STRs.prodStatus, ZEnvironments.prod, STRs.productionEnv);

        if (! serviceName ) {
            this.props.applySettings();
            return <span></span>;
        }

        const markup = (
            <div className="deploy-overview">
                {controlPanel}
                {stagingStatus}
                {prodStatus}
            </div>
        );
        
        return markup;
    }

    private buildControls = ( ): JSX.Element => {
        const serviceState = this.props.serviceUIState
        const authState = this.props.authNServerData;
        const { availableDeploymentVersionList, versionIdx, destinationIdx, currentDeploymentInProgressStatus } = serviceState;

        const destinations = [
            STRs.stagingEnv,
            STRs.productionEnv
        ];

        let promoteToProdMarkup = <div></div>;
        if ((authState.user as ZUserMin).role === ZUserTypes.zadmin) {
            const disableChk = (destinationIdx === DestinationIdxEnum.prod)
            const { unsafePromoteEnabled, unsafePromoteNeedConfirmation, unsafeCertNameMismatch } = serviceState;

            promoteToProdMarkup = (
                <>
                <div>
                    <div><input type="checkbox" checked={unsafePromoteEnabled}  disabled={disableChk}
                                onChange={(): void => { 
                                        this.props. setUnsafePromoteState(!unsafePromoteEnabled, unsafePromoteNeedConfirmation, unsafeCertNameMismatch);
                                    }}></input></div>
                    <div>{STRs.unsafePromoteToProd}</div>
                </div>
                <div>
                    <div><input type="checkbox" checked={unsafeCertNameMismatch}  disabled={disableChk}
                                onChange={(): void => { 
                                        this.props. setUnsafePromoteState(unsafePromoteEnabled, unsafePromoteNeedConfirmation, !unsafeCertNameMismatch);
                                    }}></input></div>
                    <div>{STRs.unsafeIgnoreCertMismatch}</div>
                </div>
                </>
            )
        }
        let versions: string[] = [];
        versions = availableDeploymentVersionList.map((version: VersionListEntry): string => {
            return version.versionName;
        })

        if (versions.length === 0) {
            return <div></div>;
        }

        const env = (destinationIdx === DestinationIdxEnum.prod) ? ZEnvironments.prod : ZEnvironments.staging;
        const isDeployInProgress = currentDeploymentInProgressStatus[env];
        const canDeployService = isServiceDeployable(serviceState, serviceState.selectedServiceVersion)
        const isBtnDisabled = (availableDeploymentVersionList[0].versionNumber === -1) || isDeployInProgress || !canDeployService;

        const destList = ZDropdown(destinations, destinationIdx, 'deploy-dest', 
                                 (idx: number) => { this.props.setDeploymentDestinationIndex(idx) });
        const versionList = ZDropdown(versions, versionIdx, 'deploy-dest', 
                            (idx) => { this.props.setDeploymentVersionIndex(idx) });
        const btnLabel = (destinationIdx === DestinationIdxEnum.staging) ? STRs.deployToStaging : STRs.deployToProduction;
        
        return (
            <div className="deploy-controls">
                <div>{STRs.deployDestination}</div>
                <div>{destList}</div>
                <div>{STRs.deployVersion}</div>
                <div>{versionList}</div>
                {promoteToProdMarkup}
                <div>
                    <ZButton btnType="primary" btnCls="deploy-btn" disabled={isBtnDisabled} onClick={
                        () => {
                            this.deployToEnvironment()
                        }}>
                        {btnLabel}
                    </ZButton>
                </div>
            </div>
        )
    };

    private buildUnsafePromoteConf = () => {
        // const cssWidth: ZModalSize = 'sm';
        const { unsafePromoteEnabled, unsafeCertNameMismatch} =this.props.serviceUIState
        return (
            <GenDialog 
            show={true}
            title={STRs.confirmUnsafePromoteTitle} 
            msg={STRs.confirmUnsafePromote}
            cancelBtnTxt={MISC_STRINGS.cancelBtn}
            applyBtnTxt={SUBNAVSTRINGS.deployService}
            disableApply={(): boolean => { return false; }}
            onHide={(): void => {this.props.setUnsafePromoteState(unsafePromoteEnabled, false, unsafeCertNameMismatch)}}
            handleApply={(): void => { this.deployToEnvironment(); }}
        >
            <br></br>
            {STRs.confirmUnsafePromote1}
        </GenDialog>)
    }

    private buildDestStatusPanel = (title: string, env: ZEnvironments, envName: string): JSX.Element => {
        const serviceState = this.props.serviceUIState;
        const destinationStatusMap = serviceState.destinationStatusMap;
        const dRecord: DeploymentRecord = destinationStatusMap[env];

        const envDepHistory: DeploymentHistoryItem | undefined = serviceState.deploymentHistory.find(
                                                   (hist: DeploymentHistoryItem) => { return hist.env === env })

        const deployedItems: DeploymentRecord[] = (envDepHistory) ? envDepHistory.details : []; 

        let line1 = <div></div>;
        let line2 = <div></div>;
        let line3 = <div></div>;
        
        if (dRecord === undefined) {
            return this.buildDeployedStatus(title, env, envName);
        } else {

            if (dRecord.status === APISvrJobStatusEnum.in_progress) {
                let fromVersion: string | number = '';

                if (dRecord.prev_version === null || dRecord.prev_version === undefined) {
                    fromVersion = STRs.new;
                } else {
                    fromVersion = dRecord.prev_version;
                }
                line1 = <div>{STRs.deploying}&nbsp;{fromVersion} &nbsp; 
                          <FontAwesomeIcon icon={'arrow-right' as IconProp} /> &nbsp; {dRecord.version}</div>
                line3 = <div className="spinner">{STRs.deployInProgress}&nbsp;
                            <FontAwesomeIcon spin={true} icon={'sync' as IconProp} /></div>
            } else if (dRecord.status === APISvrJobStatusEnum.success) {
                line1 = ( <div>{STRs.deployedVersion(dRecord.version)}</div>);
                
                let firstEnvRecord = -1;
                for (let i = 0, len = deployedItems.length; i < len; i++) {
                    const record = deployedItems[i];
                    if (record.status === APISvrJobStatusEnum.accepted) {
                        continue;
                    }

                    if (firstEnvRecord < 0) {
                        firstEnvRecord = i;
                    }

                    if (record.version === dRecord.version) {
                        if (firstEnvRecord !== i) {
                            const lastAttempt: DeploymentRecord = deployedItems[firstEnvRecord];
                            line3 = <div><span>{STRs.attemptedDeploymentFailed(lastAttempt.version)}</span><br/>
                                        <span className="red-x indent">{STRs.attemptFailed}</span></div>
                        }
                        break;
                    }
                }
            }

            const lastUpdate = moment(dateFixup(dRecord.updated_at));
            line2 = <div>{STRs.updatedAt(lastUpdate.format(DATETIME_FORMAT_NOSECS))}</div>;
        }

        return (
            <div className="deploy-server-status">
                <div>{title}</div>
                {line1}
                {line2}
                {line3}
            </div>
            );
    };

    private buildDeployedStatus = (title: string, env: ZEnvironments, envName: string): JSX.Element => {
        const serviceState = this.props.serviceUIState;
        let line1 = <div></div>;
        let line2 = <div></div>;
        const line3 = <div></div>;

        const service = env === ZEnvironments.staging ? serviceState.selectedServiceVersion : serviceState.selectedServiceVersionProd;
        
        if (service.service_id === undefined || service.is_live === false) {
            line1 = <div>{STRs.noDeploymentYet(envName)}</div> ;
        } else {
            line1 = ( <div>{STRs.deployedVersion(service.version)}</div>);
            line2 = <div>{STRs.updatedAt(moment(service.updated_at).format(DATETIME_FORMAT_NOSECS))}</div>;
        }

        return (
            <div className="deploy-server-status">
                <div>{title}</div>
                {line1}
                {line2}
                {line3}
            </div>
            );
    }

    private buildHistoryStatus = (status: string, env: string): JSX.Element => {
        let msgCSS = '';
        let text = '';
        let icon = '';
        let spin = false;
    
        switch (status) {
            case APISvrJobStatusEnum.accepted:
                text = STRs.deploymentAccepted;
                break; 

            case APISvrJobStatusEnum.in_progress: 
                text = STRs.deployTo(env);
                icon = 'sync';
                spin = true;
                break;
        
            case APISvrJobStatusEnum.success:
                text = STRs.deploySuccess(env);
                icon = 'check-circle';
                break;

            case APISvrJobStatusEnum.failed:
                text = STRs.deployFailed(env);
                icon = 'exclamation-triangle';
                msgCSS = 'red-x';
                break;

            default:
                text = '';
                break;
        }
        
        const faIcon = (icon.length > 0) ? 
                    <FontAwesomeIcon className={iconColor[icon]} spin={spin} icon={icon as IconProp} />  : 
                    <span></span>;
        const statusMarkup = (<>{faIcon}&nbsp;<span className={msgCSS}>{text}</span></>);
        return statusMarkup;
    }

    private buildHistoryPanel = (): JSX.Element => {
        const serviceState = this.props.serviceUIState;
        const { deployedItems, historyViewIdx, selectedServiceVersion, 
              availableDeploymentVersionList } = serviceState;
        let markup = <span></span>;
        let viewList = <span></span>;

        if (!selectedServiceVersion || availableDeploymentVersionList.length === 0) {
            return <div></div>;
        }

        const views = [
            STRs.stagingProd,
            STRs.stagingEnv,
            STRs.productionEnv,
        ];

        viewList = ZDropdown(views, historyViewIdx, 'view-list', 
                             (idx) => { if (historyViewIdx !== idx) { this.props.setDeploymentHistoryViewIndex(idx) }}
        );

        if (deployedItems.length === 0) {
            const msg = serviceState.selectedServiceVersion.service_id !== undefined ? STRs.noDeploymnetHistory: STRs.newServiceNoDeployment ;
            markup = (
                <>
                    <div className="new-service-no-history">
                        {msg}
                    </div>
                </>
            )
        } else {
            const deployHeader: JSX.Element = (
                <div className="service-deploy-header">
                    <div></div>
                    <div>{STRs.environment}</div>
                    <div>{STRs.date}</div>
                    <div>{STRs.fromVersion}</div>
                    <div>{STRs.toVersion}</div>
                    <div>{MISC_STRINGS.status}</div>
                </div>);

                const table: JSX.Element[] = [];

            try {
                for (let i = 0, len = deployedItems.length; i < len; i++) {
                    const item: DeployedItem = deployedItems[i];
                    const env = item.env === 'prod' ? STRs.productionEnv : STRs.stagingEnv;
                    let rowClass = '';
                    if (historyViewIdx === HistoryViewIdxEnum.both) {
                        rowClass = (item.env === 'prod') ? 'prod-service' : '';
                    }
                    const status = this.buildHistoryStatus(item.status, item.env);
                    table.push((
                        <div key={'table' + i} className="service-deploy-item">
                            <div className={rowClass}></div>
                            <div >{env}</div>
                            <div>{item.updated_at.format(DATETIME_FORMAT_NOSECS)}</div>
                            <div>{item.prev_version}</div>
                            <div>{item.version}</div>
                            <div>{status}</div>
                        </div>
                    ))
                }

            } catch ( err ) {
                console.log('error build deploy history');
            }
            markup =  (
                <>
                    <div>
                        <ListPanel 
                        cssClass="history-table"
                        renderHeader={() => { return deployHeader; }}
                        renderBody={() => { return <div>{table}</div> }}>
                        </ListPanel>
                    </div>
                </>
            );
        }

        return (
            <div className="deploy-history-panel">
                <div>{STRs.deploymentHistory}</div>
                <div>{STRs.view}&nbsp;&nbsp; {viewList}</div>
                {markup}
            </div>
        )
    }

    private initDeployServicesPage = () => {
        const systemState = this.props.systemNavProjectState;
        const svcIdx = systemState.serviceEvtIdx;
        const serviceName = systemState.serviceEvents[svcIdx];
        // console.log(`svcId: ${svcIdx}; serviceName: ${serviceName}`);

        if (systemState._currentProject.currentServiceEnvironment === ZEnvironments.prod) {
            this.props.changeServiceEnvironment(ZEnvironments.staging);
            return;
        }

        if (! serviceName ) {
            this.props.applySettings();
            return;
        }

        const project: _CurrentProject = this.props.systemNavProjectState._currentProject;
        const sDesc: ZService | undefined = 
                project.servicesList.find((svc => { return svc.serviceName === serviceName} )
        );

        if (sDesc !== undefined) {
            // this.getServiceHistory(sDesc.serviceId)
            this.loadAllProjectServices(sDesc.serviceId);
        }
    }

    private loadAllProjectServices = async (serviceId: string) => {
        const projectId = this.props.systemNavProjectState._currentProject.projectId;

        if (projectId === undefined) {
            return;
        }

        try {
            const url = buildUrl(this.props.authNServerData, ZURLS.serverServiceMgmtProjectLvl);
            const serviceList: ZRestResponse = await ZGet(url, {project_id: projectId });
            const allServices = serviceList as ZService[];

            const deployedServiceList: ZRestResponse = await ZGet(url, {project_id: projectId, is_live: true });
            const deployedServices = deployedServiceList as ZService[];

            for (let i = 0, len = allServices.length; i < len; i++) {
                const service: ZService = allServices[i];
                let stgSvc: ZService | undefined = undefined;
                if (service.service_id === serviceId) {
                    if (service.env !== ZEnvironments.staging) {
                        if (service.meta && service.meta.staging_info && service.meta.staging_info.service_id) {
                            const stgSvcId = service.meta.staging_info.service_id;
                            stgSvc = deployedServices.find((svc: ZService) => {
                                return (svc.service_id === stgSvcId)
                            }) 
                            if (stgSvc === undefined) {
                                logger.alert('could not find associated staging service');
                                return;
                            }
                        }
                    } else {
                        stgSvc = service;
                    }
                    if (stgSvc !== undefined) {
                        const stgMeta = stgSvc.meta;
                        const prodInfo = (stgMeta !== undefined) ? stgMeta.prod_info : undefined;
                        const prodSvcId = (prodInfo !== undefined) ? prodInfo.service_id : '';

                        let prodSvc: ZService | undefined;
                        if (prodSvcId.length !== 0) {
                            prodSvc = deployedServices.find(svc => { return svc.service_id === prodSvcId});
                        }

                        this.props.setSelectedServiceDetail(stgSvc, false, prodSvc);

                        const url = buildUrl(this.props.authNServerData, ZURLS.serverDeploymentHistory(stgSvc.service_id));
                        const hist: ZRestResponse = await ZGet(url, {include_env: 'prod,staging'});
                        const deployHist = hist as DeploymentHistory;
                        this.props.setDeploymentHistory(deployHist.deployment_history);
                        break;
                    }
                }
            }
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`DeployServices.loadAllProjectServices: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification('serverError');
            })
            .catch((err) => {
                console.log(`DeployServices.loadAllProjectServices: fetch failed: ${err}`);
                this.props.applySettings('serverError')
            })
        }
    }

    private deployToEnvironment = async () => {
        const authState = this.props.authNServerData;
        const { availableDeploymentVersionList, versionIdx, destinationIdx, currentDeploymentInProgressStatus,
              selectedServiceVersion, unsafePromoteEnabled, unsafePromoteNeedConfirmation, unsafeCertNameMismatch } = this.props.serviceUIState;

        if (unsafePromoteEnabled && !unsafePromoteNeedConfirmation) {
            this.props.setUnsafePromoteState(unsafePromoteEnabled, !unsafePromoteNeedConfirmation, unsafeCertNameMismatch);
            return;
        }
        
        try {
            const env = (destinationIdx === DestinationIdxEnum.prod) ? ZEnvironments.prod : ZEnvironments.staging;
            const isDeployInProgress = currentDeploymentInProgressStatus[env];
            const cantDeployNow = (availableDeploymentVersionList[0].versionNumber === -1) || isDeployInProgress;
            if (cantDeployNow) {
                return;
            }
            const version = availableDeploymentVersionList[versionIdx].versionNumber;

            const serviceId = selectedServiceVersion.service_id;

            let url = '';
            const params = {
                version
            }
            if (((authState.user as ZUserMin).role === ZUserTypes.zadmin) && (destinationIdx === DestinationIdxEnum.staging) && 
                            unsafePromoteEnabled) {
                params['unsafe_promote_to_prod'] = true
                logger.log(`unsafe promotion url = ${url}`);
            } 
            url = (destinationIdx === DestinationIdxEnum.staging) ? ZURLS.serverDeployServiceToStaging(serviceId) : 
                                                                    ZURLS.serverDeployServiceToProd(serviceId);

            if  (unsafeCertNameMismatch) {
                params['unsafe_ignore_cert_name_mismatch'] = true
            }

            url = makeQueryParams(url, params)

            url = buildUrl(this.props.authNServerData, url);
            const resp: ZRestResponse = await ZPost(url, '');
            const publishResp = resp as DeploymentPublishResponse;
            logger.log(`deployToEnv: ${url}, response: ${publishResp}`);
            window.setTimeout(() => {this.props.refreshPage()}, 1)
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`DeployServices.deployToEnvironment: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification('serverError');
            })
            .catch((err) => {
                console.log(`DeployServices.deployToEnvironment: fetch failed: ${err}`);
                this.props.applySettings('serverError');
            })
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private closeNotification = (error?: string) => {
        if (this.props.serviceUIState.serviceState === EProjectState.initComplete ||
            this.props.serviceUIState.serviceState === EProjectState.initStarted ) {
                this.props.applySettings('server error');
        }

        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        serviceUIState: state.serviceUIState,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(DeployService);