import { cacheMetrics, efrMetrics, MetricsUIDefinitions, nrumMetrics, pushMetrics, trafficMetrics, 
         wafMetrics, botMetrics, BooleanMap, dnsDirMetrics, csmMetrics, csaMetrics } from '../data/metricsAndOptionsDefs';
import { ZServiceFeatures, RestAPI, ProjUriCode, ZServiceExt, ZCloudServiceMonitor } from '../data/queryResultDefinitions';

import { State } from './analyticsState';
import { State as SystemNavProjectState } from './systemState';
import { SysFeatureEnums } from './reducerEnums';
import { hourIntervalData, ProjectDspType, ProjDTypeFeatures, minuteIntervalData } from '../shared/constants';
import { NAVSTRINGS, SUBNAVSTRINGS, MISC_STRINGS } from '../shared/strings';
import logger from '../shared/logUtilities';
import { projectDspTypeFeatureMapping, ZALL_SERVICES, CDN_GEOs } from '../shared/constants';

export const emptyMetricDataJSON = ((): string => JSON.stringify({
    'metric': '',
    'stats': '',
    'data': [{'tags': {'metric_type': '', 'origin_ip': ''}, 'time_values': []}]
}))();

export interface SubNavItem {
    icon: string;
    name: string;
    url: string;
    feature: string;
}

export enum OptAnalyticFeatures {
    dnsDir = 'dnsDir', 
    csm = 'csm',
}

export interface NavBarInfo {
    [name: string]: SubNavItem[];
} 

export interface ProjectMetricDefinitions {
    analyticsCache?: MetricsUIDefinitions[];
    analyticsCSM?:  MetricsUIDefinitions[];
    analyticsCSA?:  MetricsUIDefinitions[];
    analyticsDnsDir?: MetricsUIDefinitions[];
    analyticsEFR?: MetricsUIDefinitions[];
    analyticsNRUM?: MetricsUIDefinitions[];
    analyticsPush?: MetricsUIDefinitions[];
    analyticsTraffic: MetricsUIDefinitions[];
    analyticsWAF?: MetricsUIDefinitions[];
    analyticsBOT?: MetricsUIDefinitions[];
}

export interface SelectedMetrics {
    [metricKey: string]: boolean;
}

export interface ProjectToDTypeMapping {
    [projectName: string]: ProjectDspType;
}

export interface TabMappingObj {
    [metricKey: string]: MetricsUIDefinitions;
}

interface QueryStartEndTime {
    startTime: number; 
    endTime: number
}

interface TabMetricMap  {
    [SysFeatureEnums.trafficAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.cacheAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.efrAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.nrumAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.pushAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.wafAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.botAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.dnsDirAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.csmAnl]: MetricsUIDefinitions[];
    [SysFeatureEnums.csaAnl]: MetricsUIDefinitions[];

    analyticsTrafficObj: TabMappingObj;
    analyticsEFRObj: TabMappingObj;
    analyticsNRUMObj: TabMappingObj;
    analyticsPushObj: TabMappingObj;
    analyticsCacheObj: TabMappingObj;
    analyticsWAFObj: TabMappingObj;
    analyticsBOTObj: TabMappingObj;
    analyticsDnsDirObj: TabMappingObj;
    analyticsCSMObj: TabMappingObj
    analyticsCSAObj: TabMappingObj
}

const tabMetricMapping: TabMetricMap = ((): TabMetricMap => {
    function metricArrayToObj(metrics: MetricsUIDefinitions[]): TabMappingObj {
        const metricObj: TabMappingObj = {}
        for (let i = 0, len = metrics.length; i < len; i++) {
            const metric: MetricsUIDefinitions = metrics[i];
            metricObj[metric.key] = metric;
        }

        return metricObj;
    }

    const mMapping: TabMetricMap = {
        [SysFeatureEnums.trafficAnl]: trafficMetrics,
        analyticsTrafficObj: metricArrayToObj(trafficMetrics),
        [SysFeatureEnums.efrAnl]: efrMetrics,
        analyticsEFRObj: metricArrayToObj(efrMetrics),
        [SysFeatureEnums.nrumAnl]: nrumMetrics,
        analyticsNRUMObj: metricArrayToObj(nrumMetrics),
        [SysFeatureEnums.pushAnl]: pushMetrics,
        analyticsPushObj: metricArrayToObj(pushMetrics),
        [SysFeatureEnums.cacheAnl]: cacheMetrics,
        analyticsCacheObj: metricArrayToObj(cacheMetrics),
        [SysFeatureEnums.wafAnl]: wafMetrics,
        analyticsWAFObj: metricArrayToObj(wafMetrics),
        [SysFeatureEnums.botAnl]: botMetrics,
        analyticsBOTObj: metricArrayToObj(botMetrics),

        [SysFeatureEnums.dnsDirAnl]: dnsDirMetrics,
        analyticsDnsDirObj: metricArrayToObj(dnsDirMetrics),
        [SysFeatureEnums.csmAnl]: csmMetrics,
        analyticsCSMObj: metricArrayToObj(csmMetrics),
        [SysFeatureEnums.csaAnl]: csmMetrics,
        analyticsCSAObj: metricArrayToObj(csaMetrics)

        // cmsObj: TabMappingObj
    };

    return mMapping;
})();

export function getMetricsObjForTab(tabName: string): TabMappingObj {
    return tabMetricMapping[tabName + 'Obj'];
}

export function getMetricsForTab(tabName: string): TabMappingObj {
    return tabMetricMapping[tabName];
}

export function getCurrentMetricSelectionStates(state: State): BooleanMap {
    const feature = state.currentFeature;

    return state.futureAnalyticState[feature + 'Selected'];
}
// 
// return the list of metrics associated with the current tab.
export function getMetricCheckState(state: State): SelectedMetrics[] {
    const stateAttribute: string = state.currentFeature + 'Selected';

    return state.futureAnalyticState[stateAttribute];
}

//
// given the set of features enabled for a project, return the list of supported metrics and
// their metric information.
export function getMetricsForFeatures(state: SystemNavProjectState, features: ZServiceFeatures): 
                ProjectMetricDefinitions  {
    // const metricsList: ProjectMetricDefinitions = { traffic: [], nrum: [], push: [], efr: [], cache: [], waf: [], dnsDir: [], cms: [] };
    const metricsList: ProjectMetricDefinitions = { } as ProjectMetricDefinitions;

    const pocType = state.currentProjectDspInfo;

    const getDisplayableMetrics = (pType: number, featureMetrics: MetricsUIDefinitions[]): MetricsUIDefinitions[] => {
        return featureMetrics.filter(metric => (metric.pocType.indexOf(pType) !== -1) );
    }

    metricsList.analyticsTraffic = getDisplayableMetrics(pocType, trafficMetrics);
    metricsList.analyticsCache = getDisplayableMetrics(pocType, cacheMetrics);
    
    if (features.waf && features.waf.enabled) {
        metricsList.analyticsWAF = getDisplayableMetrics(pocType, wafMetrics);
        metricsList.analyticsBOT = getDisplayableMetrics(pocType, botMetrics);
    }

    if (features.dnsDir && features.dnsDir.enabled) {
        metricsList.analyticsDnsDir = dnsDirMetrics;
    }

    if (features.csm && features.csm.enabled) {
        metricsList.analyticsCSM = csmMetrics;
    }

    if (features.csa && features.csa.enabled) {
        metricsList.analyticsCSA = csaMetrics;
    }
    // metricsList.nrum = [];
    // if (features.network_real_user_metrics && features.network_real_user_metrics.enabled) {
    //     const metrics = nrumMetrics;
    //     metricsList.nrum = getDisplayableMetrics(pocType, metrics);
    // }

    // metricsList.push = [];
    // if (features.push) {
    //     const metrics = pushMetrics;
    //     metricsList.push = [...metrics];
    // }
 
    // metricsList.efr = [];
    // if (features.error_free_response && features.error_free_response.enabled) {
    //     const metrics = efrMetrics;
    //     metricsList.efr = getDisplayableMetrics(pocType, metrics);
    // }

    // metricsList.waf = [];

    return metricsList;
}

export function getProjectDspFeatures(systemNavProjectState: SystemNavProjectState): ProjDTypeFeatures {

    return projectDspTypeFeatureMapping[systemNavProjectState.currentProjectDspInfo];
}

//
// given the name of an analytics tab, this function will tell you if it is the
// tab currently being viewed. 
export function bViewingAnalyticFeature(systemNavProjectState: SystemNavProjectState, feature: SysFeatureEnums): boolean {
    let isCorrectTab = false;
    if (systemNavProjectState.navItem.toLowerCase() === NAVSTRINGS.analytics.toLowerCase()) {
        if (systemNavProjectState.currentFeature === feature) {
            isCorrectTab = true;
        }
    }

    return isCorrectTab;
} 

export function getPeriodStartEnd(showingRelativeTime: boolean, to: moment.Moment, from: moment.Moment, timePeriodIdx: number): QueryStartEndTime {
    let endTime;
    let startTime;

    if (showingRelativeTime) {
        endTime = Date.now();
        startTime = endTime;

        const intervals = hourIntervalData;
        const millis = intervals[timePeriodIdx].millis
        startTime = (endTime - millis);
    } else {
        endTime = to.unix() * 1000;
        startTime = from.unix() * 1000;
    }

    return {startTime, endTime};
}


// get the current starting and end time (in msecs) for the currently selected period option
export function getPeriodStartEndAnl(state: State): QueryStartEndTime {
    const currentOptions = state.appliedAnalyticState.selectedAnalyticOptions;
    let startEnd: QueryStartEndTime = {} as QueryStartEndTime;

    const {showingRelativeTime, to, from} = state.appliedAnalyticState;

    if (showingRelativeTime) {
        const timePeriodIdx = (currentOptions && (currentOptions.period !== undefined)) ? 
                                currentOptions.period : 0;
        startEnd = getPeriodStartEnd(showingRelativeTime, to, from, timePeriodIdx )
    } else {
        startEnd = getPeriodStartEnd(showingRelativeTime, to, from, 0 )
    }

    return startEnd;
}

export interface MetricOptionOverrides  {
    metrics?: string;
    requestPeriod?: {
        endTime: number | Date;
        startTime: number | Date;
    };
    direct_vs_zycada?: boolean;
    observer?: string;
    origin?: string;
    status?: string;
    hcIndex?: number;
}

export interface MetricQueryReturnValue {
    blankMetrics: string[];
    queryParams: RestAPI;
}

export const getQueryServiceIds = (systemState: SystemNavProjectState): string => {
    // const serviceIds = systemState._currentProject.servicesList.map(service => service.serviceId);
    // return serviceIds.join();
    return systemState._currentProject.currentServiceIds
}

export const areAnySvcsConfiguredForFeature = (systemState: SystemNavProjectState, feature: OptAnalyticFeatures): boolean => {
    const currentProj = systemState._currentProject;
    const { serviceIdMap } = currentProj;
    const serviceIds: string[] = currentProj.currentServiceIds.split(",");
    let hasFeature = false;

    serviceIds.forEach((svcId: string) => {
        const service = serviceIdMap[svcId] as ZServiceExt;
        if (feature === OptAnalyticFeatures.dnsDir) {
            const dnsDir = service.dns_director;
            hasFeature = hasFeature || (dnsDir && dnsDir.enabled && dnsDir.cdns !== undefined);
        } else {
            const csm = service.cloud_service_monitor as ZCloudServiceMonitor;
            hasFeature = hasFeature || (csm && csm.enabled)
        }
    });

    return hasFeature;
}

//
export function getSelectedMetrics(state: State, feature: string): string[] {
    // const tab = state.selectedSubNavTab;
    
    let tabMetricsState: BooleanMap = state.appliedAnalyticState[feature + 'Selected'];        // get the selected metrics
    tabMetricsState =  tabMetricsState !== undefined ? tabMetricsState  : {};
    
    const metricKeys = Object.keys(tabMetricsState);

    const selectedMetrics = metricKeys.filter(metricKey => {
        return tabMetricsState[metricKey] 
    });
    // logger.log('[getSelectedMetrics] - selected metrics: ' + selectedMetrics);

    return selectedMetrics;
}

export function getSelectedOptions(state: State, optionName: string): string {
    let options = '';

    const index = state.appliedAnalyticState.selectedAnalyticOptions[optionName]
    if (Number.isInteger(index)) {
        if (state.analyticOptions[index] === MISC_STRINGS.allOption) {
            if (state.analyticOptions[optionName]) {
                options = state.analyticOptions[optionName].slice(1).join();   // join everything after the 'all'
            }
        } else {
            options = (state.analyticOptions[optionName])[index];
        }
    }

    return options;
}
  
function getSelectedGeoStates(state: State): string {
    return getSelectedOptions(state, 'stateName');
}

export const getMetricV1QueryParams = (state: State, systemState: SystemNavProjectState, overrides: MetricOptionOverrides): RestAPI => {
    const requestPeriod: QueryStartEndTime = getPeriodStartEndAnl(state);
    const serviceIds = getQueryServiceIds(systemState);
    const project = systemState._currentProject;
    const env = project.currentServiceEnvironment

    const cdnIndex = (state.futureAnalyticState.selectedAnalyticOptions.cdn)
    const geoIndex = (state.futureAnalyticState.selectedAnalyticOptions.geos)

    const queryParams: RestAPI = {
        from: (requestPeriod.startTime / 1000).toFixed(),
        to: (requestPeriod.endTime / 1000).toFixed(),
        service_ids: serviceIds,
        env
    }

    if (cdnIndex !== 0) {
        const cdns = state.analyticOptions.cdns as string[];
        queryParams.cdns = cdns[cdnIndex]
    }

    if (geoIndex !== 0) {
        queryParams.geos = CDN_GEOs[geoIndex-1].value;  // first entry is all.
    }

    const hcIndex = overrides.hcIndex;
    if (hcIndex !== undefined) {
        if (hcIndex !== 0) {
            const hcs = state.analyticOptions.healthChk as string[];
            queryParams.checks = hcs[hcIndex];
        }

        if (overrides.status !== undefined) {
            queryParams.include_only_failures = true;
        }
    }
    
    return queryParams; 
}

// get the query parameters for the get-metrics REST API from the options dropdown.
export function getMetricsQueryParams(state: State, systemState: SystemNavProjectState,
                                      overrides: MetricOptionOverrides = {}): MetricQueryReturnValue {
    const currentOptions = state.appliedAnalyticState.selectedAnalyticOptions;

    const feature = systemState.currentFeature;
    const requestPeriod = (overrides.requestPeriod) ? overrides.requestPeriod : getPeriodStartEndAnl(state);

    const metricNames: string[] = overrides.metrics ? overrides.metrics.split(',') : getSelectedMetrics(state, feature);
    const tabMetricObj = tabMetricMapping[feature + 'Obj'];
    const selectedMetrics = [];
    const showBlankMetrics = [];
    
    const serviceIds = getQueryServiceIds(systemState)
    
    const lcCacheNavName = SUBNAVSTRINGS.cache.toLowerCase();
    // The cache tab may not have any cacheable services.  We need to handle that and show
    // blank graphs.
    const forceNoData = (feature === lcCacheNavName && serviceIds.length === 0); 

    let groupMetrics = '';              // we can have one dataset that aggragates multiple metrics
    let percentMetrics = '';            // we can have one dataset that is a percentage of a set of metrics against
                                        //     a whole (e.i. cache hits/all connections)
    let hasStats = false;               // do any of our metrics allow for statistics (currently only nrum)
    const userType = systemState.currentProjectDspInfo;

    // check each metric.  
    //    It might be not viewable for this user type. (posDisabled)
    //    We may have no serviceIds for the current metric set (forceNoData)
    //    The metric may be special (percent or group metric)
    //    We may have to include stats in the query.
    let dummy = false;
    for (let i = 0, len = metricNames.length; i < len; i++) {
        const metric = tabMetricObj[metricNames[i]];
        if (metric.pocDisabled.includes(userType)) {
            showBlankMetrics.push(metric.key);
        } else {
            if (forceNoData) {
                showBlankMetrics.push(metric.key);
            } else {
                selectedMetrics.push(metric.key);
                hasStats = metric.isStatisticsSupported ? true : hasStats;
                if (metric.key.indexOf(',') !== -1) {
                    if (metric.specialProc !== undefined) {
                        if (metric.specialProc === 'g') {
                            groupMetrics = metric.key;
                        } else if (metric.specialProc === 'p') {
                            percentMetrics = metric.key;
                        } else if (metric.specialProj === 'ng') {
                            dummy = !dummy; // special handling for new group metrics
                        }
                    } else {
                        logger.alert('getMetricsQueryParams: Bad MetricUIDefinitons.  Metrics are: ' + metricNames[i]);
                    }
                }
            }
        }
    }

    const queryParams: RestAPI = {
            direct_vs_zycada: false,    // (state.selectedSubNavTab === SysFeatureEnums.apiAnl),
            end_epochmillis: requestPeriod.endTime as number,
            metrics: selectedMetrics.join(),
            service_ids: serviceIds,
            start_epochmillis: requestPeriod.startTime as number,
        };

    if (hasStats) {
        const index = currentOptions.statistics;
        if (index !== undefined && state.analyticOptions.statistics) {
            queryParams.stats = state.analyticOptions.statistics[index];
        }
    }

    if (currentOptions.stateName) {
        queryParams.geos = getSelectedGeoStates(state);
    }

    if (groupMetrics.length > 0) {
        queryParams.group_metrics = groupMetrics;
    }
    if (percentMetrics.length > 0) {
        queryParams.pct_metrics = percentMetrics;
    }

    //// *******getMetricsQueryParams - old Observer and origin code is at bottom of file

    if (overrides.direct_vs_zycada) {
        queryParams.direct_vs_zycada = overrides.direct_vs_zycada;
    }

    return {
        blankMetrics: showBlankMetrics,
        queryParams 
    }
}

export const getCurrentServiceId = (systemNavProjectState: SystemNavProjectState): string => {
    let serviceId = ZALL_SERVICES;
    const idx = systemNavProjectState.serviceEvtIdx;
    const serviceName = systemNavProjectState.serviceEvents[idx];

    if (serviceName && serviceName.length > 0) { 
        switch (serviceName) {
            case MISC_STRINGS.noPublishedServices:
                break;

            default: {
                try {
                    const currentProject = systemNavProjectState._currentProject;
                    const id = currentProject.currentEnv.serviceNameToId[serviceName];
                    if (id && id.length > 0) {
                        serviceId = id;
                    }
                }
                catch(err) {
                    if (!(serviceId === ZALL_SERVICES && idx === 0 && serviceName === MISC_STRINGS.allOption)) {
                        console.log('<<<<<<<< getCurrentServiceId:  exception thrown.')
                    }
                    serviceId = '';
                }
            }
            break;
        }
    }
    
    // if (serviceId === ZALL_SERVICES) {
    //     console.log(`     uiAccessories.getCurrentServiceId: service found is ${serviceId}`)
    // } else {
    //     console.log(`     uiAccessories.getCurrentServiceId: service found is ${serviceId}`)
    // }

    return serviceId;
}

export const findServiceEventIdxForService = (systemNavProjectState: SystemNavProjectState, 
                                              serviceId: string): number => {
    let idx = 0;
    const service = systemNavProjectState._currentProject.serviceIdMap[serviceId];
    if (service) {
        const serviceName = service.serviceName;
        idx = systemNavProjectState.serviceEvents.indexOf(serviceName);
        idx = idx !== -1 ? idx : 0;
    }

    return idx;
}

export function getServiceEventList(systemNavProjectState: SystemNavProjectState): string[] {
    return systemNavProjectState.serviceEvents;
}

// export function getSelectedServiceEvtName(state: State) {
//     return state.serviceEvents[state.serviceEvtIdx];
// }

export function getCurrentUriCodes(systemNavProjectState: SystemNavProjectState): ProjUriCode[] {
    const uriCodes: ProjUriCode[] = [{name: MISC_STRINGS.allOption, uri_regex: '/*'}]
    // this should always fail (i.e. we'll always have a systemNavProjectState)
    if (! systemNavProjectState) {
        logger.log('bad system state');
    }
    return uriCodes;
}

// // tslint:disable-next-line: variable-name
// export function getUriCodeIdxFromPattern(state: State, uri_pattern: string): number {
//     const uriCodes = state.futureAnalyticState.uriCodes;
//     let index: number = uriCodes.findIndex((uriCode) => { return (uriCode.uri_pattern === uri_pattern)})
//     return index;
// }

export function getAppliedAnalyticsTimeoutValue(state: State): number {
    const idx = state.appliedAnalyticState.selectedAnalyticOptions.refresh;
    const timeoutInterval = minuteIntervalData[idx].millis

    return timeoutInterval;
}

export function isRefreshEnabled(state: State): boolean {
    // looking at indexes.  0 means off; anything else means we are doing refreshes
    return (state.appliedAnalyticState.selectedAnalyticOptions.refresh > 0);

}

// function getSelectedOrigins(state: State) {
//     return getSelectedOptions(state, 'origin');
// };

// *******getMetricsQueryParams - Observer and origin code is here
//          This is old code for getting observer and origin in the query.
//          We don't want to delete it yet but I'm moving it here to get it out of the way.
   // if (currentOptions.observer || overrides.observer) {
    //     if (overrides.observer) {
    //         queryParams.observer = overrides.observer;
    //     } else {
    //         queryParams.observer = 
    //             (currentOptions.observer === ANALYTICS_STRINGS.originOptionLabel) ? 
    //                 'origin' : 'client';
    //     }

    //     if (currentOptions.observer === ANALYTICS_STRINGS.originOptionLabel ||
    //         overrides.observer === 'origin') {
    //         const origins = getSelectedOrigins(state);
    //         if (origins && origins.length > 0) {
    //             queryParams.origins = origins.replace(/\./g, '_');
    //         } else {
    //             queryParams.observer = 'client';
    //             queryParams.origins = '';
    //         }
    //     }
    // }