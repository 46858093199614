import * as React from 'react';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { State } from '../../App';

import * as actionCreators from '../../actions/actionCreators';
import { actionTypes, OptionalApplyArgs } from '../../actions/actionCreatorTypes';

import { Certificate, ZRestResponse, FetchError, RoutingRules, CacheAgeItem, ZProductFeatureAttributes, ZCSMHealthCheck,
         ZPortsDef, ZDnsDirectorResponse, ZSecurePort, ServiceStatusEnum, ZServiceExt, ZDnsDirectorDomain, ZCSMEntity,
         HealthCheckIntervals, ZUserMin, ZUserTypes }from '../../data/queryResultDefinitions';
         
import { DisplayRoutingRules, ServiceFieldValidate, ZSessionStorage, SvcEditPanelValidationEnum, NumberMap } 
            from '../../data/metricsAndOptionsDefs';

import { ZService } from '../../reducers/systemState';
import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { EProjectState, SysFeatureEnums, ZEnvironments, ZFeaturePopupTypes } from '../../reducers/reducerEnums';
import { isServiceSaveable } from '../../reducers/editServiceValidations';
import { HealthCheckIndex } from '../../reducers/reducerEnums';

import {EditSvcFixedOptionIndex, EditSvcFixedOptions } from '../../shared/MiscEnums';
import { NotificationStyles, MAX_SERVICE_NAME_LENGTH, MAX_DOMAIN_NAME_LENGTH, MAX_ORIGIN_NAME_LENGTH, 
         MAX_SNI_HOSTNAME_LENGTH, DATETIME_FORMAT, MAX_CDN_TAG_LENGTH, MAX_HEALTH_CHECK_PATH_LENGTH,
         CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT } from '../../shared/constants';
import { SERVICES_STRINGS as STRs, MISC_STRINGS, ADMIN_STRINGS} from '../../shared/strings'; 
import { ZGet, ZPut } from '../../shared/backend';
import { getSessionStorage , integerValidation, headerValueValidation, CertificateObject, splitAndCleanDomainsString} from '../../shared/utilities';

import CertEditor from '../../shared/CertEditor';
import { certServerAction } from '../../shared/serverUtilitites';

import EditServiceJSON from './EditServiceJSON';
import ZURLS from '../../shared/urls';
import ZButton from '../../shared/ZButton';
import logger from '../../shared/logUtilities';
import ZDropdown from '../../shared/ZDropDown';
import ZDropDownJSX,  { DropdownOptions } from '../../shared/ZDropdownJSX';

export interface EditDraftServiceProps extends State {
    applySettings: (error?: string, extraData?: string, optionalArgs?: OptionalApplyArgs) => actionTypes;
    setNavItem: (analyticsTab: string, feature: SysFeatureEnums) => actionTypes,

    setProjectCertList: (certList: Certificate[], projectId: string) => actionTypes,
    setCertListIndex: (index: number) => actionTypes,

    setServiceOverviewDetails:  (serviceName: string, domainName: string, certListIdx: number,
                                 enableHTTP2: boolean, enableHTTPSRedirect: boolean, domainRedirects: string) => actionTypes,
    setServiceOriginDetails: ( originName: string, domainName: string, tlsEnabled: boolean, sni: string) => actionTypes,
    setServiceDnsDirectorDetails: (ddDomainName: string, zyTrafficPercentage: string, cdnTag: string,
                                   autoSwitchEnabled: boolean, useCdnAsOriginEnabled: boolean) => actionTypes,
    setServiceHCData: (healthChecks: ZCSMEntity[]) => actionTypes,
    setProjectFeaturesAttributes: (svcFeatureAttributes: ZProductFeatureAttributes) => actionTypes ,
    setSelectedServiceDetail: (serviceConfig: ZService, isEditable: boolean) => actionTypes,

    addServiceOrigin: ( originName: string, domainName: string, tlsEnabled: boolean, sni: string ) => actionTypes,
    setServiceOptionIndex: (index: number) => actionTypes,
    discardServiceChanges: () => actionTypes,
    draftSaved: (svc: ZServiceExt) => actionTypes,
    reloadServices: () => actionTypes,
    featureReady: () => actionTypes,
    showErrors: (feature: string, showError: boolean) => actionTypes,
    dataLoadInProgress: (feature: string) => actionTypes,

    setRoutingRule: (rule: RoutingRules) => actionTypes,
    setCurrentServiceEvtIdx: (index: number) => actionTypes,
    initEditService: (sessionStorage: ZSessionStorage) => actionTypes,
    toggleServicePopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean) => actionTypes;

    changeServiceEnvironment: (env: ZEnvironments) => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes,
    closeNotification: () => actionTypes,
    redirectTo: (url: string) => actionTypes,
}

interface PanelStatus {
    icon: string,
    css: string,
}

interface PanelStatusMap {
    [status: string]: PanelStatus
}
class EditDraftSvc extends React.Component<EditDraftServiceProps> {
    componentDidMount() {
        const sessionState: ZSessionStorage = getSessionStorage();
        logger.log(`mounting EditDraftSvc: ==> ${JSON.stringify(sessionState)}`);
        // this.props.initEditService(sessionState);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: EditDraftServiceProps) {
        // logger.log(' ++++ EditDraftSvc.componentDidUpdate: - enter edit service');
        
        try {
            const serviceState = this.props.serviceUIState.serviceState;
            const systemState = this.props.systemNavProjectState;
            const systemUIState = systemState.systemUIState;
            // const project = systemState._currentProject;

            // logger.log(` ++++ EditDraftSvc.componentDidUpdate: serviceState: ${serviceState}; systemUIState: ${systemUIState}`);
            // logger.log(` ++++ EditDraftSvc.componentDidUpdate: env: ${project.currentServiceEnvironment}; service Idx: ${systemState.serviceEvtIdx}; envData:`, project.currentEnv)

            if (systemUIState === EProjectState.serverError) {
                return;
            }

            if ( ! (systemState.projectsLoaded && systemState.servicesLoaded)) {
                return;
            }

            if (systemUIState === EProjectState.servicesUpdated) {
                this.props.featureReady();
                this.initStarted();
                return;
            }

            switch (serviceState) {
                case EProjectState.initStarted: 
                    this.initStarted();
                    break;

                case EProjectState.serviceChanged: 
                    this.props.applySettings();
                    break;

                case EProjectState.userClickedApply:
                    if (systemState.servicesLoaded) {
                        const project = systemState._currentProject;
                        const currentEnv = project.currentEnv;
                        const serviceName = systemState.serviceEvents[systemState.serviceEvtIdx];
                        const serviceId = currentEnv.serviceNameToId[serviceName];
                        const service = project.serviceIdMap[serviceId]
                        this.loadData(service);
                    }
                    break;

                default:
                    break;
            }
        } catch (e) {
            logger.log(' ++++ EditDraftSvc.componentDidUpdate: catch error:' + e);
        }
        // logger.log(' ++++ EditDraftSvc.componentDidUpdate: - exit\n');

    }

    render() {
        const svcIdx = this.props.systemNavProjectState.serviceEvtIdx;
        const currentProject = this.props.systemNavProjectState._currentProject;
        const numStagingSvcs = currentProject.stagingEnv.allServiceNames.length;
        const serviceUIState = this.props.serviceUIState;

        const popupBuilders = {
            [ZFeaturePopupTypes.UploadCert]: this.buildUploadCertPopup,
            [ZFeaturePopupTypes.EditCert]: this.buildEditCertPopup,
            [ZFeaturePopupTypes.EditServiceJSON]: this.buildEditServiceJsonPopup,
        }
        let markup = <span></span>;
        let serviceName;
        if (svcIdx !== -1) {
            serviceName = this.props.systemNavProjectState.serviceEvents[svcIdx];
        }

        if (numStagingSvcs === 0) {
            markup = (
                    <div>
                        <div className="title">{STRs.plainEditServices}</div>
                        <div>{STRs.noServices}</div>
                    </div>
            );
        } else  if (!serviceName ) {
            markup = (
                    <div>
                        <div className="title">{STRs.plainEditServices}</div>
                        <div>{STRs.badEnvironment}</div>
                    </div>
            );
        } else if (serviceUIState.editableService.status === ServiceStatusEnum.deleting) { 
            markup = (
                <div>
                    <div className="title">{STRs.plainEditServices}</div>
                    <div><strong>{STRs.svcDeletionInProgress}</strong></div>
                </div>
            )
        } else if (serviceUIState.serviceState !== EProjectState.serverError) {
            const { isDirty, showDeploymentErrors } = serviceUIState;
            const fieldValidation = this.props.serviceUIState.editSvcFieldValidate;
            const optionValidation: SvcEditPanelValidationEnum[] = this.panelValidation(fieldValidation)
            const panel = this.buildPanel(fieldValidation);
            const options = this.buildOptionsNav(optionValidation);

            const builder = popupBuilders[serviceUIState.popupType];
            const popup = (builder !== undefined) ? (builder)() : <div></div>;

            const buttonDisabled = !isServiceSaveable(serviceUIState);
            const deploymentErrors = this.getDeploymentErrors(fieldValidation, showDeploymentErrors);

            const user = this.props.authNServerData.user as ZUserMin;
            const editSvc = (user.role === ZUserTypes.zadmin) ? (
                 <>
                    <ZButton btnCls="float-left" onClick={() => {this.handleEditSvcJSON()}}>Edit Service JSON</ZButton>&nbsp;&nbsp;
                </>) : <div></div>
            markup = (
                <>
                    <div className="title">{STRs.editServiceTitle(serviceName)}{deploymentErrors}</div>
                    <div></div>
                    <div id="edit-services">
                        <div className="editsvc-main-panel">
                            <div>
                                {options}
                            </div>
                            <div>
                                {panel}
                            </div>
                            <div>
                                {editSvc}
                                <ZButton disabled={buttonDisabled} 
                                        onClick={() => {this.saveDraft()}}>
                                    {STRs.saveDraftBtn}</ZButton>&nbsp;&nbsp;
                                <ZButton onClick={() => {this.props.discardServiceChanges()}} disabled={!isDirty}>
                                    {STRs.discardDraftBtn}</ZButton>&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                    {popup}
                </>
            )
        } else {
            markup = <div>{MISC_STRINGS.serverError2}</div>;
        }

        return (
            <div className="feature-panel">
                <div className="servicesFeature">
                {markup}
                </div>
            </div>
        )
    }

    private initStarted = () => {
        const { systemNavProjectState } = this.props;
        const currentProject = systemNavProjectState._currentProject;
        if (currentProject.currentServiceEnvironment === ZEnvironments.prod) {
            this.props.changeServiceEnvironment(ZEnvironments.staging);
        } else {
            this.props.applySettings();
        }
    }

    private loadData = async (service?: ZService | undefined) => {
        try {
            const { systemNavProjectState: systemState, serviceUIState: serviceState } = this.props;
            const currentProject = systemState._currentProject;
            const projectId = currentProject.projectId;
            let svcId = '';
            
            if (systemState._currentProject.servicesList.length === 0) {
                return;
            }

            logger.log(` ++++ EditDraftSvc.loadData: service ${service}; serviceIdx: ${systemState.serviceEvtIdx}`)
            if (!service) {
                const session = getSessionStorage();
                svcId = session.serviceId;
            } else {
                svcId = service.serviceId;
            }

            this.props.dataLoadInProgress(SysFeatureEnums.editDraftService as string)
            let features: ZRestResponse | undefined = undefined;
            features = await this.loadSvcProductFeatures(projectId);
            this.props.setProjectFeaturesAttributes((features as ZProductFeatureAttributes));
            
            let certList: ZRestResponse | undefined = undefined;
            if (! serviceState.certsLoaded) {
                certList = await this.loadProjectCerts(projectId);
                this.props.setProjectCertList(certList as Certificate[], projectId);
            }

            const serviceList = currentProject.stagingEnv.allServices;

            const editableSvc = serviceList.find(( svc: ZService ) => {
                return svc.service_id === svcId;
            })

            if (editableSvc) {
                this.props.setSelectedServiceDetail(editableSvc, true);
            } else {
                alert(`Could not find service Id ${svcId}  in list of service in loadData `)
            }
        }

        catch(err) {
            if (((err as FetchError).message) !== undefined) {
                logger.log(`EditDraftSvc.loadData: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, (err as FetchError).message);
                this.closeNotification('serverError');
            } else {
                const p = err as Promise<FetchError>;

                p.then((err) => {
                    logger.log(`EditDraftSvc.loadData: fetch failed: ${err}`);

                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification('serverError');
                })
                .catch(() => {
                    this.props.applySettings('error');
                })
            }
        }

    }

    private buildOptionsNav = (panelValidation: SvcEditPanelValidationEnum[]): JSX.Element => {
        const { svcNavList, svcNavListIdx, isLegacyService, projHasCSM,isDataLoading } = this.props.serviceUIState;
        const markup: JSX.Element[] = [];

        const PanelStatusValues: PanelStatusMap = {
            [SvcEditPanelValidationEnum.good]: { icon: 'check', css: '' },
            [SvcEditPanelValidationEnum.error]: { icon: 'times-circle', css: ' bad-service-option' },
            [SvcEditPanelValidationEnum.optional]: { icon: 'minus', css: ' optional-service-panel' },
            [SvcEditPanelValidationEnum.required]: { icon: 'minus', css: ' optional-service-panel' },
        }

        // console.log(`+++++++ >>>>> Panel Validation ${JSON.stringify(panelValidation)}`)
        for (let index=0, len= svcNavList.length; index < len; index++) {
            let skipEntry = false;
            skipEntry = (isLegacyService && (index === EditSvcFixedOptionIndex.dnsDirectorIdx));
            if (!skipEntry) {
                skipEntry =  isLegacyService && (index === EditSvcFixedOptionIndex.cloudServiceMonitorIdx);
            }
            if (!skipEntry && (index === EditSvcFixedOptionIndex.cloudServiceMonitorIdx)) {
                skipEntry = !projHasCSM
            }

            if (! skipEntry) {
                let valData = PanelStatusValues[panelValidation[index]];
                if (valData === undefined || isDataLoading) {
                    valData = PanelStatusValues[SvcEditPanelValidationEnum.good]
                    // logger.alert('unknown SvcEditPanelValidationEnum')
                }
                const css = (svcNavListIdx === index) ? `${valData.css} svc-edit-menu-item-selected` : valData.css;
                const itemCSS = (index >= EditSvcFixedOptions) ? 'pad-left' : '';

                markup.push((
                    <div key={'optionListIdx' + index} className={css} onClick={() => { this.props.setServiceOptionIndex(index)}}>
                        <div><FontAwesomeIcon icon={valData.icon as IconProp} /></div>
                        <div className={itemCSS}>
                            {svcNavList[index]}
                        </div>
                    </div>
                ));
            }

            if (index+1 === EditSvcFixedOptions) {
                markup.push((
                    <div className="no-cursor" key={'optionListIdxl' + index}>
                        <div></div>
                        <div>
                            {STRs.contentDelivery}
                        </div>
                    </div>
                ));
            }
        }

        return (
            <div className="svc-edit-menu">
                {markup}
            </div>
        );
    }

    private buildPanel = (validation: ServiceFieldValidate): JSX.Element => {
        const { editableService, svcNavListIdx } = this.props.serviceUIState;

        if ( editableService.service_id === undefined ) {
            return <div></div>;
        }
        let markup: JSX.Element = <div></div>;
        if (svcNavListIdx === EditSvcFixedOptionIndex.overviewIdx) {
            markup = this.buildOverviewPanel(validation);
        } else if (svcNavListIdx === EditSvcFixedOptionIndex.originIdx) {
            markup = this.buildOriginPanel(validation);
        } else if (svcNavListIdx === EditSvcFixedOptionIndex.dnsDirectorIdx) {
            markup = this.buildDnsDirectorPanel(validation);
        } else if (svcNavListIdx === EditSvcFixedOptionIndex.cloudServiceMonitorIdx) {
            markup = this.buildCSMPanel(validation);
        } else {
            if (editableService.rules || (svcNavListIdx === EditSvcFixedOptions && editableService.features)) {
                const rrule = this.props.serviceUIState.routingRule;
                const uiAssetRules: DisplayRoutingRules = this.props.serviceUIState.routingRuleDisplay;
                markup = this.buildRulePanel( rrule, uiAssetRules );
            } else {
                logger.log('editDraftSvc.buildPanel: unknown routing rule found.');
            }
        }

        return markup;
    }

    private getDeploymentErrors = (fieldsValid: ServiceFieldValidate, showDeploymentErrors: boolean): JSX.Element => {
        const serviceState = this.props.serviceUIState;

        if (fieldsValid.originName === undefined || serviceState.isDataLoading) {         // we haven't finished initializing
            return <span></span>;
        }

        const { isCDNReserve, isLegacyService, originName, originDomainName, ddDomainName, cdnTag, csmHealthChecks } = serviceState;
        const msgs: string[] = [];
        let originValid = true;
        let ddDataValid = true;
        let cmsValid = true;

        if (! isLegacyService) {
            originValid = (fieldsValid.originName && fieldsValid.originDomainName);
            originValid = originValid && originName.length > 0 && originDomainName.length > 0;
            ddDataValid = (fieldsValid.ddDomainName && fieldsValid.cdnTag);
            ddDataValid = ddDataValid && ddDomainName.length > 0 && cdnTag.length > 0;
            if (fieldsValid.healthChecks !== undefined) {
                cmsValid = (fieldsValid.healthChecks[HealthCheckIndex.cdnCheck] && 
                            fieldsValid.healthChecks[HealthCheckIndex.originCheck]);
            }
            if (isCDNReserve) {
                cmsValid = cmsValid && csmHealthChecks[HealthCheckIndex.cdnCheck].request_path.length > 0 &&
                           csmHealthChecks[HealthCheckIndex.originCheck].request_path.length > 0;
            }


            if (isCDNReserve) {
                if (!originValid) {
                    msgs.push(STRs.supplyOriginInfo);
                }
                if (!ddDataValid) {
                    msgs.push(STRs.supplyDnsDirInfo);
                }
                if (!cmsValid) {
                    msgs.push(STRs.supplyTwoValidHealthChks);
                } 
            } else {
                if (!(originValid || ddDataValid)) {
                    msgs.push(STRs.supplyOriginOrDnsDirInfo)
                }
                if (!cmsValid) {
                    msgs.push(STRs.supplyValidHealthChecks);
                }
            } // 
        } else {                // legacy service case
            const { originName, originDomainName } = serviceState;
            originValid = (originName.length > 0 && originDomainName.length > 0 && fieldsValid.originName && fieldsValid.originDomainName);
            if (!originValid) {
                msgs.push(STRs.supplyOriginInfo);
            }
        }

        const msgMarkup: JSX.Element[] = msgs.map((msg: string, index: number): JSX.Element => {
            return (<li key={'errorMsg' + index}>{msg}</li>)
        })

        const viewErrorIcon = showDeploymentErrors ? ['fas', 'caret-up'] : ['fas', 'caret-down'];

        let errorMarkup: JSX.Element = <span></span>;
        if (msgs.length > 0) {
            const errMarkup = (showDeploymentErrors) ? (
                <div className="cant-deploy-service">
                    <div className="cant-deploy-service-msg">{STRs.fixErrors}</div>
                    <ul>
                        {msgMarkup}
                    </ul>
                </div>
            ) : <div></div>;

            errorMarkup = (
                <>
                <span className="cant-deploy-service-title"  onClick={() => { this.handleShowErrors(showDeploymentErrors)}}>  {STRs.serviceNotDeployable} <FontAwesomeIcon icon={viewErrorIcon as IconProp} /></span>
                { errMarkup }
                </>
            )
        }

        return errorMarkup;
    }
    
    private handleShowErrors = (showErrors: boolean): void => {
        this.props.showErrors('edit-service', !showErrors);
    }

    private buildUploadCertPopup = (): JSX.Element => {
        const add =  <CertEditor updatingCert={false}  
                            handleApply={(certPrivate: string, certChain: string): void => { 
                                this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false);
                                this.applyUploadCert(certPrivate, certChain); 
                                this.props.applySettings(undefined, undefined, { reloadCerts: true });
                            }}
                            handleCancel={(): void => { 
                                this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false); }} />

        return add;
    }

    private buildEditCertPopup = (): JSX.Element => {
        logger.log('edit cert popup');
        const { certList, certListIdx } = this.props.adminUIState;
        const cert = certList[certListIdx];

        const update = <CertEditor updatingCert={true} cert={cert} 
                            handleApply={(certPrivate: string, certChain: string): void => {
                                this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false);
                                this.applyUpdateCert(certPrivate, certChain);
                                this.props.applySettings(undefined, undefined, { reloadCerts: true });

                            }}
                            handleCancel={(): void => { 
                                this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false);
                             }} />

        return update;
    }

    private buildEditServiceJsonPopup = (): JSX.Element => {
        const service = this.prepareService();

        return (
            <EditServiceJSON currentLocalSvc={service}
                done= {(json: string): void => {this.editJSONDone(json)}}
                cancel= {(): void => {this.editJSONCancel()}}
                notification= {(msg: string): void => {this.editJSONnotification(msg)}}
            />)
    }

    
    private editJSONDone = async (json: string): Promise<void> => {
        console.log('editJSON done');
        await this.saveDraftInternal(JSON.parse(json))
        this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false)

    }

    private editJSONCancel = () => {
        console.log('editJSON cancel');
        this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false)
    }

    private editJSONnotification = (message: string) => {
        console.log(`editJSON notification done ${message}`);
    }

    private applyUpdateCert = async (certPrivate: string, certChain: string): Promise<void> => {
        const { certList, certListIdx } = this.props.adminUIState;
        const cert = certList[certListIdx];

        let url = ZURLS.serverCertUpdate(cert.cert_id);
        url = buildUrl(this.props.authNServerData, url);
        try {
            await certServerAction(url, certChain, certPrivate );
            this.props.applySettings();
            this.showNotification(ADMIN_STRINGS.certUpdateSuccess, NotificationStyles.success)
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                const errMsg = err.statusCode && err.statusCode === 409 ? ADMIN_STRINGS.certAlreadyLoaded : err.message;
                this.showNotification(errMsg, NotificationStyles.danger)

            })
            p.catch((err1) => {
                console.log('doubleErr: ' + err1)
            })
        }
    }

    private applyUploadCert = async (certPrivate: string, certChain: string): Promise<void> => {
        const { editableService } = this.props.serviceUIState;
        let url = ZURLS.serverCertManagement;
        url = buildUrl(this.props.authNServerData, url);

        try {
            await certServerAction(url, certChain, certPrivate, editableService.project_id);
            this.props.applySettings();
            this.showNotification(ADMIN_STRINGS.certUploadSuccess, NotificationStyles.success);
        } 
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                const errMsg = err.statusCode && err.statusCode === 409 ? ADMIN_STRINGS.certAlreadyLoaded : err.message;
                this.showNotification(errMsg, NotificationStyles.danger)
            })
            p.catch((err1) => {
                console.log('doubleErr: ' + err1)
            })
        }
    }

    private showNotification = ( message: string, style: NotificationStyles ): void => {
        this.props.showNotification(style, message);
        setTimeout(() => { this.props.closeNotification(); }, CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT);
    }

    private buildOverviewPanel = (fieldsValid: ServiceFieldValidate): JSX.Element => {
        const serviceState = this.props.serviceUIState;
        const { certNameListIdx, domainName, stagingCName, prodCName, certList, domainRedirects, editSvcFieldValidate } = serviceState;
        const snameCSS = 'service-friendly-name' + (fieldsValid.serviceName ? '' : ' field-error');

        let certDDown = <span></span>;
        let checkbox = <span></span>;
        const domainRedirErrMsg = {
            hasSvcDomainName: STRs.domainRedirErrHasSvcDomainName,
            inValidDomainName: STRs.domainRedirErrInValidDomainName,
            tooManyDomains: STRs.domainRedirErrTooManyDomains,
        }

        const jsxCertNameList: JSX.Element[] = [(<span key="no-cert">{STRs.noCertAssigned}</span>)]
        const values: string[] = ['']
        certList.forEach((cert: Certificate): void => {
            const certObj = new CertificateObject(cert);
            const certName = (<span className={certObj.checkCSS}><strong> {certObj.icon} {certObj.commonName}</strong></span>)
            jsxCertNameList.push(certName);
            values.push(certObj.commonName);
        })
        certDDown = <ZDropDownJSX 
                        optionList={jsxCertNameList} 
                        values={values} 
                        selectedIdx={certNameListIdx} 
                        ctrlId="certs" 
                        handleOptionsSelect={ 
                                (idx:number): void  => { this.handleServiceOverviewDetailUpdate('certNameListIdx', idx) }} />;
        checkbox = (<>
            <input type="checkbox" checked={serviceState.enableHTTP2} disabled={certNameListIdx === 0}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleServiceOverviewDetailUpdate('enableHTTP2', e.currentTarget.checked) }} >
            </input>&nbsp;{STRs.enableHTTP2}
        </>);


        const cObj = new CertificateObject(certList[certNameListIdx-1]);
       
        const certDetails = cObj.isAValidCert  
                                    ? <>{cObj.certFullCertDisplayInfo}  <span className="link-nowrap" 
                                        onClick={()=> {this.props.toggleServicePopup(ZFeaturePopupTypes.EditCert, true)}} >
                                        [{ADMIN_STRINGS.editCert}]
                                     </span></> 
                                    : <span>{STRs.noCertAssigned}</span>
        const statusLine = this.buildOverviewStatus();

        const platform = serviceState.editableService.platform
        const serviceplatformDetails = (platform !== undefined && platform.length > 0) ? 
                                        <><div><label>{STRs.platform}</label></div><div>{platform}</div></> : <></>;
        
        let domainRedirErr = <span>&nbsp;</span>;                                
        if (!editSvcFieldValidate.domainsRedirect) {
            const errs = Object.keys(domainRedirErrMsg);
            for (let i=0, len=errs.length; i < len; i++) {
                if (!editSvcFieldValidate.domainRedirectErrors[errs[i]]) {
                    domainRedirErr = <span className="red-x">{domainRedirErrMsg[errs[i]]}</span>
                    break;
                }
            }
        }

        const overview = (
            <section>
            <div className="section-title">{STRs.overview}</div>
            <div id="service-overview-edit">
                {statusLine}
                <div>
                    <div><label>{STRs.name}</label></div>
                    <div>               
                        <input className={snameCSS} type="text" 
                            value={serviceState.serviceName} placeholder={ STRs.serviceName }
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleServiceOverviewDetailUpdate('serviceName', e.currentTarget.value)
                            }} maxLength={ MAX_SERVICE_NAME_LENGTH } />
                    </div>

                    <div><label>{STRs.certificate}</label></div>
                    <div>{certDDown}&nbsp;&nbsp;&nbsp;<span className="link-nowrap" onClick={()=> {
                                this.props.toggleServicePopup(ZFeaturePopupTypes.UploadCert, true);}}>[{ADMIN_STRINGS.uploadNewCert}]</span></div>
                </div>
                <div>
                    <div><label>{STRs.domainName}</label></div>
                    <div>               
                        {domainName}
                    </div>
  
                    <div><label>{STRs.stagingCname}</label></div>
                    <div>               
                        {stagingCName}
                    </div>
                    <div><label>{STRs.prodCname}</label></div>
                    <div>               
                        {prodCName}
                    </div>

                    {serviceplatformDetails}

                    <div><label>{STRs.certCommonName}</label></div>
                    <div>               
                        {certDetails} 
                    </div>

            
                    <div><label>{STRs.redirectFromDomains}</label></div>
                    <div className="ztext-area pad-top-5">
                        <textarea value={domainRedirects} 
                            className={(!editSvcFieldValidate.domainsRedirect) ? 'text-area-error' : ''}
                            onChange={(e: React.FormEvent<HTMLTextAreaElement>): void => {
                                        e.stopPropagation();
                                        (this.handleServiceOverviewDetailUpdate)('domainRedirects', e.currentTarget.value) }} 
                            rows={3} cols={60} tabIndex={0}
                            placeholder={STRs.redirectRules}>
                        </textarea><br/>
                        {domainRedirErr}
                    </div>
                    <div>
                            {checkbox}
                    </div>
                    <div>{certList.length > 0 && certNameListIdx === 0 ? STRs.requiresCertificate : ''}</div>
                    
                </div>
            </div>
            </section>
        );

        return overview;
    }

    private buildOverviewStatus = (): JSX.Element => {
        const { created_at, updated_at, meta, status, version } = this.props.serviceUIState.editableService;
        let createStr = '';
        if (status === ServiceStatusEnum.draft) {
            createStr = STRs.draftCreatedAt(moment(created_at).format(DATETIME_FORMAT));
        } else {
            createStr = STRs.viewingSvcVersion(version);
        }
        const baseVersion = (meta && meta.base_version) ? STRs.clonedFrom(meta.base_version) : '';
        return (
            <div className="status-info">
                <div>{createStr}</div>
                <div>{baseVersion}</div>
                <div>{STRs.modifiedAt(moment(updated_at).format(DATETIME_FORMAT))}</div>
            </div>
        )
    }

    private buildOriginPanel = (fieldsValid: ServiceFieldValidate): JSX.Element => {
        const serviceState = this.props.serviceUIState;
        const { originName, originDomainName, tlsEnabled, sni } = serviceState;
        const sniEnabled = !tlsEnabled
        const originCSS = 'service-friendly-name' + (fieldsValid.originName ? '' : ' field-error');
        const odNameCSS = 'service-friendly-name' + (fieldsValid.originDomainName ? 
                        '' : ' field-error');
 
        const sniCSS = 'service-friendly-name' + (fieldsValid.sni ? '' : ' field-error');
        const tlsMsg = !tlsEnabled ? STRs.originUsesHTTP : '';
        const certMarkup = (
            <>
            <div>
                <input type="checkbox" checked={tlsEnabled}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    this.handleServiceOriginUpdate('tlsEnabled', e.currentTarget.checked) }} >
                </input>&nbsp;{STRs.tlsEnabled}&nbsp;
            </div>
            <div>{tlsMsg}</div>
            <div><label className={sniEnabled ? 'disabled-label' : '' }>{STRs.sni}</label></div>
            <div>               
                <input className={sniCSS} type="text" disabled={sniEnabled}
                    value={sni} placeholder={ STRs.friendlySniHostname }
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        this.handleServiceOriginUpdate('sni', e.currentTarget.value)
                    }} maxLength={ MAX_SNI_HOSTNAME_LENGTH } />
            </div>
            </>
        );

        const overview = (
            <section>
                <div className="section-title">{STRs.originInfo}</div>
                <div className="service-origin-edit">
                    <div><label>{STRs.originId}</label></div>
                    <div>               
                        <input className={originCSS} type="text" 
                            value={originName} placeholder={ STRs.originIdHelp }
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleServiceOriginUpdate('originName', e.currentTarget.value)
                            }} maxLength={ MAX_ORIGIN_NAME_LENGTH } />
                    </div>

                    <div><label>{STRs.domainName}</label></div>
                    <div>               
                        <input className={odNameCSS} type="text" 
                            value={originDomainName} placeholder={ STRs.originDomainName }
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleServiceOriginUpdate('originDomainName', e.currentTarget.value)
                            }} maxLength={ MAX_DOMAIN_NAME_LENGTH } />
                    </div>
                    {certMarkup}

                </div>
            </section>
            );

        return overview;
    }

    private buildDnsDirectorPanel = (fieldsValid: ServiceFieldValidate): JSX.Element => {
        const { ddDomainName, zyTrafficPercentage, cdnTag, 
                ddAutoSwitchEnabled, canEditCDNTag } = this.props.serviceUIState;

        const ddDomainCSS = 'service-friendly-name' + (fieldsValid.ddDomainName ? '' : ' field-error');
        const ddCdnTagCSS = 'service-friendly-name' + (fieldsValid.cdnTag ? '' : ' field-error');
        const ddTraficWeightCSS = 'service-friendly-name' + (fieldsValid.zyTrafficPercentage ? 
                        '' : ' field-error');

        let trafficPercentage = parseInt(zyTrafficPercentage);
        trafficPercentage = isNaN(trafficPercentage) ? 0 : trafficPercentage;
        const zycPercentage = 100 - trafficPercentage;

        const cdnPercentageMsg = STRs.cdnTraffic(zycPercentage);
        const  cdnReserveMarkup = (ddAutoSwitchEnabled) ? this.buildCDNReserveMarkup() : <div></div>;

        const tagMarkup = (!canEditCDNTag) ? <div>{cdnTag}</div> : (
            <input className={ddCdnTagCSS} type="text" value={cdnTag} 
                    maxLength={ MAX_CDN_TAG_LENGTH } placeholder={ STRs.cdnTagHelp }
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleDnsDirectorUpdate('cdnTag', e.currentTarget.value) }} />
        )

        const enableZyPercentage = (ddDomainName.length > 0) || (cdnTag.length > 0)
        const dnsDirector = (
            <section>
                <div className="section-title">{STRs.dnsDirectorInfo}</div>
                <div className="service-dnsdirector-edit">
                    <div><label>{STRs.cdnTag}</label></div>
                    <div>
                        {tagMarkup}
                    </div>
                    <div><label>{STRs.ddDomain}</label></div>
                    <div>               
                        <input className={ddDomainCSS} type="text" 
                            value={ddDomainName} placeholder={ STRs.ddDomain } maxLength={ MAX_ORIGIN_NAME_LENGTH }
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleDnsDirectorUpdate('ddDomainName', e.currentTarget.value) }}  />
                    </div>

                    <div className="four-col"><label>{STRs.zycadaTrafficPercentage}&nbsp;&nbsp;</label>
                        <input className={ddTraficWeightCSS} type="text"  disabled={!enableZyPercentage} maxLength={ 3 } value={trafficPercentage} 
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleDnsDirectorUpdate('zyTrafficPercentage', e.currentTarget.value)  }}/> %
                    </div>
                    <div className="four-col">{cdnPercentageMsg}</div>
                    {cdnReserveMarkup}
                </div>
            </section>
        );

        return dnsDirector;
    }

    // this is markup related to CDNReserve that is included in the DNS Director panel (if auto switch feature is enabled for the service)
    private buildCDNReserveMarkup = ( ): JSX.Element => {
        const { autoSwitch, csmHealthChecks, useCdnAsOrigin } = this.props.serviceUIState;
        const useCdnDisabled = !autoSwitch;
        
        // if only include healthcheck info if autoswitch is enabled
        let healthCheckData = <div></div>;
        if (autoSwitch) {
            const hc1Txt = csmHealthChecks[HealthCheckIndex.cdnCheck].request_path.trim();
            const hc1 = hc1Txt.length === 0 ? STRs.hcConfigHelp : hc1Txt;
            const hc1CSS = hc1Txt.length === 0 ? 'hc-help' : ''
            const hc2Txt = csmHealthChecks[HealthCheckIndex.originCheck].request_path.trim();
            const hc2 = hc2Txt.length === 0 ? STRs.hcConfigHelp : hc2Txt;
            const hc2CSS = hc2Txt.length === 0 ? 'hc-help' : ''

            healthCheckData = (
                <div>
                    <div className="pad-top-20">{STRs.healthChecksDetectFailures}</div>
                    <div className="cdn-reserve-info">
                        <div>{STRs.cdnCheck}</div><div className={hc1CSS}>{hc1}</div>
                        <div>{STRs.originCheck}</div><div className={hc2CSS}>{hc2}</div>
                    </div>
                    <div>
                        <div className="pad-top-20">{STRs.thresholds}</div>
                        <div className="cdn-reserve-info">
                        <div>{STRs.failureThresholds}</div><div>{STRs.consecutiveFailures}</div>
                        <div>{STRs.successThresholds}</div><div>{STRs.consecutiveSuccess}</div>
                        </div>
                    </div>
                </div>
            )
        }

        const markup = (
            <div className="four-col">
                <div className="hrule"></div>
                <div>{STRs.cdnReserve}</div>
                <div className="indent-20px">
                    <div>
                        <input type="checkbox"  checked={autoSwitch} 
                            onChange={(e: React.FormEvent<HTMLInputElement>) => { 
                                this.handleDnsDirectorUpdate('autoSwitch', e.currentTarget.checked) }} >
                        </input>&nbsp;&nbsp;{STRs.autoSwitch}
                    </div>
                    <div>
                        <input type="checkbox"  checked={useCdnAsOrigin} disabled={useCdnDisabled}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => { 
                                this.handleDnsDirectorUpdate('useCdnAsOrigin', e.currentTarget.checked) }} >
                        </input>&nbsp;&nbsp;{STRs.useCdnAsOrigin}
                    </div>
                    {healthCheckData}
                </div>
            </div>
        )
        
        return markup;
    }

    private buildIntervalDropDown = (hc: ZCSMEntity, ddkey: string, fieldId: string): JSX.Element => {
        const hcIntervalConst: NumberMap = this.props.serviceUIState.projCsmHCIntervals;
        const keys = Object.keys(hcIntervalConst);
        const items: string[] = [];
        let selected = 0;
        const selectedVal: HealthCheckIntervals = hc.check_interval;
        for (let i=0, len=keys.length; i < len; i++) {
            const key = keys[i]
            items.push(STRs.intervalSecValue(key, hcIntervalConst[key]));
            if (selectedVal === key) {
                selected = i;
            }
        }
        return ZDropdown(items, selected, 'hcinterval'+ddkey, 
                        (index, optionName, value) => { this.handleCSMUpdate(fieldId, value as string) }, undefined, keys);
    }

    private buildCSMPanel = (fieldsValid: ServiceFieldValidate): JSX.Element => {
        const healthChecks = this.props.serviceUIState.csmHealthChecks;

        const cdnHcCss = 'service-friendly-name' + (fieldsValid.healthChecks[HealthCheckIndex.cdnCheck] ? '' : ' field-error');
        const originHcCss = 'service-friendly-name' + (fieldsValid.healthChecks[HealthCheckIndex.originCheck] ? '' : ' field-error');

        const hcInt1 = this.buildIntervalDropDown(healthChecks[HealthCheckIndex.cdnCheck], 'hc1', 'hc1-interval' );
        const hcInt2 = this.buildIntervalDropDown(healthChecks[HealthCheckIndex.originCheck], 'hc2', 'hc2-interval' );

        return (
            <section>
                <div className="section-title">{STRs.CSMInfo}</div>
                <div className="service-csm_edit">
                    <div>{STRs.healthChecks}</div>
                    <div  className="service-csm_data">
                        <div><label>{STRs.cdnCheck}</label></div>
                        <div>
                            <input className={cdnHcCss} type="text" 
                                    value={healthChecks[HealthCheckIndex.cdnCheck].request_path} placeholder={ STRs.healthCheckHelp } maxLength={ MAX_HEALTH_CHECK_PATH_LENGTH }
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        this.handleCSMUpdate('hc1', e.currentTarget.value) }}  />
                        </div>
                        <div></div><div>{STRs.staticAssetHelp}</div>
                        <div></div><div>{STRs.checkInterval}&nbsp;&nbsp;&nbsp;&nbsp;{hcInt1}</div>

                        <div></div><div></div>

                        <div><label>{STRs.originCheck}</label></div>
                        <div>
                            <input className={originHcCss} type="text" 
                                    value={healthChecks[HealthCheckIndex.originCheck].request_path} placeholder={ STRs.healthCheckHelp } maxLength={ MAX_HEALTH_CHECK_PATH_LENGTH }
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        this.handleCSMUpdate('hc2', e.currentTarget.value) }}  />
                        </div>
                        <div></div><div>{STRs.dynamicAssetHelp}</div>
                        <div></div><div>{STRs.checkInterval}&nbsp;&nbsp;&nbsp;&nbsp;{hcInt2}</div>
                    </div>
                </div>
        </section>
        );
    }

    private buildRulePanel = (rule: RoutingRules, ruleViewingOptions: DisplayRoutingRules): JSX.Element => {
        const title = this.buildRulesTitle(rule, ruleViewingOptions);
        const sections: JSX.Element[] = [];
        if (ruleViewingOptions.match_filter) { sections.push(this.buildExtensionData(rule)); }
        if (ruleViewingOptions.features.cache) { sections.push(this.buildCacheData(rule)); }

        // NOTE: When we add waf editing back in, uncomment the first line and delete the second.
        // if (ruleViewingOptions.features.waf) { sections.push(this.buildWafData(rule)); }
        if (ruleViewingOptions.features.waf) { this.buildWafData(rule) }  // build the rule but don't include it

        if (ruleViewingOptions.features.image_mux) {  sections.push(this.buildImgMuxData(rule)); }
        if (ruleViewingOptions.features.xff) { sections.push(this.buildXffData(rule)); }

        let markup = <div>{rule.asset_type}</div>;
        markup = (
            <section className="routing-rules">
                {title}
                <div className="service-rule-edit">
                    {sections}
                </div>
            </section>
        )
        return markup;
    }

    private buildRulesTitle = (rule: RoutingRules, ruleViewingOptions: DisplayRoutingRules): JSX.Element => {
            // eslint-disable-next-line no-constant-condition
            const checkbox = false ? (
                <>
                    <input type="checkbox" checked={this.props.serviceUIState.enableHTTP2} 
                        onChange={() => {
                        logger.log(`enable ${rule.name}; `) }}>
                    </input>&nbsp;
                </>) : <span></span>;

        return (
            <div>{checkbox}
               <span className="section-title">{ruleViewingOptions.friendlyName}</span>
            </div>
        )
    }

    private buildExtensionData = (rule: RoutingRules): JSX.Element => {
        if (! rule.match_filter) {
            rule.match_filter = {
                location: {
                    path_regex: ''
                }
            }
        }
        const validFilter = rule.match_filter && rule.match_filter && rule.match_filter.location && 
                          rule.match_filter.location.path_regex;
        let markup = <div></div>;
        if (validFilter) {
            // a typical regex looks like '\.js$|\.css$';
            const regex = rule.match_filter.location.path_regex;
            let exts = regex.split('|');
            exts = exts.map( (s: string) => {
                s = s.substr(1, s.length - 2);
                return s;
            });
            const extStr = exts.join('\n')
            markup = (
                <div key="extensions">
                    <div>{STRs.extension}</div>
                    <div className="ztext-area pad-bottom-10">
                        <div className="rule-fixup" id="ruleTextArea" 
                            onClick={(e) => {e.stopPropagation(); logger.log('click text area div')}}>
                            <textarea value={extStr} onClick={() => {logger.log('click text area')}}
                                onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                                            e.stopPropagation();
                                            this.handleSvcRoutingRuleUpdate('extension', e.currentTarget.value) }} 
                                rows={4} cols={75}>
                            </textarea>
                        </div>
                    </div>
                </div>
            )
        }
        return markup;
    }
    
    private cacheRuleUIBuilder = (enabled: boolean, item: CacheAgeItem): JSX.Element => {
        const units = [STRs.seconds, STRs.minutes, STRs.hours, STRs.days];
        const cvtIdx2Unit = { d0: 's', d1: 'm', d2: 'h', d3: 'd' }
        const cvtUnit2Idx = { s: 0, m: 1, h: 2, d: 3 };

        const status = item.response_status;
        const age = item.age;
        const unit = age.substr(age.length - 1);
        const value = age.substr(0, age.length - 1);
        const validNumberCSS = 'service-friendly-name' + (integerValidation(value) ? '' : ' field-error');
        const unitIdx = cvtUnit2Idx[unit];
        const ddownOptions: DropdownOptions = { disabled: !enabled }

        const ddown = ZDropdown(units, unitIdx, ('units' + status), 
                              ((idx: number) => { 
                                const s = `${status},${value}${cvtIdx2Unit['d' + idx]}`;
                                  this.handleSvcRoutingRuleUpdate('cacheStatusValue', s); }), 
                              ddownOptions);

        return (
            <div className="cache-rule" key={'rstatus' + status}>
                <div>{STRs.cacheStatusLabel(status)}</div>
                <div>
                    <input type="text" className={validNumberCSS}
                    value={value} size={8} disabled={!enabled}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const s = `${status},${e.currentTarget.value}${unit}`;
                        this.handleSvcRoutingRuleUpdate('cacheStatusValue', s);
                    }} maxLength={ 4 } />
                </div>
                <div>{ddown}</div>
            </div>
        );
    }

    private buildCacheData = (rule: RoutingRules): JSX.Element => {
        if (rule.features.cache === undefined) {
            rule.features.cache = {
                enabled: false,
                default_age: []
            }
        }
        const enabled = rule.features.cache.enabled;     
        const cacheItems: CacheAgeItem[] | undefined = rule.features.cache.default_age;

        let markup = <div key="blank"></div>;
        if (cacheItems) {
            const cacheRules: JSX.Element[] = cacheItems.map((cItem: CacheAgeItem) => { 
                                                        return this.cacheRuleUIBuilder(enabled, cItem) })
            markup =  (
                    <div className="rule-fixup">
                    {cacheRules}
                    </div>
                )
        }

        return (
            <div className="rule-spacer" key="cache-rules">
            <input type="checkbox" checked={enabled}
                onChange={() => {
                    this.handleSvcRoutingRuleUpdate('cache', '');
                }}></input>&nbsp;{STRs.cacheStatus}
                {markup}
            </div>
        );
    }

    private buildWafData = (rule: RoutingRules): JSX.Element => {
        if (rule.features.waf === undefined) {
            rule.features.waf = { enabled: false };
        }
        const enabled = rule.features.waf.enabled;

        // TODO:  For right now the waf info is visible but always disabled.
        const wafDisabled = true;
        return (
            <div className="rule-spacer" key="waf-data">
                <input type="checkbox" checked={enabled} disabled={wafDisabled} 
                        onChange={() => {
                            this.handleSvcRoutingRuleUpdate('waf', '') }}>
                </input>&nbsp;  {STRs.enableWaf} 
            </div>
            )
    }

    private buildImgMuxData = (rule: RoutingRules): JSX.Element => {
        if (rule.features.image_mux === undefined) {
            rule.features.image_mux = { enabled: false};
        }

        const enabled = rule.features.image_mux.enabled;
        return (
            <div className="rule-spacer" key="image_mux-data">
                <input type="checkbox" checked={enabled} 
                        onChange={() => {
                            this.handleSvcRoutingRuleUpdate('image_mux', '') }}>
                </input>&nbsp; {STRs.enableImageMux} 
            </div>
        )
    }

    private buildXffData = (rule: RoutingRules): JSX.Element => {
        if (rule.features.xff === undefined) {
            rule.features.xff = {
                enabled: false,
                x_forwarded_for_header_name: ''
            }
        }

        const { enabled, x_forwarded_for_header_name } = rule.features.xff;
        let validHeaderCSS = 'service-friendly-name';
        if (enabled) {
            validHeaderCSS = validHeaderCSS + (headerValueValidation(x_forwarded_for_header_name) ? 
                                                        '' : ' field-error')
        }
        
        return (
            <div key="xff-data">
                <input type="checkbox"checked={enabled} onChange={() => {
                            this.handleSvcRoutingRuleUpdate('xff', '') }}>
                </input>&nbsp; {STRs.enableXff}
                <div className="xff-rule">
                    <div>{STRs.xffHeaderValue}&nbsp;</div>
                    <div>
                        <input type="text" className={validHeaderCSS} disabled={!enabled} maxLength={ 255 }
                        value={rule.features.xff.x_forwarded_for_header_name} size={60}  
                        placeholder={STRs.asciiCharsNoColon} onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            this.handleSvcRoutingRuleUpdate('xffHeader', e.currentTarget.value) ;
                        }}/>
                    </div>
                </div>
            </div>
        )
    }

    private panelValidation = (validation: ServiceFieldValidate): SvcEditPanelValidationEnum[] => {
        const { ddDomainName, originName, originDomainName, cdnTag, projHasCSM, csmHealthChecks, isCDNReserve} = this.props.serviceUIState;
        const panelsValid: SvcEditPanelValidationEnum[] = [];
        let panelValid: SvcEditPanelValidationEnum = SvcEditPanelValidationEnum.good;

        // overview
        panelValid = validation.serviceName && validation.domainsRedirect ? SvcEditPanelValidationEnum.good : SvcEditPanelValidationEnum.error;
        panelsValid.push(panelValid);

        let originValid = SvcEditPanelValidationEnum.good;
        let ddValid = SvcEditPanelValidationEnum.good;
        let csmValid = SvcEditPanelValidationEnum.good;

        // origin panel
        const originPanelExists = originName.length > 0 || originDomainName.length > 0;
        if (originPanelExists) {
            originValid = (validation.originName && validation.originDomainName && validation.sni) ? 
                            SvcEditPanelValidationEnum.good : SvcEditPanelValidationEnum.error;
        } else {
            originValid = SvcEditPanelValidationEnum.optional;
        }

        // DNS Director panel
        if (ddDomainName.length > 0 || cdnTag.length > 0) {
            ddValid = validation.ddDomainName && validation.zyTrafficPercentage && validation.cdnTag ?
                            SvcEditPanelValidationEnum.good : SvcEditPanelValidationEnum.error;
        } else {
            ddValid = SvcEditPanelValidationEnum.optional;
        }

        // CSM Panel
        csmValid = SvcEditPanelValidationEnum.good;

        if (projHasCSM && validation.healthChecks) {
            const hc1 = csmHealthChecks[HealthCheckIndex.cdnCheck] && csmHealthChecks[HealthCheckIndex.cdnCheck].request_path.length > 0;
            const hc2 = csmHealthChecks[HealthCheckIndex.originCheck] && csmHealthChecks[HealthCheckIndex.originCheck].request_path.length > 0;

            const validHc1 = validation.healthChecks[HealthCheckIndex.cdnCheck];
            const validHc2 = validation.healthChecks[HealthCheckIndex.originCheck];

            if (isCDNReserve) {
                csmValid = (validHc1 && validHc2 && hc1 && hc2) ? SvcEditPanelValidationEnum.good : 
                                                        SvcEditPanelValidationEnum.error;
            } else {
                const csmHasData =  hc1 || hc2;
                if (csmHasData) {
                    csmValid = validHc1 && validHc2  ? SvcEditPanelValidationEnum.good : SvcEditPanelValidationEnum.error;
                } else {
                    csmValid = SvcEditPanelValidationEnum.optional;
                }
            }
        }

        const forceGoodBad = (value: SvcEditPanelValidationEnum): SvcEditPanelValidationEnum => {
            return (value === SvcEditPanelValidationEnum.good) ? value : SvcEditPanelValidationEnum.error;
        }

        if (isCDNReserve) {
            originValid = forceGoodBad(originValid);
            ddValid = forceGoodBad(ddValid);
            csmValid = forceGoodBad(csmValid);
        }
        panelsValid.push(originValid);
        panelsValid.push(ddValid);
        panelsValid.push(csmValid);
        
        if (validation.rules) {
            validation.rules.forEach(( val: boolean ) => { 
                panelValid = val ? SvcEditPanelValidationEnum.good : SvcEditPanelValidationEnum.error;
                panelsValid.push(panelValid)
            });
        }

        return panelsValid;
    }

    private handleSvcRoutingRuleUpdate = ( fieldName: string, value: string ) => {
        const { routingRule } = this.props.serviceUIState;

        switch (fieldName) {
            case 'extension': {
                    let exts = value.split('\n');
                    exts = exts.map(s => { return '\\' +  s + '$'});
                    routingRule.match_filter.location.path_regex = exts.join('|');
                }
                break;
            
            case 'waf':
            case 'xff':
            case 'cache':
            case 'image_mux':
                routingRule.features[fieldName].enabled = !routingRule.features[fieldName].enabled;
                break;

            // values passed as "status,value" such as "200,7d"
            case 'cacheStatusValue':  {
                    const defaultAge = routingRule.features.cache.default_age;
                    if (defaultAge) {
                        const statusValue = value.split(',');
                        const itemIdx = defaultAge.findIndex((rule: CacheAgeItem) => { 
                                                        return rule.response_status === statusValue[0]
                                                    });
                        if (itemIdx !== -1) {
                            defaultAge[itemIdx].age = statusValue[1];
                        }
                    }
                }
                break;

            case 'xffHeader':
                routingRule.features.xff.x_forwarded_for_header_name = value;
                break;

            default:
                logger.alert(`handleSvcRoutingRuleUpdate: bad fieldname ${fieldName}`)
                return;
        }

        this.props.setRoutingRule(routingRule);
    }

    private handleServiceOverviewDetailUpdate = ( fieldName: string, value: string | boolean | number) => {
        const { serviceName, domainName, certNameListIdx, enableHTTP2, enableHTTPSRedirect, domainRedirects } = this.props.serviceUIState

        const oData = { serviceName, domainName, certNameListIdx, enableHTTP2, enableHTTPSRedirect, domainRedirects };

        if (oData[fieldName] !== undefined) {
            oData[fieldName] = value
        } else {
            logger.log(`handleServiceOverviewDetails: passed field name.  Was passed ${fieldName}`)
            return;
        }

        this.props.setServiceOverviewDetails(oData.serviceName, oData.domainName, oData.certNameListIdx, 
                                             oData.enableHTTP2, oData.enableHTTPSRedirect, oData.domainRedirects);
    }

    private handleServiceOriginUpdate = ( fieldName: string, value: string | boolean | number ) => {
        const { originName, originDomainName, tlsEnabled, sni } = this.props.serviceUIState

        const oData = {
            originName,
            originDomainName,
            tlsEnabled,
            sni
        }

        if (oData[fieldName] !== undefined) {
            oData[fieldName] = value
        } else {
            logger.log(`handleServiceOverviewDetails: passed bad field name.  Was passed ${fieldName}`)
            return;
        }

        this.props.setServiceOriginDetails(oData.originName, oData.originDomainName, oData.tlsEnabled, oData.sni);
    }

    private handleDnsDirectorUpdate = ( fieldName: string, value: string | boolean | number ) => {
        const { ddDomainName, zyTrafficPercentage, cdnTag, autoSwitch, useCdnAsOrigin } = this.props.serviceUIState

        const ddData = {
            ddDomainName,
            zyTrafficPercentage,
            cdnTag,
            autoSwitch,
            useCdnAsOrigin
        }

        if (ddData[fieldName] !== undefined) {
            ddData[fieldName] = value
        } else {
            logger.log(`handleDnsDirectorUpdate: passed bad field name.  Was passed ${fieldName}`)
            return;
        }

        // TODO: Help!
        this.props.setServiceDnsDirectorDetails(ddData.ddDomainName, ddData.zyTrafficPercentage, ddData.cdnTag,
                                                ddData.autoSwitch, ddData.useCdnAsOrigin);
    }

    private handleCSMUpdate = ( fieldName: string, value: string) => {
        const oldHcPaths : ZCSMEntity[]= this.props.serviceUIState.csmHealthChecks;
        const newHCData: ZCSMEntity[] = [ ...oldHcPaths];

        if (fieldName === 'hc1') {
            newHCData[HealthCheckIndex.cdnCheck].request_path = value
        } else if (fieldName === 'hc2') {
            newHCData[HealthCheckIndex.originCheck].request_path = value;
        } else if (fieldName === 'hc1-interval') { 
            newHCData[HealthCheckIndex.cdnCheck].check_interval = value as HealthCheckIntervals
        } else if (fieldName === 'hc2-interval') { 
            newHCData[HealthCheckIndex.originCheck].check_interval = value as HealthCheckIntervals

        } else {
            logger.log(`handleDnsDirectorUpdate: passed bad field name.  Was passed ${fieldName}`)
            return;
        }
        
        this.props.setServiceHCData(newHCData);
    }

    private handleEditSvcJSON = () => {
        this.props.toggleServicePopup(ZFeaturePopupTypes.EditServiceJSON, true)
    }

    private loadSvcProductFeatures = (projectId: string) => {

        // let url = ZURLS.serverProductsAndAttributes;
        let url = ZURLS.serverProductsAndAttributes(projectId);
        url = buildUrl(this.props.authNServerData, url);

        const requestData = {
            include_features: true
        }

        return ZGet(url, requestData);
    }

    private loadProjectCerts = (projectId: string) => {
        // const projectId: string = this.props.systemNavProjectState._currentProject.projectId;
        let url = ZURLS.serverCertManagement;
        url = buildUrl(this.props.authNServerData, url);

        const requestData = {
            project_id: projectId,
            meta: true,
            'meta.additional_info': true
        }

        return ZGet(url, requestData);
    }

    private saveDraft = async () => {
        if (!this.props.serviceUIState.isDirty) {
            return;
        }
        const service: ZServiceExt = this.prepareService();

        await this.saveDraftInternal(service)
    }

    private saveDraftInternal = async (service: ZServiceExt) => {
        try {
        const url = buildUrl(this.props.authNServerData, ZURLS.serverServiceManagement(service.service_id));
        const response: ZRestResponse = await ZPut(url, JSON.stringify(service));
            this.props.draftSaved(response as ZServiceExt);
            this.props.reloadServices();

            this.props.showNotification(NotificationStyles.success, STRs.draftUpdated(service.service_name));
            setTimeout(() => { 
                this.props.closeNotification(); 
            }, 5000)
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`CreateService.applyCreateServiceFailed: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification('error');
            })
            .catch(() => {
                this.props.applySettings('error');
            })
        }
    }

    private preparePorts = (): ZPortsDef => {
        const svcState = this.props.serviceUIState;
        const { certNameListIdx, certList, enableHTTP2 } = svcState; 

        const ports: ZPortsDef =  { };
        ports['80'] = {};

        if (certNameListIdx !== 0) {
            ports['80'] =  {redirect_to_tls_port: '443'};

            const certId = certList[certNameListIdx-1].cert_id;
            const z443: ZSecurePort = {
                tls_enabled: true,
                cert_id: certId
            }

            if (enableHTTP2) {
                z443.http2_enabled = true;
            }
            ports['443'] = z443;
        }

        return ports;
    }

    private prepareOrigins = (service: ZServiceExt): ZServiceExt => {
        const svcState = this.props.serviceUIState;
        const { originDomainName, originName, tlsEnabled, sni } = svcState;

        const hasOriginSection = (originDomainName.length > 0 && originName.length > 0);
        if (hasOriginSection) {
            const originsObj = service.origins !== undefined ? service.origins : {}
            if (! service.origins) {
                originsObj[originName] = {
                    domain_name: originDomainName,
                    port: (tlsEnabled) ? 443 : 80,
                }
            } else {
                originsObj[originName].domain_name = originDomainName;
                originsObj[originName].port = (tlsEnabled) ? 443 : 80
            }

            const sniStr = sni !== undefined ? sni.trim() : '';
            if (tlsEnabled && sniStr.length > 0) {
                originsObj[svcState.originName].sni = sniStr;
            }
            service.origins = originsObj;
        } else {
            delete service.origins;
        }

        return service;
    }

    private prepareDnsDirector = (service: ZServiceExt): ZServiceExt => {
        const svcState = this.props.serviceUIState;
        const { isLegacyService, ddDomainName, zyTrafficPercentage, autoSwitch, useCdnAsOrigin, cdnTag} = svcState;
 
        if (isLegacyService) {
            return service;
        }

        if (ddDomainName.length > 0 && zyTrafficPercentage.length > 0 && cdnTag.length > 0) {
            const traffic = parseInt(zyTrafficPercentage);
            // let cdnTraffic = 100;

            // build the cdn info
            let zyCname = '';
            if (service.dns_director && service.dns_director.cdns) {
                const keys = Object.keys(service.dns_director.cdns);
                if (keys.length === 1) {
                    const existingCdn = service.dns_director.cdns[keys[0]]
                    zyCname = existingCdn.cname;
                    // cdnTraffic = (existingCdn.traffic_percent !== undefined) ? existingCdn.traffic_percent : 100;
                }
            }

            const cdn: ZDnsDirectorDomain = {
                cdn_cname: ddDomainName,
                cname: zyCname,
                // traffic_percent: cdnTraffic         // currently should alwasy be 100
            }

            // fill in the dns director structure
            const dnsDirector: ZDnsDirectorResponse = {
                enabled: true,
                auto_switch: autoSwitch,
                use_cdns_as_origin: useCdnAsOrigin,
                zycada_traffic_percent: traffic,
            }
            dnsDirector.cdns = {};
            dnsDirector.cdns[cdnTag] = cdn;

            service.dns_director = dnsDirector;
        } else {
            const dnsDirector: ZDnsDirectorResponse = {
                enabled: true,
                auto_switch: autoSwitch,
                use_cdns_as_origin: useCdnAsOrigin,
                zycada_traffic_percent: 100,
                cdns: {}
            }
            service.dns_director = dnsDirector;
        }

        return service;
    }

    private prepareCSM = (service: ZServiceExt): ZServiceExt => {
        const svcState = this.props.serviceUIState;
        const { isLegacyService, csmHealthChecks, projHasCSM } = svcState;

        if (isLegacyService) {
            return service;
        }
        if (projHasCSM) {
            const cdnHc = csmHealthChecks[HealthCheckIndex.cdnCheck];
            const originHc = csmHealthChecks[HealthCheckIndex.originCheck];

            if ( cdnHc.request_path.length === 0 && originHc.request_path.length === 0) {
                if (service.cloud_service_monitor !== undefined) {
                    delete service.cloud_service_monitor;
                }
            } else {
                if (! service.cloud_service_monitor) {
                    service.cloud_service_monitor = {
                        enabled: true,
                        health_checks: {} as ZCSMHealthCheck
                    }
                } 

                const healthChecks: ZCSMHealthCheck = service.cloud_service_monitor.health_checks;
                if (cdnHc.request_path.length > 0) {
                    healthChecks.cdn_check = healthChecks.cdn_check !== undefined ? healthChecks.cdn_check : {} as ZCSMEntity;
                    healthChecks.cdn_check = csmHealthChecks[HealthCheckIndex.cdnCheck];
                } else {
                    if (healthChecks.cdn_check) {
                        delete healthChecks.cdn_check;
                    }
                }
                if (originHc.request_path.length > 0) {
                    healthChecks.origin_check = healthChecks.origin_check !== undefined ? healthChecks.origin_check : {} as ZCSMEntity;
                    healthChecks.origin_check = csmHealthChecks[HealthCheckIndex.originCheck];
                } else {
                    if (healthChecks.origin_check) {
                        delete healthChecks.origin_check;
                    }
                }
            }
        } else if (!projHasCSM && service.cloud_service_monitor !== undefined) {
            delete service.cloud_service_monitor;
        }

        return service;
    }

    private prepareService = (): ZServiceExt => {
        const svcState = this.props.serviceUIState;

        let service: ZServiceExt = svcState.editableService;

        service.service_name = svcState.serviceName;
        service.project_id = this.props.systemNavProjectState._currentProject.projectId;

        const redirects = splitAndCleanDomainsString(svcState.domainRedirects)
        const culledRedirects = {};
        redirects.forEach((domain: string) => {
            culledRedirects[domain] = domain;
        })
        service.redirect_from_zycadized_domain_names = Object.keys(culledRedirects);

        service.ports = this.preparePorts();
        service = this.prepareOrigins(service);
        service = this.prepareDnsDirector(service);
        service = this.prepareCSM(service);

        return service;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private closeNotification = (error?: string) => {
        if (this.props.serviceUIState.serviceState === EProjectState.initComplete ||
            this.props.serviceUIState.serviceState === EProjectState.initStarted ) {
                this.props.applySettings('server error');
        }

        setTimeout(() => { 
                    this.props.closeNotification(); 
                }, 5000);
    }

//     private gotoCertManagement = () => {
//         setTimeout(() => { 
//                     this.props.redirectTo(ZURLS.admin);
//                     setTimeout(() => {
//                         this.props.setNavItem(NAVSTRINGS.admin, SysFeatureEnums.certAdmin);
//                             }, 100);
//                 }, 100);
//     }
}

const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        serviceUIState: state.serviceUIState,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(EditDraftSvc);