/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';


import { PurgeType } from '../../reducers/cacheState';

import { BooleanMap, StringMap } from '../../data/metricsAndOptionsDefs';

import { MAX_CACHE_PURGE_URI_FIELD_LENGTH, MAX_PURGE_PATHS, MAX_CACHE_TAG_FIELD_LENGTH, MAX_CACHE_TAG_LINES, MAX_TAG_LENGTH } 
        from '../../shared/constants';
import { CACHE_STRINGS as Strs, MISC_STRINGS } from '../../shared/strings';

import GenDialog from '../../shared/GenDialog';
import ZDropdown from '../../shared/ZDropDown';
import logger from '../../shared/logUtilities';
import { convertStringToStringArray } from '../../shared/utilities';

export interface NewCacheRequestProps {
    onHide: () => void,
    environment: string,
    serviceDDownList: string[],
    servicesList: string[],
    serviceIdx: number,
    svcIdsToCacheTagHeader: StringMap;
    onCreatePurgeRequest: (purgeType: PurgeType, serviceId: string, purgeInfo: string) => void
}


const NewCacheRequest: React.FunctionComponent<NewCacheRequestProps> = props => {

    logger.log('NewCacheRequest Start');
    const [ uris, setUris ] = useState('');
    const [ serviceIdx, setServiceIdx ] = useState(props.serviceIdx)
    // const [ certTags, setCertTags ] = useState('');
    const [ newCReqVisible,  setNewCReqVisible ] = useState(true);
    const [ purgeType, setPurgeType ] = useState(PurgeType.urlList)

    const isPurgeDisabled = (uriErrors: BooleanMap): boolean => {
        // const {serviceIdx, uris, uriCodeListIdx, purgeType} = props;

        const purgeDisabled = false;
        if (props.servicesList.length === 0 || serviceIdx === 0) {
            return true;
        }

        if ((purgeType === PurgeType.urlList) && 
            (uris.length === 0 || (uris.length > MAX_CACHE_PURGE_URI_FIELD_LENGTH))) {
            return true;
        }
        if ((purgeType === PurgeType.cacheTags) && 
            (uris.length === 0 || (uris.length > MAX_CACHE_TAG_FIELD_LENGTH))) {
                return true;
        }
        if ((purgeType === PurgeType.urlList) && (uriErrors.tooManyUris || uriErrors.noStartingSlashOrTilda || 
                                                  uriErrors.embeddedStar )) {
            return true;
        } 

        return purgeDisabled;
    }

    const buildBody = (uriErrors: BooleanMap): JSX.Element => {
        const envList: string[] = [MISC_STRINGS.prod, MISC_STRINGS.staging]
        const envIdx = (props.environment === 'prod') ? 0 : 1;

        const envDropdown = ZDropdown(envList, envIdx, 'envDropdown', ((index: number) => { console.log(index)}), 
                                      {disabled: true});

        const envMarkup = (
            <div>
                {Strs.environmentToPurge}&nbsp;&nbsp;<strong>{envDropdown}</strong>
            </div>
        )

        const hasSvc = (props.servicesList.length > 0);
        const showHideCtrl = hasSvc ? '' : 'hideDeployed';
            
        const services: JSX.Element = buildServiceDropDown(showHideCtrl);
        // const uriCodePanel: JSX.Element = buildUriCodePanel(showHideCtrl);
        // const purgeTag: JSX.Element = this.buildPurgeTagPanel(showHideCtrl);
        const pathTagPanel: JSX.Element = buildTextPanel(showHideCtrl, uriErrors);
        const noServices: JSX.Element = hasSvc ? <div></div> : <div className='showNoSvc'>{Strs.noSvcForEnv}</div>

        return (
            <>
            {envMarkup}
            <div id="purge-request">
                {services}
                {pathTagPanel}
                {noServices}
            </div>
            </>
        )
    }

    const buildServiceDropDown = (showHideCtrl: string): JSX.Element =>  {
        const { serviceDDownList, servicesList } = props;
        const svcDropdown = ZDropdown(serviceDDownList, serviceIdx, 'svcDropdown', 
                                    ((index: number) => { 
                                        setServiceIdx(index);
                                        if (index === 0 || props.svcIdsToCacheTagHeader[servicesList[index-1]] === undefined) {
                                            setPurgeType(PurgeType.urlList);
                                        }
                                    }), {});

        const svcMarkup = (
            <div className={showHideCtrl}>
                {Strs.services}&nbsp;&nbsp;{ svcDropdown }
            </div>
        )

        return svcMarkup;
    }

    const buildPurgeTypeDropDown = (): JSX.Element => {
        const { servicesList } = props;

        const dropdown = [Strs.pathList, Strs.cacheTags];
        const purgeTypes = [PurgeType.urlList, PurgeType.cacheTags ];

        let headerTagName = '';
        let ddownDisabled = false;
        let supportCacheTags = false;

        // any services support cache tags?
        for (let i=0, len=servicesList.length; i < len; i++) {
            supportCacheTags = supportCacheTags || (props.svcIdsToCacheTagHeader[servicesList[i]] !== undefined)
        }
        
        if (serviceIdx !== 0) {
            headerTagName = props.svcIdsToCacheTagHeader[servicesList[serviceIdx - 1]];
            ddownDisabled = headerTagName === undefined;
        } else {
            ddownDisabled = true;
        }

        let pTypeMarkup = <span></span>;

        if (supportCacheTags) {
            let info = '';
            if (purgeType === PurgeType.cacheTags && serviceIdx > 0) {
                const headerTagName = props.svcIdsToCacheTagHeader[servicesList[serviceIdx - 1]]
                info = Strs.tagInfo(headerTagName)
            }
            
            const ptypeIdx = (purgeType === PurgeType.urlList) ? 0 : 1;
            const ptypeDropdown = ZDropdown(dropdown, ptypeIdx, 'svcDropdown', ((index: number) => { 
                setPurgeType(purgeTypes[index])}), {disabled: ddownDisabled});
                pTypeMarkup = (
                <div /*className={showHideCtrl}*/>
                    {Strs.purgeType}&nbsp;&nbsp;{ ptypeDropdown } &nbsp;&nbsp;{info}
                </div>
            )
        } else {
            pTypeMarkup = (
                <div /*className={showHideCtrl}*/>
                {Strs.purgeType}&nbsp;&nbsp;<strong>{Strs.pathList}</strong>
            </div>
            )
        }


        return pTypeMarkup
    }

    const getPathListMsg = (uriErrors: BooleanMap): {pathListMsg: string, instructions: JSX.Element, error: boolean} => {
        let error = false;
        const pathError = (uris.length > MAX_CACHE_PURGE_URI_FIELD_LENGTH);
        const instructions = <span>{Strs.pathInstructions}</span>;
        let pathListMsg = '';

        if (pathError) {
            pathListMsg = Strs.tooManyChars(MAX_CACHE_PURGE_URI_FIELD_LENGTH);
        } else {
            if (uriErrors.tooManyUris) {
                pathListMsg = Strs.tooManyPaths(MAX_PURGE_PATHS);
                error = true;
            } else if (uriErrors.noStartingSlashOrTilda) {
                pathListMsg = Strs.pathListNoSlashError;
                error = true;
            } else if (uriErrors.embeddedStar) {
                pathListMsg = Strs.embeddedStar;
                error = true;
            } else {
                pathListMsg = Strs.charactersLeft(uris.length, MAX_CACHE_PURGE_URI_FIELD_LENGTH);
            }
        }

        return { pathListMsg, instructions , error }
    }

    const getCacheTagsMsg = (cacheErrors: BooleanMap): {pathListMsg: string, instructions: JSX.Element, error: boolean} => {
        let error = false;
        const pathError = (uris.length > MAX_CACHE_TAG_FIELD_LENGTH);
        let pathListMsg = '';
        const instructions = <span>{Strs.tagInstructions}&nbsp;&nbsp;<strong>{Strs.legalTagChars}</strong> </span>;

        if (pathError) {
            pathListMsg = Strs.tooManyChars(MAX_CACHE_TAG_FIELD_LENGTH);
        } else {
            if (cacheErrors.tooManyUris) {
                pathListMsg = Strs.tooManyTagLines(MAX_CACHE_TAG_LINES);
                error = true;
            } else if (cacheErrors.tagLineTooLong) {
                pathListMsg = Strs.tagLineTooLong;
                error = true;
            } else if (cacheErrors.tagIllegalChars) {
                pathListMsg = Strs.illegalCharsInTags;
                error = true;
            } else {
                pathListMsg = Strs.charactersLeft(uris.length, MAX_CACHE_TAG_FIELD_LENGTH);
            }
        }

        return { pathListMsg, instructions, error }
    }

    const buildTextPanel = (showHideCtrl: string, uriErrors: BooleanMap): JSX.Element => {
        const pathListMsgInfo = (purgeType === PurgeType.urlList) ? getPathListMsg(uriErrors) : getCacheTagsMsg(uriErrors)

        const editBox = buildEditBox(pathListMsgInfo.pathListMsg, pathListMsgInfo.error)
        const purgeDropdown = buildPurgeTypeDropDown();
        const instructions = pathListMsgInfo.instructions;

        const markup = (
            <div className={'purge-controls ' + showHideCtrl}>
                {purgeDropdown}
                <div className="ztext-area"><div>{instructions}</div>
                {editBox}
                </div>
            </div>
        );
        
        return markup;
    }

    const buildEditBox = (pathListMsg: string, error: boolean) => {
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const maxWidth = (purgeType === PurgeType.urlList) ? 75 : 128;
        console.log(`purgeType = ${purgeType}`)

        useEffect(() => {
            if (textAreaRef && textAreaRef.current) {
                textAreaRef.current.focus()
            }
        }, [purgeType]);

        const placeHolder = (purgeType === PurgeType.urlList) ? Strs.urlListPlaceHolder : Strs.cacheTagPlaceHolder;

        return (
            <>
                <div className="textarea-fixup" id="purgeTextArea">
                    <textarea ref={textAreaRef}
                        value={uris}
                        className={(error ? 'text-area-error' : '')}
                        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                                    e.stopPropagation();
                                    setUris(e.currentTarget.value) }} 
                        rows={8} cols={maxWidth}
                        placeholder={placeHolder} 
                        >
                    </textarea>
                    <div className={'textarea-message ' + ((error) ? 'too-many-char-error' : '')}>
                        {pathListMsg}
                    </div>
                </div>
                </>
        );
    }

    const handleCreatePurgeRequest = (isPurgeDisabled: boolean) => {
        if (! isPurgeDisabled) {
            (props.onCreatePurgeRequest)(purgeType,props.servicesList[serviceIdx-1], uris);
        }
    }

    const cacheErrors: BooleanMap = {
        tooManyUrisOrTags: false,
        noStartingSlashOrTilda: false,
        embeddedStar: false,
        tagIllegalChars: false,
        tagLineTooLong: false,
    }
    const errorChecker = (): void => {

        const lineList = convertStringToStringArray(uris);
        if (purgeType === PurgeType.urlList) {
            if (lineList.length > MAX_PURGE_PATHS) {
                cacheErrors.tooManyUris = true;
            }
            lineList.forEach((line: string) => {
                if (line.charAt(0) !== '/' && line.charAt(0) !== '~') {
                    cacheErrors.noStartingSlashOrTilda = true;
                }
            });
        } else {
            if (lineList.length > MAX_CACHE_TAG_LINES) {
                cacheErrors.tooManyUris = true;
            }
            lineList.forEach((line: string) => {
                if (line.length > MAX_TAG_LENGTH) {
                    cacheErrors.tagLineTooLong = true;
                }
                if (!cacheErrors.tagIllegalChars) {
                    const tagRegex = /[\da-zA-Z._-]/;  // /[<>*@\\{}:,"=()?; [\]]/;

                    for (let i = 0, len = line.length; i < len; i++) {
                        const c = line.charAt(i);
                        if (!tagRegex.test(c)) {
                            cacheErrors.tagIllegalChars = true;
                            break;
                        }
                    }
                }
            })
        }
    }


    errorChecker();
    const purgeDisabled: boolean = isPurgeDisabled(cacheErrors);
    const markup = buildBody(cacheErrors);

    return (
        <GenDialog 
            show={newCReqVisible}
            title={Strs.createCachePurgeRequest} 
            msg={''}
            size='lg'
            applyBtnTxt={Strs.purge}
            disableApply={(): boolean => { return purgeDisabled}}
            onHide={(): void => {setNewCReqVisible(false); props.onHide();}}
            handleApply={(): void => {setNewCReqVisible(false); handleCreatePurgeRequest(purgeDisabled); }}
        >
            <div className="list-boundary">
            {markup}
            </div>
        </GenDialog>
    )
}

export default NewCacheRequest;