import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { isFunction } from 'lodash';

import * as actionCreators from '../../actions/actionCreators';
import { actionTypes } from '../../actions/actionCreatorTypes';

import { State } from '../../App';
import { AnalyticsMetricData, ProjectMetricKeyResponse, SvrMetricTagEntry, RestAPI, MetricsDataV1Response, ZRestResponse,
         ZServiceFeatures, SvrMetricTimeData, FetchError, ZProjectMin, CsmLogsResponse, CsmLogsData, } from '../../data/queryResultDefinitions';
import { MetricsUIDefinitions, ChartTypes } from '../../data/metricsAndOptionsDefs';
import { renderRangeInfo } from '../../shared/utilities';

import { getProjectFeatures, getCurrentProjectEnvironment } from '../../reducers/projectAccessors';
import { EProjectState, loginState, SysFeatureEnums, MetricDataTypes } from '../../reducers/reducerEnums';
import { emptyMetricDataJSON, getMetricsForFeatures, getMetricsQueryParams, getPeriodStartEndAnl, getSelectedMetrics,
         MetricOptionOverrides, MetricQueryReturnValue, ProjectMetricDefinitions, TabMappingObj, getMetricsObjForTab,
         getAppliedAnalyticsTimeoutValue, isRefreshEnabled, getMetricV1QueryParams, areAnySvcsConfiguredForFeature, OptAnalyticFeatures } from '../../reducers/uiAccessors';

import { State as AnalyticsUIState } from '../../reducers/analyticsState';
import { State as SystemNavProjectState } from '../../reducers/systemState';

import { buildUrl } from '../../reducers/serverEnvironAccessor';

import Chart from './Chart';
import MetricsPanel from './MetricsPanel';
import Options from './Options';

import { ZGet } from '../../shared/backend';
import { MISC_STRINGS, ANALYTICS_STRINGS as STRs } from '../../shared/strings';
import ZURLS from '../../shared/urls';
import ZStatBox from '../../shared/StatBox';
import { NotificationStyles, TzAbbrev, DATETIME_FORMAT, GEO_MAP } from '../../shared/constants';
import PaginationCtrl from '../../shared/PaginationCtrl';
import logger from '../../shared/logUtilities';

interface AnalyticsChartingProps extends State {
    setCurrentProjectKeys: (optionKeys: ProjectMetricKeyResponse) => actionTypes;
    initAnalyticOptions: (fullInit: boolean, feature: ZServiceFeatures, optionKeys: ProjectMetricKeyResponse, analyticTab: SysFeatureEnums) => actionTypes;
    initMetricCheckBoxes: (checkboxData: ProjectMetricDefinitions) => actionTypes;
    applySettings: (error?: string) => actionTypes;
    setAnalyticsGraphData: (graphData: AnalyticsMetricData[], appendData: boolean, numItems: number, 
                    dataType?: MetricDataTypes) => actionTypes;
    setAnalyticOption: (optionName: string, value: number) => actionTypes; 
    setAnalyticsMoreData: (currentFeature: SysFeatureEnums, type: string, data: CsmLogsResponse) => actionTypes;
    setCurrentPage: ( pageNum: number, component: string ) => actionTypes,
    refreshPage: () => actionTypes;
    featureReady: () => actionTypes;
    changeProjects: (projectId: string) => actionTypes;
    setAnalyticsRefreshId: (timeoutId: number) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    setProjects: (projects: ZProjectMin[], projectId?: string) => actionTypes;
}

interface V1MetricExtra {
    statTitle: string,
    statValue: number,
    // statId: string,
}

interface ChartDataObj {
    [metric: string]: AnalyticsMetricData;
}
        
/*
interface EFRChartData {
        client: { 
            failure_pm: AnalyticsMetricData | undefined, 
            requests_pm: AnalyticsMetricData | undefined, 
            proc_time_ms: AnalyticsMetricData | undefined
        },
        origin: { 
            failure_pm: AnalyticsMetricData | undefined, 
            requests_pm: AnalyticsMetricData | undefined, 
            proc_time_ms: AnalyticsMetricData | undefined},
}
*/

// eslint-disable-next-line 
class AnalyticCharting extends React.Component<any, AnalyticsChartingProps> {

    public componentDidMount(): void {
        if (this.props.authNServerData.isLoggedIn === loginState.loggedIn) {
            const systemState = this.props.systemNavProjectState;
            const currentProject = systemState._currentProject;
            
            if (systemState._projectList.length > 0) {
                if (systemState.projectsLoaded && systemState.servicesLoaded) {
                    const features: ZServiceFeatures = getProjectFeatures(systemState);
                    this.props.initAnalyticOptions(true, features, currentProject.optionKeys, systemState.currentFeature);
                } else {
                    logger.log('projects and services are not loaded')
                }
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public componentDidUpdate(prevProps: AnalyticsChartingProps): void {
        try {
        const systemState = this.props.systemNavProjectState;

        if (! (systemState.projectsLoaded && systemState.servicesLoaded)) {
            return;
        }
        const features: ZServiceFeatures = getProjectFeatures(systemState);
        const currentProj = systemState._currentProject;

        // if service environment changed we can't believe what the number of deployed services is.  We have to
        // reset the project info for the new environment.
        if (currentProj.servicesList.length <= 0 && 
           (systemState.systemUIState !== EProjectState.serviceEnvironmentChanged)) {
            systemState.systemUIState = EProjectState.ready;
        }

        // logger.log(`-- AnalyticsCharting: systemState ${systemState.systemUIState}`)
        switch (systemState.systemUIState) {
            case EProjectState.newProjectLoaded:
            case EProjectState.tabChanged:
                this.reInit(features);
                break;

            case EProjectState.servicesUpdated:
            case EProjectState.serviceChanged: {
                    this.reInit(features);
                }
                break;
                
            case EProjectState.serverError:
                break;

            default:
                this.analyticsStateProcessing(features);
                break;
        }
        } catch (e) {
            console.log('AnalyticCharting.componentDidUpdate: ' + e);
        }
    }

    private reInit = (features: ZServiceFeatures): void => {
        const systemState = this.props.systemNavProjectState;
        // const anlState = this.props.analyticsUIState;

        this.props.featureReady();
        this.props.initAnalyticOptions(false, features, systemState._currentProject.optionKeys, systemState.currentFeature);
    }

    private analyticsStateProcessing = (features: ZServiceFeatures): void => {
        const currentUIState = this.props.analyticsUIState.aanalyticsState;
        logger.log(`%c AnalyticCharting.analyticsStateProcessing: current analytic state is '${currentUIState}'.`, 'background: purple, color: white;');
        switch (currentUIState) {

            case EProjectState.initComplete: {
                const metricData: ProjectMetricDefinitions = getMetricsForFeatures(this.props.systemNavProjectState, features);
                this.props.initMetricCheckBoxes(metricData);
                }
                break;

            case EProjectState.userClickedApply:
            case EProjectState.serviceChanged:
            case EProjectState.serviceEnvironmentChanged:
                this.updatePage(features)
                break;

            case EProjectState.projectKeysLoaded:
            case EProjectState.dataLoadInProgress:
            case EProjectState.reloadData:
                this.loadGraphData();
                break;

            case EProjectState.userChangedPages: 
                this.loadNewCsmLogData();
                break;

            case EProjectState.dataLoaded:
                this.buildSelectedCharts();
                break;

            case EProjectState.ready:
            default:
                break; 
        }
    }

    componentWillUnmount(): void {
        if (this.props.analyticsUIState.analyticsTimeoutId > 0) {
            window.clearInterval(this.props.analyticsUIState.analyticsTimeoutId);
            this.props.setAnalyticsRefreshId(-1);
        }
    }

    public render(): JSX.Element {
        try {
        const systemState = this.props.systemNavProjectState;
        const { _currentProject, currentFeature } = systemState;
        let myCharts = <span></span>;

        const featureBuilder = {
            [SysFeatureEnums.dnsDirAnl]: this.buildDnsDirCharts,
            [SysFeatureEnums.csmAnl]: this.buildCSMCharts,
            [SysFeatureEnums.trafficAnl]: this.buildLegacyAnalyticsCharts,
            [SysFeatureEnums.cacheAnl]: this.buildLegacyAnalyticsCharts,
            [SysFeatureEnums.csaAnl]: this.buildLegacyAnalyticsCharts,
            
            [SysFeatureEnums.wafAnl]: this.buildLegacyAnalyticsCharts,
            [SysFeatureEnums.botAnl]: this.buildLegacyAnalyticsCharts,

            [SysFeatureEnums.efrAnl]: this.buildLegacyAnalyticsCharts,
            [SysFeatureEnums.nrumAnl]: this.buildLegacyAnalyticsCharts,
        }

        let analytics;
        if (_currentProject.servicesList.length > 0) {

            // console.log(`AnalyticCharting.render: currentFeature: ${currentFeature}`)
            const builder = featureBuilder[currentFeature];
            if (builder) {
                myCharts = builder();
            } else {
                console.log('AnalyticsCharting.render: bad current feature ');
                return <span></span>;
            }
            if (myCharts === undefined) {
                return <span></span>
            }
            analytics = (<>
                <Options applyHandler={(): void => { this.handleApply()}} refreshHandler={(): void => { this.handleRefresh()}}  {...this.props} />
                <div id="chart-content">
                    {myCharts}
                </div>
            </>);
        } else  {
            const msg = (systemState._projectList.length > 0) ? 
                    MISC_STRINGS.descNoDeployedServices(getCurrentProjectEnvironment(this.props.systemNavProjectState)) : 
                    MISC_STRINGS.noProjectsForUser;
            analytics = (
                <div className="no-services">
                    {msg}
                    <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                </div>
                );
        }
        
        return (
            <div className="feature-panel">
                <div className="analytics">
                    {analytics}
                </div>
            </div>
        )
        } 
        catch (e) {
            console.log(e);
            return (<span>Error rendering Analytics Charting page.</span>)
        }
    }

    protected getLastUpdateStr = (): string => {
        const analyticsState = this.props.analyticsUIState;
        const date = new Date(analyticsState.lastUpdateTime);
        const lastUpdate = date.toLocaleString();
        return analyticsState.isLoading ? MISC_STRINGS.updating : MISC_STRINGS.lastUpdateLabel + lastUpdate + TzAbbrev;
    }

    protected buildLegacyAnalyticsCharts = (): JSX.Element => {
        // const feature = this.props.systemNavProjectState.currentFeature;
        // const isEfr = feature !== SysFeatureEnums.efrAnl
        // let chartCss = 'analytics-charts';

        // if (isEfr) {
        //     myCharts = this.buildEFRCharts();
        //     chartCss = 'analytics-charts-efr'
        //     metricPanelMarkup = '';
        //     myCss = '';
        // } else {
            const chartCss = 'analytics-charts';
            const updateText = this.getLastUpdateStr();
            const myCharts = this.buildSelectedCharts();
            const myCss = ((myCharts.length === 1) ? 'single-chart' : ' multi-chart');
            const metricPanelMarkup = <div><MetricsPanel metricCSS={myCss} {...this.props}  /></div>;
        // }

        return (
            <div className={chartCss}>
                <div className="lastUpdate">{updateText}</div>
                <div className="multi-chart-container">
                    <div className="chart-container">
                        <div className="container-fluid">
                            {myCharts}
                        </div>
                    </div>
                    <div className="copyright" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                </div>
                {metricPanelMarkup}
            </div>
        );
    }

    private buildPercentStr = (value: number): string => {
        let str = MISC_STRINGS.notApplicable;

        if (value !== undefined && !Number.isNaN(value)) {
            str = value.toFixed(2);
            str = str.endsWith('.00') ? value.toFixed(0) : str;
            str = str + ' %';
        } else {
            str = MISC_STRINGS.notAvailable;
        }
        return str;
    }

    protected buildDnsDirCharts = (): JSX.Element => {
        const anlState: AnalyticsUIState = this.props.analyticsUIState;
        const sysState: SystemNavProjectState = this.props.systemNavProjectState;

        const showDnsDir = areAnySvcsConfiguredForFeature(sysState, OptAnalyticFeatures.dnsDir);
        let markup: JSX.Element = <span></span>;

        if (showDnsDir) {
            const statsArray = anlState.extraGraphInfo === undefined || anlState.extraGraphInfo.length === 0 ? 
                                                        [{aggregateValue: {ZY: -1, CDN: -1}}] : anlState.extraGraphInfo;
            const stats = statsArray[0].aggregateValue;
            const statData: V1MetricExtra[] = [
                {
                    statTitle: STRs.dnsDirStatZyLabel,
                    statValue: stats.ZY
                }, 
                {
                    statTitle: STRs.dnsDirStatCDNLabel,
                    statValue: stats.CDN
                }, 
            ]

            markup = this.buildV1Chart(statData);
        } else {
            markup = (<>
                <div className="feature-not-configured">{STRs.dnsDirNotConfigured}</div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                </>)
        }

        return markup
    }


    protected buildCSMCharts = (): JSX.Element => {
        const anlState: AnalyticsUIState = this.props.analyticsUIState;
        const sysState: SystemNavProjectState = this.props.systemNavProjectState;

        const showCSM = areAnySvcsConfiguredForFeature(sysState, OptAnalyticFeatures.csm);
        let markup = <div></div>;
        if (showCSM) {
            const statsArray = anlState.extraGraphInfo === undefined || anlState.extraGraphInfo.length === 0 ? 
                    [{aggregateValue: {ZY: -1, CDN: -1}}] : anlState.extraGraphInfo;
            const stats = statsArray[0].aggregateValue;
            const statData: V1MetricExtra[] = [
                {
                    statTitle: STRs.altZyUptime,
                    statValue: stats.ZY
                }, 
                {
                    statTitle: STRs.altCdnUptime,
                    statValue: stats.CDN
                }, 
            ]
    
            markup = this.buildV1Chart(statData,  anlState.csmLogData);
        } else {
            markup = (<>
                    <div className="feature-not-configured">{STRs.csmNotConfigured}</div>
                    <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                    </>)
        }
        return markup
    }

    protected buildV1Chart = (statData: V1MetricExtra[], logsData?: CsmLogsResponse): JSX.Element => {
        const anlState: AnalyticsUIState = this.props.analyticsUIState;
        const updateText = this.getLastUpdateStr();
        const myCharts = this.buildSelectedCharts();
        let logs = <div></div>;
        let copyrightCss = 'copyright-bottom';
        if (logsData !== undefined && logsData.logs !== undefined) {
            logs = this.buildCsmLogs(logsData);
            copyrightCss =  'copyright';
        }

        const chart = !(anlState.isLoading) && myCharts.length > 0 ? (
            <>
                <div></div>
                <div><ZStatBox stat={this.buildPercentStr(statData[0].statValue)} title={statData[0].statTitle} /></div>
                <div><ZStatBox stat={this.buildPercentStr(statData[1].statValue)} title={statData[1].statTitle} /></div>
                <div className="chart-container">
                    {myCharts}
                </div>
            </>
        ) : <div className="loading"></div>;

        return (
            <div className="analytics-chart-dnsDir">
                <div className="lastUpdate">{updateText}</div>
                <div className="multi-chart-container">
                {chart}
                </div>
                {logs}
                <div className={copyrightCss} dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
        );
    }

    private buildCsmLogs = (data: CsmLogsResponse): JSX.Element => {
        const anlState: AnalyticsUIState = this.props.analyticsUIState;
        let markup = <></>
        const pData = anlState.pagingData;
        let centerPanel: JSX.Element = <div/>;
        let rightPanel: JSX.Element = <div/>;
        let errors = <span></span>;

        if (data !== undefined) {
            const rows: JSX.Element[] = [];

            if (pData.totalItems > 0) {
                const rangeInfo = renderRangeInfo(pData);
                if (pData.pageSize < pData.totalItems) {
                     centerPanel = <div className="compensate-4-pctrl">{rangeInfo}</div>
                     if (pData.pageSize < pData.totalItems) {
                        rightPanel = <PaginationCtrl pData={pData} handlePageChange={(pageNum: number): void => { this.handleCsmLogChangePage(pageNum)}} />
                     }
                } else {
                    rightPanel = <div className="compensate-4-pctrl">{rangeInfo}</div>
                }
            } else if (!anlState.isLoading) {
                errors = <div className="col-sm-12 pad-top-20" id="serverError"><div>{MISC_STRINGS.noHcData}</div></div>;
            }
    
            if (!pData.isGoodData) {
                errors = <div className="col-sm-12 pad-top-20" id="serverError"><div>{MISC_STRINGS.serverError}</div></div>;
            }

            const topBar = (
                <div className="csm-top-bar">
                    <div>
                        <input type="checkbox" checked={anlState.futureAnalyticState.showCsmFailuresOnly}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                this.handleCsmFailureChange(e.currentTarget.checked) }} >
                        </input>
                    </div>
                    <div>{STRs.showFailures}</div>
                    {centerPanel}
                    {rightPanel}
                </div>
            );

            data.logs.forEach((row: CsmLogsData, idx: number) => {
                const checkSuccess = row.success;
                const status = checkSuccess ? STRs.pass : STRs.fail;
                const reason = checkSuccess === undefined? row.failure_reason : '';
                const time = moment(row["@timestamp"]).format(DATETIME_FORMAT)
                rows.push((
                    <tr key={'csm-data-' + idx}>
                        <td>{time}</td>
                        <td>{row.cdn}</td>
                        <td>{row.name}</td>
                        <td>{GEO_MAP[row.geo]}</td>
                        <td>{status}</td>
                        <td>{reason}</td>
                    </tr>
                ))
            });

            const dataTable =  (data.logs.length !== 0) ? (
                <div className="logs-table">
                <table>
                    <thead>
                        <tr>
                            <th>{STRs.time}</th>
                            <th>{STRs.cdn}</th>
                            <th>{STRs.healthChecks}</th>
                            <th>{STRs.geo}</th>
                            <th>{MISC_STRINGS.status}</th>
                            <th>{STRs.reason}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
            ) : <div></div>;


            markup = (<div id="csm-logs">
                {topBar}
                {dataTable}
                {errors}
            </div>)
        }

        return markup
    }

    protected handleRefresh(): boolean {
        this.props.refreshPage();
        return true;
    }
    
    protected handleApply(): boolean {
        this.props.applySettings();
        return false;
    }

    protected handleCsmLogChangePage = (pageNum: number): void => {
        this.props.setCurrentPage(pageNum, 'cms-hclogs');
    }

    private handleCsmFailureChange(failuresOnly: boolean): void {
        this.props.setAnalyticOption('csm-hc', failuresOnly ? 1 : 0)
    }

    private updatePage(features: ZServiceFeatures): void {
        const systemState = this.props.systemNavProjectState;
        const selectedSubFeature = systemState.currentFeature;
        const legacyOptions = !( selectedSubFeature === SysFeatureEnums.csmAnl || selectedSubFeature === SysFeatureEnums.dnsDirAnl);

        this.props.setCurrentProjectKeys(systemState._currentProject.optionKeys as ProjectMetricKeyResponse);
        this.loadGraphData();
        if (legacyOptions && features.metrics_by_geo && features.metrics_by_geo.enabled) {
            const promise = this.legacyLoadOptions();
            if (typeof promise !== 'string') {
                logger.log('[Analytics:updatePage] Option Data loaded');
            } else {
                logger.log('[Analytics:updatePage] - Error: ' + promise);
            }
        }
    }

    private legacyLoadOptions(): string | Promise<ZRestResponse> {
        const currentProject = this.props.systemNavProjectState._currentProject;
        if (! currentProject) {
            return 'loadOptions: No current project';
        }
        const requestPeriod = getPeriodStartEndAnl(this.props.analyticsUIState)    
        const serviceIdsStr = currentProject.currentServiceIds;

        if (serviceIdsStr.length === 0) {
            return 'loadOptions: No deployed services.';
        }
        
        const url = buildUrl(this.props.authNServerData, ZURLS.serverGetMetricKeys);
        const promise = ZGet(url, {
            'end_epochmillis': requestPeriod.endTime as number, 
            'service_ids': serviceIdsStr, 
            'start_epochmillis': requestPeriod.startTime as number});
        
        promise.then( ( optionKeys: ZRestResponse ) => {
            this.props.setCurrentProjectKeys(optionKeys as ProjectMetricKeyResponse);
            })        
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log('AnalyticCharting.loadOptions:  fetch error' + err.message)
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
                this.props.applySettings('serverError');
            })
        });

        return promise;
    }

    private fakeDataGet = () => {
        return new Promise((resolve) => {
            resolve({});
        });
    }

    private loadGraphData(): Promise<void> {
        const currentFeature = this.props.systemNavProjectState.currentFeature;

        switch(currentFeature) {
            case SysFeatureEnums.efrAnl:
                // EFR has to be handled special.  It runs the same metrics against
                // client and origin and lets the user see a comparison.
                return this.loadEFRGraphData()
                break;

            case SysFeatureEnums.csmAnl:
            case SysFeatureEnums.dnsDirAnl:
                return this.loadCsmDnsDirData();
                break;

            default:
                // do this if we have the ability to select metrics
                return this.loadSelectedGraphData();
                break;
        }
    }

    private loadEFRGraphData = async (): Promise<void> => {
    // private loadEFRGraphData(): Promise<ZRestResponse> {
        const metrics: MetricOptionOverrides[] = [
            { metrics: 'failure_pm,requests_pm', observer: 'origin', "direct_vs_zycada": false},
            { metrics: 'proc_time_ms', observer: 'origin', "direct_vs_zycada": false},
            { metrics: 'failure_pm,requests_pm', observer: 'client', 'direct_vs_zycada': false},
            { metrics: 'proc_time_ms', observer: 'client', 'direct_vs_zycada': true}
        ];

        const requestPeriod = getPeriodStartEndAnl(this.props.analyticsUIState);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const efrPromises = metrics.map((metric: MetricOptionOverrides, idx: number) => {
            const params: MetricOptionOverrides = Object.assign({}, metric);
            params.requestPeriod = requestPeriod;
            
            const queryData: MetricQueryReturnValue  = 
                getMetricsQueryParams(this.props.analyticsUIState, this.props.systemNavProjectState, params);
            // logger.log('loadEFRGraphData: queryString: ', queryData);

            const url = buildUrl(this.props.authNServerData, ZURLS.serverGetMetric);
            const promise = ZGet(url, queryData.queryParams);

            promise.then( ( graphData: ZRestResponse ) => {
                this.props.setAnalyticsGraphData(graphData as  AnalyticsMetricData[], true, 6);
                })
            .catch((errStr) => {
                const p = errStr as Promise<FetchError>;
                p.then((err) => {
                    console.log(`loadEFRGraphData: fetch failed: ${err}`);
                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification();
                    this.props.applySettings('serverError');
                })
            });

            return promise as  Promise<ZRestResponse>;
        })

        await Promise.all(efrPromises);
    }

    private loadCsmDnsDirData = async (): Promise<void> => {
        const sysState: SystemNavProjectState = this.props.systemNavProjectState;
        const analyticsState = this.props.analyticsUIState;
        const currentFeature = sysState.currentFeature;
        const queryFeatures: MetricOptionOverrides = {};

        const optFeature: OptAnalyticFeatures = (currentFeature === SysFeatureEnums.dnsDirAnl) ? OptAnalyticFeatures.dnsDir : OptAnalyticFeatures.csm;
        const doQuery = areAnySvcsConfiguredForFeature(sysState, optFeature);

        const urlMap = {
           [SysFeatureEnums.dnsDirAnl]: ZURLS.serverGetDDDnsWeights,
           [SysFeatureEnums.csmAnl]: ZURLS.serverCSMUpTime,
           [SysFeatureEnums.logsAnl]: ZURLS.serverCSMLogs,          // Yeah I know it isn't logs.  I just needed
                                                                    // away to identify it in the map.
        }

        const urlBuilder = (feature: SysFeatureEnums, queryFeature: MetricOptionOverrides) => {
            const queryData: RestAPI = getMetricV1QueryParams(analyticsState, sysState, queryFeature);
            const baseUrl = urlMap[feature](sysState._currentProject.projectId);
            const url = buildUrl(this.props.authNServerData, baseUrl);

            return {url, queryData};
        }
        let query = urlBuilder(currentFeature, queryFeatures);

        try {
            let response = await ZGet(query.url, query.queryData);
            const graphData: MetricsDataV1Response = response as MetricsDataV1Response
            this.props.setAnalyticsGraphData(graphData, false, 1, MetricDataTypes.dnsdir);

            if (currentFeature === SysFeatureEnums.csmAnl && doQuery) {
                const hcIndex = analyticsState.futureAnalyticState.selectedAnalyticOptions.healthChk;
                queryFeatures.hcIndex = hcIndex;
                if (analyticsState.futureAnalyticState.showCsmFailuresOnly) {
                    queryFeatures.status = "show-failures";
                }
                query = urlBuilder(SysFeatureEnums.logsAnl, queryFeatures);
                response = await ZGet(query.url, query.queryData);
                this.props.setAnalyticsMoreData(currentFeature, 'hcLogs', response as CsmLogsResponse)
            }
            this.handleRefreshTimeout();
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            if (p.then !== undefined) {               
                p.then((err) => {
                    console.log(`loadCsmDnsDirData: fetch failed: ${err}`);
                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification();
                    this.props.applySettings('serverError');
                })
            } else {
                const q = errStr as FetchError;      
                console.log(`AnalyticsCharting.loadCsmDnsDirData Execption: ${q.message}`)      
                
            }
        }
    }

    private loadNewCsmLogData = async (): Promise<void> => {
        const anlState = this.props.analyticsUIState as AnalyticsUIState;
        const nextData = anlState.csmLogNextData;
        let url = '';
        const queryParams = {
            hash: '',
            page: ''
        }

        try {
            if ( nextData.url !== undefined) {
                url = nextData.url;
                queryParams.hash = nextData.hash;
                queryParams.page =  anlState.pagingData.currentPage.toString();

                const response = await ZGet(url, queryParams);
                this.props.setAnalyticsMoreData(anlState.currentFeature, 'hcLogs', response as CsmLogsResponse);
            }
            else {
                console.log('AnalyticsCharting: Error build csm hc logs new page.');
            }
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            if (p.then !== undefined) {               
                p.then((err) => {
                    console.log(`loadCsmDnsDirData: fetch failed: ${err}`);
                    this.props.showNotification(NotificationStyles.danger, err.message);
                    this.closeNotification();
                    this.props.applySettings('serverError');
                })
            } else {
                const q = errStr as FetchError;      
                console.log(`AnalyticsCharting.loadCsmDnsDirData Execption: ${q.message}`)      
            }
        }
    }

    private loadSelectedGraphData = async (): Promise<void> => {
        const systemState = this.props.systemNavProjectState;
        const override = (systemState.currentFeature === SysFeatureEnums.apiAnl ? 
                         { observer: 'origin'} : {});
        const queryData: MetricQueryReturnValue  = 
                getMetricsQueryParams(this.props.analyticsUIState, systemState, override);

        if ((queryData.queryParams.metrics as string).length > 0 &&
            (queryData.queryParams.service_ids as string).length > 0) {
                try {
                const url = buildUrl(this.props.authNServerData, ZURLS.serverGetMetric);
                logger.log(`.... AnalyticChart.loadSelectedGraphData: url ${url}, query params: ${JSON.stringify(queryData.queryParams)}`)
                const response = await ZGet(url, queryData.queryParams);

                const graphData: AnalyticsMetricData[] = response as AnalyticsMetricData[]
                this.props.setAnalyticsGraphData(graphData as AnalyticsMetricData[], false, 1 );
                this.handleRefreshTimeout();
            }

            catch(errStr) {
                const p = errStr as Promise<FetchError>;
                if (isFunction(p)) {
                    p.then((err) => {
                        console.log(`loadSelectedGraphData: fetch failed: ${err}`);
                        this.props.showNotification(NotificationStyles.danger, err.message);
                        this.closeNotification();
                        this.props.applySettings('serverError');
                    })
                } else {
                    logger.log(((p as unknown) as FetchError).message);
                }
            }
            
        } else {        // NO METRICS.  SAVE DUMMY DATA
            await this.fakeDataGet();
            const start = queryData.queryParams.start_epochmillis;
            const end = queryData.queryParams.end_epochmillis;

            const fakeData = queryData.blankMetrics.map(metric => {
                const fData = JSON.parse(emptyMetricDataJSON);
                fData.metric = metric;
                fData.data[0]["time_values"] = [{epochmillis: start, value: 0}, {epochmillis: end, value: 0}];
                return fData;
            })
            this.props.setAnalyticsGraphData(fakeData as AnalyticsMetricData[], false, 1 );
            this.handleRefreshTimeout();
        }
    }

    private handleRefreshTimeout(): void {
        let timeoutId = -1;
        if (this.props.analyticsUIState.analyticsTimeoutId !== -1) {
            window.clearInterval(this.props.analyticsUIState.analyticsTimeoutId);
        }

        if (isRefreshEnabled(this.props.analyticsUIState)) {
            const timeout = getAppliedAnalyticsTimeoutValue(this.props.analyticsUIState);
            timeoutId = window.setInterval(() => { this.handleRefresh(); }, timeout);
            this.props.setAnalyticsRefreshId(timeoutId);
        } else if (this.props.analyticsUIState.analyticsTimeoutId > 0) {
            this.props.setAnalyticsRefreshId(-1);
        }
    }

    // build charts from selected metrics
    private buildSelectedCharts(): JSX.Element[] {
        const systemState = this.props.systemNavProjectState;

        const myCharts = [];
        const selectedCharts: string[] = getSelectedMetrics(this.props.analyticsUIState, systemState.currentFeature)
        const chartData = this.props.analyticsUIState.graphData;
        if (selectedCharts && selectedCharts.length === 0 || chartData.length === 0) {
            return [];
        }

        const buildChart = (currentChartData: AnalyticsMetricData | undefined, index: number, css: string) => {
            if (!currentChartData) { 
                currentChartData = this.buildHibredChart(chartDataObj, selectedCharts[index])
            }

            let chartDiv = <div></div>;
            if (currentChartData) {
                const chart = (<Chart key={index} id={index} metric={ selectedCharts[index] } 
                graphData={ currentChartData } {...this.props}/>);
                chartDiv = (
                    <div className={css} key={'content' + index}>
                        {chart}
                    </div>
                )
            }
            return chartDiv;
        }

        const chartDataObj: ChartDataObj = {};
        for (let i = 0, len = chartData.length; i < len; i++) {
            chartDataObj[chartData[i].metric] = chartData[i];
        }
        const metricObj: TabMappingObj = getMetricsObjForTab(systemState.currentFeature);
        
        for (let i = 0, len = selectedCharts.length; i < len; i++) {
            let currentChartData: AnalyticsMetricData | undefined =  chartDataObj[selectedCharts[i]];
            if (!currentChartData) { 
                currentChartData = this.buildHibredChart(chartDataObj, selectedCharts[i])
            }
            if (currentChartData) {
                let metricUIData: MetricsUIDefinitions = metricObj[currentChartData.metric];
                let chartType: ChartTypes = (metricUIData && metricUIData.chartType) ? metricUIData.chartType as ChartTypes: ChartTypes.line;

                let chartDiv = <div key={'empty' + i}></div>;
                if (chartType === ChartTypes.line) {
                    const chart = buildChart( currentChartData, i, 'col-12');
                    myCharts.push((<div className="row" key={'containerRow'+i}>{chart}</div>));
                } else {
                    const chartList: JSX.Element[] = []
                    chartList.push(buildChart( currentChartData, i, 'col-6'));
                    
                    if (i+1 < chartData.length) {
                        currentChartData = chartDataObj[selectedCharts[i + 1]];
                        if (!currentChartData) { 
                            currentChartData = this.buildHibredChart(chartDataObj, selectedCharts[i])
                        }
                        
                        if (currentChartData) {
                            metricUIData = metricObj[currentChartData.metric];

                            chartType = (metricUIData && metricUIData.chartType) ? metricUIData.chartType as ChartTypes: ChartTypes.line;
                            if (chartType === ChartTypes.pie) {
                                chartList.push(buildChart( currentChartData, i + 1, 'col-6'));
                                i++;
                            }
                        }
                    }
                
                    chartDiv = (<div className="row" key={'containerRow'+i}>{chartList}</div>)
                }
                myCharts.push(chartDiv);
            }
        }

        return myCharts;
    }

    private buildHibredChart(chartDataObj: ChartDataObj, metricKey: string): AnalyticsMetricData | undefined {
        const metrics: string[] = metricKey.split(',');
        const chartDataMetrics = Object.keys(chartDataObj)
        if (chartDataMetrics.length === 0) {
            return undefined;
        }

        let newData: AnalyticsMetricData | undefined = {
            data: [],
            metric: metricKey,
            stats: ''
        }
        let foundAllMetrics = true;
        for (let i = 0, len = metrics.length; i < len; i++) {
            if (chartDataObj[metrics[i]] === undefined) {
                foundAllMetrics = false;
            }
        }

        if (foundAllMetrics) {
            for (let i = 0, len = metrics.length; i < len; i++) {
                const data: SvrMetricTimeData = chartDataObj[metrics[i]].data[0] as SvrMetricTimeData;
                let tag: SvrMetricTagEntry = data.tags as SvrMetricTagEntry;
                if (!tag) {
                    tag = {
                        "metric_name": '',
                        "origin_ip": '',
                        attack: '',
                        country: '',
                        bottype: '',
                        iaastype: '',
                        uricode: ''
                    }
                }
                tag["metric_name"] = metrics[i];
                data.tags = tag; 
                // chartDataObj[metrics[i]].data[0].tags.metric = 
                newData.data.push(data)
            }
        } else {
            newData = undefined;
        }

        return newData;
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
/*
    // build charts for EFR metrics
    private buildEFRCharts() {
        console.log('Analytics.buildEFRCharts: enter');
        const efrChartsData: EFRChartData = {
            client: { failure_pm: undefined, requests_pm: undefined, proc_time_ms: undefined},
            origin: { failure_pm: undefined, requests_pm: undefined, proc_time_ms: undefined},
        }

        const chartData = this.props.uiState.graphData;
        for (let i = 0, len = chartData.length; i < len; i++) {
            const serverData: AnalyticsMetricData = chartData[i];
            const tags = serverData.data[0].tags;       // making typescript happy
            const view = ( tags && tags.origin_ip.length === 0) ? 'client' : 'origin';
            efrChartsData[view][serverData.metric] = serverData; 
        }

        if (chartData.length !== 6) {
            console.log('buildEFRCharts: not all data back -- ', chartData.length);
            return '';
        }

        const origin = efrChartsData.origin;
        const client = efrChartsData.client;
        const markup = (
            <div className="container">
                <div className="row"> 
                    <div className="col-sm-6">{STRs.originOptionLabel}</div>
                    <div className="col-sm-6">{STRs.clientOptionLabel}</div>
                </div>
                <div className="row">
                    {this.buildEfrCell('failure_pm', origin.failure_pm, true, 1)}
                    {this.buildEfrCell('failure_pm', client.failure_pm, false, 2)}
                </div>
                <div className="row">
                    {this.buildEfrCell('requests_pm', origin.requests_pm, true, 3)}
                    {this.buildEfrCell('requests_pm', client.requests_pm, false, 4)}
                </div>
                <div className="row">
                    {this.buildEfrCell('proc_time_ms', origin.proc_time_ms, true, 5)}
                    {this.buildEfrCell('proc_time_ms', client.proc_time_ms, false, 6)}
                </div>
            </div>
        );

        return markup;
    }

    private buildEfrCell(metric: string, data: AnalyticsMetricData | undefined, isLeft: boolean, id: number) {
        const dummyData: AnalyticsMetricData = {
            data: [
                { tags: { metric_type: '', origin_ip: '' }, time_values: [] }
            ],
            metric: 'failure_pm',
            stats: '',
        }

        const css = 'col-sm-6 ' + ((isLeft) ? 'left' : 'right');
        return (
            <div className={css}>
            <Chart key={id.toString()} id={id} metric={metric}
                graphData={(data) ? data : {...dummyData, metric}
                } {...this.props}/>
            </div>
        )
    }
    */    

    // private updateMetricKeys() {
    //     const promise = this.loadOptions();
    //     if (typeof promise !== 'string') {
    //         promise.then(() => {
    //             console.log('[Analytics:updateMetricKeys] Option Data loaded');
    //         });
    //     } else {
    //         console.log('[Analytics:updateMetricKeys] - Error: ' + promise);
    //     }
    // }

    // ************** old UpdateData()
        // private updatePage(features: FeaturesConfig) {
        // const promise = this.loadOptions();
        // if (typeof promise !== 'string') {
        //     promise.then(() => {
        //         console.log('[Analytics:updatePage] Option Data loaded');
        //         const metricPromise = this.loadGraphData();
        //         metricPromise.then(() => {
        //             console.log('[Analytics:updatePage] Graph Data loaded');
        //         });
        //     });
        // } else {
        //     console.log('[Analytics:updatePage] - Error: ' + promise);
        // }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        analyticsUIState: state.analyticsUIState,
        systemNavProjectState: state.systemNavProjectState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(AnalyticCharting);