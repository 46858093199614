import { findIconDefinition, IconDefinition, IconLookup, IconName, IconProp, SizeProp } 
         from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { SysFeatureEnums } from '../reducers/reducerEnums';

import { NavLink } from 'react-router-dom';

export interface ZNavItemProps { 
    icon: IconName; 
    itemName: string;
    feature: SysFeatureEnums,
    handler: (feature: SysFeatureEnums) => void,
    
    hideOpenIcon?: boolean,
    spkey?: string;
    navUrl?: string, 
    iconSize?: string,
    selectedFeature?: SysFeatureEnums,
    featureSet?: SysFeatureEnums[],
}

// 'ZNavItemProps' describes the shape of props.
// State is never set so we use the '{}' type.
class ZNavItem extends React.Component<ZNavItemProps> {
    constructor(props: ZNavItemProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    public handleClick(): void {
        (this.props.handler)(this.props.feature);
    }

    public render(): JSX.Element {
        let selected = false;
        if (this.props.featureSet && this.props.selectedFeature) {
            selected = (this.props.featureSet.indexOf(this.props.selectedFeature) !== -1);
        } else {
            selected = this.props.selectedFeature === this.props.feature
        }

        let  icon = <span></span>;
        if (this.props.featureSet && !this.props.hideOpenIcon) {
            const featureOpenIcon = selected ? 'caret-down' : 'caret-right';
            icon = <FontAwesomeIcon  icon={featureOpenIcon as IconProp} />
        }
        const cssName: string = selected ? 'selected' : '';
        const path = (this.props.navUrl) ? this.props.navUrl : '';

        // Don't need to log this if things are working.
        // if (this.props.selected) {
        //     logger.log('ZNavItem.render:  User selected NavItem ' + this.props.itemName);
        // }

        const item = this.buildNavItem(cssName);
        const key = this.props.spkey ? this.props.spkey : this.props.feature as string;
        return (
            <div className="navItem" key={key}  onClick={() => { this.handleClick() }}>
                <NavLink to={path}>
                    {item}
                    <div className={cssName}>
                        <span>{this.props.itemName}</span><span>{icon}</span>
                    </div>
                </NavLink>
          </div>
        );
    }
    
    private buildNavItem(cssName: string) {
        const icnLookup: IconLookup = { prefix: 'fas', iconName: this.props.icon }
        const icnDefinition: IconDefinition = findIconDefinition(icnLookup)
        const size = this.props.iconSize !== undefined ? this.props.iconSize : '1x'
        return (
            <div className={cssName}>
                <FontAwesomeIcon icon={icnDefinition} size={size as SizeProp}/>
            </div>
        );
    }
}
export default ZNavItem;