import moment from 'moment';

import { AnalyticsMetricData, CachePurgeReqStatusExt, ProjectMetricKeyResponse, ProjectSolutions,
         ServerConfig, ZProjectMin, ZServiceFeatures, ZUserMin, ZProductFeatureAttributes,  ServerPlatforms,
         AggrDataMap, ZUserTypes, ZApiKey, ProjUriCode, ApiKeyPermission, Certificate, CsmLogsResponse,
         ZServiceVersion, DeploymentHistoryItem, RoutingRules, ZTfaSecret, ZServiceExt, MetricsDataV1Response,
         ResetTokenInfo, OrgInfo, CSAHistoryItem, OrgLimit, AvailableBillingReports, BillingOrgUsageData } 
            from '../data/queryResultDefinitions';

import { ZSessionStorage } from '../data/metricsAndOptionsDefs';
import { State as serverConfig, AuthenticationType } from '../reducers/authentication';
import { ProjectMetricDefinitions } from '../reducers/uiAccessors';
import { LogOptionsFieldErrorState, LogRowData } from '../reducers/logState';
import { HeaderFilterOpsIndexEnum, PwdResetState, SysFeatureEnums, ZFeaturePopupTypes, 
         ZAdminMapType, ZEnvironments, ZSortDirection, UsageUnits, MetricDataTypes, ZSortFields } 
        from '../reducers/reducerEnums';

import { NotificationStyles, ServiceTemplateName } from '../shared/constants';
import { ZCSMEntity } from '../data/queryResultDefinitions';

export enum ActionKeys {
    ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
    REDIRECT_TO = 'REDIRECT_TO',
    FEATURE_READY = 'FEATURE_READY',
    SET_PROJECTS_OLD = 'SET_PROJECTS_OLD',
    SET_PROJECTS = 'SET_PROJECTS',
    CHANGE_PROJECTS = 'CHANGE_PROJECTS',
    CHANGE_ENVIRONMENT = 'CHANGE_ENVIRONMENT',
    RESET_PROJECT = 'RESET_PROJECT',
    SESSION_STATE_SAVED = 'SESSION_STATE_SAVED',
    INIT_SESSION_DATA = 'INIT_SESSION_DATA',
    SET_PROJ_URI_CODES = 'SET_PROJ_URI_CODES',
    SET_ORG_LIST = 'SET_ORG_LIST',
    SET_CURRENT_ORG = 'SET_CURRENT_ORG',
    SET_ORG_LIST_IDX = 'SET_ORG_LIST_IDX',
    SET_NEW_ORG_NAME = 'SET_NEW_ORG_NAME',
    SET_ORG_DATA = 'SET_ORG_DATA',
    SET_ORG_LIMITS = 'SET_ORG_LIMITS',

    SET_NAV_ITEM = 'SET_NAV_ITEM',
    SET_HAMBURGER = 'SET_HAMBURGER',
    SET_ACCOUNT_TAB = 'SET_ACCOUNT_TAB',
    INIT_SERVER_ENV = 'INIT_SERVER_ENV',
    CHANGE_SERVER_FEATURE_ENV = 'CHANGE_SERVER_FEATURE_ENV',
    SAVE_SERVER_CONFIG_DATA = 'SAVE_SERVER_CONFIG_DATA',
    
    APPLY_SETTINGS = 'APPLY_SETTINGS',
    INIT_ANALYTIC_OPTIONS = 'INIT_ANALYTIC_OPTIONS',
    INIT_METRIC_CHKBOXES = 'INIT_METRIC_CHKBOXES',
    SET_METRIC_CHKBOX = 'SET_METRIC_CHKBOX',
    SET_CURRENT_PROJECT_KEYS = 'SET_CURRENT_PROJECT_KEYS',
    SET_ANALYTIC_OPTION = 'SET_ANALYTIC_OPTION',
    SET_ANALYTICS_GRAPH_DATA = 'SET_ANALYTICS_GRAPH_DATA',
    SET_ANALYTICS_MORE_DATA = 'SET_ANALYTICS_MORE_DATA',
    SET_ANALYTICS_REFRESH_ID = 'SET_ANALYTICS_REFRESH_ID',

    SET_CURRENT_SERVICE_EVT_IDX = 'SET_CURRENT_SERVICE_EVT_IDX',
    SET_QUERY_TIME_FORMAT = 'SET_QUERY_TIME_FORMAT',        // swap between absolute & rel time
    SET_FROM_TO_TIME = 'SET_FROM_TO_TIME',

    SET_AUTO_UPDATE = 'SET_AUTO_UPDATE',
    SET_URI_CODE_IDX = 'SET_URI_CODE_IDX',

    CACHE_INIT_OPTIONS = 'CACHE_INIT_OPTIONS',
    CACHE_CMD_STARTED = 'CACHE_CMD_STARTED',
    CACHE_CMD_COMPLETED = 'CACHE_CMD_COMPLETED',
    CACHE_SET_ROW_STATE = 'CACHE_SET_ROW_STATE',
    CACHE_INIT_MODAL = 'CACHE_INIT_MODAL',
    CACHE_SUBMIT_PURGE_REQUEST = 'CACHE_SUBMIT_PURGE_REQUEST',
    CACHE_CLOSE_MODAL = 'CACHE_CLOSE_MODAL',
    CACHE_SET_INTERVAL_ID = 'CACHE_SET_INTERVAL_ID',
    CACHE_REFRESH_CACHE_STATUS = 'CACHE_REFRESH_CACHE_STATUS',

    REFRESH_PAGE = 'REFRESH_PAGE',
    DATA_LOAD_IN_PROGRESS = 'DATA_LOAD_IN_PROGRESS',
    DATA_GROUP_LOAD = 'DATA_GROUP_LOAD',
    
    // login actions
    LOGIN_USER_START = 'LOGIN_USER_START',
    LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
    LOGIN_USER_FAIL = 'LOGIN_USER_FAIL',
    LOGIN_USER_TFA = 'LOGIN_USER_TFA',
    LOGOUT_USER = 'LOGOUT_USER',
    SET_USER_INFO = 'SET_USER_INFO',
    RESET_PASSWORD = 'RESET_PASSWORD',
    SET_PWD_RESET_STATE = 'SET_PWD_RESET_STATE',
    UPDATE_EMAIL_PWD = 'UPDATE_EMAIL_PWD',
    VALIDATE_PWD = 'VALIDATE_PWD',
    PWD_CHANGE_IN_PROGRESS = 'PWD_CHANGE_IN_PROGRESS',
    SET_PWD_EXPIRED_AUTH_TOKEN = 'SET_PWD_EXPIRED_AUTH_TOKEN',

    // 2FA support
    ENABLE_TFA_SUPPORT = 'ENABLE_TFA_SUPPORT',
    SET_TFA_GEN_INFO = 'SET_TFA_GEN_INFO',
    UPDATE_TFA_TOKEN = 'UPDATE_TFA_TOKEN',
    INIT_TFA_MGMT = 'INIT_TFA_MGMT',
    BEGIN_TFA_DEACTIVATE = 'BEGIN_TFA_DEACTIVATE',

    SET_PWD_RESET_TOKEN_INFO = 'SET_PWD_RESET_TOKEN_INFO',

    INIT_UPDATE_PROFILE = 'INIT_UPDATE_PROFILE',
    VALIDATE_PROFILE = 'VALIDATE_PROFILE',
    UPDATE_PROFILE_IN_PROGRESS = 'UPDATE_PROFILE_IN_PROGRESS',

    SET_SELECTED_DOCUMENTATION_ITEM = 'SET_SELECTED_DOCUMENTATION_ITEM',

    // log actions
    INIT_LOG_OPTIONS = 'INIT_LOG_OPTIONS',
    SET_LOGS_FROM_TO_TIME = 'SET_LOGS_FROM_TO_TIME',
    SET_LOGS_OPTION_DATA = 'SET_LOGS_OPTION_DATA',
    SET_LOG_OPTIONS_FIELD_STATE = 'SET_LOG_OPTIONS_FIELD_STATE',
    SET_VISIBILITY_LOG_FILTER_MODAL = 'SET_VISIBILITY_LOG_FILTER_MODAL',
    SET_HEADER_TYPE_IDX = 'SET_HEADER_TYPE_IDX',        // set header type when building new filter
    SET_HEADER_FILTER_DATA = 'SET_HEADER_FILTER_DATA',  // change editable filter data while building filter
    ADD_HEADER_FILTER = 'ADD_HEADER_FILTER',            // add the filter
    DELETE_HEADER_FILTER = 'DELETE_HEADER_FILTER',
    TOGGLE_HEADER_FILTER = 'TOGGLE_HEADER_FILTER',
    LOG_DATA_OBTAINED = 'LOG_DATA_OBTAINED',
    SET_LOG_ROW_STATE = 'SET_LOG_ROW_STATE',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',

    SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
    CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',

    SET_SVC_CONFIG_IDX = 'SET_SVC_CONFIG_IDX',

    // reports
    SET_REPORT_QUERY_TIME_FORMAT = 'SET_REPORT_QUERY_TIME_FORMAT',
    SET_REPORT_PERIOD_INDEX = 'SET_REPORT_PERIOD_INDEX',
    SET_REPORT_FROM_TO = 'SET_REPORT_FROM_TO',
    SET_REPORT_DATA = 'SET_REPORT_DATA',
    
    // admin
    SET_ADMIN_USER_LIST = 'SET_ADMIN_USER_LIST',
    SET_ADMIN_PROJECT_LIST = 'SET_ADMIN_PROJECT_LIST',
    DELETE_USER = 'DELETE_USER',
    SET_USER_LIST_IDX = 'SET_USER_LIST_IDX',
    SET_PROJECT_LIST_IDX = 'SET_PROJECT_LIST_IDX',
    SET_APIKEY_LIST_IDX = 'SET_APIKEY_LIST_IDX',
    SET_USER_PROJECT_LIST = 'SET_USER_PROJECT_LIST',
    SET_PROJECT_USER_LIST = 'SET_PROJECT_USER_LIST',
    SET_USER_APIKEY_LIST = 'SET_USER_APIKEY_LIST',
    TOGGLE_ADMIN_POPUP = 'TOGGLE_ADMIN_POPUP',
    TOGGLE_ADMIN_POPUP_CHECKMARK = 'TOGGLE_ADMIN_POPUP_CHECKMARK',
    SET_USER_ROLE = 'SET_USER_ROLE',
    ADD_USER_TO_ALL_PROJECTS = 'ADD_USER_TO_ALL_PROJECTS',
    TOGGLE_SHOW_EXPIRED_API_KEYS = 'TOGGLE_SHOW_EXPIRED_API_KEYS',
    SET_API_KEY_STATE = 'SET_API_KEY_STATE',
    SET_PROJECT_NAME = 'SET_PROJECT_NAME',
    SET_PROJECT_SOLUTIONS = 'SET_PROJECT_SOLUTIONS',
    SET_SYSTEM_PROJECT_SOLUTIONS = 'SET_SYSTEM_PROJECT_SOLUTIONS',
    SET_SELECTED_PROJECT_SOLUTIONS = 'SET_SELECTED_PROJECT_SOLUTIONS',
    
    // admin - certs
    TOGGLE_ADMIN_SELECTION = 'TOGGLE_ADMIN_SELECTION',
    SET_PROJECT_CERT_LIST = 'SET_PROJECT_CERT_LIST',
    SET_CERT_LIST_IDX = 'SET_CERT_LIST_IDX',
    TOGGLE_CERT_DETAILS = 'TOGGLE_CERT_DETAILS',

    // service management
    SET_SERVICE_VERSION_LIST = 'SET_SERVICE_VERSION_LIST',
    SET_SELECTED_SERVICE_DETAIL = 'SET_SELECTED_SERVICE_DETAIL',
    TOGGLE_SERVICE_POPUP = 'TOGGLE_SERVICE_POPUP',
    SET_SERVICE_OVERVIEW_DETAILS = 'SET_SERVICE_OVERVIEW_DETAILS',
    SET_SERVICE_ORIGIN_DETAILS = 'SET_SERVICE_ORIGIN_DETAILS',
    SET_SERVICE_DNS_DIRECTOR_DETAILS = 'SET_SERVICE_DNS_DIRECTOR_DETAILS',
    SET_SERVICE_HC_DATA = 'SET_SERVICE_HC_DATA',
    ADD_SERVICE_ORIGIN = 'ADD_SERVICE_ORIGIN',
    SET_DEPLOYMENT_HISTORY = 'SET_DEPLOYMENT_HISTORY',
    SET_PROJECT_SERVICES = 'SET_PROJECT_SERVICES',
    RELOAD_SERVICES = 'RELOAD_SERVICES',
    CREATE_NEW_SERVICE = 'CREATE_NEW_SERVICE',
    DELETE_SERVICE = 'DELETE_SERVICE',
    SET_DEPLOYMENT_HISTORY_VIEW_IDX = 'SET_DEPLOYMENT_HISTORY_VIEW_IDX',
    SET_DEPLOYMENT_DESTINATION_IDX = 'SET_DEPLOYMENT_DESTINATION_IDX',
    SET_DEPLOYMENT_VERSION_IDX = 'SET_DEPLOYMENT_VERSION_IDX',
    SET_UNSAFE_PROMOTE_STATE = 'SET_UNSAFE_PROMOTE_STATE',
    SET_SERVICE_OPTION_IDX = 'SET_SERVICE_OPTION_IDX',
    DISCARD_SERVICE_CHANGES = 'DISCARD_SERVICE_CHANGES',
    SET_ROUTING_RULE = 'SET_ROUTINE_RULE',
    DRAFT_SAVED = 'DRAFT_SAVED',
    INIT_EDIT_SERVICE = 'INIT_EDIT_SERVICE',
    SET_SERVICE_CREATION_TEMPLATE = 'SET_SERVICE_CREATION_TEMPLATE',
    SET_PROJECT_PLATFORMS = 'SET_PROJECT_PLATFORMS',
    SET_PLATFORM_IDX = 'SET_PLATFORM_IDX',
    SET_PROJECT_FEATURES_ATTRIBUTES = 'SET_PROJECT_FEATURES_ATTRIBUTES',
    INIT_CSA_LITE = 'INIT_CSA_LITE',
    CSA_FILE_VERIFIED = 'CSA_FILE_VERIFIED',
    SET_CSA_FILE = 'SET_CSA_FILE',
    SET_CSA_JOB_LIST = 'SET_CSA_JOB_LIST',
    SHOW_ERRORS = 'SHOW_ERRORS',

    CONTROL_MODAL = 'CONTROL_MODAL',
    SET_INTERVAL_ID = 'SET_INTERVAL_ID',
    INIT_FEATURE = 'INIT_FEATURE',
    SORT_TABLE = 'SORT_TABLE',

    // Billing
    SET_BILLING_REPORTS_DATA = 'SET_AVAILABLE_BILLING_REPORTS_LIST',
    SET_PROJECT_TRAFFIC_USAGE = 'SET_PROJECT_TRAFFIC_USAGE',
    TOGGLE_BILLING_SHOW_SERVICES = 'TOGGLE_BILLING_SHOW_SERVICES',
    TOGGLE_BILLING_PROJECT_SERVICES = 'TOGGLE_BILLING_PROJECT_SERVICES',
    SET_BILLING_UNITS = 'SET_BILLING_UNITS',
    SET_BILLING_MONTH_INDEX = 'SET_BILLING_MONTH_INDEX',
}

export interface ARouterLocationChange {
    readonly type: ActionKeys.ROUTER_LOCATION_CHANGE;
    readonly payload: {
        pathname: string;
    };
}

// Initialize the server features environment
export interface AInitServerFeatureEnv {
    readonly type:  ActionKeys.INIT_SERVER_ENV;
    readonly payload: {
        readonly featuresEnv: serverConfig;
    };
}

// Change feature Environment based on project type
export interface AChangeServerFeatureEnv {
    readonly type:  ActionKeys.CHANGE_SERVER_FEATURE_ENV;
    readonly payload: {
        readonly projectType: string;
    };
}

export interface AFeatureReady {
    readonly type: ActionKeys.FEATURE_READY;
}

// store the server config data
export interface ASaveServerConfigData {
    readonly type:  ActionKeys.SAVE_SERVER_CONFIG_DATA;
    readonly payload: {
        readonly config: ServerConfig;
    };
}


// set projects list
export interface ASetProjects {
    readonly type:  ActionKeys.SET_PROJECTS;
    readonly payload: {
        readonly projects: ZProjectMin[];
        readonly projectId?: string;
    };
}

// change project
export interface  AChangeProjects {
    readonly type:  ActionKeys.CHANGE_PROJECTS;
    readonly payload: {
        readonly projectId: string;
        readonly environment?: ZEnvironments;
    };
}

export interface ASetProjURICodes {
    readonly type: ActionKeys.SET_PROJ_URI_CODES;
    readonly payload: {
        projUriCode: ProjUriCode[];
    };
}

// reset project
export interface  AResetProjects {
    readonly type:  ActionKeys.RESET_PROJECT;
    readonly payload: {
        readonly projectId: string;
    };
}

export interface ALoginUserStart {
    readonly type:  ActionKeys.LOGIN_USER_START;     
}

export interface ALoginUserTFA {
    readonly type:  ActionKeys.LOGIN_USER_TFA;     
}

export interface ALoginUserFail {
    readonly type:  ActionKeys.LOGIN_USER_FAIL;
    readonly payload: {
        errorMsg: string;
    };
}

export interface ALoginUserSuccess {
    readonly type:  ActionKeys.LOGIN_USER_SUCCESS;
}

export interface ASetUserInfo {
    readonly type: ActionKeys.SET_USER_INFO;
    readonly payload: {
        user: ZUserMin;
    };
}

export interface ALogoutUser {
    readonly type:  ActionKeys.LOGOUT_USER;
}

export interface ASetResetPasswordState {
    readonly type:  ActionKeys.SET_PWD_RESET_STATE;
    readonly payload: {
        pwdResetState: PwdResetState;
    };
}

export interface AResetPassword {
    readonly type: ActionKeys.RESET_PASSWORD;
    readonly payload: {
        success: boolean;
    };
}

export interface AValidatePassword {
    readonly type: ActionKeys.VALIDATE_PWD;
    readonly payload: {
        oldPwd: string;
        newPwd: string;
        confirmPwd: string;
    };
}

export interface AUpdateEmailPassword {
    readonly type: ActionKeys.UPDATE_EMAIL_PWD;
    readonly payload: {
        email: string;
        password: string;
    };
}
export interface ALocationChange {
    readonly type:  ActionKeys.ROUTER_LOCATION_CHANGE;
    readonly payload: {
        pathname: string;
    };
}

export interface ASetNavItem {
    readonly type:  ActionKeys.SET_NAV_ITEM;
    readonly payload: {
        navItem: string;
        feature: SysFeatureEnums;
    };
}

export interface ASetAccountTab {
    readonly type:  ActionKeys.SET_ACCOUNT_TAB;
    readonly payload: {
        navItem: string;
        feature: SysFeatureEnums;
    };
}

// inits the checkbox state for all analytics checkboxes for all tabs (based on project features)
export interface AInitMetricCheckBoxes {
    readonly type:  ActionKeys.INIT_METRIC_CHKBOXES;
    readonly payload: {
        checkboxData: ProjectMetricDefinitions;
    };
}

export interface ASetMetricCheckBox {
    readonly type:  ActionKeys.SET_METRIC_CHKBOX;
    readonly payload: {
        metricKey: string;
        value: boolean;
    };
}

export interface AInitAnalyticOptions {
    readonly type:  ActionKeys.INIT_ANALYTIC_OPTIONS;
    readonly payload: {
        fullInit: boolean;
        features: ZServiceFeatures;
        optionKeys: ProjectMetricKeyResponse;
        analyticFeature: SysFeatureEnums;
    };
}

export interface ASetCurrentProjectKeys {
    readonly type:  ActionKeys.SET_CURRENT_PROJECT_KEYS;
    readonly payload: {
        optionKeys: ProjectMetricKeyResponse;
    };
}

export interface ASetAnalyticOption {
    readonly type:  ActionKeys.SET_ANALYTIC_OPTION;
    readonly payload: {
        optionName: string;
        value: number;
    };
}

export interface OptionalApplyArgs {
    project_id?: string,
    service_id?: string,
    reloadCerts?: boolean,
}

export interface AApplySettings {
    readonly type:  ActionKeys.APPLY_SETTINGS;
    readonly payload: { 
        error?: string;
        extraData?: string;
        optionalArgs?: OptionalApplyArgs;
    };
}

export interface ASetAnalyticsGraphData {
    readonly type:  ActionKeys.SET_ANALYTICS_GRAPH_DATA;
    readonly payload: { 
        appendResults: boolean;
        graphData: AnalyticsMetricData[] | MetricsDataV1Response;
        totalItems: number;
        dataType?: MetricDataTypes;
    };
}

export interface ASetAnalyticsMoreData {
    readonly type: ActionKeys.SET_ANALYTICS_MORE_DATA,
    readonly payload: { 
        currentFeature: SysFeatureEnums, 
        type: string, 
        data: CsmLogsResponse 
    },
}

export interface ASetAnalyticsRefreshId {
    readonly type: ActionKeys.SET_ANALYTICS_REFRESH_ID;
    readonly payload: {
        timeoutId: number;
    };
}

export interface ARefreshPage {
    readonly type:  ActionKeys.REFRESH_PAGE;
}

export interface ADataLoadInProgress {
    readonly type: ActionKeys.DATA_LOAD_IN_PROGRESS;
    payload: {
        feature: string;
    };
}

export interface ADataGroupLoad {
    readonly type: ActionKeys.DATA_GROUP_LOAD;
    readonly payload: {
        inProgress: boolean;
        feature: string;
    }
}
export interface ASetCurrentServiceEvtIdx {
    readonly type:  ActionKeys.SET_CURRENT_SERVICE_EVT_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetUriCodeIdx {
    readonly type: ActionKeys.SET_URI_CODE_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetQueryTimeFormat {
    readonly type: ActionKeys.SET_QUERY_TIME_FORMAT;
    readonly payload: {
        relativeTime: boolean;
        component: string;
    };
}

export interface ASetFromToTime {
    readonly type: ActionKeys.SET_FROM_TO_TIME;
    readonly payload: {
        from: moment.Moment;
        to: moment.Moment;
    };
}

export interface ASetLogsFromToTime {
    readonly type: ActionKeys.SET_LOGS_FROM_TO_TIME;
    readonly payload: {
        from: moment.Moment;
        to: moment.Moment;
        invalidTime: boolean,
        relTimeIdx: number
    };
}

export interface ASetLogsOptionData {
    readonly type: ActionKeys.SET_LOGS_OPTION_DATA;
    readonly payload: {
        assetURL: string;
        clientIP: string;
        originIP: string;
        responseCode: string;
        requestMethodIdx: number;
    };
}

export interface AShowNotification {
    readonly type: ActionKeys.SHOW_NOTIFICATION;
    readonly payload: {
        style: NotificationStyles;
        message: string;
    };
}

export interface ACloseNotification {
    readonly type: ActionKeys.CLOSE_NOTIFICATION;
}

export interface AInitLogOptions {
    readonly type: ActionKeys.INIT_LOG_OPTIONS;
}

export interface ASetLogOptionsFieldState {
    readonly type: ActionKeys.SET_LOG_OPTIONS_FIELD_STATE;
    readonly payload: {
        errorState: LogOptionsFieldErrorState;
    };
}

export interface ASetVisibilityLogFilterModal {
    readonly type: ActionKeys.SET_VISIBILITY_LOG_FILTER_MODAL;
    readonly payload: {
        showModal: boolean;
    };
}

export interface ASetHeaderTypeIndex {
    readonly type: ActionKeys.SET_HEADER_TYPE_IDX;
    readonly payload: {
        idx: number;
    };
}

export interface ASetHeaderFilterData {
    readonly type: ActionKeys.SET_HEADER_FILTER_DATA;
    readonly payload: {
        filterOps: HeaderFilterOpsIndexEnum;
        filterName: string;
        filterValue: string;
    };
}

export interface AAddHeaderFilter {
    readonly type: ActionKeys.ADD_HEADER_FILTER;
    readonly payload: {
        isRequestHeader: boolean;
        filterOps: HeaderFilterOpsIndexEnum;
        filterName: string;
        filterValue: string;
    };
}

export interface ADeleteHeaderFilter {
    readonly type: ActionKeys.DELETE_HEADER_FILTER;
    readonly payload: {
        index: number;
    };
}

export interface AToggleHeaderFilter {
    readonly type: ActionKeys.TOGGLE_HEADER_FILTER;
    readonly payload: {
        index: number;
    };
}

export interface ALogDataObtained {
    readonly type: ActionKeys.LOG_DATA_OBTAINED;
    readonly payload: {
        status: boolean;
        logRowData: LogRowData[];
        totals: number;
    };
}

export interface ASetLogRowState {
    readonly type: ActionKeys.SET_LOG_ROW_STATE;
    readonly payload: {
        rowIdx: number;
        rowOpen: boolean;
        showReqHeader: boolean;
        showRespHeader: boolean;
    };
}

export interface ASetCurrentPage {
    readonly type: ActionKeys.SET_CURRENT_PAGE;
    readonly payload: {
        component: string,
        pageNum: number;
    };
}

export interface ASetSvcConfigIdx {
    readonly type: ActionKeys.SET_SVC_CONFIG_IDX;
    readonly payload: {
        idx: number;
    };
}

export interface ACacheCmdStarted {
    readonly type: ActionKeys.CACHE_CMD_STARTED;
}

export interface ACacheCmdCompleted {
    readonly type: ActionKeys.CACHE_CMD_COMPLETED;
    readonly payload: {
        data: CachePurgeReqStatusExt[] | undefined;
        success: boolean;
        msg: string;
    };
}

export interface  ACacheInitOptions {
    readonly type: ActionKeys.CACHE_INIT_OPTIONS;
    readonly payload: {
        environment: ZEnvironments;
        serviceId: string;
    };
}

export interface ASetCacheRowState {
    readonly type: ActionKeys.CACHE_SET_ROW_STATE;
    readonly payload: {
        rowNum: number;
    };
}

export interface ACacheCloseModal {
    readonly type: ActionKeys.CACHE_CLOSE_MODAL;
}

export interface ACacheInitModal {
    readonly type: ActionKeys.CACHE_INIT_MODAL;
    readonly payload: {
        uriCodes: ProjUriCode[]
    }
}

export interface ASubmitCachePurgeRequest {
    readonly type: ActionKeys.CACHE_SUBMIT_PURGE_REQUEST;
}

export interface ASetCacheSetIntervalId {
    readonly type: ActionKeys.CACHE_SET_INTERVAL_ID;
    readonly payload: {
        intervalId: number;
    };
}

export interface ARefreshCacheStatus {
    readonly type: ActionKeys.CACHE_REFRESH_CACHE_STATUS;
}

export interface APwdChangeInProgress {
    readonly type: ActionKeys.PWD_CHANGE_IN_PROGRESS;
    readonly payload: {
        inProgress: boolean;
    };
}

export interface ASetPwdExpiredAuthToken {
    readonly type: ActionKeys.SET_PWD_EXPIRED_AUTH_TOKEN;
    readonly payload: {
        authToken: string;
    };
}

export interface AInitUpdateProfile {
    readonly type: ActionKeys.INIT_UPDATE_PROFILE;
}

export interface AValidateProfile {
    readonly type: ActionKeys.VALIDATE_PROFILE;
    readonly payload: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
    };
}

export interface AUpdateProfileInProgress {
    readonly type: ActionKeys.UPDATE_PROFILE_IN_PROGRESS;
    readonly payload: {
        inProgress: boolean;
        updateSucceeded: boolean;
    };
}

export interface ASetSelectedDocumentationItem {
    readonly type: ActionKeys.SET_SELECTED_DOCUMENTATION_ITEM;
    readonly payload: {
        navItem: number;
        subNavItem: number;
    };
}

export interface AChangeEnvironment {
    readonly type: ActionKeys.CHANGE_ENVIRONMENT;
    readonly payload: {
        environment: ZEnvironments;
    };
}

export interface ASetAdminUserList {
    readonly type: ActionKeys.SET_ADMIN_USER_LIST;
    readonly payload: {
        userList: ZUserMin[];
        showZAdminRole: boolean;
    };
}

export interface ADeleteUser {
        readonly type: ActionKeys.DELETE_USER;
        readonly payload: {
            userId: string;
        };
}

export interface ASetAdminProjectList {
    readonly type: ActionKeys.SET_ADMIN_PROJECT_LIST;
    readonly payload: {
        projectList: ZProjectMin[];
    };
}

export interface ASetUserListIndex {
    readonly type: ActionKeys.SET_USER_LIST_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetApiKeyListIndex {
    readonly type: ActionKeys.SET_APIKEY_LIST_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetProjectListIndex {
    readonly type: ActionKeys.SET_PROJECT_LIST_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetUserProjectList {
    readonly type: ActionKeys.SET_USER_PROJECT_LIST;
    readonly payload: {
        projectList: ZProjectMin[];
    };
}

export interface ASetProjectUserList {
    readonly type: ActionKeys.SET_PROJECT_USER_LIST;
    readonly payload: {
        userList: ZUserMin[];
    };
}

export interface ASetUserApiKeyList {
    readonly type: ActionKeys.SET_USER_APIKEY_LIST;
    readonly payload: {
        apikeyList: ZApiKey[];
    };
}
export interface AToggleAdminPopup {
    readonly type: ActionKeys.TOGGLE_ADMIN_POPUP;
    readonly payload: {
        popupType: ZFeaturePopupTypes;
        popupOpen: boolean;
        value?: number | string | ZApiKey;             // currently only used for edit api key.
    };
}

export interface AToggleAdminSelection {
    readonly type: ActionKeys.TOGGLE_ADMIN_SELECTION;
    readonly payload: {
        collectionName: ZAdminMapType;
        id: string;
    };
}

export interface AToggleAdminPopupCheckmark {
    readonly type: ActionKeys.TOGGLE_ADMIN_POPUP_CHECKMARK;
    readonly payload: {
        index: number;
    };
}

export interface AToggleShowExpiredApiKey {
    readonly type: ActionKeys.TOGGLE_SHOW_EXPIRED_API_KEYS;
}

export interface ASetApiKeyState {
    readonly type: ActionKeys.SET_API_KEY_STATE;
    readonly payload: {
        friendlyName: string;
        expirationDate: moment.Moment;
        permission?: ApiKeyPermission;
    };
}

export interface ASetUserRole {
    readonly type: ActionKeys.SET_USER_ROLE;
    readonly payload: {
        role: ZUserTypes;
    };
}

export interface AAddUserToAllProjects {
    readonly type: ActionKeys.ADD_USER_TO_ALL_PROJECTS,
    readonly payload: {
        addToAllProjects: boolean
    }
}

export interface ASetReportQueryTimeFormat {
    readonly type: ActionKeys.SET_REPORT_QUERY_TIME_FORMAT;
    readonly payload: {
        showingRelativeTime: boolean;
    };
}

export interface ASetReportPeriodIndex {
    readonly type: ActionKeys.SET_REPORT_PERIOD_INDEX;
    readonly payload: {
        periodIndex: number;
    };
}

export interface ASetReportFromTo {
    readonly type: ActionKeys.SET_REPORT_FROM_TO;
    readonly payload: {
        to: moment.Moment;
        from: moment.Moment;
    };
}

export interface ASetReportData {   
    readonly type: ActionKeys.SET_REPORT_DATA;
    readonly payload: {
        reportType: string;
        data: AggrDataMap[];
        serviceIds: string;
        serviceNames: string; 
        env: string;
    };
}

export interface ASetHamburger {
    readonly type: ActionKeys.SET_HAMBURGER;
    readonly payload: {
        isOpen: boolean;
    };
}

export interface ASessionStateSaved {
    readonly type: ActionKeys.SESSION_STATE_SAVED;
}

export interface AInitSessionData {
    readonly type: ActionKeys.INIT_SESSION_DATA;
}

export interface ASetProjectCertList {
    readonly type: ActionKeys.SET_PROJECT_CERT_LIST;
    readonly payload: {
        certList: Certificate[];
        projectId: string;
    };
}

export interface ASetCertListIndex {
    readonly type: ActionKeys.SET_CERT_LIST_IDX;
    readonly payload: {
        index: number;
    };
}

export interface AToggleCertDetails {
    readonly type: ActionKeys.TOGGLE_CERT_DETAILS;
    readonly payload: {
        index: number;
    };
}

export interface ASetServiceVersionList {
    readonly type: ActionKeys.SET_SERVICE_VERSION_LIST;
    readonly payload: {
        svcVersions: ZServiceVersion[];
    };
}

export interface ASetSelectedServiceDetail {
    readonly type: ActionKeys.SET_SELECTED_SERVICE_DETAIL;
    readonly payload: {
        serviceConfig: ZServiceExt;
        isEditable?: boolean;
        serviceConfigProd?: ZServiceExt;
    };
}

export interface AToggleServicePopup {
    readonly type: ActionKeys.TOGGLE_SERVICE_POPUP;
    readonly payload: {
        popupType: ZFeaturePopupTypes;
        popupOpen: boolean;
    };
}

export interface ASetServiceOverviewDetails {
    readonly type: ActionKeys.SET_SERVICE_OVERVIEW_DETAILS;
    readonly payload: {
        serviceName: string;
        domainName: string;
        certListIdx?: number;
        enableHTTP2?: boolean;
        enableHTTPSRedirect?: boolean;
        domainsRedirect?: string;
    };
}

export interface ASetServiceDnsDirectorDetails {
    readonly type: ActionKeys.SET_SERVICE_DNS_DIRECTOR_DETAILS;
    readonly payload: {
        ddDomainName: string;
        zyTrafficPercentage: string;
        cdnTag: string;
        autoSwitchEnabled: boolean;
        useCdnAsOriginEnabled: boolean;
    };
}

export interface ASetServiceHCData {
    readonly type: ActionKeys.SET_SERVICE_HC_DATA,
    readonly payload: {
        healthChecks: ZCSMEntity[]
    }
}

export interface ASetServiceOriginDetails {
    readonly type: ActionKeys.SET_SERVICE_ORIGIN_DETAILS;
    readonly payload: {
        originName: string;
        originDomainName: string;
        tlsEnabled: boolean;
        sni: string;
    };
}

export interface AAddServiceOrigin {
    readonly type: ActionKeys.ADD_SERVICE_ORIGIN;
    readonly payload: {
        originName: string;
        originDomainName: string;
        tlsEnabled: boolean;
        sni: string;
    };
}

export interface ASetDeploymentHistory {
    readonly type: ActionKeys.SET_DEPLOYMENT_HISTORY;
    readonly payload: {
        deploymentHistory: DeploymentHistoryItem[];
    };
}

export interface ASetProjectServices {
    readonly type: ActionKeys.SET_PROJECT_SERVICES;
    readonly payload: {
        projectId: string;
        serviceList: ZServiceExt[];
        env?: ZEnvironments;
        serviceId?: string;
    };
}

export interface ASetProjectSolutions {
    readonly type: ActionKeys.SET_PROJECT_SOLUTIONS;
    readonly payload: {
        solutions: string[]
    }
}

export interface ASetSystemProjectSolutions {
    readonly type: ActionKeys.SET_SYSTEM_PROJECT_SOLUTIONS;
    readonly payload: {
        solutions: ProjectSolutions[]
    }
}

export interface ASetSelectedProjectSolutions {
    readonly type: ActionKeys.SET_SELECTED_PROJECT_SOLUTIONS;
    readonly payload: {
        solution: string,
        isSelected: boolean,
    }
}

export interface AReloadServices {
    readonly type: ActionKeys.RELOAD_SERVICES;
}

export interface ACreateNewService {
    readonly type: ActionKeys.CREATE_NEW_SERVICE;
    readonly payload: {
        service: ZServiceExt;
    };
}

export interface ADeleteService {
    readonly type: ActionKeys.DELETE_SERVICE;
    readonly payload: {
        serviceId: string
    }
}

export interface ASetDeploymentHistoryViewIndex {
    readonly type: ActionKeys.SET_DEPLOYMENT_HISTORY_VIEW_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetDeploymentDestinationIndex {
    readonly type: ActionKeys.SET_DEPLOYMENT_DESTINATION_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetDeploymentVersionIndex {
    readonly type: ActionKeys.SET_DEPLOYMENT_VERSION_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ASetUnsafePromoteState {
    readonly type: ActionKeys.SET_UNSAFE_PROMOTE_STATE;
    readonly payload: {
        unsafePromoteCheckState: boolean,
        unsafePromoteNeedConfirmation: boolean,
        unsafeCertNameMismatch: boolean,
    }
}

export interface ASetServiceOptionIndex {
    readonly type: ActionKeys.SET_SERVICE_OPTION_IDX;
    readonly payload: {
        index: number;
    };
}

export interface ADiscardServiceChanges {
    readonly type: ActionKeys.DISCARD_SERVICE_CHANGES;
}

export interface ADraftSaved {
    readonly type: ActionKeys.DRAFT_SAVED;
    readonly payload: {
        svc: ZServiceExt
    }
}

export interface AInitEditService {
    readonly type: ActionKeys.INIT_EDIT_SERVICE;
    readonly payload: {
        sessionStorage: ZSessionStorage;
    };
}

export interface ASetServiceCreationTemplate {
    readonly type: ActionKeys.SET_SERVICE_CREATION_TEMPLATE;
    readonly payload: {
        templateName: ServiceTemplateName
    }
}

export interface ASetProjectPlatforms {
    readonly type: ActionKeys.SET_PROJECT_PLATFORMS,
    readonly payload: {
        platforms: ServerPlatforms
    }
}

export interface ASetPlatformIdx {
    readonly type: ActionKeys.SET_PLATFORM_IDX,
    readonly payload: {
        platformIdx: number
    }
}
export interface ASetProjectFeaturesAttributes {
    readonly type: ActionKeys.SET_PROJECT_FEATURES_ATTRIBUTES
    readonly payload: {
        svcFeatureAttributes: ZProductFeatureAttributes
    }
}

export interface ASetRoutingRule {
    readonly type: ActionKeys.SET_ROUTING_RULE;
    readonly payload: {
        rule: RoutingRules;
    };
}

export interface AEnableTFASupport {
    readonly type: ActionKeys.ENABLE_TFA_SUPPORT;
    readonly payload: {
        enabled: boolean;
    };
}

export interface ASetTFAGenInfo {
    readonly type: ActionKeys.SET_TFA_GEN_INFO;
    readonly payload: {
        tfaGenInfo: ZTfaSecret;
    };
}

export interface ASetTFAToken {
    readonly type: ActionKeys.UPDATE_TFA_TOKEN;
    readonly payload: {
        token: string;
    };
}

export interface AInitTFAMgmt {
    readonly type: ActionKeys.INIT_TFA_MGMT;
}

export interface ABeginTFADeactivate {
    readonly type: ActionKeys.BEGIN_TFA_DEACTIVATE;
}

export interface ASetPwdResetTokenInfo {
    readonly type: ActionKeys.SET_PWD_RESET_TOKEN_INFO;
    readonly payload: {
        authenticationType: AuthenticationType;
        token: string;
        tokenStatus: ResetTokenInfo;
    };
}

export interface ASetOrgList {
    readonly type: ActionKeys.SET_ORG_LIST;
    readonly payload: {
        orgList: OrgInfo[];
        selectedOrgName?: string;
    };
}

export interface ASetCurrentOrg {
    readonly type: ActionKeys.SET_CURRENT_ORG;
    readonly payload: {
        orgId: string;
    };
}

export interface ASetOrgListIdx {
    readonly type: ActionKeys.SET_ORG_LIST_IDX;
    readonly payload: {
        orgIndex: number;
    };
}

export interface ARedirectTo {
    readonly type: ActionKeys.REDIRECT_TO,
    readonly payload: {
        url: string;
    }
}

export interface AInitCSALite {
    readonly type: ActionKeys.INIT_CSA_LITE
}

export interface AControlModalDlg {
    readonly type: ActionKeys.CONTROL_MODAL,
    readonly payload: {
        feature: SysFeatureEnums,
        setOpen: boolean
        dialogName?: ZFeaturePopupTypes,
    }
}

export interface ASetCSAFile {
    readonly type: ActionKeys.SET_CSA_FILE,
    readonly payload: {
        selectedFile: FormData
    }
}

export interface ASetCSAJobList {
    readonly type: ActionKeys.SET_CSA_JOB_LIST,
    readonly payload: {
        csaJobList: CSAHistoryItem[]
    }
}

export interface ASetIntervalId {
    readonly type: ActionKeys.SET_INTERVAL_ID,
    readonly payload: {
        intervalId: number
    }
}

export interface ASetProjectName {
    readonly type: ActionKeys.SET_PROJECT_NAME,
    readonly payload: {
        projectName: string
    }
}

export interface ASetOrgName {
    readonly type: ActionKeys.SET_NEW_ORG_NAME,
    readonly payload: {
        orgName: string
    }
}

export interface ASetOrgData {
    readonly type: ActionKeys.SET_ORG_DATA,
    readonly payload: {
        orgData: OrgInfo
    }
}

export interface ASetOrgLimits {
    readonly type: ActionKeys.SET_ORG_LIMITS,
    readonly payload: {
        limits: OrgLimit[]
    }
}

export interface AInitFeature {
    readonly type: ActionKeys.INIT_FEATURE,
    readonly payload: {
        feature: SysFeatureEnums
    }
}

export interface ASortTable {
    readonly type: ActionKeys.SORT_TABLE,
    readonly payload: {
        feature: SysFeatureEnums,
        tableName: string,
        fieldName: string | ZSortFields,
        direction: ZSortDirection
    }
}

export interface ASetBillingReportData {
    readonly type: ActionKeys.SET_BILLING_REPORTS_DATA,
    readonly payload: {
        availableBillingReports: AvailableBillingReports
    }
}

export interface ASetProjectTrafficUsage {
    readonly type: ActionKeys.SET_PROJECT_TRAFFIC_USAGE,
    readonly payload: {
        billingUsageData: BillingOrgUsageData,
        includesServices: boolean,
    }
}

export interface AToggleBillingShowServices {
    readonly type: ActionKeys.TOGGLE_BILLING_SHOW_SERVICES,
    readonly payload: {
        showingServices: boolean
    }

}

export interface AToggleBillingShowProjectServices {
    readonly type: ActionKeys.TOGGLE_BILLING_PROJECT_SERVICES,
    readonly payload: {
        projectIndex: number
    }
}

export interface ASetBillingUnits {
    readonly type: ActionKeys.SET_BILLING_UNITS,
    readonly payload: {
        units: UsageUnits
    }
}

export interface ASetBillingMonthIndex {
    readonly type: ActionKeys.SET_BILLING_MONTH_INDEX,
    readonly payload: {
        index: number
    }
}

export interface AShowErrors {
    readonly type: ActionKeys.SHOW_ERRORS,
    readonly payload: {
        feature: string,
        showErrors: boolean,
    }
}

export type actionTypes = 
    | ARedirectTo
    | AFeatureReady
    | ARouterLocationChange
    | ALoginUserSuccess
    | ALoginUserFail
    | ALoginUserStart
    | ASetUserInfo
    | ALogoutUser
    | AValidatePassword
    | AUpdateEmailPassword
    | ASetResetPasswordState
    | AResetPassword
    | AChangeServerFeatureEnv
    | ASaveServerConfigData
    | AInitServerFeatureEnv
    | AChangeProjects
    | ASetProjURICodes
    | AResetProjects
    | ASetProjects
    | ALocationChange
    | ASetHamburger
    | ASetNavItem
    | ASetAccountTab 
    | AInitMetricCheckBoxes
    | AInitAnalyticOptions
    | ASetMetricCheckBox
    | ASetAnalyticOption
    | ASetAnalyticsRefreshId
    | ASetAnalyticsGraphData
    | ASetAnalyticsMoreData
    | ASetCurrentProjectKeys
    | ARefreshPage
    | ADataLoadInProgress
    | ADataGroupLoad
    | ASetUriCodeIdx
    | ASetCurrentServiceEvtIdx
    | ASetQueryTimeFormat
    | ASetFromToTime
    | ASetLogsFromToTime
    | ASetLogsOptionData
    | AShowNotification
    | ACloseNotification
    | AInitLogOptions
    | ASetLogOptionsFieldState
    | ASetVisibilityLogFilterModal
    | ASetHeaderTypeIndex
    | ASetHeaderFilterData
    | AAddHeaderFilter
    | ADeleteHeaderFilter
    | AToggleHeaderFilter
    | ALogDataObtained
    | ASetLogRowState
    | ASetCurrentPage
    | ASetSvcConfigIdx
    | ACacheCmdStarted
    | ACacheCmdCompleted
    | ACacheInitOptions
    | ASetCacheRowState
    | ACacheCloseModal
    | ACacheInitModal
    | ASubmitCachePurgeRequest
    | ASetCacheSetIntervalId
    | ARefreshCacheStatus
    | APwdChangeInProgress
    | ASetPwdExpiredAuthToken
    | AValidateProfile
    | AUpdateProfileInProgress
    | AInitUpdateProfile
    | ASetSelectedDocumentationItem
    | AChangeEnvironment
    | ASetReportQueryTimeFormat
    | ASetReportPeriodIndex
    | ASetReportFromTo
    | ASetReportData
    | ASessionStateSaved
    | AInitSessionData
    | AApplySettings

    | ASetAdminUserList
    | ASetAdminProjectList
    | ADeleteUser
    | ASetUserListIndex
    | ASetProjectListIndex
    | ASetApiKeyListIndex
    | ASetUserProjectList
    | ASetProjectUserList
    | ASetUserApiKeyList
    | AToggleAdminSelection
    | AToggleAdminPopup
    | AToggleAdminPopupCheckmark
    | AToggleShowExpiredApiKey
    | ASetUserRole
    | AAddUserToAllProjects
    | ASetApiKeyState
    | ASetProjectCertList
    | ASetCertListIndex
    | AToggleCertDetails

    | ASetServiceVersionList
    | ASetSelectedServiceDetail
    | AToggleServicePopup
    | ASetServiceOverviewDetails
    | ASetServiceOriginDetails
    | AAddServiceOrigin
    | ASetDeploymentHistory
    | ASetDeploymentHistoryViewIndex
    | ASetUnsafePromoteState
    | ASetProjectServices
    | AReloadServices
    | ACreateNewService
    | ADeleteService
    | ASetDeploymentDestinationIndex
    | ASetDeploymentVersionIndex
    | ASetServiceOptionIndex
    | ADiscardServiceChanges
    | ASetRoutingRule
    | ADraftSaved
    | AInitEditService
    | ASetServiceCreationTemplate
    | ASetProjectPlatforms
    | ASetPlatformIdx
    | ASetProjectFeaturesAttributes
    | AEnableTFASupport
    | ASetTFAGenInfo
    | ASetTFAToken
    | ALoginUserTFA
    | AInitTFAMgmt
    | ABeginTFADeactivate
    | ASetPwdResetTokenInfo
    | ASetOrgList
    | ASetCurrentOrg
    | ASetOrgListIdx

    | AInitCSALite
    | AControlModalDlg
    | ASetCSAFile
    | ASetCSAJobList
    | ASetIntervalId
    | ASetProjectName
    | ASetOrgName
    | ASetOrgData
    | ASetOrgLimits
    | ASetServiceDnsDirectorDetails

    | AInitFeature
    | ASortTable
    | ASetBillingReportData
    | ASetProjectTrafficUsage
    | AToggleBillingShowServices
    | AToggleBillingShowProjectServices
    | ASetBillingUnits
    | ASetBillingMonthIndex
    | ASetServiceHCData 
    | AShowErrors
    | ASetSystemProjectSolutions
    | ASetProjectSolutions
    | ASetSelectedProjectSolutions
