import { createBrowserHistory as createHistory } from 'history';
import { createStore } from 'redux';

// import the root reducer
import rootReducer from './reducers/index';

// Create a history of your choosing (we're using a browser history in this case)
export const zhistory = createHistory();

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
export const store = createStore( rootReducer );
