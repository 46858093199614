import * as React from 'react';
import moment from 'moment';

import { State } from '../App';

import ApiKeyAdmin from './adminComps/ApiKeyAdmin';
import UserAdmin from './adminComps/UserAdmin';
import ProjectAdmin from './adminComps/ProjectAdmin';
import CertAdmin from './adminComps/CertAdmin';
import { MISC_STRINGS } from '../shared/strings';

import { actionTypes } from '../actions/actionCreatorTypes';
import { ZApiKey, ZUserMin, ApiKeyPermission } from '../data/queryResultDefinitions';
import { NotificationStyles } from '../shared/constants';
import { ZAdminMapType, ZFeaturePopupTypes, SysFeatureEnums } from '../reducers/reducerEnums';

export interface AdminStateProps extends State {
    applySettings: () => actionTypes;
    setAdminUserList: (userList: ZUserMin[]) => actionTypes;
    setUserListIndex: (index: number) => actionTypes;
    setUserApiKeyList: (apikeys: ZApiKey[]) => actionTypes;
    setApiKeyListIndex: (index: number) => actionTypes;
    toggleAdminSelection: (collectionName: ZAdminMapType, id: string) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    toggleAdminPopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean, 
                       value?: number | string | ZApiKey) => actionTypes;
    toggleShowExpiredApiKeys: () => actionTypes;
    setApiKeyState: (friendlyName: string, expirationDate: moment.Moment, 
                     permission?: ApiKeyPermission) => actionTypes;
}
class Admin extends React.Component<AdminStateProps> {
    render(): JSX.Element {
        const uiState = this.props.systemNavProjectState;
        let page: JSX.Element = <span></span>;
        if (uiState.currentFeature === SysFeatureEnums.apiKeyAdmin) { 
            page = <ApiKeyAdmin  {...this.props} /> 
        } else if (uiState.currentFeature === SysFeatureEnums.usersAdmin) { 
            page = <UserAdmin  {...this.props} /> 
        } else if (uiState.currentFeature === SysFeatureEnums.projectsAdmin) {
            page = <ProjectAdmin  {...this.props} />;
        } else if (uiState.currentFeature === SysFeatureEnums.certAdmin) {
            page = <CertAdmin  {...this.props} />;
        } else {
            page = <div>{MISC_STRINGS.loading}</div>
        }

        return (
            <div className="feature-panel">
                <div className="administration-features">
                    {page}
                </div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
        )
    }
}

export default Admin;
