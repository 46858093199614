import * as React from 'react';

export interface ButtonProps {
    onClick: () => void;
    disabled?: boolean;
    btnCls?: string;
    btnType?: string;
}

// eslint-disable-next-line 
export class ZButton extends React.Component<any, ButtonProps> {
    render(): JSX.Element {
        const buttonChildren = React.Children.map(this.props.children, child => {
            return child;
        });
        const baseCls = ((this.props.disabled) ? 'zbutton-disabled' : 'zbutton' ) + ' animate-hover';
        const baseClrClass = this.props.btnType === 'primary' ? 'zbutton-primary' : '';
        const btnClasses  = `${baseCls} ${baseClrClass} ${this.props.btnCls ? this.props.btnCls : ''}`;

        return (
        <button disabled={
            this.props.disabled
        } className={btnClasses} tabIndex={0}
                onClick={(): void => (this.props.onClick) () } >
            {buttonChildren}
        </button>
        );
    }
}

export default ZButton;