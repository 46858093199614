import { AnalyticsMetricData, ProjectMetricKeyResponse, ZProjectMin, ZUserMin, AggrDataMap, ZProductFeatureAttributes, ProjectSolutions,
         ZUserTypes, ProjUriCode, ZApiKey, ApiKeyPermission, Certificate, ZServiceVersion, ZServiceExt, CsmLogsResponse, ServerPlatforms,
         DeploymentHistoryItem, CachePurgeReqStatusExt, ServerConfig, ZServiceFeatures, RoutingRules, ZTfaSecret, ZCSMEntity,
         ResetTokenInfo, OrgInfo, OrgLimit, CSAHistoryItem, AvailableBillingReports, BillingOrgUsageData, MetricsDataV1Response } 
         from '../data/queryResultDefinitions';

import { ZSessionStorage } from '../data/metricsAndOptionsDefs';

import { HeaderFilterOpsIndexEnum, PwdResetState, SysFeatureEnums, ZFeaturePopupTypes, ZAdminMapType, ZEnvironments, ZSortDirection,
         UsageUnits, MetricDataTypes, ZSortFields }  from '../reducers/reducerEnums';
import { ProjectMetricDefinitions } from '../reducers/uiAccessors';
import { actionTypes, ActionKeys, OptionalApplyArgs } from './actionCreatorTypes';
import { NotificationStyles, ServiceTemplateName } from '../shared/constants';
import { LogOptionsFieldErrorState, LogRowData } from '../reducers/logState';
import { AuthenticationType } from '../reducers/authentication';
import moment from 'moment';

export const loginUserFail = (msg: string): actionTypes => {
    return {
        payload: { errorMsg: msg },
        type: ActionKeys.LOGIN_USER_FAIL,
    };
}

export const logout = (): actionTypes => {
    return {
        type: ActionKeys.LOGOUT_USER
    }
}

export const featureReady = (): actionTypes => {
    return {
        type: ActionKeys.FEATURE_READY
    }
}

export const updateEmailPassword = (email: string, password: string): actionTypes => {
    return {
        payload: {email, password},
        type: ActionKeys.UPDATE_EMAIL_PWD
    }
}
export const validatePassword = (oldPwd: string, newPwd: string, confirmPwd: string): actionTypes  => {
    return {
        payload: {oldPwd, newPwd, confirmPwd},
        type: ActionKeys.VALIDATE_PWD
    }
}
export const setResetPasswordState = (pwdResetState: PwdResetState): actionTypes => {
    return {
        payload: { pwdResetState },
        type: ActionKeys.SET_PWD_RESET_STATE
    }
}
export const pwdChangeInProgress = (inProgress: boolean): actionTypes => {
    return {
        payload: {
            inProgress
        },
        type: ActionKeys.PWD_CHANGE_IN_PROGRESS,
    }
}

export const resetPassword = (success: boolean): actionTypes => {
    return {
        payload: { success },
        type: ActionKeys.RESET_PASSWORD
    }
}

export const changeServerFeatureEnv = (projectType: string): actionTypes => {
    return {
        payload: { projectType },
        type: ActionKeys.CHANGE_SERVER_FEATURE_ENV
    };
}

export const saveServerConfig = (config: ServerConfig): actionTypes => {
    return {
        payload: { config },
        type: ActionKeys.SAVE_SERVER_CONFIG_DATA
    };
}

export const loginUserSuccess = (): actionTypes => {
    return {
        type: ActionKeys.LOGIN_USER_SUCCESS
    };
}

export const loginUserTFA = (): actionTypes => {
    return {
        type: ActionKeys.LOGIN_USER_TFA
    };
}

export const setUserInfo = (user: ZUserMin): actionTypes => {
    return {
        payload: { user },
        type: ActionKeys.SET_USER_INFO        
    }
}
export const loginUserStart = (): actionTypes => {
    return {
        type: ActionKeys.LOGIN_USER_START
    };
}

export const setProjects = (projects: ZProjectMin[], projectId?: string): actionTypes => {
    return {
        payload: { projects, projectId },
        type: ActionKeys.SET_PROJECTS
    }
}

export const setProjectServices = (projectId: string, serviceList: ZServiceExt[], env?: ZEnvironments, serviceId?: string): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_SERVICES,
        payload: {
            projectId,
            serviceList,
            env,
            serviceId,
        }
    }
}

export const reloadServices = (): actionTypes => {
    return {
        type: ActionKeys.RELOAD_SERVICES
    }
}

export const createNewService = (service: ZServiceExt): actionTypes => {
    return {
        type: ActionKeys.CREATE_NEW_SERVICE,
        payload: {
            service,
        }
    }
}

export const deleteService = (serviceId: string): actionTypes => {
    return {
        type: ActionKeys.DELETE_SERVICE,
        payload: {
            serviceId
        }
    }
}

export const changeProjects = (projectId: string, environment?: ZEnvironments): actionTypes => {
    return {
        payload: {
            projectId,
            environment,
        },
        type: ActionKeys.CHANGE_PROJECTS
    };
}

export const setProjURICodes = (projUriCode: ProjUriCode[]): actionTypes => {
    return {
        payload: {
            projUriCode
        },
        type: ActionKeys.SET_PROJ_URI_CODES
    }
}

export const resetProject = (projectId: string): actionTypes => {
    return {
        payload: {
            projectId
        },
        type: ActionKeys.RESET_PROJECT
    };
}

export const changeServiceEnvironment = (environment: ZEnvironments): actionTypes => {
    return {
        payload: {
            environment
        },
        type: ActionKeys.CHANGE_ENVIRONMENT
    };
}

export const setNavItem = (navItem: string, feature: SysFeatureEnums): actionTypes => {
    return {
        payload: {navItem, feature},
        type: ActionKeys.SET_NAV_ITEM
    };
}

export const setHamburgerOpen = (isOpen: boolean): actionTypes => {
    return {
        payload: { isOpen },
        type: ActionKeys.SET_HAMBURGER
    }
}
export const initMetricCheckBoxes = (checkboxData: ProjectMetricDefinitions): actionTypes => {
    return {
        payload: { checkboxData },
        type: ActionKeys.INIT_METRIC_CHKBOXES
    };
}

export const setMetricCheckBox = (metricKey: string, value: boolean): actionTypes => {
    return {
        payload: { metricKey, value },
        type: ActionKeys.SET_METRIC_CHKBOX
    };
}

export const initAnalyticOptions = 
                (fullInit: boolean, features: ZServiceFeatures, optionKeys: ProjectMetricKeyResponse, analyticFeature: SysFeatureEnums): actionTypes => {
    return { 
        payload: {
            fullInit,
            features,
            optionKeys,
            analyticFeature
        },
        type:  ActionKeys.INIT_ANALYTIC_OPTIONS,
    }
}

export const setAnalyticsRefreshId  = (timeoutId: number): actionTypes => {
    return {
        type: ActionKeys.SET_ANALYTICS_REFRESH_ID,
        payload: {
            timeoutId
        }
    }
}

export const setCurrentProjectKeys  = (optionKeys: ProjectMetricKeyResponse): actionTypes => {
    return {
        payload: {
            optionKeys
        },
        type:  ActionKeys.SET_CURRENT_PROJECT_KEYS
    }
}

export const setAnalyticOption  = (optionName: string, value: number): actionTypes => {
    return {
        payload: {
            optionName,
            value
        },
        type:  ActionKeys.SET_ANALYTIC_OPTION
    }
}

export const applySettings = (error?: string, extraData?: string, optionalArgs?: OptionalApplyArgs): actionTypes => {
    return {
        type: ActionKeys.APPLY_SETTINGS,
        payload: {
            error, 
            extraData,
            optionalArgs,
        },
    }
}

export const setAnalyticsGraphData = 
                ( graphData: AnalyticsMetricData[] | MetricsDataV1Response, 
                  appendResults = false,
                  totalItems = 1,
                  dataType?: MetricDataTypes): actionTypes => {
    return {
        payload: { appendResults, graphData, totalItems, dataType },
        type: ActionKeys.SET_ANALYTICS_GRAPH_DATA
    }
}

export const setAnalyticsMoreData = (currentFeature: SysFeatureEnums, type: string, data: CsmLogsResponse): actionTypes => {
    return {
        type: ActionKeys.SET_ANALYTICS_MORE_DATA,
        payload: { currentFeature, type, data, },
    }
}

export const refreshPage = (): actionTypes => {
    return {
        type: ActionKeys.REFRESH_PAGE
    }
}

export const dataLoadInProgress = (feature: string): actionTypes => {
    return {
        type: ActionKeys.DATA_LOAD_IN_PROGRESS,
        payload: {
            feature
        }
    }
}

export const dataGroupLoad = (inProgress: boolean, feature: string): actionTypes => {
    return {
        type: ActionKeys.DATA_GROUP_LOAD,
        payload: {
            inProgress,
            feature
        }
    }
}

export const setCurrentServiceEvtIdx = ( index: number): actionTypes => {
    return { 
        type:  ActionKeys.SET_CURRENT_SERVICE_EVT_IDX,
        payload: {
            index,
        }
    }
}

export const setUriCodeIdx = (index: number): actionTypes  => {
    return {
        type: ActionKeys.SET_URI_CODE_IDX,
        payload: {
            index
        }
    }
}

export const setQueryTimeFormat = (relativeTime: boolean, component: string): actionTypes => {
    return {
        type: ActionKeys.SET_QUERY_TIME_FORMAT,
        payload: {
            relativeTime, 
            component
        }
    }
}

export const setFromToTime = (from: moment.Moment, to: moment.Moment): actionTypes => {
    return {
        type: ActionKeys.SET_FROM_TO_TIME,
        payload: {
            from,
            to
        }
    }
}

export const setLogsFromToTime = (from: moment.Moment, to: moment.Moment, invalidTime: boolean, relTimeIdx: number): actionTypes => {
    return {
        type: ActionKeys.SET_LOGS_FROM_TO_TIME,
        payload: {
            from,
            to,
            invalidTime,
            relTimeIdx,
        }
    }
}

export const setLogsOptionData = (clientIP: string, originIP: string, assetURL: string, 
                                  responseCode: string, requestMethodIdx: number): actionTypes => {
    return {
        type: ActionKeys.SET_LOGS_OPTION_DATA,
        payload: {
            clientIP,
            originIP,
            responseCode,
            assetURL,
            requestMethodIdx
        }
    }
}

export const showNotification = (
    style: NotificationStyles,
    message: string): actionTypes => {
    return {
        type: ActionKeys.SHOW_NOTIFICATION,
        payload: {
            style,
            message,
        }
    }
}

export const closeNotification = (): actionTypes => {
    return {
        type: ActionKeys.CLOSE_NOTIFICATION,
    }
}

export const initLogOptions = (): actionTypes => {
    return {
        type: ActionKeys.INIT_LOG_OPTIONS,
    }
}

export const setLogOptionsFieldState = (errorState: LogOptionsFieldErrorState): actionTypes => {
    return {
        type: ActionKeys.SET_LOG_OPTIONS_FIELD_STATE,
        payload: {
            errorState
        }
    }
}

export const setVisibilityLogFilterModal = (showModal: boolean): actionTypes => {
    return {
        type: ActionKeys.SET_VISIBILITY_LOG_FILTER_MODAL,
        payload: {
            showModal
        }
    }
}
export const setHeaderTypeIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_HEADER_TYPE_IDX,
        payload: {
            idx: index
        }
    }
}

export const setHeaderFilterData = (filterOps: HeaderFilterOpsIndexEnum, filterName: string, 
                                    filterValue: string): actionTypes => {
    return {
        type: ActionKeys.SET_HEADER_FILTER_DATA,
        payload: {
            filterOps,
            filterName, 
            filterValue
        }
    }
}

export const addHeaderFilter = (
        isRequestHeader: boolean, 
        filterOps: HeaderFilterOpsIndexEnum, 
        filterName: string, 
        filterValue: string): actionTypes => {
    return {
        type: ActionKeys.ADD_HEADER_FILTER,
        payload: {
            isRequestHeader,
            filterOps,
            filterName, 
            filterValue
        }
    }
}

export const deleteHeaderFilter = (index: number): actionTypes => {
    return {
        type: ActionKeys.DELETE_HEADER_FILTER,
        payload: {
            index
        }
    }
}

export const toggleHeaderFilter = (index: number): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_HEADER_FILTER,
        payload: {
            index
        }
    }
}

export const logDataObtained = (status: boolean, logRowData: LogRowData[], totals: number): actionTypes => {
    return {
        type: ActionKeys.LOG_DATA_OBTAINED,
        payload: {
            status,
            logRowData,
            totals,
        }
    }
}

export const setLogRowState = (rowIdx: number, rowOpen: boolean, showReqHeader: boolean, 
                               showRespHeader: boolean): actionTypes => {
    return {
        type: ActionKeys.SET_LOG_ROW_STATE,
        payload: {
            rowIdx,
            rowOpen,
            showReqHeader,
            showRespHeader
        }
    }
}

export const setCurrentPage = ( pageNum: number, component: string ): actionTypes => {
    return {
        type: ActionKeys.SET_CURRENT_PAGE,
        payload: {
            component,
            pageNum
        }
    }
}

export const setSvcConfigIdx = ( idx: number ): actionTypes => {
    return {
        type: ActionKeys.SET_SVC_CONFIG_IDX,
        payload: {
            idx
        }
    }
}

export const cacheCmdStarted  = (): actionTypes => {
    return {
        type: ActionKeys.CACHE_CMD_STARTED
    }
}

export const cacheCmdCompleted = (data: CachePurgeReqStatusExt[] | undefined, success: boolean, 
                                  msg: string): actionTypes => {
    return {
        type: ActionKeys.CACHE_CMD_COMPLETED,
        payload: {
            data,
            success,
            msg
        }
    }
}

export const setCacheRowState = (rowNum: number): actionTypes => {
    return {
        type: ActionKeys.CACHE_SET_ROW_STATE,
        payload: {
            rowNum
        }
    }
}

export const cacheInitOptions = (environment: ZEnvironments, serviceId: string): actionTypes => {
    return {
        type: ActionKeys.CACHE_INIT_OPTIONS,
        payload: {
            environment,
            serviceId
        }
    }
}

export const closeCacheModal = (): actionTypes => {
    return {
        type: ActionKeys.CACHE_CLOSE_MODAL,
    }
}

export const initCacheModal = (uriCodes: ProjUriCode[]): actionTypes => {
    return {
        type: ActionKeys.CACHE_INIT_MODAL,
        payload: { 
            uriCodes
        }
    }
}

export const submitCachePurgeRequest = (): actionTypes =>  {
    return {
        type: ActionKeys.CACHE_SUBMIT_PURGE_REQUEST
    }
}

export const setCacheSetIntervalId = (intervalId: number): actionTypes => {
    return {
        type: ActionKeys.CACHE_SET_INTERVAL_ID,
        payload: {
            intervalId
        }
    }
}

export const refreshCacheStatus = (): actionTypes => {
    return {
        type: ActionKeys.CACHE_REFRESH_CACHE_STATUS
    }
}

export const validateProfile = (firstName: string, lastName: string,  email: string,  phoneNumber: string): 
                                actionTypes => {
    return {
        type: ActionKeys.VALIDATE_PROFILE,
        payload: {
            firstName,
            lastName, 
            email, 
            phoneNumber
        }
    }
}

export const updateProfileInProgress = (inProgress: boolean, updateSucceeded: boolean): actionTypes => {
    return {
        type: ActionKeys.UPDATE_PROFILE_IN_PROGRESS,
        payload: {
            inProgress,
            updateSucceeded
        }
    }
}

export const initUpdateProfile = (): actionTypes => {
    return {
        type: ActionKeys.INIT_UPDATE_PROFILE
    }
}

export const setSelectedDocumentationItem = (navItem: number, subNavItem: number): actionTypes => {
    return {
        type: ActionKeys.SET_SELECTED_DOCUMENTATION_ITEM,
        payload: {
            navItem,
            subNavItem
        }
    }
}

export const setReportQueryTimeFormat = (showingRelativeTime: boolean): actionTypes => {
    return {
        type: ActionKeys.SET_REPORT_QUERY_TIME_FORMAT,
        payload: {
            showingRelativeTime
        }
    }   
}

export const setReportPeriodIndex = (periodIndex: number): actionTypes => {
    return {
        type: ActionKeys.SET_REPORT_PERIOD_INDEX,
        payload: {
            periodIndex
        }
    }
}

export const setReportFromTo = (from: moment.Moment, to: moment.Moment): actionTypes => {
    return {
        type: ActionKeys.SET_REPORT_FROM_TO,
        payload: {
            to,
            from,
        }
    }
}

export const setReportData = (reportType: string, data: AggrDataMap[], serviceIds: string, serviceNames: string,
                              env: string): actionTypes => {
    return { 
        type: ActionKeys.SET_REPORT_DATA,
        payload: {
            reportType,
            data,
            serviceIds,
            serviceNames,
            env,
        }
    }
}

export const sessionStateSaved = (): actionTypes => {
    return {
        type: ActionKeys.SESSION_STATE_SAVED
    }
}

export const initSessionData = (): actionTypes => {
    return {
        type: ActionKeys.INIT_SESSION_DATA,
    }
}

export const setAdminUserList = (userList: ZUserMin[], showZAdmin?: boolean): actionTypes => {
    const showZAdminRole = !!showZAdmin;
    return {
        type: ActionKeys.SET_ADMIN_USER_LIST,
        payload: {
            userList,
            showZAdminRole
        }
    }
}

export const addUserToAllProjects = (addToAllProjects: boolean): actionTypes => {
    return {
        type: ActionKeys.ADD_USER_TO_ALL_PROJECTS,
        payload: {
            addToAllProjects
        }
    }
}

export const deleteUser = (userId: string): actionTypes => {
    return {
        type: ActionKeys.DELETE_USER,
        payload: {
            userId
        }
    }
}

export const setAdminProjectList = (projectList: ZProjectMin[]): actionTypes => {
    return {
        type: ActionKeys.SET_ADMIN_PROJECT_LIST,
        payload: {
            projectList,
        }
    }
}

export const setProjectUserList = (userList: ZUserMin[]): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_USER_LIST,
        payload: {
            userList,
        }
    }
}

export const setUserProjectList = (projectList: ZProjectMin[]): actionTypes => {
    return {
        type: ActionKeys.SET_USER_PROJECT_LIST,
        payload: {
            projectList,
        }
    }
}

export const setUserApiKeyList = (apikeyList: ZApiKey[]): actionTypes => {
    return {
        type: ActionKeys.SET_USER_APIKEY_LIST,
        payload: {
            apikeyList,
        }
    }
}

export const setUserListIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_USER_LIST_IDX,
        payload: {
            index,
        }
    }
}

export const setProjectListIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_LIST_IDX,
        payload: {
            index
        }
    }
}

export const setApiKeyListIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_APIKEY_LIST_IDX,
        payload: {
            index
        }
    }
}

export const toggleAdminSelection = (collectionName: ZAdminMapType, id: string): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_ADMIN_SELECTION,
        payload: {
            collectionName,
            id,
        }
    }
}

export const toggleAdminPopup = (popupType: ZFeaturePopupTypes, popupOpen: boolean, 
                                 value?: number | string | ZApiKey): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_ADMIN_POPUP,
        payload: {
            popupType,
            popupOpen,
            value,
        }
    }
}

export const toggleAdminPopupCheckmark = (index: number): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_ADMIN_POPUP_CHECKMARK,
        payload: {
            index
        }
    }
}

export const toggleShowExpiredApiKeys = (): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_SHOW_EXPIRED_API_KEYS
    }
}

export const setApiKeyState = (friendlyName: string, expirationDate: moment.Moment, permission?: ApiKeyPermission): 
            actionTypes => {
    return {
        type: ActionKeys.SET_API_KEY_STATE,
        payload: {
            friendlyName,
            expirationDate,
            permission,
        }
    }
}

export const setUserRole = (role: ZUserTypes): actionTypes => {
    return {
        type: ActionKeys.SET_USER_ROLE,
        payload: {
            role
        }
    }
}

export const setProjectCertList = (certList: Certificate[], projectId: string): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_CERT_LIST,
        payload: {
            certList, 
            projectId
        }
    }
}

export const setCertListIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_CERT_LIST_IDX,
        payload: {
            index
        }
    }
}

export const toggleCertDetails = (index: number): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_CERT_DETAILS,
        payload: {
            index
        }
    }
}

export const setServiceVersionList = (svcVersions: ZServiceVersion[]): actionTypes => {
    return {
        type: ActionKeys.SET_SERVICE_VERSION_LIST,
        payload: {
            svcVersions
        }
    }
}

export const setSelectedServiceDetail = (serviceConfig: ZServiceExt, isEditable?: boolean, serviceConfigProd?: ZServiceExt): actionTypes => {
    return {
        type: ActionKeys.SET_SELECTED_SERVICE_DETAIL,
        payload: {
            serviceConfig,
            isEditable,
            serviceConfigProd,
        }
    }
}

export const toggleServicePopup = (popupType: ZFeaturePopupTypes, popupOpen: boolean): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_SERVICE_POPUP,
        payload: {
            popupType,
            popupOpen,
        }
    }
}

export const  setServiceOverviewDetails = (serviceName: string, domainName: string, certListIdx?: number,
                                           enableHTTP2?: boolean, enableHTTPSRedirect?: boolean, domainsRedirect?: string): actionTypes => {
    return {
        type: ActionKeys.SET_SERVICE_OVERVIEW_DETAILS,
        payload: {
            serviceName,
            domainName,
            certListIdx,
            enableHTTP2,
            enableHTTPSRedirect,
            domainsRedirect,
        }
    }
}

export const setServiceOriginDetails = ( originName: string, originDomainName: string, 
                                         tlsEnabled: boolean, sni: string): actionTypes => {
    return {
        type: ActionKeys.SET_SERVICE_ORIGIN_DETAILS,
        payload: {
            originName,
            originDomainName,
            tlsEnabled,
            sni,
        }
    }
}

export const setServiceDnsDirectorDetails = (ddDomainName: string, zyTrafficPercentage: string, 
                                             cdnTag: string, autoSwitchEnabled: boolean, 
                                             useCdnAsOriginEnabled: boolean): actionTypes => {
    return {
        type: ActionKeys.SET_SERVICE_DNS_DIRECTOR_DETAILS,
        payload: {
            ddDomainName,
            zyTrafficPercentage,
            cdnTag,
            autoSwitchEnabled,
            useCdnAsOriginEnabled
        }
    }
}

export const addServiceOrigin = ( originName: string, originDomainName: string, tlsEnabled: boolean, 
                                  sni: string): actionTypes => {
    return {
        type: ActionKeys.ADD_SERVICE_ORIGIN,
        payload: {
            originName,
            originDomainName,
            tlsEnabled,
            sni
        }
    }
}

export const setDeploymentHistoryViewIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_DEPLOYMENT_HISTORY_VIEW_IDX,
        payload: {
            index
        }
    }
}

export const setDeploymentHistory = ( deploymentHistory: DeploymentHistoryItem[] ): actionTypes => {
    return {
        type: ActionKeys.SET_DEPLOYMENT_HISTORY,
        payload: {
            deploymentHistory
        }
    }
}

export const setDeploymentDestinationIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_DEPLOYMENT_DESTINATION_IDX,
        payload: {
            index
        }
    }
}

export const setDeploymentVersionIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_DEPLOYMENT_VERSION_IDX,
        payload: {
            index
        }
    }
}

export const setServiceOptionIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_SERVICE_OPTION_IDX,
        payload: {
            index
        }
    }  
}

export const discardServiceChanges = (): actionTypes => {
    return {
        type: ActionKeys.DISCARD_SERVICE_CHANGES
    }
}

export const setRoutingRule = (rule: RoutingRules): actionTypes => {
    return {
        type: ActionKeys.SET_ROUTING_RULE,
        payload: {
            rule
        }
    }
}

export const draftSaved = (svc: ZServiceExt): actionTypes => {
    return {
        type: ActionKeys.DRAFT_SAVED,
        payload: {
            svc
        }
    }
}

export const initEditService = (sessionStorage: ZSessionStorage): actionTypes => {
    return {
        type: ActionKeys.INIT_EDIT_SERVICE,
        payload: {
            sessionStorage
        }
    }
}

export const enableTFASupport = (enabled: boolean): actionTypes => {
    return {
        type: ActionKeys.ENABLE_TFA_SUPPORT,
        payload: {
            enabled
        }
    }
}

export const setTFAGenInfo = (tfaGenInfo: ZTfaSecret): actionTypes => {
    return {
        type: ActionKeys.SET_TFA_GEN_INFO,
        payload: {
            tfaGenInfo
        }
    }
}

export const updateTFAToken = (token: string): actionTypes => {
    return {
        type: ActionKeys.UPDATE_TFA_TOKEN,
        payload: {
            token
        }
    }
}

export const initTFAMgmt = (): actionTypes => {
    return {
        type: ActionKeys.INIT_TFA_MGMT
    }
}

export const beginTFADeactivate = (): actionTypes => {
    return {
        type: ActionKeys.BEGIN_TFA_DEACTIVATE,
    }
}

export const setPwdResetTokenInfo = (authenticationType: AuthenticationType, token: string, tokenStatus: ResetTokenInfo): actionTypes => {
    return {
        type: ActionKeys.SET_PWD_RESET_TOKEN_INFO,
        payload: {
            authenticationType,
            token,
            tokenStatus
        }
    }
}

export const setCurrentOrg = (orgId: string): actionTypes => {
    return {
        type: ActionKeys.SET_CURRENT_ORG,
        payload: {
            orgId
        }
    }
}

export const setOrgList = (orgList: OrgInfo[], selectedOrgName?: string): actionTypes => {
    return {
        type: ActionKeys.SET_ORG_LIST,
        payload: {
            orgList,
            selectedOrgName
        }
    }
}

export const setOrgListIdx = (orgIndex: number): actionTypes => {
    return {
        type: ActionKeys.SET_ORG_LIST_IDX,
        payload: {
            orgIndex
        }
    }
}

export const redirectTo = (url: string): actionTypes => {
    return {
        type: ActionKeys.REDIRECT_TO,
        payload: {
            url
        }
    }
}

export const initCSALite = (): actionTypes => {
    return {
        type: ActionKeys.INIT_CSA_LITE
    } 
}

export const controlModalDlg = (feature: SysFeatureEnums, setOpen: boolean, dialogName?: ZFeaturePopupTypes): actionTypes => {
    return {
        type: ActionKeys.CONTROL_MODAL,
        payload: {
            dialogName,
            setOpen,
            feature,
        }
    }
}

export const setCSAFile = (selectedFile: FormData): actionTypes => {
    return {
        type: ActionKeys.SET_CSA_FILE,
        payload: {
            selectedFile
        }
    }
}

export const setCSAJobList = (csaJobList: CSAHistoryItem[]): actionTypes => {
    return {
        type: ActionKeys.SET_CSA_JOB_LIST,
        payload: {
            csaJobList
        }
    }
}

export const setIntervalId = (intervalId: number): actionTypes => {
    return {
        type: ActionKeys.SET_INTERVAL_ID,
        payload: {
            intervalId
        }
    }
}

export const setProjectName = (projectName: string): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_NAME,
        payload: {
            projectName
        }
    }
}

export const setOrgName = (orgName: string): actionTypes => {
    return {
        type: ActionKeys.SET_NEW_ORG_NAME,
        payload: {
            orgName
        }
    }
}

export const setOrgData = (orgData: OrgInfo): actionTypes => {
    return { 
        type: ActionKeys.SET_ORG_DATA,
        payload: {
            orgData
        }
    }
}

export const setOrgLimits = (limits: OrgLimit[]): actionTypes => {
    return {
        type: ActionKeys.SET_ORG_LIMITS,
        payload: {
            limits
        }
    }
}

export const initFeature = (feature: SysFeatureEnums): actionTypes => {
    return {
        type: ActionKeys.INIT_FEATURE,
        payload: {
            feature
        }
    }
}

export const sortTable = (feature: SysFeatureEnums, tableName: string, fieldName: string | ZSortFields, direction: ZSortDirection): actionTypes => {
    return {
        type: ActionKeys.SORT_TABLE,
        payload: {
            feature,
            tableName,
            fieldName,
            direction
        }
    }
}

export const setBillingReportData = (availableBillingReports: AvailableBillingReports): actionTypes => {
    return {
        type: ActionKeys.SET_BILLING_REPORTS_DATA,
        payload: {
            availableBillingReports
        }
    }
}

export const setProjectTrafficUsage = (billingUsageData: BillingOrgUsageData, includesServices: boolean): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_TRAFFIC_USAGE,
        payload: {
            billingUsageData,
            includesServices
        }
    }
}

export const toggleBillingShowServices = (showingServices: boolean): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_BILLING_SHOW_SERVICES,
        payload: {
            showingServices
        }
    }
}

export const toggleBillingShowProjectServices = (projectIndex: number): actionTypes => {
    return {
        type: ActionKeys.TOGGLE_BILLING_PROJECT_SERVICES,
        payload: {
            projectIndex
        }
    }
}

export const setBillingUnits = (units: UsageUnits): actionTypes => {
    return {
        type: ActionKeys.SET_BILLING_UNITS,
        payload: {
            units
        }
    }
}

export const setBillingMonthIndex = (index: number): actionTypes => {
    return {
        type: ActionKeys.SET_BILLING_MONTH_INDEX,
        payload: {
            index
        }
    }
}


export const setServiceCreationTemplate = (templateName: ServiceTemplateName): actionTypes => {
    return {
        type: ActionKeys.SET_SERVICE_CREATION_TEMPLATE,
        payload: {
            templateName
        }
    }
}

export const setProjectPlatforms = (platforms: ServerPlatforms): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_PLATFORMS,
        payload: {
            platforms
        }
    }
}

export const setPlatformIdx = (platformIdx: number): actionTypes => {
    return {
        type: ActionKeys.SET_PLATFORM_IDX,
        payload: {
            platformIdx
        }
    }
}

export const setProjectFeaturesAttributes = (svcFeatureAttributes: ZProductFeatureAttributes): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_FEATURES_ATTRIBUTES,
        payload: {
            svcFeatureAttributes
        }
    }
}

export const setServiceHCData = (healthChecks: ZCSMEntity[]): actionTypes => {
    return {
        type: ActionKeys.SET_SERVICE_HC_DATA,
        payload: {
            healthChecks
        }
    }
}

export const showErrors = (feature: string, showErrors: boolean): actionTypes => {
    return {
        type: ActionKeys.SHOW_ERRORS,
        payload: {
            feature,
            showErrors,
        }
    }
}

export const setUnsafePromoteState = (unsafePromoteCheckState: boolean, unsafePromoteNeedConfirmation: boolean, 
                                      unsafeCertNameMismatch: boolean): actionTypes => {
    return {
        type: ActionKeys.SET_UNSAFE_PROMOTE_STATE,
        payload: {
            unsafePromoteCheckState,
            unsafePromoteNeedConfirmation,
            unsafeCertNameMismatch,
        }
    }
}
 
export const setSystemProjectSolutions = (solutions: ProjectSolutions[]): actionTypes => {
    return {
        type: ActionKeys.SET_SYSTEM_PROJECT_SOLUTIONS,
        payload: {
            solutions
        }
    }
}

export const setSelectedProjectSolutions = (solution: string, isSelected: boolean): actionTypes => {
    return {
        type: ActionKeys.SET_SELECTED_PROJECT_SOLUTIONS,
        payload: {
            solution,
            isSelected,
        }

    }
}

export const setProjectSolutions  = (solutions: string[]): actionTypes => {
    return {
        type: ActionKeys.SET_PROJECT_SOLUTIONS,
        payload: {
            solutions,
        }
    }
}

export const setPwdExpiredAuthToken = (authToken: string): actionTypes => {
    return {
        type: ActionKeys.SET_PWD_EXPIRED_AUTH_TOKEN,
        payload: {
            authToken
        }
    }
}