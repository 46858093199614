import * as React from 'react';
import ReactEcharts from 'echarts-for-react';

import { State } from '../../App';
import { MetricsUIDefinitions,  ChartTypes } from '../../data/metricsAndOptionsDefs';
import { AnalyticsMetricData, } from '../../data/queryResultDefinitions';
import { getMetricsObjForTab, getSelectedOptions, TabMappingObj } from '../../reducers/uiAccessors';
import { ANALYTICS_STRINGS, MISC_STRINGS as STRs } from '../../shared/strings';
import { timeSeriesOptions } from './TimeSeries';
import { pieChartOptions } from './PieChart';
// import logger from '../../shared/logUtilities';

export interface ChartProps extends State {
    graphData: AnalyticsMetricData | undefined;
    id: number;
    metric: string;
}

// eslint-disable-next-line 
class Chart extends React.Component<any, ChartProps> {
    public render(): JSX.Element {
        const systemState = this.props.systemNavProjectState;
        const metricObj: TabMappingObj = getMetricsObjForTab(systemState.currentFeature);
        const metricUIData: MetricsUIDefinitions = metricObj[this.props.metric];

        const projectDisplayType: number = systemState.currentProjectDspInfo;
        const chartType: ChartTypes = (metricUIData.chartType) ? metricUIData.chartType : ChartTypes.line;

        if (!metricUIData) {
            console.log('! Bad metric info - we are going to crash');
        }
        const optionPeriod: string = getSelectedOptions(this.props.analyticsUIState, 'period');
        // console.log('optionPeriod: ', optionPeriod);
        
        const graphData = this.props.graphData;
        const statsLabel = (graphData && graphData.stats) ? ' - ' + graphData.stats : '';
        const options = this.getOptions(metricUIData, optionPeriod);
        
        let markup;
        const chartDisabled = metricUIData.pocDisabled.includes(projectDisplayType);
        const pocMsg = chartDisabled ? (<div className="poc-msg"><div>{STRs.dataNotAvailable}</div></div>) : '';
        const blur = chartDisabled ? ' poc-blur' : '';

        let chartBodyCSS = chartType === ChartTypes.line ? 'chart-body-line' : 'chart-body-pie';
        if (options) {
            if ( graphData && graphData.data.length > 0 && graphData.data[0].time_values.length > 0) {
                markup = (
                    <ReactEcharts option={options} style={{height: '100%', width: '100%'}} 
                                  className={'react_for_echarts' + blur} notMerge={true} />
                );
            } else {
                chartBodyCSS = chartType === ChartTypes.line ? 'empty-chart-body' : 'empty-chart-body-pie';
                 markup = ANALYTICS_STRINGS.noChartData;
            }
        } else {
            markup = (<div className="chart-loading">{ANALYTICS_STRINGS.chartLoading}</div>);
        }
        
        const additionalTxt = (metricUIData.additionalTxt) ? 
                              <div className="addition-chart-text">{metricUIData.additionalTxt}</div> : <div></div>
        return <div className="chart">
            <div>
              <div className="chart-title">{metricUIData.metric + statsLabel}</div>
              <div className={chartBodyCSS}>
                {markup}
                {pocMsg}              
              </div>
              {additionalTxt}
            </div>
          </div>;
    }

    // eslint-disable-next-line 
    protected getOptions = (metricUIData: MetricsUIDefinitions, periodOption: string): any => {
        if (!this.props.graphData) {
            return undefined;
        }
        
        const chartType: ChartTypes = (metricUIData.chartType) ? metricUIData.chartType : ChartTypes.line;
        
        if (chartType === ChartTypes.pie) {
            return pieChartOptions(metricUIData, periodOption, this.props.graphData);
        } else {
            return timeSeriesOptions(metricUIData, periodOption, this.props.graphData);
        }
    }
}

// end of class
export default Chart;