import moment from 'moment';
import { RestAPI } from '../data/queryResultDefinitions';
let sysLoggingEnabled = true;

export class LogUtilities {
  public initLogger(loggingEnabled: boolean): void {
    sysLoggingEnabled = loggingEnabled;
  }

  public alert(msg: string): void {
    if (sysLoggingEnabled) {
      alert(msg);
    } else {

      const dateStr = this.getCurrentDateTime();
      console.log(dateStr, `ERROR (ALERT):  ${msg}`);
    }
  }


  // eslint-disable-next-line 
  public log(...logData: any[]): void {
    if (! sysLoggingEnabled) { return }

    const dateStr = this.getCurrentDateTime() + ' ';
    if (logData.length === 1) {
      console.log(dateStr + logData[0]);
    } else if (logData.length === 2) {
      console.log(dateStr + logData[0], logData[1]);
    } else {
      console.log(dateStr, logData);
    }
  }

  public logRESTApi(cmd: string, action: string, params: RestAPI): void {
    if (action === 'login') {
        this.log('Logging in.....');
        return;
    }

    if (params && params.metrics) {
        this.log([cmd + ' action: ' + action + ';\n   metrics: ' + params.metrics + '\n    params: ', params]); 

    } else {
        this.log([cmd + ' action: ' + action + ';\n    params: ',  params]);
    }
  }

  public getCurrentDateTime(): string {
    return moment().format('MM/DD/YY hh:mm:ssa');
  }
}

const logger = new LogUtilities();

export default logger;