import * as React from 'react';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { SvcStatusMap } from '../../data/staticData';
import { ZServiceVersion, ZRestResponse, FetchError, ServiceStatusEnum, ZUserMin, ZUserTypes, 
         Certificate } from '../../data/queryResultDefinitions';

import { orgAccessToNewFeatures } from '../../reducers/serverEnvironAccessor';
import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { _CurrentProject, ZService } from '../../reducers/systemState';
import { EProjectState, ZFeaturePopupTypes } from '../../reducers/reducerEnums';

import { SERVICES_STRINGS as STRs, MISC_STRINGS } from '../../shared/strings'; 
import { DATETIME_FORMAT, NotificationStyles } from '../../shared/constants';
import { ZGet, ZPut } from '../../shared/backend';

import GenDialog from '../../shared/GenDialog';
import ListPanel from '../../shared/ListPanel';
import logger from '../../shared/logUtilities';
import { CertificateObject, findCert} from '../../shared/utilities';
import ZButton from '../../shared/ZButton';
import ZURLS from '../../shared/urls';

import ServiceDisplay from './ServiceDisplay';
import DiffServiceVersions from './DiffServiceVersions';

export interface ViewServicesProps extends State {
    applySettings: () => actionTypes;
    resetProject: (projectId: string) => actionTypes;
    refreshPage: () => actionTypes;
    featureReady: () => actionTypes;
    setServiceVersionList: (svcVersion: ZServiceVersion[]) => actionTypes;
    setProjectCertList: (certList: Certificate[], projectId: string) => actionTypes;

    setSvcConfigIdx: (idx: number) => actionTypes;
    setSelectedServiceDetail: (serviceConfig: ZService) => actionTypes;
    toggleServicePopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    dataGroupLoad: (inProgress: boolean, feature: string) => actionTypes;
}

class ViewServices extends React.Component<ViewServicesProps> {
    componentDidMount(): void {
        logger.log('mounting ViewServices');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: ViewServicesProps): void {
        logger.log('componentDidUpdate - view services')
        try {
            // const projects = this.props.systemNavProjectState;
            const serviceState = this.props.serviceUIState.serviceState;
            const systemState = this.props.systemNavProjectState;
            const systemUIState = systemState.systemUIState;

            if (! (systemState.projectsLoaded && systemState.servicesLoaded)) {
                return;
            }

             if (systemUIState === EProjectState.servicesUpdated) {
                this.props.featureReady();
                this.initViewServicesPage();
            }
        
            // if service environment changed we can't believe what the number of deployed services is.  We have to
            // reset the project info for the new environment.
            if (serviceState === EProjectState.serviceEnvironmentChanged) {
                this.initViewServicesPage();

            } else if (serviceState === EProjectState.serviceChanged) {
                this.initViewServicesPage();

            } else if (serviceState === EProjectState.reloadData) {
                this.initViewServicesPage();
            }

        } catch (e) {
            console.log('viewServices.componentDidUpdate: catch error:' + e);
        }
    }
    
    render(): JSX.Element {
        const svcState = this.props.serviceUIState;
        const { selectedServiceVersion: service, certList} = svcState;
        const svcMap = this.props.systemNavProjectState._currentProject.serviceIdMap;
        let markup = <span></span>;
        let popup = <span></span>;

        const serviceId = service.service_id;
        const deletingMsg = (svcMap[serviceId] && svcMap[serviceId].status === ServiceStatusEnum.deleting) ? ' - ' + STRs.svcDeletionInProgress : '';
        const title = STRs.viewServiceTitle(service.service_name) + deletingMsg
        const keys = Object.keys(service);
        if (svcState.serviceState === EProjectState.dataLoadInProgress) {
            markup = (
                <div className="no-services">
                    {MISC_STRINGS.loading}
                </div>
            );
        } else if (keys.length === 0) {
            markup = (
                <div className="no-services">
                    {STRs.noServicesForEnv(this.props.systemNavProjectState._currentProject.currentServiceEnvironment)}
                </div>
            );
        } else {
            const popupBuilders = {
                [ZFeaturePopupTypes.ShowServiceJson] : this.showServiceJson,
                [ZFeaturePopupTypes.CopyServiceToDraft]:  this.showCopyDraft,
                [ZFeaturePopupTypes.DiffServiceVersions]: this.diffServiceVersions,
            }

            if (svcState.popupOpen) {
                if (popupBuilders[svcState.popupType]) {
                    popup = (popupBuilders[svcState.popupType])();
                }
            }

            const versionList = this.buildVersionList();
            const versionHeader: JSX.Element = (
                <div className="service-version-list-header">
                    <div></div>
                    <div>{STRs.version}</div>
                    <div>{STRs.lastUpdate}</div>
                    <div>{MISC_STRINGS.status}</div>
                </div>);

            const environ = service.env && service.env.length > 0 ? service.env : '---'

            const version = (service.status === ServiceStatusEnum.draft) ? STRs.configDraft : STRs.configVersion(service.version);
            const configHeader: JSX.Element = (
                <div className="service-detail-header">
                    <div></div>
                    <div className="">{ version} </div>
                    <div>{STRs.environment}:&nbsp;{environ}</div>
                </div>
            );

            const cert: Certificate | undefined = findCert(service, certList);
            const certObj = new CertificateObject(cert);

            const disableBtn = (environ === 'prod' || service.status === ServiceStatusEnum.draft)
            const serviceDisplay = <ServiceDisplay service={service} projCsmHCIntervals={svcState.projCsmHCIntervals} hideEnvironment={true} certObj={certObj} />;

            // TODO:  HACK!!!!  We should have to get this data for the user here.
            const user = this.props.authNServerData.user as ZUserMin;
            const orgId = this.props.authNServerData.currentOrg.org_id;
            const userType: ZUserTypes = user.role;
            const viewFullFeature = (orgAccessToNewFeatures(this.props.authNServerData, orgId) && 
                                        (userType !== ZUserTypes.user));

            const viewer = (viewFullFeature) ? (
                <ListPanel 
                renderHeader={(): JSX.Element => { return configHeader; }}
                renderBody={(): JSX.Element => { return (<div className="service-display-info">{serviceDisplay}</div>) }}
                renderFooter={(): JSX.Element => { return (
                    <div className="list-panel-footer">
                    <div>
                        <ZButton onClick={(): void => { this.handleShowServiceJson(); }}>
                                    {STRs.showServiceJson}
                        </ZButton>&nbsp;
                        <ZButton onClick={(): void => { this.handleCopyAsDraft(); }} disabled={disableBtn}>
                                    {STRs.copyAsDraft}
                        </ZButton>
                    </div>
                </div>
                ) }}
            />) : (
                <ListPanel 
                    renderHeader={(): JSX.Element => { return configHeader; }}
                    renderBody={(): JSX.Element => { return (<div className="service-display-info">{serviceDisplay}</div>) }}
                    renderFooter={(): JSX.Element => { return (
                        <div className="list-panel-footer">
                        <div>
                            <ZButton onClick={(): void => { this.handleShowServiceJson(); }}>
                                        {STRs.showServiceJson}
                            </ZButton>
                        </div>
                    </div>
                    ) }}
                />)

            const versionFooter = (
                <div className="list-panel-footer">
                    <div>
                        <ZButton onClick={(): void => { this.handleDiffServiceVersions(); }} disabled={svcState.svcVersions.length === 1}>
                                    {STRs.diffServiceVersions}
                        </ZButton>&nbsp;
                    </div>
                </div>
            );

            markup =  (
                <div className="feature-panel">
                <div className="servicesFeature">
                    <div className="title">{title}</div>
                    <div className="service-overview-panel">
                        <div>
                            <ListPanel
                                renderHeader={(): JSX.Element => { return versionHeader }}
                                renderBody={(): JSX.Element => { return versionList}}
                                renderFooter={(): JSX.Element => { return versionFooter}}
                            />
                        </div>
                        <div>
                            {viewer}
                        </div>
                    </div>
                </div>
                {popup}
                </div>
            )
        }
        
        return markup
    }

    private buildVersionList = (): JSX.Element => {
        const { svcVersionIdx, svcVersions, selectedServiceVersion } = this.props.serviceUIState;
        const versionList: JSX.Element[] = [];

        for (let i = 0, len = svcVersions.length; i < len; i++) {
            const selected = (i === svcVersionIdx) ? 'selected' : '';
            const serviceVersion = svcVersions[i];
            const status = (serviceVersion.is_live) ? STRs.active : SvcStatusMap[serviceVersion.status];
            const version = (serviceVersion.status === SvcStatusMap.draft) ? '---' : serviceVersion.version;
            // console.log( 'buildVersionList: ', serviceVersion );
            versionList.push((
                <div key={'serviceVersion' + i} className={'service-version-list-item ' + selected} onClick={
                        (): void => {
                            this.getVersionDetail(selectedServiceVersion.service_id, serviceVersion.version);
                            this.props.setSvcConfigIdx(i); }
                }>
                    <div></div>
                    <div>{version}</div>
                    <div>{moment(serviceVersion.updated_at).format(DATETIME_FORMAT)}</div>
                    <div>{status}</div>
                </div>
            ))
        }

        return <>{versionList}</>
    }

    private handleCopyAsDraft = (): void => {
        const { selectedServiceVersion } = this.props.serviceUIState;
        
        if ( selectedServiceVersion && selectedServiceVersion.status !== ServiceStatusEnum.draft) {
            this.props.toggleServicePopup(ZFeaturePopupTypes.CopyServiceToDraft, true);
        }
    }

    private handleShowServiceJson = (): void => {
        this.props.toggleServicePopup(ZFeaturePopupTypes.ShowServiceJson, true);
    }

    private handleDiffServiceVersions = (): void => {
        this.props.toggleServicePopup(ZFeaturePopupTypes.DiffServiceVersions, true);
    }

    private showServiceJson = (): JSX.Element => {
        const svcState = this.props.serviceUIState;
        const service = svcState.selectedServiceVersion;

        const serviceJson = (
            <GenDialog 
            show={this.props.serviceUIState.popupOpen}
            title={STRs.serviceAsJson(service.service_name)} 
            size='xl'
            msg=''
            hideApplyButton={true}
            cancelBtnTxt={MISC_STRINGS.close}
            disableApply={(): boolean => {return false}}
            onHide={(): void => {this.closePopup()}}
            handleApply={(): void => { this.closePopup(); }}
        >
            <div className="service-json">
                <pre>
                    <code>{JSON.stringify(service, null, 4)}</code>
                </pre>
            </div>
        </GenDialog>
        );

        return serviceJson;
    }

    private showCopyDraft = (): JSX.Element => {
        const { selectedServiceVersion, svcVersions } = this.props.serviceUIState;
        let message = '';
        let titleApplyBtn = '';
        if (svcVersions[0].status !== ServiceStatusEnum.draft) {
            message = STRs.confirmMakeNewDraft(selectedServiceVersion.version);
            titleApplyBtn = STRs.createNewDraft;
        } else {
            message = STRs.confirmCopyAsDraft(selectedServiceVersion.version);
            titleApplyBtn = STRs.replaceDraft;
        }

        const copyDraft = (
            <GenDialog 
                show={this.props.serviceUIState.popupOpen}
                title={titleApplyBtn} 
                msg={message}
                applyBtnTxt={titleApplyBtn}
                disableApply={(): boolean => { return false}}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { this.applyCopyAsDraft(); }}
            >
            </GenDialog>
        );

        return copyDraft;
    }

    private diffServiceVersions = (): JSX.Element => {
        const svcUIState = this.props.serviceUIState;
        return <DiffServiceVersions 
                    versionList={svcUIState.svcVersions} currentSvc={svcUIState.selectedServiceVersion} 
                    done={() => {
                        this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false);
                    }}
                    serverDetail={this.props.authNServerData}
                    notification={(msg: string) => {
                        this.props.showNotification(NotificationStyles.danger, msg);
                        this.closeNotification();
                    }}>
               </DiffServiceVersions> 
    }

    private applyCopyAsDraft = (): void => {
        const service = this.props.serviceUIState.selectedServiceVersion;
        if ( !service ) {
            return;
        }

        const serviceId = service.service_id;
        const url = buildUrl(this.props.authNServerData, 
                             ZURLS.serverServiceCopyAsDraft(serviceId, service.version));
        const promise = ZPut(url, '');
        
        promise.then( ( draft: ZRestResponse ) => {
            this.props.setSelectedServiceDetail(draft as ZService);
            this.props.refreshPage()
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`viewService.applyCopyAsDraft: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
            .catch((err) => {
                console.log(`viewService.applyCopyAsDraft: fetch failed: ${err}`);
            })
        });
    }

    

    private closePopup  = (): void => {
        this.props.toggleServicePopup(ZFeaturePopupTypes.NoPopup, false);
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }

    private initViewServicesPage = async (): Promise<void> => {
        const systemState =  this.props.systemNavProjectState;
        const project: _CurrentProject = systemState._currentProject;
        const currentEnv = project.currentEnv;
        const dataLoading = this.props.serviceUIState.dataLoading;

        if (dataLoading) {
            return;
        }

        const svcIdx = systemState.serviceEvtIdx;
        const serviceName = systemState.serviceEvents[svcIdx];

        const serviceId = currentEnv.serviceNameToId[serviceName];
        if (! serviceId ) {
            this.props.applySettings();
            return;
        }
        const sDesc: ZService | undefined = project.serviceIdMap[serviceId];

        
        if (sDesc !== undefined) {
            try {
                const serviceId = sDesc.serviceId;
                this.props.dataGroupLoad(true, 'services');
                await this.loadProjectCerts(project.projectId);
                await this.loadServiceVersions(serviceId);
                this.props.dataGroupLoad(false, 'services');
                this.props.applySettings();
            }
            catch (err) {
                this.props.dataGroupLoad(false, 'services');
            }
        }
    }

    private loadServiceVersions = async (serviceId: string): Promise<void> => {
        if (serviceId.length === 0) {
            return;
        }

        try {
            const url = buildUrl(this.props.authNServerData, ZURLS.serverServiceVersions(serviceId));
            const vlist = await ZGet(url, {});
            
            const versions = vlist as ZServiceVersion[];
            this.props.setServiceVersionList(versions);
            const version = (versions)[versions.length - 1].version;
            await this.getVersionDetail(serviceId, version);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`viewService.loadServiceVersions: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
            .catch((err) => {
                console.log(`viewService.loadServiceVersions: fetch failed: ${err}`);
            })
        }
    }

    private getVersionDetail = async (serviceId: string, version: number): Promise<void> => {
        
        const url = buildUrl(this.props.authNServerData, ZURLS.serverServiceManagement(serviceId));

        try {
            const serviceConfig = await ZGet(url, {version});
            this.props.setSelectedServiceDetail(serviceConfig as ZService);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`viewService.getVersionDetail: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
            .catch((err) => {
                console.log(`viewService.getVersionDetail: fetch failed: ${err}`);
            })
        }
    }

    private loadProjectCerts = async (projectId: string) => {
        let url = ZURLS.serverCertManagement;
        url = buildUrl(this.props.authNServerData, url);

        const requestData = {
            project_id: projectId,
            meta: true,
            'meta.additional_info': true
        }

        const response = await  ZGet(url, requestData);
        this.props.setProjectCertList(response as Certificate[], projectId);
        return response;
    }
}



// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        serviceUIState: state.serviceUIState,
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(ViewServices);