import * as React from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { TimeRange, ZSessionStorage, PaginationData } from '../data/metricsAndOptionsDefs';
import { ZUserMin, OrgInfo, OrgLimit, Certificate, ZSecurePort, ZServiceExt, ZCSMEntity, HealthCheckIntervals } from '../data/queryResultDefinitions';
import { ACCOUNT_STRINGS, ADMIN_STRINGS, MISC_STRINGS } from './strings';
import { CertUpdateStatus } from '../reducers/reducerEnums'

import logger from './logUtilities';
import { CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT, DATETIME_FORMAT_NOSECS, DAY_IN_SECONDS } from './constants';

export const closePopupNotification = (): void => {
    setTimeout(() => { 
        console.log(`hello ${CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT}`);
        // store.dispatch(
        //     closeNotification()
        // ); 
    }, 5000);
}

export const toTitleCase = (str: string): string => {
    let tstr = '';
    if (str && typeof(str) === 'string') {
        tstr =  str[0].toUpperCase() + str.slice(1);
    }
    return tstr;
}

const URL_BREAK_LEN = 74;
const HALF_URL_BREAK_LEN = URL_BREAK_LEN / 2;

export interface ShortenedUrl {
    fullUrl: string,
    url: string
}

export const buildUserName = (user: ZUserMin): string => {
    const firstName = user.first_name ? user.first_name : '';
    const lastName = user.last_name ? user.last_name + ',' : '';
    return ADMIN_STRINGS.userEmail(lastName, firstName, user.email);
}

export function convertStringToStringArray(str: string): string[] {
    const strArray = str.split(String.fromCharCode(10));
    const fStrArray = [];
    for (let i = 0, len = strArray.length; i < len; i++) {
        let s = strArray[i];
        if (/\s/.test(s)) {
            s = s.trim();
        }
        if (s.length > 0) {
            fStrArray.push(s);
        }
    }

    return fStrArray;
}

export const checkIPAddr = (ipAddr: string): boolean => {
    let valid = true;
    const ips = ipAddr.split('.');
    if (ips.length !== 4) {
        valid = false;
    } else {
        for (let i = 0, len = 4; i < len; i++) {
            const result = Number(ips[i]);
            if (Number.isNaN(result)) {
                valid = false;
            } else {
                if (i === 0) {
                    valid = (result >= 0 && result <= 255 ) ? valid : false;
                } else {
                    valid = (result >= 1 && result <= 255 ) ? valid : false;
                }
            }
        }
    }

    return valid;
}

export function isBase64(str: string): boolean {
    try {
        return window.btoa(window.atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export function truncateUrl(url: string): ShortenedUrl {
    const rc: ShortenedUrl = {
      url: '',
      fullUrl: ''
    };

    function shortenUrl(workingUrl: string) {
        const uLen = workingUrl.length;
        let backHalf = '';
        const frontHalf = workingUrl.substr(0, HALF_URL_BREAK_LEN);
        if (uLen > URL_BREAK_LEN + 3) {
            backHalf = workingUrl.substr(uLen - HALF_URL_BREAK_LEN);
        } else {
            backHalf = workingUrl.substr(HALF_URL_BREAK_LEN + 3);
        }
        return frontHalf + '...' + backHalf;
    }

    if (url.length > URL_BREAK_LEN) {
        rc.fullUrl = url;
        const idx = url.indexOf('?');
        if (idx > 0) {
            const turl = url.substr(0, idx + 1);
            if (idx > URL_BREAK_LEN) {
                rc.url = shortenUrl(turl) + '...';
            } else {
                rc.url = turl + '...';
            }
        } else {
            rc.url = shortenUrl(url);
        }

    } else {
        rc.url = url;
    }

    return rc;
}

export const isValidProjectOrgName = (name: string): boolean => {
    const n = name.trim();
    const pnameRegex = /^[a-zA-Z][a-zA-Z0-9_\- ]*$/;
    return pnameRegex.test(n) && name.length > 0;
}

export interface OrgLimitValidation {
    isValid: boolean,
    max_certs_per_project: boolean;
    max_projects: boolean;
    max_services_per_project: boolean;
    max_api_keys_per_user: boolean;
    max_firewall_rules: boolean;
}

export const validateOrgLimits = (initialData: OrgInfo, orgData: OrgInfo, orgLimits: OrgLimit[] ): OrgLimitValidation => {
    const orgLimitVal: OrgLimitValidation = {} as OrgLimitValidation;

    // orgLimitVal.isValid is only true if all limits are within range and at least one limit has been changed from it original value
    orgLimitVal.isValid = true;

    const validateIt = (field: string, min: number, max: number): void => {
        orgLimitVal[field] = orgData[field] !== undefined && (orgData[field] >= min) && (orgData[field] <= max);
        orgLimitVal.isValid = orgLimitVal.isValid && orgLimitVal[field];
    }
 
    for (let i=0, len=orgLimits.length; i < len; i++) {
        const {name, min, max} = orgLimits[i]
        validateIt(name, min, max);
    }

    return orgLimitVal;
}

export const obscureString = (s: string): string => {
    let str = '';
    if (typeof(s) === 'string') {
        str = '*****';
        if (s.length > 8) {
            str += s.slice(-4);
        } else {
            str += s.slice(-2);
        }
    }

    return str;
};

export const getTimeZone = (): string => {
    const dt = (new Date()).toTimeString();
    const tzStart = dt.indexOf('(');
    const tzEnd = dt.indexOf(')');
    let tzStr = '';
    if (tzEnd !== -1 && tzStart !== -1) {
        tzStr = dt.substring(tzStart + 1, tzEnd);
    }

    return tzStr;
};

export const dateFixup = (date: string): string => {
    if (date.indexOf('+') !== -1) {
        const dateArr = date.split(' ');
        date = dateArr[0] + 'T' + dateArr[1] + 'Z';
    }
    return date;
}

// This code was taken from:
// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
export const copyTextToClipboard = (text: string): boolean => {
    const textArea = document.createElement('textarea');
    let success = false;

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        logger.log('Copying text command was ' + msg);
        success = true;
    } catch (err) {
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
    return success;
};

const setStartOfDay = (timeDate: moment.Moment): moment.Moment => {
    timeDate.hour(0);
    timeDate.minute(0);
    timeDate.second(0);
    timeDate.millisecond(0);
    return timeDate;
}

const setEndOfDay = (timeDate: moment.Moment): moment.Moment => {
    timeDate.hour(23);
    timeDate.minute(59);
    timeDate.second(59);
    timeDate.millisecond(999);
    return timeDate;
}

const setStartOfMonth =  (timeDate: moment.Moment): moment.Moment => {
    timeDate = setStartOfDay(timeDate);
    timeDate.date(1);
    return timeDate
}

const setEndOfMonth = (timeDate: moment.Moment): moment.Moment => {
    timeDate.date(1);
    timeDate.add(1, 'month');
    timeDate.subtract(1, 'day');
    timeDate = setEndOfDay(timeDate);
    return timeDate;
}

export const getToday = (): TimeRange => {
    let from: moment.Moment = moment();
    const to: moment.Moment = moment();
    from = setStartOfDay(from);

    return {
        from,
        to
    }
}

export const getYesterday = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment()
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from = from.subtract(1, 'd');
    to = to.subtract(1, 'd');
    from = setStartOfDay(from);
    to = setEndOfDay(to)

    return {
        from,
        to
    }
}

// locale aware start of week to now
export const getThisWeek = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment();
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from = setStartOfDay(from);
    from.weekday(0);

    return {
        from,
        to
    }
}

// locale aware start of last to end of last week
export const getLastWeek = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment();
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from.subtract(1, 'week');
    from.weekday(0);
    from = setStartOfDay(from);

    to.subtract(1, 'week');
    to.weekday(6);
    to = setEndOfDay(to);

    return {
        from,
        to
    }
}

export const getThisMonth = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment();
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from.day(0);
    from = setStartOfMonth(from);

    return {
        from,
        to
    }
}

export const getLastMonth = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment();
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from.day(0);
    from = setStartOfMonth(from);
    from = from.subtract(1, 'month');

    to = to.subtract(1, 'month');
    to = setEndOfMonth(to);

    return {
        from,
        to
    }
}

export const getLast7Days = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment();
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from.subtract(7, 'days');
    return {
        to,
        from
    }
}

export const getLast30Days = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment();
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from.subtract(30, 'days');
    return {
        to,
        from
    }
}

export const getYearToDate = (stime?: string): TimeRange => {
    let from: moment.Moment = moment();
    let to: moment.Moment = moment();
    if (stime !== undefined) {
        from = moment(stime);
        to = moment(stime)
    }

    from.dayOfYear(1);
    from = setStartOfDay(from);
    return {
        to,
        from
    }
}

export const setSessionStorage = (storage: ZSessionStorage): void => {
    const str = JSON.stringify(storage);
    sessionStorage.setItem('state', str)
}

export const getSessionStorage = (): ZSessionStorage => {
    const str = sessionStorage.getItem('state');
    let storage = {} as ZSessionStorage;
    if (str !== null && str.length > 0) {
        storage = JSON.parse(str)
    }
    return storage;
}

export const removeSessionStorage = (): void => {
    sessionStorage.removeItem('state');
}

export const matchSvc = /(\w|\d|\s|\.|-)*/g;
export const matchHost = /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}$/i;
export const matchOrigin = /^([a-z0-9]|[-_]|[a-z0-9])*$/i;

// utilities routines
export const integerValidation =  (s: string): boolean => { 
    let valid = (s.match(/^-{0,1}\d+$/) !== null)
    if (valid) {
        const value = parseInt(s, 10);
        valid = (value > 0) && valid;
    }
    return valid;
}

export const headerValueValidation = (s: string): boolean => {
    const valid = ((s.match(/^[\x21-\x39|\x3B-\x7E]*$/) !== null))
    return (valid && (s.length > 0))
}

export const regMatch = (s: string, rex: RegExp): boolean => {
    const match = s.match(rex);
    const error = ((match !== null) && (match.length === 2) && (match[0].length === s.length));

    return error;
}

export const renderRangeInfo = (pData: PaginationData): string => {
    let start = (pData.currentPage - 1) * pData.pageSize;
    start = (start < 0) ? 0 : start;
    const itemsLeft = pData.totalItems - start;
    let itemsOnThisPage = pData.pageSize;
    if (itemsLeft < pData.pageSize) {
        itemsOnThisPage = itemsLeft;
    }
    const endStr = (start + itemsOnThisPage).toLocaleString();
    const startStr = (start + 1).toLocaleString();
    const totalStr = pData.totalItems.toLocaleString();
    return  `${MISC_STRINGS.entriesPart1} ${startStr} - ${endStr}${MISC_STRINGS.entriesPart2}${totalStr}`;
};

export const findCert = (service: ZServiceExt, certList: Certificate[]): Certificate | undefined => {
    let cert: Certificate | undefined = undefined;

    if (service.ports && service.ports["443"] !== undefined) {
        const port: ZSecurePort = service.ports["443"] as ZSecurePort;
        if (port.cert_id !== undefined) {
            const certId = port.cert_id;
            cert = certList.find((crt: Certificate) => { return crt.cert_id === certId });
        }

    }

    return cert;
}

// May be needed for CertificateObject if Support is provided
// interface SvcObj {
//     project_id: string;
//     services: [
//         {service_id: string}
//     ];
// }
export class CertificateObject {
    private _cert: Certificate;
    private _isValidCertObject = false;
    private _isValidExpiration = false;
    private _expiration: moment.Moment = moment();
    private _issueDate: moment.Moment = moment();
    private _nearExpiration = false;
    private _expired = false;
    private _goodCert = false;
    private _stateIcon = '';
    private _checkCSS = '';
    private _icon: JSX.Element = <span></span>;
    private _sansInfo: string[] = [];
    private _lastUpdate: moment.Moment = moment();
    private _updateStatus: CertUpdateStatus = CertUpdateStatus.unknown;
    
    public constructor(cert: Certificate | undefined) {
        this._cert = cert ? cert : {} as Certificate;
        this.updateCertObject( cert );
     }

     public update(cert: Certificate): void {
        this._cert = cert;
        this.updateCertObject( cert );
    }

    private updateCertObject = (cert: Certificate | undefined): void => {
        const now = moment(); 

        if (cert !== undefined && cert.common_name !== undefined) {
            this._expiration = moment(cert.expires_at);
            this._issueDate = moment(cert.issued_at);
            this._isValidExpiration = this._expiration.isValid();
            this._isValidCertObject = this._isValidExpiration && cert.common_name.length > 0; 
            this._expired = this._isValidExpiration && this._expiration.isBefore(now);
            this._goodCert = this._isValidCertObject && !this._expired
            this._nearExpiration = this._goodCert && !this._expiration.isAfter(now.add(7, 'd'));

            this._stateIcon = !this._expired ? 'check' : 'times-circle';
            this._checkCSS = !this._expired ? 'green-check' : 'red-x';
            if (this._isValidExpiration && this._nearExpiration) {
                this._stateIcon = 'exclamation-triangle';
                this._checkCSS = 'yellow-warning';
            }

            this._lastUpdate = moment('');
            this._updateStatus = CertUpdateStatus.unknown;

            this._sansInfo = cert.san ? cert.san : [];
            this._icon = (<FontAwesomeIcon className={this._checkCSS} icon={this.stateIcon as IconProp} />)

            if (cert.meta) {
                const meta = cert.meta;
                this._lastUpdate = moment(this.fixDate(meta.last_update));
                this._updateStatus = (cert.meta.update_status) ? 
                                cert.meta.update_status as CertUpdateStatus : CertUpdateStatus.unknown;

                // const svcIds: SvcObj[] = cert.meta.additional_info;                     
                // svcIds.forEach( (sObj: SvcObj) => {
                //     if (sObj.project_id === projectId) {
                //         sObj.services.forEach( svc => {
                //             serviceIds.push(svc.service_id)
                //         });
                //     }
                // });
            }
        } else {
            this._cert = {} as Certificate;
            this._cert.common_name = '';

            this._isValidCertObject = false;
            this._expiration = moment('');
            this._issueDate = moment('');
            this._isValidExpiration = false;
            this._expired = true;
            this._goodCert = false;
            this._nearExpiration = true;

            this._stateIcon = '';
            this._checkCSS = '';

            this._lastUpdate = moment('');
            this._updateStatus = CertUpdateStatus.unknown;

            this._sansInfo = [];
            this._icon = <span></span>
        }
    }

    get cert(): Certificate | undefined { return this._cert; }
    get isAValidCert(): boolean { return this._isValidExpiration && this._cert.common_name.length > 0; }
    get expiration(): moment.Moment { return this._expiration; }
    get expirationStr(): string {
        return this._expiration.isValid() ? this._expiration.format(DATETIME_FORMAT_NOSECS) : MISC_STRINGS.unknowExpiry
    }
  
    get nearExpiration(): boolean { return this._nearExpiration }
    get checkCSS(): string { return this._checkCSS }
    get icon(): JSX.Element { return this._icon }
    get isExpired(): boolean { return this._expired; }
    get isGoodCert(): boolean { return this._goodCert }
    get stateIcon(): string { return this._stateIcon; }
    get certId(): string { return this._cert ? this._cert.cert_id : ''}
    get commonName(): string { return this._cert ? this._cert.common_name : ''}
    get sansInfo(): string { return this._sansInfo.length > 0 ? this._sansInfo.join() : ''; }
    get issueDateStr(): string { 
        return this._issueDate.isValid() ? this._issueDate.format(DATETIME_FORMAT_NOSECS) : MISC_STRINGS.unknowExpiry
    }
    get issueDate(): moment.Moment { return this._issueDate } 
    get lastUpdateStr(): string {
        return this._lastUpdate.isValid() ? this._lastUpdate.format(DATETIME_FORMAT_NOSECS) : MISC_STRINGS.none
    }
    get issuer(): string {
        let issuerStr = '';
        if (this._isValidCertObject && this._cert.issuer) {
            const issuerFields = this._cert.issuer.split(',');
            const issuerName = (issuerFields.length > 0) ? issuerFields[0].split('=') : ['', ''];
            issuerStr = issuerName[1];
        }
        return issuerStr;
    }
    
    get updateStatus(): CertUpdateStatus { return this._updateStatus; }
    get certFullCertDisplayInfo(): JSX.Element {
        let info = <span></span>;
        if (this.commonName.length > 0) {
            const cn = ADMIN_STRINGS.certInfoCM(this.commonName);
            const expiry = !this._expired ? ADMIN_STRINGS.validTil(this.expirationStr) : ADMIN_STRINGS.expiredOn(this.expirationStr);
            const expiryInfo = <span className={this.checkCSS}>{expiry}</span> ;
            info =  <span>{cn}, &nbsp;&nbsp;&nbsp; {this.icon} {expiryInfo} </span>;
        }
        return info;
    }
    get certStatusStr(): JSX.Element { 
        let status = <span className="yellow-warning">{MISC_STRINGS.nearExpiration}</span>;
        if (! this.nearExpiration) {
            status = !this._expired ? <span className="green-check">{MISC_STRINGS.valid}</span> : <span className="red-x">{MISC_STRINGS.expiredCert}</span>;
        }
        return status;
    }
    // get certValidStr(): string { return this._isValidExpiration ? MISC_STRINGS.valid : MISC_STRINGS.expiredCert;}

    // **** TEMPORARY FIX
    private fixDate = (date: string): string => {
        if (date.indexOf('+') !== -1) {
            const dateArr = date.split(' ');
            date = dateArr[0] + 'T' + dateArr[1] + 'Z';
        }
        return date;
    }
}

export const buildCSMEntry = (entry: ZCSMEntity): ZCSMEntity => {
    const workingEntry = { check_interval: HealthCheckIntervals.default, request_path: ''}
    if (entry !== undefined) {
        workingEntry.check_interval = (entry.check_interval !== undefined) ? entry.check_interval : HealthCheckIntervals.default;
        workingEntry.request_path = (entry.request_path !== undefined) ? entry.request_path : '';
    }
    return workingEntry;
}

export const splitAndCleanDomainsString = (domainRedirects: string): string[] => {
    const redirects = domainRedirects.split(',');
    const finalRedirects: string[] = []
    for (let i=0, len=redirects.length; i < len; i++) {
        const a = redirects[i]
        let b = a.trim();
        while (b.charCodeAt(0) === 10) {
            b = b.substring(1);
        }
        if (b.length === 0) {
            continue;
        }
        finalRedirects.push(b);
    }
    return finalRedirects;
}

export class pwdExpiryUtils {
    private _now: number;
    private _expiryEpoch: number;
    private _expiryWarningDays: number;
    private _startWarningPeriod: number;
    private _isExpiring: boolean;
    private _timeString: string;

    public constructor(pwdExpiryEpoch: number, pwdExpiryWarningDays: number) {
        this._now = new Date().getTime()/1000;

        if (pwdExpiryEpoch === -1) {
            this._isExpiring = false;
            this._expiryEpoch = Math.max();
            this._expiryWarningDays = 365 * 10;
            this._startWarningPeriod = 0;
            this._timeString = '';
        } else {
            const eday1 = pwdExpiryEpoch;
            console.log(`eday1: ${eday1}; eday1 moment: ${moment(eday1)}`);
            this._expiryEpoch = eday1;

            this._expiryWarningDays = pwdExpiryWarningDays;
            this._startWarningPeriod = this._expiryEpoch - (this._expiryWarningDays * DAY_IN_SECONDS);
            console.log(`_startWarningPeriod: ${this._startWarningPeriod}; _startWarningPeriod: ${moment(this._startWarningPeriod)}}`);

            this._isExpiring = this._now > this._startWarningPeriod;

            this._timeString = '';
            this.buildTimeString();
        }
    }

    get isExpiring(): boolean { return this._isExpiring }
    get timeString(): string { return this._timeString}

    private buildTimeString(): void {
        if (! this._isExpiring) {
            this._timeString = '';
        } else {
            if ((this._now + (2 * DAY_IN_SECONDS)) < this._expiryEpoch) {
                const days = (this._expiryEpoch - this._now)/DAY_IN_SECONDS;
                this._timeString = ACCOUNT_STRINGS.pwdExpiring(Math.floor(days));
            } else if ((this._now + DAY_IN_SECONDS) <= this._expiryEpoch) {
                this._timeString = ACCOUNT_STRINGS.pwdExpringTommorrow;
            } else {
                const hours = Math.floor((this._expiryEpoch - this._now)/(60*60)); // 60 min * 60 secs
                const expiryTime = moment(new Date(0).setUTCSeconds(this._expiryEpoch)).format(DATETIME_FORMAT_NOSECS);
                if (hours > 0) {
                    this._timeString = ACCOUNT_STRINGS.pwdExpiringInNHours(hours, expiryTime)
                } else {
                    this._timeString = ACCOUNT_STRINGS.inUnderHour(expiryTime);
                }
            }

        }
    }
}