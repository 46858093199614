import * as React from 'react';
import Button from 'react-bootstrap/Button';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { State } from '../../App';

import { API_DOCUMENTATION as Strs, MISC_STRINGS } from '../../shared/strings';
import ApiDocOverview from '../../apiDocumentation/overviewApiDoc';
import ApiDocAnalytics from '../../apiDocumentation/analyticsApiDoc';
import ApiDocCache from '../../apiDocumentation/cacheApiDoc';
import ApiDocCachePurge from '../../apiDocumentation/cachePurgeApiDoc';
import ApiDocPurgeStatus from '../../apiDocumentation/purgeStatusApiDoc';
import ApiDocFWallMgt from '../../apiDocumentation/fwallMgtApiDoc';
import ApiDocWhitelistAddDelete from '../../apiDocumentation/whitelistAddDeleteApiDoc'
import ApiDocWhitelistGet from '../../apiDocumentation/whitelistGetApiDoc'
import ApiDocBlacklistAddDelete from '../../apiDocumentation/blacklistAddDeleteApiDoc'
import ApiDocBlacklistGet from '../../apiDocumentation/blacklistGetApiDoc'
import ApiDocLogs from '../../apiDocumentation/logsApiDoc'

export interface DocumentationProps extends State {
    setSelectedDocumentationItem: (navItem: number, subNavItem: number) => actionTypes
}

interface TocItem {
    name: string,
    render: () => JSX.Element
}

interface TocNav extends TocItem {
    subTopics: TocItem[];
}

/* eslint-disable react/display-name */
const toc: TocNav[] = [
    {
        name: Strs.overview,
        render: ()=> { return (< ApiDocOverview />) },
        subTopics: []
    }, {
        name: Strs.analytics,
        render: () => { return (< ApiDocAnalytics />) },
        subTopics: []
    }, {
        name: Strs.cacheManagement,
        render: () => { return (< ApiDocCache />) },
        subTopics: [
            { name: Strs.submitPurgeReq, render:  () => { return (< ApiDocCachePurge />) } },
            { name: Strs.getPurgeStatus, render: () => { return (< ApiDocPurgeStatus />) } }
        ]
    }, {
        name: Strs.firewallMgmt,
        render:  () => { return (< ApiDocFWallMgt />) },
        subTopics: [
            { name: Strs.addDelWhitelistIPs, render:  () => { return (< ApiDocWhitelistAddDelete />) } },
            { name: Strs.getWhitelistIPs, render:  () => { return (< ApiDocWhitelistGet />) } },
            { name: Strs.addDelBlacklistIPs, render:  () => { return (< ApiDocBlacklistAddDelete />) } }, 
            { name: Strs.getBlacklistIPs, render:  () => { return (< ApiDocBlacklistGet />) } },
        ]
    }, {
        name: Strs.logMgmt,
        render: () => { return (< ApiDocLogs />) },
        subTopics: []
    },
];
/* eslint-enable react/display-name */


class Documentation extends React.Component<DocumentationProps> {
    componentDidMount() {
        this.props.setSelectedDocumentationItem( 0, -1 );
    }

    private getContent = (): JSX.Element => {
        const { navItemIdx, navSubItemIdx } = this.props.authNServerData;
        const item = toc[navItemIdx];
        let content: TocItem;
        if (navSubItemIdx === -1) {
            content = item;
        } else {
            content = item.subTopics[navSubItemIdx];
        }

        return (content.render)();
    }

    public render() {
        const content = this.getContent();
        const tocPanel = this.buildTOC();
        return (
            <div className="feature-panel">
                <div id="api-documentation">
                    <div id="doc-nav-panel">{tocPanel}</div>
                    <div id="documentation-template">
                        {content}
                        <div className="copyright" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
                    </div>
                </div>
            </div>
        )
    }

    private isSelectedTopic = (navIdx: number, subnavIdx: number): string => {
        return  ((this.props.authNServerData.navItemIdx === navIdx) && 
                 (this.props.authNServerData.navSubItemIdx === subnavIdx)) ? ' selected' : '';
    }

    private topicClick = (navIdx: number, subnavIdx: number) => {
        this.props.setSelectedDocumentationItem(navIdx, subnavIdx);
    }

    private buildSubNav = (entry: TocNav, idx: number): JSX.Element[] => {
        return entry.subTopics.map((subItem, sIdx) => {
            const selectedCSS = this.isSelectedTopic(idx, sIdx);
            return (
                <div key={'tocSubDoc' + sIdx} role="presentation" className={'subtopics' + selectedCSS}>
                    <div>
                        <Button variant="link" onClick={() => {this.topicClick(idx, sIdx)}}>{subItem.name}</Button>
                    </div>
                </div>
            );
        })
    }

    private buildTOC = () => {
        const tocData = toc.map((item, i) => {
            const subItems = this.buildSubNav(item, i);
            const itemCSS = this.isSelectedTopic(i, -1);
            const tocItem = (
                <div key={'tocDoc' + i} role="presentation" className="topics">
                    <div className={itemCSS}>
                        <Button variant="link" onClick={() => this.topicClick(i, -1)}>{item.name}</Button>
                    </div>
                    <div>
                        {subItems}
                    </div>

                 </div>
            )
            return tocItem;
        });

        return (
            <div id="docNavPanel">
                <div className="title">Documentation</div>
                <div id="docItems" className="topics">
                    {tocData}
                </div>
            </div>
        )
    }
}

const stateToProps = (state: State) => {
    return {
        authNServerData: state.authNServerData
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(Documentation);