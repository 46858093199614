import zApp, { lApp, lReset, pwdExpired } from './App';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import fontAwesomeLib from './shared/IconLibrary';

import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router'
import zurls from './shared/urls';
import { store, zhistory } from './store';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

fontAwesomeLib.init();

const router = (
  <Provider store={store}>
    <Router history={zhistory}>
      <div >
        <Switch>
        <Route path={zurls.home} exact component={zApp} />
        <Route strict path={zurls.reset} component={lReset} />
        <Route strict path={zurls.activate} component={lReset} />
        <Route strict path={zurls.invite} component={lReset} />
        <Route strict path={zurls.expired} component={pwdExpired} />
        <Route path={zurls.login} component={lApp} />
        <Route path="*" component={zApp} />
        </Switch>
      </div>   
    </Router>         
  </Provider>
);

ReactDOM.render(router, document.getElementById('root'));