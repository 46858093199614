import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';
import { State } from '../../App';

import { ZUserMin, OrgInfo, ZRestResponse, FetchError, LoginData, ProjectSolutions } from '../../data/queryResultDefinitions';

import { buildUrl } from '../../reducers/serverEnvironAccessor';

import { NotificationStyles, } from '../../shared/constants';
import logger from '../../shared/logUtilities';
import { ORGANIZATION_STRINGS as STRs } from '../../shared/strings'; 
import ZButton from '../../shared/ZButton';
import ZDropdownSearch from '../../shared/ZDropdownSearch';
import { ZGet, ZPost } from '../../shared/backend';
import zurls from '../../shared/urls';

interface ChangeOrgProps extends State {
    setOrgListIdx: (orgIndex: number) => actionTypes;
    setOrgList: (orgList: OrgInfo[], selectedOrgName?: string)  => actionTypes;
    setCurrentOrg: (orgId: string) => actionTypes;
    setUserInfo: (user: ZUserMin) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    applySettings: (error?: string) => actionTypes;
    setSystemProjectSolutions: (solutions: ProjectSolutions[]) => actionTypes;
}

class ChangeOrgs extends React.Component<ChangeOrgProps> {
    componentDidMount(): void {
        logger.log('mounting DeployServices');
        this.initOrgInfo();
    }

    public render(): JSX.Element {
        const authState = this.props.authNServerData;
        const user = authState.user as ZUserMin;
        const numOrgs = authState.orgList.length;
        const currentOrg = authState.currentOrg;

        const orgDropdownList = authState.orgList.map((org: OrgInfo) => { return org.org_name });
        const myOrgIdx = orgDropdownList.findIndex((org: string) => {return (org === currentOrg.org_name); })
        const disabled = myOrgIdx === authState.orgListIdx;

        const dropdown = <ZDropdownSearch  optionList={orgDropdownList}  selectedIdx={authState.orgListIdx}
                                        ctrlId="orglist-dropdown"
                                        handleOptionsSelect={(idx) => { this.props.setOrgListIdx(idx);}}
        >
        </ZDropdownSearch>

        const changeOrg =  (numOrgs > 1) ? (
            <div className="change-org-panel">
                <div>{STRs.changeOrgs}</div>
                <div>
                    <div className="inline">{STRs.chooseOrgs}&nbsp;&nbsp;</div>
                    <div className="inline">{dropdown}</div>
                    <div className="inline">&nbsp;&nbsp;<ZButton disabled={disabled} 
                             onClick={ (): void => {this.setNewOrg(authState.orgListIdx)}}>{STRs.changeOrgs}
                        </ZButton>
                    </div>
                </div>
            </div>
        ) : <span></span>

        const orgNameFromLogin = user.company && user.company.length > 0 ? user.company : '';
        const currentOrgName = authState.currentOrg.org_name && authState.currentOrg.org_name.length > 0 ? authState.currentOrg.org_name : orgNameFromLogin;
        const markup: JSX.Element = (
                <div id="change-orgs">
                    <div className="title">{STRs.changeOrgsTitle}</div>
                    <div>{STRs.yourCurrentOrg}&nbsp;&nbsp; {currentOrgName}</div>
                    <div>
                        {changeOrg}
                    </div>
                </div>
        );

        return (
            <>
            {markup}
            </>
        )
    }

    private initOrgInfo = async (): Promise<void> => {
        logger.log('Org initialization')
        
        try {
            logger.log(' **** organizations.initOrgInfo:  load start')
            let url = buildUrl(this.props.authNServerData, zurls.serverOrgs);

            const response: ZRestResponse = await ZGet(url, {});
            let orgData = response as OrgInfo[]
            logger.log(' **** organizations.initOrgInfo: load done')

            orgData = orgData.sort((a: OrgInfo, b: OrgInfo) => {
                return a.org_name.localeCompare(b.org_name);
            })

            url = buildUrl(this.props.authNServerData, zurls.serverAllProjectSolutions);
            const solutions = await ZGet(url, {});
            this.props.setSystemProjectSolutions(solutions as ProjectSolutions[])

            // setOrgList resets the orgListIdx to the current org's list position
            this.props.setOrgList(orgData as OrgInfo[]);
        }
        catch(err) {
            const p = err as Promise<FetchError>;
            p.then((err) => {
                console.log(`updateSystemData: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
                this.props.applySettings('serverError');
            })
        }
    }

    // ***** Put this back in if we want to explicitly refresh projects for the current org.
    // private refreshProjects = (): void => {
    //     logger.log('refresh projects')
    //     const authState = this.props.authNServerData;

    //     const idx = authState.orgList.findIndex(org => {return org.org_id === authState.currentOrg.org_id})
    //     this.setNewOrg(idx);
    // }

    private setNewOrg = async (selectOrgIdx: number): Promise<void> => {
        const authState = this.props.authNServerData;
        const orgList = authState.orgList;
        const org = orgList[selectOrgIdx];
        logger.log(`set new org to ${JSON.stringify(org)}`);

        try {
            logger.log(' **** organizations.setNewOrg:  change start');
            let url = buildUrl(authState, zurls.serverChangeOrgs(org.org_id));

            const oData: ZRestResponse = await ZPost(url, '');
            const orgData = oData as LoginData;
            logger.log(' **** organizations.setNewOrg: change done');
            this.props.setUserInfo(orgData.user);
            this.props.setCurrentOrg(orgData.org_info.org_id);

            url = buildUrl(authState, zurls.serverAllProjectSolutions);
            const solutions = await ZGet(url, {});
            this.props.setSystemProjectSolutions(solutions as ProjectSolutions[])
        }
        catch(err) {
            const p = err as Promise<FetchError>;
            p.then((err) => {
                console.log(`updateSystemData: fetch failed: ${err}`);
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
                this.props.applySettings('serverError');
            })
        }
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(ChangeOrgs);