import moment from 'moment';

import { ApiKeyPermission, ZUserMin, ZApiKey, ZProjectMin, Certificate , ProjectPlanType, ProjectSolutions} from '../data/queryResultDefinitions';
import { BooleanMap } from '../data/metricsAndOptionsDefs';
import { EProjectState, ZFeaturePopupTypes, ZAdminMapType } from './reducerEnums';
import { actionTypes, ActionKeys, ASetNavItem, ASetProjectListIndex, ASetUserListIndex, ADataGroupLoad,
         ASetAdminUserList, ASetAdminProjectList, ASetProjectUserList, ASetUserProjectList, AToggleAdminPopup,
         AToggleAdminSelection, AToggleAdminPopupCheckmark, AValidateProfile, AValidatePassword, ASetUserRole,
         ASetApiKeyListIndex, ASetUserApiKeyList, ASetApiKeyState, ASetProjectCertList, ASetCertListIndex, 
         AToggleCertDetails, ADeleteUser, ASetProjectName, ASetProjectSolutions, ASetSystemProjectSolutions,
        ASetSelectedProjectSolutions, AApplySettings } from '../actions/actionCreatorTypes';

import { ZUserTypes } from '../data/queryResultDefinitions';

import { ProfilePwdValidationState } from '../shared/authUtils';
import { initProfileData, validatePwdData, validateProfileData, resetPwdProfileData } from '../shared/authUtils';
import { NAVSTRINGS } from '../shared/strings';
import { SysFeatureEnums } from './reducerEnums';
import logger from '../shared/logUtilities';
import { AAddUserToAllProjects } from '../actions/actionCreatorTypes';
import { CertificateObject } from '../shared/utilities';

export interface ZUserMap {
    [email: string]: ZUserMin;
}

export interface ZProjectMap {
    [projectId: string]: ZProjectMin;
}

export enum AdminManagementTypes {
    users = 'users',
    projects = 'projects',
    apiKeys = 'apikeys',
    certs = 'certs',
}

export interface CertDataRow {    
    open: boolean;
    projectId: string;
    certObj: CertificateObject;
}

export interface State extends ProfilePwdValidationState {
    adminType: AdminManagementTypes;
    adminState: EProjectState;
    allUsersMap: ZUserMap;
    allProjectsMap: ZProjectMap;
    
    userList: ZUserMin[];
    userListIdx: number;
    potentialUserProjectList: ZProjectMin[];
    selectedUser: ZUserMin;

    projectList: ZProjectMin[];
    projectListIdx: number;
    potentialProjectUserList: ZUserMin[];
    selectedProject: ZProjectMin;

    apiKeyList: ZApiKey[];
    numActiveKeys: number;
    newKey: ZApiKey;
    apiKeyListIdx: number;
    selectedApiKey: ZApiKey;
    showExpiredKeys: boolean;

    checkedProjects: BooleanMap;
    checkedUsers: BooleanMap;
    checkedApiKeys: BooleanMap;
    addUserToAllProjects: boolean,
    
    certList: Certificate[];
    certListIdx: number;
    selectedCert: Certificate;
    certData: CertDataRow[];

    popupCheckState: boolean[];

    projectName: string,
    projectPlanType: ProjectPlanType,
    projectSolutions: string[] | undefined,
    selectedSolutions: BooleanMap,
    solutionsChanged: boolean,
    systemProjectSolutions: string[]

    keyName: string;
    keyCreation: moment.Moment;
    keyExpire: moment.Moment;
    keyPermission: ApiKeyPermission;

    totalAllUsers: number;
    totalAllProjects: number;
    popupType: ZFeaturePopupTypes;
    popupOpen: boolean;
    showZAdminRole: boolean;
    dataLoading: boolean;
    savedProjectId: string;
}

const adminInitData: State = {
    adminType: AdminManagementTypes.users,
    adminState: EProjectState.ready,
    userListIdx: -1,
    projectListIdx: -1,
    apiKeyListIdx: -1,
    allUsersMap: {},
    userList: [],
    allProjectsMap: {},
    potentialUserProjectList: [],
    projectList: [],
    apiKeyList: [],
    numActiveKeys: 0,
    newKey: {} as ZApiKey,
    selectedUser: {} as ZUserMin,
    selectedProject: {} as ZProjectMin,
    selectedApiKey: {} as ZApiKey,
    potentialProjectUserList: [],
    addUserToAllProjects: false,
    checkedProjects: {},
    checkedUsers: {},
    checkedApiKeys: {},
    popupCheckState: [],
    totalAllUsers: 0,
    totalAllProjects: 0,
    popupType: ZFeaturePopupTypes.NoPopup,
    popupOpen: false,
    showZAdminRole: false,
    projectName: '',
    projectPlanType: ProjectPlanType.enterprise,
    selectedSolutions: {} as BooleanMap,
    projectSolutions: undefined,
    systemProjectSolutions: [],
    solutionsChanged: false,

    // apiKey stuff
    keyName: '',
    keyExpire: moment().add(3, 'm'),
    keyCreation: moment(),
    keyPermission: ApiKeyPermission.ro,
    showExpiredKeys: false,

    // cert stuff
    certList: [],
    certListIdx: -1,
    selectedCert: {} as Certificate,
    certData: [],

    // following is initialization of the ProfilePwdValidationState interface
    newPassword: '',
    password: '',
    confirmPassword: '',
    pwdValidation: {
        badCharMsg: '',
        badChars: '',
        letters: 'red',
        minChars: 'red',
        numbers: 'red',
        pwdMatch: 'red',
        validPwd: false
    },
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    profileValidation: {
        validProfile: false,
        firstNameValid: false,
        lastNameValid: false,
        phoneNumberValid: false,
        emailValid: false
    },
    role: ZUserTypes.user,
    dataLoading: false,
    savedProjectId: ''
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initAdminData = (state: State, action: actionTypes): State => {
    state.userListIdx = 0;
    state.projectListIdx = 0;
    state.apiKeyListIdx = 0;
    state.allUsersMap = {};
    state.userList.length = 0;
    state.potentialUserProjectList.length = 0;
    state.checkedUsers = {};       // checkbox map
    state.popupCheckState.length = 0;
    state.addUserToAllProjects = false;

    state.allProjectsMap = {};
    state.projectList.length = 0;
    state.potentialProjectUserList.length = 0;
    state.checkedProjects = {};    // checkbox map
    
    state.apiKeyList.length = 0;

    state.selectedUser = {} as ZUserMin;
    state.selectedProject = {} as ZProjectMin;
    state.selectedApiKey = {} as ZApiKey;

    state.checkedApiKeys = {};
    // state.adminType = AdminManagementTypes.users;
    state.popupType = ZFeaturePopupTypes.NoPopup;
    state.popupOpen = false;
    state.adminState = EProjectState.tabChanged;
    state.showExpiredKeys = false;
    state.showZAdminRole = false;

    state.projectName = '';
    state.projectPlanType = ProjectPlanType.enterprise;
    state.selectedSolutions = {} as BooleanMap;
    state.projectSolutions = undefined;
    state.solutionsChanged = false;
    // state.systemProjectSolutions = [] as string[]

    state.keyName = '';
    state.keyCreation = moment();
    state.keyExpire = moment().add('3', 'm');
    state.keyPermission = ApiKeyPermission.ro;

    state.certList.length = 0;
    state.certListIdx = 0;
    state.selectedCert = {} as Certificate;
    state.certData = [];
    state.savedProjectId = '';

    resetPwdProfileData(state);

    return state;
}

const adminMap = {
    [SysFeatureEnums.usersAdmin as string]: AdminManagementTypes.users,
    [SysFeatureEnums.projectsAdmin as string]: AdminManagementTypes.projects,
    [SysFeatureEnums.apiKeyAdmin as string]: AdminManagementTypes.apiKeys,
    [SysFeatureEnums.certAdmin as string]: AdminManagementTypes.certs,
}

const setNavItem = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetNavItem;
    const state = initAdminData(initialState, actions); 

    if (action.payload.navItem === NAVSTRINGS.admin) {
        let type = adminMap[action.payload.feature];
        type = type !== undefined ? type : AdminManagementTypes.users;

        state.adminType = type;
    }

    return state;
}

const toggleAdminPopupCheckmark = (initialState: State, actions: actionTypes): State => {
    const action = actions as AToggleAdminPopupCheckmark;
    const state = {...initialState}
    state.popupCheckState[action.payload.index] = !state.popupCheckState[action.payload.index]
    
    return state;
}

const setUserListIdx = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetUserListIndex;
    const state = {...initialState}; 

    const userIdx = action.payload.index
    state.userListIdx = userIdx;
    state.selectedUser = state.userList[userIdx];
    state.addUserToAllProjects = (state.selectedUser) ? !!state.selectedUser.auto_add_to_all_projects : false;
    if (state.adminType === AdminManagementTypes.users) {
        state.projectList.length = 0
    }

    return state;
}

const sortUsersByField = (userList: ZUserMin[], fieldName: string): ZUserMin[] => {
    const sortedList = userList.sort((u1, u2) => {
        const u1Field = u1[fieldName] === undefined ? '' : u1[fieldName].toLowerCase();
        const u2Field = u2[fieldName] === undefined ? '' : u2[fieldName].toLowerCase();
        return (u1Field > u2Field) ? 1 : -1;
    });

    return sortedList;
}

const sortProjectsByField = (projectList: ZProjectMin[], fieldName: string): ZProjectMin[] => {
    const sortedList = projectList.sort((projA, projB) => {
        const proj1Field = projA[fieldName] === undefined ? '' : projA[fieldName].toLowerCase();
        const proj2Field = projB[fieldName] === undefined ? '' : projB[fieldName].toLowerCase();
        return proj1Field > proj2Field ? -1 : 1;
    });

    return sortedList;
}

const findUserIndex = (userList: ZUserMin[], userId: string): number => {
    let userIdx = 0;
    if (userList.length > 0) {
        userIdx = userList.findIndex((u: ZUserMin) => { return u.user_id === userId });
    }
    userIdx = (userIdx === -1) ? 0 : userIdx;

    return userIdx;
}

const findProjectIndex = (projectList: ZProjectMin[], projectId: string): number => {
    let projectIdx = 0;
    if (projectList.length > 0) {
        projectIdx = projectList.findIndex((proj: ZProjectMin) => { return proj.project_id === projectId });
    }

    projectIdx = (projectIdx === -1) ? 0 : projectIdx;

    return projectIdx;
}

// When the user list is loaded it is turned into a map.  It is also set as the 
// user list.  If a user list for a project is later set (which will load a new
// user list), we still have all the original users defined in the map and their
// corresponding user objects are still saved.
const setAdminUserList = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetAdminUserList;
    const state = {...initialState}; 
    
    const userList = sortUsersByField(action.payload.userList, 'last_name');
    state.totalAllUsers = userList.length;

    state.showZAdminRole = action.payload.showZAdminRole

    if (userList.length > 0) {
        state.allUsersMap = {};
        userList.forEach((user) => {
            state.allUsersMap[user.user_id] = user;
            user.auto_add_to_all_projects = !!user.auto_add_to_all_projects;
        })

        if (state.adminType === AdminManagementTypes.users ||
            state.adminType === AdminManagementTypes.apiKeys) {
            state.projectListIdx = 0;
            let userId = '';

            if (state.userList.length > 0) {
                userId = state.userList[state.userListIdx].user_id;
            }
            const userIdx = findUserIndex(state.userList, userId);

            state.userList = userList;
            state.userListIdx = userIdx;
            
            state.selectedUser = state.userList[userIdx];
            if (state.adminState === EProjectState.ready) {
                state.adminState = EProjectState.initComplete;
            }
        }
    } else {
        state.userListIdx = -1;
        state.selectedUser = {} as ZUserMin;
    }
    state.adminState = EProjectState.ready;

    return state;

}

const setProjectListIdx = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetProjectListIndex;
    const state = {...initialState}; 

    const projectIdx = action.payload.index
    state.projectListIdx = projectIdx;
    state.selectedProject = state.projectList[projectIdx];
    
    state.adminState = EProjectState.ready;
    state.projectSolutions = undefined;
    // logger.log(`Project List index: ${state.projectListIdx}`)

    return state;
}

const setApiKeyListIdx = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetApiKeyListIndex;
    const state = {...initialState}; 

    const apikeyIdx = action.payload.index
    state.apiKeyListIdx = apikeyIdx;
    state.selectedApiKey = state.apiKeyList[apikeyIdx];
    
    state.adminState = EProjectState.ready;
    return state;
}

const buildCertRowData = (cert: Certificate, projectId: string): CertDataRow => {
    const certRow: CertDataRow = {
        open: false, 
        projectId,
        certObj: new CertificateObject(cert)
    }

    return certRow;
}

const setProjectCertList = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetProjectCertList;
    const state = {...initialState};

    const apiCertList = action.payload.certList;
    state.certList.length = 0;
    state.certData.length = 0;
    state.certListIdx = 0;

    apiCertList.forEach((cert: Certificate) => {
        state.certList.push(cert);
        state.certData.push(buildCertRowData(cert, action.payload.projectId));
    })
    
    state.adminState = EProjectState.ready;
    return state;
}

const setCertListIdx = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetCertListIndex;
    const state = {...initialState};

    const idx = action.payload.index;
    state.certListIdx = (idx < state.certList.length) ? idx : 0;

    return state;
}

const toggleCertDetails = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AToggleCertDetails;
    const state = {...initialState};

    const idx = action.payload.index;
    if (idx < state.certList.length) {
        state.certData[idx].open = !state.certData[idx].open
    }

    return state;
}

// When the project list is loaded it is turned into a map.  It is also set as the 
// project list.  If the project list for a user is later set (which will load a new
// user list), we still have all the original project defined in the map and their
// corresponding project objects are still saved.
const setAdminProjectList = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetAdminProjectList;
    const state = {...initialState}; 

    const projectList = sortProjectsByField( action.payload.projectList, 'project_name' );
    const oldProjList = state.projectList;
    state.projectList = projectList;
    state.totalAllProjects = projectList.length;
    state.projectSolutions = undefined;
    state.selectedSolutions = {} as BooleanMap;

    if (projectList.length > 0) {
        if (state.adminType === AdminManagementTypes.projects || 
            state.adminType === AdminManagementTypes.certs) {
            state.userListIdx = 0;
            let projectId = '';

            state.allProjectsMap = {};
            projectList.forEach((project) => {
                state.allProjectsMap[project.project_id] = project;
            });

            if (state.savedProjectId.length > 0) {
                projectId = state.savedProjectId;
                state.savedProjectId = '';
            } else if (oldProjList.length > 0) {
                projectId = oldProjList[state.projectListIdx].project_id;
            }

            const projectIdx = findProjectIndex(projectList, projectId);
            state.projectListIdx = projectIdx;

            state.selectedProject = state.projectList[projectIdx];
            if (state.adminState === EProjectState.ready) {
                state.adminState = EProjectState.initComplete;
            }
        }
    } else {
        state.projectListIdx = -1;
        state.selectedProject = {} as ZProjectMin;
    }

    state.adminState = EProjectState.ready;

    return state;
}

const setProjectUserList = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState}; 
    const action = actions as ASetProjectUserList;

    const userList = sortUsersByField( action.payload.userList,  'name');

    state.checkedUsers = {};
    state.userList.length = 0;
    if (userList.length > 0) {
        const userIdx = 0;
        for (let i = 0, len = userList.length; i < len; i++) {
            const usr = userList[i];
            if (usr && usr.user_id) {
                state.userList.push(state.allUsersMap[usr.user_id]);
            }
        }

        state.selectedUser = userList[userIdx];
        state.userListIdx = userIdx;
        state.userList.forEach(usr => {
            state.checkedUsers[usr.user_id] = false;
        });
    } else {
        state.userListIdx = -1;
    }

    const projectUserMap: ZUserMap = {};

    let potentialUsers = Object.keys(state.allUsersMap);
    // walk the array and make a map of the projects the user currently has
    userList.forEach(usr => {
        projectUserMap[usr.user_id] = usr;
    })

    // remove the projects Ids that the user is associated with from the array.
    potentialUsers = potentialUsers.filter(usr => {
        return (projectUserMap[usr] === undefined)
    })

    // build an array of projects that the user is not associated with
    const missingUsers: ZUserMin[] = [];
    state.popupCheckState.length = 0;

    potentialUsers.forEach( id => {
        missingUsers.push(state.allUsersMap[id]);
        state.popupCheckState.push(false);
    })
    state.potentialProjectUserList = missingUsers;

    state.adminState = EProjectState.ready;
    return state;
}

const setUserProjectList = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState}; 
    const action = actions as ASetUserProjectList;

    const projectList = sortProjectsByField( action.payload.projectList,  'name');

    state.checkedProjects = {};
    state.projectList.length = 0;
    if (projectList.length > 0) {
        const projIdx = 0;
        for (let i = 0, len = projectList.length; i < len; i++) {
            state.projectList.push(state.allProjectsMap[projectList[i].project_id]);
        }
        state.projectList = projectList;

        state.selectedProject = projectList[projIdx];  // projIdx is 0 here
        state.projectListIdx = projIdx;
        projectList.forEach(proj => {
            state.checkedProjects[proj.project_id] = false;
        });
    } else {
        state.projectListIdx = -1;
    }

    const userProjectMap: ZProjectMap = {};

    let potentialProjects = Object.keys(state.allProjectsMap);
    // walk the array and make a map of the projects the user currently has
    projectList.forEach(project => {
        userProjectMap[project.project_id] = project;
    })

    // remove the projects Ids that the user is associated with from the array.
    potentialProjects = potentialProjects.filter(project => {
        return (userProjectMap[project] === undefined)
    })

    // build an array of projects that the user is not associated with
    const missingProjects: ZProjectMin[] = [];
    state.popupCheckState.length = 0;

    potentialProjects.forEach( id => {
        missingProjects.push(state.allProjectsMap[id]);
        state.popupCheckState.push(false);
    })
    state.potentialUserProjectList = missingProjects;

    state.adminState = EProjectState.ready;
    return state;
}

const setUserAPIKeyList = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetUserApiKeyList;

    let oldSelectedKeyId = '';
    if (state.apiKeyList.length > 0) {
        oldSelectedKeyId = state.apiKeyList[state.apiKeyListIdx].api_key_id;
    }
    const keyList = action.payload.apikeyList;
    keyList.sort((k1, k2) => { return (k1.name < k2.name) ? -1 : 1})

    state.checkedApiKeys = {};
    state.apiKeyList.length = 0;
    state.numActiveKeys = 0;
    const now = moment();
    if (keyList.length > 0) {
        let keyIdx = 0;
        if (oldSelectedKeyId.length > 0) {
            keyIdx = keyList.findIndex(key => {return key.api_key_id === oldSelectedKeyId});
            keyIdx = (keyIdx === -1) ? 0 : keyIdx;
        }

        state.apiKeyList.length = 0;
        state.apiKeyList = keyList;

        state.selectedApiKey = keyList[keyIdx];  // projIdx is 0 here
        state.apiKeyListIdx = keyIdx;
        keyList.forEach(key => {
            if (moment(key.expires_at) > now) {
                state.numActiveKeys++;
            }
            state.checkedApiKeys[key.api_key_id] = false;
        });
    } else {
        state.apiKeyListIdx = -1;
    }
    state.adminState = EProjectState.ready;
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleApply = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState}; 
    const action = actions as AApplySettings;

    state.potentialProjectUserList.length = 0;
    state.checkedProjects = {};
    state.checkedUsers = {};
    state.checkedApiKeys = {};
    state.popupCheckState.length = 0;
    state.adminState = EProjectState.dataLoadInProgress;

    state.password =  '';
    state.newPassword =  '';
    state.confirmPassword =  '';
    state.selectedSolutions = {};
    state.solutionsChanged = false;
    if (action.payload.optionalArgs && action.payload.optionalArgs.project_id) {
        state.savedProjectId = action.payload.optionalArgs.project_id;
    }
    
    return state;
}

const toggleListSelection = (state: State, collection: ZAdminMapType, id: string): State => {    
    const arrayMap = {
        [ZAdminMapType.SelectedUserProject]: 'checkedProjects',
        [ZAdminMapType.SelectedProjectUsers]: 'checkedUsers',
        [ZAdminMapType.SelectedUserApiKey]: 'checkedApiKeys',
    }

    const checkType = arrayMap[collection];
    if (checkType) {
        if ((state[checkType])[id] !== undefined) {
            (state[checkType])[id] = !(state[checkType])[id];
        }
    }

    return state;
}

const toggleAdminSelection = (initialState: State, actions: actionTypes): State => {
    let state = {...initialState}; 
    const action = actions as AToggleAdminSelection;
    state = toggleListSelection( state, action.payload.collectionName, action.payload.id);

    return state;
}

const toggleAdminPopupVisibility = (initialState: State, actions: actionTypes): State => {
    const action = actions as AToggleAdminPopup;
    const state = {...initialState}; 

    const popupOpen = state.popupOpen = action.payload.popupOpen;
    state.popupType = popupOpen ? action.payload.popupType : ZFeaturePopupTypes.NoPopup;

    switch (state.popupType) {
    case ZFeaturePopupTypes.EditUser: {
            const {first_name: firstName, last_name: lastName, email, phone, role, auto_add_to_all_projects } = state.selectedUser;
            validateProfileData( state, firstName as string, lastName as string, 
                                email as string, phone as string);
            state.role = role;
            state.addUserToAllProjects = auto_add_to_all_projects
        }
        break;
    
    case ZFeaturePopupTypes.CreateUser:
        validateProfileData( state, '', '', '', '');
        state.role = ZUserTypes.user;
        state.addUserToAllProjects = false;
        break;
    
    case ZFeaturePopupTypes.GenerateKey:
        state.keyName = '';
        state.keyCreation = moment();
        state.keyExpire = moment().add('3', 'month');
        state.keyPermission = ApiKeyPermission.ro;
        break;

    case ZFeaturePopupTypes.EditKey: {
        const keyIdx = action.payload.value as number;
        if (keyIdx !== undefined &&  keyIdx >= 0) {
            const key = state.apiKeyList[keyIdx]
            state.keyName = key.name;
            state.keyExpire = moment(key.expires_at);
            state.keyPermission = key.permission;
        }}
        break;

    case ZFeaturePopupTypes.NewAPIKeyCreated: 
        state.newKey = (!popupOpen) ? {} as ZApiKey : action.payload.value as ZApiKey;
        break;

    case ZFeaturePopupTypes.NoPopup:
        break;

    case ZFeaturePopupTypes.EditProject:
        state.projectName = state.projectList[state.projectListIdx].name;
        state.selectedSolutions = buildEditProjectSolutionList(state)
        break;
        
    case ZFeaturePopupTypes.CreateProject:
        state.projectName = '';
        state.selectedSolutions = {};
        state.systemProjectSolutions.forEach((solution: string) => { state.selectedSolutions[solution] = false; });
        break;

    default:
        state.projectName = '';
        state.keyName = '';
        state.keyCreation = moment();
        state.keyExpire = moment();
        state.keyPermission = ApiKeyPermission.ro;
        break;
    }

    return state;
}

const validatePwdAction = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AValidatePassword;
    const state = {...initialState};
    const { oldPwd, newPwd, confirmPwd} =  action.payload;

    validatePwdData( state, oldPwd, newPwd, confirmPwd)

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initUpdateProfile = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};
    const user = {} as ZUserMin;
    
    initProfileData(state, user.first_name, user.last_name, user.email, user.phone)

    return state;
}

const validateUserProfile = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AValidateProfile;
    const state = {...initialState};
    const { firstName, lastName, email, phoneNumber } = action.payload;
    validateProfileData( state, firstName, lastName, email, phoneNumber);

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const toggleShowExpiredApiKeys = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};

    state.showExpiredKeys = !state.showExpiredKeys;

    return state;
}

const setApiKeyState = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetApiKeyState;
    const state = {...initialState};
    const {friendlyName, expirationDate, permission} = action.payload;

    state.keyName = friendlyName;
    state.keyExpire = expirationDate;

    if (permission !== undefined) {
        state.keyPermission = permission;
    }
    
    return state;
}

const setUserType = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetUserRole;
    const state = {...initialState};
    state.role = action.payload.role;
    return state;
}

const deleteUser = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ADeleteUser;
    const state = {...initialState};

    const userId = action.payload.userId;
    if (state.userList[state.userListIdx].user_id === userId) {
        state.userList.splice(state.userListIdx, 1)
        state.userListIdx = 0;
        delete state.allUsersMap[userId];
    }
    
    return state;
}

const setProjectName = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as ASetProjectName;
    const state = {...initialState};

    state.projectName = action.payload.projectName;
    return state;
}

const addUserToAllProjects = ( initialState: State, actions: actionTypes ): State => {
    const action = actions as AAddUserToAllProjects;
    const state = {...initialState};

    state.addUserToAllProjects = action.payload.addToAllProjects;
    return state;
}

const dataGroupLoad =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ADataGroupLoad;

    if (action.payload.feature === 'admin') {
        state.dataLoading = action.payload.inProgress;
    }
    return state;
}

const buildEditProjectSolutionList = (state: State): BooleanMap => {

    const projectSolutions = state.projectSolutions === undefined ? []: state.projectSolutions;
    const selectedSolution = {} as BooleanMap;

    state.systemProjectSolutions.forEach((solution: string) => { selectedSolution[solution] = false})
    for (let i=0, len=projectSolutions.length; i < len; i++) {
        delete selectedSolution[projectSolutions[i]]
    }

    return selectedSolution;
}

const setProjectSolutions =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetProjectSolutions;

    const projectSolutions = action.payload.solutions;
    state.projectSolutions = projectSolutions;

    state.selectedSolutions = buildEditProjectSolutionList(state);
    state.solutionsChanged = false;
    return state;
}

const setSystemProjectSolutions = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetSystemProjectSolutions;

    state.systemProjectSolutions.length = 0;
    const solutions = action.payload.solutions;
    solutions.forEach((solution: ProjectSolutions) => {state.systemProjectSolutions.push(solution.solution_name)})

    return state;
}

const setSelectedProjectSolutions = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetSelectedProjectSolutions;

    const { solution, isSelected } = action.payload;

    if (solution && solution.length > 0) {
        state.selectedSolutions[solution] = isSelected;

        let changed = false;
        const keys = Object.keys(state.selectedSolutions);
        keys.forEach((key: string) => {
            changed = changed || state.selectedSolutions[key]
        })
        state.solutionsChanged = changed;

    } else {  
        // if no solutions provided, reset the selected solutions to false
        const keys = Object.keys(state.selectedSolutions)
        for (let i=0, len=keys.length; i < len; i++) {
            state.selectedSolutions[keys[i]] = false;
        }
        state.solutionsChanged = false;
    }

    return state;
}

type ActionFunction = ((state: State, action: actionTypes) => State);
type AdminActions = ActionKeys.SET_PROJECTS | ActionKeys.CHANGE_PROJECTS | ActionKeys.SET_NAV_ITEM |
                    ActionKeys.SET_USER_LIST_IDX | ActionKeys.APPLY_SETTINGS | ActionKeys.ADD_USER_TO_ALL_PROJECTS |
                    ActionKeys.SET_ADMIN_USER_LIST | ActionKeys.SET_ADMIN_PROJECT_LIST | ActionKeys.SET_PROJECT_NAME |
                    ActionKeys.SET_PROJECT_LIST_IDX | ActionKeys.SET_USER_LIST_IDX | ActionKeys.SET_APIKEY_LIST_IDX |
                    ActionKeys.SET_USER_PROJECT_LIST | ActionKeys.SET_PROJECT_USER_LIST |
                    ActionKeys.SET_USER_APIKEY_LIST | ActionKeys.TOGGLE_ADMIN_POPUP | ActionKeys.VALIDATE_PROFILE |
                    ActionKeys.TOGGLE_ADMIN_SELECTION | ActionKeys.TOGGLE_ADMIN_POPUP_CHECKMARK | ActionKeys.DATA_GROUP_LOAD |
                    ActionKeys.VALIDATE_PWD | ActionKeys.INIT_UPDATE_PROFILE | ActionKeys.SET_USER_ROLE | 
                    ActionKeys.TOGGLE_SHOW_EXPIRED_API_KEYS | ActionKeys.SET_API_KEY_STATE | ActionKeys.DELETE_USER |
                    ActionKeys.SET_PROJECT_CERT_LIST | ActionKeys.SET_CERT_LIST_IDX | ActionKeys.TOGGLE_CERT_DETAILS | 
                    ActionKeys.SET_PROJECT_SOLUTIONS | ActionKeys.SET_SYSTEM_PROJECT_SOLUTIONS | ActionKeys.SET_SELECTED_PROJECT_SOLUTIONS;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in AdminActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.SET_PROJECTS]: initAdminData,
    [ActionKeys.CHANGE_PROJECTS]: initAdminData,
    [ActionKeys.SET_ADMIN_USER_LIST]: setAdminUserList,
    [ActionKeys.SET_ADMIN_PROJECT_LIST]: setAdminProjectList,
    [ActionKeys.SET_PROJECT_SOLUTIONS]: setProjectSolutions,
    [ActionKeys.SET_USER_LIST_IDX]: setUserListIdx,
    [ActionKeys.SET_PROJECT_LIST_IDX]: setProjectListIdx,
    [ActionKeys.SET_APIKEY_LIST_IDX]: setApiKeyListIdx,
    [ActionKeys.SET_CERT_LIST_IDX]: setCertListIdx,
    [ActionKeys.SET_USER_PROJECT_LIST]: setUserProjectList,
    [ActionKeys.SET_PROJECT_USER_LIST]: setProjectUserList,
    [ActionKeys.SET_USER_APIKEY_LIST]: setUserAPIKeyList,
    [ActionKeys.SET_PROJECT_CERT_LIST]: setProjectCertList,
    [ActionKeys.TOGGLE_CERT_DETAILS]: toggleCertDetails,

    [ActionKeys.SET_NAV_ITEM]: setNavItem,
    [ActionKeys.TOGGLE_ADMIN_POPUP]: toggleAdminPopupVisibility,
    [ActionKeys.TOGGLE_ADMIN_SELECTION]: toggleAdminSelection,
    [ActionKeys.TOGGLE_ADMIN_POPUP_CHECKMARK]: toggleAdminPopupCheckmark,
    [ActionKeys.APPLY_SETTINGS]: handleApply,
    [ActionKeys.VALIDATE_PROFILE]: validateUserProfile,
    [ActionKeys.VALIDATE_PWD]: validatePwdAction,
    [ActionKeys.INIT_UPDATE_PROFILE]: initUpdateProfile,
    [ActionKeys.SET_USER_ROLE]: setUserType,
    [ActionKeys.TOGGLE_SHOW_EXPIRED_API_KEYS]: toggleShowExpiredApiKeys,
    [ActionKeys.SET_API_KEY_STATE]: setApiKeyState,
    [ActionKeys.DELETE_USER]: deleteUser,
    [ActionKeys.SET_PROJECT_NAME]: setProjectName,
    [ActionKeys.ADD_USER_TO_ALL_PROJECTS]: addUserToAllProjects,
    [ActionKeys.DATA_GROUP_LOAD]: dataGroupLoad,
    [ActionKeys.SET_SYSTEM_PROJECT_SOLUTIONS]: setSystemProjectSolutions,
    [ActionKeys.SET_SELECTED_PROJECT_SOLUTIONS]: setSelectedProjectSolutions,
}

function adminUIState(initialState: State, action: actionTypes ): State {
    let state: State = initialState;

    if ( initialState === undefined ) {
        state = Object.assign({}, adminInitData);
    }

    const fct: ActionFunction | undefined = actionMap[action.type];
    if (fct) {
        logger.log(` >>>> adminUIState before: action ${action.type}, ${state.adminType} `)
//         logger.log(`actionKey: ${action.type}  adminState:  ${state.adminState}
// state: ${JSON.stringify(state)}`)
        state = (fct)(state, action);
        // logger.log(`after update adminState:  ${state.adminState}`)
        logger.log(` >>>> adminUIState after: action ${action.type}, ${state.adminType} `)

    } 

    return  state;
}

export default adminUIState;
