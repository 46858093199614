/* eslint-disable react/prop-types */
import * as React from 'react';
import Modal from 'react-bootstrap/Modal';

import ZButton from '../../shared/ZButton';

import { ProfilePwdValidationState } from '../../shared/authUtils';
import { MISC_STRINGS, ACCOUNT_STRINGS, ADMIN_STRINGS as Strs } from '../../shared/strings';
import { ZUserTypes } from '../../data/queryResultDefinitions';
import ZInputTextBox from '../../shared/ZInputText';

export interface CreateEditUserProps {
    show: boolean,
    editingUser: boolean,
    isCurrentUser: boolean,
    createUserState: ProfilePwdValidationState,
    currentRole: ZUserTypes,
    showZAdmin?: boolean,
    addToAllProjects: boolean,

    setRole: (role: ZUserTypes) => void,
    addUserToAllProjectsHandler: (addUser: boolean) => void,
    validateEmail: (email: string) => void,
    onHide: () => void,
    disableApply: () => boolean,
    handleApply: () => void,
}

// tslint:disable-next-line: variable-name
const CreateEditUser: React.FunctionComponent<CreateEditUserProps> = props => {
    const { profileValidation, email } = props.createUserState;
    const role = props.createUserState.role;
    
    let title = Strs.createNewUser;
    let applyTitle = Strs.createNewUser;
    let roleDescript = <span></span>;

    const showValidateMsg = !props.editingUser && !profileValidation.emailValid ?  ACCOUNT_STRINGS.emailRule : '';
    let paddingCSS = '';
    let emailInput = <div></div>;
    let addToProjects = props.addToAllProjects;
    let addToUserDisabled = false;

    const lcCurrentRole = props.currentRole.toLowerCase();     // zadmin case
    const isCurrentlyAdmin = (lcCurrentRole.indexOf(ZUserTypes.admin) !== -1);
    const isSelectedRoleAdmin = (role === ZUserTypes.admin) || (role === ZUserTypes.zadmin)

    if (isSelectedRoleAdmin) {
        addToUserDisabled = true;
        addToProjects = true;
    }

    const addUserMsg = <span>{ Strs.addUserToAllProjects}</span>
    let addUserNote = <span>&nbsp;</span>
    if (props.editingUser) {
        roleDescript = <div>{Strs.currentRole}<strong>{props.currentRole}</strong></div>

        title = Strs.editUser;
        applyTitle = Strs.updateUser;

        if (isCurrentlyAdmin) {
            addUserNote = <span>{Strs.noteAdminDemotion}</span>;
        } else if (isSelectedRoleAdmin) {
            addUserNote = <span>{Strs.noteAddUserToProjects}</span>
        } 
    } else {
        paddingCSS = 'pad-top-10';
        
        if (isSelectedRoleAdmin) {
            addUserNote = <span>{Strs.noteAddUserToProjects}</span>
        }

        emailInput = (
            <>
            <div className="popup-title">{Strs.userProfile}</div>
            <div className="row">
            <div className="col-md-6 margin-1em">
                <div className="create-user-padding">
                    <ZInputTextBox 
                    label={MISC_STRINGS.emailAddress}
                    value={email}
                    ikey="email"
                    onTextChange={(txt: string): void => { props.validateEmail(txt)}}
                    disabled={false}
                    maxLength={255}
                    placeholderText={MISC_STRINGS.emailAddress}
                    validationMsg={showValidateMsg}
                />
                </div>
            </div>
        </div>
        </>
        );
    }

    const disabled = props.disableApply();
    const handleDoCmd = (): void => {
        if (!disabled) {
            (props.handleApply)();
            (props.onHide)()
        }
    }

    const handleRoleChange = (userRole: ZUserTypes): void => {
        if (props.createUserState.role !== userRole) {
            (props.setRole)(userRole);
        }
    }

    const buildRoleHandling = (): JSX.Element => {
        let zadmin = <span></span>;
        if (props.showZAdmin) {
            zadmin = (
                <div className="role-radio-btns"><label>
                    <input type="radio" value={ZUserTypes.zadmin} checked={role === ZUserTypes.zadmin}
                            onChange={(): void => {handleRoleChange(ZUserTypes.zadmin); }}/>
                    &nbsp;&nbsp;<strong>{Strs.zadmin}</strong> - {Strs.zadminDesc}</label>
                </div>
            );
        }
        return (
            <div className="row pad-top-10">
                <div className="col-md-12">
                <div className="role-radio-btns"><label>
                    <input type="radio" value={ZUserTypes.user} checked={role === ZUserTypes.user}
                    onChange={(): void => {handleRoleChange(ZUserTypes.user)}}/>
                            &nbsp;&nbsp;<strong>{Strs.user}</strong> - {Strs.userDesc}</label>
                </div>
                <div className="role-radio-btns"><label>
                    <input type="radio" value={ZUserTypes.dev} checked={role === ZUserTypes.dev}
                        onChange={(): void => {handleRoleChange(ZUserTypes.dev)}}/>
                        &nbsp;&nbsp;<strong>{Strs.dev}</strong> - {Strs.devDesc}</label>
                </div>
                <div className="role-radio-btns"><label>
                    <input type="radio" value={ZUserTypes.admin} checked={role === ZUserTypes.admin}
                        onChange={(): void => {handleRoleChange(ZUserTypes.admin); }}/>
                        &nbsp;&nbsp;<strong>{Strs.admin}</strong> - {Strs.adminDesc}</label>
                </div>
                {zadmin}
                </div>
            </div>
        )
    }

    const roleMarkup = (! props.isCurrentUser) ? buildRoleHandling() : <span>{Strs.editRoleSelf}</span>;


    let addToProjectsMarkup = <span></span> 
    if (! props.isCurrentUser) {
        addToProjectsMarkup = (
            <div className="add-to-all-projects margin-1em">
                <input type="checkbox" checked={addToProjects} disabled={addToUserDisabled}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.addUserToAllProjectsHandler(e.currentTarget.checked) }} >
                </input>&nbsp;&nbsp;{addUserMsg}
                <div className="margin-1em smaller-text">{addUserNote}</div>
            </div>
        );
    }   

    const selectUserRole = props.editingUser ? Strs.changeUserRole : Strs.selectUserRole;
    return (
        <Modal onHide={(): void => { props.onHide() }} show={props.show} size='xl' dialogClassName="zmodal" backdrop="static">
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {title}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="popup">
                <div className="margin-1em">
                {emailInput}
                <div className={'row ' + paddingCSS}>
                    <div className="col-md-12">
                        <div className="popup-title">{roleDescript}</div>
                        <div className="popup-subtitle"><strong>{selectUserRole}</strong></div>
                    </div>
                </div>
                {roleMarkup}
                </div>
                {addToProjectsMarkup}
            </div>
            </Modal.Body>
            <Modal.Footer>
                <ZButton disabled={props.disableApply()} onClick={(): void => { handleDoCmd()}} >{applyTitle}</ZButton>
                <ZButton onClick={(): void => { props.onHide()} } >{MISC_STRINGS.cancelBtn}</ZButton>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateEditUser;