import { convertStringToStringArray } from './utilities';
import { certUpdateBody } from '../data/queryResultDefinitions';
import { ZPost, ZPut } from './backend';
import logger from './logUtilities';

export const certServerAction = async (url: string, certChain: string, certPrivate: string, 
                                       projectId?: string): Promise<void> => {

    const endpointBody: certUpdateBody = {
        "cert_chain": condenseCert(certChain),
        "private_key": condenseCert(certPrivate)
    }

    if (projectId === undefined) {
        await ZPut(url, JSON.stringify(endpointBody));
        
    } else {
        endpointBody.project_id = projectId;
        await ZPost(url, JSON.stringify(endpointBody));
    }

    logger.log(endpointBody);
}


const condenseCert = (certs: string): string => {
    const certBody = convertStringToStringArray(certs);
    let condensedCert = '';

    for (let i = 0, len = certBody.length; i < len; i++) {
        const line = certBody[i];
        if (line.indexOf('-----BEGIN ') !== -1) {
            condensedCert += `${line}\n`;
        } else if (line.indexOf('-----END ') !== -1) {
            condensedCert += `\n${line}\n`;
        } else {
            condensedCert += line;
        }
    }
    return condensedCert;
}