/* eslint-disable id-blacklist */
import { ProjectToDTypeMapping } from './uiAccessors';
import { EProjectState, serviceDisplayEnum, projectDisplayEnum, sessionStorageStates, 
         SysFeatureEnums, ZEnvironments } from './reducerEnums';

import { actionTypes, ActionKeys, AChangeProjects, ARouterLocationChange, ASetNavItem, ARedirectTo,
         AShowNotification, AResetProjects, ASetHamburger, AChangeEnvironment, ASetUriCodeIdx, ASetCurrentServiceEvtIdx, 
         ASetProjURICodes, ASetProjects, ASetProjectServices, AApplySettings, ACreateNewService, ADataLoadInProgress,  
         ASetAdminProjectList, ASetProjectFeaturesAttributes, ADataGroupLoad, ASetSystemProjectSolutions, /* ADeleteService */
    } from '../actions/actionCreatorTypes';

import { StringMap, ZSessionStorage } from '../data/metricsAndOptionsDefs';
import { ZServiceExt, ZEventConfig, ZServiceFeatures, DNSDirectorAttributes, ProjectSolutions, /* ServiceStatusEnum, */
         ProjectMetricKeyResponse, ProjUriCode, FeatureDef, ZProjectMin, ZProductFeatureAttributes } 
    from '../data/queryResultDefinitions';

import { NotificationData } from '../shared/Notification';
import { ProjectDspType, NotificationStyles, ZALL_SERVICES } from '../shared/constants';
import { NAVSTRINGS, MISC_STRINGS } from '../shared/strings';
import logger from '../shared/logUtilities';
import cloneDeep from 'lodash/cloneDeep';
import ZURLS from '../shared/urls';

import { getSessionStorage, removeSessionStorage } from '../shared/utilities'

export interface ZService extends ZServiceExt {
    serviceName: string;
    serviceId: string;
}

export interface CacheableServiceCheck {
    [serviceId: string]: boolean;
}

export interface ServiceMapById {
    [serviceId: string]: ZService;
}

interface EnvironData {
    envName: string;
    allServiceNames: string[];
    allServices: ZService[];
    serviceNameToId: StringMap;
    cachableService: CacheableServiceCheck;
}

export interface _CurrentProject extends ZProjectMin {
    optionKeys: ProjectMetricKeyResponse;
    projectId: string;
    projectType: ProjectDspType;

    // Notes:
    // serviceList is set based on navItem/SubName and env.  So if we are in analytics, service list
    // wiil contain the deployed services for the selected environment.  If we are in service management;
    // servicelist will contain all services for the environment.
    servicesList: ZService[];

    serviceIdToCDNMap: StringMap, 
    serviceIdToHealthChkMap: StringMap,
    serviceIdMap: ServiceMapById;               // all services (regardless of env)
    projectFeatures: ZServiceFeatures;          // union of features from all servies
    serviceNames: string;                       // comma separated string of service_names of current env
    currentServiceIds: string;                  // comma separated string of service_ids of current env

    uriCodes: ProjUriCode[]
    currentServiceEnvironment: ZEnvironments;
    currentEnv: EnvironData;
    prodEnv: EnvironData;
    stagingEnv: EnvironData;
}

export interface State {
    projectsLoaded: boolean;
    servicesLoaded: boolean;
    dataLoading: boolean;
    uriCodesLoaded: boolean;
    projectFeaturesLoaded: boolean;
    firstSvcLoadForProject: boolean,

    _projectList: ZProjectMin[];
    _currentProject: _CurrentProject;
    _projectObj: {                   // get project by Id rather than index
        [projectId: string]: ZProjectMin;
    };
    systemProjectSolutions: string[];
    currentFeature: SysFeatureEnums;
    projectDspTypeMap: ProjectToDTypeMapping;
    currentProjectDspInfo: ProjectDspType;
    projectFeatures: ZServiceFeatures;
    
    systemUIState: EProjectState;
    showingEvents: boolean;
    loadService: serviceDisplayEnum;
    showProject: projectDisplayEnum;
    navItem: string;
    notificationData: NotificationData;
    redirectUrl: string;
    
    // serviceEvents is the array that contains the information in the service menu.
    serviceEvents: string[];
    serviceEvtIdx: number;

    // these next 2 are not currently really used
    eventInfo: ZEventConfig[];
    eventNames: string[];

    // these refer to the current project uri codes.
    uriCodeIdx: number;
    
    hamburgerOpen: boolean;
    savedServiceId: string;
    sessionStateChanged: sessionStorageStates;
    svcProdFeatureMap: ZProductFeatureAttributes,
    ddAutoSwitchEnabled: boolean,
    projHasCSM: boolean,
    projHasDnsDir: boolean,
}

const initSysData: State = {
    projectsLoaded: false,
    projectFeaturesLoaded: false,
    servicesLoaded: false,
    dataLoading: false,
    uriCodesLoaded: false,
    firstSvcLoadForProject: true,

    _currentProject: { } as _CurrentProject,
    _projectObj: {  },
    _projectList: [],
    systemProjectSolutions: [],

    showingEvents: false,
    currentFeature: SysFeatureEnums.analytics,
    projectDspTypeMap: {},
    currentProjectDspInfo: ProjectDspType.LEGACY,
    navItem: NAVSTRINGS.analytics,
    notificationData: { show: false, style: NotificationStyles.info, message: '' },
    loadService: serviceDisplayEnum.undefined,
    showProject: projectDisplayEnum.none,

    eventNames: [] as string[],

    serviceEvtIdx: 0,
    systemUIState: EProjectState.ready,
    redirectUrl: '',

    eventInfo: [],
    projectFeatures: {} as ZServiceFeatures,
    serviceEvents: [] as string[],      // array of the current strings shown in service menu. 
                                        // they may be service name or event names.

    hamburgerOpen: false,
    sessionStateChanged: sessionStorageStates.init,
    uriCodeIdx: -1,
    savedServiceId: '',
    svcProdFeatureMap: {} as ZProductFeatureAttributes,
    ddAutoSwitchEnabled: false,
    projHasCSM: false,
    projHasDnsDir: false,
};

export interface SvcDDFeatureItem {
    tab: string;
    loadService: serviceDisplayEnum;
    showProj: projectDisplayEnum;
    hideServiceDropdown?: boolean;
}

export interface SvcDDFeatureMap {
    [feature: string]: SvcDDFeatureItem;
}

export const enumToFeatureMapping: SvcDDFeatureMap = {
    [SysFeatureEnums.account]: {tab: 'accountUpdateProfile', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none },
    [SysFeatureEnums.accountChgPwd]: {tab: 'changePwd', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.accountUpdateProfile]: {tab: 'accountUpdateProfile', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.apiKeys]: {tab: 'viewUsersApiKeys', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.documentation]: {tab: 'accountDocumentation', loadService: serviceDisplayEnum.none,  showProj: projectDisplayEnum.none },
    [SysFeatureEnums.tfaMgmt]: {tab: 'TFAMgmt', loadService: serviceDisplayEnum.none,  showProj: projectDisplayEnum.none },

    [SysFeatureEnums.analytics]: {tab: 'analytics', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.dnsDirAnl]: { tab: 'dnsDir', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.csmAnl]: {tab: 'csm', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.csaAnl]: {tab: 'csa', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.trafficAnl]: {tab: 'traffic', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.nrumAnl]: {tab: 'nrum', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.efrAnl]: {tab: 'efr', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.cacheAnl]: {tab: 'cache', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.wafAnl]: {tab: 'css-waf', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.botAnl]: {tab: 'css-bot', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.logsAnl]:  {tab: 'logs', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    
    [SysFeatureEnums.admin]: {tab: 'users', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.usersAdmin]: {tab: 'users', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.projectsAdmin]: {tab: 'projects', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.apiKeyAdmin]: {tab: 'apikeys', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.certAdmin]: {tab: 'certs', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},

    [SysFeatureEnums.svcMgmt]: {tab: 'viewServices', loadService: serviceDisplayEnum.all, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.viewServices]: {tab: 'viewServices', loadService: serviceDisplayEnum.all, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.editDraftService]: {tab: 'editDraftService', loadService: serviceDisplayEnum.all, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.deleteService]: {tab: 'deleteService', loadService: serviceDisplayEnum.all, showProj: projectDisplayEnum.all, hideServiceDropdown: true},
    [SysFeatureEnums.deployService]: {tab: 'deployService', loadService: serviceDisplayEnum.all, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.createService]: {tab: 'createService', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.all},

    [SysFeatureEnums.orgYourOrgs]: {tab: 'manageYourOrgs', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.orgManageOrgs]: {tab: 'ManageOrgs', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},

    [SysFeatureEnums.csaLiteConfig]: {tab: 'csaLite', loadService: serviceDisplayEnum.deployedSingleService, showProj: projectDisplayEnum.all},
    
    [SysFeatureEnums.billingReports]: {tab: 'trafficUsage', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.billingReportsTrafficUsage]: {tab: 'trafficUsage', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
    [SysFeatureEnums.billingReportsDownloads]: {tab: 'download', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},

    [SysFeatureEnums.cache]: {tab: '', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all },
    [SysFeatureEnums.services]: {tab: '', loadService: serviceDisplayEnum.all, showProj: projectDisplayEnum.justProjects },
    [SysFeatureEnums.reports]: {tab: '', loadService: serviceDisplayEnum.deployed, showProj: projectDisplayEnum.all},
    [SysFeatureEnums.logout]: {tab: '', loadService: serviceDisplayEnum.none, showProj: projectDisplayEnum.none},
}

const isCacheable = (svc: ZService): boolean => {
    let cacheable = false;
    if (svc.rules) {
        const rules = svc.rules;
        for (let k = 0, rlen = rules.length; k < rlen; k++) {
            const rule = rules[k];
            if (rule.features && rule.features.cache && rule.features.cache.enabled) {
                cacheable = true;
                break;
            }
        }
    } else if (svc.features.cache && svc.features.cache.enabled) {
        cacheable = true;
    }

    return cacheable;
}

const initEnv = (envName: ZEnvironments): EnvironData => {
    const env: EnvironData = {
        envName,
        allServiceNames: [],
        allServices: [],
        serviceNameToId: {} as StringMap,
        cachableService: {} as CacheableServiceCheck,
    }

    return env;
};

// initialize a new 'current project' structure
const initCurrentProject = (project: ZProjectMin): _CurrentProject => {
    let currentProj: _CurrentProject = {} as _CurrentProject;
    if (project) {
        currentProj = cloneDeep(project) as _CurrentProject;
    }

    currentProj.optionKeys = { geo: [], origin: [], service_ids: {}, cdns: [], healthChecks: []};
    currentProj.projectId = project.project_id ? project.project_id : '';
    const projectType: ProjectDspType = ProjectDspType.PRODUCTION_STREAMING;

    currentProj.projectType = projectType;
    currentProj.serviceNames = '';
    currentProj.servicesList = [];
    
    currentProj.prodEnv = initEnv(ZEnvironments.prod);
    currentProj.stagingEnv = initEnv(ZEnvironments.staging);
    currentProj.currentEnv = currentProj.prodEnv;
    currentProj.currentServiceEnvironment = ZEnvironments.unknown;
    currentProj.uriCodes = [];

    currentProj.serviceIdMap = {} as ServiceMapById;
    currentProj.currentServiceIds = '';
    currentProj.projectFeatures = {} as ZServiceFeatures;
    currentProj.serviceIdToCDNMap = {} as StringMap;
    currentProj.serviceIdToHealthChkMap = {} as StringMap;

    return currentProj;
}

const initState = (state: State): State => {
    state = cloneDeep(initSysData);

    initCurrentProject({} as ZProjectMin)

    state._currentProject.prodEnv = initEnv(ZEnvironments.prod);
    state._currentProject.stagingEnv = initEnv(ZEnvironments.staging);
    state._currentProject.currentServiceEnvironment = ZEnvironments.prod;

    return state;
}

// called once when projects are set for org
function buildProjectDspTypeMappings(projects: ZProjectMin[]): ProjectToDTypeMapping {
    const map: ProjectToDTypeMapping = {};
    
    for (let i = 0, len = projects.length; i < len; i++) {
        const project = projects[i];

        const pocType = ProjectDspType.PRODUCTION_STREAMING;
        map[project.project_id] = pocType;
    }
    
    return map;
}

// initialize the service based features section
function initServiceFeatures(service: ZService): ZService {
    let features: ZServiceFeatures = {} as ZServiceFeatures;

    features.cache = { enabled: false };
    features.error_free_response = { enabled: false };
    features.network_real_user_metrics = { enabled: false };
    features.metrics_by_geo = { enabled: false }
    features.waf = {enabled: false}

    const initFeature = (ftr: ZServiceFeatures, sFeatures: ZServiceFeatures, featureName: string): ZServiceFeatures => {
        if (sFeatures[featureName] !== undefined) {
            // if (ftr[featureName]) {
                ftr[featureName] = sFeatures[featureName];
            // }
        }
        return ftr;
    }

    if (service.features) {
        const nFeatures = service.features;
        const keys = Object.keys(nFeatures);
        keys.forEach(featureName => { 
            features = initFeature(features, nFeatures, featureName); 
        });
    }
    service.features = features;
    return service;
}

// called to get a union of the features in the services in the selected project
// This union is used to determine what should be shown in the analytics nav
function promoteServiceFeatures( project: _CurrentProject): ZServiceFeatures {
    const services = project.servicesList;

    /* eslint-disable camelcase */
    const projFeatures: ZServiceFeatures = {
        cache: { enabled: false },
        error_free_response: { enabled: false },
        network_real_user_metrics: { enabled: false },
        metrics_by_geo: {enabled: false },
        waf: { enabled: false },
        dnsDir: { enabled: false },
        csm: { enabled: false },
        csa: { enabled: false }
    };

    const waf: FeatureDef = {enabled: false};
    const dnsDir: FeatureDef = {enabled: false};
    const csm: FeatureDef = {enabled: false};
    const csa: FeatureDef = {enabled: false};
    /* eslint-enable camelcase */

    const checkFeature = (features: ZServiceFeatures, featureName: string): FeatureDef => {
    let featureData: FeatureDef = features[featureName];

        if (featureData === undefined) {
            featureData = {enabled: false};
        }
        featureData.enabled = featureData.enabled || projFeatures[featureName].enabled;
        return featureData;
    }

    services.forEach( sd => {
        /* eslint-disable camelcase */
        if (sd.is_live) {
            const features = sd.features;
            projFeatures.cache = checkFeature(features, 'cache');
            projFeatures.error_free_response = checkFeature(features, 'error_free_response');
            projFeatures.network_real_user_metrics = checkFeature(features, 'network_real_user_metrics');
            waf.enabled = waf.enabled || (sd.cloud_service_shield !== undefined);
            dnsDir.enabled = dnsDir.enabled || ((sd.dns_director !== undefined) && (sd.dns_director.cdns !== undefined));
            csa.enabled = csa.enabled || ((sd.cloud_service_accelerator !== undefined) && sd.cloud_service_accelerator.enabled);
            csm.enabled = csm.enabled || (sd.cloud_service_monitor !== undefined);
        }
        /* eslint-enable camelcase */
    });
    projFeatures.waf = waf;
    projFeatures.dnsDir = dnsDir;
    projFeatures.csm = csm;
    projFeatures.csa = csa;
    
    return projFeatures;
}

const buildSvcCdnMap = ( project: _CurrentProject ): StringMap => {
    const cdnMap: StringMap = {} as StringMap;
    if (project.projectFeatures.dnsDir && project.projectFeatures.dnsDir.enabled) {
        const services = project.servicesList;
        const allCdnNamesProd: StringMap = {};
        const allCdnNamesStaging: StringMap = {};

        services.forEach(sd => {
            /* eslint-disable camelcase */
            if (sd.is_live) {
                if (sd.dns_director !== undefined) {
                    const serviceCDNs = sd.dns_director.cdns;
                    if (serviceCDNs) {
                        const cdnKeys = Object.keys(serviceCDNs);
                        cdnMap[sd.serviceId] = cdnKeys.join();
                        cdnKeys.forEach((key: string) => {
                            const cmap: StringMap = (sd.env === ZEnvironments.prod) ? allCdnNamesProd : allCdnNamesStaging;
                            cmap[key] = key;
                        })
                    }
                } else {
                    cdnMap[sd.serviceId] = '';
                }
            }
        });

        let allCdnNameArray = Object.keys(allCdnNamesProd);
        cdnMap[ZALL_SERVICES + ZEnvironments.prod]= allCdnNameArray.join();

        allCdnNameArray = Object.keys(allCdnNamesStaging);
        cdnMap[ZALL_SERVICES + ZEnvironments.staging]= allCdnNameArray.join();
    }

    return cdnMap
}

const manageSessionState = (state: State, sessionState: sessionStorageStates): void => {
    if (state.sessionStateChanged !== sessionStorageStates.init) {
        state.sessionStateChanged = sessionState;
    }
}

const buildSvcHcMap = ( project: _CurrentProject): StringMap => {
    const hcMap: StringMap = {} as StringMap;
    if (project.projectFeatures.csm && project.projectFeatures.csm.enabled) {
        const services = project.servicesList;
        const allHCNamesProd: StringMap = {};
        const allHcNamesStaging: StringMap = {};

        services.forEach(sd => {
            /* eslint-disable camelcase */
            if (sd.is_live && sd.cloud_service_monitor && sd.cloud_service_monitor.enabled) {
                const serviceHCs = sd.cloud_service_monitor.health_checks;
                if (serviceHCs) {
                    const hcKeys = Object.keys(serviceHCs);
                    hcMap[sd.serviceId] = hcKeys.join();
                    hcKeys.forEach((key: string) => {
                        const cmap: StringMap = (sd.env === ZEnvironments.prod) ? allHCNamesProd : allHcNamesStaging;
                        cmap[key] = key;
                    })
                }
            }
        });

        let allHcNameArray = Object.keys(allHCNamesProd);
        hcMap[ZALL_SERVICES + ZEnvironments.prod]= allHcNameArray.join();

        allHcNameArray = Object.keys(allHcNamesStaging);
        hcMap[ZALL_SERVICES + ZEnvironments.staging]= allHcNameArray.join();
    }

    return hcMap
}

const getStartingServiceEnvironment = (project:  _CurrentProject, environment?: string): ZEnvironments => {
    let env = ZEnvironments.unknown;
    if (environment && environment !== ZEnvironments.unknown) {
        env = environment as ZEnvironments;
    }

    if (env.length === 0 || env === ZEnvironments.unknown) {
        env = (project.prodEnv.allServices.length > 0)  ? ZEnvironments.prod : ZEnvironments.staging;
    }

    return env;
}

function setCurrentServiceEnvironment(project: _CurrentProject, env: ZEnvironments): void {
    project.currentServiceEnvironment = env;

    const currentEnv: EnvironData = project[env + 'Env'];
    project.currentEnv = currentEnv;
    project.currentServiceEnvironment = env;

    project.serviceNames = currentEnv.allServiceNames.join();
    const serviceIds: string[] = [];
    currentEnv.allServices.forEach((svc) => { serviceIds.push(svc.serviceId) });
    project.servicesList = currentEnv.allServices;
}

const setServicesLoadedState = (state: State): State => {
    const feature = state.currentFeature;

    const viewMapping: SvcDDFeatureItem =  enumToFeatureMapping[feature];
    state.servicesLoaded = viewMapping && ((viewMapping.loadService === serviceDisplayEnum.none) || 
                           (viewMapping.loadService === serviceDisplayEnum.environmentOnly)) ? true : false;

    return state;
}

// used when we change project.
const initProject = (state: State, projectId: string, env: ZEnvironments): void => {
    const project: ZProjectMin = state._projectObj[projectId];
    logger.log(`   Changing to project id ${projectId}; `, project)

    if (project) {
        const currentProj: _CurrentProject = initCurrentProject(project);
        state._currentProject = currentProj;
        state = setServicesLoadedState(state);
        state.uriCodesLoaded = false;

        const environ = getStartingServiceEnvironment(currentProj, env)
        setCurrentServiceEnvironment(currentProj, environ);
        state.currentProjectDspInfo = state._currentProject.projectType;

        state.loadService = serviceDisplayEnum.none;
        manageSessionState(state, sessionStorageStates.dirty)

        // set up events if they exist
        if (state.showingEvents) {
            logger.alert('systemState.initUiAndNav: System thinks we have ann event.')
        } else {
            state.eventNames.length = 0;
        }

        state.projHasCSM = false;
        state.projHasDnsDir = false;
        state.ddAutoSwitchEnabled = false;

        state.systemUIState = EProjectState.newProjectLoaded;
    } else {
        logger.log(`\nsystemState reducer: bad project id passed to initProject. Bad project id:  ${projectId}` );
    }
}

const initServiceIdsAndCDNs = (state: State, serviceId: string, services: ZService[]): string[] => {
    const serviceIdList: string[] = [];
    const project = state._currentProject;
    let cdns: string[] = [];
    let hcs: string[] = [];

    if (state.showingEvents) {
        const events: ZEventConfig[] | undefined = state.eventInfo;
        if (events) {
            logger.alert('We have events in this service')
        } else {
            logger.log('initServiceIdsAndCDNs: We are suppose to be show events ' + 
                    'but we do not have any.');
        }
    } else {
        if (serviceId === undefined || serviceId === '' || serviceId === ZALL_SERVICES) {
            state.serviceEvtIdx = 0;
            for (let i = 0, len = services.length; i < len; i++) {
                const svcId = services[i].service_id;
                if (svcId !== undefined) {
                    serviceIdList.push(svcId);
                }
            }
            const svcCdnData = project.serviceIdToCDNMap[ZALL_SERVICES+project.currentServiceEnvironment];
            cdns = svcCdnData ? svcCdnData.split(',') : [];
            const svcHealthChkData = project.serviceIdToHealthChkMap[ZALL_SERVICES+project.currentServiceEnvironment];
            hcs = svcHealthChkData ? svcHealthChkData.split(',') : [];
        } else {
            serviceIdList.push(serviceId);
            if (project.serviceIdToCDNMap[serviceId]) {
                // cdns = Object.keys(project.serviceIdToCDNMap[serviceId])
                const svcCdnData = project.serviceIdToCDNMap[serviceId];
                cdns = svcCdnData ? svcCdnData.split(',') : [];

                // hcs = Object.keys(project.serviceIdToHealthChkMap[serviceId ])
                const svcHealthChkData = project.serviceIdToHealthChkMap[serviceId]
                hcs = svcHealthChkData ? svcHealthChkData.split(',') : []
            }
        }
    }

    project.currentServiceIds = serviceIdList.join();
    project.optionKeys.cdns = cdns;
    project.optionKeys.healthChecks = hcs;
    // console.log(`###### ----- systemState.initServicesId: currentServiceIds: ${project.currentServiceIds}; serviceEvtIdx: ${state.serviceEvtIdx}`)
    return serviceIdList
}


// based on whether we are showing services or environment, perform initialization
// function initCurrentServiceData(state: State, svcEvtIndex: number): void {
function initCurrentServiceData(state: State, serviceId: string): void {
    const services = state._currentProject.servicesList;
    const feature = state.currentFeature;
    
    const viewMapping: SvcDDFeatureItem =  enumToFeatureMapping[feature];
    if (!viewMapping ) {
        logger.alert(feature);
    }

    switch (viewMapping.loadService) {
        case serviceDisplayEnum.all:
            initServiceIdsAndCDNs(state, serviceId, services);
            break;

        case serviceDisplayEnum.deployed:
        case serviceDisplayEnum.deployedSingleService:
        case serviceDisplayEnum.environmentOnly: {
            initServiceIdsAndCDNs(state, serviceId, services);
        }
        break;

        case serviceDisplayEnum.none:
        default:
            state.serviceEvtIdx = 0;
            break;
    }
}

// builds data used for project, environment and services menu
const buildNavAndDisplayInfo = (state: State): SvcDDFeatureItem => {
    const feature = featureOverride(state, state.currentFeature);
    
    const viewMapping: SvcDDFeatureItem =  enumToFeatureMapping[feature];
    if (!viewMapping ) {
        logger.alert(feature);
    }

    if (viewMapping.tab.length > 0) {
        // state.selectedSubNavTab = viewMapping.tab;
        state.showProject = viewMapping.showProj;
        state.currentFeature = feature;
    }

    const loadService = viewMapping.loadService;
    if (loadService !== state.loadService) {
        state.loadService = loadService;
        const currentSvcEnv = state._currentProject.currentEnv;

        if (state._projectList.length === 0 || currentSvcEnv.allServiceNames.length === 0) {
            state.serviceEvents = [MISC_STRINGS.noPublishedServices];
            state.serviceEvtIdx = 0;
        } else {
            switch (loadService) {
                // now we only have service list that we need.  reducer doesn't care if it is deployed or all
                case serviceDisplayEnum.all:
                    state.serviceEvents = currentSvcEnv.allServiceNames;
                    // state.serviceEvtIdx = 0;
                    break;

                case serviceDisplayEnum.deployed: {
                    state.serviceEvents = [MISC_STRINGS.allOption].concat(currentSvcEnv.allServiceNames);
                    }
                    break;

                case serviceDisplayEnum.deployedSingleService:
                    state.serviceEvents = currentSvcEnv.allServiceNames
                    break;

                case serviceDisplayEnum.environmentOnly:
                case serviceDisplayEnum.none:
                    state.serviceEvents = [] as string[];
                    break;
                    
                default:
                    break;
            }
        }
    }

    return viewMapping;
}

const setProjects = (initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};
    const action = actions as ASetProjects;

    const projects = action.payload.projects;

    if (projects.length > 0) {
        // sort the project names
        const projectList = projects.sort((a: ZProjectMin, b: ZProjectMin) => {
            return a.name.localeCompare(b.name);
        });

        state._projectList = projectList;

        let projId = action.payload.projectId;
        projId = (projId !== undefined) && (typeof (projId) === 'string') ? action.payload.projectId : '';

        if (projects.length > 0) {
            projects.forEach( proj => { state._projectObj[proj.name] = proj; } )
        }
        state.projectDspTypeMap = buildProjectDspTypeMappings( projects );

        const projectObj = {}
        projectList.forEach( (p: ZProjectMin) => {
            projectObj[p.project_id] = p;
        })
        state._projectObj = projectObj;

        let currentProj = projectObj[projId as string];
        currentProj = (currentProj === undefined) ? projects[0] : currentProj;
        initProject(state, currentProj.project_id, ZEnvironments.unknown);

        state.firstSvcLoadForProject = false;
        state.dataLoading = false;
        state.projectsLoaded = true;
        state.uriCodesLoaded = false;
        state.projectFeaturesLoaded = false;
        state.systemUIState = EProjectState.projectsUpdated;
    } else {
        // following are features a user in an org without project is allowed to be in.
        const allowedFeatures = [
            SysFeatureEnums.accountUpdateProfile, SysFeatureEnums.accountChgPwd, SysFeatureEnums.apiKeys, SysFeatureEnums.tfaMgmt,
            SysFeatureEnums.orgYourOrgs, SysFeatureEnums.admin, SysFeatureEnums.certAdmin, SysFeatureEnums.usersAdmin, SysFeatureEnums.projectsAdmin, 
            SysFeatureEnums.apiKeyAdmin
        ];
        
        const featureIdx = allowedFeatures.indexOf(state.currentFeature);
        state.currentFeature = (featureIdx === -1) ? SysFeatureEnums.accountUpdateProfile : state.currentFeature;

        if (projects.length > 0) {
            projects.forEach( proj => { state._projectObj[proj.name] = proj; } )
        }
        state.projectDspTypeMap = buildProjectDspTypeMappings( projects );
        state._projectObj = {};

        state.dataLoading = false;
        state.projectsLoaded = true;
        state.projectFeaturesLoaded = true;
        state.servicesLoaded = true;
        state.uriCodesLoaded = true;
        state._currentProject = {} as _CurrentProject;
        state._currentProject.name = '__no projects__';
        state._currentProject.servicesList = [];
        state.systemUIState = EProjectState.ready;
    }
    setServicesLoadedState(state);

    return state;
}

const setProjectServices = (initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};
    const action = actions as ASetProjectServices;
    const currentProject = state._currentProject;

    logger.log('systemState.setProjectServices enter: ', state)

    if (action.payload.serviceList && action.payload.serviceList.length > 0) {
        const services = action.payload.serviceList;
        if (action.payload.projectId !== state._currentProject.projectId) {
            logger.alert(`systemState.setProjectServices: proj id of current project doesn't match project of service list.`)
            return state;
        }

        const serviceIdMap = {};
        const serviceList: ZServiceExt[] = services.sort((a: ZServiceExt, b: ZServiceExt) => {
            return a.service_name.localeCompare(b.service_name);
        });

        currentProject.servicesList.length = 0;

        currentProject.prodEnv = initEnv(ZEnvironments.prod);
        currentProject.stagingEnv = initEnv(ZEnvironments.staging);

        for (let i = 0, len = serviceList.length; i < len; i++) {
            // if (serviceList[i].status === ServiceStatusEnum.deleting) {
            //     continue;
            // }
            const service = cloneDeep(serviceList[i] as ZService);
            currentProject.servicesList.push(service);
            // make services id and name Javascript friendly - camel case
            const serviceId = service.service_id;
            service.serviceId = serviceId;
            const serviceName = service.service_name;
            service.serviceName = serviceName;

            initServiceFeatures( service );
            const env: EnvironData = currentProject[service.env + 'Env'];
            env.allServices.push(service);
            env.allServiceNames.push(serviceName)
            env.serviceNameToId[serviceName] = serviceId;
            env.cachableService[serviceId] = isCacheable(service)

            serviceIdMap[serviceId] = service;
        }

        currentProject.serviceIdMap = serviceIdMap;
    } else {
        logger.log('\nsystemState.setProjectServices:  SetProjects was passed an array with 0 elements.');
    }

    let env = action.payload.env;
    env = env && state.firstSvcLoadForProject ? env : ZEnvironments.unknown;

    currentProject.projectFeatures = promoteServiceFeatures(currentProject);
    currentProject.serviceIdToCDNMap = buildSvcCdnMap(currentProject);
    currentProject.serviceIdToHealthChkMap = buildSvcHcMap(currentProject);

    // currentProject.optionKeys.cdns = serviceId && serviceId.length > 0 ? JSON.stringify(currentProject.serviceIdToCDNMap);
    const environ = getStartingServiceEnvironment(currentProject, env)
    state.loadService = serviceDisplayEnum.undefined;
    setCurrentServiceEnvironment(currentProject, environ);

    const serviceId = action.payload.serviceId;
    // console.log(`###### ----- systemState.setProjectServices: env: ${env}; serviceId: ${serviceId}, serviceEvents: ${state.serviceEvents}`);

    buildNavAndDisplayInfo(state);
    initCurrentServiceData(state, serviceId as string)

    state.serviceEvtIdx = 0;
    if (serviceId !== undefined) {
        const svc = state._currentProject.serviceIdMap[serviceId];
        if (svc !== undefined) {
            const name = svc.serviceName;
            const index = state.serviceEvents.indexOf(name);
            state.serviceEvtIdx = (index !== -1) ? index : 0;
        }
    }

    state.firstSvcLoadForProject = true;
    state.servicesLoaded = true;
    manageSessionState(state, sessionStorageStates.dirty)
    state.systemUIState = EProjectState.servicesUpdated;
    state.savedServiceId = '';

    logger.log('systemState.setProjectServices done', state)
    return state;
}

function createNewService(initialState: State, actions: actionTypes): State {
    const action = actions as ACreateNewService;

    const service = cloneDeep(action.payload.service as ZService);
    const currentProject = initialState._currentProject;
    const serviceList = cloneDeep(currentProject.servicesList)
    serviceList.push(service);

    const setServicesAction: ASetProjectServices = {
        type: ActionKeys.SET_PROJECT_SERVICES,
        payload: {
            projectId: currentProject.projectId, 
            serviceList,
            env: ZEnvironments.staging,
            serviceId: service.service_id,
        }
    }

    // psych out the the project services command
    const savedFeature = initialState.currentFeature;
    initialState.currentFeature = SysFeatureEnums.editDraftService;
    const state = setProjectServices(initialState, setServicesAction);
    state.currentFeature = savedFeature;

    state.savedServiceId = service.service_id;
    state.dataLoading = false;
    manageSessionState(state, sessionStorageStates.dirty)

    return state
}

function deleteService(initialState: State): State {
    const state = {...initialState};
    // const action = actions as ADeleteService;
    // const serviceId = action.payload.serviceId;
    // const project = state._currentProject;
    // const services = project.currentEnv.allServices;

    // const idx = services.findIndex((service: ZService) => { return service.serviceId === serviceId });
    // if (idx !== -1) {
    //     services.splice(idx, 1)
    // }

    state.servicesLoaded = false;
    state.serviceEvtIdx = 0;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function reloadServices(initialState: State, actions: actionTypes): State {
    const state = {...initialState};

    state.servicesLoaded = false;
    const serviceName = state.serviceEvents[state.serviceEvtIdx];
    state.savedServiceId = state._currentProject.currentEnv.serviceNameToId[serviceName];
    return state;
}

function changeProject(initialState: State, actions: actionTypes): State {
    const action = actions as AChangeProjects;
    const state = {...initialState};
    
    logger.log('systemState.changeProject Enter');
    if (state._projectList.length > 0) {
        const env = action.payload.environment;
        let projectId: string = (action.payload.projectId) ? action.payload.projectId : state._currentProject.projectId;
        projectId = state._projectObj[projectId] ? projectId : state._currentProject.projectId;
        initProject( state, projectId, env as ZEnvironments);
    }
    const feature = state.currentFeature;
    state.currentFeature = (feature === SysFeatureEnums.csmAnl || feature === SysFeatureEnums.dnsDirAnl) ? SysFeatureEnums.analytics : feature;
    state.firstSvcLoadForProject = false;
    state.projectFeaturesLoaded = false;
    
    logger.log('systemState.changeProject exit');
    return state;
}

 // eslint-disable-next-line @typescript-eslint/no-unused-vars
const applySettings = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as AApplySettings;

    if (action.payload && action.payload.error) {
        state.systemUIState = EProjectState.serverError;
    } else {
        state.systemUIState = EProjectState.ready;
    }

    return state;
}

const routerLocationChange = (initialState: State, actions: actionTypes ): State => {
    const action = actions as ARouterLocationChange;
    const state = {...initialState}; 
    state.navItem = action.payload.pathname.substring(1);
    return state;
}

const saveServiceId = (state: State): State => {
    const oldSvcName = state.serviceEvents[state.serviceEvtIdx];
    if (oldSvcName) {
        let oldSvcId = state._currentProject.currentEnv.serviceNameToId[oldSvcName];
        oldSvcId = oldSvcId !== undefined ? oldSvcId : '';
        state.savedServiceId = state.savedServiceId.length > 0 ? state.savedServiceId : oldSvcId;
    } else {
        state.savedServiceId = '';
    }

    return state;
}

const featureOverride = (state: State, feature: SysFeatureEnums): SysFeatureEnums => {
    const features = state._currentProject.projectFeatures
    if (feature === SysFeatureEnums.analytics) {
        feature = SysFeatureEnums.trafficAnl;

        if (features !== undefined) {
            // state._currentProject.projectFeatures
            if (features.dnsDir && features.dnsDir.enabled) {
                feature = SysFeatureEnums.dnsDirAnl;
            } else if (features.csm && features.csm.enabled) {
                feature = SysFeatureEnums.csmAnl;
            } else if (features.csa && features.csa.enabled) {
                feature = SysFeatureEnums.csaAnl;
            }
        } 
    }
    return feature;
} 

const setNavItem = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetNavItem;
    let state = {...initialState}; 

    const oldTab = state.navItem;
    if (state._projectList.length > 0) {
        state = saveServiceId(state);
    }

    const { navItem, feature } = action.payload;
    state.navItem = navItem;
    state.currentFeature = feature;
    state = setServicesLoadedState(state);

    state.hamburgerOpen = false;
    manageSessionState(state, sessionStorageStates.dirty)
    state.dataLoading = false;
    logger.log(`systemState.setNavItem: old tab ${oldTab}, new tab ${action.payload.feature}, sub nav set to ${state.currentFeature}`)

    return state;
}

const setHamburgerOpen = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState}; 
    const action = actions as ASetHamburger;
    state.hamburgerOpen = action.payload.isOpen;
    return state;
}

// can't change environment before services are loaded.
const changeEnvironment = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState}; 
    const action  = actions as AChangeEnvironment;

    state.systemUIState = EProjectState.serviceEnvironmentChanged;
    
    const env = action.payload.environment;
    const oldSvcName = state.serviceEvents[state.serviceEvtIdx];
    const project = state._currentProject;
    const oldSvc = project.servicesList.find((svc) => oldSvcName === svc.service_name);
    
    manageSessionState(state, sessionStorageStates.dirty)

    // let svcIdx = 0;
    let newSvcId = '';
    if (project.currentServiceEnvironment !== env) {
        if (oldSvc && oldSvc.meta ) {
            const env_info = (env as string) + '_info';
            if (oldSvc.meta[env_info] !== undefined) {
                newSvcId = oldSvc.meta[env_info].service_id;
            }
        }
    }

    state.loadService = serviceDisplayEnum.undefined;  
    setCurrentServiceEnvironment(state._currentProject, env);
    initCurrentServiceData(state, newSvcId);
    buildNavAndDisplayInfo(state);

    const svcIdx = state._currentProject.servicesList.findIndex((svc: ZServiceExt) => {return svc.service_id === newSvcId; })
    state.serviceEvtIdx = (svcIdx === -1) ? 0 : svcIdx;
    
    // state.serviceEvtIdx = svcIdx;
    logger.log(`  ---- systemState.changeEnvironment: new env = ${env}; new svcIdx = ${state.serviceEvtIdx} `)

    return state;
}

const showSysNotification = (initialState: State, actions: actionTypes): State => {
    const action = actions as AShowNotification;
    const state = {...initialState};

    state.notificationData.message = action.payload.message;
    state.notificationData.style = action.payload.style;
    state.notificationData.show = true;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const closeSysNotification = (initialState: State, action: actionTypes): State => {
    const state = {...initialState};
    state.notificationData.message = '';
    state.notificationData.style = NotificationStyles.info;
    state.notificationData.show = false;
    return state;
}

function resetProject(states: State, actions: actionTypes): State {
    const state = {...states};
    const action = actions as AResetProjects;
    
    const projectId = action.payload.projectId;
    const env = state._currentProject.currentServiceEnvironment;
    state.loadService = serviceDisplayEnum.none;
    state.systemUIState = EProjectState.serviceChanged
    initProject(state, projectId, env);

    return state;
}

const setUriCodeIdx = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetUriCodeIdx;
    const state = {...initialState};

    logger.log('uri_code_idx: ' + action.payload.index);

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initSessionData = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    manageSessionState(state, sessionStorageStates.init)
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sessionStateSaved = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    manageSessionState(state, sessionStorageStates.clean)

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const featureReady = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    state.systemUIState = EProjectState.ready;
    if (state.sessionStateChanged === sessionStorageStates.init) {
        state.sessionStateChanged = sessionStorageStates.dirty;
    }
    return state;
}

const setCurrentServiceEvtIdx = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetCurrentServiceEvtIdx;
    const state = {...initialState};

    if (state.serviceEvtIdx !== action.payload.index) {
        state.serviceEvtIdx = action.payload.index;

        const feature = state.currentFeature;
    
        const viewMapping: SvcDDFeatureItem =  enumToFeatureMapping[feature];
        if (!viewMapping ) {
            logger.alert(feature);
        } else {
            if (state.showingEvents) {
                logger.alert('sysUIState.setCurrentServiceEvtIdx: we are set to show. We no longer support events');
            } else {
                const name = state.serviceEvents[state.serviceEvtIdx];
                const serviceId = state._currentProject.currentEnv.serviceNameToId[name];
                initCurrentServiceData(state, serviceId);
            }
        }
        state.systemUIState = EProjectState.serviceChanged;
        manageSessionState(state, sessionStorageStates.dirty)
    }

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const newOrgInit = (initialState: State, actions: actionTypes): State => {
    const { navItem, currentFeature} = initialState;

    const state = initState(initialState);
    
    state.navItem = navItem;
    state.currentFeature = currentFeature;

    state.projectsLoaded = false;
    setServicesLoadedState(state);
    state.uriCodesLoaded = false;

    return state;
}

const setProjUriCodes = (initialState: State, actions: actionTypes): State => {
    const state = { ...initialState};
    const action = actions as ASetProjURICodes;

    if (state._currentProject) {
        state._currentProject.uriCodes = action.payload.projUriCode;
    }

    state.uriCodesLoaded = true;

    return state;
}

const dataLoadInProgress = (initialState: State, actions: actionTypes): State => {
    const state = { ...initialState};
    const action = actions as ADataLoadInProgress;

    if (action.payload.feature === 'main') {
        state.systemUIState = EProjectState.dataLoadInProgress;
        state.dataLoading = true;
    }

    return state;
}

const redirectTo = (initialState: State, actions: actionTypes): State => {

    const state = { ...initialState};
    const action = actions as ARedirectTo;

    state.redirectUrl = action.payload.url;
    const storage: ZSessionStorage = getSessionStorage();
    logger.log(`SystemUIState: Setting redirect url to ${action.payload.url}, local storage is ${JSON.stringify(storage)}`);
    // console.log(`%c SystemUIState.redirectTo: dataloading ${state.dataLoading}`, 'background: green; color: white;')
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setAdminProjectList  = (initialState: State, actions: actionTypes): State => {
    const action = actions as ASetAdminProjectList;
    let state = initialState;

    const newProjList = action.payload.projectList;
    const currentList = initialState._projectList;

    const setupReload = () => {
        const currentProj = initialState._currentProject;
        const currentProjId = ((currentProj !== undefined) && (currentProj.projectId !== undefined)) ? currentProj.projectId : undefined;
        const projAction: ASetProjects = {
            type: ActionKeys.SET_PROJECTS,
            payload: {
                projects: [...newProjList],
                projectId: currentProjId
            }
        }
        state = setProjects(initialState, projAction);
        return state;
    }

    if (newProjList.length !== currentList.length) {
        setupReload();
    } else { // lists are the same length.  Looking for a change that would 
             // cause us to reload the projets.
        for (let i = 0, len = newProjList.length; i < len; i++) {
            const proj = newProjList[i];
            const projId = proj.project_id;
            const oldProj = initialState._projectObj[projId];
            if (oldProj === undefined || oldProj.name !== proj.name) {
                setupReload();  // names and project_id don't match
            }
        }
    }

    return state;
}

const logoutUser =  (initialState: State): State => {

    const state = { ...initialState};

    state.projectsLoaded = false;
    state.servicesLoaded = false;
    state.uriCodesLoaded = false;
    state.projectFeaturesLoaded = false;
    state.dataLoading = false;

    state.redirectUrl = ZURLS.login;
    removeSessionStorage();

    return state;
}

const setProjectFeaturesAttributes = (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetProjectFeaturesAttributes;

    const products: ZProductFeatureAttributes = action.payload.svcFeatureAttributes;
    const dnsDir = products.dns_director;
    const foundDD = dnsDir !== undefined && dnsDir.enabled;

    state.svcProdFeatureMap = products;
    state.ddAutoSwitchEnabled = false;

    if (foundDD) {
        const ddProduct: DNSDirectorAttributes = products['dns_director'] as DNSDirectorAttributes;
        state.ddAutoSwitchEnabled = ddProduct.attributes && ddProduct.attributes.allow_auto_switch ? 
        ddProduct.attributes.allow_auto_switch : false;
    }
    const csm = products.cloud_service_monitor;
    state.projHasCSM = (csm !== undefined && csm.enabled)
    state.projHasDnsDir = foundDD;
    state.projectFeaturesLoaded = true;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setCurrentOrg =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    state._currentProject = {} as _CurrentProject;
    state._projectList.length = 0;
    state._projectObj = {};

    state.projectsLoaded = false;
    setServicesLoadedState(state);

    state.projectsLoaded = false;
    state.servicesLoaded = false;
    state.uriCodesLoaded = false;
    state.projectFeaturesLoaded = false;
    state.dataLoading = false;

    return state;
}

const dataGroupLoad =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ADataGroupLoad;

    if (action.payload.feature === 'main') {
        state.dataLoading = action.payload.inProgress;
    }
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginUser =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};

    state.currentFeature = SysFeatureEnums.analytics;
    state.navItem = NAVSTRINGS.analytics;

    return state;
}

const setSystemProjectSolutions  =  (initialState: State, actions: actionTypes): State => {
    const state = {...initialState};
    const action = actions as ASetSystemProjectSolutions;

    state.systemProjectSolutions.length = 0;
    action.payload.solutions.forEach(((solution: ProjectSolutions): void => {
      state.systemProjectSolutions.push(solution.solution_name);  
    }));

    return state;
}

type ActionFunction = ((state: State, action: actionTypes) => State);
type AdminActions = ActionKeys.CHANGE_PROJECTS | ActionKeys.APPLY_SETTINGS | ActionKeys.LOGOUT_USER | 
                    ActionKeys.RESET_PROJECT | ActionKeys.SET_URI_CODE_IDX | ActionKeys.RELOAD_SERVICES | ActionKeys.LOGIN_USER_SUCCESS |
                    ActionKeys.CHANGE_ENVIRONMENT | ActionKeys.SET_NAV_ITEM | ActionKeys.CHANGE_ENVIRONMENT | ActionKeys.DATA_GROUP_LOAD |
                    ActionKeys.ROUTER_LOCATION_CHANGE | ActionKeys.SET_HAMBURGER | ActionKeys.SHOW_NOTIFICATION |
                    ActionKeys.SESSION_STATE_SAVED | ActionKeys.INIT_SESSION_DATA | ActionKeys.CLOSE_NOTIFICATION |
                    ActionKeys.FEATURE_READY | ActionKeys.SET_CURRENT_SERVICE_EVT_IDX | ActionKeys.SET_PROJ_URI_CODES | 
                    ActionKeys.CREATE_NEW_SERVICE | ActionKeys.DATA_LOAD_IN_PROGRESS | ActionKeys.REDIRECT_TO |
                    ActionKeys.SET_ADMIN_PROJECT_LIST | ActionKeys.SET_PROJECT_FEATURES_ATTRIBUTES | ActionKeys.SET_CURRENT_ORG |
                    ActionKeys.SET_PROJECTS | ActionKeys.SET_PROJECT_SERVICES | ActionKeys.SET_CURRENT_ORG | 
                    ActionKeys.SET_SYSTEM_PROJECT_SOLUTIONS | ActionKeys.DELETE_SERVICE;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in AdminActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.SET_ADMIN_PROJECT_LIST]: setAdminProjectList,
    [ActionKeys.CHANGE_PROJECTS]: changeProject,
    [ActionKeys.CHANGE_ENVIRONMENT]: changeEnvironment,
    [ActionKeys.APPLY_SETTINGS]: applySettings,
    [ActionKeys.SET_PROJECTS]: setProjects,
    [ActionKeys.SET_PROJECT_SERVICES]: setProjectServices,
    [ActionKeys.RELOAD_SERVICES]: reloadServices,
    [ActionKeys.CREATE_NEW_SERVICE]: createNewService,
    [ActionKeys.DELETE_SERVICE]: deleteService,
    [ActionKeys.RESET_PROJECT]: resetProject,
    [ActionKeys.SET_URI_CODE_IDX]: setUriCodeIdx,
    [ActionKeys.SET_NAV_ITEM]: setNavItem,
    [ActionKeys.ROUTER_LOCATION_CHANGE]: routerLocationChange,
    [ActionKeys.SET_HAMBURGER]: setHamburgerOpen,
    [ActionKeys.SHOW_NOTIFICATION]: showSysNotification,
    [ActionKeys.CLOSE_NOTIFICATION]: closeSysNotification,
    [ActionKeys.SESSION_STATE_SAVED]: sessionStateSaved,
    [ActionKeys.INIT_SESSION_DATA]: initSessionData,
    [ActionKeys.FEATURE_READY]: featureReady,
    [ActionKeys.SET_CURRENT_SERVICE_EVT_IDX]: setCurrentServiceEvtIdx,
    [ActionKeys.SET_CURRENT_ORG]: newOrgInit,
    [ActionKeys.DATA_LOAD_IN_PROGRESS]: dataLoadInProgress,
    [ActionKeys.SET_PROJ_URI_CODES]: setProjUriCodes, 
    [ActionKeys.REDIRECT_TO]: redirectTo,
    [ActionKeys.LOGOUT_USER]: logoutUser,
    [ActionKeys.SET_PROJECT_FEATURES_ATTRIBUTES]: setProjectFeaturesAttributes,
    [ActionKeys.SET_CURRENT_ORG]: setCurrentOrg,
    [ActionKeys.LOGIN_USER_SUCCESS]: loginUser,
    [ActionKeys.DATA_GROUP_LOAD]: dataGroupLoad,
    [ActionKeys.SET_SYSTEM_PROJECT_SOLUTIONS]: setSystemProjectSolutions,
}

function systemNavProjectState(initialState: State, action: actionTypes ): State {
    let state: State = initialState;

    if ( initialState === undefined ) {
        state = Object.assign({}, initSysData);
    }
    const fct: ActionFunction | undefined = actionMap[action.type];
    if (fct) {
        // logger.log(`%c\n ====> sysState Enter: action: ${action.type}; servicesLoaded: ${state.servicesLoaded}
        // action: ${JSON.stringify(action)}`, 'color: red;')
        state = (fct)(state, action);
        // logger.log(`%c\n ====> sysState Exit: action: ${action.type}; servicesLoaded: ${state.servicesLoaded}`, 'color: red')
    } 

    return  state;
}
//
export default systemNavProjectState;