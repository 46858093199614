// 
// Response from get-metric-keys REST API
// These are the Interface definitions for the REST Call (ProjectMetricKeyResponse)
// **********
// Origin
// Sample Origin: {'name':'zcache16.internal.zycada.net','ips':['127.0.0.1','192.73.240.90']}]}

import moment from 'moment';
import { StringMap, NumberMap } from './metricsAndOptionsDefs';

export interface Origin {
  name: string;
  ips: string[];
}

export interface ServiceIdMapping {
  [serviceName: string]: string;
}

//
// response format from get-metric-key REST API
export interface ProjectMetricKeyResponse {
  service_ids: ServiceIdMapping;  
  geo: string[];                  
  origin: Origin[];
  cdns?: string[];
  healthChecks?: string[];
}

// 
// Response from call to get the server environment.
// Interface definitions for the server environment Data (ServerConfig)
// **********
// Interface for referencing test features
export interface ServerConfig {
  api_url_mappings: {
    [projectType: string]: string;
  };
  automatEnabled: boolean;
  legacyConsole: string;
  features: string;         // comma separated string of org ids that have access to new features
  loggingEnabled: boolean;
  login_url: string;
  publish_promote_old_versions_upto: StringMap;
  CSALite: string,
  CSA_verification: string,
}

//
// Response from Login.
// This returns a user's ZUser definition and the default organization the user is associated with
// **********
export interface LoginData {
  user: ZUserMin;
  org_info: OrgInfo;
}

export enum ZUserTypes {
  user = 'user',
  dev = 'dev',
  admin = 'admin',
  zadmin = 'zadmin',
}

// Definition of ZUserMin
export interface ZUserMin  {
  company?: string;
  email: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  role: ZUserTypes;
  email_verified: boolean;
  auto_add_to_all_projects: boolean;
  user_id: string;
  two_factor_auth: {
    enabled: boolean;
    type: string;
  };
  meta: {
    num_projects: number;
    num_api_keys: number;
    password_expires_at_epoch_seconds: number;
  };
}

export enum TFA_FORMAT {
  QR = 'qr',
  TEXT =  'text'
}

export enum TFA_TYPE {
  'otp_token' = 'otp_token'
}

export interface ZTfaSecret {
    secret: string;
    secret_format: TFA_FORMAT;
    recovery_codes: string[];
}

// Response body for Create User and Edit User
export interface CreateUserBody {
  'email': string;
  'first_name'?: string;
  'last_name'?:  string;
  'role'?: string;
  'phone'?: string;
  'password'?: string;
  'auto_add_to_all_projects'?: boolean
}

export interface EditUserBody {
  'email': string;
  'role'?: string;
  'auto_add_to_all_projects'?: boolean
}

//
// Definition of a Project
// 
export enum ProjectPlanType  {
  basic ='basic',
  business = 'business',
  enterprise = 'enterprise'
}

export interface ZProjectMin {
    project_id: string;
    name: string;
    plan: ProjectPlanType,
    meta: {
      num_users: number;
      num_certs: number;
      max_services_allowed: number;
      max_certs_allowed: number;
    };
}

export interface ProjectSolutions {
  solution_name: string;
  created_at?: string,
  cancelled_at?: string,
}

export interface OrgInfo {
    max_certs_per_project: number;
    max_projects: number;
    max_services_per_project: number;
    max_api_keys_per_user: number;
    max_firewall_rules: number;
    org_id: string;
    org_logo_url: string;
    org_name: string;
    num_password_valid_days: number,
    num_prev_passwords_not_allowed: number,
    password_expiry_warning_days: number
}

export interface OrgLimit {
    "name": string,
    "value": number,
    "min": number,
    "max": number
}

export enum ServiceStatusEnum {
  'draft' = 'draft',
  'publishing' = 'publishing',
  'published' = 'published',
  'publish_failed' = 'publish_failed',
  'active' = 'active',
  'deleting' = 'deleting',
  'delete_failed' = 'delete_failed',
  'success' = 'success'
}

export enum APISvrJobStatusEnum {
  'accepted' = 'accepted',
  'success' = 'success',
  'in_progress' = 'in_progress',
  'failed' = 'failed',
}

export interface ZServiceFeatures {
  dnsDir?: FeatureDef,
  csm?: FeatureDef,
  cache: CacheFeature | FeatureDef;
  xff?: XffFeature | FeatureDef;
  waf?: FeatureDef;
  csa?: FeatureDef;
  image_mux?: FeatureDef;
  
  streaming?: FeatureDef;
  compression?: FeatureDef;
  error_free_response: FeatureDef;
  headers?: FeatureDef;
  metrics_by_geo: FeatureDef;
  network_real_user_metrics: FeatureDef;
  snippets?: FeatureDef;
}

export interface CompanionService {
  service_id: string;
  cname: string;
}

export interface ZServiceExt {
  project_id: string;
  service_id: string;
  service_name: string;
  created_at: string;
  updated_at: string;
  is_live: boolean;
  status: ServiceStatusEnum;
  service_info: ZServiceExt;
  platform: string;
  env: string;
  zycadized_domain_name: string;
  cname: string;
  customer_id: string;
  version: number;
  cloud_service_monitor?: ZCloudServiceMonitor,
  features: ZServiceFeatures;
  redirect_from_zycadized_domain_names?: string[];
  cloud_service_shield?: ZCloudServiceShield,
  health_checks: {
    [name: string]: ZHealthCheckOld;
  };
  origins?: {
    [name: string]: ZOrigin;
  };
  origin_rules: {
    [name: string]: string;       // value is a json string
  };
  ports: ZPortsDef;
  dns_director: ZDnsDirectorResponse,
  cloud_service_accelerator?: ZCloudServiceAccelerator,
  rules: RoutingRules[];
  type: string;
  meta?: {
    base_version?: number;
    prod_info?: CompanionService;
    staging_info?: CompanionService;
  };
}

export enum ProductNameEnum {
  DNSDirector = 'DNSDirector',
  ContentDelivery = 'ContentDelivery',
  CSM = 'CSM'
}

export interface PlatformInfo {
  display_name: string,
}

export interface ServerPlatforms {
  [name: string]: PlatformInfo
}

export interface ProductAttributes {
  enabled: boolean,
}

export interface DNSDirectorAttributes extends ProductAttributes {
  attributes: {
      allow_auto_switch: boolean
  }
}

export interface CSMAttributes extends ProductAttributes {
  attributes: {
      num_hcs: number
      check_interval: NumberMap
  }
}

export interface CacheAttributes extends ProductAttributes {
  attributes: {
      advanced_settings_enabled: boolean
  }
}

export interface XffAttributes extends ProductAttributes {
  attributes: {
    allow_custom_name: boolean
  }
}

export interface ZProductsSimple {
  products: {
    dns_director?: ProductAttributes,
    cloud_service_monitor?: ProductAttributes
    content_delivery?: ProductAttributes,
    cloud_service_shield?: ProductAttributes
  }
}

export interface ZProductFeatureAttributes {
  dns_director?: DNSDirectorAttributes,
  cloud_service_monitor?:  CSMAttributes
  content_delivery?: {
    enabled: boolean,
    auto_cert_renewal: ProductAttributes,
    cache: CacheAttributes,
    xff: XffAttributes,
  },
  cloud_service_shield?: ProductAttributes,
}

export interface ZServiceVersion {
    updated_at: string;
    version: number;
    status: string;
    is_live: boolean;
}

export interface ZHealthCheckOld {
  success_status_codes: string;
  request_path: string;
}

export interface ZOrigin {
  domain_name: string;                       //  (user editable - Backup Origin / Secondary Origin)
  port: number;                              //  (user editable)
  sni?: string;                               // SNI data
}

export interface ZPortsDef {
  [name: string]: ZPort | ZSecurePort | string;
}
export interface ZPort {
  redirect_to_tls_port?: string;
}

export interface ZSecurePort {
  tls_enabled: boolean;
  cert_id?: string;
  cipher_suites?: string[];
  tls_versions?: string[];
  tls_tickets_enabled?: boolean;
  http2_enabled?: boolean;
  max_headers_size?: number;
}

export interface ZDnsDirectorDomains {
  [tagName: string]: ZDnsDirectorDomain
}
export interface ZDnsDirectorDomain {
  cdn_cname: string,
  cname: string,
  traffic_percent?: number,
}

export interface ZDnsDirectorResponse {
  enabled: boolean,
  auto_switch: boolean,
  use_cdns_as_origin: boolean,
  zycada_traffic_percent: number
  cdns?: ZDnsDirectorDomains;
}

export enum HealthCheckIntervals {
  slow = 'slow',
  default = 'default',
  fast = 'fast'
}
export interface ZCSMEntity {
  // name: string,
  request_path: string,
  check_interval: HealthCheckIntervals,
}

export interface ZCSMHealthCheck {
  [checkName: string]: ZCSMEntity;
}

export interface ZCloudServiceMonitor {
  enabled: boolean,
  health_checks: ZCSMHealthCheck
}

export interface ZCloudServiceShield {
  waf: {
    ruleset: {
      enabled: boolean
    }
  }
}
export interface ZCloudServiceAccelerator {
  enabled: boolean;
}
export interface DeploymentPublishResponse {
  job_id: string;
  service_id: string;
  status: string;
}

export interface DeploymentRecord {
  prev_version: number;
  version: number;
  created_at: string;
  updated_at: string;
  status: string;
  updatedAt?: moment.Moment;           // this is added when the reducer gets the record
}
export interface DeploymentHistoryItem {
  service_id: string;
  env: string;
  live_version: number;
  deployment_in_progress: boolean;
  details: DeploymentRecord[];
}

export interface DeploymentHistory {
  deployment_history: DeploymentHistoryItem[];
}
export interface FeatureDef {
  enabled: boolean;
}

export enum UriCodeUsageType  {
  ANALYTICS = 'analytics',
  CACHE = 'cache',
  BILLING = 'billing',
}

export interface ProjUriCode {
  name: string;
  // uri_prefix: string;
  // usage: UriCodeUsageType[];
  // uri_code_id?: string;
  uri_regex?: string;
  // uri_project_id?: string;
}

export interface UriCode extends ProjUriCode {
  uri_pattern: string;                // UriCodeExt can be either a uri_regex or uri_prefix.
                                      // The UI doesn't care.  So we figure out which it is and
                                      // put it here so we don't have to figure it out later.
}

export enum ApiKeyPermission {
  ro = 'ro',
  rw = 'rw',
}

export interface ZApiKey {
  api_key: string;
  api_key_id: string;
  created_at: string;
  expires_at: string;
  last_used_at: string;
  name: string;
  permission: ApiKeyPermission;
}

export interface ZEventConfig {   // USED [OPTIONALLY] BY LEGACY SERVICE
  end_time: string;
  name: string;
  service_id: string;
  start_time: string;
  uri_pattern: string;
  service_idx?: number;
}

export interface LogHeaderEntries {
  [name: string]: string;
}
export interface LogOrigin {
  ip?: string;
  port?: number; 
  proc_time_us?: number;
}

// definition of logs data;
export interface LogEntry  {
  [name: string]: LogHeaderEntries | LogOrigin | number | string;
}

export interface LogData {
  logs: LogEntry[];
  total: number;
}
export interface LogFilterExt {
  name: string;
  value?: string[];
  operation: string;
}

// data returned by the cachePurge GET command
export interface CacheUriCodes {
  domain_name: string;
  uri_code: string;
  uri_pattern: string;
}

export interface CachePurgeReqStatusExt {
    created_at: string;
    job_id: string;
    message: string;
    service_id: string;
    status: string;
    updated_at: string;
    uris: string[];
    uri_codes: CacheUriCodes[];
    cache_tags: string[];
}

// 
// Definition of the data that is returned by the get-metrics REST Call
// export interface AnalyticsGraphData:  AnalyticsMetricData[];
export interface AnalyticsMetricData {
  data: SvrMetricTimeData[];
  metric: string;
  stats: string;
}

export interface MetricDataV1 {
  name: string,
  "aggregate_value": number,
  unit: number,
  values: [][]
}

export interface MetricV1ExtraData {
  aggregateValue: NumberMap
}

export interface MetricsDataV1Response {
  results: {
    series: MetricDataV1[]
  }
}

export interface SvrMetricTimeData {
    tags: SvrMetricTagEntry | null;
    time_values: SvrMetricDataPoint[];
}

export interface SvrMetricDataPoint {
  epochmillis: number;
  value: number;
}

export interface SvrMetricTagEntry {
  metric_name: string;
  origin_ip: string;
  attack: string;
  country: string;
  bottype: string;
  iaastype: string;
  uricode: string;
}

export interface  CsmLogsData {
  "@timestamp": string,
  service_id: string,
  cdn: string,
  geo: string,
  name: string,
  success?: boolean,
  failure_reason?: string
}

export interface CsmLogsMeta {
  message?: string,
  total_records: number,
  num_pages: number,
  next: string;
}

export interface CsmLogsResponse {
  logs: CsmLogsData[],
  meta: CsmLogsMeta
}

export interface CachePurgeRequest {
  job_id: string;
  message: string;
  status: string;
}

export interface ChangePwdResponse {
  message: string;
}

export interface ChangeProfileResponse {
  message: string;
}

export interface changePasswordBody {
  old_password: string,
  new_password: string,
  auth_data?: string
}

export interface AggrDataMap {
    name: string;
    value: number;
}

// Response from POST /v1/certs
// Response from GET /v1/certs?project_id=xxx
// Response from PUT /v1/certs/certidxxxx - note: message only with PUT.
// ?meta=true&meta.addtional_info=true
export interface Certificate {
  cert_id: string;
  cert_chain: string;
  common_name: string;
  san: string[];
  issued_at: string;          // ISO 8601
  expires_at: string;         // ISO 8601
  issuer: string,
  job_id: string;
  error?: {
    error_code: number;
    message: string;
  };
  meta?: {
    last_update: string;      // ISO 8601
    update_status: string;    // one of 'success', 'failed', 'in_progress'
    additional_info: [
      {
        project_id: string;
        services: [
          { service_id: string }
        ];
      }
    ];
  };
}

export interface certUpdateBody {
    "project_id"?: string,
    "cert_chain": string,
    "private_key": string,
}

export interface CacheAgeItem {
  response_status: string;
  age: string;
}
export interface CacheFeature extends FeatureDef {
  default_age?: CacheAgeItem[];
  force_default_age?: CacheAgeItem[];
  purge?: {
    cache_tag_header_name: string;
  }
}

export interface XffFeature extends FeatureDef {
  x_forwarded_for_header_name: string;
}

export interface ZMatchFilter {
  location: {
    path_regex: string;
  };
}

export interface RoutingFeatures {
    cache: CacheFeature;
    waf: FeatureDef;
    xff: XffFeature;
    image_mux: FeatureDef;
}

export interface RoutingRules {
  asset_type: string;
  name: string;         // right now this is the same as asset_type
  features: RoutingFeatures;
  match_filter: ZMatchFilter;
}

export interface RestAPI {
  [param: string]: string | number | boolean;
}

export interface FetchError {
  message: string;
  statusCode?: number;
  prompt_for_otp?: boolean;
  auth_data?: string,
}

export interface RestModifiers {
  mimeType?: string;
  noAuthKey?: boolean;
  accept?: string;
}

export interface ResetTokenInfo {
  token_valid: boolean;
  token_expired: boolean;
  max_rate_reached: boolean;
  retry_after_hours: number;
  max_attempts_reached: boolean;
  url: string;
  meta?: {
    org_name: string;
    email: string;
  };
}

export interface CSAHistoryItem {
  object_id: string,
  file_name: string,
  status: APISvrJobStatusEnum,
  user_id: string,
  job_id: string,
  created_at: string,
  updated_at: string,
}

export interface ReportOverviewData {
  month: string,        // number month starting with 1
  year: string,
  csv_url: string,
  pdf_url: string,
}

export interface AvailableBillingReports {
  [report_type: string]: ReportOverviewData[]
}

export interface BillingBaseData {
  total_requests: number,
  total_bytes_in: number,
  total_bytes_out: number,
  total_bytes_in_gb: number,
  total_bytes_out_gb: number,
  total_bytes_in_tb: number,
  total_bytes_out_tb: number,
  total_bytes_in_pb: number,
  total_bytes_out_pb: number,
  total_log_bytes_out: number,
  total_log_bytes_out_gb: number,
  total_log_bytes_out_tb: number,
  total_log_bytes_out_pb: number,
}


export interface ProjectBillingData extends BillingBaseData {
  project_id:   string | number,
  name: string,
}

export interface ServiceBillingData extends BillingBaseData {
  service_id: string,
  env: string
  zycadized_domain_name: string,
}

export interface ProjectServiceBillingData extends ProjectBillingData {
  services: ServiceBillingData[]
}

export interface BillingOrgUsageData extends BillingBaseData {
  org_name: string,
  projects: ProjectBillingData[] | ProjectServiceBillingData[]
}

export type ZRestResponse =
| AnalyticsMetricData[]
| LoginData
| ProjectMetricKeyResponse
| ServerConfig
| LogData
| CachePurgeReqStatusExt[]
| CachePurgeRequest
| ChangePwdResponse
| ChangeProfileResponse
| ZApiKey
| AggrDataMap[]
| ZUserMin
| ZUserMin[]
| ZProjectMin
| ZProjectMin[]
| ZApiKey[]
| ProjUriCode
| ProjUriCode[]
| FetchError
| Certificate
| Certificate[]
| ZServiceVersion[]
| ZServiceExt
| ZServiceExt[]
| DeploymentRecord
| DeploymentHistory
| ZTfaSecret
| ResetTokenInfo
| DeploymentPublishResponse
| OrgInfo
| OrgInfo[]
| CSAHistoryItem
| CSAHistoryItem[]
| OrgLimit[]
| AvailableBillingReports
| ReportOverviewData[]
| BillingOrgUsageData
| ZProductFeatureAttributes
| CsmLogsResponse
| string[]

