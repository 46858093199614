import { ANALYTICS_STRINGS as STRs, ANALYTICS_STRINGS, SERVICES_STRINGS as SVCSTRs } from '../shared/strings';
import moment from 'moment';
import { SysFeatureEnums, ZSortDirection } from '../reducers/reducerEnums';
import { ZServiceExt } from './queryResultDefinitions';

export interface StringMap {
  [name: string]: string;
}

export interface BooleanMap {
  [name: string]: boolean;
}

export interface AnyMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

export interface NumberMap {
  [name: string]: number;
}

export interface SvcIdToSvcMap {
  [serviceId: string]: ZServiceExt;
}

export enum ChartTypes {
  line = 'line',
  pie = 'pie',
}

export interface TimeRange {
  to: moment.Moment;
  from: moment.Moment;
}

export interface DisplayRoutingRules {
  friendlyName: string;
  features: {
    cache: boolean;
    waf: boolean;
    xff: boolean;
    image_mux: boolean | undefined;
  };
  match_filter: boolean;
}

export interface SvcRoutingRuleUIMap {
  [name: string]: DisplayRoutingRules;
}

export const servicesTypeAssetRule: SvcRoutingRuleUIMap = {
  dynamic: {
    friendlyName: SVCSTRs.dynamicAssets,
    features: {
      cache: false,
      waf: true,
      xff: true,
      image_mux: false,
    },
    match_filter: false
  },
  javascript_and_css: {
    friendlyName: SVCSTRs.javascriptAndCss,
    features: {
      cache: true,
      waf: true,
      xff: true,
      image_mux: false,
    },
    match_filter: true
  },
  images: {
    friendlyName: SVCSTRs.images,
    features: {
      cache: true,
      waf: true,
      xff: true,
      image_mux: true,
    },
    match_filter: true
  },
  videos: {
    friendlyName: SVCSTRs.video,
    features: {
      cache: true,
      waf: true,
      xff: true,
      image_mux: false,
    },
    match_filter: true
  },
  static_assets: {
    friendlyName: SVCSTRs.staticAssets,
    features: {
      cache: true,
      waf: true,
      xff: true,
      image_mux: false,
    },
    match_filter: true
  },
  default: {
    friendlyName: '',
    features: {
      cache: true,
      waf: true,
      xff: true,
      image_mux: undefined,
    },
    match_filter: true
  },
}

export enum SvcEditPanelValidationEnum {
  good = 'good',
  error = 'error',
  optional = 'opt',
  required = 'req'
}

export interface ServiceFieldValidate {
  serviceName: boolean;
  domainName: boolean;
  originName: boolean;
  originDomainName: boolean;
  domainsRedirect: boolean,
  sni: boolean;
  rules?: boolean[];
  domainRedirectErrors: {
    hasSvcDomainName: boolean,
    inValidDomainName: boolean,
    tooManyDomains: boolean
  }
  ddDomainName: boolean;
  zyTrafficPercentage: boolean;
  healthChecks: boolean[];
  cdnTag: boolean;
}

export interface MetricsUIDefinitions {
  nameReplacements?: StringMap;
  additionalTxt?: string;
  chartType?: ChartTypes;
  colors?: StringMap;
  description: string;
  isStatisticsSupported: boolean;
  key: string;
  metric: string;
  pocType: number[];
  pocDisabled: number[];
  required?: boolean;
  specialProc?: string;
  unit: string;
  tag?: string;
}

export interface MetricsUIDefObj {
  [tabName: string]: MetricsUIDefinitions[];
}

const dnsDirMetrics: MetricsUIDefinitions[] = [
  {
    description: '',
    isStatisticsSupported: false,
    key: 'traffic_perc',
    metric: STRs.mTrafficPercentage,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'percentage',
    nameReplacements: {
      'traffic_perc_zy': STRs.altZyTraffic,
      'traffic_perc_cdn': STRs.altCDNTraffic,
    }
  }
]

const csmMetrics: MetricsUIDefinitions[] = [
  {
    description: '',
    isStatisticsSupported: false,
    key: 'uptime_perc',
    metric: STRs.mHealthCheckStatus,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'percentage',
    nameReplacements: {
      'uptime_perc_zy': STRs.altZyUptime,
      'uptime_perc_cdn': STRs.altCdnUptime,
    }
  }
]
const trafficMetrics: MetricsUIDefinitions[] = [
  {
    description: ANALYTICS_STRINGS.descAssocTraffic,
    isStatisticsSupported: false,
    key: 'requests_pm,http2xx_pm,http3xx_pm,http4xx_pm,http5xx_pm',
    metric: ANALYTICS_STRINGS.mAssocTraffic,
    pocType: [0, 1, 2, 3, 4],
    pocDisabled: [],
    required: true,
    specialProc: 'g',
    unit: 'Rate Per Minute',
    nameReplacements: {
      'requests_pm': ANALYTICS_STRINGS.altReqPM,
      'http2xx_pm': ANALYTICS_STRINGS.altHTTP2xx,
      'http3xx_pm': ANALYTICS_STRINGS.altHTTP3xx,
      'http4xx_pm': ANALYTICS_STRINGS.altHTTP4xx,
      'http5xx_pm': ANALYTICS_STRINGS.altHTTP5xx,
    },
    colors: {
      'requests_pm': 'rgba(255, 255, 255, 1)',
      'http2xx_pm': 'rgba(18, 193, 141, 1)',
      'http3xx_pm': 'rgba(83, 208, 218, 1)',
      'http4xx_pm': 'rgba(255, 174, 66, 1)',
      'http5xx_pm': 'rgba(231, 99, 99, 1)',
    },
    additionalTxt: ANALYTICS_STRINGS.clickLegend,
  },
  {
    description: STRs.descBytesIn,
    isStatisticsSupported: false,
    key: 'bytes_in_pm',
    metric: STRs.mBytesIn,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Bytes Per Minute',
    nameReplacements: {
      'bytes_in_pm': ANALYTICS_STRINGS.altBytesIn
    },
  },
  {
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    description: STRs.descBytesOut,
    isStatisticsSupported: false,
    key: 'bytes_out_pm',
    metric: STRs.mBytesOut,
    unit: 'Bytes Per Minute',
    nameReplacements: {
      'bytes_out_pm': ANALYTICS_STRINGS.altBytesOut
    },
  },
  {
    description: STRs.descPercentPacketLoss,
    isStatisticsSupported: false,
    key: 'nreq_with_atleast_one_retrans_pm,requests_pm',
    metric: STRs.mPercentPacketLoss,
    pocType: [1],
    pocDisabled: [],
    specialProc: 'p',
    unit: 'Percentage',
    nameReplacements: {
      'requests_pm': ANALYTICS_STRINGS.altReqPM,
      'nreq_with_atleast_one_retrans_pm': ANALYTICS_STRINGS.altPercentPacketLoss
    },
  },
  // {
  //   description: STRs.mOriginReqsPrefetch,
  //   isStatisticsSupported: false,
  //   key: 'requests_prefetch_exit_pm',
  //   metric: STRs.descOriginReqsPrefetch,
  //   pocType: [3],
  //   pocDisabled: [],
  //   unit: 'Rate Per Minute',
  //   nameReplacements: {
  //     'requests_prefetch_exit_pm': ANALYTICS_STRINGS.altOriginReqsPrefetch
  //   },
  // }
];

const botMetrics: MetricsUIDefinitions[] = [
  {
    description: ANALYTICS_STRINGS.descBotTraffic,
    isStatisticsSupported: false,
    key: 'requests_bot_pm',
    metric: ANALYTICS_STRINGS.mBotTraffic,
    pocType: [0, 1, 2, 3, 4],
    pocDisabled: [],
    required: true,
    unit: 'Rate Per Minute',
    specialProc: '',
    nameReplacements: {
      'requests_pm': ANALYTICS_STRINGS.altReqPM,
      'http2xx_pm': ANALYTICS_STRINGS.altWAFAttacks,
      'requests_attack_pm': ANALYTICS_STRINGS.altWAFAttacks,
      'requests_bot_pm': ANALYTICS_STRINGS.altBOTSDetected,
    },
  },
  {
    description: STRs.descWAFBOT,
    isStatisticsSupported: false,
    key: 'requests_attack_bottype_pm',
    metric: STRs.mWAFBOT,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Percentage',
    chartType: ChartTypes.pie,
    tag: 'bottype',
    nameReplacements: {
      'OTHER': ANALYTICS_STRINGS.altWAFOther,
      'requests_attack_bottype_pm': ANALYTICS_STRINGS.altAttackByBOT,
      'SEO': ANALYTICS_STRINGS.altWAFBOTSeo,
      'MARKETING': ANALYTICS_STRINGS.altWAFBOTMarketing,
      'SEARCH': ANALYTICS_STRINGS.altWAFBOTSearch,
      'CRAWLER': ANALYTICS_STRINGS.altWAFBOTCrawer,
      'SCRAPER': ANALYTICS_STRINGS.altWAFBOTScraper,
    }
  },
  {
    description: STRs.descWAFIIAS,
    isStatisticsSupported: false,
    key: 'requests_attack_iaastype_pm',
    metric: STRs.mWAFIAAS,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Percentage',
    chartType: ChartTypes.pie,
    tag: 'iaas',
    nameReplacements: {
      'OTHER': ANALYTICS_STRINGS.altWAFOther,
      'requests_attack_iaastype_pm': ANALYTICS_STRINGS.altAttackByIAAS,
      'GOOGLE': ANALYTICS_STRINGS.altWAFIAASGoogle,
      'AMAZON': ANALYTICS_STRINGS.altWAFIAASAmazon,
      'MICROSOFT': ANALYTICS_STRINGS.altWAFIAASMicrosoft,
      'ORACLE': ANALYTICS_STRINGS.altWAFIAASOracle,
      'IBM': ANALYTICS_STRINGS.altWAFIAASIBM,
    }
  },
];


const wafAttackList = { 
  'OTHER': ANALYTICS_STRINGS.altWAFOther,
  'RESTRICTED_FILE_ACCESS': ANALYTICS_STRINGS.altWAFRestrictedFileAccess,
  'RESTRICTED_FILE_EXTENSION': ANALYTICS_STRINGS.altWAFRestrictedFileExtention,
  'URL_ENCODING_ABUSE': ANALYTICS_STRINGS.altWAFUrlEncodingAbuse,
  'REMOTE_COMMAND_EXECUTION': ANALYTICS_STRINGS.altWAFRemoteCommandException,
  'PHP_INJECTION_ATTACK': ANALYTICS_STRINGS.altWAFPHPInjection,
  'PHP_INJECTION': ANALYTICS_STRINGS.altWAFPHPInjection,
  'SQL_INJECTION_ATTACK': ANALYTICS_STRINGS.altWAFSQLInjection,
  'SQL_INJECTION': ANALYTICS_STRINGS.altWAFSQLInjection,
  'PATH_TRAVERSAL_ATTACK': ANALYTICS_STRINGS.altWAFPathTraversal,
  'PATH_TRAVERSAL': ANALYTICS_STRINGS.altWAFPathTraversal,
  'NODEJS_INJECTION_ATTACK': ANALYTICS_STRINGS.altWAFNodejsInjection,
  'NODE_JS_INJECTION':  ANALYTICS_STRINGS.altWAFNodejsInjection,
  'REMOTE_FILE_INCLUSION_ATTACK': ANALYTICS_STRINGS.altWAFRemoteFileInclusion,
  'REMOTE_FILE_INCLUSION': ANALYTICS_STRINGS.altWAFRemoteFileInclusion,
  'OS_FILE_ACCESS_ATTEMPT': ANALYTICS_STRINGS.altWAFOSFileAccessAttempt,
  'OS_FILE_ACCESS': ANALYTICS_STRINGS.altWAFOSFileAccessAttempt,
  'XSS_FILTER_ATTACK': ANALYTICS_STRINGS.altWAFXSSFilter,
  'INVALID_REQUEST_CHARACTER': ANALYTICS_STRINGS.altWAFInvalidCharacter,
  'CVE_2014_6271': ANALYTICS_STRINGS.altWAFCVE_2014_6271,
  'CVE_2015_5842': ANALYTICS_STRINGS.altWAFCVE_2015_5842,
  'CVE_2017_9805': ANALYTICS_STRINGS.altWAFCVE_2017_9805,
  'CVE_2021_44228': ANALYTICS_STRINGS.altWAFCVE_2021_44228,
  'DATA_LEAKAGES': ANALYTICS_STRINGS.altWAFDataLeakage,
  'JAVA_APPLICATION_ATTACK': ANALYTICS_STRINGS.altWAFJavaApplicationAttack,
  'PROTOCOL_ATTACK': ANALYTICS_STRINGS.altWAFProtocolAttack,
  'PROTOCOL_ENFORCEMENT': ANALYTICS_STRINGS.altWAFProtocolEnforcement,
  'SCANNER_DETECTION': ANALYTICS_STRINGS.altWAFScannerDetection,
  'SESSION_FIXATION': ANALYTICS_STRINGS.altWAFSessionFixation,
  'XSS_INJECTION': ANALYTICS_STRINGS.altWAFXssInjection,
}

const wafMetrics: MetricsUIDefinitions[] = [
  {
    description: ANALYTICS_STRINGS.descWAFTraffic,
    isStatisticsSupported: false,
    key: 'requests_attack_detected_pm,requests_attack_blocked_pm', 
    metric: ANALYTICS_STRINGS.mWAFTraffic,
    pocType: [0, 1, 2, 3, 4],
    pocDisabled: [],
    required: true,
    unit: 'Rate Per Minute',
    specialProc: '',
    nameReplacements: {
      'requests_pm': ANALYTICS_STRINGS.altReqPM,
      'requests_attack_detected_pm': ANALYTICS_STRINGS.altAttacksDetected,
      'requests_attack_blocked_pm': ANALYTICS_STRINGS.altAttacksBlocked,
    },
    additionalTxt: ANALYTICS_STRINGS.clickLegend,
  },
  {
    description: STRs.descWAFBlockedType,
    isStatisticsSupported: false,
    key: 'attacks_blocked_pm',
    metric: STRs.mWAFTypeBlocked,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Percentage',
    chartType: ChartTypes.pie,
    tag: 'attack',
    nameReplacements: wafAttackList,
  },
  {
    description: STRs.descWAFDetectedType,
    isStatisticsSupported: false,
    key: 'attacks_detected_pm',
    metric: STRs.mWAFTypeDetected,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Percentage',
    chartType: ChartTypes.pie,
    tag: 'attack',
    nameReplacements: wafAttackList,
  },
  {
    description: STRs.descWAFBlockedCountry,
    isStatisticsSupported: false,
    key: 'attacks_blocked_country_pm',
    metric: STRs.mWAFBlockedCountry,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Percentage',
    chartType: ChartTypes.pie,
    tag: 'country',
    nameReplacements: {
      'OTHER': ANALYTICS_STRINGS.altWAFOther,
      'attacks_blocked_country_pm': ANALYTICS_STRINGS.altAttackByCountryBlocked,
    }
  },
  {
    description: STRs.descWAFDetectedCountry,
    isStatisticsSupported: false,
    key: 'attacks_detected_country_pm',
    metric: STRs.mWAFDetectedCountry,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Percentage',
    chartType: ChartTypes.pie,
    tag: 'country',
    nameReplacements: {
      'OTHER': ANALYTICS_STRINGS.altWAFOther,
      'attacks_detected_country_pm': ANALYTICS_STRINGS.altAttackByCountryDetected,
    }
  },
];

const cacheMetrics: MetricsUIDefinitions[] = [
  {
    description: STRs.descCacheHits,
    isStatisticsSupported: false,
    key: 'cache_hit_pm',
    metric: STRs.mCacheHits,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'cache_hit_pm': ANALYTICS_STRINGS.altCacheHits
    },
    unit: 'Rate Per Minute'
  },
  {
    description: STRs.descCacheMisses,
    isStatisticsSupported: false,
    key: 'cache_miss_pm',
    metric: STRs.mCacheMisses,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'cache_miss_pm': ANALYTICS_STRINGS.altCacheMisses
    },
    unit: 'Rate Per Minute'
  },
  {
    description: STRs.descCacheHitRatio,
    isStatisticsSupported: false,
    key: 'cache_hit_pm,requests_pm',
    metric: 'Cache Hit Ratio Per Minute',
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    specialProc: 'p',
    nameReplacements: {
      'cache_hit_pm,requests_pm': ANALYTICS_STRINGS.altCacheHitRatio
    },
    unit: 'Percentage'
  },
  {
    description: STRs.descCacheStaleReqs,
    isStatisticsSupported: false,
    key: 'cache_stale_pm',
    metric: STRs.mCacheStaleReqs,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'cache_stale_pm': ANALYTICS_STRINGS.altCacheStale
    },
    unit: 'Rate Per Minute'
  },
  {
    description: STRs.descCacheExpiredReqs,
    isStatisticsSupported: false,
    key: 'cache_expired_pm',
    metric: STRs.mCacheExpiredReqs,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'cache_expired_pm': ANALYTICS_STRINGS.altCacheExpired
    },
    unit: 'Rate Per Minute'
  }
  // {
  //     description: STRs.descCacheSkips,
  //     isStatisticsSupported: false,
  //     key: 'cache_skip_pm',
  //     metric: STRs.mCacheSkips,
  //     pocType: [1, 2, 3, 4],
  //     pocDisabled: [1],
  //     unit: 'Rate Per Minute',
  // },
  // {
  //     description: STRs.descCacheValidations,
  //     isStatisticsSupported: false,
  //     key: 'cache_revalidated_pm',
  //     metric: STRs.mCacheValidations,
  //     pocType: [1, 2, 3, 4],
  //     pocDisabled: [1],
  //     unit: 'Rate Per Minute',
  // },
];

const nrumMetrics: MetricsUIDefinitions[] = [
  {
    description: STRs.descRetrans,
    isStatisticsSupported: true,
    key: 'retrans_pm',
    metric: STRs.mRetrans,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'retrans_pm': ANALYTICS_STRINGS.altNRUMPacketLoss
    },
    unit: 'Rate Per Minute'
  },
  {
    description: STRs.descTTFB,
    isStatisticsSupported: true,
    key: 'ttfb_ms',
    metric: STRs.mTTFB,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'ttfb_ms': ANALYTICS_STRINGS.altNRUMTTFB
    },
    unit: 'Milliseconds'
  },
  {
    description: STRs.descResponseTime,
    isStatisticsSupported: true,
    key: 'tot_time_ms',
    metric: STRs.mResponseTime,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'tot_time_ms': ANALYTICS_STRINGS.altNRUMResponseTime
    },
    unit: 'Milliseconds'
  },
  {
    description: STRs.descConnTime,
    isStatisticsSupported: true,
    key: 'conn_time_ms',
    metric: STRs.mConnTime,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'conn_time_ms': ANALYTICS_STRINGS.altNRUMConnectTime
    },
    unit: 'Milliseconds'
  },
  {
    description: STRs.descSSLHandshake,
    isStatisticsSupported: true,
    key: 'ssl_handshake_ms',
    metric: STRs.mSSLHandshake,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'ssl_handshake_ms': ANALYTICS_STRINGS.altNRUMSSLHandshake
    },
    unit: 'Milliseconds'
  },
  {
    description: STRs.descDLTime,
    isStatisticsSupported: true,
    key: 'download_ms',
    metric: STRs.mDLTime,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'download_ms': ANALYTICS_STRINGS.altNRUMDownload
    },
    unit: 'Milliseconds'
  },
  {
    description: STRs.descRespSize,
    isStatisticsSupported: true,
    key: 'response_size',
    metric: STRs.mRespSize,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'response_size': ANALYTICS_STRINGS.altNRUMResponseSize
    },
    unit: 'Bytes'
  },
  {
    description: STRs.descThroughput,
    isStatisticsSupported: true,
    key: 'throughput_kbytes_ps',
    metric: STRs.mThroughput,
    pocType: [1, 2, 3, 4],
    pocDisabled: [1],
    nameReplacements: {
      'throughput_kbytes_ps': ANALYTICS_STRINGS.altNRUMThroughput
    },
    unit: 'Kilobytes Per Second'
  }
];

const efrMetrics: MetricsUIDefinitions[] = [
  {
    description: STRs.mProcessingTime,
    isStatisticsSupported: true,
    key: 'proc_time_ms',
    metric: STRs.mProcessingTime,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Processing Time'
  },
  {
    description: STRs.mFailures,
    isStatisticsSupported: false,
    key: 'failure_pm',
    metric: STRs.mFailures,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Failures Per Minute'
  },
  {
    description: STRs.mReqsPM,
    isStatisticsSupported: false,
    key: 'requests_pm',
    metric: STRs.mReqsPM,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Rate Per Minute'
  }
];

const pushMetrics: MetricsUIDefinitions[] = [
  {
    description: STRs.descPushedClaimed,
    isStatisticsSupported: false,
    key: 'pushed_claimed_pm',
    metric: STRs.mPushedClaimed,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Rate Per Minute'
  },
  {
    description: STRs.descExternalGets,
    isStatisticsSupported: false,
    key: 'external_get_pm',
    metric: STRs.mExternalGets,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Rate Per Minute'
  },
  {
    description: STRs.descReqsWithSession,
    isStatisticsSupported: false,
    key: 'requests_with_session_pm',
    metric: STRs.mReqsWithSession,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Rate Per Minute'
  },
  {
    description: STRs.descReqsWithoutSession,
    isStatisticsSupported: false,
    key: 'requests_without_session_pm',
    metric: STRs.mReqsWithoutSession,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Rate Per Minute'
  }
];

const csaMetrics: MetricsUIDefinitions[] = [
  {
    description: STRs.descCSAAcceleration,
    isStatisticsSupported: false,
    key: 'csa_acceleration_ratio_pm',
    metric: STRs.mCSAAcceleration,
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'percentage',
    nameReplacements: {
      'csa_acceleration_ratio_pm': ANALYTICS_STRINGS.altCSAAccelerationRation
    },
  },
  {
    description: STRs.descCSAResponseTimeMean,
    isStatisticsSupported: false,
    key: 'csa_client_latency_mean_pm,csa_origin_latency_mean_pm',
    metric: STRs.mCSAResponseTimeMean,
    specialProc: 'ng',
    pocType: [0, 2, 3, 4],
    pocDisabled: [],
    unit: 'Milliseconds',

    nameReplacements: {
      'csa_client_latency_mean_pm': ANALYTICS_STRINGS.altCSAAvgClientResponseTIme,
      'csa_origin_latency_mean_pm': ANALYTICS_STRINGS.altCSAAvgOriginResponseTime,
    },
    colors: {
      'csa_client_latency_mean_pm': 'rgba(18, 193, 141, 1)',
      'csa_origin_latency_mean_pm': 'rgba(83, 208, 218, 1)',
    },
    additionalTxt: ANALYTICS_STRINGS.clickLegend,
  },
  // {
  //   description: STRs.descCSARequests,
  //   isStatisticsSupported: false,
  //   key: 'csa_client_requests_pm,csa_origin_requests_pm,csa_accelerated_requests_pm',
  //   specialProc: 'ng',
  //   metric: STRs.mCSARequests,
  //   pocType: [0, 2, 3, 4],
  //   pocDisabled: [],
  //   unit: 'Rate Per Minute',
  //   nameReplacements: {
  //     'csa_client_requests_pm': ANALYTICS_STRINGS.altCSAClientRequest,
  //     'csa_origin_requests_pm': ANALYTICS_STRINGS.altCSAOriginRequest,
  //     'csa_accelerated_requests_pm': ANALYTICS_STRINGS.altCSAAcceleratedRequest,
  //   },
  //   colors: {
  //     'csa_client_requests_pm': 'rgba(18, 193, 141, 1)',
  //     'csa_origin_requests_pm': 'rgba(83, 208, 218, 1)',
  //     'csa_accelerated_requests_pm': 'rgba(255, 174, 66, 1)',
  //   },
  //   additionalTxt: ANALYTICS_STRINGS.clickLegend,
  // },
  // {
  //   description: STRs.descCSABytesOut,
  //   isStatisticsSupported: false,
  //   key: 'csa_client_bytes_out_pm,csa_origin_bytes_out_pm',
  //   specialProc: 'ng',
  //   metric: STRs.mCSABytesOut,
  //   pocType: [0, 2, 3, 4],
  //   pocDisabled: [],
  //   unit: 'Bytes Per Minute',
  //   nameReplacements: {
  //     'csa_client_bytes_out_pm': ANALYTICS_STRINGS.altCSABytesOutClient,
  //     'csa_origin_bytes_out_pm': ANALYTICS_STRINGS.altCSABytesOutOrigin,
  //   },
  //   colors: {
  //     'csa_client_bytes_out_pm': 'rgba(18, 193, 141, 1)',
  //     'csa_origin_bytes_out_pm': 'rgba(83, 208, 218, 1)',
  //   },
  //   additionalTxt: ANALYTICS_STRINGS.clickLegend,
  // },
]

const allMetrics: MetricsUIDefObj = {
  [SysFeatureEnums.trafficAnl]: trafficMetrics,
  [SysFeatureEnums.cacheAnl]: cacheMetrics,
  [SysFeatureEnums.nrumAnl]: nrumMetrics,
  [SysFeatureEnums.efrAnl]: efrMetrics,
  [SysFeatureEnums.pushAnl]: pushMetrics,
  [SysFeatureEnums.dnsDirAnl]: dnsDirMetrics,
  [SysFeatureEnums.wafAnl]: wafMetrics,
  [SysFeatureEnums.botAnl]: botMetrics,
  [SysFeatureEnums.csmAnl]: csmMetrics,
  [SysFeatureEnums.csaAnl]: csaMetrics,
}

export interface AnalyticsOptions {
  observer?: string[];
  origin?: string[];
  period: string[];
  refresh: string[];
  stateName?: string[]; // to avoid confusion with system state
  statistics?: string[];
  geos?: string[];
  cdns?: string[];
  healthChk?: string[];
}

export interface ZSessionStorage {
  environment: string;
  feature: SysFeatureEnums;
  projectId: string;
  serviceId: string;
  tab: string;
  url: string;
  misc?: StringMap;
}

export interface ColumnSortMap {
  [fieldName: string]: ZSortDirection
}

export interface PaginationData {
  pageSize: number,
  totalItems: number,
  qryTotalItems: number,
  pageToShow: number,
  currentPage: number,
  moreThanMax: boolean,
  isGoodData: boolean,
}

// current misc values is 'newSvcId' for create service

export { trafficMetrics, cacheMetrics, efrMetrics, nrumMetrics, pushMetrics, allMetrics, wafMetrics, botMetrics, 
         dnsDirMetrics, csmMetrics, csaMetrics };
