/* eslint-disable react/prop-types */
import * as React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { PageItemProps } from 'react-bootstrap/PageItem';

import { PaginationData } from '../data/metricsAndOptionsDefs';

export interface PaginationProps {
    pData: PaginationData,
    handlePageChange: (pageNum: number) => void 
}

const  PaginationCtrl: React.FunctionComponent<PaginationProps> = props => {
    const first: PageItemProps = { active: false, disabled: false };
    const last: PageItemProps = { active: false, disabled: false };
    const pItems: JSX.Element[] = [] as JSX.Element[];

    const currPage = props.pData.currentPage;
    const totalPages = Math.floor((props.pData.totalItems - 1) / props.pData.pageSize) + 1;
    const makePageItem = (pNum: number): JSX.Element => {
        const active: boolean = pNum === currPage;
        return (
            <Pagination.Item key={pNum} active={active} onClick={() => {
                props.handlePageChange(pNum) }} >{pNum}</Pagination.Item>
        )
    }

    const pageNeighbors = 2;
    if (totalPages <= 7) {  // 7 pages or less, show them all
        for (let i = 0, len = totalPages; i < len; i ++) {
            pItems.push(makePageItem(i + 1));
        }
    } else {
        if (currPage === 3) {
            pItems.push(makePageItem(1));  // always show page 1
        } else if (currPage === 4) {
            pItems.push(makePageItem(1));
            pItems.push(makePageItem(2));
        }
        if (currPage > 4) {
            pItems.push(makePageItem(1));  // always show page 1
            
            pItems.push(
                <Pagination.Ellipsis key="ell1" onClick={() => { props.handlePageChange(currPage - pageNeighbors)}} />
            );
        }

        if (currPage === totalPages) {
            pItems.push(makePageItem(totalPages - 2));
        }

        // now show current page and a page on either side .
        if (currPage !== 1) {
            pItems.push(makePageItem(currPage - 1));
        }
        pItems.push(makePageItem(currPage));
        if (currPage !== totalPages) {
            pItems.push(makePageItem(currPage + 1));
        }
        if (currPage === 1) {
            pItems.push(makePageItem(3));
        }

        // handle back part of control
        if (currPage === (totalPages - 2)) {
            pItems.push(makePageItem(totalPages));
        } else if (currPage === (totalPages - 3)) {
            pItems.push(makePageItem(totalPages - 1));
            pItems.push(makePageItem(totalPages));
        }
        if (currPage <= totalPages - 4) {
            pItems.push(
                <Pagination.Ellipsis key="ell2" onClick={() => { props.handlePageChange(currPage + pageNeighbors) }} />);
        
            pItems.push(makePageItem(totalPages));
        }

    }

    if (props.pData.currentPage === 1) {
        first.disabled = true;
        last.disabled = false;
    }

    if (props.pData.currentPage === totalPages) {
        first.disabled = false;
        last.disabled = true;
    }

    const pagingCtrl: JSX.Element = (
        <Pagination size="sm">
            <Pagination.First {...first} onClick={() => {
                                                props.handlePageChange(1)}} />
            {pItems}
            <Pagination.Last {...last} onClick={() => {
                                                props.handlePageChange(totalPages)}} />
        </Pagination>
    )
    
    return <div>{pagingCtrl}</div>;
}

export default PaginationCtrl;