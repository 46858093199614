import * as React from 'react';

// tslint:disable
const ApiDocOverview = (): JSX.Element => {
    return ( <>
<div className="z-title">Zycada API Documentation</div>

<div className="zsection">
    This document describes the REST APIs exposed by Zycada platform. These APIs can be used to perform operations like
    created/update/delete services, purge caches, blacklist/whitelist IP addresses, etc. All REST API requests are
    authenticated by the Authorization header, which has the following format:

    <table className="zlist">
        <tbody>
        <tr>
            <td>Authorization:</td>
            <td>Bearer [API_Key]</td>
        </tr>
        </tbody>
    </table>

    <p>
        API_Key is a string that establishes authority to manipulate assets in the Zycada platform.  You can get the API_Key from the console.
    </p>

    <p>
    The following are the domain names for REST APIs for the different Zycada environments:
    </p>

    <table className="zlist">
        <tbody>
        <tr>
            <td>Production</td>
            <td className="zli-inner">
                https://api.zycada.com
            </td>
        </tr>
        <tr>
            <td>Staging</td>
            <td className="zli-inner">
                https://staging-api.zycada.com
            </td>
        </tr>
        </tbody>
    </table>


    <div className="before-code"><h3>Supported operations</h3></div>
    <ul>
        <li>Manage your firewall IP addresses.  APIs exist to add whitelist and blacklist IPs; get a list
            of whitelist and black list IPs; and delete specific whitelist and blacklist APIs.</li>
        <li>Manage your cached assets.  APIs allow you to request purge operations and obtain the
            status of those operations.  Assets can be purged by URIs.  They can also be purged using URI Codes
            which are special codes that define a specific portion of your data tree.  When URI codes are used
            in the purge call, all data below the specified location are purged.</li>
        <li>Retrieve log data from the Zycada platform.</li>
        <li>Retrieve analytic metrics from the Zycada platform.</li>
    </ul>

</div>
</>
    )
}

export default ApiDocOverview;