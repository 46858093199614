import * as React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';
import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { ZApiKey, ZUserMin, ZRestResponse, ApiKeyPermission, FetchError } from '../../data/queryResultDefinitions';

import { buildUrl } from '../../reducers/serverEnvironAccessor';
import { ZAdminMapType, ZFeaturePopupTypes, EProjectState } from '../../reducers/reducerEnums';

import { ApiKeyControl, loadUserApiKeys, buildEditApiKeyPopup, buildNewKeySuccess, buildExpireKeysPopup } from './apiKeyUtils';

import { ADMIN_STRINGS as Strs} from '../../shared/strings';
import { NotificationStyles } from '../../shared/constants';
import { ZGet } from '../../shared/backend';
import { buildUserName } from '../../shared/utilities';

import ZURLS from '../../shared/urls';
import ListPanel from '../../shared/ListPanel';
import logger from '../../shared/logUtilities';

export interface APIKeyAdminProps extends State {
    applySettings: () => actionTypes;
    setAdminUserList: (userList: ZUserMin[]) => actionTypes;
    setUserListIndex: (index: number) => actionTypes;
    setUserApiKeyList: (apikeys: ZApiKey[]) => actionTypes;
    setApiKeyListIndex: (index: number) => actionTypes;
    toggleAdminSelection: (collectionName: ZAdminMapType, id: string) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    toggleAdminPopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean, 
                       value?: number | string | ZApiKey) => actionTypes;
    toggleShowExpiredApiKeys: () => actionTypes;
    setApiKeyState: (friendlyName: string, expirationDate: moment.Moment, 
                     permission?: ApiKeyPermission) => actionTypes;
}

// eslint-disable-next-line 
class ApiKeyAdmin extends React.Component<APIKeyAdminProps> {
    componentDidMount(): void {
        logger.log('mounting Api Key admin');
        this.initAPIKeyAdminPage();
    }

    componentDidUpdate(prevProps: APIKeyAdminProps): void {
        const adminUIState = this.props.adminUIState;
        const oldAdminState = prevProps.adminUIState;
        if (adminUIState.adminState === EProjectState.tabChanged) {
            this.initAPIKeyAdminPage();
        }
        logger.log(`component did update (Api Key admin): ${adminUIState}`);
        if (adminUIState.userListIdx !== oldAdminState.userListIdx || 
            adminUIState.showExpiredKeys !== oldAdminState.showExpiredKeys) {
            loadUserApiKeys(this.props)
        }
    }

    render(): JSX.Element {
        const adminState = this.props.adminUIState;
        const hasUsers = adminState.userList.length > 0;
        const userList = this.buildUserList(hasUsers);

        // const numCheckedItems = getNumCheckedAdminItems(adminState, AdminManagementTypes.apiKeys);
        // const canNotRemoveKeys = (numCheckedItems === 0);
        // const canEditKey = !(numCheckedItems === 1);
        const canCreateKeys = false;
        const markup = <ApiKeyControl state={this.props} canCreateKeys={canCreateKeys} showCreateBtn={false} />;

        const popupBuilders = {
            // [ZFeaturePopupTypes.GenerateKey]: this.buildGenerateApiKeyPopup,
            [ZFeaturePopupTypes.EditKey]: buildEditApiKeyPopup,
            [ZFeaturePopupTypes.ExpireKeys]: buildExpireKeysPopup,
            [ZFeaturePopupTypes.NewAPIKeyCreated]: buildNewKeySuccess,
        };

        let adminPopup = <span></span>;
        if (popupBuilders[this.props.adminUIState.popupType]) {
            adminPopup = (popupBuilders[this.props.adminUIState.popupType])(this.props);
        }

        const userHeader: JSX.Element = (
            <div className="admin-ka-user-list-header">
                <div></div>
                <div>{Strs.user}</div>
                <div>{Strs.numKeys}</div>
            </div>);


        return (
        <>
            <div className="title">{Strs.keyManagement}</div>
            <div id="admin-tables" className="key-admin">
                <div className="admin-panel">
                    <ListPanel 
                        renderHeader={(): JSX.Element => { return userHeader }}
                        renderBody={(): JSX.Element => { return userList}}
                    />
                </div>
                {markup}
            </div>
            {adminPopup}
        </>
        );
    }

    private buildUserList = (hasUsers: boolean): JSX.Element => {
        const { userListIdx, userList } = this.props.adminUIState;
        const usrList: JSX.Element[] = [];

        if (hasUsers) {
            for (let i = 0, len = userList.length; i < len; i++) {
                const selected = (i === userListIdx) ? 'selected' : '';
                const user = userList[i];

                const keysInProject = (user && user.meta.num_api_keys !== undefined) ? user.meta.num_api_keys : '';
                usrList.push((
                    <div key={'line' + i} className={'admin-ka-user-list-item ' + selected}
                        onClick={(): void => {
                            this.selectedUserChangedHandler(i)
                        }
                    }>
                        <div></div>
                        <div>{buildUserName(user)}</div>
                        <div>{keysInProject}</div>
                    </div>
                ))
            }
        } else {
            usrList.push((
                <div key="no-users" className="admin-ka-user-list-item" >
                    <div></div>
                    <div>{Strs.noUserNoKeys}</div>
                    <div></div>
                </div>
            ))
        }

        return <>{usrList}</>;
    }

    // ***********
    //
    // Click handlers
    private selectedUserChangedHandler = (index: number, force = false): void => {
        const adminState = this.props.adminUIState;
        if ( (adminState.userList.length > 0) && (index !== adminState.userListIdx) || force) {
            this.props.setUserListIndex(index);
            loadUserApiKeys(this.props);
        }
    }

    // **************
    //
    // Data loading methiods
    private initAPIKeyAdminPage = (): void => {
        this.loadUserList();
    }

    private loadUserList = async (): Promise<void> => {
        try {
            const url = buildUrl(this.props.authNServerData, ZURLS.serverAdminUserList);
            const userList: ZRestResponse = await ZGet(url, {});
            this.props.setAdminUserList(userList as ZUserMin[]);
            this.selectedUserChangedHandler(this.props.adminUIState.userListIdx, true);
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`ApiKeyAdmin.loadUserList: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        }
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        adminState: state.adminUIState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default  connect (stateToProps, mapDispatchToProps)(ApiKeyAdmin);
