import * as React from 'react';
const ApiDocAnalytics = (): JSX.Element => {

    return ( <>
        <div className="z-title">Analytics</div>

        <div className="zsection">
        The analytics API is used to retrieve metrics from the delivery network. The metrics in the API response are
        in JSON format.  Maximum of 1000 metrics will be returned per API call. When there is more than 1000 metrics 
        matching the filter criteria, the “next” field in the response can be used to paginate.
    
        <div className="before-code"><h3> </h3></div>
        <table className="zlist">
            <tbody>
            <tr>
                <td>Method:</td>
                <td className="method">GET</td>
            </tr>
            <tr>
                <td>Production URL:</td>
                <td className="zli-inner">https://api.zycada.com/v1/metrics</td>
            </tr>
            <tr>
                <td>Staging URL:</td>
                <td className="zli-inner">https://staging-api.zycada.com/v1/metrics</td>
            </tr>
            <tr>
                <td>Headers:</td>
                <td>
                    <table className="izlist">
                        <tbody>
                        <tr>
                            <td>Authorization:</td>
                            <td>Bearer <i>[API_Key]</i></td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    
        <div className="before-code"><h3>Arguments</h3></div>
        <ul className="simple-list">
            <li>
                The [API_KEY] is a string that  establishes authority to manipulate assets. 
                You can only modify your own assets.
            </li>
        </ul>
    
        <div className="before-code"><h3>Request</h3></div>
        The following query parameters can be specified on the URL used in fetching analytics data.
    
        <table className="qlist">
            <tbody>
            <tr>
                <th>Query Parameter:</th>
                <th className="method">Description</th>
            </tr>
            <tr>
                <td>from</td>
                <td>
                    <h4>Starting time (inclusive)</h4>
                    <h4>Could be in one of the following formats:</h4>
                    <h5>Relative time:</h5>
                    <p>-Ns - N seconds earlier</p>
                    <p>-Nm - N minutes earlier</p>
                    <p>-Nh - N hours earlier</p>
                    <p>-Nd - N days earlier</p>
                    <p className="tspacer">Can be combined and specified as NmNhNd</p>
                    <h5 className="tspacer">Absolute time <span className="normal">(ISO 8601 format)</span>:</h5>
                    <p>MM/DD/YYYY:HH:MM:SS</p>
                    <h4 className="tspacer">Epoch seconds</h4>
                    This is a mandatory field.
                </td>
            </tr>
            <tr>
                <td>to</td>
                <td>
                    <h4>End time (exclusive). Accepts the same format as above.</h4>
                    <h4>
                    A special value of “now” can be specified to retrieve logs up to but not including the current time.
                    </h4>
                    This is a mandatory field.
                </td>
            </tr>
            <tr>
                <td>service_id</td>
                <td>
                    <h4>Comma separated list of the service ids whose log data should be received.</h4>
                    This is a mandatory field.
                </td>
            </tr>
            <tr>
                <td>metrics</td>
                <td>
                    <h4>Comma separated list of metrics that should be retrieved. For instance:</h4>
                    <h4>&nbsp;&nbsp;&nbsp;&nbsp;cache_hits, cache_misses, requests</h4>
                    This is a mandatory field.
                </td>
            </tr>
            <tr>
                <td>agg_interval_sec</td>
                <td>
                    <h4>Aggregation interval for metrics.</h4>
                    Default is 1 minute.
                </td>
            </tr>
            </tbody>
        </table>
    
    <div className="before-code"><h3>Response</h3></div>
    The response body is a JSON object that contains the metrics. The following describes the
    JSON object returned by the server..
    <pre className="expected-response">{`{
    "status": "success",                        // string. API response status.
    "next": "",                                 // string. URL to fetch the next page of the response.
    "metrics": [ ]                              // array of analytic results. Each entry contains an analytics
                                                // result object.  The format of these objects are defined below:
}
    
The following is the format of the analytics results object:

{
    "metric": "",                           // string. Metric name.
    "data": [                               // Array. Metric data.
        {
            "epoch_second": 1552339800,     // number. Time as epoch second format.
            "value": 11                     // number. Metric value.
        },
        {
            "epoch_second": 1552340100,     // number. Time as epoch second format.
            "value": 7.666666666666666      // number. Metric value.
        },
        ...
    ]
}`}</pre>
    
        <div className="before-code"><h3>HTTP Response Status</h3></div>
        <ul className="simple-list sample-response">
            <li><h4>200</h4> Success.</li>
            <li><h4>400</h4> Bad Request. (e.g. invalid field type in the request)</li>
            <li><h4>401</h4> Invalid API key.</li>
            <li><h4>403</h4> You are trying to obtain analytics on domains that aren&apros;t yours.</li>
        </ul>
    
        <div className="before-code"><h3>Sample HTTP Request</h3></div>
        <pre className="sample-response">

        https://api.zycada.com/v1/metrics?from=-5d&to=now&service_id=1234&metrics=cache_hits
        
        </pre>
    
        <div className="before-code"><h3>Sample HTTP Response</h3></div>
        <pre className="sample-response">{`{   
    "status": “success”,
    "next": “https://api.zycada.com/v1/metrics?from=-5d&to=now&service_id=1234&metrics=cache_hits&skip=1000&limit=1000”,
    “metrics”: [
        { 
            "metric": "cache_hits",
            "data": [
                { 
                    "epoch_second": 1552339800,
                    "value": 11
                },
                { 
                    "epoch_second": 1552340100,
                    "value": 7.666666666666666
                },
                ...
            ]
        }
    ]
}`}</pre>
    </div>
    </>
    );
// tslint:enable
};

export default ApiDocAnalytics;
