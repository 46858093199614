import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { ZRestResponse, AvailableBillingReports, ReportOverviewData, FetchError } 
            from '../../data/queryResultDefinitions';
    
import { SysFeatureEnums, EProjectState} from '../../reducers/reducerEnums';

import { buildUrl } from '../../reducers/serverEnvironAccessor';

import { REPORT_STRINGS as STRs, MISC_STRINGS as MISC } from '../../shared/strings';
import { ZGet } from '../../shared/backend';
import { NotificationStyles } from '../../shared/constants';

import ZURLS from '../../shared/urls';
import logger from '../../shared/logUtilities';

interface ReportsDownloadProps extends State {
    applySettings: (error?: string) => actionTypes;
    initFeature: (featurName: SysFeatureEnums) => actionTypes,
    featureReady: () => actionTypes,
    setBillingReportData:(availableBillingReports: AvailableBillingReports) => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
}

class BillingDownloads extends React.Component<ReportsDownloadProps> {
    public componentDidMount(): void {
        logger.log('Change profile');
        this.props.initFeature(SysFeatureEnums.billingReportsDownloads);
    }
    
    public componentDidUpdate(): void {
        const reportState = this.props.reportUIState.reportState;
        if (reportState === EProjectState.initStarted || reportState === EProjectState.unknown) {
            this.initTrafficUsage();
        }
    }

    render(): JSX.Element {
        const reportUIState = this.props.reportUIState;
        const authState = this.props.authNServerData;
        const orgName = authState.currentOrg.org_name;
        let reportMarkup: JSX.Element = (<div></div>);

        switch (reportUIState.reportState) {
            case EProjectState.unknown:
                break;

            case EProjectState.ready: {
                const availableBillingReports = reportUIState.availableBillingReports;
                const keys = Object.keys(availableBillingReports)
                const title = STRs.dataDownloadsTitle(orgName);
                if (keys.length > 0) {
                    const downlistMarkup = this.buildDownloads();
                    reportMarkup =  (<>
                        <div className="title">{title}</div>
                        <div>
                            <div className="download-instructs">{STRs.dataDownloadsInstructs(orgName)}</div>
                            {downlistMarkup}
                        </div>
                        </>)
                } else {
                    reportMarkup = (<>
                        <div className="title">{title}</div>
                        <div>
                            <div className="download-instructs">{STRs.noReportsForOrg}</div>
                        </div>
                        </>

                    )
                }}
                break;

            default:
                reportMarkup = <div>{MISC.loading}</div>;
                break;

        }
        
        return reportMarkup;
    }

    private buildDownloads = (): JSX.Element => {
        const reportState = this.props.reportUIState;
        const months = {
            '01': MISC.january, '02': MISC.february, '03': MISC.march, '04': MISC.april, 
            '05': MISC.may, '06': MISC.june, '07': MISC.july, '08': MISC.august, 
            '09': MISC.september, '10': MISC.october, '11': MISC.november, '12':MISC.december
        }

        let statementDates: JSX.Element[] = [];
        const availableTrafficReports = reportState.availableBillingReports.traffic_usage;

        if (availableTrafficReports && availableTrafficReports.length > 0) {
            statementDates = availableTrafficReports.map((report: ReportOverviewData) => {
                let monthStr = months[report.month];
                monthStr = monthStr !== undefined ? monthStr : STRs.unknownMonth;
                monthStr =  STRs.monthYear(monthStr, report.year);
                const downloadName = `${months[report.month]}-${report.year}-download.csv`; 
                return (
                    <div key={monthStr} className="download-list-item">
                        <div>{monthStr}</div>
                        <div><a href={report.csv_url} download={downloadName}>CSV</a></div>
                    </div>
                )
            });

        } else {
            statementDates.push((<div>{STRs.noBillingData}</div>));
        }

        return <>{statementDates}</>
    }

    private initTrafficUsage = async (): Promise<void> => {

        const url = buildUrl(this.props.authNServerData, ZURLS.serverBillingReportsAvailable);

        try {
            const getData: ZRestResponse = await ZGet(url, {});
            this.props.setBillingReportData(getData as AvailableBillingReports);
            this.props.featureReady();
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`TrafficeUsage.initTrafficUsage:: fetch failed: ${err}`);

                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
                this.props.applySettings('serverError');
            })
        }
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        reportUIState: state.reportUIState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default  connect (stateToProps, mapDispatchToProps)(BillingDownloads);