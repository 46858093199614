import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../actions/actionCreators';
import { actionTypes } from '../../actions/actionCreatorTypes';

import { State } from '../../App';

import { MetricsUIDefinitions } from '../../data/metricsAndOptionsDefs';

import { getProjectFeatures } from '../../reducers/projectAccessors';
import { SysFeatureEnums } from '../../reducers/reducerEnums'
import { getMetricCheckState, getMetricsForFeatures, ProjectMetricDefinitions } from '../../reducers/uiAccessors';
import { SelectedMetrics } from '../../reducers/uiAccessors';
import { ZServiceFeatures } from '../../data/queryResultDefinitions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import SharedPanel from '../../shared/SharedPanel';
import { ANALYTICS_STRINGS as STRs, MISC_STRINGS as MSTRs } from '../../shared/strings';

export interface MetricPanelProps extends State {
    metricCSS: string;
    setMetricCheckBox: (metricKey: string, value: boolean) => actionTypes;
}

// eslint-disable-next-line 
class MetricsPanel extends React.Component<any, MetricPanelProps> {

    public render(): JSX.Element {
        const panelChildren = React.Children.map(this.props.children, child => {
            return child;
        });

        const features: ZServiceFeatures = getProjectFeatures(this.props.systemNavProjectState);
        const currentFeature = this.props.analyticsUIState.currentFeature; 
        const projMetricsList: ProjectMetricDefinitions = 
                                    getMetricsForFeatures(this.props.systemNavProjectState, features);
        const metricCheckStates: SelectedMetrics[] = getMetricCheckState(this.props.analyticsUIState)

        let metricCheckBoxes;

        switch (currentFeature) {
            case SysFeatureEnums.trafficAnl:
                metricCheckBoxes = this.buildCheckBoxGroup(metricCheckStates, projMetricsList.analyticsTraffic);
                break;

            case SysFeatureEnums.cacheAnl:
                metricCheckBoxes = projMetricsList[SysFeatureEnums.cacheAnl] !== undefined ?
                    this.buildCheckBoxGroup(metricCheckStates, projMetricsList[SysFeatureEnums.cacheAnl] as MetricsUIDefinitions[]) : [];
                break;

            case SysFeatureEnums.nrumAnl:
                metricCheckBoxes = (projMetricsList[SysFeatureEnums.nrumAnl]) ? 
                    this.buildCheckBoxGroup(metricCheckStates, projMetricsList[SysFeatureEnums.nrumAnl] as MetricsUIDefinitions[]) : [];
                break;

            case SysFeatureEnums.pushAnl:
                metricCheckBoxes = (projMetricsList[SysFeatureEnums.pushAnl]) ?
                    this.buildCheckBoxGroup(metricCheckStates, projMetricsList[SysFeatureEnums.pushAnl] as MetricsUIDefinitions[]) : [];
                break;

            case SysFeatureEnums.wafAnl:
                metricCheckBoxes = (projMetricsList[SysFeatureEnums.wafAnl]) ? 
                    this.buildCheckBoxGroup(metricCheckStates, projMetricsList[SysFeatureEnums.wafAnl] as MetricsUIDefinitions[]) : [];
                break;

            case SysFeatureEnums.botAnl:
                metricCheckBoxes = (projMetricsList[SysFeatureEnums.botAnl]) ? 
                    this.buildCheckBoxGroup(metricCheckStates, projMetricsList[SysFeatureEnums.botAnl] as MetricsUIDefinitions[]) : [];
                break;

            case SysFeatureEnums.csaAnl:
                metricCheckBoxes = (projMetricsList[SysFeatureEnums.csaAnl]) ? 
                    this.buildCheckBoxGroup(metricCheckStates, projMetricsList[SysFeatureEnums.csaAnl] as MetricsUIDefinitions[]) : [];
                break;

            case SysFeatureEnums.efrAnl:
            case SysFeatureEnums.streamSOAnl:
            case SysFeatureEnums.streamLBAnl:
            case SysFeatureEnums.streamWMAAnl:
            case SysFeatureEnums.logsAnl:
                metricCheckBoxes = (<span>no metrics</span>);
                break;

            default:
                break;
        }

        return (
            <SharedPanel panelId="metric-panel" panelClasses={this.props.metricCSS}
                         title={STRs.metricsLabel.toUpperCase()}>
                {panelChildren}
                <div>
                    {metricCheckBoxes}
                </div>
            </SharedPanel>
        );
    }

    private buildCheckBoxGroup(metricCheckStates: SelectedMetrics[] | undefined, metricsList: MetricsUIDefinitions[]): 
    React.ReactNode {
        let metricCheckBoxes;
        let metricCheckBoxes1;
        let allChecked = true;
        if (metricCheckStates) {
            metricCheckBoxes = metricsList.map((metricDef: MetricsUIDefinitions) => {
                const checked: boolean = metricCheckStates[metricDef.key] ? true : false;
                allChecked = allChecked && checked;

                return this.makeCheckbox(checked, metricDef);
            });

            const allMetric: MetricsUIDefinitions = {
                description: STRs.descAll,
                isStatisticsSupported: false,
                key: MSTRs.allOption,
                metric: MSTRs.allOption,
                pocType: [0, 1, 2, 3],
                pocDisabled: [],
                unit: '',
            };

            metricCheckBoxes1 = [this.makeCheckbox(allChecked, allMetric)];
            metricCheckBoxes1.push((<div className="hrule" key="rule" />));
            metricCheckBoxes = metricCheckBoxes1.concat(metricCheckBoxes);
        } else {
            metricCheckBoxes = '';
        }

        return metricCheckBoxes;
    }
    
    private makeCheckbox(checked: boolean, metricDef: MetricsUIDefinitions): React.ReactNode {
        const ttip = <Tooltip id={'tt' + metricDef.key}>{metricDef.description}</Tooltip>
        return (
            <OverlayTrigger  key={metricDef.key} placement="top" delay={500} overlay={ttip}>
                <label className="ZCheckBox">{metricDef.metric}
                    <input type="checkbox" disabled={metricDef.required} checked={checked} name={metricDef.key} 
                           onChange={(e): void => { const value: boolean = (e.target.checked);
                                              this.props.setMetricCheckBox(e.target.name, value);
                    }} />
                    <span className="checkmark">&nbsp;</span>
                </label>
            </OverlayTrigger>
        )
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        analyticsUIState: state.analyticsUIState,
        
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(MetricsPanel);