import React, { useState,} from 'react';

import { State } from '../../App';

import { actionTypes } from '../../actions/actionCreatorTypes';

import { FetchError, ServiceStatusEnum } from '../../data/queryResultDefinitions';
import { StringMap } from '../../data/metricsAndOptionsDefs';

import { ZService } from '../../reducers/systemState'
import {  ZEnvironments } from '../../reducers/reducerEnums'
import { buildUrl } from '../../reducers/serverEnvironAccessor';

import { ZDelete } from '../../shared/backend';

import ZURLS from '../../shared/urls';
import ZButton from '../../shared/ZButton';
import ZDropDown from '../../shared/ZDropDown';

import { NotificationStyles } from '../../shared/constants';
import { SERVICES_STRINGS as STRs, SUBNAVSTRINGS } from '../../shared/strings'; 
// import logger from '../../shared/logUtilities';

export interface DeleteServiceProps extends State {
    deleteService: (serviceId: string) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
}

const DeleteService: React.FunctionComponent<DeleteServiceProps> = props => {
    const [ serviceNameList, setServiceNameList] = useState<string[]>([]);
    const [ servicesList, setServicesList] = useState<ZService[]>([]);
    const [ index, setIndex ] = useState(-1)
    const navState = props.systemNavProjectState;
    let hasServices = true;

    if (!navState.servicesLoaded) {
        return <div></div>
    }

    const applyDeleteService = async (): Promise<void> => {
        // const name = serviceNameList[index];
        const service = servicesList[index]

        let serviceId = service.service_id;
        const env = service.env;
        if (env === ZEnvironments.staging) {
            if (service.meta && service.meta.prod_info && service.meta.prod_info.service_id) {
                serviceId = service.meta.prod_info.service_id
            }
        }

        const url = buildUrl(props.authNServerData, ZURLS.serverServiceManagement(serviceId));

        try {
            await ZDelete(url, '');
            const msg = STRs.successDeletedService(service.serviceName);
            props.showNotification(NotificationStyles.success, msg);
            setIndex(0);
            closeNotification();
            props.deleteService(serviceId)
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`viewService.applyDeleteService: fetch failed: ${err}`);

                props.showNotification(NotificationStyles.danger, err.message);
                closeNotification();
            })
            .catch((err) => {
                console.log(`viewService.applyDeleteService: fetch failed: ${err}`);
            })
        }
    }

    const closeNotification = (): void => {
        setTimeout(() => { props.closeNotification(); }, 5000);
    }

    const allSvcNames: string[] = [];
    const allSvcs: ZService[] = [];
    const allSvcNamesMap: StringMap = {}

    const services = navState.serviceEvents;

    const serviceName = (index === -1) ? services[navState.serviceEvtIdx] : serviceNameList[index];

    // get all service names
    // first get staging service names
    const proj = navState._currentProject;
    const prodEnv = proj.prodEnv;
    const stagingEnv = proj.stagingEnv;

    if (proj.stagingEnv !== undefined && proj.stagingEnv.allServiceNames !== undefined) {
        proj.stagingEnv.allServiceNames.forEach((name: string): void => {
            const svcId = stagingEnv.serviceNameToId[name]
            const svc = proj.serviceIdMap[svcId];
            if (svc.status !== ServiceStatusEnum.deleting) {
                allSvcNames.push(name);
                allSvcs.push(svc)
                allSvcNamesMap[name] = name;
            }
        })
    }

    // get prod service names
    if (proj.prodEnv !== undefined && proj.prodEnv.allServiceNames !== undefined) {
        proj.prodEnv.allServiceNames.forEach((name: string): void => {
            if (allSvcNamesMap[name] === undefined) {
                const svcId = prodEnv.serviceNameToId[name]
                const svc = proj.serviceIdMap[svcId];
                if (svc.status !== ServiceStatusEnum.deleting) {
                    allSvcNames.push(name);
                    allSvcs.push(svc)
                    allSvcNamesMap[name] = name;
                }
            }
        })
    }

    let idx = allSvcNames.findIndex((name: string) => {
        return name === serviceName;
    });

    if (idx === -1) {
        idx = 0;
        if (allSvcNames.length === 0) {
            hasServices = false;
            allSvcNames.push(STRs.dropdownNoServices);
        } 
    }

    if (JSON.stringify(allSvcNames) !== JSON.stringify(serviceNameList)) {
        setIndex(idx);              
        setServiceNameList(allSvcNames);
        setServicesList(allSvcs);
    }
    
    const projectName = proj.name;
    const serviceNameDropdown = ZDropDown( serviceNameList, index, 'svcEvt-selection',  (idx) => { setIndex(idx) });

    const deletePanel = (hasServices) ? (
        <div className="delete-service-panel">
            <div>{SUBNAVSTRINGS.deleteService}</div>
            <div>
                <div>{STRs.deleteStagingAndProdServiceWarning}</div>
                {STRs.serviceDropdownLabel}&nbsp;&nbsp;{serviceNameDropdown}&nbsp;&nbsp;
                <ZButton disabled={!hasServices} onClick={(): void => { applyDeleteService(); }}>
                            {STRs.deleteService} </ZButton>
                    
            </div>
        </div>
    ) : (
        <div>{STRs.noServicesForDelete}</div>
    );

    const markup = (
        <div className="feature-panel">
        <div className="servicesFeature">
            <div id="delete-service">
                <div className="title">{STRs.deleteService}</div>
                <div>{STRs.currentProject}&nbsp;&nbsp; {projectName}</div>
                {deletePanel}
            </div>
        </div>
        </div>
    );
    return markup;
}


export default DeleteService; 