import * as React from 'react';

import { RoutingRules, ZMatchFilter, /* ZServiceFeatures, */ RoutingFeatures, CacheFeature, FeatureDef,
         XffFeature, CacheAgeItem, ZServiceExt, ZDnsDirectorDomain, HealthCheckIntervals,
         ZCloudServiceMonitor, ZCSMEntity } from '../../data/queryResultDefinitions';
import { StringMap, DisplayRoutingRules, servicesTypeAssetRule, NumberMap } from '../../data/metricsAndOptionsDefs';
import { BackupHCValues } from '../../reducers/reducerEnums';
// import { ZService } from '../../reducers/systemState';
import { SERVICES_STRINGS as STRs, MISC_STRINGS, ADMIN_STRINGS } from '../../shared/strings';
// import  SharedPanel from '../../shared/SharedPanel'
import { CertificateObject } from '../../shared/utilities';

export interface ServiceDisplayProps {
    service: ZServiceExt,
    hideEnvironment?: boolean,
    projCsmHCIntervals?: NumberMap,
    certObj: CertificateObject,
}

// there must be a 1 to 1 match between SvcStatusMap and the ServiceStatusEnum in queryResultsDefinitions

interface ServiceData {
    key: string,
    data: {
        [key: string]: string
    },
    list: StringMap[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const buildServiceOverview = (serviceInfo: ZServiceExt, serviceName: string, hideEnv: boolean, cert?: CertificateObject): JSX.Element => {
    // console.log(serviceInfo);
    let keyId = 0;

    // if (cert) 
    const title = <div key={"ovr" + keyId++}><span className="section-title">{STRs.service}</span></div>;
    const svcNameChange: string[] = (serviceInfo.service_name === serviceName) ? [ '', ''] : 
                                  [STRs.asterick, STRs.asterickMsg];

    let certDef = <><div></div><div></div></>;
    if (cert && cert.isAValidCert) {
        certDef = (
            certDef = <><div key={"ovr" + keyId++}>{STRs.certCommonName}</div><div>{cert.certFullCertDisplayInfo}</div></>
        );
    }
    const platform = serviceInfo.platform
    let serviceplatformDetails = <><div></div><div></div></>;
    if (platform !== undefined && platform.length > 0) {
        serviceplatformDetails = (
            <><div key={"ovr" + keyId++}>{STRs.platform}</div><div key={"ovr" + keyId++}>{platform}</div></>
        )
    }
    return (
        <section key={"ovr" + keyId++} className="view-routing-rules">
            {title}
            <div key={"ovr" + keyId++} className="view-service-rule">
                <div key={"ovr" + keyId++}>{STRs.serviceName}</div><div key={"ovr" + keyId++}>{serviceInfo.service_name}{svcNameChange[0]}</div>
                <div key={"ovr" + keyId++}>{STRs.domainName}</div><div key={"ovr" + keyId++}>{serviceInfo.zycadized_domain_name}</div>
                <div key={"ovr" + keyId++}>{STRs.cname}</div><div key={"ovr" + keyId++}>{serviceInfo.cname}</div>
                {serviceplatformDetails}
                {certDef}
            </div>
            {svcNameChange[1]}
        </section>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-types
const objectContents = (obj: object) => {
    const keys = Object.keys(obj);
    const objContent = {};
    for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        let data = obj[key];
        if (Array.isArray(data)) {
            data = JSON.stringify(data);
        }
        objContent[key] = data;
    }

    return objContent;
};

const objectList = (obj: Record<string, string>) => {
    const keys = Object.keys(obj);
    const objContent = [];
    for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        let data = obj[key];
        if (Array.isArray(data)) {
            data = JSON.stringify(data);
            // console.log(data);
            data = data.substring(1, data.length - 1);
        } else if (typeof data === 'boolean' ) {
            data = data ? MISC_STRINGS.true : MISC_STRINGS.false;
        } 
        if (typeof data === 'string' && data.charAt(0) === '"') {
            data = data.substring(1, data.length - 1);
        }
        objContent.push({name: key, data});
    }

    return objContent;
};

const buildOrigins = (originData: ServiceData[]): JSX.Element => {
    let i = 0;
    let origins: JSX.Element[] = originData.map( (oData, idx) => {
        const key = oData.key;
        oData.data.sni = oData.data.sni ? oData.data.sni : oData.data.domain_name;
        const spacer = (idx === 0) ? '' : ' pad-top';
        return (
            <div  key={'ori' + i++} className={'svc-origins' + spacer}>
                <div key={'ori' + i++}>{key}</div>
                <div key={'ori' + i++}>{STRs.domainName}</div><div key={'ori' + i++}>{oData.data.domain_name}</div>
                <div key={'ori' + i++}>{STRs.sni}</div><div key={'ori' + i++}>{oData.data.sni}</div>
                <div key={'ori' + i++}>{STRs.port}</div><div key={'ori' + i++}>{oData.data.port}</div>
            </div>
        )
    })
    if (originData.length === 0) {
        origins = [(<div key="noorigins">{STRs.noOrigin}</div>)]
    }
    
    return (
        <section className="view-routing-rules">
            <div key={'ori' + i++}><span className="section-title">{STRs.origins}</span></div>
            <div key={'ori' + i++} className="view-service-rule">
                <div key={'ori' + i++}>{origins}</div>
            </div>
        </section>
        )
}

const buildPorts = (portData: ServiceData[], cert: CertificateObject): JSX.Element => {
    let keyId = 0;
    const ports: JSX.Element[] = [] as JSX.Element[];
    for (let i = 0, len = portData.length; i < len; i++) {
        const spacer = (i === 0) ? '' : ' pad-top';
        const pData: ServiceData = portData[ i ];
        const pDetail: JSX.Element[] = [] as JSX.Element[];
        const key = pData.key;
        if (pData.list.length === 0) {
            pData.list.push({
                name: '',
                data: STRs.enabled
            })
        }
        for (let j = 0, jlen = pData.list.length; j < jlen; j++) {
            const col1 = (j === 0) ? <div key={'port' + keyId++}>{key}</div> : <div key={'port' + keyId++}> </div>;

            const extraData = (pData.list[j].name === 'cert_id') ? <span>&nbsp;&nbsp;&nbsp;&nbsp;({ADMIN_STRINGS.certInfoCM(cert.commonName)})</span> : <span></span>;
            pDetail.push(col1);
            pDetail.push(<div key={'port' + keyId++}>{pData.list[j].name}</div>);
            pDetail.push(<div key={'port' + keyId++}>{pData.list[j].data} {extraData}</div>);
        }
        ports.push(
            <div key={'port' + keyId++} className={'svc-ports' + spacer }>
            {pDetail}
            </div>
        );
    }
    if (portData.length === 0) {
        ports.push(<div key="noports">{STRs.noPorts}</div>)
    }

    return (
        <section  key={'port' + keyId++} className="view-routing-rules">
            <div key={'port' + keyId++}><span className="section-title">{STRs.ports}</span></div>

            <div key={'port' + keyId++} className="view-service-rule">
                <div key={'port' + keyId++}>{ports}</div>
            </div>
        </section>
    )
}

const buildDnsDirector = (service: ZServiceExt): JSX.Element => {
    const dnsDirector = service.dns_director;
    let i = 0;

    if (dnsDirector === undefined) {
        return <div key="noDnsDir"></div>;
    }
    let directorMarkup: JSX.Element[] = [];
    if (dnsDirector.cdns === undefined) {
        directorMarkup = ([<div key="noDnsDirector">{STRs.noDnsDirector}</div>]);
    } else {
        const domains = dnsDirector.cdns;
        const cdnKeys = Object.keys(domains);
        const cdnTraffic = 100 - dnsDirector.zycada_traffic_percent;

        directorMarkup.push((
            <div  key={'dnsd' + i++} className='svc-dns-dir'>
                <div key={'dnsd' + i++}>{STRs.zycadaTrafficPercentage }</div><div>{dnsDirector.zycada_traffic_percent}</div>
                <div key={'dnsd' + i++}>{STRs.ddTrafficPercentage }</div><div>{cdnTraffic}</div>
            </div>
        ));

        for (let i=0, len=cdnKeys.length; i < len; i++) {
            const key = cdnKeys[i];
            const domain: ZDnsDirectorDomain = domains[key];
            const cdnTraffic = (cdnKeys.length > 1) ? (
                <><div className="pad-left-15" key={'dnsd' + i++}>{STRs.cdnTrafficPercentage}</div>
                  <div key={'dnsd' + i++}>{domain.traffic_percent}</div></>
            ) : <></>;
            directorMarkup.push((
                <div  key={'dnsd' + i++} className={'svc-dns-dir pad-top'}>
                    <div key={'dnsd' + i++}>{STRs.cdnTagName(key)}</div><div key={'dnsd' + i++}></div>
                    <div className="pad-left-15" key={'dnsd' + i++}>{STRs.domainName}</div><div key={'dnsd' + i++}>{domain.cname}</div>
                    {cdnTraffic}
                </div>
            ))
        }

        const autoSwitch = service.dns_director.auto_switch
        if (autoSwitch) {
            directorMarkup.push((
                <div  key={'dnsd' + i++} className='svc-dns-dir'>
                    <div key={'dnsd' + i++} className="pad-top"> </div><div> </div>
                    <div key={'dnsd' + i++}>{STRs.autoSwitchEnabled }</div><div>{MISC_STRINGS.yes}</div>
                    <div key={'dnsd' + i++}>{STRs.useCDNEnabled }</div>
                    <div>{service.dns_director.use_cdns_as_origin ? MISC_STRINGS.yes : MISC_STRINGS.no }</div>
                </div>
            ));
        }
    }

    return (
        <section key={'dnsd' + i++} className="view-routing-rules">
        <div key={'dnsd' + i++}><span className="section-title">{STRs.dnsDirector}</span></div>
            <div key={'dnsd' + i++} className="view-svc-default-display">
                <div key={'dnsd' + i++}>{directorMarkup}</div>
            </div>
        </section>
    );
}

const buildCSM = (service: ZServiceExt, hcAttributeIntervals: NumberMap): JSX.Element => {
    const csm: ZCloudServiceMonitor = service.cloud_service_monitor as ZCloudServiceMonitor;
    let i = 0;

    let markup: JSX.Element = <div key="nocsm"></div>;
    if (csm === undefined) {
        return markup;
    } else {
        const hcEntries: JSX.Element[] = [];
        const buildHCInfo = (hcEntry: ZCSMEntity, label: string): void => {
            if (hcEntry && hcEntry.request_path.length > 0) {
                const interval = hcEntry.check_interval !== undefined ? hcEntry.check_interval : HealthCheckIntervals.default;
                const intervalString  =STRs.intervalSecValue(interval, hcAttributeIntervals[interval])
                hcEntries.push((<div className="svc-csm-data">
                                    <div key={'csm' + i++}>{label}</div>
                                    <div key={'csm' + i++}>{STRs.requestPath}</div><div>{hcEntry.request_path}</div>
                                    <div></div><div>{STRs.checkInterval}</div><div> {intervalString}</div>
                                </div>));
            }
        }

        const hcs = csm.health_checks;
        buildHCInfo( hcs["cdn_check"], STRs.cdnCheck);
        buildHCInfo( hcs["origin_check"], STRs.originCheck);
 
        if (hcEntries.length === 0) {
            markup = <div>{STRs.noHealthChecks}</div>
        } else {
            markup = (
                <>
                    <div>{STRs.healthChecks}</div>
                        {hcEntries}
                </>
            )
        }
    }

    return (
        <section key={'csm' + i++} className="view-routing-rules">
            <div key={'csm' + i++}><span className="section-title">{STRs.cloudServiceMonitor}</span></div>
            <div key={'csm' + i++} className="view-svc-default-display">
                <div key={'csm' + i++}>
                    {markup}
                </div>
            </div>
        </section>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-types
const buildComponentData = (container: object | undefined): ServiceData[] => {
    const data: ServiceData[] = [] as ServiceData[];
    if (! container) {
        container = {};
    }
    const keys = Object.keys(container);

    for (let i = 0, len = keys.length; i < len; i++) {
        const attrib = container[keys[i]];
        data.push({key: keys[i], data: objectContents(attrib), list: objectList(attrib)})
    }
    return data;
};

const buildExtensionData = (rule: RoutingRules): JSX.Element => {
    
    const validFilter = rule.match_filter && rule.match_filter && rule.match_filter.location && 
    rule.match_filter.location.path_regex;
    let markup = <div key="noext"></div>;
    if (validFilter) {
        // a typical regex looks like '\.js$|\.css$';
        const regex = rule.match_filter.location.path_regex;
        let exts = regex.split('|');
        exts = exts.map( (s: string) => {
            s = s.substr(1, s.length - 2);
            return s;
        });
        const extStr = exts.join(', ')
        markup = (
            <>
                <div>{STRs.extension}</div>
                <div>{extStr}</div>
            </>
        )
    }

    return markup;
}

const cacheRuleUIBuilder = (item: CacheAgeItem): JSX.Element => {
    const units = [STRs.seconds, STRs.minutes, STRs.hours, STRs.days];
    const cvtUnit2Idx = { s: 0, m: 1, h: 2, d: 3 };

    const status = item.response_status;
    const age = item.age;
    const unit = age.substr(age.length - 1);
    const value = age.substr(0, age.length - 1);
    const unitIdx = cvtUnit2Idx[unit];

    return (
        <div key={'status' + status} className="cache-rule">
            <div>{STRs.cacheStatusLabel(status)}:</div>
            <div>{value} {units[unitIdx]}</div>
        </div>
    );
}

const buildCacheData = (rule: RoutingRules): JSX.Element => {
    const cacheItems: CacheAgeItem[] | undefined = rule.features.cache.default_age;

    let markup = <><div></div><div></div></>;
    if (cacheItems) {
        const cacheRules: JSX.Element[] = cacheItems.map((cItem: CacheAgeItem) => { 
                                                    return cacheRuleUIBuilder(cItem) });

        markup = (
                <>
                <div>{STRs.cacheAge}</div>
                <div>
                    {cacheRules}
                </div>
            </>
        )
    }

    return markup;
}

const buildWafData = (rule: RoutingRules): JSX.Element => {
    const enabled = rule.features.waf.enabled ? STRs.enabled : STRs.disabled;
    return (
        <>
            <div>{STRs.wafStatus}</div>
            <div>{enabled}</div>
        </>
        )
}

const buildImgMuxData = (rule: RoutingRules): JSX.Element => {
    const enabled = rule.features.image_mux.enabled ? STRs.enabled : STRs.disabled;
    return (
        <>
            <div>{STRs.imgMuxStatus}</div>
            <div>{enabled}</div>
        </>
        )
}

const buildXffData = (rule: RoutingRules): JSX.Element => {
    const xff: XffFeature = rule.features.xff;

    let markup = <div></div>;
    let header = <div></div>;

    if (xff) {
        let enabled = STRs.disabled;
        if (xff.enabled) {
            enabled = STRs.enabled;
            header = <div>{STRs.HeaderValue}: {xff.x_forwarded_for_header_name}</div>
        }

        markup = (
            <>
                <div>{STRs.XffStatus}</div>
                <div>
                    <div>{enabled}</div>
                    {header}
                </div>
            </>
        )
    }

    return markup;
}

const buildRulePanel = (rule: RoutingRules): JSX.Element => {
    let markup = <div></div>;
    const assetType = rule.asset_type;

    if (servicesTypeAssetRule[assetType]) { 
        const dispInfo: DisplayRoutingRules = servicesTypeAssetRule[assetType];

        const title = <div key={assetType}><span className="section-title">{dispInfo.friendlyName}</span></div>;
        const sections: JSX.Element[] = [];
        if (dispInfo.match_filter && rule.match_filter) { sections.push(buildExtensionData(rule)); }
        if (dispInfo.features.cache && rule.features.cache) { sections.push(buildCacheData(rule)); }
        if (dispInfo.features.waf && rule.features.waf) { sections.push(buildWafData(rule)); }
        if (dispInfo.features.image_mux && rule.features.image_mux) { 
                                                sections.push(buildImgMuxData(rule)); 
                                            }
        if (dispInfo.features.xff && rule.features.xff) { sections.push(buildXffData(rule)); }

        if (sections.length > 0) {
            markup = (
                <section className="view-routing-rules">
                    {title}
                    <div className="view-service-rule">
                        {sections}
                    </div>
                </section>
            )
        }
    }
    
    return markup;
}

const buildRoutingRules = (service: ZServiceExt): JSX.Element => {
    const rules: JSX.Element[] = [];

    const rRules = service.rules ? service.rules : [];
    rRules.forEach((rule: RoutingRules) => {
        rules.push(buildRulePanel(rule))
    });

    const features = service.features;
    if (features) {
        const dFeatures: RoutingFeatures = {
            cache: features.cache as CacheFeature,
            waf: features.waf as FeatureDef,
            xff: features.xff as XffFeature,
            image_mux: { enabled: false },
        }

        const dynRoutingRule: RoutingRules = {
            asset_type: 'dynamic',
            name: 'dynamic',
            features: dFeatures,
            match_filter: {} as ZMatchFilter,
        }
        rules.push(buildRulePanel(dynRoutingRule));
    }

    return <>{rules}</>
}
/* eslint-disable react/prop-types */
const ServiceDisplay: React.FunctionComponent<ServiceDisplayProps> = props => {
    // const service: ZService = props.serviceDef.service_info ? props.serviceDef.service_info : props.serviceDef;
    const service = props.service;
    const hideEnv = (props.hideEnvironment !== undefined && props.hideEnvironment);
    const certObj = props.certObj
    const serviceOverview = buildServiceOverview(service, props.service.service_name, hideEnv, certObj);

    const originData: ServiceData[] = buildComponentData(service.origins);
    const origins = buildOrigins(originData);
    const routingRules = buildRoutingRules(service);
    const dnsDirector = buildDnsDirector(service);

    // the port data may come as an array soon. In which case we don't need to
    // call buildComponentData.
    const portsData = buildComponentData(service.ports);
    const ports = buildPorts(portsData, certObj);

    const HCValues = (props.projCsmHCIntervals && props.projCsmHCIntervals["default"] !== undefined) ? props.projCsmHCIntervals : BackupHCValues;
    const cloudSvcMonitor = buildCSM(service, HCValues);

    
    // NOTE:  Not doing old style health checks for now.
    // const healthCheckData = buildComponentData(service.health_checks);
    // let healthChecks = buildHealthChecks(healthCheckData);

    return (
        <div className="service-display">
            {serviceOverview}
            {origins}
            {ports}
            {dnsDirector}
            {cloudSvcMonitor}
            {routingRules}
            <div className='svc-dsp-spacer'></div>
        </div>
    )
}

export default ServiceDisplay;