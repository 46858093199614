import * as React from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { State } from '../../App';
import { buildUrl } from '../../reducers/serverEnvironAccessor';

import ZURLS from '../../shared/urls';
import { ZRestResponse, ZUserMin, FetchError, CreateUserBody } from '../../data/queryResultDefinitions';
import { ZPut } from '../../shared/backend';
import { NotificationStyles } from '../../shared/constants'

import logger from '../../shared/logUtilities';
import ZButton from '../../shared/ZButton';
import { MISC_STRINGS, ACCOUNT_STRINGS as Strs } from '../../shared/strings';

import { ProfileValidationState } from '../../shared/authUtils';
import ChangeProfileCtrl from '../../shared/ChangeProfileCtrl';

export interface UpdateProfileProps extends State {
    closeNotification: () => actionTypes;
    initUpdateProfile: () => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    updateProfileInProgress: (inProgress: boolean, updateSucceeded: boolean) => actionTypes;
    validateProfile: (fname: string, lname: string, email: string, phone: string) => actionTypes;
}

class UpdateAccountProfile extends React.Component<UpdateProfileProps> {
    public componentDidMount(): void {
        logger.log('Change profile');
        this.props.initUpdateProfile();
    }

    render(): JSX.Element {
        const { firstName, lastName, phoneNumber, user } = this.props.authNServerData;
        const profileValidation: ProfileValidationState = this.props.authNServerData.profileValidation;
        const email = (user && user.email) ? user.email : '';
  
        const tfaEnabled = user && user.two_factor_auth && user.two_factor_auth.enabled;
        const tfaEnabledString = tfaEnabled ? Strs.twoFactorAuthEnabled : Strs.twoFactorAuthDisabled;
        const tfaIcon: string[] = tfaEnabled ? ['fas', 'lock'] :  ['fas', 'lock-open'];
        const tfaIconCss = tfaEnabled ? 'tfa-secure' : 'tfa-not-secure';
        
        return (
            <div className="feature-panel">
                <div id="change-profile">
                    <div className="title">{Strs.updateProfileTitle}</div>
                    <div className="col-md-offset-1 col-md-10">
                        <ChangeProfileCtrl firstName={firstName} lastName={lastName} 
                            phoneNumber={phoneNumber} email={email}  readOnlyEmail={true}
                            profileValidation={profileValidation}
                            validateProfile={
                                (fName: string, lstName: string, emailAddr: string, phoneNum: string): void => {
                                    this.props.validateProfile(fName, lstName, email, phoneNum);
                                }}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-offset-1 col-md-10 tfa-status">
                            <FontAwesomeIcon className={tfaIconCss} icon={tfaIcon as IconProp} />&nbsp;
                            {tfaEnabledString}
                        </div>
                    </div>
  
                    <div className="row">
                        <div className="col-md-12">
                            <ZButton disabled={(! profileValidation.validProfile) }  btnType="primary"
                                    onClick={(): void => {this.handleProfileUpdate(); }} btnCls="btn-padding">
                                <span>{Strs.saveProfile}</span>
                            </ZButton>
                        </div>
                    </div>
                </div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
        )
    }
    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }

    private handleProfileUpdate = (): void => {

        const { firstName, lastName, phoneNumber } = this.props.authNServerData;
        const userId = this.props.authNServerData.user ? this.props.authNServerData.user.user_id : '';
        if (userId.length === 0) {
            console.log('handleProfileUpdate: no userid for user.');
            return;
        }
        const email = (this.props.authNServerData.user && this.props.authNServerData.user.email) ? 
                     this.props.authNServerData.user.email : '';

        const profile: CreateUserBody = {
            'first_name': firstName,
            'last_name': lastName,
            email
        };
        
        if (phoneNumber.length > 0) {
            profile.phone = phoneNumber;
        }

        const changeProfileUrl = buildUrl(this.props.authNServerData, ZURLS.serverChangeProfile(userId))
        this.props.updateProfileInProgress(true, false);
        const promise = ZPut(changeProfileUrl, JSON.stringify(profile));

        promise.then( ( profileData: ZRestResponse ) => {
            const profileResults: ZUserMin = profileData as ZUserMin;
            if (profileResults.user_id === userId) {
                this.props.updateProfileInProgress(false, true);
                this.props.showNotification(NotificationStyles.success, Strs.profileUpdatedSuccess);
                this.closeNotification();
            }
        })
        .catch((errStr) => {
            const p = errStr as Promise<string>;
            p.then((err) => {
                // eslint-disable-next-line
                const tempS: any = err;
                const s: FetchError = tempS as FetchError;

                this.props.showNotification(NotificationStyles.danger, s.message);
                this.props.updateProfileInProgress( false, false );
                this.closeNotification();
            })
            // let err = JSON.parse(errStr);
            // if (err.status === 400) {
            //     if (err.message && err.message.length > 0) {
            //         this.props.showNotification(NotificationStyles.success, err.message);
            //     } else {
            //         this.props.showNotification(NotificationStyles.success, Strs.profileUpdatedFailed);
            //     }
            //     this.props.updateProfileInProgress( false, false );
            //     this.closeNotification();
            // } 
        });
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        authNServerData: state.authNServerData
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(UpdateAccountProfile);