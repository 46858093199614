import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ACCOUNT_STRINGS as Strs, MISC_STRINGS, LOGIN_STRINGS } from '../../shared/strings'; // MISC_STRINGS

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { ZRestResponse, ZTfaSecret, FetchError } from '../../data/queryResultDefinitions';

import { TFAState } from '../../reducers/reducerEnums';
import { buildUrl } from '../../reducers/serverEnvironAccessor';

import ZURLS from '../../shared/urls';
import { ZPost } from '../../shared/backend';
import { NotificationStyles } from '../../shared/constants'

import ZButton from '../../shared/ZButton';
import logger from '../../shared/logUtilities';

export interface TFAMgmtProps extends State {
    initTFAMgmt: () => actionTypes,
    setTFAGenInfo: (genInfo: ZTfaSecret) => actionTypes,
    updateEmailPassword: (email: string, password: string) => actionTypes,
    updateTFAToken: (token: string) => actionTypes,
    enableTFASupport: (enabled: boolean) => actionTypes,
    beginTFADeactivate: () => actionTypes,
    closeNotification: () => actionTypes,
    redirectTo: (url: string) => actionTypes,
    logout: () => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes,
}

class TFAMgmt extends React.Component<TFAMgmtProps> {
    componentDidMount(): void {
        this.props.initTFAMgmt();
    }

    componentWillUnmount(): void {
        this.props.initTFAMgmt();
    }

    render(): JSX.Element {
        const { user, tfaState } = this.props.authNServerData;
        const tfaEnabled = user && user.two_factor_auth && user.two_factor_auth.enabled;
        const tfaIcon: string[] = tfaEnabled ? ['fas', 'lock'] :  ['fas', 'lock-open'];
        const tfaIconCss = tfaEnabled ? 'tfa-secure' : 'tfa-not-secure';
        
        const tfaStatusString = tfaEnabled ? Strs.TFACurrentlyEnabled : Strs.TFACurrentlyDisabled;
        let markup = <span></span>;
        if (tfaState === TFAState.unknown) {
            markup = !tfaEnabled ? this.buildEnableTFA(tfaState) : this.buildDisableTFA(tfaState);
        } else {
            switch (tfaState) {
                case TFAState.gotSecret:
                case TFAState.confirming2FA:
                    markup = this.buildEnableTFA(tfaState);
                    break;

                case TFAState.disabling2FA:
                    markup = this.buildDisableTFA(tfaState);
                    break;

                default:
                    logger.alert(`unknown TFAState ${tfaState}`);
            }
        }

        return (
            <div className="feature-panel">
               <div id="tfa-mgmt">
                <div className="title">{Strs.tfaMgmtTitle}</div>
                    <div>
                        <div className="current-tfa-status">
                            <FontAwesomeIcon className={tfaIconCss} icon={tfaIcon as IconProp} />&nbsp;
                            {tfaStatusString}
                        </div>
                        <div className="current-tfa-status">
                        {markup} 
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private buildEnableTFA = (tfaState: TFAState): JSX.Element => {
        let markup: JSX.Element = <span></span>;
        if (tfaState === TFAState.unknown) {
            markup = (
                <div>
                    <div>
                        <ZButton btnType="primary" onClick={(): void => { this.start2FAGen(); }} btnCls="btn-padding">
                            <span>{Strs.TFAEnable}</span>
                        </ZButton>
                    </div>
                </div>
            );
        } else if (tfaState === TFAState.gotSecret) {
            markup = this.buildGenStep2UI();
        } else if (tfaState === TFAState.confirming2FA) {
            markup = this.buildGenStep3UI();
        }

        return markup;
    }

    private buildGenStep2UI = (): JSX.Element => {
        const { tfaGenInfo, tfaToken } = this.props.authNServerData;
        const img = `data:image/png;base64,${tfaGenInfo.secret}`;
        const canComplete = tfaToken.length !== 6;
        const user = this.props.authNServerData.user;
        const email = user ? user.email : '';

        return (
                <div className="tfa-gen-info">
                    <div>{Strs.TFAGenIntro(email)}</div>
                    <div><img alt="2fa secret" src={img}/></div>
                    <div>{Strs.TFAGenSetToken}</div>
                    <div>
                        {MISC_STRINGS.tfaTokenLabel} &nbsp;
                        <input type="text" className="service-friendly-name" value={tfaToken} size={8} maxLength={ 6 }
                            onChange={(e: React.FormEvent<HTMLInputElement>): void => { 
                                            this.props.updateTFAToken(e.currentTarget.value); }}
                        />
                    </div>
                    <div>
                    <ZButton btnType="primary" btnCls="btn-padding" disabled={canComplete}
                             onClick={(): void => {this.completeTfaSetup(); }} >
                        <span>{Strs.TFAActivate}</span>
                    </ZButton>
                </div>
                </div>
            )
    }

    private buildGenStep3UI = (): JSX.Element => {
        const tokens: string[] = this.props.authNServerData.tfaGenInfo.recovery_codes;
        const tokenList = tokens.map((tkn: string, index: number) => {
            return <li key={'token' + index}>{tkn}</li>
        })
        return (
            <div className="tfa-success">
                <div>{Strs.tfaSuccess1}</div>
                <div><strong>{Strs.tfaSuccess1a}</strong></div>
                <div>{Strs.tfaSuccess2}</div>
                <ol>
                    {tokenList}
                </ol>
                <div><strong>{Strs.tfaSuccess3}</strong></div>
                <div>{Strs.tfaSuccess4}</div>
                <div>{Strs.tfaSuccess5}</div>

                <div></div>
                <div>
                    <ZButton btnType="primary" btnCls="btn-padding"
                            onClick={(): void => {
                                this.props.initTFAMgmt(); 
                                this.props.logout();
                                this.props.redirectTo(ZURLS.login); }} >
                        <span>{Strs.gotoLogin}</span>
                    </ZButton>
                </div>
            </div>
        )
    }

    private buildDisableTFA = (tfaState: TFAState): JSX.Element => {
        let markup: JSX.Element = <span></span>;
        if (tfaState === TFAState.unknown) {
            markup = (
                <div>
                    <div>{Strs.TFADisableStart}</div>
                    <div>
                        <ZButton btnType="primary" onClick={(): void => {this.props.beginTFADeactivate(); }} 
                                 btnCls="btn-padding">
                            <span>{Strs.FTADisable}</span>
                        </ZButton>
                    </div>
                </div>
            );
        } else if (tfaState === TFAState.disabling2FA) {
            markup = this.buildStopTFAStep2UI();
        } 

        return markup;
    }

    private buildStopTFAStep2UI = (): JSX.Element => {
        const pwd = this.props.authNServerData.password;

        return (
            <div className="tfa-success">
                <div>To disable Two Factor Authentication, enter you password below:</div>
                <div>{LOGIN_STRINGS.password}&nbsp;
                    <input type="password" className="service-friendly-name" value={pwd}
                    onChange={(e: React.FormEvent<HTMLInputElement>): void => { 
                                    this.props.updateEmailPassword('', e.currentTarget.value); }}
                    />  
                </div>
                <ZButton btnType="primary" onClick={(): void => {this.completeTFADeactivate(); }} 
                            btnCls="btn-padding">
                    <span>{Strs.TFADeactivate}</span>
                </ZButton>
            </div>
        );
    }

    //
    // the following methods make the REST call to enable and disable TFA
    //
    private start2FAGen = (): void => {
        const user = this.props.authNServerData.user;
        const userId = user ? user.user_id : '';
        if (! userId) {
            logger.log('TFAMgmt.start2FAGen: no userid');
            return;
        }

        const tfaGenUrl = buildUrl(this.props.authNServerData, ZURLS.serverTfaGeneration(userId))
        const promise = ZPost(tfaGenUrl, '{}');

        promise.then( ( pwdData: ZRestResponse ) => {
            const genInfo: ZTfaSecret = pwdData as ZTfaSecret;
            this.props.setTFAGenInfo(genInfo);
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });
    }

    private completeTfaSetup = (): void => {
        const user = this.props.authNServerData.user;
        const userId = user ? user.user_id : '';
        if (! userId) {
            logger.log('TFAMgmt.start2FAGen: no userid');
            return;
        }

        const tfaGenUrl = buildUrl(this.props.authNServerData, ZURLS.serverTfaActivate(userId))
        const promise = ZPost(tfaGenUrl, JSON.stringify({ 'otp_token': this.props.authNServerData.tfaToken}));

        promise.then( (  ) => {
            this.props.enableTFASupport(true);
        })
        .catch((errStr) => {
            this.props.enableTFASupport(false);
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });
    }

    private completeTFADeactivate = (): void => {
        const { user, password } = this.props.authNServerData;

        const userId = user ? user.user_id : '';
        if (! userId) {
            logger.log('TFAMgmt.start2FAGen: no userid');
            return;
        }

        const email = user !== undefined ? user.email : '';

        const tfaDeactivateUrl = buildUrl(this.props.authNServerData, ZURLS.serverTfaDeactivate(userId))
        const promise = ZPost(tfaDeactivateUrl, JSON.stringify({ email, password}));

        promise.then( (  ) => {
            this.props.enableTFASupport(false);
        })
        .catch((errStr) => {
            this.props.initTFAMgmt();
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.props.showNotification(NotificationStyles.danger, err.message);
                this.closeNotification();
            })
        });
    }

    private closeNotification = (): void => {
        setTimeout(() => { this.props.closeNotification(); }, 5000);
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stateToProps = (state: State) => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        adminState: state.adminUIState,
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(TFAMgmt);