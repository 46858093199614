import { MetricsUIDefinitions, StringMap } from '../../data/metricsAndOptionsDefs';
import { AnalyticsMetricData, SvrMetricDataPoint, SvrMetricTimeData, SvrMetricTagEntry } from 
            '../../data/queryResultDefinitions';
// import { ANALYTICS_STRINGS, MISC_STRINGS as STRs } from '../../shared/strings';
// import { DataFormatter } from './DataFormatter';

// const fmtr = new DataFormatter();

interface PieChartEntry {
    name: string,
    value: number,
    label: { 
        color: string
    },
    itemStyle: {
        color: string
    }
}
interface PieChartData {
    // legendData: string[],
    seriesData: PieChartEntry[],
    selected: boolean[]
}

const processPieData = (svrGraphData: AnalyticsMetricData, metricUIData: MetricsUIDefinitions): PieChartData => {
    const pieData: PieChartData =  {
        // legendData: [],
        seriesData: [],
        selected: []
    }

    const totalUpSeries = (data: SvrMetricDataPoint[]): number => {
        let total = 0;
        data.forEach(item => {
            const value = Number.isNaN(item.value) ? 0 : item.value;
            total = total + value;
        });

        return Number.parseFloat(total.toFixed(2));
    }

    const series: SvrMetricTimeData[] = svrGraphData.data;
    const { tag, nameReplacements } = metricUIData;
    const altNames: StringMap = (nameReplacements !== undefined) ? nameReplacements : {};
    const pieColor = ['#53D0DA', '#DAC453', '#E76363', '#2D3690', '#12C18D',
                  '#F976ED', '#1C78FE', '#ACD194', '#EEBFB1', '#EEBFB1'];

    for (let i = 0, len = series.length; i < len; i++) {
        const dataTotal = totalUpSeries(series[i].time_values);
        const tagMap = series[i].tags;
        const tags: SvrMetricTagEntry = tagMap !== null ? tagMap as SvrMetricTagEntry : {} as SvrMetricTagEntry;
        
        const name = (tag && tags[tag]) ? tags[tag] : tag;
        const entryName = altNames[name] ? altNames[name] : name;
        
        const sliceColor = pieColor[i % 10];
        pieData.seriesData.push({ name: entryName, value: dataTotal, label: {color: '#FFF'}, 
                                  itemStyle: { color: sliceColor } });
        // pieData.legendData.push(entryName);
        pieData.selected.push(true);
    }

    return pieData;
}

const buildEChartPieOptions = (pieData: PieChartData, metricUIData: MetricsUIDefinitions) => {
    const pieChartOptionsTemplate = {
        tooltip : {
            trigger: 'item',
            backgroundColor: 'rgb(50,50,50,.7)',
            borderColor: '#333',
            padding: 5,
            textStyle: {
                color: '#fff' 
            },
            formatter: '{b}: {c} - {d}%'
        },
        // legend: {
        //     type: 'scroll',
        //     orient: 'vertical',
        //     right: 10,
        //     top: 20,
        //     bottom: 20,
        //     data: ['x'],
        //     textStyle: {
        //         color: '#fff'
        //     },
        //     selected: [false]
        // },
        series : [
            {
                name: '',
                type: 'pie',
                radius : '80%',
                center: ['50%', '50%'],
                data: [{name: 'foo', value: 3}],
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(64, 64, 64, 0.5)'
                    }
                }
            }
        ]
    };

    const options = Object.assign({}, pieChartOptionsTemplate);
    // options.legend.data = pieData.legendData;
    // options.legend.selected = pieData.selected;
    options.series[0].data = pieData.seriesData;

    const metricKey = metricUIData.key;
    const altNames = metricUIData.nameReplacements ? metricUIData.nameReplacements : {};
    const metricName: string  = altNames[metricKey] ? altNames[metricKey] : metricKey;
    options.series[0].name = metricName;

    return options;
}

// eslint-disable-next-line 
export function pieChartOptions(metricUIData: MetricsUIDefinitions, periodOption: string, svrGraphData: AnalyticsMetricData): any {
    const pieData: PieChartData = processPieData(svrGraphData, metricUIData);

    const options = buildEChartPieOptions(pieData, metricUIData);

    return options;
}
