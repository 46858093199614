import * as React from 'react';
import { State } from '../App';

import { SysFeatureEnums } from '../reducers/reducerEnums';
import AnalyticsCharting from './analyticsComps/AnalyticsCharting';
import Logs from './analyticsComps/Logs';

class Analytics extends React.Component<State> {
    render(): JSX.Element{
        const systemNavProjectState = this.props.systemNavProjectState;
        let page: JSX.Element = <span></span>;
        if (systemNavProjectState.currentFeature === SysFeatureEnums.logsAnl) { 
            page = <Logs  {...this.props} /> 
        } else {
            page = <AnalyticsCharting  {...this.props} />;
        }

        return page;
    }
}

export default Analytics;