import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import authNServerData from './authentication';
import systemNavProjectState from './systemState';
import logsUIState from './logState';
import serviceUIState from './serviceState';
import cacheUIState from './cacheState';
import adminUIState from './adminState';
import reportUIState from './reportState';
import analyticsUIState from './analyticsState';
import csaLiteUIState from './CSALite';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ApplicationState {}

const rootReducer = combineReducers<ApplicationState>({ authNServerData, systemNavProjectState, logsUIState, 
                                                        reportUIState, serviceUIState, cacheUIState, adminUIState, 
                                                        analyticsUIState, routerReducer, csaLiteUIState });

export default rootReducer;