import './App.scss';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actionCreators from './actions/actionCreators';

import Login from './component/Login';
import Main from './component/Main';
import Reset from './component/Reset';
import PwdExpired from './component/PwdExpired';

import { State as AuthNServerData } from './reducers/authentication';
import { State as systemNavProjectState } from './reducers/systemState';
import { State as LogStateData } from './reducers/logState' ;
import { State as ServiceStateData } from './reducers/serviceState';
import { State as CacheStateData } from './reducers/cacheState';
import { State as AdminStateData } from './reducers/adminState';
import { State as ReportStateData } from './reducers/reportState';
import { State as AnalyticsStateData } from './reducers/analyticsState';
import { State as CSALiteStateData } from './reducers/CSALite';

export interface State {
    authNServerData: AuthNServerData;
    systemNavProjectState: systemNavProjectState;
    logsUIState: LogStateData;
    serviceUIState: ServiceStateData;
    cacheUIState: CacheStateData;
    adminUIState: AdminStateData;
    reportUIState: ReportStateData;
    analyticsUIState: AnalyticsStateData;
    csaLiteUIState: CSALiteStateData;
}

function mapStateToProps(state: State): State {
    return {
        adminUIState: state.adminUIState,
        authNServerData: state.authNServerData,
        cacheUIState: state.cacheUIState,
        logsUIState: state.logsUIState,
        serviceUIState: state.serviceUIState,
        systemNavProjectState: state.systemNavProjectState,
        reportUIState: state.reportUIState,
        analyticsUIState: state.analyticsUIState,
        csaLiteUIState: state.csaLiteUIState
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

const zApp = connect(mapStateToProps, mapDispatchToProps)(Main);
export const lApp = connect(mapStateToProps, mapDispatchToProps)(Login);
export const lReset = connect(mapStateToProps, mapDispatchToProps)(Reset);
export const pwdExpired = connect(mapStateToProps, mapDispatchToProps)(PwdExpired);

export default zApp;
