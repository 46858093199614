import React from 'react';
import zurls from '../shared/urls';
import { Redirect } from 'react-router-dom'

import { actionTypes } from '../actions/actionCreatorTypes';

interface UnknownProps {
    redirectTo: (url: string) => actionTypes;
}

class UnknownAction extends React.Component <UnknownProps> {
    // public componentDidUpdate(): void {
    //     this.props.redirectTo( zurls.login );
    // }
  
    public render(): JSX.Element { 
        return <Redirect to={zurls.login} push={true}/>; 
    }
}

export default UnknownAction;
