import * as React from 'react';
import moment from 'moment';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { State } from '../../App';
import LogHeaderFilters from './LogHeaderFilters'
import { HeaderFilterItem, LogOptionsFieldErrorState, ResponseCodeData } from '../../reducers/logState';
import { HeaderFilterOpsIndexEnum }  from '../../reducers/reducerEnums';
import { reqMethods } from '../../data/staticData';

import { LOG_STRINGS as STRs, MISC_STRINGS, ERROR_STRINGS as ErrStrings } from '../../shared/strings';
import { checkIPAddr } from '../../shared/utilities';
import logger from '../../shared/logUtilities';
// import AbsTimeControl from '../../shared/AbsTimeControl';
import TimeControlTab from '../../shared/TimeControl';
import FilterItem from '../../shared/FilterItem';
import ZButton from '../../shared/ZButton';
import ZDropDown from '../../shared/ZDropDown';
import { MAX_LOG_TIME_PERIOD_DAYS, NotificationStyles } from '../../shared/constants';

interface LogOptionsProps extends State {
    applyHandler(): boolean,
    setLogsOptionData: (clientIP: string, originIP: string, assetURL: string, responseCode: string, 
                        requestMethodIdx: number) => actionTypes,
    setLogsFromToTime: (from: moment.Moment, to: moment.Moment, invalidTime: boolean, relTimeIdx: number) => actionTypes,
    setLogOptionsFieldState: (errorState: LogOptionsFieldErrorState) => actionTypes,
    setQueryTimeFormat: (relativeTime: boolean, component: string) => actionTypes;
    setVisibilityLogFilterModal: (showModal: boolean) => actionTypes,
    setHeaderTypeIndex: (index: number) => actionTypes,
    setHeaderFilterData: (filterOps: HeaderFilterOpsIndexEnum, filterName: string, filterValue: string) => actionTypes,
    addHeaderFilter: (isRequest: boolean, filterOps: HeaderFilterOpsIndexEnum, filterName: string, 
                      filterValue: string) => actionTypes,
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
    deleteHeaderFilter: (index: number) => actionTypes,
    toggleHeaderFilter: (index: number) => actionTypes,
}

const noErrors: LogOptionsFieldErrorState = {
    clientIP: false,
    originIP: false,
    responseCode: false,
    assetURL: false,
    dateRange: false,
    badFrom: false,
};

interface SimpleEvent {
    currentTarget: {
        id: string,
        value: string,
    }
}

class LogOptions extends React.Component<LogOptionsProps> {
    constructor(props: LogOptionsProps) {
        super(props);
        this.toChangeHandler = this.toChangeHandler.bind(this);
        this.fromChangeHandler = this.fromChangeHandler.bind(this);
        this.dataChanged = this.dataChanged.bind(this);
        this.addHeadersModal = this.addHeadersModal.bind(this);
    }

    render() {
        const {logsUIState} = this.props;
        const tc = <TimeControlTab
            showingRelativeTime={logsUIState.showRelativeTime}
            relativeTimeIdx={logsUIState.periodIdx}
            from={logsUIState.from}
            to={logsUIState.to}
            handleTabChange={(selectedTab: number) => { this.selectedTabHandler(selectedTab); }}
            handleRelativeSelectChange={(index: number, optionName: string) => {this.handleOptionSelect(index as number, optionName as string)}}
            handleFromChange={(from) => {this.fromChangeHandler(from as moment.Moment)}}
            handleToChange={(to) => {this.toChangeHandler(to as moment.Moment)}}
        />

        let clientIp, respCode;
        const fieldErrorState = logsUIState.fieldErrorState;
        const clientIpCSS = fieldErrorState.clientIP ? 'field-error' : '';
        // let originIpCSS = fieldErrorState.originIP ? 'field-error' : '';
        const respCodeCSS = fieldErrorState.responseCode ? 'field-error' : '';
        const assetURLCSS = fieldErrorState.assetURL ? 'field-error' : '';

        const filterList: JSX.Element = this.buildFilters(this.props.logsUIState.logFilters)
        
        let noteString = <div></div>;
        if (logsUIState.logPagingData.moreThanMax) {
            const totalItems = logsUIState.logPagingData.qryTotalItems.toLocaleString();
            noteString = (<div className="log-note">
                <strong>{STRs.noteLabel}</strong> { STRs.noteTruncationMsg(STRs.noteMaxRecs, totalItems) }
                </div>);
        }
        const method = ZDropDown(
            reqMethods,
            logsUIState.requestMethodIdx, 
            'reqMethdod',
            (index, optionName) => {
                const e: SimpleEvent = { currentTarget: { id: optionName as string, value: index.toString()} };
                this.dataChanged(e)},
            {label: "", optionName: 'requestMethod'}
            );

        const applyBtnText = (logsUIState.areLogOptionsDirty) ? MISC_STRINGS.updateBtn : MISC_STRINGS.refresh;
        // <label>{STRs.originIP}&nbsp;&nbsp;
        // <input id="originIP" type="text" className={originIpCSS} autoComplete="off" 
        //    value={originIp} size={18} max-length="15" placeholder={STRs.exampleIP}
        //    onKeyUp={ (e: React.KeyboardEvent<HTMLInputElement>) => { this.inputCheck(e) }}
        //    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {this.dataChanged(e)}} />&nbsp;&nbsp;
        // </label>

        return ( 
        <div className="log-options">
            <div className="block1">
                {tc}
                <div className="applyItems">
                    <ZButton onClick={this.handleApplyBtn}
                                btnCls="apply" btnType="primary">
                        {applyBtnText}
                    </ZButton>
                </div>
            </div>
            <div className="hrule"></div>
            <div className="block2 zinput">
                <label>Method&nbsp;&nbsp;
                    {method}
                </label>
                <label>{STRs.assetURL}&nbsp;&nbsp;
                  <input id="assetURL" type="text" className={assetURLCSS} autoComplete="off" 
                    value={respCode}  size={30} maxLength={2083} placeholder={STRs.exampleURL} 
                    onKeyUp={ (e: React.KeyboardEvent<HTMLInputElement>) => { this.inputCheck(e) }}
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {this.dataChanged(e)}} />&nbsp;&nbsp;
                </label>

                <label>{STRs.clientIP}&nbsp;&nbsp;
                    <input id="clientIP" type="text" className={clientIpCSS} autoComplete="off" 
                       value={clientIp} size={18} maxLength={15} placeholder={STRs.exampleIP} 
                       onFocus={() => {this.onFocusChanged()}}
                        onBlur={() => {this.onFocusChanged()}}
                        onKeyUp={ (e: React.KeyboardEvent<HTMLInputElement>) => { this.inputCheck(e) }}
                       onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {this.dataChanged(e)}} />&nbsp;&nbsp;
                </label>

                <label>{STRs.responseCode}&nbsp;
                <input id="respCode" type="text" className={respCodeCSS} autoComplete="off" 
                   value={respCode} size={18} maxLength={15} placeholder={STRs.exampleRespCode} 
                   onFocus={() => {this.onFocusChanged()}}
                   onBlur={() => {this.onFocusChanged()}}
                   onKeyUp={ (e: React.KeyboardEvent<HTMLInputElement>) => { this.inputCheck(e) }}
                   onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {this.dataChanged(e)}} />&nbsp;&nbsp;
                </label>

                <div onClick={() => this.addHeadersModal()}>{STRs.addHeaderFilter}</div>

            </div>

            <div>{filterList}</div>
            {noteString}

            <LogHeaderFilters 
                onHide={() => {this.onCloseModal(); }} 
                show={logsUIState.showModal} 
                headerTypeIdx={logsUIState.headerTypeIdx} 
                filterOp={logsUIState.filterOps} 
                filterName={logsUIState.filterName} 
                filterValue={logsUIState.filterValue}
                filterList={filterList}
                onHeaderTypeIndexChange={(index: number) => {this.filterTypeChanged(index)}}
                onFilterDataChange={(filterName: string, filterValue: string, op: HeaderFilterOpsIndexEnum) => {
                                      this.filterDataChanged(filterName, filterValue, op)}}
                onAddFilter={(isRequest: boolean, filterOp: HeaderFilterOpsIndexEnum, filterName: string, 
                              filterValue: string) => {this.onAddFilter(isRequest, filterOp, filterName, filterValue )}}
                onDeleteFilter={(index: number) => { this.onDeleteFilter(index)}}
                onToggleFilter={(index: number) => {this.onToggleFilter(index)}}
            />
        </div>);
    
    }

    private onToggleFilter(index: number) {
        // console.log(`toggle filter: ${index}`);
        this.props.toggleHeaderFilter(index);
    }

    private onDeleteFilter(index: number) {
        // console.log(`delete filter: ${index}`);
        this.props.deleteHeaderFilter(index);
    }

    inputCheck(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
          if (this.props.logsUIState.areLogOptionsDirty) {
            this.handleApplyBtn();
          }
        }
      }

    private filterTypeChanged(index: number) {
        this.props.setHeaderTypeIndex(index)
    }

    private filterDataChanged(filterName: string, filterValue: string, op: HeaderFilterOpsIndexEnum) {
        this.props.setHeaderFilterData(op, filterName, filterValue);
    }

    private onAddFilter(isRequest: boolean, filterOp: HeaderFilterOpsIndexEnum, filterName: string, 
                        filterValue: string) {
        this.props.addHeaderFilter(isRequest, filterOp, filterName, filterValue);
    }

    private onFocusChanged = (): void => {
        const errorState: LogOptionsFieldErrorState  = this.validateFields();
        this.props.setLogOptionsFieldState(errorState);
    }

    private dataChanged(e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | SimpleEvent) {
        const id = e.currentTarget.id;
        const value = e.currentTarget.value;
        let {clientIP, originIP, responseCode, assetURL, requestMethodIdx} = this.props.logsUIState;

        switch (id) {
            case 'clientIP':
                clientIP = value;
                break;
            case 'originIP':
                originIP = value;
                break;
            case 'respCode':
                responseCode = value;
                break;
            case 'assetURL':
                assetURL = value;
                break;
            case 'requestMethod':
                requestMethodIdx = parseInt(value)
                break;
            
            default:
                break;
        }
        this.props.setLogsOptionData(clientIP, originIP, assetURL, responseCode, requestMethodIdx);
    }

    private addHeadersModal = () => {
        this.props.setVisibilityLogFilterModal(true);
    };

    private selectedTabHandler = (selectedTab: number): void => { 
        this.props.setQueryTimeFormat( selectedTab === 0, 'LOGS' );
    }

    private handleOptionSelect = (index: number, optionName: string): void => {
        const { to, from } = this.props.logsUIState;
        this.props.setLogsFromToTime(to, from, true, index);
        logger.log(`logs.handleOptionSelect: index: ${index}; optionName: ${optionName}`)
    }

    private fromChangeHandler(from: moment.Moment | string) {
        const { to, periodIdx } = this.props.logsUIState;

        this.props.setLogsFromToTime(from as moment.Moment, to, false, periodIdx)
    }

    private toChangeHandler(to: moment.Moment | string) {
        const { from, periodIdx }  = this.props.logsUIState;

        this.props.setLogsFromToTime(from, to as moment.Moment, false, periodIdx)
    }

    private validateFields = (): LogOptionsFieldErrorState => {
        const logState = this.props.logsUIState;
        let errorState: LogOptionsFieldErrorState  = {...noErrors}

        logState.responseCodeData = this.isResponseCodeValid(errorState, logState.responseCode);
        errorState = logState.responseCodeData.errorState;
        errorState = this.isRequestUriValid(errorState);            // currently we can never have a URI error
        errorState = this.isClientIPValid(errorState, logState.clientIP);
        // errorState = this.isOriginIPValid(errorState, logState.originIP);   // no longer filter on this
        errorState = this.isStartDateValid(errorState, logState.from, logState.to);

        return errorState;
    }

    private handleApplyBtn = () => {
        let error = false;
        const errorState: LogOptionsFieldErrorState  = this.validateFields();
        let displayMessage = '';

        const messages = {
            clientIP: ErrStrings.clientIPError,
            originIP: '',
            responseCode: ErrStrings.responseCodeError,
            assetURL: '',
            dateRange: ErrStrings.absTimeFromWithin7ofTo,
            badFrom: ErrStrings.absTimeFromAfterTo
        }

        const keys = Object.keys(errorState);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (errorState[keys[i]]) {
                error = true;
                displayMessage = messages[keys[i]];
            }
        }

        if (! error ) {
            (this.props.applyHandler)();
        } else {
            this.props.setLogOptionsFieldState(errorState);
            this.props.showNotification(NotificationStyles.danger, displayMessage);
            setTimeout(() => this.props.closeNotification(), 5000);
        }
    };

    isStartDateValid = (errorState: LogOptionsFieldErrorState, from: moment.Moment, to: moment.Moment): 
                            LogOptionsFieldErrorState => {
        errorState.dateRange = false;
        if (from.isAfter(to) ) {
            errorState.badFrom = true;
        } else if (from.isBefore(moment(to).subtract(MAX_LOG_TIME_PERIOD_DAYS, 'days'))) {
            errorState.dateRange = true
        }

        return errorState;
    }
 
    
    isRequestUriValid (errorState: LogOptionsFieldErrorState): LogOptionsFieldErrorState {
        // Note: on 11/28/2018 - Roy told me we should not validate URLs.
        errorState.assetURL = false;

        return errorState;
    }
    
    private isClientIPValid(errorState: LogOptionsFieldErrorState, clientIP: string): LogOptionsFieldErrorState {
        errorState.clientIP = false;
        if (clientIP.length > 0) {
            errorState.clientIP = !checkIPAddr(clientIP);
        }
        return errorState;
    }
    
    // private isOriginIPValid(errorState: LogOptionsFieldErrorState, originIP: string): LogOptionsFieldErrorState {
    //     errorState.originIP = false;
    
    //     if (originIP.length > 0) {
    //         errorState.originIP = !checkIPAddr(originIP);
    //     }

    //     return errorState;
    // }
    
    private isResponseCodeValid = (errorState: LogOptionsFieldErrorState, responseCode: string): ResponseCodeData => {
        const rcodeData: ResponseCodeData = {
            type: '',
            codes: [],
            errorState: {...errorState}
        }
        function validCodes(codeList: string[]): boolean {
            for (let i = 0, len = codeList.length; i < len;  i++) {
                const num = Number(codeList[i]);
                if (Number.isNaN(num) || num < 100 || num >= 600) {
                    return false;
                }
            }
            return true;
        }
    
        let valid = true;
        const code: string = responseCode;
        if (responseCode.length > 0) {
            if (code.indexOf('-') !== -1) {
                const codePieces: string[] = code.split('-');
                if (codePieces.length !== 2) {
                    valid = false;
                } else {
                    valid = validCodes(codePieces);
                    if (valid) {
                        if (Number.parseInt(codePieces[0], 10) >= Number.parseInt(codePieces[1], 10)) {
                            valid = false
                        } else {
                            rcodeData.type = 'range';
                            rcodeData.codes = codePieces
                        }
                    }
                }
                rcodeData.errorState.responseCode = valid;
            } else if (code.indexOf(',') !== -1) {
                const codePieces = code.split(',');
                valid = validCodes(codePieces);
                rcodeData.type = 'array';
                rcodeData.type = 'array';
                rcodeData.codes =  codePieces;
                rcodeData.errorState.responseCode = valid;

            } else {
                const codePieces = [code];
                valid = validCodes(codePieces);
                if (valid) {
                    rcodeData.type = 'single';
                    rcodeData.codes = codePieces;
                }
                rcodeData.errorState.responseCode = valid;
            }
        } else {
            rcodeData.type = 'blank';
            rcodeData.codes =  [];
            rcodeData.errorState.responseCode = true;
        }
    
        rcodeData.errorState.responseCode = !valid;
        return rcodeData;
    }

    onCloseModal = () => {
        this.props.setVisibilityLogFilterModal(false);
    }

  buildFilters(filterList: HeaderFilterItem[] ): JSX.Element {
    const reqFilters: JSX.Element[] = [];
    const respFilters: JSX.Element[] = [];
    
    const buildFilterName = (item: HeaderFilterItem): string => {
      const opNameArray = [ STRs.noneFltLabel, STRs.isFltLabel, STRs.isNotFltLabel, 
        STRs.existsFltLabel, STRs.doesNotExistFltLabel]
  
      let filterName = (item.isRequest ? STRs.request : STRs.response) + item.filterName;
      filterName = filterName + opNameArray[item.filterOp + 1];
      if (item.filterOp === HeaderFilterOpsIndexEnum.opIs || item.filterOp === HeaderFilterOpsIndexEnum.opIsNot) {
        filterName = `${filterName}'${item.filterValue}'`;
      }
  
      return filterName;
    }

    for (let i = 0, len = filterList.length; i < len; i++) {
      const item = filterList[i];

      const filterItem = FilterItem(
        buildFilterName(item), 
        item.isEnabled,
        i,
        (index: number) => {this.onDeleteFilter(index)},
        (index: number) => {this.onToggleFilter(index)});
        
      if ( item.isRequest ) {
          reqFilters.push( filterItem );
        } else {
          respFilters.push(filterItem);
        }
    }

    let filters = <span></span>;
    if (filterList.length > 0) {
      filters = (
        <div className="filters-list filter-indent">
          {STRs.filters}
          <div className="filter-indent">
            <div>{reqFilters}</div>
            <div>{respFilters}</div>
          </div>
        </div>
      )
    }

    return filters;
  }
}

const stateToProps = (state: State) => {
    return {
        // uiState: state.uiState,
        logsUIState: state.logsUIState,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(LogOptions);
