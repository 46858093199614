/* eslint-disable react/prop-types */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import logger from './logUtilities';

// import logger from './logUtilities';

export enum inputBoxStyle {
    'SingleLine' = 'SingleLine',
    'LabelOnTop' = 'LabelOnTop',
}
export interface InputTextProps {
    label: string;
    value: string;
    ikey: string;
    onTextChange: (s: string) => void;
    disabled?: boolean;
    maxLength?: number;
    extraCSS?: string;
    inputType?: string;
    layoutStyle?: inputBoxStyle;
    placeholderText?: string;
    validationMsg?: string;
}

// tslint:disable-next-line: variable-name
const ZInputTextBox: React.FunctionComponent<InputTextProps> = props => {

    const pdisabled = !!props.disabled;
    const maxLength = props.maxLength && props.maxLength > 0 ? props.maxLength : 0;
    const placeholder = props.placeholderText ? props.placeholderText : '';
    const validationMsg = props.validationMsg ? props.validationMsg : '';
    const extraCSS = props.extraCSS ? props.extraCSS : '';
    const layoutStyle = props.layoutStyle ? props.layoutStyle : inputBoxStyle.LabelOnTop;
    const inputType = props.inputType && props.inputType.length > 0 ? props.inputType : 'text';

    const errWithOverLay = (msg: string ): JSX.Element => {
        const ttip = <Tooltip id={`tooltip-${props.ikey + 'vmsg'}`}><span>{msg}</span></Tooltip>;
        return (
            <OverlayTrigger  key={props.ikey + 'vmg1'} placement="right" overlay={ ttip }>
                <Button variant="link">
                     <FontAwesomeIcon className="validation-instruct-icon" size="xs" icon={['fas', 'exclamation-triangle']} />
                </Button>
            </OverlayTrigger>
        )
    }

    const showValidationRules = (msg: string): JSX.Element => {
        let rules = <span></span>;
        if (msg.length > 0) {
            const overlay = errWithOverLay(msg);
            rules = (<span className="validation-instruct-icon">{overlay}</span>)
        }
        return rules;
    }

    let markup = <span></span>;
    const zinput = <input type={inputType} disabled={pdisabled} maxLength={maxLength}
                          className={'form-control input-text-box'} placeholder={placeholder} value={props.value} 
                          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                                        (props.onTextChange)(e.currentTarget.value);
                                    }} />

    if (layoutStyle === inputBoxStyle.LabelOnTop) {
        markup = (
            <div className={'ztext-input-box ' + extraCSS}>
                <div>
                    <div className={''}>{props.label}{showValidationRules(validationMsg)}</div>
                </div>
                <div className={'validate'}>
                    <div className="input-group input-group-lg">
                        {zinput}
                    </div>
                </div>
            </div>
        )
    } else if (layoutStyle === inputBoxStyle.SingleLine) {
        const validation =   <div className={''}>{showValidationRules(validationMsg)}</div>
        markup = (
            <div>
                <div className={'ztext-input-box '}>

                    <div className={'validate sinput-grid'}>
                        <div className={'input-group input-group-lg ' + extraCSS}>
                            {zinput}
                        </div>
                        {validation}
                        <div></div>
                    </div>
                </div>
            </div> 
        )
    } else {
        logger.alert('unknown layout style');
    }

    return markup;
}

export default ZInputTextBox;