import { MetricsUIDefinitions, StringMap } from '../../data/metricsAndOptionsDefs';
import { AnalyticsMetricData, SvrMetricDataPoint, SvrMetricTimeData } from 
            '../../data/queryResultDefinitions';
import { ANALYTICS_STRINGS, MISC_STRINGS as STRs } from '../../shared/strings';
import { formatterEnum, DataFormatter } from './DataFormatter';
import logger from '../../shared/logUtilities';
const fmtr = new DataFormatter();

export interface EChartSeries {
    data: EChartPoint[];    // [['a', 120], ['b', 132]],
    name: string;                 // 't1',
    type: string;                 // 'line',
    lineStyle?: {
        width: number
    };
    areaStyle?: {
        color?: string
        opacity?: number,
    };
}

type PointValue = number | string;

// interface NumberStringTuple extends Array<string | number | undefined> { 0: number; 1: number | undefined }
interface NumberStringTuple extends Array<string | number | null> { 0: string; 1: number | null }

export interface EChartPoint {
    name: string,
    value: NumberStringTuple
    format: formatterEnum,
}

export interface TSSeriesData {
    idx: number,
    seriesName: string,
    lineData: EChartPoint[];
    metricKey: string;
}

export interface EChartTSToolTipItem {
    // axisDim: string,         // 'x'
    // axisId: string,          // 'series00'
    // axisIndex: number,       // 0
    // axisType: string,        // 'xAxis.time'
    axisValue:  number,         // 1533600300000
    // axisValueLabel: string,  // '17:05↵08-06'
    backgroundColor: string,
    borderColor: string,
    color: string,              // '#53D0DA'
    // componentSubType: string,// 'line'
    // componentType: string,   // 'series'
    data: EChartPoint,          // {name: 'Mon Aug 06 2018 17:05:00 GMT-0700 (Pacific Daylight Time)', value: Array(2)}
    // dataIndex: number,       // 3
    // dataType: string,        // undefined
    marker: string,             // '<span style='display:inline-block;margin-right:5px;border-radius:10px;
                                //  width:10px;height:10px;background-color:#53D0DA;'></span>'
    name: string,               // 'Mon Aug 06 2018 17:05:00 GMT-0700 (Pacific Daylight Time)'
    seriesId: string,           // 'http2xx_pm0'
    seriesIndex: number,        // 0
    seriesName: string,         // '199.38.181.206'
    // seriesType: string,         // 'line'
}

// eslint-disable-next-line 
export function timeSeriesOptions(metricUIData: MetricsUIDefinitions, periodOption: string, svrGraphData: AnalyticsMetricData): any {
    // const svrGraphData = this.props.graphData as AnalyticsMetricData;
    const seriesArray: TSSeriesData[] = [];
    const format: formatterEnum = pickfmtr( metricUIData.unit );

    const findName = (name: string): string => { 
        let newName = ''
        if (metricUIData.nameReplacements && metricUIData.nameReplacements[name]) {
            newName = metricUIData.nameReplacements[name];
        }
        return newName;
    }
    // once through this loop for each line on the graph
    for (let i = 0, len = svrGraphData.data.length; i < len; i++) {
        const sdata: SvrMetricTimeData = svrGraphData.data[i];
        if (sdata) {
            const graphLine: EChartPoint[] | undefined = processGraphData(sdata, format);
            let names = '';
            let metricKey = '';
            let name = '';
            if (graphLine) {
                if (sdata.tags && sdata.tags.metric_name) {
                    name = metricKey = sdata.tags.metric_name;
                    name = findName(name);
                    names += name;
                }
                if (names.length === 0) {
                    names = findName(metricUIData.key);
                }
                if (sdata.tags && sdata.tags.origin_ip) {
                    names += ((names.length > 0) ? ' - ' : '') + sdata.tags.origin_ip;
                }

                seriesArray.push( { idx: i, lineData: graphLine, seriesName: names, metricKey: metricKey } );
            }
        }
    }

    const options = buildEChartTSOptions( svrGraphData, seriesArray, format, periodOption, metricUIData.colors);

    return options;
}

function processGraphData(graphData: SvrMetricTimeData, format: formatterEnum): EChartPoint[] | undefined {
    if (graphData.time_values && graphData.time_values.length === 0) {
        return undefined;
    }

    const chartData: EChartPoint[] = [];

    for (let i = 0, len = graphData.time_values.length; i < len; i++) {
        const point: SvrMetricDataPoint = graphData.time_values[i];

        const date = new Date(point.epochmillis);
        let time: string = date.toString();
        
        time = fmtr.formatDate(date, true) + 'T' + fmtr.formatTime(date, true, false, true);
        let yValue: number | null;

        if (point.value !== null) {
            yValue = Math.round(point.value * 1e2) / 1e2;
        } else {
            yValue = null;
        }
        const value: NumberStringTuple = [time, yValue]
        chartData.push({name: date.toString(), value, format });
        }

    return chartData;
}

function buildEChartTSOptions(svrGrphData: AnalyticsMetricData,  
                              metricData: TSSeriesData[],
                              format: formatterEnum,
                              periodOption: string,
                              colors: StringMap | undefined) {
    
    // These next 2 lines are just to define options template so we can replace
    // them later
    const sdata: EChartPoint = {name: '', format, value: ['', 1]};
    const sample: EChartSeries = { data: [sdata], name: 't1', type: 'line' };

    const optionsTemplate = {
        animation: false, 
        color: ['#53D0DA', '#DAC453', '#E76363', '#2D3690', '#12C18D', '#F976ED', 
                '#1C78FE', '#ACD194', '#EEBFB1', '#EEBFB1'], 
        grid: { 
            bottom: '3%', 
            containLabel: true, 
            left: '3%', 
            right: '4%', 
            show: true, 
            top: '15%',
            altTop: '20%'
        }, 
        legend: { 
            data: [{ name: 't1', icon: 'roundRect', textStyle: { align: 'left' } }], 
            borderWidth: 1,

            show: true, 
            textStyle: { color: '#ffffff', fontFamily: 'Roboto' } 
        }, 
        series: [sample],           // template will be replaced real data
        title: { 
            show: false 
        }, 
        tooltip: {
            formatter: (params: EChartTSToolTipItem[]) => {
                return buildToolTip(params);
            }, 
            showDelay: 300, 
            backgroundColor: 'rgb(50,50,50,.7)',
            borderColor: '#333',
            borderWidth: '1',
            // position: [100,100],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
            position: (point: PointValue[]): PointValue[] => {
                // console.log(`points: ${JSON.stringify(point)}`)
                return [point[0],point[1]]
            },
            textStyle: { color: '#ffffff', fontFamily: 'Roboto', fontSize: 12, fontWeight: 200 }, 
            transitionDuration: 0, trigger: 'axis' 
        }, 
        xAxis: [{
            axisLabel: { 
                color: '#ffffff', 
                formatter: (value: number) => {
                    return fmtr.buildSimpleDateTime(value);
                },
                fontFamily: 'Roboto+Condensed',
                fontSize: 12,
                fontWeight: '200',
            }, 
            axisLine: {
                show: true

            }, 
            boundaryGap: false, 
            splitLine: {
                lineStyle: {
                    color: '#333333',
                    width: 1
                },
                show: true,
            },
            type: 'time' 
        }], 
        yAxis: [{
            axisLabel: { 
                color: '#ffffff', 
                // formatter is a placeholder.  The real one is assigned during processing
                formatter: (value: number) => {
                    return fmtr.buildGenericLabel(value);
                },
            },
            axisLine: {
                show: true

            }, 
            splitLine: {
                lineStyle: {
                    color: '#333333',
                    width: 1
                },
                show: true,
            },
            type: 'value' 
        }]
    };

    const options = Object.assign({}, optionsTemplate);
    options.series = [];
    options.legend.data = []
    options.yAxis[0].axisLabel.formatter = yAxisFormatGenerator(format);
    options.xAxis[0].axisLabel.formatter = xAxisFormatGenerator(periodOption);
    if (colors) {
        options.color = [];
    }
    for (let i = 0, len = metricData.length; i < len; i++) {
        const theSeries: TSSeriesData = metricData[i];
        if (colors) {
            const lineColor = colors[theSeries.metricKey];
            if ( lineColor && lineColor.length > 0 ) {
                options.color.push( lineColor );
            } else {
                logger.alert(`buildEChartOptions: no color associated with metric key ${theSeries.metricKey}.`);
            }
        }

        // setup the data
        options.series.push({
            data: theSeries.lineData,
            name: theSeries.seriesName ? theSeries.seriesName : svrGrphData.metric,
            type: 'line',
            lineStyle: { width: 2},
            areaStyle: { opacity: .1}
        });

        // setup the legend
        if (metricData.length > 1) {
            options.grid.top = options.grid.altTop;
            options.legend.data.push({
                icon: 'roundRect',
                name: theSeries.seriesName ? theSeries.seriesName : svrGrphData.metric,
                textStyle: { align: 'left' }
            });
        } else {
            options.legend.show = false;
        }
    }

    return options;
}

function pickfmtr(metricName: string): formatterEnum {
    let formatter = formatterEnum.UNDEFINED;
    if (metricName ) {
        metricName = metricName.toLowerCase();
        if (metricName.includes(STRs.kilobyteLabel)) {
            formatter = formatterEnum.KBYTE;
        } else if (metricName.includes(STRs.byteLabel)) {
            formatter = formatterEnum.BYTE;
        } else if (metricName.includes(STRs.millisecondLabel)) {
            formatter = formatterEnum.TIME;
        }  else if (metricName.includes(STRs.percentLabel)) {
            formatter = formatterEnum.PERCENT;
        } else {
            formatter = formatterEnum.NUM;
        }
    }

    return formatter;
}

function yAxisFormatGenerator(formatter: formatterEnum) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let fct = (value: number) => '';
    
    switch (formatter) {
        case formatterEnum.KBYTE:
            fct = ((value: number) => fmtr.buildKbLabel(value));
            break;

        case formatterEnum.BYTE:
            fct = ((value: number) => fmtr.buildByteLabel(value));
            break;

        case formatterEnum.TIME:
            fct = ((value: number) => fmtr.buildTimeLabel(value));
            break;

        case formatterEnum.NUM:
            fct = ((value: number) => fmtr.buildGenericLabel(value));
            break;

        case formatterEnum.PERCENT:
            fct = ((value: number) => fmtr.buildPercentLabel(value));
            break;

        default:
    }

    return fct;
}

function xAxisFormatGenerator(period: string) {
    let fct;

    if (period.includes(ANALYTICS_STRINGS.dayOption)) {
        fct = ((value: number) => fmtr.buildSimpleDateTime(value));
    } else {
        fct = ((value: number) => fmtr.buildSimpleTime(value));
    }

    return fct;
}

function buildToolTip(params: EChartTSToolTipItem[]): string {
    let marker = '';
    let tipEntry = '';
    let param: EChartTSToolTipItem;
    const tipData: string[] = [];
    let hasData = false;

    // console.log(`tooltip data: ${params}`);
    const date = new Date(params[0].axisValue);
    const dateStr1 = `<span style="padding-bottom: 3px;">${fmtr.formatDate(date)}`
    const dateStr2 = `&nbsp;&nbsp;${fmtr.formatTime(date, false, true, false)}</div>`;
    const dateStr = dateStr1 + dateStr2;

    tipData.push('<div>');
    tipData.push(dateStr);

    if (params.length > 1) { 
        for (let i = 0, len = params.length; i < len; i++) {
            param = params[i];
            marker = param.marker;
            if (typeof param.data.value[1] === 'number') {
                const dataStr = fmtr.formatToolTipData(param.data.value[1], param.data.format)

                tipEntry = `<span>${marker} ${param.seriesName}:&nbsp;&nbsp;${dataStr}</span><br>`;
                tipData.push(tipEntry);
                hasData = true;
            } 
        }
     } else {
        param = params[0];
        marker = param.marker;
        if (typeof param.data.value[1] === 'number') {
            hasData = true;
            const dataStr = fmtr.formatToolTipData(param.data.value[1], param.data.format);
            tipEntry = '<span>' + marker + '&nbsp;&nbsp;' + param.seriesName + ':&nbsp;&nbsp;' + dataStr + '</span>';
            tipData.push(tipEntry);
        }
    }

    tipData.push('</div>');
    const theTip = (hasData) ? tipData.join(' ') : '';
    return theTip ;
}