import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
// import moment from 'moment'; - need when GANU adds cert deployment status

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../App';
import { actionTypes } from '../../actions/actionCreatorTypes';
import * as actionCreators from '../../actions/actionCreators';

import { CertDataRow } from '../../reducers/adminState';
import { EProjectState, ZFeaturePopupTypes, CertUpdateStatus } from '../../reducers/reducerEnums';
import { buildUrl } from '../../reducers/serverEnvironAccessor'

import { ZProjectMin, ZRestResponse, FetchError, Certificate } from '../../data/queryResultDefinitions';

import { ADMIN_STRINGS as Strs } from '../../shared/strings';
import { copyTextToClipboard  } from '../../shared/utilities';
import { NotificationStyles, CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT } from '../../shared/constants';
import { ZGet, ZDelete } from '../../shared/backend';
import { certServerAction } from '../../shared/serverUtilitites';

import ZURLS from '../../shared/urls';
import ListPanel from '../../shared/ListPanel';
import logger from '../../shared/logUtilities';
import GenDialog from '../../shared/GenDialog';
import ZButton from '../../shared/ZButton';
import { CertificateObject } from '../../shared/utilities';
import CertEditor from '../../shared/CertEditor';

export interface CertAdminProps extends State {
    setAdminProjectList: (projectList: ZProjectMin[]) => actionTypes;
    setProjectListIndex: (index: number) => actionTypes;
    setProjectCertList: (certList: Certificate[], projectId: string) => actionTypes;
    setCertListIndex: (index: number) => actionTypes;
    applySettings: () => actionTypes;
    toggleCertDetails: (index: number) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    toggleAdminPopup: (popupType: ZFeaturePopupTypes, popupOpen: boolean) => actionTypes;
    setCertDetails: (certChain: string, privateKey: string) => actionTypes;
    closeNotification: () => actionTypes;
}

// eslint-disable-next-line 
class CertAdmin extends React.Component<any, CertAdminProps> {
    // private certChainTextAreaRef = React.createRef<HTMLTextAreaElement>();

    componentDidMount(): void {
        logger.log('mounting project admin');
        this.initCertAdminPage();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: CertAdminProps): void {
        const adminUIState = this.props.adminUIState.adminState;
        logger.log(`component did update (cert admin): ${adminUIState}`)
        if (adminUIState === EProjectState.tabChanged) {
            this.initCertAdminPage();
        }
        if (adminUIState === EProjectState.dataLoadInProgress) {
            this.loadProjectList();
        // } else if (adminUIState === EProjectState.tabChanged) {
        //     this.initCertAdminPage();
        }
    }

    render(): JSX.Element {
        // const systemState = this.props.systemNavProjectState;
        const adminState =  this.props.adminUIState;
        // const hasProjects = systemState._projectList.length > 0;
        const hasProjects = (adminState.projectList && adminState.projectList.length > 0);

        const projectList: JSX.Element = this.buildProjectList(hasProjects);
        const certList: JSX.Element = this.buildCertList();
        const cannotEditCert = (adminState.certList.length === 0);
        const cannotRemoveCert = (adminState.certList.length === 0);

        const popupBuilders = {
            [ZFeaturePopupTypes.UploadCert]: this.buildUploadCertPopup,
            [ZFeaturePopupTypes.EditCert]: this.buildEditCertPopup,
            [ZFeaturePopupTypes.DeleteCert]: this.buildDeleteCertPopup,
        };

        let certPopup = <span></span>;
        if (popupBuilders[adminState.popupType]) {
            certPopup = (popupBuilders[this.props.adminUIState.popupType])();
        }

        const projectHeader: JSX.Element = (
            <div className="admin-ca-project-list-header">
                <div></div>
                <div>{Strs.project}</div>
                <div>{Strs.numCerts}</div>
            </div>)

            const certsHeader: JSX.Element = (
            <div className="admin-ca-cert-list-header">
                <div></div>
                <div>{Strs.commonName}</div>
                <div>{Strs.expirationDate}</div>
                <div>{Strs.certStatus}</div>
                <div>{Strs.certId}</div>
                <div></div>
            </div>);

        // change the test to true to enable the delete button.
        // eslint-disable-next-line no-constant-condition
        const deleteButton = false ? (
            <>
                &nbsp;&nbsp;
                <ZButton onClick={(): void => { this.handleFooterBtnClick(ZFeaturePopupTypes.DeleteCert); }} 
                    disabled={cannotRemoveCert} btnCls="list-panel-footer-item">
                    {Strs.deleteCert}
                </ZButton> 
            </>) : <span></span>;

        return (<>
            <div className="title">{Strs.certManagement}</div>
            <div id="admin-tables" className="cert-admin">
                <div className="admin-panel">
                    <ListPanel 
                        renderHeader={(): JSX.Element => { return projectHeader }}
                        renderBody={(): JSX.Element => { return <>{projectList}</>}}
                    />
            </div>
                <div className="admin-panel">
                    <ListPanel 
                        renderHeader={(): JSX.Element => { return certsHeader }}
                        renderBody={(): JSX.Element => { return <>{certList}</>}}
                        renderFooter={(): JSX.Element => { return (
                            <div className="list-panel-footer">
                                <div>
                                    <ZButton disabled={!hasProjects} onClick={(): void => { 
                                                        this.handleFooterBtnClick(ZFeaturePopupTypes.UploadCert); }}>
                                        {Strs.uploadCert}
                                    </ZButton>&nbsp;&nbsp;
                                    <ZButton disabled={cannotEditCert} onClick={(): void => { 
                                        this.handleFooterBtnClick(ZFeaturePopupTypes.EditCert); }}>
                                        {Strs.editCert}
                                    </ZButton>
                                    { deleteButton }
                                </div>
                            </div>
                        )}}
                    />
                </div>
            </div>
            {certPopup}
        </>);
    }

    // render helper methods
    private buildProjectList = (hasProjects: boolean): JSX.Element => {
        const { projectList, projectListIdx} = this.props.adminUIState;
        const projListItems: JSX.Element[] = []

        if (hasProjects) {
            projectList.forEach((proj: ZProjectMin, index: number) => {
                const numCerts = proj.meta && proj.meta.num_certs ? proj.meta.num_certs.toString() : '---';
                const selected = (index === projectListIdx) ? ' selected' : '';

                projListItems.push( 
                    <div key={index} className={'admin-ca-project-list-item' + selected} onClick={(): void => {
                        this.handleSelectedProjectChanged(index);
                    }}>
                        <div></div>
                        <div>{proj.name}</div>
                        <div>{numCerts}</div>
                        <div></div>
                    </div>
                )
            });
        } else {
            projListItems.push( 
                <div key="no-projects" className="admin-ca-project-list-item">
                    <div></div>
                    <div>{Strs.noProjectsInOrg}</div>
                    <div></div>
                    <div></div>
                </div>
            )
        }

        return <>{projListItems}</>;
    }

    private buildCertList = (): JSX.Element => {
        const {certData, certListIdx} = this.props.adminUIState;
        
        const certItems: JSX.Element[] = []
        certData.forEach((cert: CertDataRow, index: number) => {
            certItems.push(this.buildCertListEntry(cert, certListIdx === index, index));
        });

        return <>{certItems}</>;
    }

    private buildCertListEntry = (cert: CertDataRow, isSelected: boolean, index: number): JSX.Element => {
        const open = cert.open ? 'caret-down' : 'caret-right'
        
        const certObj: CertificateObject = cert.certObj;
        const certState = (certObj.updateStatus === CertUpdateStatus.inProgress) ? 
            <><FontAwesomeIcon className="fa-spinner" icon={'spinner' as IconProp} />&nbsp;&nbsp;</> : <span></span>;
        
        const selected = isSelected ? 'selected' : '';
        /****************** *        -- save for when Ganu implements status.
        // TODO:
        let lastUpdate = cert.lastUpdate;
        let isLastUpdateAMoment = moment.isMoment(lastUpdate) && lastUpdate.isValid();
        let lastUpdateStr = isLastUpdateAMoment ? 
                                (lastUpdate as moment.Moment).format(DATETIME_FORMAT_NOSECS) : Strs.unknown;

            <div className="extra">
                <div>{Strs.lastUpdate} {lastUpdateStr}</div>
                <div>{Strs.updateStatus} {cert.updateStatus}</div>
                <div>{Strs.assocServices}</div>
                <div>{cert.serviceIds.length > 0 ? cert.serviceIds.join(', ') : Strs.none}</div>
                <div>{Strs.sanInfo}</div>
                <div>{cert.sanInfo.length > 0 ? cert.sanInfo.join(', ') : Strs.none}</div>
            </div>
        */
       let issuer = <></>
       let sansInfo = '';
       if (cert.open) {
            const issuerName = cert.certObj.issuer;
            if (issuerName.length > 0) {
                issuer = <div>{Strs.certIssuer}  {issuerName}</div>
            }
            const sansInfoData = certObj.sansInfo;
            sansInfo = sansInfoData.length > 0 ? sansInfoData : Strs.noSanInfo;
       }

       const extra = (cert.open) ? (
            <div className="extra">
                <div></div>
                {issuer}
                <div>{Strs.issueDate} {certObj.issueDateStr}</div>
                <div>{Strs.sanInfo}</div>
                <div className="sans-info">{sansInfo}</div>
            </div>
        ) : <div></div>;

        const item = (
            <div key={index} className={'admin-ca-cert-list-item ' + selected} onClick={
                (): void => { if (!isSelected) { this.handleCertSelection(index)}}
            }>
                <div>
                    <span onClick={(): void => { this.openCert(index) }}><FontAwesomeIcon icon={open as IconProp} /></span>
                </div>
                <div>{certState}{certObj.commonName}</div>
                <div>{certObj.expirationStr}
                </div>
                <div>{certObj.icon}
                    &nbsp;&nbsp;{certObj.certStatusStr}</div>
                <div>{certObj.certId}&nbsp;&nbsp;<span onClick={(): void => { this.copyToClipboard( certObj.certId) }}>
                <FontAwesomeIcon icon={'copy' as IconProp} /></span></div>
                <div></div>
                {extra}
            </div>
        );

        return item;
    }

    // click handlers
    private handleFooterBtnClick = (popup: ZFeaturePopupTypes): void => {
        this.props.toggleAdminPopup(popup, true);
    }

    private handleSelectedProjectChanged = (index: number, force = false): void => {
        if ( (index !== this.props.adminUIState.projectListIdx) || force) {
            this.props.setProjectListIndex(index);
            this.loadSelectedProjectsCerts(index);
        }
    }

    private handleCertSelection = (selectedCert: number): void => {
        this.props.setCertListIndex(selectedCert);
    }

    private openCert = (index: number): void => {
        this.props.toggleCertDetails(index);
    }

    private copyToClipboard = ( key: string ): void => {
        const success = copyTextToClipboard(key);
        const msg = (success) ? Strs.certIdCopied : Strs.apiKeyNotCopied;
        const msgStyle = (success) ? NotificationStyles.success : NotificationStyles.danger ;

        this.showNotification(msg, msgStyle);
    }

    // upload cert handling
    private buildUploadCertPopup = (): JSX.Element => {
        const add =  <CertEditor updatingCert={false}  
                        handleApply={(certPrivate: string, certChain: string): void => { 
                                        this.applyUploadCert(certPrivate, certChain); }}
                        handleCancel={(): void => { this.closePopup() }} />

        return add;
    }

    private applyUploadCert = async (certPrivate: string, certChain: string): Promise<void> => {
        const { selectedProject } = this.props.adminUIState;
        let url = ZURLS.serverCertManagement;
        url = buildUrl(this.props.authNServerData, url);

        try {
            await certServerAction(url, certChain, certPrivate, selectedProject.project_id);
            this.props.applySettings();
            this.showNotification(Strs.certUploadSuccess, NotificationStyles.success);
        } 
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                const errMsg = err.statusCode && err.statusCode === 409 ? Strs.certAlreadyLoaded : err.message;
                this.showNotification(errMsg, NotificationStyles.danger)
            })
            p.catch((err1) => {
                console.log('doubleErr: ' + err1)
            })
        }
    }

    private showNotification = ( message: string, style: NotificationStyles ): void => {
        this.props.showNotification(style, message);
        setTimeout(() => { this.props.closeNotification(); }, CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT);
    }  

    // Edit Cert handling
    private buildEditCertPopup = (): JSX.Element=> {
        logger.log('edit cert popup');
        const { certList, certListIdx } = this.props.adminUIState;
        const cert = certList[certListIdx];

        const update = <CertEditor updatingCert={true} cert={cert} 
                            handleApply={(certPrivate: string, certChain: string): void => {this.applyUpdateCert(certPrivate, certChain);}}
                            handleCancel={(): void => { this.closePopup() }}>
                        </CertEditor>

        return update;
    }

    private applyUpdateCert = async (certPrivate: string, certChain: string): Promise<void> => {
        const { certList, certListIdx } = this.props.adminUIState;
        const cert = certList[certListIdx];

        let url = ZURLS.serverCertUpdate(cert.cert_id);
        url = buildUrl(this.props.authNServerData, url);
        try {
            await certServerAction(url, certChain, certPrivate );
            this.props.applySettings();
            this.showNotification(Strs.certUpdateSuccess, NotificationStyles.success)
        }
        catch(errStr) {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                const errMsg = err.statusCode && err.statusCode === 409 ? Strs.certAlreadyLoaded : err.message;
                this.showNotification(errMsg, NotificationStyles.danger)

            })
            p.catch((err1) => {
                console.log('doubleErr: ' + err1)
            })
        }
    }

    // Delete cert handling
    private buildDeleteCertPopup = (): JSX.Element => {
        logger.log('delete cert popup')
        alert('MUST BE ASSOCIATED WITH NO SERVICES IN THE PROJECT.')
        const { certList, certListIdx } = this.props.adminUIState;
        const cert = certList[certListIdx];
        const message = Strs.confirmDelete(cert.common_name, cert.cert_id);

        const update = (
            <GenDialog 
                show={this.props.adminUIState.popupOpen}
                title={Strs.deleteExistingCert} 
                msg={message}
                size='sm'
                applyBtnTxt={Strs.deleteCert}
                disableApply={(): boolean => { return false}}
                onHide={(): void => {this.closePopup()}}
                handleApply={(): void => { this.applyDeleteCert(); }}
            >
            </GenDialog>
        );

        return update;
    }

    private applyDeleteCert = (): void => {
        const { certList, certListIdx } = this.props.adminUIState;
        const cert = certList[certListIdx];

        let url = ZURLS.serverCertUpdate(cert.cert_id);
        url = buildUrl(this.props.authNServerData, url);

        const promise = ZDelete(url, '');
        promise.then( ( ) => {
            this.props.applySettings();
        });
        promise.catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                this.showNotification(err.message, NotificationStyles.danger)
            })
        })
    }
    
    // Load data from server
    private initCertAdminPage = (): void => {
        this.loadProjectList();
    }

    private loadProjectList = (): void => {
        const url = buildUrl(this.props.authNServerData, ZURLS.serverAdminProjectList);
        const promise = ZGet(url, {});
        
        promise.then( ( projectList: ZRestResponse ) => {
            this.props.setAdminProjectList(projectList as ZProjectMin[]);
        }).then(() => {
            this.handleSelectedProjectChanged(this.props.adminUIState.projectListIdx, true);
        })
        .catch((errStr) => {
            const p = errStr as Promise<FetchError>;
            p.then((err) => {
                console.log(`CertAdmin.loadProjectList: fetch failed: ${err}`);
                this.showNotification(err.message, NotificationStyles.danger)
            })
        });
    }

    private loadSelectedProjectsCerts = (index: number): void => {
        const projects = this.props.adminUIState.projectList;
        if (projects.length > 0) {
            const project = projects[index];
            this.loadProjectsCerts(project);
        }
    }

    private loadProjectsCerts = (project: ZProjectMin): void => {
        const projectId = (project) ? project.project_id : '';
        if (projectId && projectId.length > 0) {
            let url = ZURLS.serverCertManagement;
            url = buildUrl(this.props.authNServerData, url);

            const requestData = {
                "project_id": projectId,
                meta: true,
                'meta.additional_info': true
            }

            const promise = ZGet(url, requestData);
            promise.then( ( certList: ZRestResponse ) => {
                this.props.setProjectCertList(certList as Certificate[], projectId);
            })
            .catch((errStr) => {
                const p = errStr as Promise<FetchError>;
                p.then((err) => {
                    console.log(`ProjectAdmin.loadProjectsUserData: fetch failed: ${err}`);
                    this.showNotification(err.message, NotificationStyles.danger)
                })
            });
        }
    }

    private closePopup  = (): void => {
        this.props.toggleAdminPopup(ZFeaturePopupTypes.NoPopup, false);
    }
}

// eslint-disable-next-line 
const stateToProps = (state: State): any => {
    return {
        systemNavProjectState: state.systemNavProjectState,
        adminState: state.adminUIState,
    }
}

// eslint-disable-next-line 
function mapDispatchToProps(dispatch: Dispatch): any {
    return bindActionCreators( actionCreators, dispatch);
}

export default connect(stateToProps, mapDispatchToProps)(CertAdmin);