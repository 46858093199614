import * as React from 'react';

import { State } from '../App';

import { SysFeatureEnums } from '../reducers/reducerEnums';
import { actionTypes } from '../actions/actionCreatorTypes';

import ViewServices from './svcMgntComps/ViewServices';
import EditDraftServices from './svcMgntComps/EditDraftSvc';
import DeployService from './svcMgntComps/DeployService';
import CreateService from './svcMgntComps/CreateService';
import DeleteService from './svcMgntComps/DeleteService';
import { MISC_STRINGS } from '../shared/strings';
import { NotificationStyles } from '../shared/constants';

export interface ServiceManagementProps extends State {
    deleteService: (serviceId: string) => actionTypes;
    showNotification: (style: NotificationStyles, message: string) => actionTypes;
    closeNotification: () => actionTypes;
}

class ServiceManagement extends React.Component<ServiceManagementProps> {
    render(): JSX.Element {
        const uiState = this.props.systemNavProjectState;
        let page: JSX.Element = <span></span>;
        if (uiState.currentFeature === SysFeatureEnums.viewServices) { 
            page = <ViewServices  {...this.props} /> 
        } else if (uiState.currentFeature === SysFeatureEnums.editDraftService) { 
            page = <EditDraftServices  {...this.props} /> 
        } else if (uiState.currentFeature === SysFeatureEnums.deployService) {
            page = <DeployService  {...this.props} />;
        } else if (uiState.currentFeature === SysFeatureEnums.createService) {
            page = <CreateService  {...this.props} />;
        } else if (uiState.currentFeature === SysFeatureEnums.deleteService) {
            page = <DeleteService  {...this.props} />;
        } else {
            page = <div>{MISC_STRINGS.loading}</div>
        }

        return (
            <div className="feature-panel">
                <div className="serice-management-features">
                    {page}
                </div>
                <div className="copyright-bottom" dangerouslySetInnerHTML={MISC_STRINGS.getCopyright()} />
            </div>
        )
    }
}

export default ServiceManagement;