/* eslint-disable react/prop-types */
import * as React from 'react';

import { MISC_STRINGS as Strs } from './strings';
import logo from '../img/Zycada_Horizontal_White300.png';

export interface LPProps {
  title: string;
  msg: string;
  lpCSS: string;
  copyrightPanelBottom?: boolean;   // true means the bottom is below the panel; false means that it is positioned absolutely
}

// tslint:disable-next-line: variable-name
const LoginPanel: React.FunctionComponent<LPProps> = (props) => {

  const panelChildren = React.Children.map(props.children, child => {
    return child;
  });

  const copyrightBtm = (props.copyrightPanelBottom !== undefined) && props.copyrightPanelBottom ? "copyright" : "copyright-bottom";

  const dboxCSS = props.lpCSS ? 'display-box ' + props.lpCSS : 'display-box';
  return (
    <div className="login-panel container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className={dboxCSS}>
          <img className="logo" src={logo} title="logo" alt="logo" />
            <div className="title">{props.title}</div>
            {panelChildren}
          </div>
          <div>{props.msg}</div>
        </div>
        <div className={copyrightBtm} dangerouslySetInnerHTML={Strs.getCopyright()} />
      </div>
    </div>
  );
}

export default LoginPanel;
