export type ZModalSize = 'sm' | 'lg' | 'xl' | undefined;

// This enum (EditSvcFixedOptionIndex) is tied to the constant EditSvcFixedOptions.  
// If you update the enum, you must update the constant.
export enum EditSvcFixedOptionIndex {
    overviewIdx = 0,
    originIdx = 1,
    dnsDirectorIdx = 2,
    cloudServiceMonitorIdx = 3,
}
export const EditSvcFixedOptions = 4;  // This number should always match the number of enums
                                       // entries in the EditSvcFixedOptionIndex enum.  All of 
                                       // the items after that in the nav are dynamic.
