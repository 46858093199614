import * as React from 'react';

// tslint:disable
const ApiDocFWallMgt = (): JSX.Element => {
    return ( <>
<div className="z-title">Firewall Management</div>

<div className="zsection">
    This section describes Zycada endpoints used for whitelisting and blacklisting
    IP Addresses in the Zycada network.

    <div className="before-code"><h3>Supported Operations</h3></div>
    <ul>
        <li>Add new IPs to your blacklist or whitelist</li>
        <li>Enumerate the current white or blacklist IPs</li>
        <li>Remove IPs from the existing whitelist or blacklist</li>
    </ul>

    <div className="zheading">The following summarizes the commands you can perform:</div>
    <table className="zcmd-summary">
        <thead>
        <tr>
            <th>URL / Command</th><th>GET</th><th>POST</th><th>DELETE</th>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    https://api.zycada.com/v1/blacklisted_ips
                    https://staging-api.zycada.com/v1/blacklisted_ips
                </td>
                <td>Obtain the list of blacklisted IP addresses</td>
                <td>Add new IPs to the list of blacklisted IP addresses</td>
                <td>Remove IPs from the list of blacklisted IP addresses</td>
            </tr>
            <tr>
                <td>
                    https://api.zycada.com/v1/whitelisted_ips
                    https://staging-api.zycada.com/v1/whitelisted_ips
                </td>
                <td>Obtain the list of whitelisted IP addresses</td>
                <td>Add new IPs to the list of whitelisted IP addresses</td>
                <td>Remove IPs from the list of whitelisted IP addresses</td>
            </tr>
        </tbody>
    </table>
</div>
</>
    )
}

export default ApiDocFWallMgt;