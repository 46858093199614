import moment from 'moment';
import { actionTypes, ActionKeys, ASetReportData, ASetReportFromTo, ASetReportPeriodIndex, AToggleBillingShowProjectServices,
         ASetReportQueryTimeFormat, AApplySettings, AInitFeature, ASetBillingReportData, ASetProjectTrafficUsage, 
         AToggleBillingShowServices, ASortTable, ASetBillingUnits, ASetBillingMonthIndex } from '../actions/actionCreatorTypes';

import { AggrDataMap, AvailableBillingReports, ReportOverviewData, ProjectServiceBillingData, ProjectBillingData,
         BillingOrgUsageData } from '../data/queryResultDefinitions';
import { TimeRange, ColumnSortMap } from '../data/metricsAndOptionsDefs';

import { EProjectState, SysFeatureEnums, ZSortDirection, UsageUnits, TrafficBillingFieldsEnum, ZOpenRowsState } from './reducerEnums';
import { getYesterday, getToday, getThisWeek, getThisMonth, getLastWeek, getLastMonth, getLast7Days, 
         getLast30Days, getYearToDate } from '../shared/utilities';
import logger from '../shared/logUtilities';

import cloneDeep from 'lodash/cloneDeep';

// These are method names in utilities.tsx.  They should match 1:1 with the list of time periods.
// We use the index from the dropdown to pick the method to call.
const converters = [
    getToday,
    getYesterday,
    getThisWeek,
    getThisMonth,
    getLastWeek,
    getLastMonth,
    getLast7Days,
    getLast30Days,
    getYearToDate
];

export interface ProjectServiceBillingDataUI extends ProjectServiceBillingData {
    showingServicesOpen: boolean,
}

export interface ProjectBillingDataUI extends ProjectBillingData {
    showingServicesOpen: boolean,
}

export type BillingDataUI  =  ProjectBillingDataUI | ProjectServiceBillingDataUI;

export interface State {
    from: moment.Moment,
    isDirty: boolean,
    isLoading: boolean,
    periodIdx: number,
    reportData: AggrDataMap[],
    reportState: EProjectState,
    showingRelativeTime: boolean,
    to: moment.Moment,
    updateTime: moment.Moment,
    currentReportData: {
        from: moment.Moment,
        to: moment.Moment,
        showingRelativeTime: boolean,
        serviceIds: string,
        serviceNames: string,
        env: string,
        periodIdx: number,
    },
    billingReportsListLoaded: boolean,
    showingServices: boolean,
    currentBillingReport: SysFeatureEnums,
    availableBillingReports: AvailableBillingReports,
    currentBillingReportMonths: ReportOverviewData[],
    billingUsageData: BillingDataUI[],
    billingOrgUsageData: BillingOrgUsageData,
    columnSortMap: ColumnSortMap,
    currentSortField: TrafficBillingFieldsEnum,
    billingUsageUnits: UsageUnits,
    allServicesOpen: ZOpenRowsState,
    serviceDataLoaded: boolean,
}

const reportInitData: State = {
    from: moment(),
    isDirty: true,
    isLoading: false,
    periodIdx: 0,
    reportData: [],
    reportState: EProjectState.ready,
    showingRelativeTime: true,
    to: moment(),
    updateTime: moment(),
    currentReportData: {
        from: moment(),
        to: moment(),
        showingRelativeTime: true,
        serviceIds: '',
        serviceNames: '',
        env: '',
        periodIdx: 0,     
    },

    billingReportsListLoaded: false,
    currentBillingReport: SysFeatureEnums.unknown,
    availableBillingReports: {} as AvailableBillingReports,
    billingUsageData: [] as ProjectBillingDataUI[],    // this is a copy of the org data's project info.
    billingOrgUsageData: {} as BillingOrgUsageData,  // this is a copy of org data passed with out the project info
    showingServices: false,
    columnSortMap: {} as ColumnSortMap,
    currentSortField: TrafficBillingFieldsEnum.projectName,
    billingUsageUnits: UsageUnits.gigaBytes,
    currentBillingReportMonths: [] as ReportOverviewData[],
    allServicesOpen: ZOpenRowsState.none,
    serviceDataLoaded: false,
}


const initSortMap = (state: State, columnNames: string[]): State => {
    columnNames.forEach((name: string) => {
        state.columnSortMap[name] = ZSortDirection.l2h;
    })

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initReportData = (state: State, actions: actionTypes): State => {
    state.isLoading = false;
    state.periodIdx = 0;
    state.reportData = [];
    state.reportState = EProjectState.ready;
    state.showingRelativeTime = true;
    state.updateTime = moment();
    state.isDirty = true;

    const range: TimeRange = (converters[state.periodIdx])()
    state.to = range.to;
    state.from = range.from;
    state.currentReportData = {
        from: range.from,
        to: range.to,
        showingRelativeTime: true,
        serviceNames: '',
        serviceIds: '',
        env: '',
        periodIdx: 0, 
    }

    state.showingServices = false;
    state.currentBillingReport = SysFeatureEnums.unknown;
    state.availableBillingReports = {} as AvailableBillingReports;
    state.billingUsageData = [] as ProjectBillingDataUI[];
    state.columnSortMap = {} as ColumnSortMap;
    state.currentSortField = TrafficBillingFieldsEnum.projectName;
    state.billingUsageUnits = UsageUnits.gigaBytes;
    state.allServicesOpen = ZOpenRowsState.none;
    state.serviceDataLoaded = false;

    return state;
}

const initFeature = (state: State, actions: actionTypes): State => {
    let newState: State = state;
    const action = actions as AInitFeature;

    const handler = {
        [SysFeatureEnums.reports]: initAnalyticsReports,
        [SysFeatureEnums.billingReportsDownloads]: initBillingReports,
        [SysFeatureEnums.billingReportsTrafficUsage]: initBillingReports,
    }

    const feature = action.payload.feature;
    newState = {...initReportData( state, actions)};

    if (handler[feature]) {
        newState = handler[feature](newState, actions);
    } else {
        newState = initReports(newState, actions);
    }
 
    return newState;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initAnalyticsReports = (state: State, actions: actionTypes) => {
    state.reportState = EProjectState.initComplete;
    state.currentBillingReport = SysFeatureEnums.unknown;

    state.isDirty = true;

    return state;
}

const initBillingReports = (state: State, actions: actionTypes) => {
    state.reportState = EProjectState.initStarted;
    const action = actions as AInitFeature;

    const feature = action.payload.feature;
    state.currentBillingReport = feature !== undefined ? feature : SysFeatureEnums.unknown;
    state.isDirty = false;
    state.showingServices = false;
    state.availableBillingReports = {};

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initReports = (state: State, actions: actionTypes) => {
    state.reportState = EProjectState.unknown;

    state.currentBillingReport = SysFeatureEnums.unknown;
    state.isDirty = false;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const serviceOrEventChanged = (initialState: State, actions: actionTypes) => {
    const state = {...initialState};
    state.reportState = EProjectState.serviceChanged;
    state.isDirty = true;

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetProject = ( initialState: State, actions: actionTypes ): State => {
    const state = {...initialState};
    state.reportState = EProjectState.serviceChanged
    state.isDirty = true;       // will make report apply button code happy

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeServiceEnvironment = (initialState: State, actions: actionTypes) => {
    const state = {...initialState};
    state.reportState = EProjectState.serviceChanged
    state.isDirty = true;

    return state;
}

const setReportData =  (initialState: State, actions: actionTypes) => {
    const action = actions as ASetReportData;
    const state = {...initialState};
    state.reportData = action.payload.data;
    state.currentReportData.serviceNames = action.payload.serviceNames;
    state.currentReportData.serviceIds = action.payload.serviceIds;
    state.currentReportData.env = action.payload.env;

    state.updateTime = moment();
    state.isLoading = false;
    state.reportState = EProjectState.ready;

    return state;
}

const applySettings = (initialState: State, actions: actionTypes) => {
    const state = {...initialState};
    const action = actions as AApplySettings;

    state.isLoading = true;
    state.isDirty = false;
    
    if (action.payload.error && EProjectState.userClickedApply === state.reportState) {
        state.reportState = EProjectState.serverError;
    } else {
        state.reportState = EProjectState.userClickedApply;
    }
    
    state.currentReportData = {
        from: state.from,
        to: state.to,
        showingRelativeTime: state.showingRelativeTime,
        serviceNames: '',
        serviceIds: '',
        env: '',
        periodIdx: state.periodIdx,
    }
    return state;
}

const setReportFromTo = (initialState: State, actions: actionTypes) => {
    const action = actions as ASetReportFromTo;
    const state = {...initialState};

    state.to = action.payload.to;
    state.from = action.payload.from;
    state.isDirty = true;

    return state;
}

const setReportPeriodIndex = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as ASetReportPeriodIndex;

    state.isDirty = true;
    const index = action.payload.periodIndex
    state.periodIdx = index;
    const range: TimeRange = (converters[index])()
    state.to = range.to;
    state.from = range.from;

    return state;
}

const setReportQueryTimeFormat = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as ASetReportQueryTimeFormat;

    state.isDirty = true;
    state.showingRelativeTime = action.payload.showingRelativeTime;
    if (state.showingRelativeTime) {
        state.periodIdx = 0;
    } else {
        const range: TimeRange = (converters[state.periodIdx])();
        state.to = range.to;
        state.from = range.from;
    }

    return state;
}

const setBillingReportData = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as ASetBillingReportData;

    const availableBillingReports = action.payload.availableBillingReports;
    state.availableBillingReports = availableBillingReports;
    let reports = availableBillingReports['traffic_usage'];
    
    reports = reports.sort((a: ReportOverviewData, b: ReportOverviewData): number => {
        let rc = 0;

        if (a.year === b.year) {
            rc = a.month > b.month ? -1 : 1
        } else {
            rc = a.year > b.year ? -1 : 1;
        }

        return rc;
    });

    state.currentBillingReportMonths =  reports;
    state.reportState = EProjectState.dataLoadInProgress;

    return state;
}

// const sortData = (data: B÷illingDataUI[], field: string, direction: ZSortDirection): BillingDataUI[]  => {
function sortData<Type> (data: Type[], field: string, direction: ZSortDirection, fallbackField?: string): Type[] {
    let rc = 0;
    data = data.sort((a: Type, b: Type): number => {
        let aData = a[field];
        let bData = b[field];

        aData = ((aData === undefined) || (typeof aData === 'string' && aData.length ===0)) ? 0 : aData
        bData = ((bData === undefined) || (typeof bData === 'string' && bData.length ===0)) ? 0 : bData

        const sortValues = (asort: number | string, bsort: number | string): number => {
            if (asort === bsort && fallbackField) {
                asort = a[fallbackField];
                bsort = b[fallbackField];
            }
            if (direction === ZSortDirection.l2h) {
                rc = asort < bsort ? -1 : 1;
            } else {
                rc = asort > bsort ? -1 : 1;
            }
            return rc;
        }

        if (typeof(aData) !== typeof(bData)) {
            logger.log(`sortData: aData: ${a}; bData: ${b}; field ${field}; Types don't match`)
            rc = 0;
        } else if (typeof(aData) === 'string') {
            aData = aData.toUpperCase();
            bData = bData.toUpperCase();
            rc = sortValues(aData, bData);
        } else if (typeof(aData) === 'number') {
            rc = sortValues(aData, bData);
        } else {
            logger.log(`sortData: aData: ${a}; bData: ${b}; field ${field}; Unknown type.`)
        }
    
        return rc;
    })

    return data;
}

const setProjectTrafficUsage  = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as ASetProjectTrafficUsage;

    // projects are only in the orgData. services and project_id are only in the project or service level data
    const excludeField = { "services": true, "project_id": true, "projects": true };

    state.billingOrgUsageData = {} as BillingOrgUsageData;
    const orgData = action.payload.billingUsageData;
    let keys = Object.keys(orgData);

    // copy the org summary
    keys.forEach((key: string) => {
        if (! excludeField[key]) {
            state.billingOrgUsageData[key] = orgData[key];
        }
    });

    const sortFields = [
        TrafficBillingFieldsEnum.projectName,
        TrafficBillingFieldsEnum.totalRequests,
        TrafficBillingFieldsEnum.totalBytesOut,
        TrafficBillingFieldsEnum.totalBytesIn,
        TrafficBillingFieldsEnum.totalLogBytesOut,
    ];
    initSortMap(state, sortFields);

    const projectData = orgData.projects;
    if (projectData.length > 0) {
        keys = Object.keys(projectData[0]);
        keys = keys.filter((key: string) => {
           return  (! excludeField[key])
        });
        
        state.billingUsageData.length = 0;
        const hasSvcData = action.payload.includesServices;
        for (let i = 0, len = projectData.length; i < len; i++) {
            const oData = cloneDeep(projectData[i])
            const data: BillingDataUI = hasSvcData ? oData as ProjectServiceBillingDataUI :
                                                   oData as ProjectBillingDataUI;
            data.showingServicesOpen = false;
            state.billingUsageData.push(data);
        }
        state.billingUsageData = sortData(state.billingUsageData, TrafficBillingFieldsEnum.projectName, ZSortDirection.l2h, TrafficBillingFieldsEnum.projectName);
        
        if (hasSvcData) {
            state.serviceDataLoaded = true;
            for (let i = 0, len = projectData.length; i < len; i++) {
                const project = state.billingUsageData[i];
                (project as ProjectServiceBillingData).services = 
                            sortData((project as ProjectServiceBillingData).services, TrafficBillingFieldsEnum.domainName, ZSortDirection.l2h, TrafficBillingFieldsEnum.projectName);
            }
        } else {
            state.serviceDataLoaded = false;
        }
        state.showingServices = false;
    }

    state.allServicesOpen = ZOpenRowsState.none;
    state.isLoading = false;
    state.reportState = EProjectState.ready;

    return state;
}

const toggleBillingShowServices = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as AToggleBillingShowServices;

    const newShowServiceState = action.payload.showingServices;
    state.showingServices = newShowServiceState;
    state.allServicesOpen = ZOpenRowsState.none;
    state.billingUsageData.forEach((project: ProjectBillingDataUI) => {
        project.showingServicesOpen = false;
    });
    return state;
}

const toggleBillingProjecShowServiceOpen  = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as AToggleBillingShowProjectServices;
    const index = action.payload.projectIndex;

    if (index === -1) {             // toggle all rows open or closed
                                    // if some of the rows are open, open all rows
        state.allServicesOpen = (state.allServicesOpen === ZOpenRowsState.all) ? ZOpenRowsState.none : ZOpenRowsState.all;
        state.billingUsageData.forEach((project: ProjectBillingDataUI) => {
            project.showingServicesOpen = (state.allServicesOpen === ZOpenRowsState.all)
        });
    } else {
        const project = state.billingUsageData[action.payload.projectIndex];
        project.showingServicesOpen = !project.showingServicesOpen;

        let open = 0;
        let closed = 0;
        state.billingUsageData.forEach((proj: ProjectBillingDataUI) => {
            if (proj.showingServicesOpen) {
                open++;
            } else {
                closed++;
            }
        });
        state.allServicesOpen = (open === state.billingUsageData.length) ? ZOpenRowsState.all :
                                (closed === state.billingUsageData.length) ? ZOpenRowsState.none : ZOpenRowsState.mixed;
    }

    return state;
}

const sortTable  = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as ASortTable;
    const {feature, fieldName, direction} = action.payload;
    
    if (feature === SysFeatureEnums.billingReportsTrafficUsage) {
        state.columnSortMap[fieldName] = direction;
        state.currentSortField = fieldName as TrafficBillingFieldsEnum;
        state.billingUsageData = sortData(state.billingUsageData, fieldName, direction, TrafficBillingFieldsEnum.projectName);
        
        const hasSvcData = state.serviceDataLoaded;
        const projectData = state.billingUsageData;
        if (hasSvcData) {
            let fName = fieldName;
            if (fieldName === TrafficBillingFieldsEnum.projectName) {
                fName = TrafficBillingFieldsEnum.domainName;
            }
            for (let i = 0, len = projectData.length; i < len; i++) {
                const project = projectData[i];
                (project as ProjectServiceBillingData).services = 
                            sortData((project as ProjectServiceBillingData).services, fName, direction, TrafficBillingFieldsEnum.projectName);
            }
        }
    }

    return state;
}

const setBillingUnits = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as ASetBillingUnits;

    state.billingUsageUnits = action.payload.units;

    return state;
}

const setBillingMonthIndex = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 
    const action = actions as ASetBillingMonthIndex;

    if (state.periodIdx !== action.payload.index) {
        state.periodIdx = action.payload.index;
        state.reportState = EProjectState.dataLoadInProgress;
        state.currentSortField = TrafficBillingFieldsEnum.projectName;
    }

    return state;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const featureReady = (initialState: State, actions: actionTypes) => {
    const state = {...initialState}; 

    state.reportState = EProjectState.ready;
    return state;
}

type ActionFunction = ((state: State, action: actionTypes) => State);
type AdminActions = ActionKeys.SET_PROJECTS | ActionKeys.CHANGE_PROJECTS | ActionKeys.SET_REPORT_DATA |
                    ActionKeys.SET_REPORT_QUERY_TIME_FORMAT | ActionKeys.SET_REPORT_PERIOD_INDEX  | ActionKeys.FEATURE_READY |
                    ActionKeys.SET_REPORT_FROM_TO | ActionKeys.SET_CURRENT_SERVICE_EVT_IDX | ActionKeys.SET_BILLING_MONTH_INDEX |
                    ActionKeys.CHANGE_ENVIRONMENT | ActionKeys.APPLY_SETTINGS | ActionKeys.RESET_PROJECT | ActionKeys.SORT_TABLE |
                    ActionKeys.INIT_FEATURE | ActionKeys.SET_BILLING_REPORTS_DATA | ActionKeys.SET_PROJECT_TRAFFIC_USAGE |
                    ActionKeys.SET_BILLING_UNITS | ActionKeys.TOGGLE_BILLING_PROJECT_SERVICES | ActionKeys.TOGGLE_BILLING_SHOW_SERVICES;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ActionCmds = { [k in AdminActions]: ActionFunction | undefined }

const actionMap: ActionCmds = {
    [ActionKeys.CHANGE_ENVIRONMENT]: changeServiceEnvironment,
    [ActionKeys.SET_PROJECTS]: initFeature,
    [ActionKeys.CHANGE_PROJECTS]: initFeature,
    [ActionKeys.SET_CURRENT_SERVICE_EVT_IDX]: serviceOrEventChanged,
    [ActionKeys.SET_REPORT_DATA]: setReportData,
    [ActionKeys.SET_REPORT_QUERY_TIME_FORMAT]:  setReportQueryTimeFormat,
    [ActionKeys.SET_REPORT_FROM_TO]: setReportFromTo,
    [ActionKeys.SET_REPORT_PERIOD_INDEX]: setReportPeriodIndex,
    // [ActionKeys.APPLY_REPORT_OPTIONS]: applyReportOptions,
    [ActionKeys.APPLY_SETTINGS]: applySettings, 
    // [ActionKeys.INIT_REPORTS]: initAnalyticsReports,
    [ActionKeys.RESET_PROJECT]: resetProject,
    [ActionKeys.INIT_FEATURE]: initFeature,
    [ActionKeys.FEATURE_READY]: featureReady,

    [ActionKeys.SET_BILLING_REPORTS_DATA]: setBillingReportData,
    [ActionKeys.SET_PROJECT_TRAFFIC_USAGE]: setProjectTrafficUsage,
    [ActionKeys.TOGGLE_BILLING_SHOW_SERVICES]: toggleBillingShowServices,
    [ActionKeys.TOGGLE_BILLING_PROJECT_SERVICES]: toggleBillingProjecShowServiceOpen,
    [ActionKeys.SORT_TABLE]: sortTable,
    [ActionKeys.SET_BILLING_UNITS]: setBillingUnits,
    [ActionKeys.SET_BILLING_MONTH_INDEX]: setBillingMonthIndex,
}

function reportsUIState(initialState: State, action: actionTypes ): State {
    let state: State = initialState;

    if ( initialState === undefined ) {
        state = Object.assign({}, reportInitData);
    }

    // console.log(`cacheState: ${action.type}`)
    const fct: ActionFunction | undefined = actionMap[action.type];
    if (fct) {
        // logger.log(`---> before:  actionType:  ${action.type}, reportState: ${state.reportState}`)
        state = (fct)(state, action);
        // logger.log(`---> after:  actionType:  ${action.type}, reportState: ${state.reportState}`)
    } 

    return  state;
}

export default reportsUIState;