import React, { useState,} from 'react';
import Editor from 'react-simple-code-editor';
import { ZServiceExt } from '../../data/queryResultDefinitions';

import GenDialog from '../../shared/GenDialog';
import logger from '../../shared/logUtilities';

import { SERVICES_STRINGS as Strs, MISC_STRINGS} from '../../shared/strings';


export interface EditServiceJSONProps {
    currentLocalSvc: ZServiceExt,
    done: (json: string) => void,
    cancel: () => void,
    notification: (message: string) => void,
}

const EditServiceJSON: React.FunctionComponent<EditServiceJSONProps> = props => {
    const [ service, setService ] = useState(JSON.stringify( props.currentLocalSvc, null, 4));
    const [ editorVisible, setEditorVisible] = useState(true)
    const [ serviceChanged, setServiceChanged] = useState(false)
    let validJSON = false;
    let editingStatus = Strs.invalidJSON;
    let msgCss =' edit-json-error-msg'
    try {
        JSON.parse(service);
        validJSON = serviceChanged;
        editingStatus = serviceChanged ? Strs.validJSON : Strs.serviceUnchanged;
        msgCss =' edit-json-msg'
    }
    catch (err) {
        logger.log('badJson')
    }

    const editor = (
            <div className="edit-service">
                <Editor 
                    value={service}
                    highlight={e => {return e}}
                    onValueChange={(value: string): void => {
                        setServiceChanged(true);
                        setService(value) }
                    }
                    tabSize={4}
                    textareaClassName="fixed-font edit-service-text-area"
                    preClassName="edit-service-text-area"
                />
            </div>)

    const editSvcJson = (
        <GenDialog 
            show={editorVisible}
            title={Strs.editServiceJSONTitle(props.currentLocalSvc.service_name)} 
            msg={Strs.editServiceJSON}
            size='xl'
            cancelBtnTxt={MISC_STRINGS.close}
            applyBtnTxt={Strs.saveDraftBtn}
            disableApply={(): boolean => {return !validJSON}}
            onHide={(): void => { setEditorVisible(false); props.cancel();} }
            handleApply={(): void => {setEditorVisible(false); props.done(service); }}
        >
            <div className="list-boundary">
                {editor}

                <div className={'textarea-message' + msgCss} >
                    {editingStatus}
                </div>
            </div>
        </GenDialog>
    );

    return editSvcJson;
}

export default EditServiceJSON;