import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

function FilterItem(
    filterText: string,
    isEnabled: boolean,
    index: number,
    onDelete: (index: number) => void | undefined,
    onToggled: (index: number) => void | undefined
): JSX.Element {
    const trash = onDelete ? 
                <span className="icon" 
                    onClick={() => {onDelete(index)}}><FontAwesomeIcon size="sm" icon="trash-alt" /></span> : '';
    
    let toggle: JSX.Element = <span></span>;
    if (onToggled) {
        let toggleIcon: JSX.Element;
        if (isEnabled) {
            toggleIcon = <FontAwesomeIcon size="sm" icon="toggle-on" />
        } else { 
            toggleIcon = <FontAwesomeIcon size="sm" rotation={180} icon="toggle-on" />; 
        }
        toggle =  <span className="icon" onClick={() => {onToggled(index)}}>{toggleIcon}</span>
    }
    const filterCSS = isEnabled ? 'filter-enabled' : 'label-strikethru';
    const key = 'filter' + index;
    
    return (
        <div className="filter-item" key={key}>
        {toggle}
        <span className={filterCSS}>{filterText}</span>
        {trash}
        </div>
    );                
}

export default FilterItem;