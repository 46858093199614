import { ANALYTICS_STRINGS as STRINGS } from '../shared/strings';
import { LogEntry } from '../data/queryResultDefinitions';
import { StringMap } from '../data/metricsAndOptionsDefs';

export const CLOSE_NOTIFICATION_AUTOCLOSE_TIMEOUT = 15000;
export const hourIntervalsConstants = [1, 3, 6, 9, 12, 24, 72, 168];
export const minuteIntervalConstants = [0, 1, 5, 15];

export const ZALL_SERVICES = 'zall';
export const NAV_PANEL_DDOWN_MAX_TITLE_LEN = 22;

export const DAY_IN_SECONDS = 60 * 60 * 24;
const HOUR_IN_MILLIS = 60 * 60 * 1000;
const MINUTES_IN_MILLIS = 60 * 1000;

// Password expiry constants
export const DEFAULT_DAYS_TIL_PWD_EXPIRES = (3650 * 24 * 60 * 60);
export const DEFAULT_PASSWORD_VALID_DAYS = 7;
export const DEFAULT_NUM_PREV_PREV_PWD_NOT_ALLOWED = 3;
export const DEFAULT_PWD_EXPIRY_WARNING_DAYS = 14;

const tzNameRegex = /.*\s(.+)/.exec(new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' }));
let tzName = '';
if (tzNameRegex) {
    tzName = tzNameRegex[0].split(',')[1];
}
export const TzAbbrev = tzName;
export const MAX_LOG_TIME_PERIOD_DAYS = 7;           // days

export const STATISTICS = [STRINGS.meanOption, STRINGS.minOption, STRINGS.maxOption];
export interface GEOs_DATA  {
    name: string,
    value: string,
}

export const CDN_GEOs: GEOs_DATA[] = [
    { name: STRINGS.geoNA, value: 'NA'}, {name: STRINGS.geoSA, value: 'SA'}, {name: STRINGS.geoEU, value: 'EU'},
    { name: STRINGS.geoAS, value:'AS' }, {name: STRINGS. geoOC, value: 'OC'}
];

export const GEO_MAP: StringMap = {
    NA: STRINGS.geoNA,
    SA: STRINGS.geoSA,
    EU: STRINGS.geoEU,
    AS: STRINGS.geoAS,
    OC: STRINGS.geoOC
}

export const DATETIME_FORMAT = 'MM/DD/YY hh:mm:ss a';
export const DATETIME_FORMAT_NOSECS = 'MM/DD/YY hh:mm a';
export const DATE_FORMAT = 'MM/DD/YY';
export const TIME_FORMAT = 'hh:mm a';

export const APIKEY_NAME_LENGTH = 32;
export const MAX_PROJECT_NAME_LEN = 64;
export const MAX_ORG_NAME_LEN = 32;

export const MAX_DOMAIN_REDIRECTS = 16;

export interface IntervalDataItem {
    millis: number,
    name: string,
    value: string
}

export enum ServiceTemplateName {
   'cdn_reserve' = 'cdn_reserve',
    ecommerce = 'ecommerce',
    default = 'default',
    
}
// Logs Constants
export const PAGE_SIZE = 500;
export const MAX_ESEARCH_RECORDS_AVAILABLE = 10000;
export const MAX_PAGE_CTRL_ITEMS_DISPLAYABLE = 3;
export const CSM_PAGE_SIZE = 100;
export const CSM_MAX_RECORDS_AVAILABLE = 1000;

export const MAX_CACHE_PURGE_URI_FIELD_LENGTH = 32000;
export const MAX_PURGE_PATHS = 40;              // changed to 40 (from 250) in conjunction with regex purge requests
export const MAX_TAG_LENGTH = 128;
export const MAX_CACHE_TAG_LINES = 8;
export const MAX_CACHE_TAG_FIELD_LENGTH = (MAX_CACHE_TAG_LINES * MAX_TAG_LENGTH) + 8;  // The +8 is just to give a little spare room for crlf and stuff.
export const CACHE_REFRESH_INTERVAL = 60000;    // 60 seconds
export const MAX_PASSWORD_LENGTH = 72;

export enum ReportsTypes {
    trafficUsage = 'traffic_usage'
}

// tslint:disable-next-line: variable-name
export const LogDummyData: LogEntry = {
    '@timestamp': '2019-09-15T02:39:23.000Z',
    request_uri: '/hls/live/590971/181117WRCssPri/20181...master_264/00000/master_264_01203.ts?...',
    response_status: 200,
    cache_status: 'HIT',
    response_time_ms: 0,
    client_ip: '172.58.19.24',
    origin: { },
    request_headers: {
        user_agent: 'AppleCoreMedia/1.0.0.16B92 (iPhone; U; CPU OS 12_1 like Mac OS X; en_us)',
    },
    max_age: '--',
    push_indicator: 'false',
    response_size: 229445,
} 

export enum CssColor {
    $PRIMARY_GREEN = '#07D094',
    $PRIMARY_ORANGE = '#FF6329',
    $PRIMARY_YELLOW = '#f5AF4E',
    $PRIMARY_RED = '#FF5050',
    $PRIMARY_BLUE = '#53D0DA',
    $PRIMARY_WHITE = '#FFFFFF',
    $RULE_COLOR = '#333333',
    $GREY_Z1 = '#161C23',
    $GREY_Z2 = '#1E262E',
    $GREY_Z3 = '#515B66',
    $GREY_Z4 = '#A1A9B2',
}

export enum NotificationStyles {
    success = 'success', 
    warning = 'warning', 
    danger = 'danger', 
    info = 'info'
}

// NOTE =  If you add a new ProjDspType remember to create a new entry in 
// 'projectDspTypeFeatureMapping' for that type
export enum ProjectDspType  {       // was called POC_TYPE in zconsole 1.5
    LEGACY = 0,                   // 'legacy',
    BONGOBD_TYPE = 1,             // 'limited-poc',
    DEMO = 2,                     // 'demo',
    PRODUCTION = 3,               // 'production',
    PRODUCTION_EC = 3,            // 'production_ecommerce'
    PRODUCTION_STREAMING = 4,     // hide prefetch for streaming.
}

export const CERT_PRIVATE_START = '-----BEGIN PRIVATE KEY-----';
export const CERT_PRIVATE_END = '-----END PRIVATE KEY-----';
export const CERT_RSA_PRIVATE_START = '-----BEGIN RSA PRIVATE KEY-----';
export const CERT_RSA_PRIVATE_END = '-----END RSA PRIVATE KEY-----';
export const CERT_CHAIN_START = '-----BEGIN CERTIFICATE-----';
export const CERT_CHAIN_END = '-----END CERTIFICATE-----';
export const CERT_MAX_LINE_LENGTH = 64;
export const CERT_MAX_PER_CHAIN = 5;                // per Roy on 4/30/2020

export const MAX_SERVICE_NAME_LENGTH = 128;
export const MAX_DOMAIN_NAME_LENGTH = 255;
export const MAX_ORIGIN_NAME_LENGTH = 128;
export const MAX_SNI_HOSTNAME_LENGTH = 255;
export const MAX_CDN_TAG_LENGTH = 10;
export const MAX_HEALTH_CHECK_PATH_LENGTH = 1000;

// port: should be between 0-65535 (excluding 0, 22) - Ganu 5/14/2020
export interface ProjDTypeFeatures {
    cacheManageVisible: boolean,
    cacheAnalyticsVisible: boolean,
    loggingVisible: boolean,
    loggingObscurred: boolean,
}

export interface ProjDTypeFeaturesEntry {
    [type: string]: ProjDTypeFeatures
}

export const projectDspTypeFeatureMapping: ProjDTypeFeaturesEntry = {
    [ProjectDspType.BONGOBD_TYPE]: {   // legacy
        cacheManageVisible: false,
        cacheAnalyticsVisible: false,
        loggingVisible: false,
        loggingObscurred: true,
    },
    [ProjectDspType.BONGOBD_TYPE]: {   // 'limited-poc'
        cacheManageVisible: false,
        cacheAnalyticsVisible: true,
        loggingVisible: true,
        loggingObscurred: true,
    },
    [ProjectDspType.BONGOBD_TYPE]: {   // demo 
        cacheManageVisible: true,
        cacheAnalyticsVisible: true,
        loggingVisible: true,
        loggingObscurred: false,
    },
    [ProjectDspType.PRODUCTION_EC]: {   // production - ECommerce
        cacheManageVisible: true,
        cacheAnalyticsVisible: true,
        loggingVisible: true,
        loggingObscurred: false,
    },
    [ProjectDspType.PRODUCTION_STREAMING]: {   // production  - Streaming
        cacheManageVisible: true,
        cacheAnalyticsVisible: true,
        loggingVisible: true,
        loggingObscurred: false,
    }
}

export const minuteIntervalData = (() => {
    const intervalList: IntervalDataItem[] = [];

    for (let i = 0, len = minuteIntervalConstants.length; i < len; i++) {
        const interval = minuteIntervalConstants[i];
        let label = '';
        if (interval === 0) {
            label = STRINGS.offOption;
        } else if (interval === 1) {
            label = interval + STRINGS.minuteOption;
        } else {
            label = interval + STRINGS.minutesOption;
        }
        const result = {
            millis: MINUTES_IN_MILLIS * interval,
            name: label,
            value: ''
        }
        intervalList.push(result);
    }

    return intervalList;
})();

export const hourIntervalData = (() => {
    const intervalList: IntervalDataItem[] = [];

    for (let i = 0, len = hourIntervalsConstants.length; i < len; i++) {
        let label = '';
        const interval = hourIntervalsConstants[i]

        if (interval === 1) {
            label = interval + STRINGS.hourOption;
        } else if (interval < 24) {
            label = interval + STRINGS.hoursOption;
        } else if (interval === 24) {
            label = (interval / 24) + STRINGS.dayOption;
        } else if (interval > 24) {
            label = (interval / 24) + STRINGS.daysOption;
        }

        const result = {
            millis: HOUR_IN_MILLIS * interval,
            name: label,
            value: '-' + interval + STRINGS.hourLabel
        }
        intervalList.push(result);
    }

    return intervalList;
})();
